<script>
import CirclePlaceholder from './circle-placeholder'

export default {
  name: 'PlaceholderPortrait',
  components: {
    CirclePlaceholder,
  },
  props: {
    src: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <div :class="$style.root">
    <img v-if="src" :src="src" :class="$style.img" />

    <circle-placeholder v-else :class="$style.img" />
  </div>
</template>

<style lang="scss" module>
@import '@scss';

.root {
  position: relative;
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 50%;
}

.img {
  display: inline;
  width: auto;
  height: 100%;
  margin: 0 auto;
}
</style>
