import store from '../store'
import { ValuesType } from 'utility-types'
import { Notification } from 'typedefs'
import { Action } from 'types'

export type NotificationItem = {
  actions?: Action[]
  loading: boolean
} & Notification

type State = {
  notificationAdded: NotificationAdded
  notifications: NotificationItem[]
}

export const state: State = {
  notificationAdded: null,
  notifications: [],
}

export const getters = {
  notifications(state) {
    return state.notifications
  },
  notificationAdded(state) {
    return state.notificationAdded
  },
}

export const mutations = {
  SET_NOTIFICATIONS(state, value: NotificationItem[]) {
    state.notifications = value ? [...value] : []
  },
  SET_NOTIFICATION_ADDED(state, value) {
    state.notificationAdded = value ? { ...value } : null
  },
}

export const actions = {
  setNotificationAdded({ commit }, value) {
    commit('SET_NOTIFICATION_ADDED', value)
  },

  setNotifications({ commit }, items: NotificationItem[]) {
    commit('SET_NOTIFICATIONS', items)
  },
}

type NotificationAdded = Notification | null
type ActionKeys = keyof typeof actions
type GetterKeys = keyof typeof getters

type DispatchPayload = ValuesType<State>

const MODULE_KEY = 'notifications'

export function dispatch(action: ActionKeys, payload: DispatchPayload) {
  return store.dispatch(`${MODULE_KEY}/${action}`, payload)
}

export function get(key: GetterKeys) {
  const rv: ValuesType<State> = store.getters[`${MODULE_KEY}/${key}`]
  return rv
}
