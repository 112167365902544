<script>
export default {
  name: 'PageContent',
}
</script>

<template>
  <section :class="$style.container">
    <slot />
  </section>
</template>

<style lang="scss" module>
@import '@scss';

.container {
  flex-grow: 1;
}
</style>
