<template>
  <fp-columns :columns="[columns.side, columns.form]">
    <!-- common left side -->
    <div :slot="columns.side.id" :class="$style.side">
      <div :class="$style.sideInner + ' px-4'">
        <div :class="$style.tagline">
          <!-- If tagline will chande with route change, replace it with a slot -->
          <strong>Pioneering innovation</strong> in the video game advertising
          industry
        </div>
      </div>
    </div>

    <!-- forms -->
    <div :slot="columns.form.id" :class="$style.form">
      <img
        src="@assets/images/fp-logo-color-horizonal.svg"
        :class="$style.logoColour"
        alt="Welcome to Frameplay"
        tabindex="-1"
      />
      <img
        src="@assets/images/fp-logo-horizonal-white.svg"
        :class="$style.logoWhite"
        tabindex="-1"
      />
      <div :class="$style.formInner">
        <!-- 🌸 view for sign up / login / forgot password 🌼-->
        <transition :name="transitionName" mode="out-in">
          <keep-alive>
            <router-view :title="title" @set-transition="setTransition" />
          </keep-alive>
        </transition>
      </div>
    </div>
  </fp-columns>
</template>

<script>
import _capitalize from 'lodash/capitalize'

export default {
  data() {
    return {
      transitionName: 'fade-quick',

      columns: {
        side: {
          id: 'first',
          align: 'center',
          classes: `${this.$style.first} xs12 md6`,
        },
        form: {
          id: 'second',
          align: 'center',
          classes: `xs12 md6`,
        },
      },
    }
  },

  computed: {
    title() {
      return (
        this.$route?.meta?.title ||
        _capitalize(this.$route.name) ||
        'Authorization'
      )
    },
  },

  methods: {
    setTransition(name) {
      this.transitionName = name
    },
  },
}
</script>

<style lang="scss" module src="./auth-layout.scss"></style>
