import difference from 'lodash/difference'
import uniqueRandomArray from 'unique-random-array'

// export function getUniqueRandomIndex<T>(
//   usedItemIndices: number[],
//   items: T[]
// ): [number[], number] {
//   const itemIndices = items.map((o, i) => i)
//   const newUsedIndices = [...usedItemIndices]

//   // get an index from b not in a
//   const notUsed = setDiff(itemIndices, usedItemIndices)
//   const toReturn = Math.floor(Math.random() * notUsed.length)

//   // update obtained index to a
//   if (usedItemIndices.indexOf(toReturn) === -1) {
//     newUsedIndices.push(toReturn)
//   }

//   return [newUsedIndices, toReturn]
// }

const enumToArray = enumInput => {
  const arr: any[] = Object.keys(enumInput).map(k => enumInput[k])
  return arr
}

/**
 * swaps the values in an array for the specified indices
 * mutates original array!
 */
const swapArrayElements = (arr, i, i2) => {
  const tmp = arr[i]
  arr[i] = arr[i2]
  arr[i2] = tmp
}

const toObject = (
  array: any[],
  mapKey?: (obj: any, item: any, i: number) => any
): { [key: string]: any } => {
  const valueAsKey = (obj: any, item: any) => {
    const _obj = { ...obj }
    _obj[item] = item
    return _obj
  }
  // const indexAsKey = (obj, item, currentIndex) => {
  //   const _obj = { ...obj }
  //   _obj[currentIndex] = item
  //   return _obj
  // }

  // const customKeyAsKey = (obj, item, key) => {
  //   const _obj = { ...obj }
  //   _obj[key] = item
  //   return _obj
  // }

  // mapper
  const rv = array.reduce((obj, item, currentIndex) => {
    const defaultHandler = valueAsKey
    let _obj = { ...obj }

    // no key
    if (!mapKey) {
      _obj = defaultHandler(obj, item)
      return _obj
    }

    //  key is a function
    if (typeof mapKey === 'function') {
      _obj = mapKey(obj, item, currentIndex)
      return _obj
    }

    // key that matches predefined handler
    // const map = {
    //   index: () => indexAsKey(obj, item, currentIndex),
    // }

    // _obj = map[key] && map[key]()

    // if (!_obj) {
    //   _obj = customKeyAsKey(obj, item, key)
    // }

    return _obj
  }, {})

  return rv
}

const arrayToCSV = (arr, delimiter = ',') =>
  arr
    .map(v =>
      v.map(x => (isNaN(x) ? `"${x.replace(/"/g, '""')}"` : x)).join(delimiter)
    )
    .join('\n')

// native forEach does not work when looping async functions.
// this function implements a custom forEach that works with async funcs.
// this is also used for async funcs to run in series instead of in parallel
// to run async funcs in parallel use Promise.all()
// https://codeburst.io/javascript-async-await-with-foreach-b6ba62bbf404
const asyncForEach = async <T>(
  array: T[],
  callback: (item: T, i: number, array: T[]) => void
) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

const fillArray = (
  numElements: number,
  valueSetter?: (i: number) => void
): any[] => {
  const arr = Array.apply(null, Array(numElements))
  return arr.map((x, i) => {
    return valueSetter ? valueSetter(i) : i
  })
}

const isArrayEmpty = (a: any) => Array.isArray(a) && a.every(isArrayEmpty)

const _findMinOrMax = (arr: number[], type: 'min' | 'max') => {
  let out = arr[0]
  for (let i = 1, len = arr.length; i < len; i++) {
    let v = arr[i]
    if (type === 'max') {
      out = v > out ? v : out
    } else {
      out = v < out ? v : out
    }
  }

  return out
}

const findMax = (arr: number[]) => _findMinOrMax(arr, 'max')
const findMin = (arr: number[]) => _findMinOrMax(arr, 'min')

// uncomment this when needed
// https://exploringjs.com/impatient-js/ch_sets.html#missing-set-operations
// Difference (a \ b), "Set that contains those elements of a that are not in b"
// export const setDiff = (a, b) => {
//   const out = [...new Set([...new Set(a)].filter(x => !new Set(b).has(x)))]
//   return out
// }

// intersection does not work for object types
function intersection<T>(a: T[], b: T[]): T[] {
  const rv = a.filter(x => b.includes(x))
  return rv
}

function valToArray<T>(val: T): T[] {
  return val ? [val] : []
}

function chunkArray(array, size) {
  if (array.length <= size) {
    return [array]
  }
  return [array.slice(0, size), ...chunkArray(array.slice(size), size)]
}

export {
  toObject,
  fillArray,
  asyncForEach,
  isArrayEmpty,
  findMax,
  findMin,
  intersection,
  valToArray,
  difference,
  enumToArray,
  swapArrayElements,
  chunkArray,
  arrayToCSV,
  uniqueRandomArray,
}
