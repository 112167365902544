import gql from 'graphql-tag'
import { notification } from '../fragments'

export const getNotifications = gql`
  query getNotifications($limit: Int, $skip: Int, $type: NotificationTypeEnum) {
    # Logged In User Account
    getNotifications(limit: $limit, skip: $skip, type: $type) {
      total
      items {
        ...notification
      }
    }
  }
  ${notification}
`
