import '@component-styles/button.scss'
import { convertToUnit } from '@shared/utils/ui'
import { createComponent, computed, reactive } from '@vue/composition-api'
import { BEMProvider } from '@plugins/bem'
import router from '@src/router'

const bem = BEMProvider('fp-button')

export default createComponent({
  name: 'FpButton',

  props: {
    // Modifiers
    type: {
      default: 'primary',
      type: String,
    },

    color: {
      default: 'inherit',
      type: String,
    },

    size: {
      default: 'normal',
      type: String,
    },

    width: {
      type: [String, Number],
      default: '',
    },

    height: {
      type: [String, Number],
      default: '',
    },

    fill: { type: Boolean },

    icon: {
      default: '',
      type: String,
    },

    iconPosition: {
      default: 'right',
      type: String,
    },

    // States
    loading: Boolean,

    disabled: Boolean,

    // link for the vue router
    link: {
      type: [String, Object],
      default: null,
    },
    // href for the external link
    href: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit, slots }) {
    const computedState = reactive({
      icon: computed(() =>
        props.disabled ? 'ban' : props.type === 'text' ? null : props.icon
      ),
      width: computed(() => {
        if (props.fill) return '100%'

        return convertToUnit(props.width)
      }),
      height: computed(() => {
        return convertToUnit(props.height)
      }),
      isText: computed(() => {
        return props.type === 'text'
      }),
    })

    function clickHandler(event: MouseEvent) {
      const { disabled, loading, link, href } = props

      if (disabled || loading) return

      if (link) {
        return router.push(link)
      }

      if (href) {
        return jumpToExternalLink(href)
      }

      emit('click', event)
    }

    return () => {
      const {
        disabled,
        loading,

        size: $size,
        color: $color,
        type: $type,
        iconPosition: $iconPosition,
      } = props

      const { icon, width, isText, height } = computedState

      return (
        <button
          {...bem({
            disabled,
            loading,

            $size,
            $type,
          })}
          v-ripple={!isText}
          style={{ width, height }}
          onClick={clickHandler}
        >
          <span {...bem('::text', { $color })}>{slots.default()}</span>

          {icon && !isText && (
            <div {...bem('::icon', { $iconPosition })}>
              <fp-icon name={icon} size="1x" />
            </div>
          )}

          {loading && !isText && (
            <div {...bem('::loader')}>
              <v-progress-circular
                size="20"
                width="2"
                indeterminate
                color="inherit"
              />
            </div>
          )}
        </button>
      )
    }
  },
})

function jumpToExternalLink(href: string) {
  const a = document.createElement('a')

  a.href = href
  a.target = '_blank'

  a.click()

  a.remove()
}
