import { getOrgChildren } from '@graphql/companies/queries'
import { Store } from 'vuex'
import { OrgChildrenList } from '@graphql-types'

type State = {
  orgChildrenList: Object
  loading: boolean
}

export const state: State = {
  orgChildrenList: {},
  loading: false,
}

export const getters = {
  orgChildrenList({ orgChildrenList }) {
    return orgChildrenList
  },
  loading({ loading }) {
    return loading
  },
}

export const mutations = {
  SET_ORG_CHILDREN_LIST(state, value: OrgChildrenList) {
    state.orgChildrenList = value
  },
  SET_LOADING(state, value) {
    state.loading = value
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // it also runs after a user logs out and logs in, so we get the new user info
  // maybe from another user
  // async init({ dispatch }) {
  //   await dispatch('getOrgChildrenList')
  // },

  async setLoading({ dispatch, commit, state }, data) {
    commit('SET_LOADING', data)
  },

  async getOrgChildrenList(
    this: Store<any>,
    { dispatch, commit },
    variables = {}
  ) {
    dispatch('setLoading', true)

    const [err, res] = await this.dispatch('tryQuery', {
      query: getOrgChildren,
      variables,
    })

    if (!err) commit('SET_ORG_CHILDREN_LIST', res)
    dispatch('setLoading', false)
  },
}
