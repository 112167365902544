import {
  createComponent,
  SetupContext,
  watch,
  reactive,
  computed,
} from '@vue/composition-api'
import * as type from './type'
import style from './index.module.scss'

export default createComponent({
  name: 'ViewportTabs',
  // @ts-ignore
  props: [...type.vueProps],

  setup(props: type.Props, ctx: SetupContext) {
    // hooks

    // states
    const state = reactive({
      selectedItemKey:
        props.initialActiveItemKey || Object.keys(props.itemMap)[0],
      itemMap: props.itemMap || {},
      loading: props.loading,
    })

    const cState = reactive({
      selectedItem: computed(() => props.itemMap[state.selectedItemKey] || {}),
    })

    // events
    const handleItemClick = (k: string, v: type.Item, i: number) => {
      state.selectedItemKey = k
      ctx.emit('click:item', k, v, i)
    }

    watch(
      () => props.itemMap,
      val => {
        state.itemMap = val
      }
    )

    watch(
      () => props.initialActiveItemKey,
      val => {
        state.selectedItemKey = val || Object.keys(props.itemMap)[0]
      }
    )

    watch(
      () => props.loading,
      val => {
        state.loading = val
      }
    )

    // render funcs
    const Items = () => {
      return (
        <div role="navigation" class={[style.itemsBox, 'pt-md-11', 'pt-4']}>
          {Object.entries(state.itemMap).map(([k, v], i) => {
            const activeClass =
              k === state.selectedItemKey && !v.disabled && style.itemActive
            const disabledClass = v.disabled && style.itemDisabled
            const clickHandler = v.disabled
              ? () => {}
              : () => handleItemClick(k, v, i)

            return state.loading ? (
              <fp-skeleton height="24" width="110" class="mb-6" />
            ) : (
              <div
                onClick={clickHandler}
                class={[
                  activeClass,
                  disabledClass,
                  style.item,
                  `pb-md-6`,
                  'pb-4',
                ]}
                data-cy={'account-settings-tab--' + v.text}
              >
                <div class="d-flex">
                  {v.text}
                  {/* {v.icon && (
                    <fp-icon-button
                      class="ml-1"
                      {...{ props: v.icon?.props }}
                    />
                  )} */}
                </div>
              </div>
            )
          })}
        </div>
      )
    }

    // main render
    return () => {
      return (
        <div class={[style.root, 'pb-6']}>
          <v-container fill-height fluid class="py-0">
            <v-row class={style.row} justify="center">
              {!props.noNav && (
                <v-col
                  class={[style.itemsRoot, 'px-lg-8 px-md-6 py-lg-12 py-md-10']}
                  cols="12"
                  md="2"
                >
                  <div class={ctx.slots.header ? `pb-4` : ''}>
                    <label class={style.label}>{props.title}</label>

                    {props.title2 && (
                      <div>
                        <label class={style.label}>{props.title2}</label>
                      </div>
                    )}
                  </div>

                  {ctx.slots.header ? (
                    <div class={[style.headerBox, `pb-2`]}>
                      {ctx.slots.header && ctx.slots.header()}
                    </div>
                  ) : null}

                  <Items />
                </v-col>
              )}

              {!state.loading && (
                <v-col
                  class={[style.contentRoot, `pa-9`]}
                  cols="12"
                  md="10"
                  lg="6"
                  {...{ props: cState.selectedItem.options?.content?.col }}
                  // {...{ style: cState.selectedItem.options?.content?.style }}
                >
                  <div
                    class={[
                      style.contentHeaderBox,
                      'd-flex',
                      'justify-center',
                      'justify-space-between',
                    ]}
                  >
                    <h2>
                      {cState.selectedItem.title || cState.selectedItem.text}
                    </h2>

                    <div class={style.status}>
                      {ctx.slots.status && ctx.slots.status()}
                    </div>

                    <div>{ctx.slots.actions && ctx.slots.actions()}</div>
                  </div>

                  <transition name="view-fade-vertical" mode="out-in">
                    {ctx.slots[state.selectedItemKey] &&
                      ctx.slots[state.selectedItemKey]()}
                  </transition>
                </v-col>
              )}

              {state.loading && (
                <v-col
                  class={[style.contentRoot, `pa-9`]}
                  cols="12"
                  md="10"
                  lg="6"
                >
                  <fp-skeleton height="42" width="190" bottomMargin="40" />
                  <fp-skeleton height="50" width="100%" bottomMargin="30" />
                  <fp-skeleton height="50" width="100%" bottomMargin="30" />
                  <fp-skeleton height="50" width="100%" bottomMargin="30" />
                  <fp-skeleton height="50" width="100%" bottomMargin="46" />
                  <fp-skeleton height="44" width="200" round />
                </v-col>
              )}

              <v-col>{` `}</v-col>
            </v-row>
          </v-container>
        </div>
      )
    }
  },
})
