<script>
import DonutChart from '@components/charts/donut'
import { isArrayEmpty, uuid } from '@utils'
import {
  globalColorPrimary,
  globalColorAccent,
  globalColorMidGrey,
  globalColorLightGrey,
  globalColorDarkPrimary,
  globalColorMidPrimary,
  globalColorLightPrimary,
  globalColorLighterPrimary,
  globalColorDarkerAccent,
  globalColorMidAccent,
  globalColorSuccess,
} from '@scss-ts'

export default {
  name: 'DonutCard',

  components: {
    DonutChart,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    horizontalLegendBreakpoint: {
      type: String,
      default: 'smAndDown',
      validator(val) {
        return ['smAndDown', 'mdAndDown'].includes(val)
      },
    },
    dataMap: {
      type: Object,
      default: () => ({}),
    },
    legend: {
      type: Object,
      default: () => ({}),
    },
    series: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: true,
    },
    bar: {
      type: Object,
      default: () => ({ text: '' }),
    },
    card: {
      type: Object,
      default: () => ({ height: 288, extraHeight4LegendWhenSm: 65 }),
    },

    height: {
      type: [String, Number],
      default: 245,
    },
  },
  data() {
    return {
      uuid,
      isArrayEmpty,
      hoveredPiece: '',
      chart: {
        colors: [
          globalColorLightPrimary,
          globalColorMidPrimary,
          globalColorAccent,
          globalColorPrimary,
          globalColorMidGrey,
          globalColorLightGrey,
          globalColorDarkPrimary,
          globalColorLighterPrimary,
          globalColorDarkerAccent,
          globalColorMidAccent,
          globalColorSuccess,
        ],
        height: this.height,
      },
    }
  },
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint
    },

    hasBarSlot() {
      return !!this.$slots['bar']
    },

    chartHeight() {
      const _height = this.breakpoint[this.horizontalLegendBreakpoint]
        ? this.chart.height + this.card.extraHeight4LegendWhenSm
        : this.chart.height

      return _height | 0
    },

    skeleton() {
      const size = this.chartHeight / 1.25
      const marginTop = (this.chartHeight - size) / 2
      return {
        size: size + 'px',
        marginTop: marginTop + 'px',
      }
    },

    cardHeight() {
      const height = this.breakpoint[this.horizontalLegendBreakpoint]
        ? this.card.height + this.card.extraHeight4LegendWhenSm
        : this.card.height

      // +5 for a bit of padding
      const barHeight = this.hasBarSlot ? 63 + 5 : 0

      return `${height + barHeight}px`
    },
  },
}
</script>

<template>
  <fp-card no-body-pad :style="`height:${cardHeight}`">
    <template #label>
      {{ title }}
    </template>

    <transition name="fade" mode="out-in">
      <div
        v-if="loading"
        key="donut-skeleton"
        class="fa-spin"
        :class="$style.donutSkeleton"
        :style="`height:${skeleton.size};
           width:${skeleton.size};
           margin-top:${skeleton.marginTop};`"
      />
      <div v-else key="donut-real">
        <donut-chart
          :id="uuid()"
          :height="chartHeight"
          :colors="chart.colors"
          :data-map="dataMap"
          :horizontal-legend-breakpoint="horizontalLegendBreakpoint"
          :legend="{
            ...legend,
            heightWhenSm: card.extraHeight4LegendWhenSm,
          }"
          :series="series"
        />

        <div
          v-if="hasBarSlot"
          :class="[$style.bar, 'd-flex', 'align-center', 'justify-center']"
        >
          <slot name="bar" />
        </div>
      </div>
    </transition>
  </fp-card>
</template>

<style lang="scss" module>
@import '@scss';

.donutSkeleton {
  margin: auto;
  border: 13px solid $color-primary-glass;
  border-right-color: $color-accent-glass;
  border-bottom-color: $color-mid-primary-glass;
  border-radius: 50%;
  animation-direction: reverse;
}

.bar {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  padding: 25px;
  font-weight: 500;
  color: $color-white;
  text-align: center;
  background: $gradient-primary-diagonal;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  border-radius: 0 0 $size-card-border-radius $size-card-border-radius;

  a {
    color: $color-white !important;
  }

  @include mq($from: wide) {
    padding: 25px 90px;
  }

  @include mq($until: mobile) {
    padding: 12px;
    font-size: 14px;
  }
}
</style>
