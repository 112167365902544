import router from '@router'
import { Auth } from 'aws-amplify'
import { cs, isFunction, omit } from '@utils'
import { AUTH_EXCEPTIONS } from '@shared/auth/constants'
import dispatchActionForAllModules from '../utils/dispatch-action-for-all-modules'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import forIn from 'lodash/forIn'
import useOnboard from '@views/sign-up/useOnboard'
import * as RouteName from '@router/routeNames'
import { INIT_ORG } from '@src/shared/org/constants'
import { OrgDispatch } from '@state/modules/org.store'
import { OrgDashboardDispatch } from '@state/modules/orgDashboard.store'
import { DashboardDispatch } from '@state/modules/dashboard.store'
import to from 'await-to-js'

import { UserGetters } from './user.store'
import { SSO_LOGIN_KEYS, CI_CONTEXT } from '@src/shared/constants'

import {
  CognitoUserSession,
  CognitoUser,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUserPool,
} from 'amazon-cognito-identity-js'

// Recommended to import as jwt_decode
// we have to disable the next line or else it'll throw "Identifier 'jwt_decode' is not in camel case  camelcase"
// eslint-disable-next-line
import jwt_decode from 'jwt-decode'

// incomplete, legacy hard-coded strings remain
export const LOCAL_STORE_KEYS = {
  FORGOT_PASS_INFO: 'forgot_password_info',
  SIGNUP_CONFIRM_ID: 'signup_confirm_id',
  SIGNUP_EMAIL: 'signup_email',
  USER_ID: 'user_id',
}

export const BLANK_STATE_ERROR = 'unauthorized'

export const SSO_REFRESH_TOKEN_EXPIRED_ERROR = 'ssoRefreshTokenExpired'

export const state = {
  // If localStorage have object type user_id , we consider it as loggedIn
  // ⚠️ NOTE that there will be a string type user_id
  // which indicate user is verifying code, NOT loggedIn !

  /* This current user info is from amplifyJS, DOES NOT contain first and last name */
  currentUser: getCurrentUserFromLocal() || null,

  forgotPasswordInfo: {
    username: null,
  },
  authErrors: [],
  authState: {
    submitting: false,
  },
  ssoLoading: false,
}

export const getters = {
  // When user finish sign-up's form but not verify code,
  // localStorage will contain a string type user_id
  verifyingCode(state) {
    return !!getSavedState(LOCAL_STORE_KEYS.SIGNUP_CONFIRM_ID)
  },
  // Whether the user is currently logged in.
  loggedIn(state, { verifyingCode }) {
    return !!state.currentUser && !verifyingCode
  },
  currentUser(state) {
    return state.currentUser
  },
  authErrorMessages(state) {
    return state.authErrors && state.authErrors.length
      ? state.authErrors.map(error => error.message)
      : null
  },
  ssoLoading(state) {
    return state.ssoLoading
  },
}

export const mutations = {
  SET_SUBMITTING(state, newValue = false) {
    state.authState.submitting = newValue
  },
  SET_FORGOT_PASSWORD_INFO(state, payload) {
    const { username = null } = payload || {}
    // order of destructure is important. spread original first,
    // then overwrite with new state

    const newState = { ...state.forgotPasswordInfo, username: username }
    state.forgotPasswordInfo = newState
  },
  SET_AUTH_ERRORS(state, err) {
    state.authErrors = []
    // sometimes cognito returns string or an object
    const errMsg = err && err.message ? err.message : err
    const errCode = err && err.code ? err.code : err
    if (errMsg || errCode)
      state.authErrors.push({ code: errCode, message: errMsg })
  },
  SET_CURRENT_USER(state, data) {
    let userObject = data

    if (!isNull(userObject) && !isUndefined(userObject)) {
      // Reduce size of user_id by removing unecessary keys
      userObject = omit(userObject, [
        'pool',
        'Session',
        'client',
        'authenticationFlowType',
        'storage',
        'keyPrefix',
        'userDataKey',
        'deviceKey',
      ])
    }

    saveState(LOCAL_STORE_KEYS.USER_ID, userObject)
    state.currentUser = userObject
  },
  SET_SSO_LOADING(state, loading) {
    state.ssoLoading = loading
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ dispatch }) {},

  // ---------------------- //
  // STATE HELPER ACTIONS
  // -----------------------//

  // Validates the current user's token and refreshes it
  // with new data from the API.
  async validate({ state, dispatch, commit }) {
    const user = await Auth.currentAuthenticatedUser()
      .then(user => {
        commit('SET_CURRENT_USER', user)
        return user
      })
      .catch(err => {
        const currentUser = getCurrentUserFromLocal()
        cs.e(err, 'auth')
        dispatch('logOut')

        // refreshToken has a limit set by the server. We can't refresh this manually so SSO users needs to be redirected back to the login page
        // if this is a SSO user, redirect then to the loginUrl instead after logging out cognito
        // SSO users will be the only user type that has a loginUrl
        if (
          currentUser &&
          currentUser?.signInUserSession?.idToken?.payload?.loginUrl
        ) {
          // use window.location.href to keep the browser history
          window.location.href =
            currentUser.signInUserSession.idToken.payload.loginUrl

          // return an error so the redirect back to the login page for SSO users
          // doesn't contain a flash of the platform login page
          return SSO_REFRESH_TOKEN_EXPIRED_ERROR
        }

        // err thrown can be found here, https://github.com/aws-amplify/amplify-js/blob/main/packages/auth/src/Auth.ts#L1806

        // black page issue is related to the user store, specifically getUserInfo
        // the init for the store sets the loading as true,
        // once getCurrentUser fails it prevents any scripts from continuing
        // which is why we check if the loading is set to true here, if so
        // return something we can use to "identify" the failure
        if (UserGetters('loading') && err.includes('not authenticated')) {
          return BLANK_STATE_ERROR
        }
      })
    return user
  },

  // RESET ERROR STATE
  clearAuthErrors({ commit }) {
    commit('SET_AUTH_ERRORS', null)
  },

  // ---------------------- //
  // COGNITO ACTIONS
  // -----------------------//

  // Refresh cognito session
  // https://github.com/aws-amplify/amplify-js/issues/2560
  async refreshSession({ commit, dispatch, getters }, callback) {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser()
      const currentSession = await Auth.currentSession()
      cognitoUser.refreshSession(
        currentSession.refreshToken,
        (err, session) => {
          // const { idToken, refreshToken, accessToken } = session
          // do whatever you want to do now :)

          if (err) {
            commit('SET_AUTH_ERRORS', err)
          }

          commit('SET_CURRENT_USER', cognitoUser)
          if (callback && isFunction(callback)) {
            callback(session, err)
          }
        }
      )
    } catch (e) {
      cs.l('Unable to refresh Token', e)
    }
  },

  // SIGN IN
  logIn({ commit, dispatch, getters }, { username, password }) {
    commit('SET_SUBMITTING', true)
    if (getters.loggedIn) return dispatch('validate')

    // when sign up, close signup verify wait page, preserve signup info
    // so when login still retain login user prefill
    // preserve signup confirm id so resend email still works
    const preservedKeys = preserveLocalStorageKeys().filter(
      key =>
        key !== LOCAL_STORE_KEYS.SIGNUP_EMAIL &&
        key !== LOCAL_STORE_KEYS.SIGNUP_CONFIRM_ID
    )

    deleteLocalStorageKeys(preservedKeys)

    return Auth.signIn(username, password)
      .then(async user => {
        // delete these on successful signup but preserve it on fail, see above
        deleteLocalStorageKeys([
          LOCAL_STORE_KEYS.SIGNUP_EMAIL,
          LOCAL_STORE_KEYS.SIGNUP_CONFIRM_ID,
        ])

        commit('SET_CURRENT_USER', user)

        const { shouldOnboard } = useOnboard({
          root: {
            $router: router,
          },
        })

        if (shouldOnboard()) {
          router.push({ name: RouteName.ONBOARD_ACCOUNT_TYPE })
        } else {
          // some methods are async like getUserInfo,
          // which will break cypress if not await
          await dispatchActionForAllModules('init')
        }
      })
      .then(() => {
        const redirectFrom = router.history?.current?.query?.redirectFrom
        if (redirectFrom) {
          router.push({ path: redirectFrom })
        } else {
          router.push({ name: 'dashboard' })
        }
      })
      .catch(err => {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        if (err?.code === AUTH_EXCEPTIONS.userNotConfirmedException) {
          // save the email to help login faster
          saveState(LOCAL_STORE_KEYS.SIGNUP_EMAIL, username)
          router.push({
            name: RouteName.SIGNUP_VERIFY_WAIT,
          })
        }

        cs.e(err, 'Login:')

        const notExistMsg = 'Incorrect email or password'
        const errMap = {
          UserNotFoundException: notExistMsg,
          NotAuthorizedException: notExistMsg,
        }

        const errToShow = errMap[err?.code] || 'Unable to login'

        commit('SET_AUTH_ERRORS', errToShow)
      })
      .finally(() => commit('SET_SUBMITTING', false))
  },

  async ssoLogin({ commit, dispatch, getters }, { code, redirectRoute }) {
    try {
      if (getters.loggedIn) await dispatch('logOut')

      // use URLSearchParams to set the `application/x-www-form-urlencoded` params
      const params = new URLSearchParams()
      params.set('client_id', process.env.VUE_APP_COGNITO_CLIENT_ID)
      params.set('grant_type', 'authorization_code')
      params.set('code', code)
      // Use the current url so it matches the environment
      params.set('redirect_uri', window.location.origin)
      // TODO - removed once testing fed is over.
      // used for localhost
      // params.set('redirect_uri', 'https://app.dev.llm.games')

      const envTokenUrlNameMap = {
        [CI_CONTEXT.DEV]: 'fpdev',
        [CI_CONTEXT.TEST]: 'frameplay-test',
        [CI_CONTEXT.PROD]: 'frameplay',
      }

      const envTokenUrlName =
        envTokenUrlNameMap[process.env.VUE_APP_CI_CONTEXT] ?? 'frameplay-demo' // todo do demo proper

      const response = await fetch(
        `https://${envTokenUrlName}.auth.us-east-1.amazoncognito.com/oauth2/token`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: params,
        }
      )

      const data = await response.json()
      const decodedJwt = jwt_decode(data[SSO_LOGIN_KEYS.ID_TOKEN])

      const session = new CognitoUserSession({
        IdToken: new CognitoIdToken({
          IdToken: data[SSO_LOGIN_KEYS.ID_TOKEN],
        }),
        AccessToken: new CognitoAccessToken({
          AccessToken: data[SSO_LOGIN_KEYS.ACCESS_TOKEN],
        }),
        RefreshToken: new CognitoRefreshToken({
          RefreshToken: data[SSO_LOGIN_KEYS.REFRESH_TOKEN],
        }),
      })

      var userData = {
        Username: decodedJwt.email,
        Pool: new CognitoUserPool({
          UserPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
          ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
        }),
      }

      const cognitoUser = new CognitoUser(userData)
      cognitoUser.setSignInUserSession(session)

      await Auth.currentAuthenticatedUser()
        .then(async user => {
          commit('SET_CURRENT_USER', user)

          await dispatchActionForAllModules('init')
        })
        .then(() => {
          // push to the original route if successful
          router.push(redirectRoute)
        })
        .catch(err => {
          cs.e(err, 'Login:')
          // throw error so it can be caught to return a blank page
          throw err
        })
    } catch (err) {
      // throw error so it can be caught to return a blank page
      cs.e(err?.message ?? err, 'Code:')
      throw err
    }
  },

  // SIGN OUT
  logOut({ commit }) {
    return Auth.signOut()
      .then(() => {
        commit('SET_CURRENT_USER', null)

        // Clear Vuex dashboard state
        OrgDashboardDispatch('clearOrgDashboard')
        DashboardDispatch('clearDashboard')

        // Clear Vuex org state
        OrgDispatch('setOrganisation', INIT_ORG)

        deleteLocalStorageKeys([LOCAL_STORE_KEYS.USER_ID])
      })
      .catch(err => cs.e(err))
  },

  setForgotPasswordInfo({ commit }, { username }) {
    commit('SET_SUBMITTING', true)
    const forgotPassInfo = { username }
    commit('SET_FORGOT_PASSWORD_INFO', forgotPassInfo)
    saveState(LOCAL_STORE_KEYS.FORGOT_PASS_INFO, forgotPassInfo)
    commit('SET_SUBMITTING', false)
  },

  // CONFIRM CODE
  // Request to send new password confirm code
  sendForgotPasswordCode(
    { commit },
    { username, redirect = true, onSuccess = null }
  ) {
    commit('SET_SUBMITTING', true)
    // username needed later for forgotPasswordSubmit cognito API
    const forgotPassInfo = { username }
    commit('SET_FORGOT_PASSWORD_INFO', forgotPassInfo)
    saveState(LOCAL_STORE_KEYS.FORGOT_PASS_INFO, forgotPassInfo)

    return Auth.forgotPassword(username)
      .then(res => {
        // Route to homepage after successful login

        if (onSuccess) {
          onSuccess(res)
        }

        if (redirect === true) {
          router.push({
            name: 'new-password',
          })
        }
      })
      .catch(err => {
        const handleErr = msg => {
          commit('SET_AUTH_ERRORS', msg)
        }

        switch (err.code) {
          case AUTH_EXCEPTIONS.userNotFoundException:
            handleErr('Cannot reset password for invalid email.')
            break
          case AUTH_EXCEPTIONS.limitExceededException:
            handleErr('Attempt limit exceeded, please try after some time.')
            break
          default:
            handleErr('Unable to reset password.')
        }
      })
      .finally(() => commit('SET_SUBMITTING', false))
  },

  // NEW PASSWORD
  // Request new password
  forgotPasswordSubmit(
    { commit, state },
    { code, password, onSuccess = null }
  ) {
    commit('SET_SUBMITTING', true)

    const DEFAULT_ERROR_MSG = 'Unable to reset password.'
    const username =
      state?.forgotPasswordInfo?.username ||
      getSavedState(LOCAL_STORE_KEYS.FORGOT_PASS_INFO)?.username

    Auth.forgotPasswordSubmit(username, code, password)
      .then(res => {
        commit('SET_FORGOT_PASSWORD_INFO', null)

        saveState(LOCAL_STORE_KEYS.FORGOT_PASS_INFO, null)

        if (onSuccess) {
          onSuccess(res)
        }

        router.push({
          path: '/login',
          params: { redirectedFromForgotPasswordSuccess: true },
        })
      })
      .catch(err => {
        switch (err.code) {
          case AUTH_EXCEPTIONS.codeMismatchException:
            commit('SET_AUTH_ERRORS', 'Confirmation code is invalid.')
            break
          case AUTH_EXCEPTIONS.invalidPasswordException:
            commit('SET_AUTH_ERRORS', err)
            break
          case AUTH_EXCEPTIONS.expiredCodeException:
            commit('SET_AUTH_ERRORS', 'Confirmation code has expired.')
            break
          default:
            // some cognito error messages dont make sense to the end user
            // show a less confusing error message
            commit('SET_AUTH_ERRORS', {
              code: err.code,
              message: DEFAULT_ERROR_MSG,
            })
        }
      })
      .finally(() => commit('SET_SUBMITTING', false))
  },

  // SIGN UP
  // Submit sign up form
  signUp(
    { commit, dispatch, state },
    { accountType, company, email, phone, password }
  ) {
    commit('SET_SUBMITTING', true)

    return Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        // hard code phone to bypass cognito's phone requirement
        // the number +140123123123 is agreed with backend, do not change without confirm
        phone_number: '+140123123123'.replace(/[\s\-\(\)]/g, ''),
      },
      validationData: [], // optional
    })
      .then(data => {
        saveState(LOCAL_STORE_KEYS.SIGNUP_CONFIRM_ID, data.userSub)
        saveState(LOCAL_STORE_KEYS.SIGNUP_EMAIL, email)
        return data
      })
      .catch(err => {
        switch (err.code) {
          case AUTH_EXCEPTIONS.usernameExistsException:
          case AUTH_EXCEPTIONS.invalidParameterException:
            commit('SET_AUTH_ERRORS', err)
            break
          case AUTH_EXCEPTIONS.UserLambdaValidationException:
            if (
              // This is an very peticular case caused by disposable email
              // we can only get string type error from backend so
              err.message ===
              'PreSignUp failed with error invalid email address.'
            ) {
              const error = new Error('Invalid email address.')
              error.code = AUTH_EXCEPTIONS.invalidEmail

              throw error
            } else {
              generalErrorHandle(err)
            }

            break
          default:
            generalErrorHandle(err)
        }

        throw err

        function generalErrorHandle(err) {
          commit('SET_AUTH_ERRORS', {
            code: err.code,
            message: 'Unable to sign up.',
          })
        }
      })
      .finally(() => commit('SET_SUBMITTING', false))
  },

  setSignupConfirmId({ commit }, { id }) {
    commit('SET_SUBMITTING', true)
    saveState(LOCAL_STORE_KEYS.SIGNUP_CONFIRM_ID, id)
    commit('SET_SUBMITTING', false)
  },

  // CONFIRM SIGNUP CODE
  confirmSignup({ commit }, { code }) {
    let confirmId = getSavedState(LOCAL_STORE_KEYS.SIGNUP_CONFIRM_ID)

    commit('SET_SUBMITTING', true)

    return Auth.confirmSignUp(confirmId, code, {
      // Optional. Force user confirmation irrespective of
      // existing alias. By default set to True.
      forceAliasCreation: false,
    })
      .catch(err => {
        switch (err.code) {
          case AUTH_EXCEPTIONS.codeMismatchException:
            commit('SET_AUTH_ERRORS', err)
            break
          default:
            commit('SET_AUTH_ERRORS', {
              code: err.code,
              message: 'Unable to verify code.',
            })
        }

        throw err
      })
      .finally(() => commit('SET_SUBMITTING', false))
  },

  // RESEND CODE
  resendCode() {
    let confirmId = getSavedState(LOCAL_STORE_KEYS.SIGNUP_CONFIRM_ID)

    return Auth.resendSignUp(confirmId)
      .then(data => data)
      .catch(err => console.warn(err))
  },

  // Set Password when a user is created via Super Admin
  // Using to() structure to return data
  // Return Array [
  //    String - error
  //    Bool - Object
  // ]
  async setPassword(_, { username, oldPassword, password }) {
    const [err, res] = await to(
      Auth.signIn({
        username, // Required, the username
        password: oldPassword, // Optional, the password
      })
    )
    // res === cognito user object
    if (res && !err) {
      const { authenticationFlowType } = res
      if (authenticationFlowType === 'USER_SRP_AUTH') {
        const [err2, res2] = await to(
          Auth.changePassword(
            res, // the Cognito User Object
            oldPassword, // the OLD password
            password // the NEW password,
          )
        )
        return [err2, res2]
      }
    }
    return [err, res]
  },

  setSsoLoading({ commit }, loading) {
    commit('SET_SSO_LOADING', loading)
  },
}

// ===
// Private helpers
// ===

function getCurrentUserFromLocal() {
  const val = getSavedState(LOCAL_STORE_KEYS.USER_ID)

  const isString = typeof val === 'string' && /[\w\d-]/g.test(val)

  if (isString) {
    saveState(LOCAL_STORE_KEYS.USER_ID, null)

    return null
  }

  return val
}

function getSavedState(key) {
  try {
    const value = window.localStorage.getItem(key)

    return JSON.parse(value)
  } catch (e) {
    console.error(e)

    return null
  }
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}

function preserveLocalStorageKeys() {
  let keys = []
  forIn(window.localStorage, (value, objKey) => {
    keys.push(objKey)
  })
  return keys
}

function deleteLocalStorageKeys(array) {
  forIn(window.localStorage, (value, objKey) => {
    if (array.includes(objKey)) {
      window.localStorage.removeItem(objKey)
    }
  })
}
