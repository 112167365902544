import * as apolloUtils from 'apollo-utilities'
import { filter as _filter } from 'graphql-anywhere'
import { deepEqual } from '@utils'

export const getOperationName = o => apolloUtils.getOperationName(o)
export const getMainDefinition = o => apolloUtils.getMainDefinition(o)

export const filter = (doc, data) => _filter(doc, data)

export const isBulkUpdate = variables => {
  if (!variables) return false
  const keys = Object.keys(variables)
  const firstProp = variables[keys[0]]
  if (!firstProp) return false

  // we assume if the first object prop is an array it is a bulk action
  // e.g. ids: [] or items: []
  // todo: check agaisnt graphql schema programatically
  const firstPropIsArray = Array.isArray(firstProp)
  const isBulk = firstPropIsArray && firstProp.length > 1

  return isBulk
}

export const deepEqualByDoc = (a, b, docOfTruth) => {
  const inputsSame = deepEqual(filter(docOfTruth, a), filter(docOfTruth, b), {
    strict: true,
  })

  if (!inputsSame) return true
  return false
}
