import gql from 'graphql-tag'

export const getCurrentUser = gql`
  query getCurrentUser {
    getCurrentUser {
      email
      firstName
      id
      lastName
      allowAccessToAllEntities

      roles {
        allowEarnings
        allowLiveData
        roleID
        roleName
        rolePermissions
      }

      org {
        active
        id
        status
        title
        type
        website
        hierarchy
      }
    }
  }
`
