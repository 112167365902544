<template>
  <!-- important: assume persistent if loading so users cant cancel the  UI to interrupt processing (e.g. payment) -->

  <v-dialog
    v-bind="propsPenetrating"
    :width="width"
    :scrollable="scrollable"
    :persistent="loading ? true : persistent"
    :overlay-color="globalColorBlack"
    :overlay-opacity="0.9"
  >
    <!--  v-bem="{}" must be a child of v-dialog. V-dialog sometimes chops off the v-bem parent
    from its children, breaking the BEM system, e.g. when using states and mods  -->
    <div v-bem="{ loading, centerActions, error, warning }">
      <v-card v-bem:card>
        <v-card-title>
          <h3 v-bem:title>
            {{ computeTitle }}
          </h3>
        </v-card-title>

        <div v-if="loading" v-bem:loader>
          <v-progress-circular
            size="30"
            width="3"
            class="fp-dialog__spinner"
            indeterminate
            color="inherit"
          />

          <v-card-text v-if="loading && !!$slots.loaderContent">
            <div v-bem:content class="text-center pt-5">
              <slot name="loaderContent" />
            </div>
          </v-card-text>
        </div>

        <div v-if="closeable" v-bem:close>
          <fp-icon-button
            icon="times"
            tooltip="Close dialog"
            @click="emitClose"
          />
        </div>

        <v-card-text v-if="!loading">
          <div v-bem:content>
            <slot name="content" />
          </div>
        </v-card-text>

        <v-card-actions v-if="!loading">
          <div v-bem:actions>
            <slot name="actions" />
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import { globalColorBlack } from '@scss-ts'

export default {
  name: 'FpDialog',
  inheritAttrs: false,
  props: {
    value: Boolean,

    title: {
      default: '',
      type: String,
    },
    width: {
      default: '560',
      type: String,
    },
    scrollable: {
      default: false,
      type: Boolean,
    },
    persistent: {
      default: true,
      type: Boolean,
    },

    closeable: Boolean,
    // States
    loading: Boolean,
    error: Boolean,
    warning: Boolean,
    centerActions: Boolean,
  },
  data() {
    return {
      globalColorBlack,
    }
  },
  computed: {
    propsPenetrating() {
      const { $attrs, value } = this

      return { ...$attrs, value }
    },
    computeTitle() {
      if (this.error && !this.title) return 'Oops, an error occurred.'
      return this.title
    },
  },

  methods: {
    emitClose() {
      this.$emit('input', false)
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" src="@component-styles/dialog"></style>
