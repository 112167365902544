import { createComponent } from '@vue/composition-api'

export default createComponent({
  name: 'SVGGlobeThreeOrbs',
  setup() {
    return () => {
      return (
        <svg
          width="80"
          height="82"
          viewBox="0 0 80 82"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M30.0273 49.8828C24.459 48.5518 21 46.2979 21 44C21 41.7021 24.459 39.4482 30.0273 38.1172L31.4014 37.7886L31.2539 39.1943C30.9154 42.3891 30.9154 45.6108 31.2539 48.8057L31.4014 50.2114L30.0273 49.8828Z"
            fill="#90CAF9"
          />
          <path
            d="M48.7461 48.8057C49.0847 45.6108 49.0847 42.3891 48.7461 39.1943L48.5986 37.7886L49.9727 38.1172C55.541 39.4482 59 41.7021 59 44C59 46.2979 55.541 48.5518 49.9727 49.8828L48.5986 50.2114L48.7461 48.8057Z"
            fill="#90CAF9"
          />
          <path
            d="M40 63C37.7021 63 35.4482 59.541 34.1172 53.9727L33.7891 52.5981L35.1943 52.7456C38.3891 53.0848 41.6108 53.0848 44.8057 52.7456L46.2109 52.5981L45.8828 53.9727C44.5518 59.541 42.2979 63 40 63Z"
            fill="#90CAF9"
          />
          <path
            d="M44.8057 35.2544C41.6108 34.9152 38.3891 34.9152 35.1943 35.2544L33.7891 35.4019L34.1172 34.0273C35.4482 28.459 37.7021 25 40 25C42.2979 25 44.5518 28.459 45.8828 34.0273L46.2109 35.4019L44.8057 35.2544Z"
            fill="#90CAF9"
          />
          <path
            d="M22.3809 36.9683C23.342 34.5819 24.7751 32.4142 26.5943 30.595C28.4135 28.7759 30.5814 27.3429 32.9678 26.3818L35.4395 25.3918L34.2305 27.7639C33.1121 30.0816 32.315 32.5409 31.8613 35.0739L31.7313 35.7316L31.0731 35.861C28.5402 36.3152 26.0811 37.1125 23.7636 38.231L21.3916 39.44L22.3809 36.9683Z"
            fill="#42A5F5"
          />
          <path
            d="M32.9678 61.6182C30.5814 60.6572 28.4135 59.2242 26.5943 57.4051C24.7751 55.5859 23.342 53.4182 22.3809 51.0317L21.3916 48.5605L23.7637 49.769C26.0813 50.8876 28.5406 51.6849 31.0737 52.139L31.7319 52.2684L31.8619 52.9261C32.3156 55.4592 33.1127 57.9185 34.2311 60.2361L35.4401 62.6082L32.9678 61.6182Z"
            fill="#42A5F5"
          />
          <path
            d="M45.77 60.2359C46.8884 57.9184 47.6855 55.4593 48.1392 52.9264L48.2692 52.2687L48.9274 52.1393C51.4605 51.6852 53.9198 50.8878 56.2374 49.7693L58.6095 48.5608L57.6202 51.032C56.659 53.4185 55.2257 55.5863 53.4063 57.4054C51.5869 59.2245 49.4189 60.6574 47.0322 61.6183L44.5605 62.6083L45.77 60.2359Z"
            fill="#42A5F5"
          />
          <path
            d="M56.2363 38.231C53.9189 37.1125 51.4597 36.3151 48.9268 35.861L48.2686 35.7316L48.1386 35.0739C47.6851 32.541 46.8882 30.0817 45.77 27.7642L44.561 25.3921L47.0327 26.3821C49.4191 27.3431 51.5868 28.7762 53.4059 30.5952C55.225 32.4143 56.658 34.582 57.6191 36.9683L58.6084 39.4395L56.2363 38.231Z"
            fill="#42A5F5"
          />
          <path
            d="M40 51C38.0753 50.996 36.1528 50.8698 34.2441 50.6221L33.4756 50.5244L33.3779 49.7554C32.874 45.935 32.874 42.065 33.3779 38.2446L33.4756 37.4756L34.2441 37.3779C38.0648 36.874 41.9352 36.874 45.7559 37.3779L46.5244 37.4756L46.6221 38.2446C47.126 42.065 47.126 45.935 46.6221 49.7554L46.5244 50.5244L45.7559 50.6221C43.8472 50.8698 41.9247 50.996 40 51Z"
            fill="#42A5F5"
          />
          <path
            d="M8.28027 69C11.0417 69 13.2803 66.7614 13.2803 64C13.2803 61.2386 11.0417 59 8.28027 59C5.51885 59 3.28027 61.2386 3.28027 64C3.28027 66.7614 5.51885 69 8.28027 69Z"
            fill="#42A5F5"
          />
          <path
            d="M71.7197 69C74.4812 69 76.7197 66.7614 76.7197 64C76.7197 61.2386 74.4812 59 71.7197 59C68.9583 59 66.7197 61.2386 66.7197 64C66.7197 66.7614 68.9583 69 71.7197 69Z"
            fill="#42A5F5"
          />
          <path
            d="M40 13C42.7614 13 45 10.7614 45 8C45 5.23858 42.7614 3 40 3C37.2386 3 35 5.23858 35 8C35 10.7614 37.2386 13 40 13Z"
            fill="#42A5F5"
          />
          <path
            d="M8.28003 56C6.69778 56 5.15106 56.4692 3.83547 57.3482C2.51988 58.2273 1.4945 59.4767 0.888997 60.9385C0.283496 62.4003 0.125069 64.0089 0.433751 65.5607C0.742432 67.1126 1.50436 68.538 2.62318 69.6569C3.742 70.7757 5.16746 71.5376 6.71931 71.8463C8.27116 72.155 9.87969 71.9965 11.3415 71.391C12.8033 70.7855 14.0527 69.7602 14.9318 68.4446C15.8108 67.129 16.28 65.5823 16.28 64C16.2772 61.8791 15.4335 59.8459 13.9338 58.3462C12.4341 56.8466 10.4009 56.0028 8.28003 56ZM8.28003 68C7.48891 68 6.71555 67.7654 6.05775 67.3259C5.39996 66.8864 4.88727 66.2616 4.58452 65.5307C4.28176 64.7998 4.20255 63.9956 4.35689 63.2196C4.51123 62.4437 4.8922 61.731 5.45161 61.1716C6.01102 60.6122 6.72375 60.2312 7.49967 60.0769C8.2756 59.9225 9.07986 60.0017 9.81077 60.3045C10.5417 60.6072 11.1664 61.1199 11.6059 61.7777C12.0454 62.4355 12.28 63.2089 12.28 64C12.2802 64.5253 12.1768 65.0455 11.9758 65.5309C11.7749 66.0163 11.4802 66.4573 11.1088 66.8288C10.7373 67.2002 10.2963 67.4948 9.81094 67.6958C9.32557 67.8968 8.80536 68.0001 8.28003 68Z"
            fill="#1976D2"
          />
          <path
            d="M71.72 56C70.1377 56 68.591 56.4692 67.2754 57.3482C65.9598 58.2273 64.9344 59.4767 64.3289 60.9385C63.7234 62.4003 63.565 64.0089 63.8737 65.5607C64.1824 67.1126 64.9443 68.538 66.0631 69.6569C67.1819 70.7757 68.6074 71.5376 70.1593 71.8463C71.7111 72.155 73.3196 71.9965 74.7814 71.391C76.2433 70.7855 77.4927 69.7602 78.3717 68.4446C79.2508 67.129 79.72 65.5823 79.72 64C79.7172 61.8791 78.8734 59.8459 77.3737 58.3462C75.874 56.8466 73.8408 56.0028 71.72 56ZM71.72 68C70.9289 68 70.1555 67.7654 69.4977 67.3259C68.8399 66.8864 68.3272 66.2616 68.0245 65.5307C67.7217 64.7998 67.6425 63.9956 67.7968 63.2196C67.9512 62.4437 68.3321 61.731 68.8915 61.1716C69.451 60.6122 70.1637 60.2312 70.9396 60.0769C71.7155 59.9225 72.5198 60.0017 73.2507 60.3045C73.9816 60.6072 74.6063 61.1199 75.0458 61.7777C75.4854 62.4355 75.72 63.2089 75.72 64C75.7186 65.0604 75.2967 66.077 74.5468 66.8269C73.797 67.5767 72.7804 67.9986 71.72 68Z"
            fill="#1976D2"
          />
          <path
            d="M18 44C18 48.3512 19.2903 52.6047 21.7077 56.2225C24.1251 59.8404 27.561 62.6602 31.581 64.3253C35.6009 65.9905 40.0244 66.4262 44.292 65.5773C48.5596 64.7284 52.4796 62.6331 55.5564 59.5564C58.6331 56.4796 60.7284 52.5596 61.5773 48.292C62.4262 44.0244 61.9905 39.6009 60.3253 35.581C58.6602 31.561 55.8404 28.1251 52.2225 25.7077C48.6047 23.2903 44.3512 22 40 22C34.1673 22.0069 28.5756 24.3269 24.4513 28.4513C20.3269 32.5756 18.0069 38.1673 18 44ZM22 44C22 42.39 25.07 40.33 30.26 39.09C29.9133 42.3542 29.9133 45.6458 30.26 48.91C25.07 47.67 22 45.61 22 44ZM23.31 50.66C25.7107 51.818 28.2572 52.6456 30.88 53.12C31.3544 55.7428 32.182 58.2893 33.34 60.69C31.077 59.7829 29.0214 58.4264 27.2975 56.7025C25.5736 54.9786 24.2171 52.923 23.31 50.66ZM30.88 34.88C28.2572 35.3544 25.7107 36.182 23.31 37.34C24.2171 35.077 25.5736 33.0214 27.2975 31.2975C29.0214 29.5736 31.077 28.2171 33.34 27.31C32.182 29.7107 31.3544 32.2572 30.88 34.88ZM40 62C38.39 62 36.33 58.93 35.09 53.74C38.3542 54.0867 41.6458 54.0867 44.91 53.74C43.67 58.93 41.61 62 40 62ZM58 44C58 45.61 54.93 47.67 49.74 48.91C50.0867 45.6458 50.0867 42.3542 49.74 39.09C54.93 40.33 58 42.39 58 44ZM56.69 37.34C54.2893 36.182 51.7428 35.3544 49.12 34.88C48.6456 32.2572 47.818 29.7107 46.66 27.31C48.923 28.2171 50.9786 29.5736 52.7025 31.2975C54.4264 33.0214 55.7829 35.077 56.69 37.34ZM49.12 53.12C51.7428 52.6456 54.2893 51.818 56.69 50.66C55.7829 52.923 54.4264 54.9786 52.7025 56.7025C50.9786 58.4264 48.923 59.7829 46.66 60.69C47.818 58.2893 48.6456 55.7428 49.12 53.12ZM46 44C45.9963 45.8826 45.8727 47.7631 45.63 49.63C41.8929 50.1233 38.1071 50.1233 34.37 49.63C33.8767 45.8929 33.8767 42.1071 34.37 38.37C38.1071 37.8767 41.8929 37.8767 45.63 38.37C45.8727 40.2369 45.9963 42.1174 46 44ZM44.91 34.26C41.6458 33.9133 38.3542 33.9133 35.09 34.26C36.33 29.07 38.39 26 40 26C41.61 26 43.67 29.07 44.91 34.26Z"
            fill="#1976D2"
          />
          <path
            d="M40 16C41.5823 16 43.129 15.5308 44.4446 14.6518C45.7602 13.7727 46.7855 12.5233 47.391 11.0615C47.9965 9.59966 48.155 7.99113 47.8463 6.43928C47.5376 4.88743 46.7757 3.46197 45.6569 2.34315C44.538 1.22433 43.1126 0.462403 41.5607 0.153721C40.0089 -0.15496 38.4003 0.00346625 36.9385 0.608967C35.4767 1.21447 34.2273 2.23985 33.3482 3.55544C32.4692 4.87103 32 6.41775 32 8C32.0028 10.1209 32.8466 12.1541 34.3462 13.6538C35.8459 15.1534 37.8791 15.9972 40 16ZM40 4C40.7911 4 41.5645 4.2346 42.2223 4.67413C42.8801 5.11365 43.3928 5.73836 43.6955 6.46927C43.9983 7.20017 44.0775 8.00444 43.9231 8.78036C43.7688 9.55629 43.3878 10.269 42.8284 10.8284C42.269 11.3878 41.5563 11.7688 40.7804 11.9231C40.0044 12.0775 39.2002 11.9983 38.4693 11.6955C37.7384 11.3928 37.1136 10.8801 36.6741 10.2223C36.2346 9.56449 36 8.79113 36 8C35.9999 7.47468 36.1032 6.95447 36.3042 6.4691C36.5052 5.98373 36.7998 5.54272 37.1712 5.17125C37.5427 4.79979 37.9837 4.50516 38.4691 4.30419C38.9545 4.10322 39.4747 3.99986 40 4Z"
            fill="#1976D2"
          />
          <path
            d="M28.1211 9.95894C27.9181 9.46972 27.5295 9.08092 27.0404 8.87773C26.5513 8.67454 26.0016 8.67354 25.5117 8.87494C17.4494 12.1694 10.7713 18.1458 6.60571 25.7945C2.4401 33.4432 1.04228 42.2953 2.6484 50.8554C2.73336 51.3149 2.97627 51.7301 3.3351 52.0294C3.69392 52.3286 4.14608 52.493 4.6133 52.494C4.73518 52.4946 4.85684 52.4835 4.9766 52.4608C5.49867 52.3654 5.96148 52.0665 6.26326 51.63C6.56504 51.1934 6.68109 50.6549 6.5859 50.1327C5.14053 42.4738 6.3867 34.5508 10.1134 27.7054C13.84 20.86 19.8181 15.513 27.0352 12.5699C27.2782 12.4701 27.4991 12.3233 27.6853 12.138C27.8714 11.9526 28.0192 11.7324 28.1201 11.4898C28.221 11.2473 28.273 10.9872 28.2732 10.7245C28.2733 10.4618 28.2217 10.2016 28.1211 9.95894Z"
            fill="#1976D2"
          />
          <path
            d="M73.4141 50.1328C73.3189 50.6549 73.4349 51.1935 73.7367 51.63C74.0385 52.0666 74.5013 52.3655 75.0234 52.4609C75.1431 52.4836 75.2648 52.4947 75.3867 52.4941C75.8538 52.4927 76.3058 52.3282 76.6645 52.029C77.0233 51.7298 77.2663 51.3148 77.3516 50.8555C78.9576 42.2955 77.5597 33.4435 73.3941 25.7949C69.2285 18.1463 62.5505 12.17 54.4883 8.87551C54.2447 8.76966 53.9824 8.71344 53.7168 8.71012C53.4513 8.7068 53.1877 8.75645 52.9415 8.85617C52.6953 8.95589 52.4715 9.10369 52.2831 9.29091C52.0947 9.47813 51.9455 9.70103 51.8443 9.94658C51.743 10.1921 51.6917 10.4554 51.6934 10.721C51.6951 10.9866 51.7497 11.2492 51.854 11.4934C51.9583 11.7377 52.1103 11.9587 52.301 12.1436C52.4917 12.3284 52.7174 12.4734 52.9648 12.57C60.1819 15.513 66.16 20.86 69.8866 27.7054C73.6133 34.5509 74.8595 42.4739 73.4141 50.1328Z"
            fill="#1976D2"
          />
          <path
            d="M59.9688 71.4747C54.1434 75.6398 47.1614 77.879 40.0001 77.879C32.8388 77.879 25.8568 75.6398 20.0313 71.4747C19.8192 71.3168 19.5778 71.2025 19.3212 71.1385C19.0646 71.0745 18.7978 71.0621 18.5363 71.1019C18.2749 71.1417 18.0239 71.233 17.798 71.3705C17.572 71.508 17.3756 71.689 17.2201 71.9029C17.0646 72.1168 16.953 72.3595 16.892 72.6168C16.8309 72.8741 16.8214 73.141 16.8642 73.402C16.907 73.663 17.0011 73.9129 17.1412 74.1373C17.2812 74.3617 17.4644 74.556 17.68 74.7091C24.1578 79.4466 31.975 82.0001 40.0003 82.0001C48.0257 82.0001 55.8428 79.4466 62.3206 74.7091C62.533 74.5547 62.7129 74.36 62.85 74.1361C62.9871 73.9121 63.0788 73.6634 63.1198 73.4041C63.1608 73.1447 63.1503 72.8798 63.0889 72.6245C63.0275 72.3692 62.9165 72.1285 62.7621 71.9162C62.6077 71.7038 62.413 71.5239 62.189 71.3868C61.9651 71.2497 61.7164 71.158 61.457 71.117C61.1977 71.076 60.9328 71.0865 60.6775 71.1479C60.4222 71.2092 60.1815 71.3203 59.9691 71.4747H59.9688Z"
            fill="#1976D2"
          />
        </svg>
      )
    }
  },
})
