<script>
import { convertToUnit, formatCentsToDollars, formatCurrency } from '@utils'
import FpTableCell from '@components/_base/table/table-cell.tsx'
import FpTableRowSkeleton from '@components/_base/table/row-skeleton.vue'
import { handleSort, defaultSortData } from '@components/_base/table/api'
import { noop } from '@shared/function'
import { globalColorMidIce } from '@scss-ts'
import { sideScroll } from '@shared/ui/dom'

function getColsSettingWidth(cols) {
  if (!cols.length) return 0

  return cols.reduce((total, cur) => {
    return cur.width + total
  }, 0)
}

export default {
  name: 'FpTable',

  components: { FpTableCell, FpTableRowSkeleton },

  props: {
    // Will defaultly insert a checkbox column if true
    selectable: { type: Boolean, default: true },

    // Enable expand slot if true
    expandable: {
      type: Boolean,
      default: false,
    },
    // Hide border if true
    borderFree: Boolean,

    rowAlignTop: Boolean,

    // Not emit('row-click) if true
    rowClickable: { type: Boolean, default: true },

    // Show skeleton if true
    loading: Boolean,
    // Will remove header if true
    hideHeader: Boolean,

    hideFooter: Boolean,

    // Enable sort functionality if true
    sortable: Boolean,
    sortBy: {
      type: Array,
      default: () => [],
    },

    // If total === 0, the Pagination will be hidden
    total: {
      type: Number,
      default: 0,
    },

    totalAmount: {
      type: Number,
      default: null,
    },

    perPage: {
      type: Number,
      default: 10,
    },

    page: {
      type: Number,
      default: 1,
    },

    // * Data source
    data: {
      type: Array,
      default: () => [],
    },

    // * The field on each item object that designates a unique key.
    // * The value of this property has to be unique for each item.
    itemKey: {
      type: String,
      default: 'id',
    },

    // Text shown when no items are provided to the component
    noDataText: {
      type: String,
      default: 'No data available for display.',
    },

    selection: {
      type: Array,
      default: () => [],
    },

    columns: {
      // 👋 for type details, see type ColConfig inside ./tabel-cell.tsx
      type: Array,
      default: () => [],
    },

    rowHoverBackground: {
      type: String,
      default: globalColorMidIce,
    },
  },

  data() {
    return {
      expanded: [],
      transitioned: [],
      closeTimeouts: {},
      /** @type {Array<boolean>} */
      rowSelectedReflection: [],
      rowExpandedReflection: {},
      rowLoadingReflection: [],
      currentSortData: { ...defaultSortData },
      dataSorted: [],
      sortIcons: {
        desc: 'caret-down',
        asc: 'caret-up',
      },

      // A vertical line will show if below 3 value is true
      // * The line is for distinguish between fixed cols and normal cols
      tableScrollable: false,
      tableScrolled2Left: false,
      tableScrolled2Right: false,

      scrollEle: null,

      uuid: Math.random().toString(16).slice(8),
    }
  },

  computed: {
    fTotalAmount() {
      return formatCurrency(formatCentsToDollars(this.totalAmount), 2)
    },

    onePage() {
      return this.pageCount === 1
    },

    rowSkeletonsSet() {
      return Array(this.perPage)
        .fill(false)
        .map(_ => Math.random().toString(16))
    },

    computedItems() {
      const rv = this.dataSorted.map((item, index) => {
        return {
          selected: this.rowSelectedReflection[index] || false,
          loading: this.rowLoadingReflection[index] || false,
          expanded: this.rowExpandedReflection[item[this.itemKey]] || false,
          ...item,
        }
      })

      return rv
    },

    selectedItems() {
      return this.computedItems.filter((item, index) => {
        if (item.selected) {
          return item
        }
      })
    },

    // Fixed col stuffs start
    unfixedCols() {
      return this.columns.filter(col => {
        const fixedButNoWidth = col.fixed && !col.width
        if (fixedButNoWidth) {
          console.warn(
            `🚨 [FpTable]: column '${col.value}' been config as fixed, but without width, that's not cool.`
          )
        }
        return !col.fixed || fixedButNoWidth
      })
    },

    // !! 1. DO NOT USE { fixPosition: 'left' } AT THE MEMENT !!!
    // !! 2. DO NOT USE string-width like { width: '100px' | '15%' } it will destory the fixedColsWidth Calcation
    // TODO: left cols fixed with checkbox has some style issues at the moment
    fixedLeftCols() {
      const { columns } = this

      const filtered = columns.filter(
        col => col.fixed && col.fixPosition === 'left' && col.width
      )

      if (!filtered.length) return []

      return filtered.reduce((rst, now) => {
        const offset = convertToUnit(rst.reduce((a, b) => a + b.width, 50))

        return [...rst, { ...now, offset, fixPosition: 'left' }]
      }, [])
    },

    fixedRightCols() {
      const { columns } = this

      const filtered = columns.filter(
        col => col.fixed && col.fixPosition !== 'left' && col.width
      )

      if (!filtered.length) return []

      const out = filtered
        .reverse()
        .reduce((rst, now) => {
          const offset = convertToUnit(rst.reduce((a, b) => a + b.width, 0))

          return [...rst, { ...now, offset, fixPosition: 'right' }]
        }, [])
        .reverse()

      // help ot draw edge line
      out[0].__edge = true

      return out
    },

    fixedColWidth() {
      const { hasLeftFixedCol, fixedLeftCols, fixedRightCols } = this
      return {
        left: convertToUnit(
          getColsSettingWidth(fixedLeftCols) + (hasLeftFixedCol ? 50 : 0)
        ),
        right: convertToUnit(getColsSettingWidth(fixedRightCols)),
      }
    },

    hasLeftFixedCol() {
      return !!this.fixedLeftCols.length
    },

    hasFixedCol() {
      return !!this.hasLeftFixedCol || !!this.fixedRightCols.length
    },

    showFixedColsEdge() {
      return this.tableScrollable && !this.tableScrolled2Right
    },

    // Fixed col stuffs end

    computedCols() {
      const { unfixedCols, fixedLeftCols, fixedRightCols } = this
      return [...fixedLeftCols, ...unfixedCols, ...fixedRightCols]
    },

    computedHeaders() {
      const { computedCols, sortable, sortBy } = this

      if (sortable === undefined) {
        return computedCols
      }

      const enableSortBy = !!sortBy.length

      const out = computedCols
        .map(col =>
          Object.assign(
            {
              sortable: enableSortBy ? sortBy.includes(col.value) : sortable,
            },
            col
          )
        )
        .filter(o => !!o && !!o.value)

      return out
    },

    mainCheckboxProps() {
      let selectedCount = 0

      this.rowSelectedReflection.forEach((selected, row) => {
        selected && selectedCount++
      })

      const overallCount = this.data.length

      let value = false
      let indeterminate = false

      if (overallCount !== 0) {
        if (selectedCount === overallCount) {
          value = true
        } else if (selectedCount >= 1) {
          indeterminate = true
        }
      }

      return {
        value,
        indeterminate,
      }
    },

    showFooter() {
      if (this.hideFooter) return false
      return this.total > 0 || this.$slots['batch-actions']
    },

    pageCount() {
      const { total, perPage } = this
      let count = Math.ceil(total / perPage)
      return count
    },

    isMobile() {
      return this.$vuetify.breakpoint.xs
    },
  },

  watch: {
    data: {
      handler: function (v) {
        this.resetSelection()
        this.currentSortData = { ...defaultSortData }
      },
      deep: true,
      immediate: true,
    },

    loading: {
      handler: function (v) {
        if (!v) {
          this.$nextTick(() => {
            this.checkTableIsScrolled()
            this.checkTableScrollable()
          })
        }
      },
      immediate: true,
    },
  },

  created() {
    if (this.data.length) {
      this.resetSelection()
    }

    // When selection changed, sync the selection prop data
    this.$on('selection-change', () => {
      this.$emit('update:selection', this.selectedItems)
    })
  },

  mounted() {
    if (this.hasFixedCol) {
      this.scrollEle = this.$el.querySelector('.v-data-table__wrapper')

      this.scrollEle.addEventListener('scroll', this.checkTableIsScrolled)

      window.addEventListener('resize', this.checkTableScrollable)
    }
  },

  beforeDestroy() {
    if (this.hasFixedCol && this.scrollEle) {
      this.scrollEle.removeEventListener('scroll', this.checkTableIsScrolled)

      window.removeEventListener('resize', this.checkTableScrollable)
    }
  },

  methods: {
    convertToUnit,

    getRowIndex(item) {
      return this.computedItems.indexOf(item)
    },

    isSelected(item) {
      return this.rowSelectedReflection[this.getRowIndex(item)]
    },

    resetSelection() {
      const tmpSelection = []
      this.data.forEach((_, index) => {
        tmpSelection.push(false)
      })
      this.$set(this, 'rowSelectedReflection', tmpSelection)

      this.dataSorted = [...this.data]
    },

    onMainSelectChanged(checked) {
      const newSelectionValue = Array(this.rowSelectedReflection.length).fill(
        checked
      )

      this.$set(this, 'rowSelectedReflection', newSelectionValue)

      this.$emit('selection-change', this.selectedItems)
    },

    onRowSelectChanged(item, checked) {
      const index = this.getRowIndex(item)

      if (this.computedItems[index].loading) return

      this.$set(this.rowSelectedReflection, index, checked)

      this.$emit('selection-change', this.selectedItems)
    },

    onRowClick({ target, srcElement }, item) {
      if (!this.rowClickable) return
      if (this.isMobile) return
      if (srcElement.href) return
      if (item) {
        const index = this.getRowIndex(item)

        if (this.computedItems[index]?.loading) return
      }

      this.$emit('row-click', { item })
    },

    onSortClick(col) {
      this.dataSorted = handleSort(
        col,
        this.currentSortData,
        this.data,
        this.customSort
      )
    },

    getLoadingToggler(item) {
      const rowIdx = this.getRowIndex(item)

      return val => {
        const curVal = this.computedItems[rowIdx].loading

        const nextVal = val || !curVal

        this.$set(this.rowLoadingReflection, rowIdx, nextVal)
      }
    },

    getExpandToggler(item) {
      if (!this.expandable) return noop

      const toggler = value => {
        const vTableRef = this.$refs.table
        vTableRef.expand(
          item,
          typeof value === 'boolean'
            ? value
            : !vTableRef.expanded[item[this.itemKey]]
        )
      }

      return toggler
    },

    checkTableIsScrolled() {
      if (!this.scrollEle) return

      const { scrollLeft, scrollWidth, offsetWidth } = this.scrollEle

      this.tableScrolled2Left = scrollLeft === 0

      this.tableScrolled2Right = scrollWidth === scrollLeft + offsetWidth
    },

    checkTableScrollable() {
      if (!this.scrollEle) return

      const { scrollWidth, offsetWidth } = this.scrollEle

      this.tableScrollable = scrollWidth > offsetWidth
    },

    syncExpansion(expanded) {
      const expansion = expanded.reduce((expansion, item) => {
        expansion[item[this.itemKey]] = true
        return expansion
      }, {})
      this.$set(this, 'rowExpandedReflection', expansion)
    },

    handleTableScroll(direction) {
      sideScroll(this.scrollEle, direction, 25, 200, 20)
    },

    getItemId(item) {
      return item.id
    },
    toggleExpand(props) {
      if (!this.expandable) return
      const item = props.item
      const id = props.item.id
      if (props.isExpanded && this.transitioned[id]) {
        this.closeExpand(item)
      } else {
        clearTimeout(this.closeTimeouts[id])
        props.expand(true)
        this.$nextTick(() => this.$set(this.transitioned, id, true))
        this.$nextTick(() =>
          this.expanded.forEach(i => i !== item && this.closeExpand(i))
        )
      }
    },
    closeExpand(item) {
      const id = this.getItemId(item)
      this.$set(this.transitioned, id, false)
      this.closeTimeouts[id] = setTimeout(
        () => this.$refs.table.expand(item, false),
        200
      )
    },
  },
}
</script>

<template>
  <div
    v-bem="{
      selectable,
      borderFree,
      loading,
      onePage,
      rowClickable,
      showEdge: showFixedColsEdge,
    }"
    :style="{ '--rowHoverBackground': rowHoverBackground }"
  >
    <div
      v-if="!isMobile && tableScrollable && !tableScrolled2Left && !loading"
      v-bem:scroll-btn
      @click="handleTableScroll('left')"
    >
      <fp-icon name="arrow-left" />
    </div>

    <div
      v-if="!isMobile && tableScrollable && !tableScrolled2Right && !loading"
      v-bem:scroll-btn.right
      :style="{ right: fixedColWidth.right }"
      @click="handleTableScroll('right')"
    >
      <fp-icon name="arrow-right" />
    </div>

    <v-data-table
      v-show="!loading"
      ref="table"
      key="table"
      :headers="[
        { text: '', value: 'checkbox-col', width: '50px' },
        ...computedHeaders,
      ]"
      :items-per-page="perPage"
      :items="computedItems"
      :item-key="itemKey"
      hide-default-header
      hide-default-footer
      :expanded.sync="expanded"
      :show-expand="expandable"
      :style="{
        marginLeft: isMobile ? null : fixedColWidth.left,
        marginRight: isMobile ? null : fixedColWidth.right,
      }"
      @update:expanded="syncExpansion"
    >
      <!-- HEADERS -->
      <template #header>
        <thead v-bem:head="{ hidden: hideHeader }">
          <tr>
            <th
              v-if="selectable"
              v-bem:th.checkbox="{ fixed: hasLeftFixedCol, $pos: 'fix-left' }"
              width="50px"
            >
              <fp-checkbox
                v-bind="mainCheckboxProps"
                light
                :disabled="loading"
                :use-label="false"
                @change="onMainSelectChanged"
              />
            </th>

            <th
              v-for="(col, index) in computedHeaders"
              :key="col.text + index"
              v-bem:th="{
                fixed: col.fixed && col.width,
                $pos: col.fixed ? `fix-${col.fixPosition}` : null,
                sortable: col.sortable,
                sorting: currentSortData.column === col.value,
                $align: col.align,
              }"
              :style="{
                width: convertToUnit(col.width),
                '--offset': col.fixed ? col.offset : 0,
              }"
              @click.stop="onSortClick(col)"
            >
              {{ col.text }}

              <fp-icon
                v-if="col.sortable"
                :name="
                  currentSortData.column === col.value
                    ? sortIcons[currentSortData.order]
                    : 'sort'
                "
              />
            </th>
          </tr>
        </thead>
      </template>

      <template #item="props">
        <tr
          v-if="!loading && !expandable"
          v-bem:row="{
            loading: props.item.loading,
            expanded: props.item.expanded,
            alignTop: rowAlignTop,
          }"
          tabindex="3"
          @keyup.enter="
            _ => onRowSelectChanged(props.item, !isSelected(props.item))
          "
          @click="e => onRowClick(e, props.item)"
        >
          <td
            v-if="selectable"
            v-bem:td.checkbox="{
              fixed: hasLeftFixedCol,
              $pos: 'fix-left',
            }"
            @click.stop
          >
            <div v-bem:cell>
              <fp-checkbox
                :value="isSelected(props.item)"
                light
                :disabled="props.item.loading"
                @change="checked => onRowSelectChanged(props.item, checked)"
              />
            </div>
          </td>

          <fp-table-cell
            v-for="(column, i) in computedCols"
            :key="`${uuid}-${column.value}-${props.index}-${i}`"
            :selected="isSelected(props.item)"
            :column-config="column"
            :item="props.item"
            :slots="$scopedSlots"
            :toggle-row-loading="getLoadingToggler(props.item)"
            :toggle-row-expand="getExpandToggler(props.item)"
            :row-expanded="props.item.expanded"
            :row-loading="props.item.loading"
            :item-index="props.index"
          />
        </tr>
        <!-- expandable row -->
        <tr
          v-if="!loading && expandable"
          v-bem:row="{
            loading: props.item.loading,
            expanded: props.item.expanded,
            alignTop: rowAlignTop,
          }"
          tabindex="3"
          @keyup.enter="
            _ => onRowSelectChanged(props.item, !isSelected(props.item))
          "
          @click="toggleExpand(props)"
        >
          <td
            v-if="selectable"
            v-bem:td.checkbox="{
              fixed: hasLeftFixedCol,
              $pos: 'fix-left',
            }"
            @click.stop
          >
            <div v-bem:cell>
              <fp-checkbox
                :value="isSelected(props.item)"
                light
                :disabled="props.item.loading"
                @change="checked => onRowSelectChanged(props.item, checked)"
              />
            </div>
          </td>

          <fp-table-cell
            v-for="(column, i) in computedCols"
            :key="`${uuid}-${column.value}-${props.index}-${i}`"
            :selected="isSelected(props.item)"
            :column-config="column"
            :item="props.item"
            :slots="$scopedSlots"
            :toggle-row-loading="getLoadingToggler(props.item)"
            :toggle-row-expand="getExpandToggler(props.item)"
            :row-expanded="props.item.expanded"
            :row-loading="props.item.loading"
            :item-index="props.index"
          />
        </tr>
        <!-- /expandable row -->
      </template>

      <!-- EXPAND AREA -->
      <template #expanded-item="{ headers, item }">
        <tr v-bem:row.expand-row>
          <td
            :colspan="headers.length"
            :class="{
              'ma-0 pa-0': true,
              'expanded-closing': !transitioned[getItemId(item)],
            }"
            style="height: auto"
          >
            <v-expand-transition>
              <div
                v-show="transitioned[getItemId(item)]"
                style="padding: 16px 0 46px"
              >
                <slot name="expanded-item" v-bind="{ item }" />
              </div>
            </v-expand-transition>
          </td>
        </tr>
      </template>

      <!-- NO DATA -->
      <template #no-data>
        <slot name="empty">
          <div v-bem:no-data>
            {{ noDataText }}
          </div>
        </slot>
      </template>
    </v-data-table>

    <!-- LOADING BODY -->
    <div
      v-if="loading && !isMobile"
      key="placeholder-desktop"
      v-bem:placeholder
    >
      <header v-bem:skeleton.thead>
        <fp-skeleton right-margin="20" circle no-animate width="22" />
        <fp-skeleton width="40" right-margin="270" no-animate height="16" />
        <fp-skeleton width="60" right-margin="50" no-animate height="16" />
        <fp-skeleton width="60" right-margin="50" no-animate height="16" />
        <fp-skeleton width="30" no-animate height="16" />
      </header>
      <fp-table-row-skeleton v-for="nonstr in rowSkeletonsSet" :key="nonstr" />
    </div>

    <div v-if="loading && isMobile" key="placeholder-mobile" v-bem:placeholder>
      <div v-bem:mobile-skeleton-card>
        <fp-skeleton
          width="30vw"
          height="20"
          no-animate
          top-margin="14"
          bottom-margin="14"
        />
        <div v-bem:mobile-skeleton-row>
          <fp-skeleton width="20vw" height="18" />
          <fp-skeleton width="40vw" height="20" />
        </div>
        <div v-bem:mobile-skeleton-row>
          <fp-skeleton width="12vw" height="18" />
          <fp-skeleton width="20vw" height="20" />
        </div>
        <div v-bem:mobile-skeleton-row>
          <fp-skeleton width="22vw" height="18" />
          <fp-skeleton width="50vw" height="20" />
        </div>
        <div v-bem:mobile-skeleton-row>
          <fp-skeleton width="18vw" height="18" />
          <fp-skeleton width="40vw" height="20" />
        </div>
        <div v-bem:mobile-skeleton-row>
          <fp-skeleton width="24vw" height="18" />
          <fp-skeleton width="30vw" height="20" />
        </div>
        <div v-bem:mobile-skeleton-row>
          <fp-skeleton width="18vw" height="18" />
          <fp-skeleton width="37vw" height="20" />
        </div>
      </div>
    </div>

    <div v-if="typeof totalAmount === 'number'" v-bem:total-amount>
      <div
        :class="['pt-8', 'pr-4', 'd-flex', 'justify-end']"
        data-test-id="table-total-amount"
      >
        <span class="pr-3">Total</span> {{ fTotalAmount }}
      </div>
    </div>

    <div v-if="showFooter" v-bem:footer>
      <div v-bem:footer-col.grow>
        <slot name="batch-actions" />
      </div>

      <v-pagination
        v-if="total > 0"
        :value="page"
        :total-visible="isMobile ? 5 : 7"
        :length="pageCount"
        @input="$emit('update:page', $event)"
      />
    </div>
  </div>
</template>

<style lang="scss" src="@component-styles/table"></style>
