import { library } from '@fortawesome/fontawesome-svg-core'

export const iconList = [
  'faAngleDown',
  'faAngleUp',
  'faArchive',
  'faArrowCircleDown',
  'faArrowCircleUp',
  'faArrowDown',
  'faArrowLeft',
  'faArrowRight',
  'faArrowUp',
  'faBan',
  'faBars',
  'faBell',
  'faBolt',
  'faBomb',
  'faBook',
  'faBoxes',
  'faBoxOpen',
  'faBroom',
  'faBuilding',
  'faCalendar',
  'faCalendarCheck',
  'faCaretDown',
  'faCaretUp',
  'faCertificate',
  'faChartArea',
  'faChartBar',
  'faCheck',
  'faCheckCircle',
  'faSms',
  'faCheckDouble',
  'faCheckSquare',
  'faChevronDown',
  'faChevronUp',
  'faChevronLeft',
  'faChevronRight',
  'faClock',
  'faClone',
  'faCloudUploadAlt',
  'faCodeBranch',
  'faCog',
  'faCoins',
  'faCopy',
  'faDesktop',
  'faDollarSign',
  'faDownload',
  'faDraftingCompass',
  'faEdit',
  'faEllipsisH',
  'faEnvelope',
  'faEnvelopeOpen',
  'faExclamation',
  'faExclamationCircle',
  'faExclamationTriangle',
  'faExternalLinkAlt',
  'faEye',
  'faEyeSlash',
  'faFan',
  'faFileAlt',
  'faFileDownload',
  'faFileImage',
  'faFileUpload',
  'faFileWord',
  'faFileVideo',
  'faFilePdf',
  'faFilter',
  'faFingerprint',
  'faGraduationCap',
  'faHourglassHalf',
  'faImage',
  'faInfo',
  'faInfoCircle',
  'faKey',
  'faList',
  'faLock',
  'faMapMarkedAlt',
  'faMinus',
  'faMinusSquare',
  'faMobileAlt',
  'faMoneyCheckAlt',
  'faPause',
  'faPaperPlane',
  'faPen',
  'faPercentage',
  'faPhone',
  'faPhoneAlt',
  'faPlay',
  'faPlayCircle',
  'faPlus',
  'faPlusCircle',
  'faPrint',
  'faQuestion',
  'faQuestionCircle',
  'faReceipt',
  'faRedo',
  'faRocket',
  'faScroll',
  'faSearch',
  'faShieldAlt',
  'faSignOutAlt',
  'faSortDown',
  'faSpinner',
  'faSquare',
  'faSync',
  'faTachometerAlt',
  'faTag',
  'faThumbsUp',
  'faThumbsDown',
  'faTimes',
  'faTimesCircle',
  'faTrash',
  'faUpload',
  'faUser',
  'faUserCircle',
  'faUsers',
  'faUserCheck',
  'faUserClock',
  'faUserTimes',
  'faUserEdit',
  'faVideo',
  'faVideoSlash',
  'faVolumeUp',
  'faWallet',
  'faCcMastercard',
  'faCcVisa',
  'faCcAmex',
  'faCcJcb',
  'faCreditCard',
  'faCcDinersClub',
  'faAngleRight',
  'faAngleLeft',
  'faCalendarDay',
  'faPencilAlt',
  'faEllipsisH',
  'faThumbsUp',
  'faThumbsDown',
  'faCode',
]

// https://fontawesome.com/icons
library.add(
  require('@fortawesome/free-solid-svg-icons/faAngleDown').definition,
  require('@fortawesome/free-solid-svg-icons/faAngleUp').definition,
  require('@fortawesome/free-solid-svg-icons/faArchive').definition,
  require('@fortawesome/free-solid-svg-icons/faArrowCircleDown').definition,
  require('@fortawesome/free-solid-svg-icons/faArrowCircleUp').definition,
  require('@fortawesome/free-solid-svg-icons/faArrowDown').definition,
  require('@fortawesome/free-solid-svg-icons/faArrowLeft').definition,
  require('@fortawesome/free-solid-svg-icons/faArrowRight').definition,
  require('@fortawesome/free-solid-svg-icons/faArrowUp').definition,
  require('@fortawesome/free-solid-svg-icons/faBan').definition,
  require('@fortawesome/free-solid-svg-icons/faBars').definition,
  require('@fortawesome/free-solid-svg-icons/faBell').definition,
  require('@fortawesome/free-solid-svg-icons/faBolt').definition,
  require('@fortawesome/free-solid-svg-icons/faBomb').definition,
  require('@fortawesome/free-solid-svg-icons/faBook').definition,
  require('@fortawesome/free-solid-svg-icons/faBoxes').definition,
  require('@fortawesome/free-solid-svg-icons/faBoxOpen').definition,
  require('@fortawesome/free-solid-svg-icons/faBroom').definition,
  require('@fortawesome/free-solid-svg-icons/faBuilding').definition,
  require('@fortawesome/free-regular-svg-icons/faCalendar').definition,
  require('@fortawesome/free-solid-svg-icons/faCalendarCheck').definition,
  require('@fortawesome/free-solid-svg-icons/faCaretDown').definition,
  require('@fortawesome/free-solid-svg-icons/faCaretUp').definition,
  require('@fortawesome/free-solid-svg-icons/faCertificate').definition,
  require('@fortawesome/free-solid-svg-icons/faChartArea').definition,
  require('@fortawesome/free-solid-svg-icons/faChartBar').definition,
  require('@fortawesome/free-solid-svg-icons/faCheck').definition,
  require('@fortawesome/free-solid-svg-icons/faCheckCircle').definition,
  require('@fortawesome/free-solid-svg-icons/faCheckDouble').definition,
  require('@fortawesome/free-solid-svg-icons/faCheckSquare').definition,
  require('@fortawesome/free-solid-svg-icons/faChevronDown').definition,
  require('@fortawesome/free-solid-svg-icons/faChevronUp').definition,
  require('@fortawesome/free-solid-svg-icons/faChevronLeft').definition,
  require('@fortawesome/free-solid-svg-icons/faChevronRight').definition,
  require('@fortawesome/free-regular-svg-icons/faClock').definition,
  require('@fortawesome/free-solid-svg-icons/faClock').definition,
  require('@fortawesome/free-solid-svg-icons/faClone').definition,
  require('@fortawesome/free-solid-svg-icons/faCloudUploadAlt').definition,
  require('@fortawesome/free-solid-svg-icons/faCodeBranch').definition,
  require('@fortawesome/free-solid-svg-icons/faCog').definition,
  require('@fortawesome/free-solid-svg-icons/faCoins').definition,
  require('@fortawesome/free-solid-svg-icons/faCopy').definition,
  require('@fortawesome/free-solid-svg-icons/faDesktop').definition,
  require('@fortawesome/free-solid-svg-icons/faDollarSign').definition,
  require('@fortawesome/free-solid-svg-icons/faDownload').definition,
  require('@fortawesome/free-solid-svg-icons/faDraftingCompass').definition,
  require('@fortawesome/free-solid-svg-icons/faFileImage').definition,
  require('@fortawesome/free-solid-svg-icons/faEdit').definition,
  require('@fortawesome/free-solid-svg-icons/faEllipsisH').definition,
  require('@fortawesome/free-solid-svg-icons/faEnvelope').definition,
  require('@fortawesome/free-solid-svg-icons/faEnvelopeOpen').definition,
  require('@fortawesome/free-solid-svg-icons/faExclamation').definition,
  require('@fortawesome/free-solid-svg-icons/faExclamationCircle').definition,
  require('@fortawesome/free-solid-svg-icons/faExclamationTriangle').definition,
  require('@fortawesome/free-solid-svg-icons/faExternalLinkAlt').definition,
  require('@fortawesome/free-solid-svg-icons/faEye').definition,
  require('@fortawesome/free-solid-svg-icons/faEyeSlash').definition,
  require('@fortawesome/free-solid-svg-icons/faFan').definition,
  require('@fortawesome/free-solid-svg-icons/faFileAlt').definition,
  require('@fortawesome/free-solid-svg-icons/faFileDownload').definition,
  require('@fortawesome/free-solid-svg-icons/faFileUpload').definition,
  require('@fortawesome/free-solid-svg-icons/faFileVideo').definition,
  require('@fortawesome/free-solid-svg-icons/faFileWord').definition,
  require('@fortawesome/free-solid-svg-icons/faFilePdf').definition,
  require('@fortawesome/free-solid-svg-icons/faFilter').definition,
  require('@fortawesome/free-solid-svg-icons/faFingerprint').definition,
  require('@fortawesome/free-solid-svg-icons/faGraduationCap').definition,
  require('@fortawesome/free-solid-svg-icons/faHourglassHalf').definition,
  require('@fortawesome/free-solid-svg-icons/faImage').definition,
  require('@fortawesome/free-solid-svg-icons/faInfo').definition,
  require('@fortawesome/free-solid-svg-icons/faInfoCircle').definition,
  require('@fortawesome/free-solid-svg-icons/faKey').definition,
  require('@fortawesome/free-solid-svg-icons/faList').definition,
  require('@fortawesome/free-solid-svg-icons/faLock').definition,
  require('@fortawesome/free-solid-svg-icons/faMapMarkedAlt').definition,
  require('@fortawesome/free-solid-svg-icons/faMinus').definition,
  require('@fortawesome/free-solid-svg-icons/faMinusSquare').definition,
  require('@fortawesome/free-solid-svg-icons/faMobileAlt').definition,
  require('@fortawesome/free-solid-svg-icons/faMoneyCheckAlt').definition,
  require('@fortawesome/free-solid-svg-icons/faPaperPlane').definition,
  require('@fortawesome/free-solid-svg-icons/faPause').definition,
  require('@fortawesome/free-solid-svg-icons/faPen').definition,
  require('@fortawesome/free-solid-svg-icons/faPercentage').definition,
  require('@fortawesome/free-solid-svg-icons/faPhone').definition,
  require('@fortawesome/free-solid-svg-icons/faPhoneAlt').definition,
  require('@fortawesome/free-solid-svg-icons/faPlay').definition,
  require('@fortawesome/free-solid-svg-icons/faPlayCircle').definition,
  require('@fortawesome/free-solid-svg-icons/faPlus').definition,
  require('@fortawesome/free-solid-svg-icons/faPlusCircle').definition,
  require('@fortawesome/free-solid-svg-icons/faPrint').definition,
  require('@fortawesome/free-solid-svg-icons/faQuestion').definition,
  require('@fortawesome/free-solid-svg-icons/faQuestionCircle').definition,
  require('@fortawesome/free-solid-svg-icons/faReceipt').definition,
  require('@fortawesome/free-solid-svg-icons/faRedo').definition,
  require('@fortawesome/free-solid-svg-icons/faRocket').definition,
  require('@fortawesome/free-solid-svg-icons/faScroll').definition,
  require('@fortawesome/free-solid-svg-icons/faSearch').definition,
  require('@fortawesome/free-solid-svg-icons/faShieldAlt').definition,
  require('@fortawesome/free-solid-svg-icons/faSignOutAlt').definition,
  require('@fortawesome/free-solid-svg-icons/faSortDown').definition,
  require('@fortawesome/free-solid-svg-icons/faSms').definition,
  require('@fortawesome/free-solid-svg-icons/faSortUp').definition,
  require('@fortawesome/free-solid-svg-icons/faSort').definition,
  require('@fortawesome/free-solid-svg-icons/faSpinner').definition,
  require('@fortawesome/free-regular-svg-icons/faSquare').definition,
  require('@fortawesome/free-solid-svg-icons/faSync').definition,
  require('@fortawesome/free-solid-svg-icons/faTachometerAlt').definition,
  require('@fortawesome/free-solid-svg-icons/faTag').definition,
  require('@fortawesome/free-solid-svg-icons/faThumbsUp').definition,
  require('@fortawesome/free-solid-svg-icons/faThumbsDown').definition,
  require('@fortawesome/free-solid-svg-icons/faTimes').definition,
  require('@fortawesome/free-solid-svg-icons/faTimesCircle').definition,
  require('@fortawesome/free-solid-svg-icons/faTrash').definition,
  require('@fortawesome/free-solid-svg-icons/faUpload').definition,
  require('@fortawesome/free-solid-svg-icons/faUser').definition,
  require('@fortawesome/free-solid-svg-icons/faUser').definition,
  require('@fortawesome/free-solid-svg-icons/faUserCircle').definition,
  require('@fortawesome/free-solid-svg-icons/faUserEdit').definition,
  require('@fortawesome/free-solid-svg-icons/faUsers').definition,
  require('@fortawesome/free-solid-svg-icons/faUserCheck').definition,
  require('@fortawesome/free-solid-svg-icons/faUserClock').definition,
  require('@fortawesome/free-solid-svg-icons/faUserTimes').definition,
  require('@fortawesome/free-solid-svg-icons/faVideo').definition,
  require('@fortawesome/free-solid-svg-icons/faVideoSlash').definition,
  require('@fortawesome/free-solid-svg-icons/faVolumeUp').definition,
  require('@fortawesome/free-solid-svg-icons/faWallet').definition,
  require('@fortawesome/free-solid-svg-icons/faAngleRight').definition,
  require('@fortawesome/free-solid-svg-icons/faAngleLeft').definition,
  require('@fortawesome/free-solid-svg-icons/faCalendarDay').definition,
  require('@fortawesome/free-solid-svg-icons/faPencilAlt').definition,
  require('@fortawesome/free-solid-svg-icons/faEllipsisH').definition,
  require('@fortawesome/free-solid-svg-icons/faThumbsUp').definition,
  require('@fortawesome/free-solid-svg-icons/faThumbsDown').definition,
  require('@fortawesome/free-solid-svg-icons/faCreditCard').definition,
  require('@fortawesome/free-solid-svg-icons/faCode').definition,

  // brand icons
  require('@fortawesome/free-brands-svg-icons/faCcMastercard').definition,
  require('@fortawesome/free-brands-svg-icons/faCcVisa').definition,
  require('@fortawesome/free-brands-svg-icons/faCcAmex').definition,
  require('@fortawesome/free-brands-svg-icons/faCcJcb').definition,
  require('@fortawesome/free-brands-svg-icons/faCcDinersClub').definition
)
