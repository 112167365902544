<template>
  <div v-bem="{ fill }">
    <span v-bem:inner v-html="message"></span>
  </div>
</template>

<script>
export default {
  name: 'FpHintMessage',

  props: {
    message: {
      type: String,
      default: '',
    },

    fill: Boolean,
  },
}
</script>

<style lang="scss" src="@component-styles/hint-message"></style>
