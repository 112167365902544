import { createComponent } from '@vue/composition-api'
// @ts-ignore
import FormLayout from '@layouts/form-layout'
// @ts-ignore
import BottomNav from '@components/bottom-nav'
import style from './form.module.scss'

type Props = {
  width: string | number
  padding: string | number
}
export default createComponent({
  name: 'PageContentForm',

  props: {
    width: {
      type: [Number, String],
      default: 800,
    },
    padding: {
      type: [Number, String],
      default: '40px 100px',
    },
  },

  components: { FormLayout, BottomNav },

  setup(props: Props, { slots }) {
    return () => {
      return (
        <div>
          <form-layout width={props.width}>
            <fp-card class={style.card} padding={props.padding}>
              {slots.default()}
            </fp-card>
          </form-layout>

          {(slots.bottomNavLeft || slots.bottomNavRight) && (
            <bottom-nav>
              <form-layout width={props.width}>
                <v-container fluid class="px-0">
                  {' '}
                  <v-row justify="space-between">
                    <v-col md="3" cols="6">
                      {slots.bottomNavLeft && slots.bottomNavLeft()}
                    </v-col>
                    <v-col md="3" cols="6">
                      {slots.bottomNavRight && slots.bottomNavRight()}
                    </v-col>
                  </v-row>
                </v-container>
              </form-layout>
            </bottom-nav>
          )}
        </div>
      )
    }
  },
})
