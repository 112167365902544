<template>
  <div v-bem:skeleton>
    <fp-skeleton right-margin="18" circle no-animate width="22" />
    <fp-skeleton
      :right-margin="titleRightMargin"
      :width="titleWidth"
      height="18"
    />
    <fp-skeleton right-margin="60" width="50" round height="18" />
    <fp-skeleton right-margin="30" width="80" no-animate height="18" />
    <fp-skeleton round width="120" no-animate height="18" />

    <div v-bem:skeleton-actions>
      <fp-skeleton no-animate width="88" height="18" right-margin="14" />
      <fp-skeleton circle no-animate width="28" />
    </div>
  </div>
</template>

<script>
export default {
  skipGloballyRegister: true,

  blockName: 'fp-table',

  name: 'FpTableSkeletonRow',

  data() {
    const baseWidth = 260
    const maxFlexWidth = 110
    const flexWidth = ~~(Math.random() * maxFlexWidth)

    return {
      titleWidth: baseWidth - maxFlexWidth + flexWidth,
      titleRightMargin: 50 + maxFlexWidth - flexWidth,
    }
  },
}
</script>
