import { PaymentTypeEnum } from 'typedefs'
import { IconColorMap } from '@components/_base/icon'
import {
  globalColorSuccess,
  globalColorWhite,
  globalColorMidGrey,
  globalGradientPrimary,
  globalColorInfo,
  // @ts-ignore
} from '@scss-ts'

// match colors to transaction chip colors
// todo: remove the manual color sync so both transaction chip and icon use same color api
const iconColorMap: IconColorMap<PaymentTypeEnum> = {
  DEPOSIT: {
    background: globalColorSuccess,
    color: globalColorWhite,
    name: 'wallet',
  },
  EARNINGS: {
    background: globalColorSuccess,
    color: globalColorWhite,
    name: 'wallet',
  },
  SPEND: {
    background: globalGradientPrimary,
    color: globalColorWhite,
    name: 'wallet',
  },
  WITHDRAWAL: {
    background: globalGradientPrimary,
    color: globalColorWhite,
    name: 'wallet',
  },
  ADJUSTMENT: {
    background: globalColorMidGrey,
    color: globalColorWhite,
    name: 'wallet',
  },
}

export const useTransaction = () => {
  const getIcon = (type: PaymentTypeEnum) => {
    const icon = {
      color: iconColorMap[type]?.color ?? globalColorWhite,
      background: iconColorMap[type]?.background ?? globalColorMidGrey,
      name: iconColorMap[type]?.name ?? globalColorInfo,
    }

    return icon
  }

  return { getIcon }
}
