import { createComponent } from '@vue/composition-api'

export default createComponent({
  name: 'SVGLinkedRectangles',
  setup() {
    return () => {
      return (
        <svg
          width="80"
          height="72"
          viewBox="0 0 80 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M70 0H10C7.34917 0.00436307 4.80817 1.05933 2.93375 2.93375C1.05933 4.80817 0.00436307 7.34917 0 10V50C0.00436307 52.6508 1.05933 55.1918 2.93375 57.0662C4.80817 58.9407 7.34917 59.9956 10 60H27.88C27.33 65.25 24.66 68 22 68C21.4696 68 20.9609 68.2107 20.5858 68.5858C20.2107 68.9609 20 69.4696 20 70C20 70.5304 20.2107 71.0391 20.5858 71.4142C20.9609 71.7893 21.4696 72 22 72H58C58.5304 72 59.0391 71.7893 59.4142 71.4142C59.7893 71.0391 60 70.5304 60 70C60 69.4696 59.7893 68.9609 59.4142 68.5858C59.0391 68.2107 58.5304 68 58 68C55.34 68 52.67 65.25 52.12 60H70C72.6508 59.9956 75.1918 58.9407 77.0662 57.0662C78.9407 55.1918 79.9956 52.6508 80 50V10C79.9956 7.34917 78.9407 4.80817 77.0662 2.93375C75.1918 1.05933 72.6508 0.00436307 70 0ZM29.27 68C30.8422 65.6166 31.7406 62.8523 31.87 60H48.13C48.2594 62.8523 49.1578 65.6166 50.73 68H29.27ZM70 56H10C8.76375 55.9948 7.55931 55.6077 6.55151 54.8916C5.54371 54.1756 4.78179 53.1657 4.37 52H75.63C75.2182 53.1657 74.4563 54.1756 73.4485 54.8916C72.4407 55.6077 71.2363 55.9948 70 56ZM76 48H4V10C4.00127 8.40909 4.63382 6.88371 5.75876 5.75876C6.88371 4.63382 8.40909 4.00127 10 4H70C71.5909 4.00127 73.1163 4.63382 74.2412 5.75876C75.3662 6.88371 75.9987 8.40909 76 10V48Z"
            fill="#1976D2"
          />
          <path
            d="M28 14C25.6266 14 23.3066 14.7038 21.3332 16.0224C19.3598 17.3409 17.8217 19.2151 16.9135 21.4078C16.0052 23.6005 15.7676 26.0133 16.2306 28.3411C16.6936 30.6689 17.8365 32.8071 19.5147 34.4853C21.193 36.1635 23.3311 37.3064 25.6589 37.7694C27.9867 38.2324 30.3995 37.9948 32.5922 37.0866C34.7849 36.1783 36.6591 34.6402 37.9776 32.6668C39.2962 30.6935 40 28.3734 40 26C39.9974 22.8182 38.7322 19.7675 36.4824 17.5176C34.2325 15.2678 31.1818 14.0026 28 14ZM28 34C26.0542 34.0035 24.1741 33.2965 22.7128 32.0117C21.2515 30.7268 20.3096 28.9527 20.064 27.0224C19.8184 25.0922 20.2861 23.1387 21.3791 21.5289C22.4721 19.9191 24.1153 18.7638 26 18.28V26C25.9991 26.2629 26.0502 26.5234 26.1504 26.7664C26.2506 27.0095 26.3979 27.2303 26.5838 27.4162C26.7697 27.6021 26.9905 27.7494 27.2336 27.8496C27.4766 27.9498 27.7371 28.0009 28 28H35.72C35.2777 29.7129 34.2801 31.231 32.8833 32.3166C31.4864 33.4023 29.7691 33.9943 28 34ZM30 24V18.28C31.3757 18.6391 32.6309 19.3584 33.6362 20.3638C34.6416 21.3691 35.3609 22.6243 35.72 24H30Z"
            fill="#1976D2"
          />
          <path
            d="M62 16H50C49.4696 16 48.9609 16.2107 48.5858 16.5858C48.2107 16.9609 48 17.4696 48 18C48 18.5304 48.2107 19.0391 48.5858 19.4142C48.9609 19.7893 49.4696 20 50 20H62C62.5304 20 63.0391 19.7893 63.4142 19.4142C63.7893 19.0391 64 18.5304 64 18C64 17.4696 63.7893 16.9609 63.4142 16.5858C63.0391 16.2107 62.5304 16 62 16Z"
            fill="#1976D2"
          />
          <path
            d="M62 24H50C49.4696 24 48.9609 24.2107 48.5858 24.5858C48.2107 24.9609 48 25.4696 48 26C48 26.5304 48.2107 27.0391 48.5858 27.4142C48.9609 27.7893 49.4696 28 50 28H62C62.5304 28 63.0391 27.7893 63.4142 27.4142C63.7893 27.0391 64 26.5304 64 26C64 25.4696 63.7893 24.9609 63.4142 24.5858C63.0391 24.2107 62.5304 24 62 24Z"
            fill="#1976D2"
          />
          <path
            d="M62 32H50C49.4696 32 48.9609 32.2107 48.5858 32.5858C48.2107 32.9609 48 33.4696 48 34C48 34.5304 48.2107 35.0391 48.5858 35.4142C48.9609 35.7893 49.4696 36 50 36H62C62.5304 36 63.0391 35.7893 63.4142 35.4142C63.7893 35.0391 64 34.5304 64 34C64 33.4696 63.7893 32.9609 63.4142 32.5858C63.0391 32.2107 62.5304 32 62 32Z"
            fill="#1976D2"
          />
        </svg>
      )
    }
  },
})
