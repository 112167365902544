import gql from 'graphql-tag'
import { notification } from '../fragments'

export const onNotificationAdded = gql`
  subscription onNotificationAdded($user: String!) {
    notificationAdded(user: $user) {
      ...notification
    }
  }
  ${notification}
`
