var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.$style.container,
    ( _obj = {}, _obj[_vm.$style.isAdminMode] = _vm.adminEditMode, _obj ),
    'justify-end' ]},[(_vm.showTransactionBalance)?_c('div',{class:_vm.$style.headerAction,attrs:{"data-cy":"header-action--transactions"}},[_c('transaction',{on:{"on-add-funds":_vm.handleAddFundsClick}})],1):_vm._e(),_c('div',{class:_vm.$style.headerAction,attrs:{"data-cy":"header-action--notifications"}},[_c('notification')],1),_c('fp-dropdown',{ref:"userDropdown",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var toggle = ref.toggle;
  var visible = ref.visible;
return [_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],class:[_vm.$style.effect, _vm.$style.headerAction],attrs:{"data-cy":"user-dropdown-activator","tabindex":"2"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return toggle(!_vm.fetching && !_vm.loading)},"click":function($event){return toggle(!_vm.fetching && !_vm.loading)}}},[_c('fp-icon',{class:_vm.$style.userIcon,attrs:{"name":"user-circle","size":"lg"}}),_c('transition',{attrs:{"name":"fade-skeleton","mode":"out-in"},on:{"after-enter":_vm.afterOrgnameEnter}},[(_vm.fetching && _vm.loading)?_c('fp-skeleton',{attrs:{"right-margin":"8","left-margin":"8","width":_vm.isMobile ? 0 : _vm.orgnameEleWidth,"height":"25"}}):_c('span',{class:_vm.$style.userName,attrs:{"data-cy":"header-username-text"}},[_vm._v(_vm._s(_vm.displayName))])],1),_c('fp-icon',{class:[_vm.$style.icon, _vm.$style.angleIcon],attrs:{"name":visible ? 'angle-up' : 'angle-down',"size":"sm"}})],1)]}},{key:"content",fn:function(ref){
  var toggle = ref.toggle;
  var isPopupsEntered = ref.isPopupsEntered;
return [_c('user-content',{attrs:{"visible":isPopupsEntered,"user-info":_vm.userInfo},on:{"toggle-visible":toggle}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }