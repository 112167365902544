// This mixin only serve for CheckboxGroup & RadioGroup
// DO NOT USE AT OTHER PLACES!

export default {
  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    name: {
      type: String,
      default: Math.random().toString(16),
    },
  },

  provide() {
    return {
      injectFromGroup: true,
      name: this.name,
      // pass refer down to radio or chekcbox component
      groupInstance: this,
    }
  },
}
