<script>
export default {
  name: 'PlaceholderPortrait',
}
</script>

<template>
  <fp-icon name="user-circle" size="10x" :class="$style.userIcon" />

  <!-- <v-avatar size="180">
    <v-img src="@assets/images/placeholder-portrait.svg"></v-img>
  </v-avatar> -->
</template>

<style lang="scss" module>
@import '@scss';
.userIcon {
  font-size: 11em;
}
</style>
