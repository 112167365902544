const bUrl = 'https://www.frameplay.gg' // Brochure URL
const dUrl = 'https://docs.frameplay.gg' // Documentation URL
const sUrl = 'https://support.frameplay.gg' // Support URL
const qs = '?ref=platform'

export const state = {
  links: {
    qs,
    brochure: {
      base: bUrl,
      url: bUrl + qs,
      terms: `${bUrl}/terms${qs}#Terms`,
      privacy: `${bUrl}/terms${qs}#Policy`,
    },
    docs: {
      base: dUrl,
      url: dUrl + qs,
    },
    support: {
      base: sUrl,
      url: sUrl + qs,
    },
  },
}

export const getters = {
  links({ links }) {
    return links
  },
}

export const mutations = {}

export const actions = {}
