import { createComponent } from '@vue/composition-api'
import style from './style.module.scss'

type Props = {
  title: string
}

export default createComponent({
  name: 'Divider',

  // todo: remove run time props when migrated to vue 3
  // https://github.com/vuejs/composition-api/issues/146
  props: {
    title: {
      type: String,
    },
  },

  setup(props: Props) {
    const DividerHasText = () => (
      <v-container fluid class="px-0 py-6">
        {' '}
        <v-row no-gutter justify="center" align="center" class="text-center">
          <v-col>
            <div class={style.line}></div>
          </v-col>
          <v-col lg="1" md="2" cols="3">
            <div class={style.title}>{props.title}</div>
          </v-col>
          <v-col>
            <div class={style.line}></div>
          </v-col>
        </v-row>
      </v-container>
    )
    const DividerNoText = () => (
      <v-container fluid class="px-0 py-6">
        {' '}
        <v-row no-gutter>
          <v-col>
            <div class={style.line}></div>
          </v-col>
        </v-row>
      </v-container>
    )

    return () => {
      if (props.title) return <DividerHasText />
      return <DividerNoText />
    }
  },
})
