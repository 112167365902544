import { SetupContext } from '@vue/composition-api'
const escape = require('escape-html');

export type Methods = {
  /** Shows error or error.message if there is one. Does nothing if not. */
  error: (err: any) => void
  success: (message: string) => void
  warning: (message: string) => void
}

export const useToaster = (ctx: SetupContext): Methods => {
  const error = (err: any) => {
    if (err) ctx.root.$toast.error(escape(err?.message ?? err))
  }

  const success = (message: string) => {
    if (message) ctx.root.$toast.success(escape(message))
  }

  const warning = (message: string) => {
    if (message) ctx.root.$toast.warning(escape(message))
  }
  return { error, success, warning }
}
