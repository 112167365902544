export function getCountryFlagImg(key) {
  const DEFAULT = `1DEFAULT`
  const _key = key?.toUpperCase() || DEFAULT

  try {
    const path = require(`@assets/images/flags/${_key}.svg`)
    return path
  } catch (e) {
    try {
      return require(`@assets/images/flags/${DEFAULT}.svg`)
    } catch (e) {
      return ''
    }
  }
}
