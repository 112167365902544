<template>
  <div v-bem="{}">
    <v-combobox
      v-bind="propsPenetrating"
      :filter="filter"
      :hide-no-data="!search"
      :search-input.sync="search"
      :items="itemsInternal"
      :data-cy="cypressName"
      v-on="$listeners"
      @change="handleChange"
    >
      <template v-slot:selection="{ item, parent }">
        <v-chip
          v-if="propsPenetrating.multiple === true"
          close
          ripple
          @click:close="parent.selectItem(item)"
        >
          <span v-bem:selectionChipLabel>{{ item }}</span>
        </v-chip>

        <v-chip v-else ripple>
          <span v-bem:selectionChipLabel>{{ item }}</span>
        </v-chip>
      </template>

      <template v-slot:item="{ attrs, on, index, item }">
        <v-list-item
          :key="index"
          v-bind="attrs"
          :ripple="{ class: 'primary--text' }"
          v-on="on"
          @click="handleListItemClick"
        >
          <v-list-item-action v-if="deletableItems" @click.stop>
            <fp-icon-button
              class="close-button"
              icon="times"
              size="small"
              @click.stop.prevent="deleteItem(index, item)"
            />
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>
              {{ item }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import vuetifyIcon from '@component-mixins/vuetifyIcon'

export default {
  name: 'FpCombobox',

  inject: ['$validator'],

  $_veeValidate: {
    value() {
      return this.value
    },
    name() {
      return this.name
    },
  },

  mixins: [vuetifyIcon],

  props: {
    name: { type: String, default: '' },
    cypressName: { type: String, default: 'cy-fp-combobox' },

    items: {
      type: Array,
      default: () => [],
    },

    required: Boolean,
    error: Boolean,

    message: { type: String, default: '' },

    header: { type: String, default: 'Select an option or create one' },

    deletableItems: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    search: null,
    lastItemInModel: [],
    itemsInternal: [],
  }),

  computed: {
    valueToPenetrate() {
      const found = this.items?.find(o => o === this.value)
      const out = [...(found || []), ...(this.value || [])].filter(o => !!o)
      return out
    },

    propsPenetrating() {
      const { $attrs, error, message: errorMessages, valueToPenetrate } = this
      let formatClear = this.vuetifyIcon('circle-cross')

      const props = {
        clearIcon: formatClear,
        errorMessages,
        error,
        multiple: true,
        chips: true,
        'hide-selected': true,
        outlined: true,
        'open-on-clear': true,
        'deletable-chips': true,
        'persistent-hint': true,
        clearable: true,
        value: valueToPenetrate,
        ...$attrs,
      }

      return props
    },
  },

  watch: {
    items: {
      handler: function (input) {
        this.cacheLastItem(this.$attrs.value)
        this.itemsInternal = [...input]
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    this.cacheLastItem(this.$attrs.value)
    if (this.$attrs.items) this.itemsInternal = [...this.$attrs.items]
    if (this.header) {
      this.itemsInternal.unshift({ header: this.header })
    }
  },

  methods: {
    handleListItemClick() {
      this.search = null
    },
    cacheLastItem(input) {
      if (input && input.length === 1) {
        this.lastItemInModel = input
      }
    },
    deleteItem(index, item) {
      this.itemsInternal = this.itemsInternal.filter(o => o !== item)
    },
    filter(item, queryText, itemText) {
      if (item?.header) return false

      const hasValue = val => (val != null ? val : '')

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      )
    },
    handleChange(input) {
      if (this.$attrs.multiple === false) {
        const o = input?.trim() ?? ''
        this.$emit('input', o)
        return o
      }

      const clean = input.map(x => {
        if (x) {
          if (typeof x === 'string') {
            const o = x.trim()
            return o
          } else {
            const o = x.toString().trim()
            return o
          }
        }
      })

      clean.forEach(v => {
        if (!this.itemsInternal.includes(v) && v) {
          this.itemsInternal.push(v)
        }
      })

      this.$emit('input', clean)
    },
  },
}
</script>

<style lang="scss" src="@component-styles/combobox"></style>
