<template>
  <animate-container
    :pose="visible ? 'visible' : 'hidden'"
    :class="$commonStyle.container"
  >
    <animate-item :class="$style.userInfo">
      <div :class="$style.baseInfo">
        <h4 :class="$style.userName" class="text-truncate">
          {{ displayName }}
        </h4>
        <p :class="$style.email" class="text-truncate">
          {{ displayEmail }}
        </p>
        <!-- <fp-chip v-if="displayRole" small type="dark">
          <div :class="$style.roleChip">
            {{ displayRole }}
          </div>
        </fp-chip> -->
      </div>
    </animate-item>

    <animate-item
      v-if="!isSuperAdmin || adminEditMode"
      :class="$commonStyle.divide"
    />

    <a-link
      v-for="item in allowedLinks"
      :key="item.label"
      v-bind="item"
      :data-cy="`header-link--${item.to.name}`"
      @click="hideDropdown"
    >
      {{ item.label }}
    </a-link>

    <animate-item v-if="!adminEditMode" :class="$commonStyle.divide" />

    <a-link
      v-if="!adminEditMode"
      data-cy="logout"
      icon="sign-out-alt"
      @click="logoutAction"
    >
      Logout
    </a-link>
  </animate-container>
</template>

<script>
import router from '@router'
import posed from 'vue-pose'
import ALink from './header-link'
import { mapGetters, mapActions } from 'vuex'
import { checkRoutePermission, checkRoutePermissionHier } from '@router/utils'
import { HEADER_DROPDOWN_LINK_LABELS } from '@src/shared/constants'
// import { humanize } from '@src/shared/string'

const BaseLinks = [
  {
    label: HEADER_DROPDOWN_LINK_LABELS.YOUR_ORGANIZATION,
    icon: 'users',
    to: {
      name: 'account',
    },
  },
]

const AdminLinks = [
  {
    label: HEADER_DROPDOWN_LINK_LABELS.TRANSACTIONS,
    icon: 'receipt',
    to: {
      name: 'transactions',
    },
  },
  {
    label: HEADER_DROPDOWN_LINK_LABELS.GAME_RATES,
    icon: 'percentage',
    to: {
      name: 'rates',
    },
  },
  {
    label: HEADER_DROPDOWN_LINK_LABELS.DOWNLOAD_SDK,
    icon: 'download',
    to: {
      name: 'download',
    },
  },
  {
    label: HEADER_DROPDOWN_LINK_LABELS.ADVANCED_SETTINGS,
    icon: 'code',
    to: {
      name: 'advanced-settings',
    },
  },
  {
    label: HEADER_DROPDOWN_LINK_LABELS.INVENTORY_AVAILABILITY,
    icon: 'boxes',
    to: {
      path: '/advanced-settings?tab=inventoryAvailability',
    },
  },
  {
    label: HEADER_DROPDOWN_LINK_LABELS.INVENTORY_AVAILABILITY_AUDIT_LOG,
    icon: 'scroll',
    to: {
      path: '/advanced-settings?tab=inventoryAvailabilityAuditLog',
    },
  },
]

export default {
  skipGloballyRegister: true,

  name: 'UserDropdownContent',

  provide() {
    return {
      $style: this.$style,
    }
  },

  components: {
    ALink,
    AnimateContainer: posed.div({
      visible: {
        beforeChildren: true,
        staggerChildren: 20,
      },
      hidden: {
        afterChildren: true,
      },
    }),
    AnimateItem: posed.div({
      visible: {
        opacity: 1,
        y: 0,
      },
      hidden: { opacity: 0, y: 100 },
    }),
  },

  props: {
    visible: Boolean,
  },

  computed: {
    ...mapGetters('org', [
      'title',
      'email',
      'orgType',
      'id',
      'isSuperAdmin',
      'isAgency',
      'isAdvertiser',
      'currentOrganisation',
      'organisation',
      'isVerified',
    ]),
    ...mapGetters('user', ['userInfo', 'canAccessMPT']),

    Links() {
      if (!this.isVerified) {
        return BaseLinks
      }
      return [
        ...BaseLinks,
        {
          label: HEADER_DROPDOWN_LINK_LABELS.ADVANCED_SETTINGS,
          icon: 'code',
          to: {
            name: 'advanced-settings',
          },
        },
        {
          label: HEADER_DROPDOWN_LINK_LABELS.INVENTORY_AVAILABILITY,
          icon: 'boxes',
          to: {
            path: '/advanced-settings?tab=inventoryAvailability',
          },
        },
        {
          label: HEADER_DROPDOWN_LINK_LABELS.INVENTORY_AVAILABILITY_AUDIT_LOG,
          icon: 'scroll',
          to: {
            path: '/advanced-settings?tab=inventoryAvailabilityAuditLog',
          },
        },
      ]
    },

    displayName() {
      let name = this.userInfo?.firstName
      if (this.userInfo?.lastName) {
        name = name + ` ${this.userInfo?.lastName}`
      }

      return name || this.source.title
    },

    // displayRole() {
    //   if (this.userInfo && this.userInfo.roles && this.userInfo.roles[0])
    //     return humanize(
    //       this.userInfo.roles[0].roleID.split(':')[1]
    //     ).toUpperCase()
    //   return ''
    // },

    displayEmail() {
      return this.userInfo?.email ? this.userInfo?.email : this.contact?.email
    },

    source() {
      if (this.adminEditMode) {
        return this.currentOrganisation
      }

      return this.organisation
    },

    adminEditMode() {
      return this.$route.meta.adminEditMode
    },

    allRoutes() {
      const o = this.$router.options.routes.find(
        r => r.name === 'main'
      ).children
      return o
    },

    allowedLinks() {
      if (this.isSuperAdmin && !this.adminEditMode) {
        return AdminLinks
      }

      const out = this.Links.filter(link => {
        // TODO: only allow MPT to FPSA and all GG users for now
        if (link.label === HEADER_DROPDOWN_LINK_LABELS.INVENTORY_AVAILABILITY) {
          return this.canAccessMPT
        }

        // TODO: only allow MPT audit log to FPSA for now
        if (
          link.label ===
          HEADER_DROPDOWN_LINK_LABELS.INVENTORY_AVAILABILITY_AUDIT_LOG
        ) {
          return this.isSuperAdmin
        }

        const targetRoute = this.allRoutes.find(
          route => route.name === link.to.name
        )

        const x = checkRoutePermission(
          targetRoute?.meta?.allow,
          targetRoute?.meta?.permissions
        )
        const y = checkRoutePermissionHier(targetRoute?.meta?.allowHier)

        return x && y
      })

      return out
    },
  },

  methods: {
    ...mapActions('auth', ['logOut']),
    hideDropdown() {
      this.$emit('toggle-visible', false)
    },
    logoutAction() {
      this.hideDropdown()
      this.logOut().then(() => {
        router.push('/login')
      })
    },
  },
}
</script>

<style lang="scss" module>
@import '~watson-scss';
@import '@scss';

.roleChip {
  display: block;
  max-width: 260px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.userInfo {
  display: flex;
  width: 100%;
  min-width: 240px;
  padding: 0 10px 10px;
  font-size: 16px;
}

.baseInfo {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  text-align: left;
}

.email {
  margin-bottom: 8px !important;
  font-size: 14px;
  line-height: 1.2;
}

.userName {
  max-width: 100%;
  min-height: 27px;
  margin-top: 7px;
  margin-bottom: 5px;
  font-weight: $content-font-weight-bold;
  line-height: 1.2;
}

.verification {
  display: inline-block;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: $content-font-weight-bold;
  line-height: 1;
  color: $color-white;
  background-color: $color-primary;
  border-radius: 20px;
}
</style>

<style lang="scss" src="./header-action.scss" module="$commonStyle"></style>
