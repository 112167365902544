import gql from 'graphql-tag'

export const getTags = gql`
  query getTags($types: [TagTypeEnum]) {
    getTags(types: $types) {
      tag
      name
      value
      valueType
      internal
      type
    }
  }
`
export const validatePhone = gql`
  query validatePhone($phone: String!) {
    validatePhone(phone: $phone)
  }
`
