import { registerVueComponents } from './componentManager'

// Globally register all base components for convenience, because they
// will be used very frequently. Components are registered using the
// PascalCased version of their file name.

// https://webpack.js.org/guides/dependency-management/#require-context
const requireComponent = require.context(
  // Look for files in the current directory
  '.',
  // Enable look in subdirectories
  true,
  // Only include .vue files
  /[\w-]+\.(vue|tsx)$/
)

// For each matching file name...
requireComponent.keys().forEach(fileName => {
  registerVueComponents(fileName, () => requireComponent(fileName))
})
