import gql from 'graphql-tag'

export const dashDataPoint = gql`
  fragment dashDataPoint on DashDataPoint {
    data
    label
  }
`

export const dashSeriesData = gql`
  fragment dashSeriesData on DashSeriesData {
    total
    chart {
      ...dashDataPoint
    }
  }
  ${dashDataPoint}
`

export const dashSeries = gql`
  fragment dashSeries on DashSeries {
    money {
      ...dashSeriesData
    }
    viewableImpressions {
      ...dashSeriesData
    }
    uniqueUsers {
      ...dashSeriesData
    }
  }
  ${dashSeriesData}
`
export const dashCards = gql`
  fragment dashCards on DashCards {
    age {
      ...dashDataPoint
    }
    sex {
      ...dashDataPoint
    }
    genre {
      ...dashDataPoint
    }
    rating {
      ...dashDataPoint
    }
  }
  ${dashDataPoint}
`

export const dashRangeMetricsData = gql`
  fragment dashRangeMetricsData on DashRangeMetricsData {
    series {
      ...dashSeries
    }
    cards {
      ...dashCards
    }
  }
  ${dashSeries}
  ${dashCards}
`

export const dashRangeMetrics = gql`
  fragment dashRangeMetrics on DashRangeMetrics {
    daily {
      ...dashRangeMetricsData
    }
    weekly {
      ...dashRangeMetricsData
    }
    monthly {
      ...dashRangeMetricsData
    }
  }
  ${dashRangeMetricsData}
`

export const dashTableDataRow = gql`
  fragment dashTableDataRow on DashTableDataRow {
    label
    daily
    weekly
    monthly
  }
`

export const dashTableData = gql`
  fragment dashTableData on DashTableData {
    money {
      ...dashTableDataRow
    }
    viewableImpressions {
      ...dashTableDataRow
    }
  }
  ${dashTableDataRow}
`

export const dashTables = gql`
  fragment dashTables on DashTables {
    top {
      ...dashTableData
    }
    country {
      ...dashTableData
    }
  }
  ${dashTableData}
`
