import gql from 'graphql-tag'

export const s3Object = gql`
  fragment s3Object on S3Object {
    originalName
    url
    thumbnailUrl
    contentType
    keyTag
    platforms
    size
    pixels
    mimeType
    durationSecond
  }
`
