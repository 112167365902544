import { MutableKeys } from 'utility-types'

export type Props = {
  value: boolean
  width: number
  stateless: boolean
  temporary: boolean
  // hideOverlay: boolean
}

// Vue-metadata types

type VuePropsKeys = MutableKeys<Props>[]
export const vueProps: VuePropsKeys = [
  'value',
  'width',
  'stateless',
  'temporary',
  // 'hideOverlay',
]
