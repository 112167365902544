import {
  createComponent,
  reactive,
  onMounted,
  onBeforeUnmount,
} from '@vue/composition-api'
import Vue from 'vue'

export const getDigits = timer => {
  if (!timer) {
    return { min: '00', sec: '00' }
  }
  const timerFloat = parseFloat(timer)
  if (!timerFloat) {
    return { min: '00', sec: '00' }
  }
  const timerAbs = Math.abs(timerFloat)
  const sign = timerAbs === timerFloat ? '' : '-'
  const min = Math.floor(timerAbs / 60).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })
  const sec = (timerAbs % 60).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })
  return {
    min: sign + min,
    sec,
  }
}

export default createComponent({
  name: 'Countdown',
  props: {
    durationInSeconds: [Number, String],
    timerBus: Vue,
  },
  setup(props, ctx) {
    let duration = 0
    if (props.durationInSeconds) {
      if (typeof props.durationInSeconds === 'string')
        duration = parseInt(props.durationInSeconds)
      else if (typeof props.durationInSeconds === 'number')
        duration = props.durationInSeconds
      else duration = 30
      duration = Math.floor(duration)
    }
    const time = getDigits(duration)
    const initialMin = time.min
    const initialSec = time.sec

    const state = reactive<any>({
      minutes: initialMin,
      seconds: initialSec,
      interval: undefined,
    })

    const startTimer = () => {
      clearInterval(state.interval)

      let timer: any = parseInt(props.durationInSeconds + '')

      state.interval = setInterval(function () {
        const time = getDigits(timer)

        state.minutes = time.min
        state.seconds = time.sec

        if (--timer < 0) {
          clearInterval(state.interval)
          timeup()
        }
      }, 1000)
    }

    const timeup = () => {
      ctx.emit('timeup')
    }

    const resetTimer = () => {
      clearInterval(state.interval)
      state.minutes = initialMin
      state.seconds = initialSec
    }

    const pauseTimer = () => {
      clearInterval(state.interval)
    }

    onMounted(() => {
      if (props.timerBus) {
        props.timerBus.$on('start', startTimer)
        props.timerBus.$on('reset', resetTimer)
        props.timerBus.$on('pause', pauseTimer)
      }
    })

    onBeforeUnmount(() => {
      if (props.timerBus) {
        props.timerBus.$off('start', startTimer)
        props.timerBus.$off('reset', resetTimer)
        props.timerBus.$off('pause', pauseTimer)
      }
    })

    return () => {
      return (
        <span class="d-flex align-center">
          {state.minutes}
          <span style="padding:0 2px">:</span>
          {state.seconds}
        </span>
      )
    }
  },
})
