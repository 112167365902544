const ICON_POSITIONS = ['left', 'right']

export default {
  props: {
    icon: {
      type: String,
      default: '',
    },

    iconPosition: {
      type: String,
      default: 'right',
      validator(v) {
        return ICON_POSITIONS.includes(v)
      },
    },
  },
}
