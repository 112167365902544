import { GQL_CLIENT_NAMES } from '@constants'
import { maybeCallGuards } from '@graphql/shared/guards'

const { DEFAULT_CLIENT } = GQL_CLIENT_NAMES
let clients

export function saveClients(clientsMap) {
  // steal&save link to clients obj
  clients = clientsMap
}

function getClient() {
  return clients[DEFAULT_CLIENT]
}

function query(options) {
  const client = getClient()
  return client.query(options)
}

async function mutate(options) {
  const client = getClient()
  // we decide whether to trigger mutation checks before the mutation operation,
  // but the actual judgment whether a mutation is approved or not occurs
  // on the network level, using a custom apollo-link -> see guardLink in this project
  await maybeCallGuards(options.mutation, options.variables, options.dialog)
  return client.mutate(options)
}

export default { query, mutate }
