import * as queries from '@graphql/game/queries'
import * as tQueries from '@graphql/titles/queries'
import {
  TITLE_TYPES,
  GAME_RELEASE_VALUES,
  GAME_RATING_VALUES,
  AD_RESTRICTIONS_LABELS,
  GAME_OS_LABELS,
} from '@shared/constants'
import {
  createGame,
  updateGame,
  archiveGames,
  unarchiveGames,
  pauseGames,
  unpauseGames,
  requestGameReviews,
} from '@graphql/game/mutations'

import {
  GameReleases,
  GameGenres,
  GamePlatforms,
  GameRatingImages,
  GameRatings,
  AdRestrictions,
  GameOSList,
} from '@shared/enums'

import cloneDeep from 'lodash/cloneDeep'
import { enumsToSelectItems } from '@utils'

export const state = {
  games: {
    items: Array(6)
      .fill(false)
      .map(_ => Object.create(null)),
  },
  gameList: { items: [] },
  gameListAll: { items: [] },
  currentGame: null,
  myGameAccess: null,
  // mapState - If items required to be observable, move to Getter
  ratingItems: null,
  ratingItemsWithoutAny: null,
  genreItems: null,
  genreItemsWithoutAny: null,
  platformItems: null,
  releaseItems: null,
  restrictionItems: null,
  sensitiveRestrictionItems: null,
  gameAdApprovals: { items: [] },
}

export const getters = {
  games({ games }) {
    return games?.items
  },
  total({ games }) {
    return games?.total
  },
  currentGame({ currentGame }) {
    return currentGame
  },
  gameList({ gameList }) {
    return gameList?.items
  },
  gameListAll({ gameListAll }) {
    return gameListAll?.items
  },
  gameAdApprovals({ gameAdApprovals }) {
    return gameAdApprovals?.items
  },
  myGameAccess({ myGameAccess }) {
    return myGameAccess
  },
  ratingItems({ ratingItems }) {
    return ratingItems
  },
  ratingItemsWithoutAny({ ratingItemsWithoutAny }) {
    return ratingItemsWithoutAny
  },
  genreItems({ genreItems }) {
    return genreItems
  },

  genreItemsWithoutAny({ genreItemsWithoutAny }) {
    return genreItemsWithoutAny
  },
  platformItems({ platformItems }) {
    return platformItems
  },
  osItems({ osItems }) {
    return osItems
  },
  releaseItems({ releaseItems }) {
    return releaseItems
  },
  restrictionItems({ restrictionItems }) {
    return restrictionItems
  },
  sensitiveRestrictionItems({ sensitiveRestrictionItems }) {
    return sensitiveRestrictionItems
  },
}

export const mutations = {
  SET_GAMES(state, data) {
    state.games = data
  },

  SET_GAME_LIST(state, data) {
    const firstItem = [{ title: 'All Games', id: null }]
    let filterList = cloneDeep(data)
    state.gameList = { items: data }
    state.gameListAll = { items: firstItem.concat(filterList) }
  },

  SET_GAME_AD_APPROVALS(state, data) {
    state.gameAdApprovals = { items: data }
  },

  SET_CURRENT_GAME(state, data) {
    state.currentGame = data
  },

  SET_MY_GAME_ACCESS(state, data) {
    state.myGameAccess = data
  },

  SET_ESRB_RATING_ITEMS(state) {
    const firstItem = [{ text: 'All Ratings', value: null }]
    let ratingList = enumsToSelectItems({
      enums: GameRatings,
      avatars: GameRatingImages,
      textMap: GAME_RATING_VALUES,
    })
    let ratingListAll = cloneDeep(ratingList)

    const ratingItemsWithoutAny = ratingListAll.filter(
      item => item.value !== 'ANY'
    )

    ratingListAll = firstItem.concat(ratingItemsWithoutAny)

    state.ratingItems = ratingList
    state.ratingItemsWithoutAny = ratingItemsWithoutAny
  },

  SET_PLATFORM_ITEMS(state) {
    state.platformItems = enumsToSelectItems({
      enums: GamePlatforms,
      textMap: {
        PC: 'Desktop',
      },
    })
  },

  SET_OS_ITEMS(state) {
    state.osItems = enumsToSelectItems({
      enums: GameOSList,
      textMap: {
        ...GAME_OS_LABELS,
      },
    })
  },

  SET_GENRE_ITEMS(state) {
    // drop ANY genre value
    state.genreItems = enumsToSelectItems({
      enums: GameGenres,
    })

    state.genreItemsWithoutAny = enumsToSelectItems({
      enums: GameGenres.filter(item => item !== 'ANY'),
    })
  },

  SET_RELEASE_ITEMS(state) {
    state.releaseItems = enumsToSelectItems({
      enums: GameReleases,
      textMap: GAME_RELEASE_VALUES,
    })
  },

  SET_RESTRICTION_ITEMS(state) {
    state.restrictionItems = enumsToSelectItems({
      enums: AdRestrictions,
      textMap: AD_RESTRICTIONS_LABELS,
    })
  },

  // MOCK VUEX MUTATIONS
  MOCK_ARCHIVE_GAME(state, data) {
    state.games.items.forEach((element, index) => {
      if (data.includes(element.id)) element.archived = false
    })
  },
  MOCK_UNARCHIVE_GAME(state, data) {
    state.games.items.forEach((element, index) => {
      if (data.includes(element.id)) element.archived = true
    })
  },
  MOCK_PAUSE_GAME(state, data) {
    state.games.items.forEach((element, index) => {
      if (data.includes(element.id)) element.paused = !element.paused
    })
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ dispatch }) {
    dispatch('initGameItems')
  },

  initGameItems({ commit }) {
    commit('SET_ESRB_RATING_ITEMS')
    commit('SET_GENRE_ITEMS')
    commit('SET_PLATFORM_ITEMS')
    commit('SET_OS_ITEMS')
    commit('SET_RELEASE_ITEMS')
    commit('SET_RESTRICTION_ITEMS')
  },

  // ---------------------- //
  // GRAPHQL QUERIES
  // -----------------------//
  async getGames({ commit }, variables) {
    const query = variables.isSuperAdmin
      ? queries.getGamesSuperAdmin
      : queries.getGames
    const [err, res] = await this.dispatch('tryQuery', {
      query: query,
      variables: variables.variables,
    })

    if (!err) commit('SET_GAMES', res)
    else commit('SET_GAMES', [])

    return [err, res]
  },

  async getGameById({ commit }, variables) {
    const query = variables.isSuperAdmin
      ? queries.getGamesSuperAdmin
      : queries.getGames

    const [err, res] = await this.dispatch('tryQuery', {
      query: query,
      variables: variables.variables,
    })

    if (!err && res) {
      if (res.items.length === 0) {
        throw new Error(`The game ID "${variables.ids}" does not exist`)
      }
      // REAL data returns array.length === 1 &&
      // MOCKED returns different ID in static list
      // return first item in the array
      commit('SET_CURRENT_GAME', res.items[0])
      return res.items[0]
    } else {
      return null
    }
  },

  async getGameList({ commit }, variables = { type: TITLE_TYPES.GAME }) {
    const [err, res] = await this.dispatch('tryQuery', {
      query: tQueries.getTitles,
      variables,
    })

    if (!err && res) commit('SET_GAME_LIST', res)
  },

  async getGameAdApprovals({ commit }, variables) {
    const query = queries.getGameAdApprovals
    const [err, res] = await this.dispatch('tryQuery', {
      query: query,
      variables,
    })

    if (!err) commit('SET_GAME_AD_APPROVALS', res)
    return [err, res]
  },

  async getGameAdApprovalHistory({ commit }, variables) {
    const query = queries.getGameAdApprovalHistory
    const [err, res] = await this.dispatch('tryQuery', {
      query: query,
      variables,
    })

    return [err, res]
  },

  async getGameAccess({ commit }, variables) {
    const query = queries.getGameAccess
    const [err, res] = await this.dispatch('tryQuery', {
      query: query,
      variables,
    })

    return [err, res]
  },

  async getMyGameAccess({ commit }, variables) {
    const query = queries.getGameAccessForCurrentUser
    const [err, res] = await this.dispatch('tryQuery', {
      query: query,
      variables,
    })

    if (!err && res) {
      commit('SET_MY_GAME_ACCESS', res)
    }

    return [err, res]
  },

  // ---------------------- //
  // GRAPHQL MUTATIONS
  // -----------------------//
  async createGame({ commit }, input) {
    // id expected to be Array
    // if (process.env.VUE_APP_USE_MOCKED_DATA === 'true')
    //   commit('MOCK_PAUSE_GAME', id)

    const [err, res] = await this.dispatch('tryMutate', {
      mutation: createGame,
      variables: input,
    })

    return [err, res]
  },

  async updateGame({ commit }, input) {
    // id expected to be Array
    // if (process.env.VUE_APP_USE_MOCKED_DATA === 'true')
    // commit('MOCK_PAUSE_GAME', id)
    const [err, res] = await this.dispatch('tryMutate', {
      mutation: updateGame,
      variables: input,
    })
    return [err, res]
  },

  async archiveGames({ commit }, ids) {
    // type id expected to be Array
    if (process.env.VUE_APP_USE_MOCKED_DATA === 'true') {
      commit('MOCK_ARCHIVE_GAME', ids)
    }

    const [err, res] = await this.dispatch('tryMutate', {
      mutation: archiveGames,
      variables: { ids },
    })

    return [err, res]
  },

  async unarchiveGames({ commit }, ids) {
    // type id expected to be Array
    if (process.env.VUE_APP_USE_MOCKED_DATA === 'true') {
      commit('MOCK_UNARCHIVE_GAME', ids)
    }

    const [err, res] = await this.dispatch('tryMutate', {
      mutation: unarchiveGames,
      variables: { ids },
    })

    return [err, res]
  },

  async pauseGames({ commit }, ids) {
    // id expected to be Array
    if (process.env.VUE_APP_USE_MOCKED_DATA === 'true') {
      commit('MOCK_PAUSE_GAME', ids)
    }

    const [err, res] = await this.dispatch('tryMutate', {
      mutation: pauseGames,
      variables: { ids },
    })

    return [err, res]
  },

  async unpauseGames({ commit }, ids) {
    // id expected to be Array
    if (process.env.VUE_APP_USE_MOCKED_DATA === 'true') {
      commit('MOCK_PAUSE_GAME', ids)
    }

    const [err, res] = await this.dispatch('tryMutate', {
      mutation: unpauseGames,
      variables: { ids },
    })

    return [err, res]
  },

  async requestGameReviews({ commit }, ids) {
    const [err, res] = await this.dispatch('tryMutate', {
      mutation: requestGameReviews,
      variables: { input: { ids } },
    })

    return [err, res]
  },

  async updateGameAdApprovals({ commit }, input) {
    const mutation = mutations.updateGameAdApprovals
    const [err, res] = await this.dispatch('tryQuery', {
      mutation: mutation,
      input,
    })
    return [err, res]
  },
}
