import { html } from '@shared/string/intellisense'
import { formatCurrency, formatNumber } from '@src/shared/utils'

type KeyCss = {
  color: string
}

type Val = {
  value: string
  keyCss: KeyCss
}

type KeyVals = {
  [key: string]: Val
}

const setKeyCss = (css: KeyCss) => {
  return `
  font-size: 9px;
  line-height: 13px;
  letter-spacing: $content-letter-spacing * 2;
  text-transform: uppercase;
  color: ${css.color};`
}

const valueCss = () => {
  return `
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #2E2D38;`
}

const iterateKeyVals = (keyVals: KeyVals) => {
  const rt = Object.keys(keyVals)
    .map(key => {
      const val: Val = keyVals[key]
      const keyLower = key.toLowerCase()

      let func = () => formatNumber(val.value)
      if (keyLower === 'money') {
        func = () => formatCurrency(val.value, 2)
      }

      return html`
        <div style="${setKeyCss(val.keyCss)}"> ${key} </div>
        <div style="${valueCss()}"> ${func()} </div>
      `
    })
    .join('<br/>')

  return rt
}

const getTooltip = (keyVals: KeyVals) => html`
  <div> ${iterateKeyVals(keyVals)} </div>
`

const getTooltipContainerCss = (extraCss: string) => {
  return `
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    ${extraCss}
  `
}

export { getTooltip, getTooltipContainerCss }
