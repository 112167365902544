import { MutableKeys } from 'utility-types'

// Component Types
type Col = {
  md: number
}

type Content = {
  col: Col
}

type Options = {
  content: Content
}

export type Item = {
  text: string
  options: Options
  disabled: boolean
  title?: string
}

export type ItemMap = Record<string, Item>

export type Props = {
  initialActiveItemKey: string
  title: string
  title2: string
  noNav: boolean
  // header: string
  itemMap: ItemMap
  loading: boolean
}

// Vue-metadata types

type VuePropsKeys = MutableKeys<Props>[]
export const vueProps: VuePropsKeys = [
  'title',
  'title2',
  'noNav',

  // 'header',
  'itemMap',
  'initialActiveItemKey',
  'loading',
]
