// credit: https://github.com/streamich/react-use/blob/master/src/useLocalStorage.ts

export const useLocalStorage = (key: string, raw?: boolean) => {
  const getValue = () => {
    try {
      const localStorageValue = localStorage.getItem(key)
      return raw ? localStorageValue : JSON.parse(localStorageValue || 'null')
    } catch (e) {
      // If user is in private mode or has storage restriction
      // localStorage can throw. JSON.parse and JSON.stringify
      // can throw, too.
      console.error(e)
      return ''
    }
  }

  const setValue = val => {
    try {
      localStorage.setItem(key, JSON.stringify(val))
    } catch (e) {
      console.error(e)
    }
  }

  const remove = () => localStorage.removeItem(key)

  // deletes all keys and valus
  const clear = () => {
    try {
      localStorage.clear()
    } catch (e) {
      console.error(e)
    }
  }

  return { getValue, remove, setValue, clear }
}

export default useLocalStorage
