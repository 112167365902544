<script>
import * as api from './api'
import { hexToRgba } from '@styles/utils'
import { globalColorPrimary } from '@scss-ts'

import * as echarts from 'echarts/lib/echarts'

export default {
  name: 'AreaChart',

  props: {
    id: {
      type: String,
      required: true,
    },
    valueIsDollars: Boolean,
    height: {
      type: [Number, String],
      required: true,
    },
    areaColor: {
      type: String,
      default: '',
    },
    dataMap: {
      type: Object,
      default: () => ({}),
    },
    seriesData: {
      type: Array,
      default: () => [],
    },
    isEmptyOpts: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      chart: null,
    }
  },
  computed: {},
  watch: {
    seriesData() {
      this.setOption()
    },
    areaColor() {
      this.setOption()
    },
  },

  mounted() {
    this.chart = api.init(document.getElementById(this.id))
    this.setOption()
    this.chart.resize()
  },
  methods: {
    handleResize() {
      if (this.chart) {
        this.setOption()
        this.chart.resize()
      }
    },
    setOption() {
      const color = this.areaColor || globalColorPrimary
      const color1 = hexToRgba(color, '0.3')
      const color2 = hexToRgba(color, '0')

      const allOptions = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              formatter: params => {
                const rt = api.formatTooltipLabel(
                  params?.value,
                  this.valueIsDollars
                )
                return rt
              },
            },
          },
        },
        series: [
          {
            data: this.seriesData,
            color,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: color1 },
                { offset: 1, color: color2 },
              ]),
            },
          },
        ],
      }

      api.setOption(this.chart, { ...allOptions }, null, {
        ...this.isEmptyOpts,
      })
    },
  },
}
</script>

<template>
  <div v-resize="handleResize" :class="$style.root" :style="'height:' + height">
    <div :id="id" style="width: 100%; height: inherit"></div>
  </div>
</template>

<style lang="scss" module>
@import '@scss';

.root {
  width: 100%;
}
</style>
