// This store is used to track the amount of times an Ad was cloned.
// Done so we can append the appropriate suffix to the Ad title
// (similar to when an OS copy/paste of a file)
export const state = {
  ads: {},
}

export const getters = {
  ads({ ads }) {
    return ads
  },
}

export const mutations = {
  SET_ADS(state, data) {
    state.ads = data
  },
}

export const actions = {
  async increaseAdClonedAmount({ commit, state }, id) {
    const ads = state.ads

    if (id in ads) {
      ads[id] = ads[id] + 1
    } else {
      ads[id] = 1
    }

    commit('SET_ADS', ads)
  },

  async getAdClonedAmount({ state }, id) {
    const ads = state.ads

    // check if ad id is within the state
    // if so return the current amount cloned
    if (id in ads) {
      return ads[id]
    }

    // else return the base num for the suffix
    return 0
  },
}
