import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { cs } from '@utils'

import { saveClients } from '@graphql'

export function getGraphqlProvider(customResolvers) {
  if (Vue._installedPlugins.indexOf(VueApollo) < 0) {
    Vue.use(VueApollo)
  }

  const options = { errorHandler, clients: {} }

  // Now only defaultClient can be used because raw typedef schema has been removed
  const defaultClientModule = require('./defaultClient')
  const defaultClient = defaultClientModule.default()

  options.defaultClient = defaultClient

  cs.i(`Using Default Apollo Client without mocked data`)

  const extraApolloKeys = Object.keys(options.clients)
  if (extraApolloKeys && extraApolloKeys.length > 0) {
    cs.i(`Extra Apollo clients: ${extraApolloKeys}`)
  }

  // note: multiple apollo clients wont show in chrome devtools
  // issue https://github.com/apollographql/apollo-client/pull/4511
  const provider = new VueApollo(options)

  saveClients(provider.clients)

  return provider
}

/**
 * private methods
 * */

function errorHandler(error) {
  const isProduction = process.env.NODE_ENV === 'production'
  if (!isProduction) cs.e(error && error.message, '%cApollo Error')
}
