import { getTags } from '@shared/backend'
import { Maybe, MetaTagInput, TagTypeEnum } from '@graphql-types'
import * as errorMessages from '@shared/error/message'
import { ALLOWED_CHARS_REGEX } from '@shared/regexes'

// constants
export const GAME_RESTRICTING_KEY = 'gr'
export const GAME_RESTRICTING_NAME = 'Game restricting'

export const AD_RESTRICTING_KEY = 'ar'
export const AD_RESTRICTING_NAME = 'Ad restricting'

export const INTERNAL_KEY = 'in'
export const INTERNAL_NAME = 'Internal Label'

// validation constants
export const MAX_LEN = 60 // database max is 64, FE sends prefix e.g. ar:, 61 + 3, used 60 to make it nicer

// private consts
const UNKNOWN_KEY = 'unknown'

/**
 * METHODS
 */

export const getMetaTagsAsString = async ({
  includeType = false,
  gqlVariables = { types: [TagTypeEnum.MATCHING] },
} = {}) => {
  // @ts-ignore
  const [_, res] = await getTags({ ...gqlVariables })

  const items: any[] =
    (res &&
      res.map(o => {
        // returns as  [type]:[tag] format
        if (includeType) return o?.tag ?? ''

        // returns as [tag] format
        // @ts-ignore
        const [_, tag] = splitMetaTagsFromType(o?.tag)
        return tag
      })) ||
    []

  return items
}

// splits the tags from its prefix/type, assuming [type]:[tag]
export const splitMetaTagsFromType = (input: string) => {
  let type = UNKNOWN_KEY
  let tag = ''

  if (!input) return [type, tag]

  const toks = input.split(':') ?? ['', '']

  // check if tag without prefix
  if (toks.length < 2) {
    type = ''
    tag = toks[0] ?? ''
  } else {
    type = toks[0] ?? ''
    tag = toks[1] ?? ''
  }

  if (!type) type = UNKNOWN_KEY

  return [type, tag]
}

// parses tags from server to a map of tag type and their tags
export const toMetaTagTypeMap = (tags: Maybe<Array<MetaTagInput>>) => {
  const map = {
    [AD_RESTRICTING_KEY]: [],
    [GAME_RESTRICTING_KEY]: [],
    [INTERNAL_KEY]: [],
    [UNKNOWN_KEY]: [],
  }

  if (!tags) return map

  tags.forEach(o => {
    const [type, tag] = splitMetaTagsFromType(o?.tag ?? '')

    if (map[type]) {
      map[type].push(tag)
    } else {
      // @ts-ignore
      map[UNKNOWN_KEY].push(tag)
    }
  })

  return map
}

// maps meta tag data from server to the required format of a select component item
export const mapMetaTagsToSelectItems = (tags: Maybe<Array<MetaTagInput>>) => {
  // todo: unifiy select item types
  const out: any[] = []
  const map = toMetaTagTypeMap(tags)

  Object.entries(map).forEach(([k, v]) => {
    v &&
      v.forEach(tag => {
        const selectItemValue = `${k}:${tag}`

        out.push({
          text: tag,
          value: selectItemValue,
          chip: { name: k.toUpperCase() },
        })
      })
  })

  return out
}

// maps the meta tag component items (e.g. from combo box) to format required by the server
export const mapComponentItemsToMetaTagInputs = (
  tagObjects: any[]
): MetaTagInput[] => {
  let out: MetaTagInput[] = []

  const tmp = tagObjects.map(o => {
    const tagType = o?.type ?? ''
    const items = o?.items ?? []
    const x = items.map(item => `${tagType}:${item}`)
    return x
  })

  tmp.forEach(arr => {
    arr.forEach(x => {
      out.push({ tag: x, internal: false })
    })
  })

  return out
}

// e.g. for use in combobox
export const groupMetaTagsByType = (tags: Maybe<Array<MetaTagInput>>) => {
  if (!tags) return []

  const map = toMetaTagTypeMap(tags)
  const out = Object.entries(map)
    .map(([k, v]) => {
      if (k === UNKNOWN_KEY) {
        return { type: '', items: v }
      }
      return { type: k, items: v }
    })
    .filter(x => x.items.length > 0)

  return out
}

/**
 * VALIDATION
 */

// returns [valid, errorMessage]
export const validateTag = (tag: string): [boolean, string] => {
  const regex = new RegExp(ALLOWED_CHARS_REGEX)

  const LABEL = 'Meta tag'

  if (tag && tag.length > MAX_LEN) {
    return [false, errorMessages.maxLen(LABEL, MAX_LEN)]
  }
  if (tag && !regex.test(tag)) {
    return [false, errorMessages.invalidChars(LABEL)]
  }

  return [true, '']
}
