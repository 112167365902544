import gql from 'graphql-tag'
import { output } from '../../shared/fragments/mutationOutput'

export const pauseAdSpaces = gql`
  mutation pauseAdSpaces($ids: [ID]!) {
    pauseAdSpaces(ids: $ids) {
      ...output
    }
  }
  ${output}
`

export const unpauseAdSpaces = gql`
  mutation unpauseAdSpaces($ids: [ID]!) {
    unpauseAdSpaces(ids: $ids) {
      ...output
    }
  }
  ${output}
`

export const archiveAdSpaces = gql`
  mutation archiveAdSpaces($ids: [ID]!) {
    archiveAdSpaces(ids: $ids) {
      ...output
    }
  }
  ${output}
`

export const unarchiveAdSpaces = gql`
  mutation unarchiveAdSpaces($ids: [ID]!) {
    unarchiveAdSpaces(ids: $ids) {
      ...output
    }
  }
  ${output}
`
