export default {
  props: {
    message: { type: String, default: '' },
    // validation will be outside the component
    // and just pass through the result to here
    error: { type: Boolean },
    success: { type: Boolean },
  },

  computed: {
    state() {
      let state = 'default'

      if (this.error) state = 'error'
      if (this.success) state = 'success'

      return state
    },

    hasState() {
      if (this.error && this.success) {
        console.warn(
          '[Component Input]: Error and Success cannot happen in the same time'
        )
      }
      return this.error || this.success
    },
  },
}
