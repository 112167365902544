<script>
export default {
  name: 'CcIconList',
  props: {
    loading: Boolean,
    icons: {
      type: Array,
      default: () => [
        'cc-mastercard',
        'cc-visa',
        'cc-amex',
        'cc-jcb',
        'cc-diners-club',
      ],
    },
  },
}
</script>

<template v-if="loading">
  <fp-skeleton round :loading="loading" :width="300" :height="35" />
</template>
<template v-else>
  <div class="d-flex align-center">
    <div :class="$style.labelBox">
      <label :class="[$style.label, 'pr-1']"> Pay with: </label>
    </div>

    <div v-for="icon in icons" :key="icon">
      <fp-icon
        prefix="fab"
        :name="icon"
        :class="[$style.iconCc, 'mx-1']"
        size="2x"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@scss';

.iconCc {
  width: 100% !important;
  max-width: 1.125em;
  color: $color-black;
}
.labelBox {
  @extend %font-input-label;

  flex-shrink: 0;
}
</style>
