import gql from 'graphql-tag'
import { ad } from '../fragments/Ad'
import { insertionOrderDetails } from '../fragments/InsertionOrderDetails'

export const getAdReport = gql`
  query getAdReport(
    $ad: String!
    $startDate: AWSTimestamp!
    $endDate: AWSTimestamp!
  ) {
    getAdReport(ad: $ad, startDate: $startDate, endDate: $endDate) {
      rows
    }
  }
`

export const getAds = gql`
  query getAds(
    $ids: [ID]
    $limit: Int
    $skip: Int
    $orderBy: DefaultOrderByEnum
    $filter: AdFiltersInput
    $canAccessInsertionOrder: Boolean!
  ) {
    # Logged In User Account
    getAds(
      ids: $ids
      limit: $limit
      skip: $skip
      orderBy: $orderBy
      filter: $filter
    ) {
      skip
      limit
      total
      items {
        ...ad
        insertionOrderDetails @include(if: $canAccessInsertionOrder) {
          ...insertionOrderDetails
        }
      }
    }
  }
  ${ad}
  ${insertionOrderDetails}
`

export const getAdList = gql`
  query getAds(
    $ids: [ID]
    $limit: Int
    $skip: Int
    $orderBy: DefaultOrderByEnum
    $filter: AdFiltersInput
  ) {
    # Logged In User Account
    getAds(
      ids: $ids
      limit: $limit
      skip: $skip
      orderBy: $orderBy
      filter: $filter
    ) {
      items {
        id
        title
      }
    }
  }
`

export const getAdSummary = gql`
  query getAdSummary(
    $ads: [ID]!
    $limit: Int
    $skip: Int
    $orderBy: DefaultOrderByEnum
  ) {
    getAdSummary(ads: $ads, limit: $limit, skip: $skip, orderBy: $orderBy) {
      items {
        id
        nickname
        displayStartDate
        displayEndDate
        timezone
        adCreatives {
          originalName
          url
          thumbnailUrl
          contentType
          keyTag
          platforms
          size
          pixels
          mimeType
        }
        locations
      }
    }
  }
`

export const getDMAs = gql`
  query getDMAs($limit: Int, $skip: Int) {
    getDMAs(limit: $limit, skip: $skip) {
      items {
        title
        id
      }
    }
  }
`
