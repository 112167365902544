// import '@component-styles/icon.scss'
import { createComponent, reactive, computed } from '@vue/composition-api'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import style from './style.module.scss'

type Shape = 'circle' | 'square'
type ShapedClassMap = Record<Shape, string>

type Props = {
  source: 'font-awesome'
  prefix: string
  name: string
  shape: Shape
  background: string
  size:
    | 'xs'
    | 'sm'
    | 'lg'
    | '1x'
    | '2x'
    | '3x'
    | '4x'
    | '5x'
    | '6x'
    | '7x'
    | '8x'
    | '9x'
    | '10x'
}

export type IconColorMap<K extends string> = Record<
  K,
  {
    background: string
    color: string
    name: string
  }
>

export default createComponent({
  name: 'FpIcon',
  components: {
    FontAwesomeIcon,
  },
  inheritAttrs: false,
  props: {
    source: {
      type: String,
      default: 'font-awesome',
    },
    prefix: {
      type: String,
      required: false,
      default: 'fas',
    },
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: '1x',
    },
    shape: {
      type: String,
    },
    background: {
      type: String,
    },
  },
  setup(props: Props, context) {
    return () => {
      const state = reactive({
        shapedIconStyle: computed(() => {
          return `background: ${props.background}`
        }),
      })
      const map: ShapedClassMap = {
        circle: style.circleBox,
        square: style.squareBox,
      }

      let shapedClass = map[props.shape]
      if (!shapedClass && props.background) {
        shapedClass = map.square
      }

      const FaIcon = () => (
        <font-awesome-icon
          class="fp-icon"
          {...context.attrs}
          icon={[props.prefix, props.name]}
          size={props.size}
        />
      )
      const ShapedIcon = () => (
        <div class={shapedClass} style={state.shapedIconStyle}>
          <div class={style.shapedContent}>
            <FaIcon />
          </div>
        </div>
      )

      const rv = shapedClass ? <ShapedIcon /> : <FaIcon />
      return rv
    }
  },
})
