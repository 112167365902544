<script>
import { formatCurrency, substring } from '@utils'
// Client-wide Standard currency display component
// Currency: USD
// decimals: 2
export default {
  name: 'FpStdCurrency',

  props: {
    value: {
      type: [String, Number],
      default: '',
    },
  },

  computed: {
    money2Display() {
      return substring(formatCurrency(this.value, 2), 1)
    },
  },
}
</script>

<template>
  <div :class="$style.currencyWrap">
    <span :class="$style.currencySign">$</span>{{ money2Display }}
  </div>
</template>

<style lang="scss" module>
@import '@scss';

.currencyWrap {
  display: inline-block;
  font-weight: inherit;
}

.currencySign {
  margin-right: 1px;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  color: inherit;
  vertical-align: text-top;
}
</style>
