<template>
  <v-menu v-bind="propsPenetrating">
    <template v-slot:activator="{ on: { click } }">
      <div @click="click">
        <slot name="activator" />
      </div>
    </template>

    <slot></slot>
  </v-menu>
</template>

<script>
export default {
  name: 'FpMenu',
  props: {},
  computed: {
    propsPenetrating() {
      return { ...this.$attrs }
    },
  },
}
</script>

<style lang="scss" src="@component-styles/menu"></style>
