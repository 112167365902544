// @ts-ignore
import kebabCase from 'lodash/kebabCase'
import Vue from 'vue'

// Configurations
const STATIC_ELEMENT_PREFIX = '::'
const ELEMENT_SEP = '__'
const STATIC_MODIFIER_PREFIX = ':'
const MODIFIER_SEP = '--'
const DYNAMIC_MODIFIER_PREFIX = '$'
const STATE_PREFIX = 'is-'

let _nameSpace = ''

// ⚠️ Base machanism of BEM plugin
export function generateBem(block, element, modifier) {
  if (!block) {
    return ''
  }

  let className = ''

  if (element && modifier) {
    className = `${block}${ELEMENT_SEP}${element}${MODIFIER_SEP}${modifier}`
  } else if (element && !modifier) {
    className = `${block}${ELEMENT_SEP}${element}`
  } else if (!element && modifier) {
    className = `${block}${MODIFIER_SEP}${modifier}`
  } else if (!element && !modifier) {
    className = block
  }

  if (_nameSpace) {
    className = _nameSpace + className
  }

  return className
}

// TODO: test
export function getStatesAndModifiers(directiveValue, directiveModifiers) {
  const states = []
  const modifiers = []
  const result = {
    states,
    modifiers,
  }

  if (!directiveValue & !directiveModifiers) return result

  if (directiveModifiers) {
    Object.keys(directiveModifiers).forEach(modifierName =>
      modifiers.push(modifierName)
    )
  }

  if (directiveValue) {
    Object.entries(directiveValue).forEach(([key, value]) => {
      if (key.startsWith(DYNAMIC_MODIFIER_PREFIX)) {
        if ([undefined, null, false].includes(value)) return

        const modifierName = value
        return modifiers.push(modifierName)
      }

      if (value) {
        const stateName = key
        states.push(stateName)
      }
    })
  }

  return result
}

// Get classNames apart from BEM's
// TODO: test
export function getOriginalClassNameFromVnode(vnode) {
  const result = []
  const { data, context } = vnode

  // classes writed inside component
  const { class: dynamicClass, staticClass } = data
  dynamicClass && result.push(dynamicClass)
  staticClass && result.push(staticClass)

  // classes writed when use component
  // eg. <some-component :class="[name1, name2]" class="xxx"  />
  const { class: componentDynamicClass, staticClass: componentStaticClass } =
    context.$vnode.data

  // TODO: Find a better way to determine whether the element is a wrapper
  // refer: https://github.com/vuejs/vue/blob/dev/src/core/vdom/vnode.js#L13
  const isWrappeeOfComp = !!vnode.parent

  if (!isWrappeeOfComp) {
    return result
  }

  componentDynamicClass && result.push(componentDynamicClass)
  componentStaticClass && result.push(componentStaticClass)

  // Wrapper tag should inherit className from component
  // such as <comp :class="foo" />
  return result
}

export function joinBemWithOriginal({
  originalClassName,
  blockName,
  elementName,
  states,
  modifiers,
}) {
  const result = [...originalClassName]

  // Base block or block__element name
  result.push(generateBem(blockName, elementName))

  // Add modifiers
  if (modifiers.length) {
    modifiers.forEach(modifierName => {
      result.push(generateBem(blockName, elementName, modifierName))
    })
  }

  // Add states
  if (states.length) {
    states.forEach(stateName => {
      result.push(`${STATE_PREFIX}${stateName}`)
    })
  }

  return result.join(' ')
}

/**
 * This is directive's hook handler (for `bind` and `componentUpdated` both),
 * @feature Get directive arguments & values and Write final className for element
 * @sideEffect modify real node's className
 * @note if you use JSX inteand of template, please use BEMProvider function below
 * @return {void}
 */
export function useBemEffect(
  el,
  { value: directiveValue, arg: elementName, modifiers: directiveModifiers },
  vnode
) {
  const { states, modifiers } = getStatesAndModifiers(
    directiveValue,
    directiveModifiers
  )
  const instance = vnode.context
  const blockName = kebabCase(
    instance.$options.blockName || instance.$options.name
  )

  if (!blockName) {
    return console.warn('[BEM]: Component name is required: ', vnode.context)
  }

  const bemData = {
    originalClassName: getOriginalClassNameFromVnode(vnode),
    nameSpace: _nameSpace,
    blockName,
    elementName,
    states,
    modifiers,
  }

  el.__bem__ = bemData

  const resultClassName = joinBemWithOriginal(bemData)

  if (resultClassName) {
    el.className = resultClassName
  }
}

const BEMDirective = {
  bind: useBemEffect,
  inserted: useBemEffect,
  componentUpdated: useBemEffect,
}

/**
 * BEM classname provider, esay to get a string of classes reflect current states
 * @return { Function } function for generate { class: string } like object
 * @example const bem = BEMProvider('b') => <div {...bem('::text', { loading })} />
 */
export const BEMProvider = function (blockName) {
  const bem = {
    blockName,
    elementName: '',
    modifierName: '',
  }

  function __getClassName({ blockName, elementName, modifierName }) {
    return generateBem(blockName, elementName, modifierName)
  }

  // Define:
  // type TSates = { [stateName]: boolean, $[modiferName]: string }
  // function(states: TSates): { class: string }
  // function(bemDefineString: string): { class: string }
  // function(bemDefineString: string, states: TSates): { class: string }
  return function (...args) {
    const result = []

    function __walkStates(obj) {
      return function (stateName) {
        // The result className
        let cls = ''

        if (obj[stateName]) {
          if (stateName.startsWith(DYNAMIC_MODIFIER_PREFIX)) {
            bem.modifierName = obj[stateName]

            cls = __getClassName(bem)
          } else {
            cls = `is-${stateName}`
          }

          result.push(cls)
        }
      }
    }

    if (args.length === 1 && typeof args[0] === 'object') {
      const states = args[0]
      // Only have states or modifiers
      result.push(blockName)

      Object.keys(states).forEach(__walkStates(states))
    } else if (args.length >= 1 && typeof args[0] === 'string') {
      // Have extra classNames

      const classList = args[0].split(' ')

      const isElement = classList[0].startsWith(STATIC_ELEMENT_PREFIX)

      if (isElement) {
        bem.elementName = classList[0].slice(STATIC_ELEMENT_PREFIX.length)
      }

      classList.forEach(input => {
        let cls = input
        if (cls.startsWith(STATIC_ELEMENT_PREFIX)) {
          // Block__Element
          const lastElement = bem.elementName
          bem.elementName = cls.slice(STATIC_ELEMENT_PREFIX.length)
          cls = __getClassName(bem)
          bem.elementName = lastElement
        } else if (cls.startsWith(STATIC_MODIFIER_PREFIX)) {
          // Block--Modifier
          bem.modifierName = cls.slice(STATIC_MODIFIER_PREFIX.length)
          cls = __getClassName(bem)
        } else if (cls === '$b') {
          cls = blockName
        }

        result.push(cls)
      })

      if (typeof args[1] === 'object') {
        const states = args[1]
        const stateNameList = Object.keys(states)

        if (stateNameList.length) {
          stateNameList.forEach(__walkStates(states))
        }
      }
    } else if (!args.length) {
      result.push(blockName)
    }

    // Reset after each round
    bem.modifierName = ''
    bem.elementName = ''

    // preitter-disable-next-line
    return { class: result.join(' ') }
  }
}

const BEMPlugin = {
  install(Vue, options) {
    if (options && options.nameSpace) {
      _nameSpace = options.nameSpace + '-'
    }

    Vue.directive('bem', BEMDirective)

    Vue.prototype.$bem = function (...args) {
      const currentBem = BEMProvider(
        kebabCase(this.$options.blockName || this.$options.name)
      )

      return currentBem(...args).class
    }
  },
}

export default function useBEM(options) {
  Vue.use(BEMPlugin, options)
}
