<template>
  <!-- todo: handle different input types. example here https://codesandbox.io/s/2wyrp5z000?from-embed -->
  <!-- https://medium.com/@logaretm/authoring-validatable-custom-vue-input-components-1583fcc68314 -->
  <div v-bem="{ ice, $textAlign: `text-${textAlign}` }">
    <v-textarea
      v-if="isTextarea"
      ref="input"
      :value="value"
      v-bind="propsPenetrating"
      type="input"
      v-on="listenersPenetrating"
      @input="inputHandler"
      @change="changeHandler"
      @keyup.native="keyboardEventHandlers"
      @keydown.native="keyboardEventHandlers"
      @keypress.native="keyboardEventHandlers"
    />

    <v-text-field
      v-else-if="isMoney"
      ref="input"
      v-model="modValue"
      :value="compValue"
      v-bind="propsPenetrating"
      type="tel"
      @input="inputHandler"
      @change="changeHandler"
      @keyup.native="keyboardEventHandlers"
      @keydown.native="keyboardEventHandlers"
      @keypress.native="keypressEventHandlers"
    />

    <v-text-field
      v-else-if="inputDebounced"
      ref="input"
      :value="value"
      v-bind="propsPenetrating"
      v-on="listenersPenetrating"
      @input="debouncedOnInput"
      @change="changeHandler"
      @keyup.native="keyboardEventHandlers"
    />

    <v-text-field
      v-else
      ref="input"
      :value="value"
      v-bind="propsPenetrating"
      v-on="listenersPenetrating"
      @input="inputHandler"
      @change="changeHandler"
      @keyup.native="keyboardEventHandlers"
    />
  </div>
</template>

<script>
import vuetifyIcon from '@component-mixins/vuetifyIcon'
import vuetifyMoney from '@component-mixins/vuetifyMoney'

import { omit } from '@shared/utils'
import { debounce } from 'lodash'
import { round, trimStartingZerosForDecimals } from '@shared/math/helpers'

export default {
  name: 'FpInput',

  mixins: [vuetifyIcon, vuetifyMoney],

  inheritAttrs: false,

  inject: ['$validator'],

  $_veeValidate: {
    // This is not documented well. When using raw html input returning the value
    // is fine like this: this.$el.value. But, if you use a custom component need to
    // get the value of the ref that is bound to the custom component's input. Otherwise,
    // the vee-validate confirm rule won't work. For "vue": "2.6.10". See:
    // https://github.com/baianat/vee-validate/issues/1896
    // https://codesandbox.io/s/9l7r2mlxwy
    value() {
      return this.value
    },

    // name getter
    name() {
      return this.name
    },
  },

  props: {
    // Vuetify Defaults
    appendIcon: { type: String, default: '' },
    error: Boolean,
    hint: { type: String, default: '' },
    icon: { type: String, default: '' },
    label: { type: String, default: '' }, // label and placeholder all are considered as label
    message: { type: String, default: '' },
    name: { type: String, default: '' },
    persistentHint: { type: Boolean, default: true },
    placeholder: { type: String, default: '' }, // label and placeholder all are considered as label
    prependIcon: { type: String, default: '' },
    required: Boolean,
    value: { type: [String, Number], default: '' },
    textAlign: { type: String, default: 'left' },
    inputDebounced: { type: Boolean, default: false },
    trimValue: { type: Boolean, default: true },

    // Custom Props
    ice: Boolean,
    type: {
      type: String,
      default: 'text',
      validator: val => {
        return [
          'text',
          'tel',
          'date',
          'month',
          'password',
          'email',
          'number',
          'address',
          // Not real HTML type attrs
          'textarea',
          'money',
        ].includes(val)
      },
    },

    // TODO: implement max and min for number and money
    // max: { type: Number, default: Infinity },
    // min: { type: Number, default: -Infinity },
    formatNumber: { type: Boolean, default: false },
    // Add step="any" to address browser validation: https://stackoverflow.com/questions/48892570/please-enter-a-valid-value-the-two-nearest-valid-value-is
    step: { type: String, default: 'any' },
    isInteger: { type: Boolean, default: false },
  },

  computed: {
    isTextarea() {
      return this.type === 'textarea'
    },
    isMoney() {
      return this.type === 'money'
    },
    isNumber() {
      return this.type === 'number'
    },

    propsPenetrating() {
      const {
        label,
        placeholder,
        type,
        error,
        required,
        message: errorMessages,
        persistentHint,
        icon,
        hint,
        name,
        step,
        $attrs,
      } = this

      // Transform icon String for Vuetify input components
      // https://vuetifyjs.com/en/framework/icons
      let formatAppend = this.vuetifyIcon(this.appendIcon)
      let formatPrepend = this.vuetifyIcon(this.prependIcon || icon)

      const props = {
        label,
        placeholder,
        outlined: true,
        type,
        name,
        persistentHint,
        hint,
        errorMessages,
        error,
        appendIcon: formatAppend,
        prependInnerIcon: formatPrepend,
        ...$attrs,
        disabled: $attrs.loading || $attrs.disabled,
      }

      if (required) {
        props.label += '*'
      }

      if (this.isNumber) {
        props.step = step
      }

      return props
    },

    // Penetrating listeners not used by money input**
    listenersPenetrating() {
      const listeners = { ...this.$listeners }

      // remove the event listener for onInput
      // input events causes the lagging issue: https://lowlatencymedia.atlassian.net/browse/PR-2821
      // the only exception to this is money text fields.
      if (this.isMoney) {
        return omit(listeners, ['keyup', 'keydown', 'keypress'])
      }

      return omit(listeners, ['keyup', 'keydown', 'keypress', 'input'])
    },
  },

  mounted() {
    this.$refs.input.setLabelWidth()
  },

  created() {
    // We need to debounce the onInput changes so it doesn't lag the fields.
    // This is needed because certain fields (username and passwords) need onInput to be used instead of the onChange method.
    // Particularly for iOS and password managers, the autofill will not work correctly:
    // e.g the value will not be updated in vue when autofilled and will throw "The password field is required" even if it is filled visually
    this.debouncedOnInput = debounce(this.debouncedInputHandler, 200)
  },

  methods: {
    debouncedInputHandler(value) {
      let emitValue = value

      // We need to trim the input values for almost all inputs: https://lowlatencymedia.atlassian.net/browse/PR-4282
      // The only except would be the password fields which shouldn't be trimed. In this case the new trimValue prop will manage this.
      // On default the value is trimed and if trimValue is set to false we'll skip.
      if (this.trimValue) {
        emitValue = emitValue.trim()
      }

      if (this.isMoney) {
        emitValue = this.machineFormat(this.modValue)
      }
      this.$emit('input', emitValue)
    },
    inputHandler(value) {
      if (this.isNumber) {
        if (!this.formatNumber) return
        let formatValue = value

        if (this.isInteger) {
          formatValue = formatValue.replaceAll('.', '')
        } else {
          formatValue = this.machineFormat(formatValue)
        }

        let roundValue = formatValue
        if (this.isInteger) {
          // format number to integer, for fields such as impression count
          roundValue = `${round(formatValue, 0)}`
        } else {
          // format number to 2 decimals, for fields such as percentage
          roundValue = trimStartingZerosForDecimals(formatValue)
        }

        if (roundValue) {
          this.$emit('input', roundValue)
        }
      }
      // if this is a money field then emit else use onChange instead
      if (this.isMoney) {
        this.$emit('input', this.machineFormat(this.modValue))
      }
    },
    changeHandler(value) {
      let emitValue = value || ''

      if (this.trimValue) {
        emitValue = emitValue.trim()
      }

      if (this.isMoney) {
        emitValue = this.machineFormat(this.modValue)
      }

      // emit the input event here as a workaround for removing the input event
      // see https://lowlatencymedia.atlassian.net/browse/PR-2821 for more details
      this.$emit('input', emitValue)
      this.$emit('change', emitValue)
    },

    keyboardEventHandlers(e) {
      if (!e) return

      const listener = this.$listeners[e.type]
      listener && typeof listener === 'function' && listener(e)
    },
  },
}
</script>

<style lang="scss" src="@component-styles/input"></style>
