import gql from 'graphql-tag'
import store from '@state/store'
import { replaceAll } from '../utils'
import { OrgGetters } from '@src/state/modules/org.store'
import { ORG_VERIFY_STATUS } from '../constants'

const getOrgVerifyByIds = <T = any>(
  ids: string[]
): Promise<[any, { items: T[] } | null]> => {
  const query = gql`
    query getOrgVerifications($ids: [ID]) {
      getOrganisations(ids: $ids) {
        items {
          id
          title
          status
          isStudent
        }
      }
    }
  `
  return store.dispatch('tryQuery', {
    query,
    variables: { ids },
  })
}

/** Either not verified, rejected or pending review, suspensed would be considered as Unverified! */
const isThisErrorBecauseUnverified = err => {
  if (err?.statusCode)
    return ['UP300', 'UP301', 'UP302', 'UP303'].includes(err.statusCode)
  return false
}

export const isVerificationStatus = statusToVerify => {
  if (!statusToVerify) return null

  // ’UNDER REVIEW‘ -> ’UNDER_REVIEW‘
  const key = replaceAll(statusToVerify.trim(), ' ', '_')

  const storedStatus = OrgGetters('verifyStatus').toLowerCase()

  const entry = ORG_VERIFY_STATUS[key].toLowerCase()

  // check agaisnt bool specifically in your logic because verification is
  // getting data from backend. If you click button too early, it may return null
  if (!storedStatus || !entry) return null

  return storedStatus === entry
}

export { getOrgVerifyByIds, isThisErrorBecauseUnverified }
