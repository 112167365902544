<template>
  <v-dialog ref="dialog" v-model="dialogVisible" width="800" persistent>
    <template #activator="{ on }">
      <div v-bem="{ error }">
        <div v-if="!hideLabel" v-bem:label-wrapper>
          <label v-bem:label> Locations </label>
        </div>

        <ul v-bem:activator>
          <li
            v-for="countryBlock in selectedBlocks"
            :key="countryBlock.code"
            v-bem:value
          >
            <fp-locations-block
              :tabindex="$attrs.tabindex"
              :block="countryBlock"
              @edit="modifyBlock(countryBlock)"
              @delete="deleteBlock(countryBlock)"
            />
          </li>

          <li
            v-bem:add-button
            v-ripple
            tabindex="0"
            v-on="on"
            @keydown.enter="on.click"
          >
            <fp-icon name="plus-circle" />

            {{ placeholder + `${required ? '*' : ''}` }}
          </li>
        </ul>

        <div v-if="message" v-bem:message>
          {{ message }}
        </div>

        <div v-if="hint" v-bem:hint>
          {{ hint }}
        </div>
      </div>
    </template>

    <fp-card padding="0">
      <header v-bem:header>
        <h3>Select Locations</h3>
        <small>Common locations to choose from:</small>

        <ul v-bem:presets>
          <li
            v-for="item in presetsNotChecked"
            :key="item.code"
            v-bem:preset-pill
            v-ripple
            @click="addBlock(item)"
          >
            <img v-bem:flag :src="getCountryFlagImg(item.code)" />

            <div class="name">
              {{ item.name }}
            </div>

            <fp-icon class="icon" name="plus-circle" />
          </li>
        </ul>
      </header>

      <div v-bem:body>
        <section v-bem:col>
          <header v-bem:col-header>
            <div v-bem:filter="{ active: filterActive }">
              <fp-icon name="filter" />
              <!-- maybe use trie data structure for country+division search
              https://www.youtube.com/watch?v=zIjfhVPRZCg
              https://github.com/joshjung/trie-search -->
              <input
                v-model="searchString"
                v-bem:filter-input
                placeholder="Search locations"
                type="text"
              />
            </div>
          </header>

          <div v-bem:scroll-container>
            <div
              v-if="!searching"
              class="fp-locations__scroll-inner"
              :style="{ paddingLeft: '10px' }"
            >
              <div v-bem:global-item>
                <fp-checkbox :value="isGlobal" @change="handleGlobalChange" />

                <span v-bem:global-text> Global </span>
              </div>

              <v-treeview
                ref="treeViewComp"
                :value="treeViewValue"
                selectable
                :open.sync="openedCountries"
                open-on-click
                selected-color="primary"
                item-key="code"
                item-children="divisions"
                :items="countries"
                expand-icon="$caretDown"
                @input="handleSelect"
              >
                <template #append="{ item }">
                  <!-- USED AS SCROLL ARCHOR -->
                  <span :data-code="item.code" />
                </template>
              </v-treeview>
            </div>

            <div v-else v-bem:searching>
              <v-progress-circular
                size="30"
                width="3"
                indeterminate
                color="primary"
              />
            </div>
          </div>
        </section>

        <section v-bem:col>
          <header v-bem:col-header>
            <h5>Selected Locations</h5>

            <div v-ripple v-bem:button @click="clearAll"> Clear All </div>
          </header>

          <div v-bem:scroll-container>
            <div v-bem:scroll-inner>
              <template v-if="selectedBlocks.length">
                <fp-locations-block
                  v-for="countryBlock in selectedBlocks"
                  :key="countryBlock.code"
                  :block="countryBlock"
                  plain
                  @edit="modifyBlock(countryBlock)"
                  @delete="deleteBlock(countryBlock)"
                />
              </template>

              <small v-else v-bem:empty>
                Pick locations on the left side or header
              </small>
            </div>
          </div>
        </section>
      </div>

      <footer v-bem:footer>
        <fp-button size="small" @click="confirmCloseDialog"> OK </fp-button>
      </footer>
    </fp-card>
  </v-dialog>
</template>

<script>
import _difference from 'lodash/difference'

import FpLocationsBlock from './locations-block'

import { getCountryFlagImg } from '@shared/countries'
import * as countryWorker from '@shared/countries/api'

let searchTimeout

/** @Block's schema */
// block: {
//   name: 'country name',
//   code: 'country code',
//   divisions: {
//     selected: [
//       {
//         name: 'division name',
//         code: 'division code',
//       },
//     ],
//     total: 1,
//   },
// },

export default {
  blockName: 'fp-locations',

  name: 'FpLocationsSelect',

  components: {
    FpLocationsBlock,
  },

  $_veeValidate: {
    value() {
      return this.value
    },
    name() {
      return this.name
    },
  },

  props: {
    name: { type: String, default: '' },

    value: {
      type: Array,
      default: () => [],
    },

    blocks: {
      type: Array,
      default: () => [],
    },

    label: {
      type: String,
      default: 'Locations',
    },

    placeholder: {
      type: String,
      default: 'Select locations',
    },

    required: Boolean,
    error: Boolean,
    message: { type: String, default: '' },
    hint: { type: String, default: '' },
    hideLabel: { type: Boolean, default: false },
  },

  data() {
    return {
      dialogVisible: false,

      globalChecked: false,
      // Sometime invoke updateValue will trigger handleSelect unexceptedly
      // so we need a flag to prevent v-treeview reset value to empty array
      doNotTriggerTreeViewInputEvent: false,

      // If user select Global, cache the current value, then if user unselect Global, revert it
      cachedValue: [],
      // When user search locations, use `valueFiltering` as treeView's value
      valueFiltering: [],
      justAssignedValueFiltering: false,

      searching: false,
      searchString: '',

      countries: [],
      openedCountries: [],

      selectedBlocks: [],

      presets: [
        { code: 'US', name: 'USA' },
        { code: 'AU', name: 'Australia' },
        { code: 'GB', name: 'United Kingdom' },
        { code: 'JP', name: 'Japan' },
        { code: 'KR', name: 'South Korea' },
      ],
    }
  },

  computed: {
    filterActive() {
      return !!this.searchString
    },

    isGlobal() {
      return this.value.length === 1 && this.value.includes('ALL')
    },

    treeViewValue() {
      if (this.isGlobal) {
        return []
      }

      if (this.filterActive) {
        return this.valueFiltering
      }

      return this.value
    },

    presetsNotChecked() {
      return this.presets.filter(({ code }) => {
        return !this.selectedBlocks.find(block => {
          return block.code === code
        })
      })
    },
  },

  watch: {
    dialogVisible(val) {
      document.documentElement.setAttribute(
        'data-scroll',
        val ? 'no-scroll' : 'auto'
      )

      if (val && !this.isGlobal) {
        this.emitTreeViewSelected()
      }
    },

    searchString(val) {
      clearTimeout(searchTimeout)

      this.searching = true

      searchTimeout = setTimeout(async () => {
        this.valueFiltering = [...this.value]

        await this.initTree()

        this.searching = false

        this.justAssignedValueFiltering = true

        this.emitTreeViewSelected()
      }, 280)
    },

    value: {
      handler: function (val) {
        this.syncSelectBlocks(val)
      },
      immediate: true,
    },
  },

  created() {
    this.initTree()
  },

  methods: {
    getCountryFlagImg,

    async initTree() {
      this.countries = await countryWorker.getAll({
        fields: ['code', 'name', 'divisions'],
        search: this.searchString,
        excludedCodes: ['CN', 'KP'],
      })
    },

    clearAll() {
      if (this.value.length === 0) return

      this.updateValue([])
    },

    hideDialog() {
      this.searchString = ''
      this.dialogVisible = false
    },

    confirmCloseDialog() {
      this.hideDialog()

      if (this.isGlobal || this.value.length === 0) return

      // Squash same-country-division codes if they could make up the whole country
      // e.g. ['AU-VIC', 'AU-TSM', '...AU-REST'] ==> ['AU']
      const valueAfterSquash = this.selectedBlocks
        .map(block => {
          const selectedDivisions = block.divisions.selected
          if (
            selectedDivisions.length === 0 ||
            selectedDivisions.length === block.divisions.total
          ) {
            return block.code
          }

          return selectedDivisions.map(dv => dv.code)
        })
        .flat()

      // Squash value will trigger handleSelect, we dont want it happen
      this.doNotTriggerTreeViewInputEvent = true
      this.updateValue(valueAfterSquash)
    },

    async syncSelectBlocks(value) {
      this.selectedBlocks = await countryWorker.genBlocksByCodes(value)
      this.$emit('update:blocks', this.selectedBlocks)
    },

    handleGlobalChange(checked) {
      if (checked) {
        this.cachedValue = [...this.value]

        // updateValue['ALL'] actiion will trigger handleSelect([])
        this.doNotTriggerTreeViewInputEvent = true
        this.updateValue(['ALL'])
      } else {
        this.updateValue([...this.cachedValue])
      }
    },

    /** Let treeview check country/division's checkboxs corresponding to current treeViewValue */
    /** And will expand country code to it's all divisions code, eg. ['AU'] -> ['AU-VIC', ...all] */
    /** ⚠️ Note this method will trigger treeview's input event */
    emitTreeViewSelected() {
      this.$nextTick(() => {
        this.$refs.treeViewComp.emitSelected()
      })
    },

    /** 1️⃣ Handle v-treeview selection change by user select */
    /** 2️⃣ Handle v-treeview emitSelected invoked by this.emitTreeViewSelected */
    /** 3️⃣ Handle v-treeview emitSelected invoked by searchString change */
    handleSelect(value) {
      if (this.justAssignedValueFiltering) {
        this.justAssignedValueFiltering = false
        this.valueFiltering = [...value]

        return
      }

      if (this.doNotTriggerTreeViewInputEvent) {
        this.doNotTriggerTreeViewInputEvent = false
        return
      }

      if (this.filterActive) {
        const codesJustRemoved = _difference(this.valueFiltering, value)
        const codesJustAdded = _difference(value, this.valueFiltering)

        const newValueToEmit = [...this.value]

        if (codesJustAdded.length) {
          newValueToEmit.push(...codesJustAdded)
        }

        if (codesJustRemoved.length) {
          codesJustRemoved.forEach(code => {
            const index = newValueToEmit.findIndex(c => c === code)
            if (index !== -1) {
              newValueToEmit.splice(index, 1)
            }
          })
        }

        this.valueFiltering = [...value]

        this.updateValue(this.isGlobal ? this.valueFiltering : newValueToEmit)

        return
      }

      this.updateValue(value)
    },

    modifyBlock(country) {
      this.searchString = ''
      this.dialogVisible = true

      this.$nextTick(() => {
        this.$nextTick(() => {
          this.scrollCountryIntoView(country)
        })
      })
    },

    addBlock({ code, name }) {
      if (this.isGlobal) {
        this.updateValue([...this.cachedValue, code])
      } else {
        this.updateValue([...this.value, code])
      }
    },

    deleteBlock({ code: ctCode }) {
      const valueAfterDeleteThisCountry = this.value.reduce((result, code) => {
        if (!code.includes(ctCode)) {
          result.push(code)
        }

        return result
      }, [])

      this.updateValue(valueAfterDeleteThisCountry)
    },

    scrollCountryIntoView({ code }) {
      const ele = this.$refs.treeViewComp.$el
      const target = ele.querySelector(`[data-code=${code}]`)

      if (!target) return

      if (!this.openedCountries.includes(code)) {
        this.openedCountries.push(code)
      }

      target.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    },

    updateValue(value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="scss" src="@component-styles/locations-select"></style>
