// @ts-check
// Code from https://github.com/MadimetjaShika/vuetify-google-autocomplete/tree/dev/src/vga

let installing = false
let installPromise = null

let installed = false
let failed = false

/** @type {any} */
const w = window

/**
 * Insert Google Map script tag into body with custom option
 * @param {string} apiKey
 * @param {string | undefined} version
 * @param {string | string[] | undefined} language
 * @returns {Promise<boolean>}
 */
const insertGoogleScript = (apiKey, version, language) => {
  if (installing) {
    return installPromise
  }

  installPromise = new Promise((resolve, reject) => {
    if (installed) {
      resolve(installed)
    }

    if (!apiKey) {
      reject(new Error('APIKey required'))
    }

    // If not within browser context, do not continue processing.
    if (typeof window === 'undefined' || typeof document === 'undefined') {
      reject(new Error('Google map only support browser environment'))
    }

    if (typeof w.google === 'object' && typeof w.google.maps === 'object') {
      if (typeof w.google.maps.places === 'object') {
        return // google is already loaded, don't try to load it again to prevent errors
      }

      throw new Error(
        'Google is already loaded, but does not contain the places API.'
      )
    }

    installing = true

    const script = document.createElement('SCRIPT')

    // Allow apiKey to be an object.
    // This is to support more esoteric means of loading Google Maps,
    // such as Google for business
    // https://developers.google.com/maps/documentation/javascript/get-api-key#premium-auth
    const options = {}

    if (typeof apiKey === 'string') {
      options.key = apiKey
    } else if (typeof apiKey === 'object') {
      Object.keys(apiKey).forEach(key => {
        options[key] = apiKey[key]
      })
    } else {
      reject(new Error('apiKey should either be a string or an object'))
    }

    options.libraries = 'places'

    function initMap() {
      // can insert a map but currently do nothing
    }
    w.initMap = initMap

    const parameters = Object.keys(options)
      .map(
        key => `${encodeURIComponent(key)}=${encodeURIComponent(options[key])}`
      )
      .join('&')

    let url = `https://maps.googleapis.com/maps/api/js?${parameters}&callback=initMap`

    if (version) {
      url = `${url}&v=${version}`
    }

    if (language) {
      url = `${url}&language=${language}`
    }

    script.setAttribute('src', url)
    script.setAttribute('async', '')
    script.setAttribute('defer', '')

    script.onload = () => {
      installed = true
      installing = false
      resolve(installed)
    }

    script.onerror = () => {
      failed = true
      installing = false
      reject(failed)
    }

    document.body.appendChild(script)
  })

  return installPromise
}

export default insertGoogleScript
