import gql from 'graphql-tag'
import { output } from '../../shared/fragments/mutationOutput'

export const updateOrgDetails = gql`
  mutation updateOrgDetails($input: OrgDetailsInput!) {
    updateOrgDetails(input: $input) {
      ...output
    }
  }
  ${output}
`

export const updateContactDetails = gql`
  mutation updateContactDetails($id: ID, $input: OrgContactDetailsInput!) {
    updateContactDetails(input: $input, id: $id) {
      ...output
    }
  }
  ${output}
`

export const updateBillingDetails = gql`
  mutation updateBillingDetails($id: ID, $input: OrgBillingDetailsInput!) {
    updateBillingDetails(input: $input, id: $id) {
      ...output
    }
  }
  ${output}
`

export const createAccount = gql`
  mutation createAccount($input: CreateAccountInput!) {
    createAccount(input: $input) {
      ...output
    }
  }
  ${output}
`
export const suspendOrgs = gql`
  mutation suspendOrgs($ids: [ID]!) {
    suspendOrgs(ids: $ids) {
      ...output
    }
  }
  ${output}
`

export const updateSelfApprovalToggle = gql`
  mutation updateSelfApprovalToggle($input: selfApprovalToggleInput!) {
    updateSelfApprovalToggle(input: $input) {
      ...output
    }
  }
  ${output}
`
