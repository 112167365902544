import { actions, getters, mutations } from './reports'
import store from '../store'

type Actions = keyof typeof actions
type Computations = keyof typeof getters
type Mutations = keyof typeof mutations

export function reportsDispatch(action: Actions, payload: any) {
  return store.dispatch(`reports/${action}`, payload)
}

export function reportsCommit(mutation: Mutations, payload: any) {
  return store.commit(`reports/${mutation}`, payload)
}

export function reportsGetters(key: Computations) {
  return store.getters[`reports/${key}`]
}
