<template>
  <v-container :fluid="true" class="py-0">
    <v-container fluid class="px-0">
      <v-row>
        <v-col
          v-for="column in columns"
          :key="column.id"
          tag="main"
          :class="`${$style.layout} pa-0 ${column.classes || ''}`"
        >
          <slot :name="column.id" />
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: 'FpColumns',

  props: {
    columns: {
      type: Array,
      default: () => {
        return [{ id: 'first' }, { id: 'second' }]
      },
    },
  },
}
</script>

<style lang="scss" module>
@import '@scss';

.layout {
  @extend %full-height;
}
</style>
