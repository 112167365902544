<template>
  <h4 :class="$style.container">
    <fp-icon v-if="icon" size="lg" :class="$style.icon" :name="icon" />
    <slot />
  </h4>
</template>

<script>
export default {
  skipGloballyRegister: true,

  name: 'HeaderActionDropdownCommonHeading',

  props: { icon: { type: String, default: '' } },
}
</script>

<style lang="scss" module>
@import '@scss';

.container {
  margin-bottom: 10px;
  font-size: 17px;
  font-weight: 600;
}

.icon {
  margin-right: 8px;
}
</style>
