import * as queries from '@graphql/org-dashboard/queries'

import random from 'lodash/random'

const ORG_DASHBOARD_INIT = {
  dashboard: { rangeMetrics: null, tables: null },
  orgs: [],
}

export const state = {
  rangeMetrics: ORG_DASHBOARD_INIT.dashboard.rangeMetrics,
  tables: ORG_DASHBOARD_INIT.dashboard.tables,
  throttle: '',
  orgs: ORG_DASHBOARD_INIT.orgs,
}

export const getters = {
  rangeMetrics({ rangeMetrics }) {
    return rangeMetrics
  },

  tables({ tables }) {
    return tables
  },

  orgs({ orgs }) {
    return orgs
  },
}

export const mutations = {
  SET_ASYNC_THROTTLE(state, data) {
    state.throttle = data
  },
  SET_ORG_DASHBOARD(state, res) {
    state.rangeMetrics = res.dashboard.rangeMetrics
    state.tables = res.dashboard.tables
    state.orgs = res.orgs
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ dispatch }) {},

  // ---------------------- //
  // GRAPHQL QUERIES
  // -----------------------//
  async getOrgDashboard({ dispatch, commit, state }, variables) {
    const throttle = await dispatch('vuexStateThrottle')

    const [err, res] = await this.dispatch('tryQuery', {
      query: queries.getOrgDashboard,
      variables,
    })

    if (!err && throttle === state.throttle) {
      commit('SET_ORG_DASHBOARD', res)
    }

    return [err, res]
  },

  // ---------------------- //
  // HELPER ACTIONS
  // -----------------------//
  vuexStateThrottle({ commit }) {
    // Stop the Vuex store from updating the state with old data requests
    // Give us a random number to compare against
    let throttle = random(0, 1000)
    commit('SET_ASYNC_THROTTLE', throttle)
    return throttle
  },

  clearOrgDashboard({ commit }) {
    commit('SET_ORG_DASHBOARD', ORG_DASHBOARD_INIT)
  },
}
