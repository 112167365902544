import { Store } from 'vuex'
import store from '../store'
import * as queries from '@graphql/game-rates/queries'

type MapKeyToObj<T> = { [K in keyof T]: any }

export const state = {
  rates: {
    items: [],
    total: 0,
  },
  currentRate: null,

  rateList: {
    items: [],
  },
}

type States = typeof state

export const getters = {
  total({ rates }) {
    return rates?.total
  },

  rates({ rates }) {
    return rates?.items
  },

  rateList({ rateList }) {
    return rateList?.items
  },
}

type Getters = MapKeyToObj<typeof getters>

export const mutations = {
  SET_RATES(state: States, data) {
    state.rates = data
  },

  SET_CURRENT_RATE(state: States, rateData) {
    state.currentRate = rateData
  },

  SET_RATE_LIST(state: States, data) {
    state.rateList = data
  },
}

type Mutations = MapKeyToObj<typeof mutations>

export const actions: {
  [key: string]: (
    store: {
      dispatch
      getters: Getters
      commit(x: keyof Mutations, y?: any): void
    },
    arg: any
  ) => any | Promise<any>
} = {
  init() {},

  async getRates(this: Store<any>, { getters, commit }, variables) {
    const [err, res] = await this.dispatch('tryQuery', {
      query: queries.getGameRates,
      variables,
    })

    if (res && !err) commit('SET_RATES', res)
    return [err, res]
  },

  async getRateList() {},
}

type Actions = MapKeyToObj<typeof actions>

export function RateGetters(key: Getters) {
  return store.getters[`rates/${key}`]
}

export function RateDispatch(action: Actions, payload: any) {
  return store.dispatch(`rates/${action}`, payload)
}
