//
// Money / Currency masking for Veutify inputs
// https://github.com/juareznasato/vuetify-money/
// Customised version of this small vuetify-money library
//
import { substring } from '@utils'

export default {
  props: {
    // Money specific
    moneyOptions: {
      type: Object,
      default: () => {
        return {
          locale: 'en-US',
          prefix: '',
          precision: 2,
        }
      },
    },
  },

  data() {
    return {
      modValue: '', // Money input v-model prop
    }
  },

  computed: {
    // Logic: compValue is the stopping point
    // The value enters through the prop and stops here, formatted.
    // When typing in the v-text-field the value is manipulated to NNNNNN.NN,
    // issued to the parent component and returned by the prop, stopping again here, formatted.
    compValue() {
      // String and Number
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return (this.modValue = this.humanFormat(
        this.machineFormat(this.humanFormat(this.value))
      ))
    },
  },

  methods: {
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault()
      }
    },

    keypressEventHandlers(e) {
      if (!e) return

      this.onlyNumber(e)
    },

    /* Format in standard NNNNNN.NN */
    machineFormat(value) {
      let number = value
      if (number) {
        // Retirar prefixo
        if (number.includes(' ')) {
          number = number.split(' ')[1]
        }
        // Retirar pontos e virgulas
        number = number
          .replace(new RegExp(/[.]/, 'g'), '')
          .replace(new RegExp(',', 'g'), '')
        // Ajustar quantidade de zeros à esquerda
        number = number.padStart(parseInt(this.moneyOptions.precision) + 1, '0')
        // Incluir ponto na casa correta, conforme a precisão configurada
        number =
          substring(
            number,
            0,
            number.length - parseInt(this.moneyOptions.precision)
          ) +
          '.' +
          substring(
            number,
            number.length - parseInt(this.moneyOptions.precision),
            number.length
          )
        if (isNaN(number)) {
          number = '0'
        }
      } else {
        number = '0'
      }
      return number
    },
    humanFormat: function (value) {
      let number = value
      if (isNaN(number)) {
        number = '0'
      } else {
        // number = Number(number).toLocaleString(this.moneyOptions.locale, {maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'BRL'});
        number = Number(number).toLocaleString(this.moneyOptions.locale, {
          maximumFractionDigits: this.moneyOptions.precision,
          minimumFractionDigits: this.moneyOptions.precision,
        })
      }
      return this.moneyOptions.prefix + ' ' + number
    },
  },
}
