const VERIFY_PHONE = 'verify-phone'
const ONBOARD_ACCOUNT_TYPE = 'onboard-account-type'
const ONBOARD_ORG_TYPE = 'onboard-org-type'
const ONBOARD_STUDENT = 'onboard-student'
const ONBOARD_CONTACT = 'onboard-contact'
const ONBOARD_USER = 'onboard-user'
const ONBOARD_SCHOOL = 'onboard-school'
const ONBOARD_BUSINESS = 'onboard-business'

const SIGNUP_VERIFY_CODE = 'sign-up-verify-code'
const SIGNUP_VERIFY_CODE_FB = 'sign-up-verify-code-fb'
const SIGNUP_VERIFIED = 'sign-up-verified'
const SIGNUP_VERIFY_WAIT = 'sign-up-verify-wait'
const REPORTING = 'reporting'
const REPORTING_OLD = 'reporting-old'

const FORGET_PASSWORD = 'forgot-password'
const NEW_PASSWORD = 'new-password'
const SET_PASSWORD = 'set-password'

// todo: update rest of app to use the constants.
// Below are constants where not all places in the app are using it, i.e. some still hardcoded strings
const DASHBOARD = 'dashboard'

export {
  VERIFY_PHONE,
  ONBOARD_ACCOUNT_TYPE,
  ONBOARD_ORG_TYPE,
  ONBOARD_STUDENT,
  ONBOARD_CONTACT,
  ONBOARD_USER,
  ONBOARD_SCHOOL,
  ONBOARD_BUSINESS,
  SIGNUP_VERIFY_CODE_FB,
  SIGNUP_VERIFY_CODE,
  SIGNUP_VERIFIED,
  SIGNUP_VERIFY_WAIT,
  REPORTING,
  REPORTING_OLD,
  DASHBOARD,
  FORGET_PASSWORD,
  NEW_PASSWORD,
  SET_PASSWORD,
}
