// @ts-ignore

import * as utils from './utils'

export function FPString(input) {
  let val = input
  return {
    firstUpper: function () {
      val = utils.firstUpper(val)
      return this
    },
    replaceAll: function (s, n) {
      val = utils.replaceAll(val, s, n)
      return this
    },
    capitalize: function () {
      val = utils.capitalize(val)
      return this
    },
    toLower: function () {
      val = utils.toLower(val)
      return this
    },
    splitCamel: function () {
      val = utils.splitCamel(val)
      return this
    },
    startCaseAllUppercase: function () {
      val = utils.startCaseAllUppercase(val)
      return this
    },
    trim: function () {
      val = utils.trim(val)
      return this
    },

    // todo: figure out how to detect end of method chaining, if even possible in js
    // so we don't need to call result() to get the value
    result: function () {
      return val
    },
  }
}

export default FPString
