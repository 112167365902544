<script>
import * as m from '@graphql/shared/mutations'
import { getOperationName } from '@graphql/utils'
import { verifyItemInput } from '@graphql/shared/inputs'
import { updateGameAdApprovalsInput } from '@graphql/game/inputs'
import { BUSINESS_ACTIONS, BUSINESS_TYPES } from '@constants'
import { updateGameAdApprovals } from '@graphql/game/mutations/index'

export default {
  name: 'GuardDialog',
  props: {
    guard: {
      type: Object,
      default: () => ({}),
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      [getOperationName(m.verifyItems)]: {
        form: {
          ...verifyItemInput,
        },
        variables: {
          ...m.verifyItemsArgs,
        },
        mapVariables: this.mapVerifyItemVariables,
        getApproval: this.getVerifyItemApproval,
      },
      [getOperationName(updateGameAdApprovals)]: {
        form: {
          ...updateGameAdApprovalsInput,
        },
        variables: {
          items: [{ ...updateGameAdApprovalsInput }],
        },
        mapVariables: this.mapUpdateGameAdApprovalsVariables,
        getApproval: this.getVerifyItemApproval,
      },
    }
  },
  computed: {
    isVerifyItemsReject() {
      const items = this.guard?.variables?.items || []
      return items[0]?.approved === false
    },
    currentForm() {
      return this[this.guard?.id]?.form
    },
    currentGuardVariables() {
      return this[this.guard?.id]?.variables
    },
    showRejectMessageField() {
      const { guard, currentForm, currentGuardVariables } = this

      if (guard?.variables?.showRejectMessageField) return true

      // expect verifyItems mutation to have args items and input prop message.
      // when changing verifyItems mutation this must also change.
      // todo: strongly type this
      if (
        !currentForm ||
        !currentForm.hasOwnProperty('message') ||
        !currentGuardVariables.hasOwnProperty('items')
      ) {
        return false
      }

      const items = guard?.variables?.items || []
      const picked = (({ AD, GAME, ORGANIZATION }) => [AD, GAME, ORGANIZATION])(
        BUSINESS_TYPES
      )
      const condition =
        items[0]?.approved === false && picked.includes(items[0]?.type)

      if (condition) return currentForm
      return false
    },
  },
  mounted() {
    if (!this.showDialog) return

    const dialogOptions = {
      title: this.guard.body.title,
      content: this.guard.body.content,
      confirmText: 'Confirm',
      onConfirm: args => this.handleConfirm(args?.userInput),
      onCancel: this.handleCancel,
      cancelText: 'Cancel',
      confirmInput: this.guard.body.confirmInput,
      promptOptions: this.guard?.variables?.promptOptions ?? null,
      promptOptionCustomInputValue:
        this.guard?.variables?.promptOptionCustomInputValue ?? '',
    }

    if (this.showRejectMessageField) {
      this.$prompt({
        ...dialogOptions,
        required: true,
        label: 'Rejection message',
      })
    } else {
      this.$confirm({
        ...dialogOptions,
      })
    }
  },
  methods: {
    getVerifyItemApproval() {
      if (this.isVerifyItemsReject) {
        // do not approve if reject message not provided
        return !!this.currentForm.message
      }
      // approve for non-reject
      return true
    },
    mapVerifyItemVariables() {
      const { guard, currentForm } = this
      const currVars = { ...guard?.variables }

      // dont ovveride variables if not reject
      if (!this.isVerifyItemsReject) {
        return
      }

      // add new reject message to vars
      const items = currVars.items.map(item => {
        const out = { ...item, message: currentForm.message }
        return out
      })
      const newVars = {
        items: [...items],
      }

      return newVars
    },
    mapUpdateGameAdApprovalsVariables() {
      const { guard, currentForm } = this
      const currVars = { ...guard?.variables }

      if (currVars.type !== BUSINESS_ACTIONS.REJECT) return

      const items = currVars.input.items.map(item => {
        const out = { ...item, comment: currentForm.comment }
        return out
      })
      const newVars = {
        input: { items: [...items] },
      }

      return newVars
    },
    handleCancel() {
      return this.$emit('on-cancel')
    },
    handleConfirm(userInput) {
      if (this.showRejectMessageField && userInput) {
        if (this.guard.id === 'updateGameAdApprovals') {
          this[this.guard.id].form.comment = userInput
        } else {
          this[this.guard.id].form.message = userInput
        }
      }

      const { guard } = this
      const guardOveride = this[guard.id]

      if (!guardOveride) {
        return this.$emit('on-confirm', true)
      }

      const isApproved = this[guard.id]?.getApproval()
      const newVariables = this[guard.id]?.mapVariables()
      return this.$emit('on-confirm', isApproved, newVariables)
    },
  },
}
</script>

<template><div></div></template>
