<template>
  <div v-if="block" v-bem="{ plain, readonly }">
    <div v-bem:flag-wrapper @click.stop="$emit('delete')">
      <img v-if="!isGlobal" v-bem:flag :src="getCountryFlagImg(block.code)" />
      <span else> 🌍 </span>
    </div>

    <div v-bem:content>
      <strong v-bem:country>{{ countryDisplay }}</strong>
      <small v-bem:divisions>{{ divisionsDisplay }}</small>
    </div>

    <fp-icon-button
      v-if="!isGlobal && !readonly"
      tooltip="Modify"
      size="small"
      icon="pen"
      @click="$emit('edit')"
    />
    <fp-icon-button
      v-if="!readonly"
      tooltip="Remove"
      size="small"
      icon="trash"
      @click="$emit('delete')"
    />
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import { getCountryFlagImg } from '@shared/countries'

export default {
  name: 'FpLocationsBlock',

  skipGloballyRegister: true,

  props: {
    plain: Boolean,
    readonly: Boolean,

    block: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isGlobal() {
      return this.block.code === 'ALL'
    },

    isAllDivisions() {
      const { divisions } = this.block

      return (
        divisions.selected.length === divisions.total ||
        divisions.selected.length === 0
      )
    },

    countryDisplay() {
      const { name, divisions } = this.block
      const divisionsNumberText = this.isAllDivisions
        ? ''
        : `(${divisions.selected.length})`

      return `${name} ${divisionsNumberText}`
    },

    divisionsDisplay() {
      const {
        divisions: { selected },
      } = this.block

      if (this.isGlobal) {
        return 'All Countries and Regions'
      }

      if (this.isAllDivisions) {
        return 'All Regions'
      }

      let result = []

      let divisions2Show = selected

      if (selected.length > 3) {
        divisions2Show = cloneDeep(selected).slice(0, 3)
      }

      divisions2Show.forEach(item => {
        if (item.name.length > 15) {
          const shortDivisionCode = this.removeCountryPrefix(item.code)
          return result.push(shortDivisionCode)
        }
        result.push(item.name)
      })

      if (selected.length > 3) {
        return result.join(', ') + `...and ${selected.length - 3} more`
      }

      return result.join(', ')
    },
  },

  methods: {
    getCountryFlagImg,

    removeCountryPrefix(divisionCode = '') {
      return divisionCode.split('-')[1]
    },
  },
}
</script>
