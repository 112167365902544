import { getGameStatusByIds } from '@src/shared/game/api'
import Vue from 'vue'
import { GameStatusEnum } from '@graphql-types'

type GameId = string

export type GameStatusObj = {
  id: GameId
  title: string
  status: GameStatusEnum
}

type MapKeyToObj<T> = { [K in keyof T]: any }

export const state = {
  map: {},
}

type States = typeof state

export const getters = {
  ids({ map }: States) {
    return Object.keys(map)
  },
}

type Getters = MapKeyToObj<typeof getters>

export const mutations = {
  SET_NEW(state, game: GameStatusObj) {
    Vue.set(state.map, game.id, game)
  },

  CLEAR_ALL(state) {
    Vue.set(state, 'map', {})
  },
}

type Mutations = MapKeyToObj<typeof mutations>

export const actions: {
  [key: string]: (
    store: { getters: Getters; commit(x: keyof Mutations, y?: any): void },
    arg: any
  ) => any | Promise<any>
} = {
  init() {},

  async fetchGameStatus({ getters, commit }, ids: GameId[]) {
    const news = ids.filter(id => !getters.ids.includes(id))

    if (news.length === 0) return false

    const [err, data] = await getGameStatusByIds<GameStatusObj>(news)

    if (!err && data) {
      data.items.forEach(org => {
        commit('SET_NEW', org)
      })
    }
  },

  clearVerifyMap({ commit }) {
    commit('CLEAR_ALL')
  },
}
