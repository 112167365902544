import * as m from '../mutations'
import { handleSensitiveInfoChange } from '../../shared/guards/guardHandlers'
import { getOperationName } from '../../utils'

const getGuards = () => {
  const out = {
    [getOperationName(m.verifyItems)]: operation =>
      handleSensitiveInfoChange({
        operation,
      }),
  }
  return out
}

export const guards = {
  ...getGuards(),
}
