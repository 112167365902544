<script>
export default {
  skipGloballyRegister: true,

  name: 'FpMoreActionsMenu',

  inheritAttrs: false,

  props: {
    icon: {
      type: String,
      default: 'ellipsis-h',
    },

    tooltip: {
      type: String,
      default: 'More actions',
    },

    actions: {
      default: () => ({}),
      type: Object,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    visible() {
      return !!Object.keys(this.actions).length
    },
  },

  methods: {
    takeAction(value, actionName) {
      if (value.disabled !== true) {
        this.$emit('take-action', actionName)
      } else {
        this.$toast.warning({
          text: value?.tooltip || 'This action is disabled.',
        })
      }
    },
  },
}
</script>

<template>
  <fp-menu left offset-x offset-y>
    <template v-slot:activator>
      <fp-icon-button
        v-if="visible"
        :tooltip="tooltip"
        :icon="icon"
        :disabled="disabled"
        v-bind="$attrs"
        tabindex="3"
      />
    </template>

    <div :class="$style.actions">
      <v-list>
        <div v-for="(value, actionName) in actions" :key="actionName">
          <v-tooltip :disabled="!value.tooltip" left>
            <template v-slot:activator="{ on }">
              <v-list-item
                v-on="on"
                @click="_ => takeAction(value, actionName)"
              >
                <v-list-item-title :class="$style.listItemTitle">
                  <fp-icon
                    :name="value.icon.name || value.icon"
                    :class="$style[actionName] + ' mr-2'"
                  />
                  {{ (value.text || actionName) | splitCamel | firstUpper }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <div v-if="value.tooltip">
              {{ value.tooltip }}
            </div>
          </v-tooltip>
        </div>
      </v-list>
    </div>
  </fp-menu>
</template>

<style lang="scss" module>
@import '@scss';

.actions {
  .listItemTitle {
    color: $color-mid-grey;
  }
}
</style>
