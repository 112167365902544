import {
  createComponent,
  onMounted,
  onBeforeUnmount,
  getCurrentInstance,
  reactive,
  computed,
  watch,
} from '@vue/composition-api'
import { s3UploadFile } from '@shared/s3'
import { BEMProvider } from '@src/plugins/bem'
import { ColConfig } from '../_base/table/table-cell'
import { contentTypes } from '@shared/contentTypes'

import '@component-styles/bulk-upload.scss'

import {
  createMultipartUploadURLs,
  setMultipartUpload,
  uploadFile,
} from '@graphql/file/mutations'
import useList from '../hooks/useList'
import RippleTrigger from '@src/shared/vue/ripple-trigger'
import {
  AdRatiosEnum,
  S3Object,
  AdCreativePlatformEnum,
  KeyValue,
} from '@graphql-types'
import {
  AD_CONTENT_TYPE,
  AD_CREATIVE_TYPE,
  ASPECT_RATIO_VALUES,
  VIDEO_ASPECT_RATIO_VALUES,
  MIME_TYPE,
  S3_MULTIPART_UPLOAD_CHUNK_SIZE,
  VIDEO_FILE_DURATION,
  EXCLUDED_ASPECT_RATIO_FOR_NON_FPSA_VALUES,
  EXCLUDED_VIDEO_ASPECT_RATIO_FOR_NON_FPSA_VALUES,
} from '@src/shared/constants'
import { FpS3File, FileStatusEnum, FeErrorsEnum } from './types'
import {
  isUploading,
  isUploaded,
  isError,
  isWarning,
  isWaitingUpload,
  isRatioRepeated,
  isBackendChecking,
  isAllGood,
  getImgDimension,
  getFileExtension,
  getCreativeRatio,
  getFileNameFromUrl,
  kb2Mb,
  getRatio,
  getVideoDimension,
  trimEtag,
  checkMinMaxPixels,
} from './utils'
import { VNode } from 'vue'
import store from '@src/state/store'
import { asyncRetry, truncate, hasAlphabet } from '@src/shared/utils'
import gql from '@graphql'
import { UserGetters } from '@src/state/modules/user.store'

const bem = BEMProvider('fp-bulk-upload')

export const ValidRatiosInNumber: string[] = Object.keys(AdRatiosEnum).map(
  r => ASPECT_RATIO_VALUES[r]
)
export const ValidVideoRatiosInNumber: string[] = Object.keys(AdRatiosEnum).map(
  r => VIDEO_ASPECT_RATIO_VALUES[r]
)

class FpS3FileClass implements FpS3File {
  status = FileStatusEnum.UNKNOWN

  id = ''
  fileName = ''
  extension
  mimeType = ''
  size = 0
  ratio = {
    real: '',
    estimate: undefined,
  }
  duration = 0
  width = 0
  height = 0
  uploadPercent = 0

  error
  warning

  raw
  url = ''
  thumbnailUrl = ''

  constructor(file: File) {
    this.raw = file
    this.fileName = file.name
    this.mimeType = file.type
    this.size = Math.floor(file.size / 1024)

    this.extension = getFileExtension(this.fileName)
  }

  switchStatus(status: FileStatusEnum) {
    this.status = status
  }

  clearError() {
    this.error = null
  }
}

// Component itself
export default createComponent({
  name: 'FpS3BulkUploader',

  props: {
    value: {
      type: Array,
      default: () => [],
    },

    tags: {
      type: Object,
      default: () => ({}),
    },

    platforms: {
      type: Array,
      default: () => [
        AdCreativePlatformEnum.NONMOBILE,
        AdCreativePlatformEnum.MOBILE,
      ],
    },

    contentType: {
      type: String,
      default: AD_CONTENT_TYPE.IMAGE,
    },

    rawItems: {
      type: Array,
      default: () => [],
    },

    limit: {
      type: Number,
      default: ValidRatiosInNumber.length,
    },

    maxSize: {
      type: Number,
      default: 2048,
    },

    accept: {
      default: '.jpg,.png,.jpeg',
      type: String,
    },

    id: {
      default: Math.random().toString(16).slice(2),
      type: String,
    },

    error: Boolean,
    message: String,
  },

  setup(props, { root, emit }) {
    const _this = getCurrentInstance()

    let modelUpdateFlag = false

    const s3UploadTasks = reactive({
      queue: [] as FpS3File[],
      running: false,
    })

    const dragState = reactive({
      hover: false,
    })

    const {
      state: fileList,
      removeAt: removeItemAt,
      set: setList,
    } = useList<FpS3File>()

    const state = reactive({
      uploaderWorking: computed(() => {
        return fileList.items.some(
          item => isUploading(item) || isBackendChecking(item)
        )
      }),
      isVideoFile: computed(() => {
        if (props.contentType === AD_CONTENT_TYPE.IMAGE) return false
        return true
      }),
      displayFileListItems: computed(() =>
        fileList.items
          .filter(item => {
            return filterFileByType(item)
          })
          .filter(item => {
            // TODO - remove this filter after the new creative ratios is available for everyone
            if (!UserGetters('canAccessNewCreativeRatios')) {
              if (state.isVideoFile) {
                if (
                  EXCLUDED_VIDEO_ASPECT_RATIO_FOR_NON_FPSA_VALUES.includes(
                    item.ratio.real
                  ) &&
                  !item.error
                )
                  return false
              } else {
                if (
                  EXCLUDED_ASPECT_RATIO_FOR_NON_FPSA_VALUES.includes(
                    item.ratio.real
                  ) &&
                  !item.error
                )
                  return false
              }
            }

            return true
          })
      ),
      // TODO - remove validRatiosInNumber + validVideoRatiosInNumber state props after creative ratios is available to everyone
      validRatiosInNumber: computed(() =>
        Object.keys(AdRatiosEnum)
          .filter(r => {
            if (!UserGetters('canAccessNewCreativeRatios')) {
              if (
                EXCLUDED_ASPECT_RATIO_FOR_NON_FPSA_VALUES.includes(
                  r as AdRatiosEnum
                )
              )
                return false
            }

            return true
          })
          .map(r => ASPECT_RATIO_VALUES[r])
      ),
      validVideoRatiosInNumber: computed(() =>
        Object.keys(AdRatiosEnum)
          .filter(r => {
            if (!UserGetters('canAccessNewCreativeRatios')) {
              if (
                EXCLUDED_ASPECT_RATIO_FOR_NON_FPSA_VALUES.includes(
                  r as AdRatiosEnum
                ) ||
                EXCLUDED_VIDEO_ASPECT_RATIO_FOR_NON_FPSA_VALUES.includes(
                  r as AdRatiosEnum
                )
              )
                return false
            }

            return true
          })
          .map(r => VIDEO_ASPECT_RATIO_VALUES[r])
      ),
    })

    const countComputed = reactive({
      uploaded: computed(
        () =>
          fileList.items
            .filter(item => isAllGood(item) && filterFileByType(item))
            .filter(item => {
              // TODO - remove this filter after the new creative ratios is available for everyone
              if (!UserGetters('canAccessNewCreativeRatios')) {
                if (state.isVideoFile) {
                  if (
                    EXCLUDED_VIDEO_ASPECT_RATIO_FOR_NON_FPSA_VALUES.includes(
                      item.ratio.real
                    ) &&
                    !item.error
                  )
                    return false
                } else {
                  if (
                    EXCLUDED_ASPECT_RATIO_FOR_NON_FPSA_VALUES.includes(
                      item.ratio.real
                    ) &&
                    !item.error
                  )
                    return false
                }
              }

              return true
            }).length
      ),
      warning: computed(
        () =>
          fileList.items.filter(
            item => isWarning(item) && filterFileByType(item)
          ).length
      ),
      error: computed(
        () =>
          fileList.items.filter(item => isError(item) && filterFileByType(item))
            .length
      ),
    })

    const filterFileByType = item => {
      if (props.contentType === AD_CONTENT_TYPE.IMAGE) {
        return (
          item.mimeType === MIME_TYPE.JPEG || item.mimeType === MIME_TYPE.PNG
        )
      } else {
        return item.mimeType === MIME_TYPE.MP4
      }
    }

    const FileTableColumns: ColConfig[] = [
      {
        text: 'File Name',
        value: 'fileName',
      },
      {
        text: 'Size',
        value: 'size',
      },
      {
        text: 'Ratio',
        value: 'ratio',
        align: 'center',
      },

      {
        text: 'Dimension',
        value: 'dimension',
      },

      {
        text: 'Status',
        value: 'status',
        width: 200,
        align: 'center',
      },
    ]

    const VideoFileTableColumns: ColConfig[] = [
      {
        text: 'File Name',
        value: 'fileName',
      },
      {
        text: 'Size',
        value: 'size',
      },
      {
        text: 'Ratio',
        value: 'ratio',
        align: 'center',
      },

      {
        text: 'Duration',
        value: 'duration',
      },

      {
        text: 'Status',
        value: 'status',
        width: 200,
        align: 'center',
      },
    ]

    setupDragAndDrop()

    // When edit ad, we should infer fileList from value(s3object[])
    watch(
      () => props.value,
      newVal => {
        if (!modelUpdateFlag) {
          const x = genFileListFromS3Object(newVal as S3Object[])
          setList(x)
        } else {
          modelUpdateFlag = false
        }
      }
    )

    // ------------- 🧩 RENDER FUNCTION -------------
    return () => {
      const successCount = countComputed.uploaded
      const errorCount = countComputed.error
      const warningCount = countComputed.warning

      return (
        <section {...bem()}>
          <label
            v-ripple
            for={props.id}
            {...bem('::file-browser', { hover: dragState.hover })}
            ref="fileBrowserRef"
          >
            <div {...bem('::browser-icons')}>
              {state.isVideoFile ? (
                <fp-icon name="file-video" class="object" />
              ) : (
                <fp-icon name="file-image" class="object" />
              )}

              <fp-icon name="file-video" class="shadow left" />
              <fp-icon name="file-video" class="shadow right" />
            </div>

            <div {...bem('::browser-label')}>
              <strong>
                Drag file(s) here or <a> click to upload</a>
              </strong>
              {state.isVideoFile ? (
                <small>
                  support {props.accept}, duration 6/10/15 seconds, aspect{' '}
                  {/* TODO - after creatives is released for all users, update the wording to be /9:16/4:3/1:1 only  */}
                  ratio 16:9
                  {UserGetters('canAccessNewCreativeRatios')
                    ? '/9:16/4:3/1:1'
                    : ', pixels 1920x1080'}
                  , max frame rate 30fps exclusive, <br /> max file size{' '}
                  {kb2Mb(props.maxSize)}.
                </small>
              ) : (
                <small>
                  Up to {props.limit} ratios, max size {kb2Mb(props.maxSize)}{' '}
                  each, support {props.accept}
                </small>
              )}
            </div>

            <input
              id={props.id}
              ref="input"
              hidden="hidden"
              type="file"
              multiple
              accept={props.accept}
              value="value"
              onChange={event => fileChangeHandler(event.target.files)}
            />
          </label>

          <div {...bem('::list')}>
            <div {...bem('::list-title')}>
              <h4>Your uploads</h4>

              <div {...bem('::result')}>
                {!!successCount && (
                  <fp-chip type="info" size="small" class="mr-2">
                    {successCount} uploaded
                  </fp-chip>
                )}
                {!!warningCount && (
                  <fp-chip type="warning" class="mr-2">
                    {warningCount} warning{successCount > 1 ? 's' : ''}
                  </fp-chip>
                )}
                {!!errorCount && (
                  <fp-chip type="error">
                    {errorCount} error{errorCount > 1 ? 's' : ''}
                  </fp-chip>
                )}
              </div>
            </div>

            <div {...bem('::table-container')}>
              {state.isVideoFile ? (
                <fp-table
                  ref="table"
                  selectable={false}
                  row-hover-background="#fafafa"
                  no-data-text="Upload a file to begin"
                  data={state.displayFileListItems}
                  columns={VideoFileTableColumns}
                  per-page={100}
                  scopedSlots={{
                    'cell.fileName': ({ item }) =>
                      genTitleCell(
                        item,
                        {
                          removeFile,
                          emit,
                        },
                        false
                      ),
                    'cell.size': ({ item }) => genSizeCell(item),
                    'cell.ratio': ({ item }) => genRatioCell(item),
                    'cell.duration': ({ item }) => genDurationCell(item),
                    'cell.status': ({ item }) => genStatusCell(item),
                  }}
                />
              ) : (
                <fp-table
                  ref="table"
                  selectable={false}
                  row-hover-background="#fafafa"
                  no-data-text="Upload a file to begin"
                  data={state.displayFileListItems}
                  columns={FileTableColumns}
                  per-page={100}
                  scopedSlots={{
                    'cell.fileName': ({ item }) =>
                      genTitleCell(item, {
                        removeFile,
                        emit,
                      }),
                    'cell.size': ({ item }) => genSizeCell(item),
                    'cell.ratio': ({ item }) => genRatioCell(item),
                    'cell.dimension': ({ item }) => genDimensionCell(item),
                    'cell.status': ({ item }) => genStatusCell(item),
                  }}
                />
              )}
            </div>
          </div>

          <div {...bem('::footer')}>
            <fp-button
              class="fp-bulk-upload__button"
              onClick={onFinishClick}
              icon="check"
              iconPosition="right"
              loading={state.uploaderWorking}
            >
              Done
            </fp-button>
          </div>
        </section>
      )
    }

    function setupDragAndDrop() {
      onMounted(() => {
        const fileBrowserEl = _this!.$refs.fileBrowserRef as HTMLElement

        addListener(
          document,
          'dragleave drop dragenter dragover',
          preventDefault,
          false
        )

        addListener(fileBrowserEl, 'drop', (event: DragEvent) => {
          // 🚧 Filter out un-supported types
          let files = [...event.dataTransfer!.files].filter(fileTypeFilter)

          if (!files.length) return

          RippleTrigger.show(fileBrowserEl)

          fileChangeHandler(files)
        })

        addListener(
          fileBrowserEl,
          'dragleave drop',
          () => (dragState.hover = false)
        )

        addListener(fileBrowserEl, 'dragover', () => (dragState.hover = true))
      })

      onBeforeUnmount(() => {
        removeListener(
          document,
          'dragleave drop dragenter dragover',
          preventDefault
        )
      })
    }

    function fileChangeHandler(files: File[]) {
      if (!files) return

      const validFiles = Object.values(files).filter(fileTypeFilter)
      if (!validFiles.length) return

      let raws = [...files]

      // 🚧 Filter out repeated files
      raws = raws.filter(repeatedFileFilter)

      if (!raws.length) return

      clearInput()

      Promise.all(raws.map(fileAssemblyLine)).then(() => {
        // 3. Run upload tasks
        if (!s3UploadTasks.running) {
          runUploadTasks()
        }
      })
    }

    function clearInput() {
      // Clear input's own files cache in case user
      // remove the uploaded file and wanna select it again in the same session
      try {
        ;(_this?.$refs.input as HTMLInputElement).value = null as any
      } catch (err) {}
    }

    async function fileAssemblyLine(rawFile: File) {
      const fpS3File = new FpS3FileClass(rawFile)
      try {
        if (state.isVideoFile) {
          const { width, height, duration } = await getVideoDimension(
            fpS3File.raw
          )

          fpS3File.width = width
          fpS3File.height = height
          fpS3File.duration = duration

          Object.assign(fpS3File.ratio, getCreativeRatio(width, height))

          await runFrontendCheckTask(fpS3File)

          // 1. Push to list
          fileList.items.push(fpS3File)

          // 2. Push non-error file to uplaod sequence
          if (
            !isError(fpS3File) &&
            !isUploaded(fpS3File) &&
            !isUploading(fpS3File)
          ) {
            s3UploadTasks.queue.push(fpS3File)
            fpS3File.switchStatus(FileStatusEnum.WAITING_FOR_UPLOAD)
          }
          return fpS3File
        } else {
          const { width, height, src } = await getImgDimension(fpS3File.raw)

          fpS3File.width = width
          fpS3File.height = height
          fpS3File.url = src

          Object.assign(fpS3File.ratio, getCreativeRatio(width, height))

          await runFrontendCheckTask(fpS3File)

          // 1. Push to list
          fileList.items.push(fpS3File)

          // 2. Push non-error file to uplaod sequence
          if (
            !isError(fpS3File) &&
            !isUploaded(fpS3File) &&
            !isUploading(fpS3File)
          ) {
            s3UploadTasks.queue.push(fpS3File)
            fpS3File.switchStatus(FileStatusEnum.WAITING_FOR_UPLOAD)
          }

          return fpS3File
        }
      } catch (error) {
        root.$toast.error(
          `Unable to progress ${fpS3File.fileName}, please check if file is corrupt.`
        )
      }
    }

    async function runFrontendCheckTask(file: FpS3File): Promise<FpS3File> {
      file.switchStatus(FileStatusEnum.FRONTEND_CHECKING)

      // Check duration
      if (
        file.duration &&
        !VIDEO_FILE_DURATION.includes(Math.floor(file.duration))
      ) {
        file.switchStatus(FileStatusEnum.FRONTEND_CHECK_ERROR)
        file.error = new Error(FeErrorsEnum.DURATION_INVALID)
        return file
      }

      // Check size
      let isSizeValid = false
      if (state.isVideoFile) {
        isSizeValid = videoSizeValidate(file)
      } else {
        isSizeValid = sizeValidate(file)
      }

      if (!isSizeValid) {
        file.switchStatus(FileStatusEnum.FRONTEND_CHECK_ERROR)

        file.error = new Error(FeErrorsEnum.SIZE_EXCEED)

        return file
      }

      // Check ratio
      const isRatioValid = ratioValidate(file)

      if (!isRatioValid) {
        file.switchStatus(FileStatusEnum.FRONTEND_CHECK_ERROR)

        file.error = new Error(FeErrorsEnum.UNVALID_RATIO)

        return file
      }

      // Check min/max pixel restriction
      const err = checkMinMaxPixels(
        file.width,
        file.height,
        file.ratio.estimate ?? file.ratio.real
      )

      if (err) {
        file.switchStatus(FileStatusEnum.FRONTEND_CHECK_ERROR)
        file.error = new Error(err)
        return file
      }

      // Check ratio duplication
      const isRatioRepeated = repeatedRatioValidate(file)

      if (!isRatioRepeated) {
        file.switchStatus(FileStatusEnum.FRONTEND_CHECK_ERROR)

        file.error = new Error(FeErrorsEnum.REPEATED_RATIO)

        return file
      }

      file.switchStatus(FileStatusEnum.FRONTEND_CHECK_FINISHED)

      return file
    }

    // ------------- ⏳ FILE FILTERS -------------
    function fileTypeFilter(file: File) {
      const { type, name } = file
      const extension = getFileExtension(name).toLowerCase()

      const baseType = type.replace(/\/.*$/, '')

      const isTypeValid = props.accept
        .split(',')
        .map(type => type.trim())
        .filter(type => type)
        .some(acceptedType => {
          if (/\..+$/.test(acceptedType)) {
            return extension === acceptedType
          }
          if (/\/\*$/.test(acceptedType)) {
            return baseType === acceptedType.replace(/\/\*$/, '')
          }
          if (/^[^\/]+\/[^\/]+$/.test(acceptedType)) {
            return type === acceptedType
          }

          return false
        })

      if (!isTypeValid) {
        root.$toast.error(
          `Wrong file type: ${file.name}, support ${props.accept} only.`
        )
      }

      return isTypeValid
    }

    function repeatedFileFilter(file: File) {
      const isRepeated = fileList.items.find(
        item => item.fileName === file.name
      )

      if (isRepeated) {
        root.$toast.warning(`Repeated file detected: ${file.name}`)
      }

      return !isRepeated
    }

    // ------------- 🔎 FILE VALIDATORS -------------
    // Note belows are all only frontend validations
    function sizeValidate(file: FpS3File) {
      return file.size < props.maxSize
    }

    function videoSizeValidate(file: FpS3File) {
      return file.size < props.maxSize
    }

    function ratioValidate(file: FpS3File) {
      const { real, estimate } = file.ratio

      if (!real) return false

      // TODO - revert to use ValidRatiosInNumber + ValidVideoRatiosInNumber.
      // Currently using the state versions because we need to check for FPSA
      const isRealRatioValid = state.isVideoFile
        ? state.validVideoRatiosInNumber.includes(real)
        : state.validRatiosInNumber.includes(real)
      const isApproxRatioValid =
        estimate &&
        (state.isVideoFile
          ? state.validVideoRatiosInNumber.includes(estimate)
          : state.validRatiosInNumber.includes(estimate))

      return isRealRatioValid || isApproxRatioValid
    }

    function repeatedRatioValidate(file: FpS3File) {
      const thisRatio = file.ratio.estimate || file.ratio.real

      return !fileList.items.some(({ ratio: { estimate, real }, mimeType }) => {
        if (state.isVideoFile) {
          return (
            mimeType === MIME_TYPE.MP4 && [estimate, real].includes(thisRatio)
          )
        } else {
          return (
            (mimeType === MIME_TYPE.JPEG || mimeType === MIME_TYPE.PNG) &&
            [estimate, real].includes(thisRatio)
          )
        }
      })
    }

    // ------------- 🌩 UPLOAD FLOW -------------
    function runUploadTasks() {
      s3UploadTasks.running = true

      uploadNextInTheQ()
    }

    function uploadNextInTheQ() {
      const q = s3UploadTasks.queue

      if (!q.length) {
        s3UploadTasks.running = false
        return
      }

      const currObject = q.shift()

      // Handle if item was removed from the list while in the queue
      if (!currObject || !fileList.items.includes(currObject)) {
        return uploadNextInTheQ()
      }

      if (state.isVideoFile) {
        runUploadVideoFile(currObject).finally(uploadNextInTheQ)
      } else {
        upload(currObject)
          .then(file => {
            runBackendCheckTask(file)
          })
          .finally(uploadNextInTheQ)
      }
    }

    async function upload(file: FpS3File): Promise<FpS3File> {
      const customTags = props.tags // platform... etc.

      const ContentType =
        contentTypes.find(ct => ct.ext === file.extension)?.mimeType ||
        file.raw.type

      const fileRatio = file.ratio.estimate || file.ratio.real

      const Tagging = getTagging(customTags, fileRatio)

      file.status = FileStatusEnum.UPLOADING

      const onprogress = ev => {
        const { loaded, total } = ev
        const percent = ~~((loaded * 100) / total)

        file.uploadPercent = percent
      }

      try {
        const key = await s3UploadFile(
          file.raw,
          ContentType,
          Tagging,
          onprogress
        )

        file.switchStatus(FileStatusEnum.UPLOAD_FINISHED)
        file.id = key ? key.slice(0, -getFileExtension(key).length) : ''
        return file
      } catch (error) {
        file.switchStatus(FileStatusEnum.UPLOAD_NETWORK_ERROR)
        file.error = error
        throw error
      }
    }

    async function runBackendCheckTask(file: FpS3File, isVideo = false) {
      file.switchStatus(FileStatusEnum.BACKEND_CHECKING)

      const fileNameInBucket = isVideo ? file.key : file.id + file.extension

      const [err] = await asyncRetry({
        fn: fileCheckMutation,
        fnName: 'File Check Progress',
        inputs: [fileNameInBucket],
        times: 21,
        interval: 1000,
        shouldRetry: ([err]) => {
          return ['FL402'].includes(err.statusCode)
        },
        errorMessage: 'Upload failed, please try uploading this file again.',
      })

      if (err) {
        file.switchStatus(FileStatusEnum.BACKEND_CHECK_ERROR)
        file.error = err

        return root.$toast.error(
          err.message
            ? err.message
            : 'Something went wrong, please try again later'
        )
      }

      file.switchStatus(FileStatusEnum.ALL_GOOD)

      await updateValue()
    }

    async function runUploadVideoFile(file) {
      const customTags = props.tags
      const Tagging = getTagging(
        customTags,
        file.ratio.estimate || file.ratio.real
      )

      try {
        const numberOfParts = Math.ceil(
          file.size / S3_MULTIPART_UPLOAD_CHUNK_SIZE
        )

        const res = await gql.mutate({
          mutation: createMultipartUploadURLs,
          variables: {
            input: {
              originalName: file.fileName,
              contentType: file.mimeType,
              tags: [...Tagging],
              numberOfParts,
            },
          },
        })
        const data = res.data?.createMultipartUploadURLs
        if (!data.success) {
          throw new Error(data.message)
        }
        file.id = data.id
        file.key = data.key
        file.thumbnailUrl = data.thumbnailURL
        file.url = data.url
        initialUploadMultipart(file, data)
      } catch (err) {
        file.switchStatus(FileStatusEnum.BACKEND_CHECK_ERROR)
        s3UploadTasks.running = false
        file.error = err
        const errMsg =
          (err as Error).message ??
          'Something went wrong, please try again later'
        root.$toast.error(errMsg)
      }
    }

    async function initialUploadMultipart(file, data) {
      file.status = FileStatusEnum.UPLOADING

      let progress = {}

      const onprogress = (ev, partNumber) => {
        const { loaded, total } = ev
        progress[partNumber] = {
          loaded,
          total,
        }

        let sumLoaded = 0
        let sumTotal = 0
        Object.values(progress).forEach((i: any) => {
          sumLoaded += i.loaded
          sumTotal += i.total
        })
        const percent = ~~((sumLoaded * 100) / sumTotal)

        file.uploadPercent = percent
      }

      Promise.all(
        data.partURLs.map(async ({ partNumber, url }) => {
          await uploadPart(url, file, partNumber, onprogress)
        })
      )
        .then(async () => {
          file.switchStatus(FileStatusEnum.UPLOAD_FINISHED)
          file.switchStatus(FileStatusEnum.BACKEND_CHECKING)
          await uploadVideoFileChunksFinish(file)
          runBackendCheckTask(file, true)
        })
        .finally(() => {
          s3UploadTasks.running = false
        })
    }

    async function uploadPart(partURL, file, partNumber, onProgress) {
      const chunkSizeInByte = S3_MULTIPART_UPLOAD_CHUNK_SIZE * 1000
      const sentSize = (partNumber - 1) * chunkSizeInByte
      const fileChunk = file.raw.slice(sentSize, sentSize + chunkSizeInByte)

      const xhr = new XMLHttpRequest()
      xhr.open('PUT', partURL, true)

      if (onProgress) {
        xhr.upload.onprogress = ev => onProgress(ev, partNumber)
      }

      return new Promise<string>((resolve, reject) => {
        xhr.send(fileChunk)

        xhr.onerror = () => {
          reject(Error('xhr error: ' + xhr.responseText))
        }

        xhr.onload = () => {
          if (xhr.status !== 200) {
            reject(Error('unexpected status code: ' + xhr.status))
            return
          }

          const eTag = trimEtag(xhr.getResponseHeader('ETag'))
          if (!file.partTags) {
            file.partTags = []
          }
          file.partTags.push({
            partNumber,
            eTagID: eTag,
          })

          resolve('')
        }
      })
    }

    async function uploadVideoFileChunksFinish(file) {
      file.partTags.sort((a, b) => {
        return a.partNumber - b.partNumber
      })
      const res = await gql.mutate({
        mutation: setMultipartUpload,
        variables: {
          input: {
            key: file.key,
            uploadID: file.id,
            isComplete: true,
            partTags: [...file.partTags],
          },
        },
      })
      const data = res.data?.setMultipartUpload
      if (!data.success) {
        file.switchStatus(FileStatusEnum.UPLOAD_NETWORK_ERROR)
        file.error = new Error(data.message)
      } else {
        file.switchStatus(FileStatusEnum.UPLOAD_FINISHED)
      }
    }

    // --------- 💾 MODEL RELATED ------------
    async function updateValue() {
      const val = await Promise.all(
        fileList.items
          .filter(f => isAllGood(f))
          .map(async f => {
            const b = kb2Mb(f.size, 2)
            const videoThumbnail = f.thumbnailUrl
            const videoUrl = f.url
            const isVideoFile = f.mimeType === MIME_TYPE.MP4
            const out = isVideoFile
              ? {
                  originalName: f.fileName,
                  fileName: f.key,
                  platforms: props.platforms,
                  thumbnailUrl: videoThumbnail,
                  keyTag: ASPECT_RATIO_VALUES[getRatio(f.ratio)],
                  contentType: AD_CREATIVE_TYPE,
                  mimeType: f.raw.type,
                  size: b,
                  pixels: `${f.width}x${f.height}`,
                  ratio: f.ratio.estimate || f.ratio.real,
                  duration: f.duration,
                  url: videoUrl,
                }
              : {
                  originalName: f.fileName,
                  fileName: f.id + f.extension,
                  platforms: props.platforms,
                  thumbnailUrl: f.url,
                  keyTag: ASPECT_RATIO_VALUES[getRatio(f.ratio)],
                  contentType: AD_CREATIVE_TYPE,
                  mimeType: f.raw.type,
                  size: b,
                  pixels: `${f.width}x${f.height}`,
                  url: f.url,
                }

            return out
          })
      )

      emit('input', val)

      modelUpdateFlag = true
    }

    function genFileListFromS3Object(value: S3Object[]): FpS3File[] {
      const x = value.map(
        ({
          originalName,
          url,
          keyTag: ratioInText,
          size,
          pixels,
          durationSecond,
          mimeType: originalMimeType,
          thumbnailUrl,
        }) => {
          const ext = getFileExtension(originalName!)

          const mimeType =
            originalMimeType ??
            contentTypes.find(item => item.ext === ext)?.mimeType

          const fakeFile = { type: mimeType, name: originalName, size: 0 }

          const fpS3File = new FpS3FileClass(fakeFile as File)

          fpS3File.id = getFileNameFromUrl(url)!.split('.')[0]
          fpS3File.uploadPercent = 100
          fpS3File.url = url!
          fpS3File.thumbnailUrl = thumbnailUrl ?? ''

          if (hasAlphabet(size!)) {
            fpS3File.size = size as any
          } else {
            fpS3File.size = Number(size!) * 1024
          }

          const isDimensionsUnknown = !pixels || pixels === 'UNKNOWN'

          if (!isDimensionsUnknown) {
            const [width, height] = pixels?.split('x')!

            fpS3File.width = Number(width)
            fpS3File.height = Number(height)
          }

          fpS3File.ratio.real = ASPECT_RATIO_VALUES[ratioInText]
          fpS3File.switchStatus(FileStatusEnum.ALL_GOOD)

          const duration = parseFloat(durationSecond ?? '0')
          fpS3File.duration = isNaN(duration) ? 0 : duration

          return fpS3File
        }
      )

      return x
    }

    async function removeFile(file2Remove: FpS3File) {
      const idx = fileList.items.findIndex(f => f.id === file2Remove.id)

      if (idx !== -1) {
        removeItemAt(idx)

        await updateValue()

        // If user removed a file which has repeated ratio's peer been error at the moment
        // Make the repeated one valid
        if (isAllGood(file2Remove)) {
          const a = getRatio(file2Remove.ratio)

          const sameRatioFileNotUploaded = fileList.items.find(file => {
            const b = getRatio(file.ratio)

            return b === a && !isUploaded(file)
          })

          if (sameRatioFileNotUploaded) {
            sameRatioFileNotUploaded.clearError()

            s3UploadTasks.queue.push(sameRatioFileNotUploaded)

            sameRatioFileNotUploaded.switchStatus(
              FileStatusEnum.WAITING_FOR_UPLOAD
            )

            if (!s3UploadTasks.running) {
              runUploadTasks()
            }
          }
        }
      }
    }

    function getTagging(
      customTags: {
        [key: string]: any
      },
      fileRatio: string
    ): KeyValue[] {
      // Check S3 doc for tagging characters restriction:
      // https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/Using_Tags.html#tag-restrictions
      return Object.entries({
        ...customTags,
        TYPE: AD_CREATIVE_TYPE,
        KEY_TAG: ASPECT_RATIO_VALUES[fileRatio],
        PLATFORM: [
          AdCreativePlatformEnum.NONMOBILE,
          AdCreativePlatformEnum.MOBILE,
        ].join('-'),
      }).map(([key, value]) => ({ key, value })) as KeyValue[]
    }

    function onFinishClick() {
      emit('done')
    }
  },
})

function genTitleCell(
  file: FpS3File,
  { removeFile, emit },
  previewable = true
) {
  const error = isError(file)
  const warning = isWarning(file)
  const waiting = isWaitingUpload(file)
  const uploading = isUploading(file)
  const beChecking = isBackendChecking(file)

  const progressTransform = {
    transform: `translate3d(0, 0, 0) scaleX(${file.uploadPercent / 100})`,
  }

  let Icon: VNode

  if (uploading || beChecking) {
    Icon = (
      <v-progress-circular
        size="19"
        class="mx-2"
        width="2"
        indeterminate
        color="primary"
      />
    )
  } else if (waiting) {
    Icon = (
      <fp-icon
        style={{ fontSize: '19px' }}
        class="mx-2"
        prefix="far"
        name="clock"
      />
    )
  } else {
    Icon = <fp-icon-button icon="times" onClick={() => removeFile(file)} />
  }

  return (
    <div
      class="row align-center"
      {...bem('::title-cell', { error, unimportant: waiting, warning })}
    >
      <div class="px-2">{Icon}</div>

      <div
        {...bem('::file-block', { previewable })}
        onClick={() => previewable && handlePreview()}
      >
        <div {...bem('::progress')} style={progressTransform} />

        <div {...bem('::file-name')} class="text-truncate">
          {file.fileName.replace(file.extension, '')}
        </div>
        <span {...bem('::file-ext')}>{file.extension}</span>
      </div>
    </div>
  )

  function handlePreview() {
    emit('preview', getPreviewObj(file))
  }
}

function genSizeCell(file: FpS3File) {
  const isSizeExceed =
    isError(file) && file.error?.message === FeErrorsEnum.SIZE_EXCEED
  const waiting = isWaitingUpload(file)
  const size = kb2Mb(file.size, 2)
  return (
    <div {...bem('size-cell', { error: isSizeExceed, unimportant: waiting })}>
      {size}
    </div>
  )
}

function genRatioCell(file: FpS3File) {
  const {
    ratio: { estimate, real },
  } = file

  const waiting = isWaitingUpload(file)

  const isRepeated = isRatioRepeated(file)

  const isRatioInvalid =
    isError(file) && file.error?.message === FeErrorsEnum.UNVALID_RATIO

  let ratio = estimate ? '≈' + estimate : real

  // If the ratio is 9:21 for example, it will show the approximate icon ≈
  // This algorithm calculates the "real" ratio to be 7:3 and override the string to be just the number
  if (
    estimate &&
    (estimate === ASPECT_RATIO_VALUES.TWENTY_ONE_BY_NINE ||
      real === ASPECT_RATIO_VALUES.NINE_BY_TWENTY_ONE)
  ) {
    ratio = estimate
  }

  return (
    <div
      {...bem('ratio-cell', {
        error: isRatioInvalid || isRepeated,
        unimportant: waiting,
      })}
    >
      {ratio}
    </div>
  )
}

function genDimensionCell(file: FpS3File) {
  const waiting = isWaitingUpload(file)
  const warning = isWarning(file)

  return (
    <div {...bem('', { unimportant: waiting, warning })}>
      {file.width}x{file.height}
    </div>
  )
}

function genDurationCell(file: FpS3File) {
  const waiting = isWaitingUpload(file)
  const warning = isWarning(file)

  return (
    <div {...bem('', { unimportant: waiting, warning })}>
      {file.duration ? `${file.duration.toFixed(1)}s` : '0s'}
    </div>
  )
}

function genStatusCell(file: FpS3File) {
  const error = isError(file)
  const warning = isWarning(file)
  const waiting = isWaitingUpload(file)
  const uploading = isUploading(file)
  const allGood = isAllGood(file)
  const beChecking = isBackendChecking(file)

  let message

  if (uploading) {
    message = 'Uploading...'
  }

  if (waiting) {
    message = 'Waiting...'
  }

  if (beChecking) {
    message = 'Checking...'
  }

  if (error || warning) {
    message = file.error?.message
  }

  const fMessage = truncate(message, { length: 30 })

  const statusCellContent = on => {
    return (
      <div
        {...bem('::status-cell', {
          error,
          warning,
          primary: beChecking || uploading,
          unimportant: waiting,
          success: allGood,
        })}
        on={on}
      >
        {error && <fp-icon name="exclamation-circle" />}
        {allGood && <fp-icon size="lg" name="check-circle" />}
        <span class="ml-1 text-truncate">{fMessage}</span>
      </div>
    )
  }

  if (!message) return statusCellContent({})

  return (
    <v-tooltip
      max-width="214px"
      top
      scopedSlots={{
        activator: ({ on }) => statusCellContent(on),
      }}
    >
      {message}
    </v-tooltip>
  )
}

function getPreviewObj(file: FpS3File) {
  const { fileName, ratio, url } = file

  const finalRatio = getRatio(ratio)

  return {
    fileName,
    ratio: finalRatio,
    url,
  }
}

function preventDefault(event: DragEvent) {
  event.preventDefault()
}

function addListener(
  target: HTMLElement | Document,
  events,
  listener,
  options?
) {
  events.split(' ').forEach(eventName => {
    target.addEventListener(eventName, listener, options)
  })
}

function removeListener(target: HTMLElement | Document, events, listener) {
  events.split(' ').forEach(eventName => {
    target.addEventListener(eventName, listener)
  })
}

async function fileCheckMutation(fileName) {
  const [err, res] = await store.dispatch('tryMutate', {
    mutation: uploadFile,
    variables: { input: { fileName } },
  })

  if (err) throw err

  return res
}
