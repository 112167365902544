import debounce from 'lodash/debounce'
import isFunction from 'lodash/isFunction'

export const noop = (...args: any[]): any => {}

// addEventListener support for IE8
function bindEvent(element, eventName, eventHandler) {
  if (element.addEventListener) {
    element.addEventListener(eventName, eventHandler, false)
  } else if (element.attachEvent) {
    element.attachEvent('on' + eventName, eventHandler)
  }
}

export { isFunction, debounce, bindEvent }
