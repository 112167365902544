var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{directives:[{name:"bem",rawName:"v-bem",value:({
    dragHover: _vm.dragHover,
    uploading: _vm.uploading,
    hasState: _vm.hasState,
    success: _vm.uploadSuccess,
    failed: _vm.uploadFailed || _vm.error,
    filled: _vm.hasFileFilledOrState,
  }),expression:"{\n    dragHover,\n    uploading,\n    hasState,\n    success: uploadSuccess,\n    failed: uploadFailed || error,\n    filled: hasFileFilledOrState,\n  }"}],style:({
    '--width': _vm.finalWidth,
    '--padding-bottom': _vm.paddingBottomPercentage,
  })},[_c('input',{attrs:{"id":_vm.id,"name":_vm.name,"hidden":"hidden","type":"file","accept":_vm.accept},domProps:{"value":_vm.value},on:{"change":_vm.fileChangeHandler}}),_c('div',{directives:[{name:"bem",rawName:"v-bem:bearing-wall",arg:"bearing-wall"}]}),_c(_vm.uploadInnerComp,{ref:"uploadInner",tag:"component"}),_c('div',{directives:[{name:"bem",rawName:"v-bem:mask",value:({ lighter: _vm.isMediaType }),expression:"{ lighter: isMediaType }",arg:"mask"}]}),(_vm.uploading)?_c('div',{directives:[{name:"bem",rawName:"v-bem:progress",value:({ lighter: _vm.isMediaType }),expression:"{ lighter: isMediaType }",arg:"progress"}],style:(_vm.progressBarStyle)}):_vm._e(),_c('transition-group',{attrs:{"name":"fade-slow","mode":"out-in"}},[(_vm.uploading && !_vm.scanning)?_c('p',{directives:[{name:"bem",rawName:"v-bem:state-msg",arg:"state-msg"}],key:"percent"},[_vm._v(" "+_vm._s(_vm.uploadPercent + '%')+" ")]):(_vm.scanning)?_c('p',{directives:[{name:"bem",rawName:"v-bem:state-msg",arg:"state-msg"}],key:"scan"},[_vm._v(" Checking... ")]):_vm._e()]),_c('transition',{attrs:{"name":"fade-slow"}},[(_vm.uploading)?_c('v-progress-circular',{staticClass:"fp-upload__spinner",attrs:{"size":"20","width":"2","indeterminate":"","color":"white"}}):_vm._e()],1),_c('transition',{attrs:{"name":"fade-slow"}},[(_vm.hasFile)?_c('div',{directives:[{name:"bem",rawName:"v-bem:clear",arg:"clear"}],on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.clear($event)}}},[_c('fp-icon',{staticClass:"clear",attrs:{"name":"times"}})],1):_vm._e()]),(_vm.error && _vm.errorMessage.length && !_vm.noErrorMessage)?_c('fp-hint-message',{attrs:{"fill":"","message":_vm.errorMessageDisplay}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }