import { MutableKeys } from 'utility-types'

export type Edit = {
  renderFunc: () => JSX.Element
}

export type Create = {
  renderFunc: () => JSX.Element
}

export type Item = {
  key: string
  label: string
  displayValue: string
  hidden: Boolean
}

export type FormStateItem = {
  label: string
  value: any
}

export type Props = {
  items: Item[]
  mode: Mode
  loading: boolean
}

export type State<T> = {
  loading: boolean
  items: T[]
}

export enum Mode {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  CREATE = 'CREATE',
  LIST = 'LIST',
  DELETE = 'DELETE',
}

// Vue-metadata types

type VuePropsKeys = MutableKeys<Props>[]
export const vueProps: VuePropsKeys = ['items', 'mode', 'loading']
