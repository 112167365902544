<script>
export default {
  page: {
    title: 'Loading page...',
    meta: [{ name: 'description', content: 'Loading page...' }],
  },
}
</script>

<template>
  <transition appear>
    <main :class="$style.container">
      <v-progress-circular
        :class="$style.loadingIcon"
        size="60"
        width="5"
        indeterminate
        color="#0047ff"
      />
    </main>
  </transition>
</template>

<style lang="scss" module>
@import '@scss';

.container {
  display: flex;
  flex-grow: 1;
  height: 100%;

  &:global(.v-enter-active) {
    transition: opacity 1s;
  }

  &:global(.v-enter) {
    opacity: 0;
  }
}

.loadingIcon {
  @extend %typography-xxlarge;

  margin: auto;
}
</style>
