import Vue from 'vue'

/** configGetter may be different in env such as jest  */
export function registerVueComponents(
  fileName: string,
  configGetter: () => any
) {
  if (fileName.includes('archived')) return
  // Get the component config
  const componentConfig = configGetter && configGetter()
  // Get the PascalCase version of the component name
  const defaultComponentConfig = componentConfig?.default

  // sometimes we have non-component files ending with .tsx.
  // E.g. hooks/api that returns jsx that are not components
  // we cannot assume all .tsx files are components
  if (!defaultComponentConfig) return

  const componentName = defaultComponentConfig?.name
  const skipGloballyRegister =
    defaultComponentConfig?.skipGloballyRegister || false

  // Globally register the component

  if (!skipGloballyRegister) {
    Vue.component(componentName, defaultComponentConfig || componentConfig)
  }
}
