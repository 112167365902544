import gql from 'graphql-tag'
import { output } from '../../shared/fragments/mutationOutput'

export const createClient = gql`
  mutation createClient($input: CreateClientInput!) {
    createClient(input: $input) {
      ...output
    }
  }
  ${output}
`

export const updateClient = gql`
  mutation updateClient($input: UpdateClientInput!) {
    updateClient(input: $input) {
      ...output
    }
  }
  ${output}
`

export const archiveClients = gql`
  mutation archiveClients($ids: [ID]!) {
    archiveClients(ids: $ids) {
      ...output
    }
  }
  ${output}
`

export const unarchiveClients = gql`
  mutation unarchiveClients($ids: [ID]!) {
    unarchiveClients(ids: $ids) {
      ...output
    }
  }
  ${output}
`
