import { reactive, SetupContext, watch, computed } from '@vue/composition-api'
import { useToaster } from '@components/_base/toaster/useToaster'
import debounce from 'lodash/debounce'
import {
  MembersDispatch,
  MembersGetters,
} from '@src/state/modules/members.store'

import * as types from './types'
import * as CRUDTypes from '@components/crud/types'
import { roleDescription } from '@shared/ui/list/roles'

import { RoleTypeEnum } from '@src/shared/constants'

import {
  createOrgMember,
  updateOrgMember,
  removeOrgMember,
  USER_ID_KEY,
  ROLE_TYPE_KEY,
  EMAIL_KEY,
  FIRST_NAME_KEY,
  LAST_NAME_KEY,
  GET_ORG_MEMBERS_LIMIT,
  JOB_TITLE_KEY,
  ALLOW_ACCESS_KEY,
} from './membersAPI'

import {
  MutationRemoveOrgMemberArgs,
  CreateOrgMemberInput,
} from '@graphql-types'

const INIT_CURRENT_VIEWED_ITEMS = { crudItems: [] }
const initialFilter = {
  firstName: '',
  lastName: '',
  phone: '',
  orgID: '',
  email: null,
}
export const useMembers = (props: types.Props, ctx: SetupContext) => {
  const toaster = useToaster(ctx)

  const state = reactive<any>({
    loading: props.loading,
    currentViewedItem: INIT_CURRENT_VIEWED_ITEMS,
    navDrawerOpen: false,
    mode: CRUDTypes.Mode.LIST,
    data: props.data,
    actions: [],
    total: props.total,
    currentPage: 1,
    limit: GET_ORG_MEMBERS_LIMIT,
    showFilter: false,
    filter: { ...initialFilter },
    orgList: {},
  })

  const computedState = reactive({
    skip: computed(() => {
      return (state.currentPage - 1) * state.limit
    }),
    firstNameCrudItem: computed(() => {
      const item = state.currentViewedItem?.crudItems.find(
        o => o?.key === FIRST_NAME_KEY
      )

      if (!item) return () => {}

      return item[state.mode]
    }),
    lastNameCrudItem: computed(() => {
      const item = state.currentViewedItem?.crudItems.find(
        o => o?.key === LAST_NAME_KEY
      )
      return item[state.mode] ?? (() => null)
    }),
    roleCrudItem: computed(() => {
      const item = state.currentViewedItem?.crudItems.find(
        o => o?.key === ROLE_TYPE_KEY
      )

      return item[state.mode] ?? (() => null)
    }),
    jobTitleCrudItem: computed(() => {
      const item = state.currentViewedItem?.crudItems.find(
        o => o?.key === JOB_TITLE_KEY
      )

      return item[state.mode] ?? (() => null)
    }),
    allowAccessCrudItem: computed(() => {
      const item = state.currentViewedItem?.crudItems.find(
        o => o?.key === ALLOW_ACCESS_KEY
      )

      return item[state.mode] ?? (() => null)
    }),
    navDrawerOpen: computed(() => {
      const x = state.mode !== CRUDTypes.Mode.LIST
      return x
    }),
    navStateless: computed(() => {
      const x = state.mode !== CRUDTypes.Mode.VIEW
      return x
    }),
    showSaveBtn: computed(() => {
      return (
        state.mode === CRUDTypes.Mode.EDIT ||
        state.mode === CRUDTypes.Mode.CREATE
      )
    }),
    name: computed(() => {
      return `${state.currentViewedItem?.firstName} ${state.currentViewedItem?.lastName}`
    }),
  })

  const getActions = val => {
    let roleID = ''

    switch (state.mode) {
      case CRUDTypes.Mode.LIST:
        roleID = val?.userRole?.roleID ?? ''
        break
      case CRUDTypes.Mode.VIEW:
        roleID = val?.userRole?.roleID ?? ''
        break
      case CRUDTypes.Mode.EDIT:
        roleID = val?.roleID?.value ?? ''
        break
      case CRUDTypes.Mode.CREATE:
        roleID = val?.roleID?.value ?? ''
        break
    }

    const description =
      roleDescription[`${roleID}`] ??
      MembersGetters('rolesData').find(role => role.value === roleID)
        ?.description

    let roleData = MembersGetters('rolesData').find(o => o.value === roleID)

    const firstItem = {
      role: roleData?.text ?? '',
      icon: roleData?.icon ?? '',
      description,
    }

    state.actions = [firstItem]
  }

  watch(
    () => MembersGetters('createForm'),
    val => {
      getActions(val)
    },
    {
      deep: true,
    }
  )

  watch(
    () => MembersGetters('editForm'),
    val => {
      getActions(val)
    },
    {
      deep: true,
    }
  )

  watch(
    () => state.currentViewedItem,
    val => {
      getActions(val)
    },
    {
      deep: true,
    }
  )

  watch(
    () => MembersGetters('mode'),
    val => {
      state.mode = val

      //

      if (state.mode === CRUDTypes.Mode.CREATE) {
        state.currentViewedItem = INIT_CURRENT_VIEWED_ITEMS
        state.currentViewedItem.crudItems = props.createData.crudItems
      }

      // if (state.mode === CRUDTypes.Mode.EDIT) {
      //   state.currentViewedItem = INIT_CURRENT_VIEWED_ITEMS
      //   state.currentViewedItem.crudItems = props.createData.crudItems
      // }
    }
  )

  watch(
    () => props.loading,
    val => {
      state.loading = val
    }
  )

  watch(
    () => props.data,
    val => {
      state.data = val
    }
  )

  watch(
    () => props.total,
    val => {
      state.total = val
    }
  )

  watch(
    () => MembersGetters('orgList'),
    val => {
      state.orgList = val
    }
  )

  // methods

  const handleNavDrawerInput = val => {
    if (!val) {
      MembersDispatch('setMode', CRUDTypes.Mode.LIST)
    }
  }

  const handleViewDetailsClick = (item, index) => {
    state.currentViewedItem = { ...item, index: index }

    MembersDispatch('setMode', CRUDTypes.Mode.VIEW)

    // router
    //   .replace({
    //     name: RouteData.accountID.name,
    //     query: {
    //       [RouteData.accountID.queryKeys.tab]: 'users',
    //       [RouteData.accountID.queryKeys.id]: item.id,
    //       [RouteData.accountID.queryKeys.m]: CRUDTypes.Mode.VIEW,
    //     },
    //   })
    //   .then(() => {
    //
    //     state.currentViewedItem = item
    //     state.mode = CRUDTypes.Mode.VIEW
    //     // state.currentViewedItem = item
    //     // // state.navDrawerOpen = true
    //   })
  }

  const handlePageUpdate = page => {
    if (state.currentPage === page) return
    state.currentPage = page
    MembersDispatch('getOrgMembers', {
      skip: computedState.skip,
    })
  }

  const handleSave = async (mode: CRUDTypes.Mode) => {
    if (mode === CRUDTypes.Mode.EDIT) {
      await saveEditForm()
    }

    if (mode === CRUDTypes.Mode.CREATE) {
      await saveCreateForm()
    }
  }

  const saveCreateForm = async () => {
    const form: any = {}
    Object.assign(form, MembersGetters('createForm'))

    if (!props.createFormValidator.validateAll()) return

    state.loading = true

    let input: CreateOrgMemberInput = {
      accountType: state.currentViewedItem.accountType,
      ...form,
    }

    Object.entries(form).map(([key, item]: any) => {
      let value = item?.value
      if (typeof value === 'string') {
        value = value.trim()
      }
      input[key] = value
    })

    delete input[USER_ID_KEY]

    input.roleID = `${input.roleID}`

    const [err] = await createOrgMember(input)

    if (err) {
      toaster.error(err)
    } else {
      MembersDispatch('setMode', CRUDTypes.Mode.LIST)
      MembersDispatch('getOrgMembers', {
        skip: computedState.skip,
      })
      toaster.success('User created')
    }

    state.loading = false
  }

  const saveEditForm = async () => {
    const form: any = {}
    Object.assign(form, MembersGetters('editForm'))
    const validator = props.editFormValidators[state.currentViewedItem.index]
    if (!validator.validateAll()) return

    state.loading = true

    let input = {
      accountType: state.currentViewedItem.accountType,
      ...form,
    }

    // MembersGetters('editForm') only triggers onInputChange, so we prefill the state here
    if (Object.keys(form).length <= 0) {
      state.currentViewedItem.crudItems.forEach(element => {
        const key = element.key
        const value = element.displayValue
        input[key] = value
      })
    } else {
      Object.entries(form).map(([key, item]: any) => {
        let value = item?.value
        if (typeof value === 'string') {
          value = value.trim()
        }
        input[key] = value
      })
    }

    delete input[EMAIL_KEY]

    input.roleID = `${input.roleID}`

    if (input.roleID.split(':')[1] === RoleTypeEnum.OWNER) {
      delete input[ROLE_TYPE_KEY]
    }

    const [err] = await updateOrgMember(input)
    if (err) {
      toaster.error(err)
    } else {
      MembersDispatch('setMode', CRUDTypes.Mode.LIST)
      MembersDispatch('getOrgMembers', {
        skip: computedState.skip,
      })

      toaster.success('User saved')
    }

    state.loading = false
  }

  const handleDeleteConfirm = async (mode: CRUDTypes.Mode) => {
    state.loading = true
    let id: MutationRemoveOrgMemberArgs = state.currentViewedItem.userID

    const [err] = await removeOrgMember(id)
    if (err) {
      toaster.error(err)
    } else {
      MembersDispatch('setMode', CRUDTypes.Mode.LIST)
      MembersDispatch('getOrgMembers', {
        skip: computedState.skip,
      })

      toaster.success('User deleted')
    }
    state.loading = false
  }

  const handleEditClick = () => {
    // const editForm = props.data[state.currentViewedItem.index]

    // MembersDispatch('setEditForm', editForm)
    MembersDispatch('setMode', CRUDTypes.Mode.EDIT)
  }

  const handleDeleteClick = () => {
    MembersDispatch('setMode', CRUDTypes.Mode.DELETE)
  }

  const handleCancelClick = (mode: CRUDTypes.Mode) => {
    if (mode === CRUDTypes.Mode.EDIT || mode === CRUDTypes.Mode.DELETE) {
      MembersDispatch('setMode', CRUDTypes.Mode.VIEW)
    } else {
      MembersDispatch('setMode', CRUDTypes.Mode.LIST)
    }
  }

  const updateFirstName = debounce(function (value) {
    state.filter.firstName = value || ''
    MembersDispatch('getOrgMembers', {
      filter: { ...state.filter },
    })
  }, 600)

  const updateLastName = debounce(function (value) {
    state.filter.lastName = value || ''
    MembersDispatch('getOrgMembers', {
      filter: { ...state.filter },
    })
  }, 600)

  const updateEmailFilter = debounce(function (value) {
    state.filter.email = value !== '' ? value : null
    MembersDispatch('getOrgMembers', {
      filter: { ...state.filter },
    })
  }, 600)

  const updateOrgFilter = debounce(function (value) {
    state.filter.orgID = value
    MembersDispatch('getOrgMembers', {
      filter: { ...state.filter },
    })
  }, 600)

  const updatePhoneFilter = debounce(function (value) {
    state.filter.phone = value || ''
    MembersDispatch('getOrgMembers', {
      filter: { ...state.filter },
    })
  }, 600)

  // life cycles

  // onMounted(() => {
  //   const id = router.currentRoute.query[RouteData.accountID.queryKeys.id]
  //   const mode = router.currentRoute.query[RouteData.accountID.queryKeys.m]
  //

  //   if (id) {
  //     state.currentViewedItem = items.find(item => item?.id === id)
  //     if (!state.currentViewedItem) {
  //       toaster.warning('Unable to find user id: ' + id)
  //       state.mode = CRUDTypes.Mode.LIST
  //     } else {
  //       state.mode = mode ?? CRUDTypes.Mode.LIST
  //     }
  //   }
  // })

  return {
    state,
    handleNavDrawerInput,
    handleViewDetailsClick,
    handleEditClick,
    handleDeleteClick,
    handleCancelClick,
    handleSave,
    handleDeleteConfirm,
    handlePageUpdate,
    updateFirstName,
    updateLastName,
    updateEmailFilter,
    updateOrgFilter,
    updatePhoneFilter,
    computedState,
  }
}

export default useMembers
