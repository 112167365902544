import { FileStatusEnum, FpS3File, FeErrorsEnum } from './types'
import { ValidRatiosInNumber } from './index'
import { hasAlphabet } from '@utils'
import { ImageRatioResolutionMap } from '@src/shared/constants'

export function isUploading(file: FpS3File) {
  return file.status === FileStatusEnum.UPLOADING
}

export function isBackendChecking(file: FpS3File) {
  return file.status === FileStatusEnum.BACKEND_CHECKING
}

export function isWaitingUpload(file: FpS3File) {
  return file.status === FileStatusEnum.WAITING_FOR_UPLOAD
}

export function isAllGood(file: FpS3File) {
  return file.status === FileStatusEnum.ALL_GOOD
}

export function isUploaded(file: FpS3File) {
  return file.uploadPercent === 100 && !isUploading(file)
}

export function isWarning(file: FpS3File) {
  return !!file.warning && file.status === FileStatusEnum.BACKEND_CHECK_WARNING
}

export function isError(file: FpS3File) {
  const {
    FRONTEND_CHECK_ERROR,
    BACKEND_CHECK_ERROR,
    BACKEND_CHECK_NETWORK_ERROR,
    UPLOAD_NETWORK_ERROR,
  } = FileStatusEnum

  return (
    !!file.error &&
    [
      FRONTEND_CHECK_ERROR,
      BACKEND_CHECK_ERROR,
      BACKEND_CHECK_NETWORK_ERROR,
      UPLOAD_NETWORK_ERROR,
    ].includes(file.status)
  )
}

export function isRatioRepeated(file: FpS3File) {
  return file.error?.message === FeErrorsEnum.REPEATED_RATIO
}

export function kb2Mb(kb: number | string, precision = 1) {
  if (hasAlphabet(kb)) {
    return kb
  }

  if (kb <= 100) return Number(kb).toFixed(0) + 'KB'

  const value = +((kb as number) / 1024).toFixed(precision)

  return value + 'MB'
}

export function getFileExtension(fileName: string) {
  return fileName.indexOf('.') > -1 ? `.${fileName.split('.').pop()}` : ''
}

export function trimEtag(etag: string | null) {
  if (!etag) return etag
  return etag.replace(/"/g, '')
}

export function getImgDimension(
  file: File
): Promise<{ width: number; height: number; src: string }> {
  const fakeImg = new Image()

  let reader = new FileReader()

  let src: string = ''

  reader.readAsDataURL(file)

  reader.onloadend = ev => {
    src = ev.target?.result || ('' as any)
    fakeImg.src = src
  }

  return new Promise((resolve, reject) => {
    fakeImg.onload = function () {
      const { width, height } = this as HTMLImageElement

      resolve({ width, height, src })
    }

    fakeImg.onerror = function (error) {
      reject(error)
    }
  })
}

export function getVideoDimension(file: File): Promise<{
  width: number
  height: number
  size: number
  duration: number
}> {
  const video = document.createElement('video')
  video.src = window.URL.createObjectURL(file)

  return new Promise((resolve, reject) => {
    video.onloadedmetadata = event => {
      const size = file.size
      const width = video.videoWidth
      const height = video.videoHeight
      const duration = video.duration

      resolve({ width, height, size, duration })
    }

    video.onerror = event => {
      reject(event)
    }
  })
}

// Calc greatest common divisor
export function calcGcd(a: number, b: number) {
  if (b === 0) return a

  return calcGcd(b, a % b)
}

export function findClosestRatio(width: number, height: number) {
  if (!width || !height) return undefined
  let estimate: string | undefined
  let delta: number = Infinity

  const gcd = calcGcd(width, height)
  const X = width / gcd
  const Y = height / gcd
  const rs = X / Y

  for (const validRatio of ValidRatiosInNumber) {
    const [x, y] = validRatio.split(':').map(Number)
    const s = x / y

    // ±1% ratio tolerance
    const expected = [s * 0.99, s * 1.01]
    if (rs < expected[0] || rs > expected[1]) {
      continue
    }

    // find the closest ratio
    if (Math.abs(rs - s) < delta) {
      delta = Math.abs(rs - s)
      estimate = validRatio
    }
  }

  return estimate
}

export function getCreativeRatio(width: number, height: number) {
  // We are assuming 60:9 is similar to 20:3 (20:3 *3  = 60:9)
  // Since BE only works with 60:9 and not 20:3
  const ratioTransformMap = {
    '20:3': '60:9',
    '32:5': '6.4:1',
    '6:5': '1.2:1',
  }

  const gcd = calcGcd(width, height)
  const X = width / gcd
  const Y = height / gcd
  const z = `${X}:${Y}`

  const real = ratioTransformMap[z] ?? z

  // If img's real ratio not existed in valid ratios,
  // try to find a approximate one
  let estimate: string | undefined
  const ratio = findClosestRatio(width, height)
  if (ratio !== real) {
    estimate = ratio
  }

  return { real, estimate }
}

export function checkMinMaxPixels(
  width: number,
  height: number,
  ratio: string
) {
  const resolution = ImageRatioResolutionMap[ratio]
  if (width > resolution.max[0] || height > resolution.max[1]) {
    return FeErrorsEnum.RESOLUTION_EXCEED
  }
  if (width < resolution.min[0] || height < resolution.min[1]) {
    return FeErrorsEnum.RESOLUTION_DECEED
  }
  return false
}

export function getRatio(ratio) {
  return ratio.estimate || ratio.real
}

export function getFileNameFromUrl(url) {
  let aTag = document.createElement('a')
  aTag.href = url
  const result = aTag.pathname.slice(1)
  ;(aTag as any) = null

  return result
}
