import { actions, getters, mutations } from './orgDashboard'
import store from '../store'

type Actions = keyof typeof actions
type Computations = keyof typeof getters
type Mutations = keyof typeof mutations

export function OrgDashboardDispatch(action: Actions, payload: any) {
  return store.dispatch(`orgDashboard/${action}`, payload)
}

export function OrgDashboardCommit(mutation: Mutations, payload: any) {
  return store.commit(`orgDashboard/${mutation}`, payload)
}

export function OrgDashboardGetters(key: Computations) {
  return store.getters[`orgDashboard/${key}`]
}
