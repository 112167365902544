<template>
  <div v-bem="{ error }">
    <label v-if="label" v-bem:label>
      {{ label }}
    </label>

    <div v-bem role="checkboxgroup">
      <slot />
    </div>

    <div v-if="message" v-bem:message>
      {{ message }}
    </div>
  </div>
</template>

<script>
import useGroup from '@component-mixins/useGroup'

export default {
  name: 'FpCheckboxGroup',

  mixins: [useGroup],

  $_veeValidate: {
    value() {
      return this.value
    },
    name() {
      return this.name
    },
  },

  props: {
    name: { type: String, default: '' },
    value: {
      type: Array,
      default: () => [],
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    error: Boolean,
  },

  data() {
    return {
      childrenCount: 1,
      optionsRegistered: new Map(),
    }
  },

  created() {
    this.$on('checkboxValueChange', this.changeHandler)
    this.$on('checkboxSignUp', this.checkboxRegister)
  },

  beforeMount() {
    this.childrenCount = this.$children.length
  },

  methods: {
    changeHandler(id, checked) {
      const { optionsRegistered } = this
      const currentCheckedValue = [].concat(this.value)
      const optionValue = optionsRegistered.get(id)
      const index = currentCheckedValue.indexOf(optionValue)

      if (checked && index === -1) {
        // Check new option
        currentCheckedValue.push(optionValue)
      } else if (!checked && index !== -1) {
        // Uncheck existed option
        currentCheckedValue.splice(index, 1)
      }

      this.$emit('change', currentCheckedValue)
    },

    checkboxRegister(id, value) {
      this.optionsRegistered.set(id, value)
      if (this.optionsRegistered.has(value)) {
        return console.warn(
          "[Checkbox Group]: there's options have the same value, check it",
          value
        )
      }
      this.optionsRegistered.set(value, id)
    },
  },
}
</script>

<style lang="scss" src="@component-styles/checkbox"></style>
