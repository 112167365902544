import Vue from 'vue'
import VeeValidate, { Validator } from 'vee-validate'
import { minValueCustom, maxValueCustom } from './customValidators'

// https://baianat.github.io/vee-validate/configuration.html
// destructing order is important , ..options last
const useFormValidatorPlugin = options => {
  // e.g. add custom rules
  extendValidator()

  Vue.use(VeeValidate, {
    dictionary: {
      en: {
        messages: {
          // the default max message is too long, causing two lines of error,
          // may break spacing consistency on some inputs
          email: fieldName => `Must be a valid email.`,
          max: (fieldName, params) => `Must be at most ${params} characters.`,
          min: (fieldName, params) => `Must be at least ${params} characters.`,
          decimal: (fieldName, params) => `Must be at most ${params} decimals.`,
          min_value: fieldName => `Must not be empty`,
        },
      },
    },
    ...options,
  })
}

// define the validator names here. if we ever change the plugin library,
// just change this constants not 100 views
export const VANILLA_RULE_NAMES = {
  required: 'required',
  min: 'min',
  max: 'max',
}

// adding fp (frameplay) namespace to denote custom rule of a vanilla rule
export const CUSTOM_RULE_NAMES = {
  fpMinValue: 'fp_min_value',
  fpMaxValue: 'fp_max_value',
}

const extendValidator = () => {
  // following naming convention of vee validation here https://baianat.github.io/vee-validate/guide/rules.html
  // https://baianat.github.io/vee-validate/guide/custom-rules.html#using-the-custom-rule

  Validator.extend(CUSTOM_RULE_NAMES.fpMinValue, {
    getMessage: (field, args) => {
      const [value, valueFormatter] = args
      const _value = (valueFormatter && valueFormatter(value)) || value
      return `The ${field} must be ${_value} or more`
    },
    validate: minValueCustom,
  })

  Validator.extend(CUSTOM_RULE_NAMES.fpMaxValue, {
    getMessage: (field, args) => {
      const [value, valueFormatter] = args
      const _value = (valueFormatter && valueFormatter(value)) || value
      return `The ${field} must be less than ${_value}`
    },
    validate: maxValueCustom,
  })
}

export default useFormValidatorPlugin
