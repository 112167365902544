const queryKeys4CRUD = {
  id: 'id',
  m: 'm', // mode
}

const accountID = {
  name: 'account-id',
  queryKeys: {
    tab: 'tab',
    ...queryKeys4CRUD,
  },
}

const holdingOrgs = {
  name: 'organizations-holding',
  path: 'organizations-holding',
}

// const companyMembers = {
//   name: 'company-users',
//   path: 'company/users',
// }
export { accountID, holdingOrgs }
