<script>
import * as api from './api'
import { formatNumber } from '@utils'
import { headingFontFamily } from '@scss-ts'

export default {
  name: 'DonutChart',

  props: {
    id: {
      type: String,
      required: true,
    },

    height: {
      type: Number,
      required: true,
    },
    colors: {
      type: Array,
      default: () => [],
    },
    dataMap: {
      type: Object,
      default: () => ({}),
    },
    legend: {
      type: Object,
      default: () => ({}),
    },

    series: {
      type: Object,
      default: () => ({}),
    },
    horizontalLegendBreakpoint: {
      type: String,
      default: 'smAndDown',
      validator(val) {
        return ['smAndDown', 'mdAndDown'].includes(val)
      },
    },
  },
  data() {
    return {
      chart: null,
      seriesData: [],
    }
  },
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint
    },

    rootDomStyle() {
      return `height:${this.height}px;`
    },
  },
  watch: {
    dataMap() {
      this.resetChart()
    },
    height() {
      this.resetChart()
    },
  },

  mounted() {
    this.chart = api.init(document.getElementById(this.id))
    this.resetChart(true)
  },
  methods: {
    resetChart(noResize = false) {
      this.mapSeriesData()
      this.setOption()
      if (!noResize) {
        this.handleResize()
      }
    },
    getOption() {
      const useHorizontalLegend =
        this.breakpoint[this.horizontalLegendBreakpoint]
      let radius = this.height

      // sm needs extra room below for legend
      if (useHorizontalLegend) {
        radius -= this.legend.heightWhenSm
      }

      const formula = () => {
        const outer = radius / 2.5
        const inner = outer - 15

        return {
          // horizontal, vertical
          center: [useHorizontalLegend ? '50%' : '35%', radius / 2],
          radius: [inner, outer],
        }
      }

      const rv = {
        legend: {
          // a| syntax is used with textStyle.rich for customise
          // separate parts of the text.
          formatter: name => {
            return `   {a|${name}}    {b|${formatNumber(this.dataMap[name])}}`
          },
          textStyle: {
            rich: {
              a: {
                fontFamily: headingFontFamily,
                fontSize: 12,
                lineHeight: 18,
                align: 'left',
              },
              b: {
                fontFamily: headingFontFamily,
                fontSize: 16,
                lineHeight: 24,
                // align right no work. maybe bug related to:
                // https://github.com/ecomfe/zrender/pull/458
                align: 'right',
              },
            },
          },
          orient: useHorizontalLegend ? 'horizontal' : 'vertical',
          itemGap: useHorizontalLegend ? 10 : 20,
          left: useHorizontalLegend ? 'center' : '55%',
          top: useHorizontalLegend ? radius - 20 : 'center',
          ...this.legend,
        },
        series: [
          {
            data: [...this.seriesData],
            center: formula().center,
            radius: formula().radius,
            color: [...this.colors],
            ...this.series,
          },
        ],
      }

      return rv
    },

    mapSeriesData() {
      const result = Object.keys(this.dataMap).map(key => {
        return {
          name: key,
          value: this.dataMap[key],
        }
      })

      this.seriesData = result
    },
    handleResize() {
      if (this.chart) {
        this.$nextTick(() => {
          this.chart.resize()
        })
      }
    },

    setOption() {
      api.setOption(this.chart, { ...this.getOption() })
    },
  },
}
</script>

<template>
  <div v-resize="handleResize" :class="$style.root" :style="rootDomStyle">
    <div :id="id" style="width: 100%; height: inherit"></div>
  </div>
</template>

<style lang="scss" module>
@import '@scss';
.root {
  width: 100%;
}
</style>
