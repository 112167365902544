<script>
import { twentyFourStringToAMPM } from '@utils'

export default {
  name: 'FpTimePicker',

  props: {
    label: {
      type: String,
      default: 'Select date',
    },
    message: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      time: null,
      modal2: false,
    }
  },

  computed: {
    propsPenetrating() {
      const { $attrs, error, message: errorMessages } = this

      const props = {
        errorMessages,
        error,
        'full-width': true,
        'ampm-in-title': true,
        value: this.value,
        ...$attrs,
      }

      return props
    },

    valueToDisplay() {
      const x = twentyFourStringToAMPM(this.value)
      return x
    },
  },

  methods: {
    handleChange(input) {
      this.$emit('input', input)
    },
  },
}
</script>

<template>
  <div>
    <v-dialog
      ref="dialog"
      v-model="modal2"
      :return-value.sync="time"
      persistent
      width="280px"
    >
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <fp-input
            v-bind="$attrs"
            :value="valueToDisplay"
            :label="label"
            :message="message"
            :error="error"
            readonly
            append-icon="clock"
          />
        </div>
      </template>
      <div v-bem="{}">
        <v-time-picker
          v-if="modal2"
          v-bind="propsPenetrating"
          v-on="$listeners"
          @change="handleChange"
        >
          <v-container fluid class="px-0">
            <v-row
              tag="footer"
              justify="end"
              align="center"
              class="fp-date-time__footer"
            >
              <fp-button size="small" type="text" @click="modal2 = false">
                Cancel
              </fp-button>

              <fp-button
                size="small"
                type="text"
                @click="$refs.dialog.save(time)"
              >
                Done
              </fp-button>
            </v-row>
          </v-container>
        </v-time-picker>
      </div>
    </v-dialog>
  </div>
</template>

<style lang="scss" src="@component-styles/time-picker"></style>
