// import Auth from '@aws-amplify/auth'
// import router from '@src/router'
// import * as RouteName from '@router/routeNames'
import { differenceInHours, addHours, parseISO } from '@utils'
import useLocalStorage from '@components/hooks/useLocalStorage'
import { SIGN_UP_EXPIRY_KEY } from '@shared/auth/constants'

// expire signup/onboard process after x hours
const SIGNUP_EXPIRY_HOURS = 1

const addAuthErrorToField = (authErrors, field, vueModel) => {
  if (!vueModel || !vueModel.errors || !field) return

  vueModel.errors.remove(field)

  if (
    authErrors &&
    Array.isArray(authErrors) &&
    authErrors.length > 0 &&
    authErrors[0]
  ) {
    const msg = authErrors[0]?.message

    vueModel.errors.add({
      field: field,
      msg: msg,
    })
  }
}

const setSignupExpiryDate = () => {
  const ls = useLocalStorage(SIGN_UP_EXPIRY_KEY)
  const expiryDate = addHours(new Date(), SIGNUP_EXPIRY_HOURS)
  ls.setValue(expiryDate)
}

const signupDateHasExpired = () => {
  const ls = useLocalStorage(SIGN_UP_EXPIRY_KEY)
  const signupExpiryDate = parseISO(ls.getValue())
  const expiredByHours = differenceInHours(new Date(), signupExpiryDate)
  return expiredByHours >= SIGNUP_EXPIRY_HOURS
}

const actionSignupExpiry = () => {
  try {
    localStorage.clear()
  } catch (e) {
    console.error(e)
  }

  window.location.href = '/'
}

// const checkPhoneVerification = async routeNameOnSuccess => {
//   const cognitoUser = await Auth.currentUserPoolUser({
//     bypassCache: true,
//   })
//   console.log(cognitoUser)
//   if (!cognitoUser?.attributes?.['phone_number_verified']) {
//     router.push({
//       name: RouteName.VERIFY_PHONE,
//       params: {
//         routeNameOnSuccess: routeNameOnSuccess,
//       },
//     })
//     return false
//   }
//   return true
// }

export {
  addAuthErrorToField,
  setSignupExpiryDate,
  signupDateHasExpired,
  actionSignupExpiry,
}
