import gql from 'graphql-tag'

export const seriesItem = gql`
  fragment seriesItem on SeriesItem {
    name
    item
  }
`

export const reportingStatsChartDimension = gql`
  fragment reportingStatsChartDimension on ReportingStatsChartDimension {
    dimension
    series {
      ...seriesItem
    }
  }
  ${seriesItem}
`

export const reportingStatsChartMetric = gql`
  fragment reportingStatsChartMetric on ReportingStatsChartMetric {
    metric
    dimensions {
      ...reportingStatsChartDimension
    }
  }
  ${reportingStatsChartDimension}
`

export const chartOption = gql`
  fragment chartOption on ChartOption {
    xAxisData
  }
`

export const reportingStatsCharts = gql`
  fragment reportingStatsCharts on ReportingStatsCharts {
    metrics {
      ...reportingStatsChartMetric
    }
    chartOption {
      ...chartOption
    }
  }
  ${reportingStatsChartMetric}
  ${chartOption}
`

export const reportingStatsCardData = gql`
  fragment reportingStatsCardData on ReportingStatsCardData {
    value
    trendValue
    errorMessage
  }
`

export const reportingStatsCard = gql`
  fragment reportingStatsCard on ReportingStatsCard {
    metric
    data {
      ...reportingStatsCardData
    }
  }
  ${reportingStatsCardData}
`

// TODO - to be removed after enabling reporting dashboard for other user types
export const dashDataPoint = gql`
  fragment dashDataPoint on DashDataPoint {
    data
    label
  }
`

export const dashSeriesData = gql`
  fragment dashSeriesData on DashSeriesData {
    total
    chart {
      ...dashDataPoint
    }
  }
  ${dashDataPoint}
`

export const dashSeries = gql`
  fragment dashSeries on DashSeries {
    money {
      ...dashSeriesData
    }
    viewableImpressions {
      ...dashSeriesData
    }
    uniqueUsers {
      ...dashSeriesData
    }
  }
  ${dashSeriesData}
`
export const dashCards = gql`
  fragment dashCards on DashCards {
    age {
      ...dashDataPoint
    }
    sex {
      ...dashDataPoint
    }
    genre {
      ...dashDataPoint
    }
    rating {
      ...dashDataPoint
    }
  }
  ${dashDataPoint}
`

export const dashRangeMetricsData = gql`
  fragment dashRangeMetricsData on DashRangeMetricsData {
    series {
      ...dashSeries
    }
    cards {
      ...dashCards
    }
  }
  ${dashSeries}
  ${dashCards}
`

export const dashRangeMetrics = gql`
  fragment dashRangeMetrics on DashRangeMetrics {
    daily {
      ...dashRangeMetricsData
    }
    weekly {
      ...dashRangeMetricsData
    }
    monthly {
      ...dashRangeMetricsData
    }
  }
  ${dashRangeMetricsData}
`

export const dashTableDataRow = gql`
  fragment dashTableDataRow on DashTableDataRow {
    label
    daily
    weekly
    monthly
  }
`

export const dashTableData = gql`
  fragment dashTableData on DashTableData {
    money {
      ...dashTableDataRow
    }
    viewableImpressions {
      ...dashTableDataRow
    }
  }
  ${dashTableDataRow}
`

export const dashTables = gql`
  fragment dashTables on DashTables {
    top {
      ...dashTableData
    }
    country {
      ...dashTableData
    }
  }
  ${dashTableData}
`
