<script>
export default {
  name: 'FpSummaryHeader',

  props: {
    loading: Boolean,
    chipType: {
      type: String,
      default: 'info',
    },
    title: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    titleClasses() {
      const adminClass = this.isAdmin ? ` ${this.$style['is-admin']} ` : ' '
      return `${this.$style.title}${adminClass}mr-4 mb-0 ml-0`
    },
  },
}
</script>

<template>
  <!-- empty root div needed when parent needs to specify custom class -->
  <div>
    <header :class="$style.header + ' mb-5'">
      <div class="d-flex w-100">
        <fp-skeletonize
          :loading="loading"
          height="29"
          right-margin="16"
          width="250"
          class="d-flex w-100"
        >
          <fp-form-title :class="titleClasses">
            {{ title }}
          </fp-form-title>
          <fp-chip :type="chipType">
            {{ status }}
          </fp-chip>
        </fp-skeletonize>

        <fp-skeletonize
          v-if="$slots.default"
          :loading="loading"
          height="30"
          width="30"
          round
          no-animate
        >
          <div :class="$style.actions">
            <slot />
          </div>
        </fp-skeletonize>
      </div>
    </header>

    <fp-skeletonize
      v-if="$slots.subtitle"
      :loading="loading"
      :class="$style.subtitle"
      height="26"
      width="180"
    >
      <slot name="subtitle" />
    </fp-skeletonize>
  </div>
</template>

<style lang="scss" module>
@import '@scss';

$title-max-width: 320;

.header {
  display: flex;
  justify-content: space-between;
  margin-right: -10px;

  .title {
    max-width: #{$title-max-width}px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include mq($until: md) {
      max-width: #{$title-max-width - 40}px;
    }

    @include mq($until: sm) {
      max-width: #{$title-max-width - 140}px;
      font-size: 22px;
    }

    @include mq($until: mobile-lg) {
      max-width: #{$title-max-width - 200}px;
      font-size: 20px;
    }
  }
  .is-admin {
    @include mq($until: md) {
      max-width: #{$title-max-width - 140}px;
    }
  }

  .actions {
    display: flex;
    margin-left: auto;
    color: $color-mid-grey;

    @include mq($until: mobile) {
      display: none;
    }
  }
}

.subtitle {
  margin-top: -10px;
  margin-bottom: 16px;
  font-size: 14px;
  color: #8e8e8c;
}
</style>
