<script>
const TYPES = [
  'light',
  'dark',
  'primary',
  'info',
  'success',
  'warning',
  'error',
  'accent',
  'disabled',
  'inactive',
]

export default {
  name: 'FpChip',
  inheritAttrs: false,

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: 'info',
      validator(val) {
        return TYPES.includes(val)
      },
    },
    textColor: {
      type: String,
      default: '',
    },
    circle: Boolean,
  },
}
</script>

<template>
  <span
    v-bem="{ disabled, $type: type }"
    :style="{
      '--text-color': textColor,
    }"
  >
    <v-chip v-bind="$attrs" :class="{ 'is-circle': circle }">
      <slot />
    </v-chip>
  </span>
</template>

<style lang="scss" src="@component-styles/chip"></style>
