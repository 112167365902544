import gql from 'graphql-tag'

export const contact = gql`
  fragment contact on Contact {
    firstName
    lastName
    email
    phone
    address
  }
`
