import gql from 'graphql-tag'
import { payment } from '../fragments/payment'

export const getPayments = gql`
  query getPayments($limit: Int, $skip: Int, $filter: PaymentsFilterInput) {
    getPayments(limit: $limit, skip: $skip, filter: $filter) {
      limit
      skip
      total
      amountSumAllPages
      items {
        ...payment
      }
    }
  }
  ${payment}
`
