// @ts-ignore
/* eslint-disable no-console */
import { ENV_MODE } from '@constants'

// saves time in dev for debugging without typing and deleting console.log a lot
// cs naming is based on c++ cin and cout, short to save typing console.log a lot

// usage: cs.e(error)

export const cs = {
  l: (payload, label) => decide(C_TYPE.L, payload, label),
  e: (payload, label) => decide(C_TYPE.E, payload, label),
  i: (payload, label) => decide(C_TYPE.I, payload, label),
  w: (payload, label) => decide(C_TYPE.W, payload, label),
}

const decide = (t, payload, label = 'cs') => {
  if (!process.env.NODE_ENV === !!ENV_MODE.DEV) return

  const fLabel = '%c' + label
  switch (t) {
    case C_TYPE.E:
      console.error(fLabel, style(), payload)
      break
    case C_TYPE.L:
      console.log(fLabel, style('grey'), payload)
      break
    case C_TYPE.I:
      console.info(fLabel, style('blue'), payload)
      break
    case C_TYPE.W:
      console.warn(fLabel, style('orange'), payload)
      break
    default:
      console.warn(fLabel, style('orange'), payload)
      break
  }
}

const style = (color = 'red') => {
  return `background: ${color} ; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;`
}

export const C_TYPE = {
  E: 'error',
  W: 'warn',
  I: 'info',
}

export default cs
