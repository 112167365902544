import { getOrgVerifyByIds } from '@src/shared/org/api'
import Vue from 'vue'
import store from '../store'

type OrgId = string

export type OrgVerify = {
  id: OrgId
  title: string
  status: string
  isStudent: boolean
}

type MapKeyToObj<T> = { [K in keyof T]: any }

export const state = {
  map: {},
}

type States = typeof state

export const getters = {
  ids({ map }: States) {
    return Object.keys(map)
  },
}

type Getters = MapKeyToObj<typeof getters>

export const mutations = {
  SET_NEW(state, org: OrgVerify) {
    Vue.set(state.map, org.id, org)
  },

  CLEAR_ALL(state) {
    Vue.set(state, 'map', {})
  },
}

type Mutations = MapKeyToObj<typeof mutations>

export const actions: {
  [key: string]: (
    store: { getters: Getters; commit(x: keyof Mutations, y?: any): void },
    arg: any
  ) => any | Promise<any>
} = {
  init() {},

  async fetchVerifyStatus({ getters, commit }, ids: OrgId[]) {
    const news = ids.filter(id => !getters.ids.includes(id))

    if (news.length === 0) return false

    const [err, data] = await getOrgVerifyByIds<OrgVerify>(news)

    if (!err && data) {
      data.items.forEach(org => {
        commit('SET_NEW', org)
      })
    }
  },

  clearVerifyMap({ commit }) {
    commit('CLEAR_ALL')
  },
}

type Actions = keyof typeof actions

export function OrgToVerifyDispatch(action: Actions, payload: any) {
  return store.dispatch(`orgToVerify/${action}`, payload)
}
