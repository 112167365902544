<template>
  <div
    :class="[
      $style.container,
      { [$style.isAdminMode]: adminEditMode },
      'justify-end',
    ]"
  >
    <div
      v-if="showTransactionBalance"
      :class="$style.headerAction"
      data-cy="header-action--transactions"
    >
      <transaction @on-add-funds="handleAddFundsClick" />
    </div>

    <div :class="$style.headerAction" data-cy="header-action--notifications">
      <notification />
    </div>

    <!-- User actions -->
    <fp-dropdown ref="userDropdown">
      <template #activator="{ toggle, visible }">
        <!-- disable click when loading, menu looks too empty/small -->
        <div
          v-ripple
          data-cy="user-dropdown-activator"
          tabindex="2"
          :class="[$style.effect, $style.headerAction]"
          @keyup.enter="toggle(!fetching && !loading)"
          @click="toggle(!fetching && !loading)"
        >
          <fp-icon name="user-circle" size="lg" :class="$style.userIcon" />

          <transition
            name="fade-skeleton"
            mode="out-in"
            @after-enter="afterOrgnameEnter"
          >
            <fp-skeleton
              v-if="fetching && loading"
              right-margin="8"
              left-margin="8"
              :width="isMobile ? 0 : orgnameEleWidth"
              height="25"
            />
            <span
              v-else
              data-cy="header-username-text"
              :class="$style.userName"
              >{{ displayName }}</span
            >
          </transition>

          <fp-icon
            :name="visible ? 'angle-up' : 'angle-down'"
            size="sm"
            :class="[$style.icon, $style.angleIcon]"
          />
        </div>
      </template>

      <!-- 🙋 USER DROPDOWN -->
      <template #content="{ toggle, isPopupsEntered }">
        <user-content
          :visible="isPopupsEntered"
          :user-info="userInfo"
          @toggle-visible="toggle"
        />
      </template>
    </fp-dropdown>
  </div>
</template>

<script>
/* eslint no-unused-vars: 0 */
import * as FundsStore from '@state/modules/funds'
import { HeaderEventBus, HEADER_EVENTS } from './'
import * as sub from '@graphql/notification/subscriptions'
import { mapGetters } from 'vuex'
import UserContent from './actions/header-user-dropdown-content'
import FpDropdown from '@components/_base/dropdown'
import Notification from './actions/notification'
import Transaction from './actions/transaction'
const ORGNAME_ELE_WIDTH = 'ORGNAME_ELE_WIDTH'

export default {
  skipGloballyRegister: true,

  name: 'HeaderActions',

  components: {
    Transaction,
    UserContent,
    FpDropdown,
    Notification,
  },

  provide() {
    return { $style: this.$style }
  },

  data() {
    return {
      userActionsVisible: false,
      orgnameEleWidth: 80,
    }
  },

  computed: {
    ...mapGetters('user', ['userInfo', 'loading', 'allowEarnings']),
    ...mapGetters('org', [
      'isUnverified',
      'isUnderReview',
      'isRejected',
      'verification',
      'fetching',
      'currentOrganisation',
      'organisation',
      'isThirdParty',
      'isSuperAdmin',
    ]),

    showTransactionBalance() {
      const x =
        !this.isSuperAdmin &&
        !this.isThirdParty &&
        this.userInfo?.permissions?.includes('getPayments') &&
        this.allowEarnings
      return x
    },

    displayName() {
      return this.userInfo?.firstName
        ? this.userInfo?.firstName
        : this.source.title
    },

    adminEditMode() {
      return this.$route.meta.adminEditMode
    },

    source() {
      if (this.adminEditMode) {
        return this.currentOrganisation
      }

      return this.organisation
    },

    isMobile() {
      return this.$vuetify.breakpoint.xs
    },
  },

  created() {
    const cachedOrgnameEleWidth = localStorage.getItem(ORGNAME_ELE_WIDTH)

    if (cachedOrgnameEleWidth && typeof +cachedOrgnameEleWidth === 'number') {
      this.orgnameEleWidth = cachedOrgnameEleWidth
    }

    this.listenHeaderEvents()
  },

  methods: {
    listenHeaderEvents() {
      HeaderEventBus.$on(HEADER_EVENTS.headerUnpin, () => {
        // eslint-disable-next-line no-unused-expressions
        this.$refs.userDropdown?.toggle(false)
      })
    },
    handleAddFundsClick() {
      const blockAddFunds =
        this.isUnverified || this.isRejected || this.isUnderReview

      if (blockAddFunds) {
        this.$confirm.verifyYourAccount()
      } else {
        // go back to step 1 of add funds when in add funds page
        if (this.$route.name === 'add-funds') {
          FundsStore.dispatch('setState', {
            key: 'currentAddFundsStep',
            value: 1,
          })
        } else {
          this.$router.push({ name: 'add-funds' })
        }
      }
    },
    showUserDropdown(ev) {
      this.userActionsVisible = true
    },

    /** @param {HTMLElement} orgnameEle */
    afterOrgnameEnter(orgnameEle) {
      const { width } = orgnameEle.getBoundingClientRect()

      localStorage.setItem(ORGNAME_ELE_WIDTH, width)
    },
  },
}
</script>

<style lang="scss" module>
@import '~watson-scss';
@import '@scss';

$header-action-x-pad: 10px;

.container {
  display: flex;
  justify-content: center;
  height: 75%;
  margin-left: auto;

  @include mq($until: mobile) {
    width: 100%;
    margin: 0;
  }
}

.headerAction {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 $header-action-x-pad;
  margin-left: 8px;
  font-size: inherit;
  color: var(--header-text-active-color);
  cursor: pointer;
  user-select: none;
  border-radius: 6px;

  @include mq($until: 1400px) {
    margin-left: 0;
  }

  @include mq($until: md) {
    padding: 5px;
    &:first-child {
      padding-left: $header-action-x-pad;
    }
  }

  @include pseudo-class(focus) {
    outline: none;
  }

  &.effect {
    @include perfect-transition;

    padding: 0 15px;

    @include mq($until: 1400px) {
      padding: 0 10px;
    }

    @include mq($until: mobile) {
      padding: 0;
    }

    @include pseudo-class(focus) {
      background-color: $color-white-glass;
    }
  }
}

.balance {
  padding-right: 10px;
  padding-left: 5px;
  font-size: 21px;

  @include mq($until: mobile) {
    font-size: 18px;
  }
}

.button {
  position: relative;
  display: flex;
  align-items: center;
  height: 42px;
  font-size: 12px;
  color: $color-white;

  @include mq($until: mobile) {
    height: 40px;
    font-size: 12px;
  }

  &.left {
    padding: 0 20px 0 30px;
    background: var(--header-button-left-background);
    border-radius: 100px 0 0 100px;

    &.round {
      border-radius: 100px;
    }

    @include mq($until: mobile) {
      padding: 0 10px 0 20px;
    }
  }

  &.right {
    padding: 0 40px 0 20px;
    background: var(--header-button-right-background);
    border-radius: 0 100px 100px 0;

    @include mq($until: lg) {
      padding: 0 20px 0 20px;

      strong {
        display: none;
      }
    }
  }

  &:focus,
  &:active {
    background: var(--header-button-active-background);
    outline: none;
  }

  > .iconCircle {
    @include circle(24px, $color-white-glass);

    position: absolute;
    right: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;

    @include mq($until: lg) {
      color: $color-primary;
      background: linear-gradient(180deg, #f6f7fc 0%, #e3e8f4 100%);
    }
  }
}

.userName {
  @include ellipsis();

  min-width: 80px;
  max-width: 130px;
  margin: 0 8px;
  font-size: 1.12em;
  text-align: center;
}

.icon {
  color: inherit;
}
.userIcon {
  font-size: 2.5em;
}
.angleIcon {
  font-size: 1.25em;
}

.userName,
.angleIcon {
  @include mq($until: mobile) {
    display: none;
  }
}
</style>
