import { getCurrentUser } from '@graphql/user/queries'
import { Store } from 'vuex'
import { User, OrgHierarchyEnum } from '@graphql-types'
import useLocalStorage from '@components/hooks/useLocalStorage'
import { LOCAL_STORE_KEYS } from '@state/modules/auth'
import { cloneDeep } from 'lodash'
import { OrgGetters } from './org.store'
import { canEditCostSettingsUserIDs, ENV_MODE } from '@src/shared/constants'

/* this user info is from graphql, contains email, id, first and last name, etc. */
type UserInfo = User
export const state = {
  userInfo: {},
  loading: false,
  isHolding: false,
  allowLiveData: false,
  allowEarnings: false,
}

export const getters = {
  userInfo({ userInfo }) {
    return userInfo
  },
  loading({ loading }) {
    return loading
  },
  isHolding({ userInfo }) {
    return userInfo?.org?.hierarchy === OrgHierarchyEnum.HOLDING
  },
  allowLiveData({ userInfo }) {
    return userInfo?.roles?.[0]?.allowLiveData
  },
  allowEarnings({ userInfo }) {
    return userInfo?.roles?.[0]?.allowEarnings
  },
  allowAccessToAllEntities({ userInfo }) {
    return userInfo.allowAccessToAllEntities
  },
  canAccessNewCreativeRatios({ userInfo }) {
    // TODO: currently enable access for all GG users only
    return userInfo?.org?.id === '6w3GKzjdd_c' || OrgGetters('isSuperAdmin')
  },
  canAccessAdPlatform({ userInfo }) {
    return userInfo?.org?.id === '6w3GKzjdd_c' || OrgGetters('isSuperAdmin')
  },
  canAccessMPT({ userInfo }) {
    // TODO: currently enable access for all GG users only
    return userInfo?.org?.id === '6w3GKzjdd_c' || OrgGetters('isSuperAdmin')
  },
  canAccessMPTPlatformFiltering() {
    // TODO: temporarily disable from GG
    return OrgGetters('isSuperAdmin')
  },
  canAccessInsertionOrder() {
    // TODO: currently enable IO for FPSA only
    return OrgGetters('isSuperAdmin')
  },
  canEditCostSettings({ userInfo }) {
    // TODO: currently enable cost settings editting for FPSA specific admins only
    return canEditCostSettingsUserIDs.includes(userInfo?.id)
  },
  canReadCostSettings() {
    // TODO: currently enable cost settings reading for FPSA only
    return OrgGetters('isSuperAdmin')
  },
  canAccessBillingSettings({ userInfo }) {
    // TODO: currently enable billing settings for FPSA only
    return (
      process.env.NODE_ENV === ENV_MODE.DEV ||
      canEditCostSettingsUserIDs.includes(userInfo?.id)
    )
  },
  canEditBillingSettings({ userInfo }) {
    // TODO: currently enable billing settings for FPSA only
    return (
      process.env.NODE_ENV === ENV_MODE.DEV ||
      canEditCostSettingsUserIDs.includes(userInfo?.id)
    )
  },
}

export const mutations = {
  SET_USER_INFO(state, value: UserInfo) {
    state.userInfo = value
  },
  SET_LOADING(state, value) {
    state.loading = value
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // it also runs after a user logs out and logs in, so we get the new user info
  // maybe from another user
  async init({ dispatch }) {
    await dispatch('getUserInfo')
  },

  async setLoading({ dispatch, commit, state }, data) {
    commit('SET_LOADING', data)
  },

  async getUserInfo(this: Store<any>, { dispatch, commit }, variables = {}) {
    dispatch('setLoading', true)

    const cachedUser = useLocalStorage(LOCAL_STORE_KEYS.USER_ID)

    const [err, res] = await this.dispatch('tryQuery', {
      query: getCurrentUser,
      variables,
    })

    const tmp = {
      ...res,
      permissions:
        cachedUser.getValue()?.signInUserSession?.idToken?.payload?.permissions,
    }

    if (!err) commit('SET_USER_INFO', tmp)
    dispatch('setLoading', false)
  },

  updatePermissions({ commit, state }) {
    const cachedUser = useLocalStorage(LOCAL_STORE_KEYS.USER_ID)
    const userInfo = cloneDeep(state.userInfo)
    const permissions =
      cachedUser.getValue()?.signInUserSession?.idToken?.payload?.permissions
    userInfo.permissions = permissions
    commit('SET_USER_INFO', userInfo)
  },
}
