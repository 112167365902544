import {
  Mutation,
  MutationCreateAccountArgs,
  OrgTypeEnum,
} from '@graphql-types'
import * as mutas from '@graphql/org/mutations'

import { reactive, SetupContext } from '@vue/composition-api'
import { useToaster } from '@components/_base/toaster/useToaster'
import useRadio from '@components/hooks/useRadio'
import useAuth from '@components/hooks/useAuth'
import { AuthGetters, AuthDispatch } from '@src/state/modules/auth.store'
import * as OnboardStore from '@state/modules/onboard'
// @ts-ignore
import scss from '@scss-ts'
import { isFunction } from '@shared/function'
import * as RouteName from '@router/routeNames'
import { tryMutate } from '@graphql/query-helpers'
import { vueToNormalObject } from '@utils'
import every from 'lodash/every'
import Check from '@components/svgs/check'

import {
  setSignupExpiryDate,
  signupDateHasExpired,
  actionSignupExpiry,
} from '@shared/auth/api'

export type Form = OnboardStore.State

type State = {
  loading: boolean
}

const initOpts = {
  immediate: true,
}
type Options = typeof initOpts

export const useOnboard = (ctx: SetupContext, opts: Options = initOpts) => {
  // expire signup/onboard process if it has not completed after x time
  // so, users dont get stuck on the signup screens
  if (signupDateHasExpired()) {
    actionSignupExpiry()
  } else {
    setSignupExpiryDate()
  }

  const tmp = OnboardStore.getState()
  const appState = vueToNormalObject(tmp)

  const auth = useAuth(ctx)
  const toaster = useToaster(ctx)

  const state: State = reactive({
    loading: false,
  })

  const radio = reactive({
    accountType: useRadio<OnboardStore.AccountType>(),
    isStudent: useRadio<OnboardStore.Student>(),
    orgType: useRadio<OnboardStore.OrgType>(),
  })

  const form = reactive<Form>({
    ...appState,
  })

  for (let [key, value] of Object.entries(radio)) {
    value.set(appState[key])
  }

  /** onboarding process starts if the user has no org id */
  const shouldOnboard = () => {
    const org =
      AuthGetters('currentUser')?.signInUserSession?.idToken?.payload
        ?.organization ?? ''

    if (!org) return true
    return false
  }

  const defaultOnNotOnboard = () => {
    ctx.root.$router.push({ name: 'dashboard' })
  }

  /** determine if and where to resume the onboarding process */
  const maybeResume = (onNotOnboard: () => void = defaultOnNotOnboard) => {
    const appState = OnboardStore.getState()

    // vue router will only route the final route when parent function finished,
    // so call parent's route here
    if (!shouldOnboard()) {
      if (onNotOnboard && isFunction(onNotOnboard)) {
        onNotOnboard()
      }
      return
    }

    const currRouteName = ctx?.root?.$route?.name ?? ''

    let allEmpty = true
    every(Object.values(appState), prop => {
      if (prop) allEmpty = false
    })

    // goto start of form if user jumped to middle of form and previous form not filled
    if (allEmpty && currRouteName !== RouteName.ONBOARD_ACCOUNT_TYPE) {
      ctx.root.$router.push({ name: RouteName.ONBOARD_ACCOUNT_TYPE })
    }
  }

  const saveAndContinue = (input: Partial<OnboardStore.State>) => {
    state.loading = true
    Object.entries(input).forEach(([key, value]) => {
      OnboardStore.dispatch('setState', {
        key: key as any,
        value: value as any,
      })
    })

    maybeResume()
  }

  const formToMutationArgs = async () => {
    const appState = OnboardStore.getState()
    const accountType = appState.accountType
    const orgType =
      accountType === 'build' ? OrgTypeEnum.GAME_PUBLISHER : appState.orgType

    const fName = appState.contact?.firstName
    const lName = appState.contact?.lastName

    const userInfo = await auth.currentUserInfo()
    const email = userInfo?.attributes?.email

    const jobTitle = appState.contact?.jobTitle
    const timezone = appState.contact?.timezone

    const args: MutationCreateAccountArgs = {
      input: {
        // @ts-ignore
        orgType,
        student: {
          ...appState.student,
        },

        title: appState.title
          ? appState.title
          : `${appState.contact?.firstName} ${appState.contact?.lastName}`,
        contact: {
          firstName: fName,
          lastName: lName,
          email,
          jobTitle,
          timezone,
        },
      },
    }
    return args
  }

  const createAccount = async () => {
    state.loading = true

    const args = await formToMutationArgs()

    const [err, res]: [
      Error | null,
      Mutation['createAccount']
    ] = await tryMutate({
      mutation: mutas.createAccount,
      variables: {
        ...args,
      },
    })

    toaster.error(err)

    if (res?.success) {
      AuthDispatch('refreshSession', async () => {
        window.location.href = '/dashboard'
      })
    } else {
      state.loading = false
    }
  }

  const getActiveIcon = () => <Check />

  // init side effects

  // to prevent users goto other onboard pages when the preceeding pages
  // have not been filled.
  if (opts?.immediate) {
    maybeResume()
  }

  return {
    getActiveIcon,
    state,
    form,
    saveAndContinue,
    maybeResume,
    radio,
    shouldOnboard,
    createAccount,
  }
}

export default useOnboard
