import { embedFuncs } from '@shared/string/tagged'
import { replaceAll } from '@utils'
import { formatType } from './utils'

const mapLabels = args => {
  const out = args.labels
    .map(label => {
      return `<li><strong>${label}</strong></li>`
    })
    .join(',')

  return replaceAll(out, ',', '')
}

export const title = embedFuncs`
Confirm ${args => formatType(args?.type, { allUppercase: true })} Update`

export const content = embedFuncs`
  <div class="text-left">
    <p>
      Updating any of the following fields will require a Frameplay admin to
      re-approve your ${formatType}:
    </p>

    <ul style="width: 200px;display: inline-block;">
      ${args => mapLabels(args)}
    </ul>
    <p>
      During the approval process ads will not be displayed in your game's ad
      placements.
    </p>
    <p>
      This review process can take up to 3 business days. <strong>Do you want to continue?</strong>
    </p>
  </div>
`
