<script>
export default {
  name: 'White',
}
</script>

<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 500 834"
    xml:space="preserve"
  >
    <path
      fill="#fff"
      d="M242.5.1 0 242.2v344.9l63.4 63.4V407.9l179.1-179.2v-89.6L63.4 318.2v-49.8L242.5 89.3z"
    />
    <path
      fill="#fff"
      d="M270.9 252.7 99.7 424v346.5l63.3 63.4V450.2l134.2-134.1H347L195.4 467.6v89.7L500 252.7z"
    />
  </svg>
</template>
