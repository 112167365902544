import * as CRUDTypes from '@components/crud/types'
import * as MemberTypes from '@components/members/types'
import { vueToNormalObject } from '@utils'

type State = {
  mode: CRUDTypes.Mode
  createForm?: MemberTypes.MemberItem
  editForm?: MemberTypes.MemberItem
  getOrgMembers: ({ filter, skip, limit }) => any
  loading: boolean
  rolesData: Array<any>
  orgList: Array<any>
}

export const state: State = {
  mode: CRUDTypes.Mode.LIST,
  createForm: undefined,
  editForm: undefined,
  getOrgMembers: () => {},
  loading: false,
  rolesData: [],
  orgList: [],
}

export const getters = {
  loading({ loading }) {
    return loading
  },
  mode({ mode }) {
    return mode
  },
  createForm({ createForm }) {
    return createForm
  },
  editForm({ editForm }) {
    return editForm
  },
  getOrgMembers({ getOrgMembers }) {
    return getOrgMembers
  },
  rolesData({ rolesData }) {
    return rolesData
  },
  orgList({ orgList }) {
    return orgList
  },
}

export const mutations = {
  SET_LOADING(state, value) {
    state.loading = value
  },
  SET_MODE(state, value) {
    state.mode = value
  },
  SET_CREATE_FORM(state, value) {
    state.createForm = value
  },
  SET_EDIT_FORM(state, value) {
    state.editForm = value
  },
  GET_ORG_MEMBERS(state, value) {
    state.getOrgMembers = value
  },
  SET_ROLES_DATA(state, value) {
    state.rolesData = value
  },
  SET_ORG_LIST(state, value) {
    state.orgList = value
  },
}

export const actions = {
  async setLoading({ dispatch, commit, state }, data) {
    commit('SET_LOADING', data)
  },
  async setMode({ dispatch, commit, state }, data) {
    commit('SET_MODE', data)
  },
  async setCreateForm({ dispatch, commit, state }, data) {
    commit('SET_CREATE_FORM', vueToNormalObject(data))
  },
  async setEditForm({ dispatch, commit, state }, data) {
    commit('SET_EDIT_FORM', vueToNormalObject(data))
  },
  async getOrgMembers({ dispatch, commit, state }, data) {
    commit('GET_ORG_MEMBERS', data)
  },
  async setRolesData({ dispatch, commit, state }, data) {
    commit('SET_ROLES_DATA', data)
  },
  async setOrgList({ dispatch, commit, state }, data) {
    commit('SET_ORG_LIST', data)
  },
}

// ===
// Helpers
// ===

// ===
// Private helpers
// ===
