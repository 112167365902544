<template>
  <v-dialog
    v-model="state.visible"
    :width="breakPoint.smAndDown ? '94%' : 600"
    :persistent="persistent"
    attach="#app-wrapper"
    @click:outside="clickOutsideHandler"
  >
    <fp-card
      :padding="breakPoint.smAndDown ? '36px 25px 30px' : '56px 45px 40px'"
    >
      <fp-icon-button
        v-if="!persistent"
        :class="$style.closeButton"
        icon="times"
        size="small"
        @click.stop="hide"
      />

      <section :class="$style.dialogInner">
        <h3 v-if="title" v-html="title" />

        <div
          v-if="content"
          :class="[
            $style.dialogContent,
            `text-${state.textAlign}`,
            state.noButtons && !isPrompt && breakPoint.smAndDown ? 'mb-0' : '',
          ]"
          v-html="content"
        />

        <div v-if="isPrompt" :class="[$style.dialogTextarea, 'mb-4']">
          <fp-select
            v-if="promptOptions && promptOptions.length > 0"
            v-model="state.userInput"
            :label="label"
            :items="promptOptions"
            :required="required"
            :error="error.value"
            :message="error.message"
            :disabled="state.loading"
            :placeholder="placeholder"
            hide-details
            class="mb-4"
          />

          <fp-input
            v-else
            v-model="state.userInput"
            type="textarea"
            :required="required"
            :error="error.value"
            :message="error.message"
            :disabled="state.loading"
            :placeholder="placeholder"
            :label="label"
          />

          <fp-input
            v-if="state.showTextArea"
            v-model="state.userInputCustom"
            type="textarea"
            :required="required"
            :error="error.value"
            :message="error.message"
            :disabled="state.loading"
            :placeholder="placeholder"
            :label="label"
          />
        </div>

        <div
          v-if="confirmInput"
          :class="$style.dialogConfirmInput"
          data-cy="modal-confirm-input"
        >
          <div class="mb-2">
            Please type
            <b data-cy="modal-confirm-text">{{ confirmInput }}</b>
            to confirm.
          </div>
          <fp-input
            v-model="state.userConfirmInput"
            type="text"
            :input-debounced="true"
          />
        </div>

        <fp-button
          v-if="confirmInput"
          :loading="state.loading"
          icon="angle-right"
          fill
          size="medium"
          type="error"
          data-cy="modal-yes-button"
          :disabled="state.userConfirmInput !== confirmInput"
          @click="yesHandler"
        >
          I understand the consequences,
          <span v-if="breakPoint.xsOnly"> <br /></span>confirm update
        </fp-button>

        <fp-button
          v-if="yesText && !confirmInput"
          :loading="state.loading"
          icon="angle-right"
          data-cy="modal-yes-button"
          :disabled="state.loading"
          @click="yesHandler"
        >
          {{ yesText }}
        </fp-button>

        <fp-button
          v-if="nopeText"
          type="text"
          class="ml-0 mt-6"
          data-cy="modal-no-button"
          :disabled="state.loading"
          :style="{ color: $style.globalColorMidPrimary }"
          @click="nopeHandler"
        >
          {{ nopeText }}
        </fp-button>
      </section>
    </fp-card>
  </v-dialog>
</template>

<script>
import {
  createComponent,
  reactive,
  getCurrentInstance,
  computed,
  watch,
} from '@vue/composition-api'
import { noop } from '@src/shared/function'
import useBreakpoint from '@components/hooks/useBreakpoint'

export default createComponent({
  name: 'FpMessageDialog',

  props: {
    isPrompt: Boolean,
    required: Boolean,
    persistent: Boolean,
    initialValue: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    textAlign: {
      type: String,
      default: 'center',
    },
    placeholder: {
      type: String,
      default: '',
    },

    requiredMessage: {
      type: String,
      default: 'This field is required.',
    },

    promptOptions: {
      type: Array,
      default: null,
    },

    // select option value that triggers a custom input
    promptOptionCustomInputValue: {
      type: String,
      default: '',
    },

    title: {
      type: String,
    },

    yesText: {
      type: String,
      default: null,
    },

    nopeText: {
      type: String,
      default: null,
    },

    content: { type: [Object, String], default: null },

    onYes: { type: Function, default: noop },
    onNope: { type: Function, default: noop },

    confirmInput: { type: String, default: '' },
  },

  setup(props, { emit, root }) {
    const state = reactive({
      loading: false,
      textAlign: '',
      visible: false,
      showTextArea: false,
      userInput: props.initialValue,
      userInputCustom: '',
      noButtons: computed(() => !props.yesText && !props.nopeText),
      userConfirmInput: null,
    })

    watch(
      () => state.userInput,
      val => {
        if (!props.promptOptionCustomInputValue || !state.userInput) {
          state.showTextArea = false
          state.userInputCustom = ''
        }

        if (
          state.userInput &&
          state.userInput.length > 0 &&
          state.userInput === props.promptOptionCustomInputValue
        ) {
          state.showTextArea = true
        } else {
          state.showTextArea = false
          state.userInputCustom = ''
        }
      }
    )

    state.textAlign = props?.textAlign ?? 'center'

    const error = reactive({
      value: false,
      message: '',
    })

    const _this = getCurrentInstance()

    const [breakPoint] = useBreakpoint({ root })

    return {
      breakPoint,
      state,
      error,
      show,
      hide,
      yesHandler,
      nopeHandler,
      clickOutsideHandler,
      toggleLoading,
    }

    function show() {
      state.visible = true
    }

    function hide() {
      state.visible = false

      setTimeout(() => {
        _this.$destroy()
      }, 500)
    }

    function toggleLoading(val) {
      state.loading = typeof val === 'boolean' ? val : !state.loading
    }

    function yesHandler() {
      state.loading = true
      if (
        props.required &&
        (!state.userInput || (state.showTextArea && !state.userInputCustom))
      ) {
        error.value = true
        error.message = props.requiredMessage
        state.loading = false
        return
      } else {
        error.value = false
        error.message = ''
      }

      const userInputFinal = state.showTextArea
        ? state.userInputCustom
        : state.userInput

      props.onYes &&
        props.onYes({ show, hide, userInput: userInputFinal, toggleLoading })
    }

    function nopeHandler() {
      props.onNope && props.onNope({ show, hide, toggleLoading })
    }

    function clickOutsideHandler() {
      if (props.persistent) return

      hide()
    }
  },
})
</script>

<style lang="scss" module="$style" src="./message-dialog.module.scss"></style>
