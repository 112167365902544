import { enumsToSelectItems } from '@utils'
import {
  DATE_INTERVAL_LABELS,
  DASHBOARD_PERIOD_INTERVAL_LABELS,
} from '@constants'
import Vue from 'vue'

import {
  LanguageEnum,
  DateIntervals,
  DashboardPeriodIntervalsEnum,
  TimeZones,
} from '@shared/enums'

export const state = {
  dateIntervals: [],
  periodIntervals: [],
  languages: [],
  timezones: [],
}

export const getters = {
  languages({ languages }) {
    return languages
  },
  dateIntervals({ dateIntervals }) {
    return dateIntervals
  },
  periodIntervals({ periodIntervals }) {
    return periodIntervals
  },
}

export const mutations = {
  SET_LANGUAGES(state) {
    let data = enumsToSelectItems({ enums: LanguageEnum })
    data[0].text = 'All Languages'
    state.languages = data
  },
  SET_DATE_INTERVALS(state) {
    let items = enumsToSelectItems({
      enums: DateIntervals,
      textMap: DATE_INTERVAL_LABELS,
    })
    state.dateIntervals = items
  },
  SET_PERIOD_INTERVALS(state) {
    let items = enumsToSelectItems({
      enums: DashboardPeriodIntervalsEnum,
      textMap: DASHBOARD_PERIOD_INTERVAL_LABELS,
    })
    state.periodIntervals = items
  },
  SET_ITEMS(state, { items, placeholder, key }) {
    let newItems = [...items]
    newItems[0].text = placeholder
    newItems[0].value = ''
    Vue.set(state, key, newItems)
  },
  SET_TIMEZONES(state) {
    let items = enumsToSelectItems({ enums: TimeZones, valueAsText: true })
    state.timezones = items
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ dispatch, commit }) {
    commit('SET_DATE_INTERVALS')
    commit('SET_PERIOD_INTERVALS')
    commit('SET_LANGUAGES')
    commit('SET_TIMEZONES')
  },

  mapItems({ commit }, { toMap, placeholder, key }) {
    const items = enumsToSelectItems({ enums: toMap })
    commit('SET_ITEMS', { items, placeholder, key })
  },
}
