<template>
  <section ref="bar-chart-skeleton" :class="$style.container">
    <ul :class="$style.yaxis">
      <li v-for="y in yaxis" :key="y" :class="$style.ycoordinate">
        <fp-skeleton no-animate height="8" width="30" />
      </li>
    </ul>

    <ul :class="$style.list">
      <li
        v-for="(item, index) in fakeData"
        :key="'bar' + index"
        :class="$style.item"
      >
        <span :style="{ height: item[0] }" :class="$style.solid" />
        <span :style="{ height: item[1] }" :class="$style.weak" />
      </li>
    </ul>

    <ul :class="$style.xaxis">
      <li v-for="x in xaxis" :key="x" :class="$style.xcoordinate">
        <fp-skeleton no-animate height="8" width="8" />
      </li>
    </ul>
  </section>
</template>

<script>
import { convertToUnit } from '@utils'

// const genRandomTwoHeight = (max = this.matchHeight) => {
//   const min = 40
//   const n1 = min + ~~(Math.random() * (max - min))
//   const n2 = n1 * (1 - Math.random() * 0.4)
//   return [convertToUnit(n1), convertToUnit(n2)]
// }

// const genBars = () => {
//   return Array(20)
//     .fill(false)
//     .map(_ => {
//       return genRandomTwoHeight()
//     })
// }

export default {
  name: 'FpBarChartSkeleton',

  data() {
    return {
      yaxis: Array(10)
        .fill(false)
        .map(_ => Math.random()),

      xaxis: Array(20)
        .fill(false)
        .map(_ => Math.random()),
      fakeData: this.genBars(),
      timer: null,
    }
  },
  computed: {},
  mounted() {},

  created() {
    this.loop()
  },

  beforeDestroy() {
    clearTimeout(this.timer)
  },

  methods: {
    matchHeight() {
      return this.$refs['bar-chart-skeleton']
        ? this.$refs['bar-chart-skeleton'].clientHeight * 0.8
        : 100
    },
    loop() {
      this.fakeData = this.genBars()

      this.timer = setTimeout(() => {
        this.loop()
      }, 600)
    },
    genRandomTwoHeight(max = this.matchHeight()) {
      const min = max * 0.1
      const n1 = min + ~~(Math.random() * (max - min))
      const n2 = n1 * (1 - Math.random() * 0.4)
      return [convertToUnit(n1), convertToUnit(n2)]
    },
    genBars() {
      return Array(20)
        .fill(false)
        .map(_ => {
          return this.genRandomTwoHeight()
        })
    },
  },
}
</script>

<style lang="scss" module>
@import '@scss';

$grid-padding-right: 24px;

.container {
  position: relative;
  flex-grow: 1;
  padding-right: $grid-padding-right;
  padding-bottom: 35px;
  padding-left: 45px;
}

.list {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  height: 100%;
  padding-left: 0;
  margin-bottom: 0;
  font-size: 0;
  list-style: none;
  border-spacing: 3px;
}

.weak,
.solid {
  display: table-cell;
  width: 12px;
  transition: height 0.6s;
}

.solid {
  margin-right: 2px;
  background-color: $color-primary-glass;
}

.weak {
  background-color: $color-accent-glass;
}

.xaxis,
.yaxis {
  position: absolute;
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.yaxis {
  top: 0;
  bottom: 35px;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 45px;
}

.xaxis {
  right: 0;
  bottom: 17px;
  left: 45px;
  align-items: flex-start;
  justify-content: space-around;
  padding-right: $grid-padding-right;
}
</style>
