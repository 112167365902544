// maintain same version of actual vee-validate implementation of min_value
// https://github.com/baianat/vee-validate/blob/0b8db79294ffab67d1b86f3a62cd8f7732e702a6/src/rules/min_value.js

const minValueCustom = (value, [min]) => {
  if (value === null || value === undefined || value === '') {
    return false
  }

  if (Array.isArray(value)) {
    return value.length > 0 && value.every(val => minValueCustom(val, [min]))
  }

  return Number(value) >= min
}

const maxValueCustom = (value, [max]) => {
  if (value === null || value === undefined || value === '') {
    return false
  }

  if (Array.isArray(value)) {
    return value.length > 0 && value.every(val => maxValueCustom(val, [max]))
  }
  return Number(value) <= max
}

export { minValueCustom, maxValueCustom }
