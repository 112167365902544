// These functions actually do nothing abt incoming string template
// just for vscode recognizing strings as html content with autoformat capbility
import { oneLine } from './tagged'

function html(...args: any) {
  return oneLine(...args)
}
function markdown(strings: string[]) {
  return strings.join('')
}
export { html, markdown }
