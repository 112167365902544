import gql from 'graphql-tag'
import { output } from '../../shared/fragments/mutationOutput'
import { notification } from '../fragments'

export const addNotification = gql`
  mutation addNotification($input: addNotificationInput!) {
    addNotification(input: $input) {
      ...notification
    }
  }
  ${notification}
`

export const deleteNotifications = gql`
  mutation deleteNotifications($id: [ID!]!) {
    deleteNotifications(id: $id) {
      ...output
    }
  }
  ${output}
`

export const dismissNotifications = gql`
  mutation dismissNotifications($id: [ID!]!) {
    dismissNotifications(id: $id) {
      ...output
    }
  }
  ${output}
`
