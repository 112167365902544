import { reactive, onMounted, onBeforeUnmount } from '@vue/composition-api'

// @ts-ignore-next-line
import ScrollIndicator from './index.vue'

function scroll2Top() {
  try {
    document.documentElement.scrollBy({
      top: -document.documentElement.scrollTop,
      behavior: 'smooth',
    })
  } catch (_) {
    // if browser not support smooth scrollBy
    // directly set scrollTop (for IE)
    document.documentElement.scrollTop = 0
  }
}

export default function useScrollIndicator(targetEleRef) {
  const indicatorVisible = reactive({
    up: false,
    down: false,
  })

  onMounted(() => {
    window.addEventListener('scroll', checkScroll)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('scroll', checkScroll)
  })

  const UpIndicator = () =>
    indicatorVisible.up ? <ScrollIndicator direction="up" /> : null

  const DownIndicator = () =>
    indicatorVisible.down ? <ScrollIndicator direction="down" /> : null

  function checkScroll() {
    if (!targetEleRef.value?.$el) {
      Object.assign(indicatorVisible, { up: false, down: false })
      return
    }

    const react = targetEleRef.value?.$el.getBoundingClientRect()

    const up = react.top < 0

    const currFormBottom = react.bottom

    const down = currFormBottom - window.innerHeight + 90 > 0

    Object.assign(indicatorVisible, { up, down })
  }

  return {
    scroll2Top,
    indicatorVisible,
    UpIndicator,
    DownIndicator,
    checkScroll,
  }
}
