import * as queries from '@graphql/client/queries'
import {
  createClient,
  updateClient,
  archiveClients,
  unarchiveClients,
} from '@graphql/client/mutations'
import cloneDeep from 'lodash/cloneDeep'
import { inferCountry } from '@shared/phone/helpers'

export const state = {
  clients: {},
  currentClient: null,
  clientList: {},
  clientListAll: {},
}

export const getters = {
  total({ clients }) {
    return clients?.total
  },
  itemsPerPage({ clients }) {
    return clients?.limit
  },
  clients({ clients }) {
    return clients?.items
  },
  currentClient({ currentClient }) {
    return currentClient
  },
  clientList({ clientList }) {
    return clientList?.items
  },
  clientListAll({ clientListAll }) {
    return clientListAll?.items
  },
}

export const mutations = {
  SET_CLIENTS(state, data) {
    state.clients = data
  },

  SET_CURRENT_CLIENT(state, data) {
    state.currentClient = data
  },

  SET_CLIENTS_LIST(state, data) {
    const firstItem = [{ title: 'All Clients', id: null }]
    let filterList = cloneDeep(data)
    filterList.items = firstItem.concat(filterList.items)

    state.clientList = data
    state.clientListAll = filterList
  },
}

export const actions = {
  // ---------------------- //
  // GRAPHQL ACTIONS
  // -----------------------//
  async getClients({ dispatch, commit }, variables = { limit: 20 }) {
    const [err, res] = await this.dispatch('tryQuery', {
      query: queries.getClients,
      variables,
    })

    if (res) commit('SET_CLIENTS', res)
    return [err, res]
  },

  async getClientList({ commit }, variables = { limit: 5000 }) {
    // get part of the full client list
    const getClientListPart = (skip, variables) => {
      return this.dispatch('tryQuery', {
        query: queries.getClientList,
        variables: {
          skip: skip,
          ...variables,
        },
      })
    }

    let fullRes = null
    let skip = 0
    let error = null

    // initial retrival of the client list
    const [initialErr, initialRes] = await getClientListPart(skip, variables)

    // set the full response
    fullRes = initialRes

    if (!initialErr) {
      // if no errors, add to the skipped items counter
      skip += fullRes.items.length

      // loop the query, if there's no error and if it hasn't reached the total
      while (error === null && skip !== fullRes.total) {
        const [err, res] = await getClientListPart(skip, variables)

        if (!err && res) {
          // on success add the item to the skip counter and concat the full response items array
          skip += res.items.length
          fullRes.items = fullRes.items.concat(res.items)
        } else {
          // else return the error
          error = err
        }
      }
    } else {
      error = initialErr
    }

    // if the full response var has items then set the client list
    // we need to check if fullRes is not falsey or else it'll throw "Cannot use 'in' operator to search for 'items' in undefined"
    if (fullRes && 'items' in fullRes && fullRes.items.length > 0) {
      commit('SET_CLIENTS_LIST', fullRes)
    }

    return [error ?? '', fullRes]
  },

  async getClientById({ commit, dispatch }, variables) {
    const [, res] = await this.dispatch('tryQuery', {
      query: queries.getClients,
      variables,
    })

    if (res.items) {
      // REAL data returns array.length === 1 &&
      // MOCKED returns different ID in static list
      // return first item in the array
      let client = res.items[0]
      client.contact.phoneCountry = inferCountry(client?.contact?.phone) ?? ''
      commit('SET_CURRENT_CLIENT', client)
      return client
    } else {
      return null
    }
  },

  // ---------------------- //
  // GRAPHQL MUTATIONS
  // -----------------------//

  async createClient({ dispatch, commit }, input) {
    return this.dispatch('tryMutate', {
      mutation: createClient,
      variables: input,
    })
  },

  async updateClient({ dispatch, commit }, input) {
    const [err, data] = await this.dispatch('tryMutate', {
      mutation: updateClient,
      variables: input,
    })

    return [err, data]
  },

  async archiveClients({ dispatch, commit }, ids) {
    return this.dispatch('tryMutate', {
      mutation: archiveClients,
      variables: { ids },
    })
  },

  async unarchiveClients({ dispatch, commit }, ids) {
    return this.dispatch('tryMutate', {
      mutation: unarchiveClients,
      variables: { ids },
    })
  },
}
