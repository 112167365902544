// @ts-nocheck

import { defaultAddressFormat } from './constants'
import { isJSONObject, isObject } from '@utils'
import { oneLine } from '@shared/string/tagged'

export function formatAddress(input, format = defaultAddressFormat) {
  const DEFAULT_VALUE = ''

  if (!input) return DEFAULT_VALUE

  if (!isJSONObject(input) && !isObject(input)) {
    // check if JSON empty string
    try {
      if (typeof input === 'string' && !JSON.parse(input)) {
        return DEFAULT_VALUE
      }
    } catch (e) {
      return input
    }
    return input
  }

  if (isObject(input)) {
    return convertObjAddr2OneLine(input)
  }

  try {
    let address = input

    if (typeof input === 'string') {
      address = JSON.parse(input)
    }

    const output = convertObjAddr2OneLine(address)

    return output
  } catch (error) {
    return DEFAULT_VALUE
  }

  /**
   * Convert google address object to one line address
   * @param {{route:string,country:string,placeId:string,latitude:number,locality:string,longitude:number,postal_code:string,street_number:string}} input
   */
  function convertObjAddr2OneLine(input) {
    return oneLine`${format(input)?.trim()}`
  }
}
