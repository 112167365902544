<script>
export default {
  page: {
    title: 'Page timeout',
    meta: [
      { name: 'description', content: 'The page timed out while loading.' },
    ],
  },
  methods: {
    reloadPage() {
      window.location.reload()
    },
  },
}
</script>

<template>
  <main :class="$style.container">
    <h3 :class="$style.title">
      Page session has expired, please refresh your browser
    </h3>

    <fp-button size="large" width="300" icon="redo" @click="reloadPage()">
      Refresh
    </fp-button>
  </main>
</template>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.title {
  text-align: center;
  transform: translateY(-40px);
}
</style>
