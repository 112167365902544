import store from '@state/store'
import { capitalize, firstUpper } from '@src/shared/utils'
import { GameAdApprovalStatusEnum, GameStatusEnum } from '@graphql-types'

export const getGameTitleById = gameId => {
  let out

  try {
    const list = store.getters['games/gameListAll'] || []

    out = list.find(o => {
      return o.id === gameId
    })?.title
  } catch (err) {}

  return out
}

export function formatStatus(game) {
  if (game?.archived === true) return GameStatusEnum.ARCHIVED
  return game?.status || GameStatusEnum.UNKNOWN
}

export function formatGameAdStatus(status: GameAdApprovalStatusEnum) {
  if (status === GameAdApprovalStatusEnum.NOTREQUESTED) return 'Not Requested'
  return capitalize(status)
}

export function getStatusText(status: string) {
  return (
    {
      [GameStatusEnum.PENDING]: 'Pending review',
    }[status] || firstUpper(status?.toLowerCase())
  )
}

export function getStatusChipType(status: GameStatusEnum) {
  const {
    DRAFT,
    ARCHIVED,
    PENDING,
    REJECTED,
    APPROVED,
    ACTIVE,
    PAUSED,
    UNKNOWN,
  } = GameStatusEnum

  return (
    {
      [DRAFT]: 'info',
      [PENDING]: 'warning',
      [REJECTED]: 'error',
      [ACTIVE]: 'success',
      [APPROVED]: 'primary',
      [PAUSED]: 'accent',
      [UNKNOWN]: 'info',
      [ARCHIVED]: 'disabled',
    }[status] || 'info'
  )
}

export function formatRatingToImage(rating) {
  return store.state.games.ratingItems.find(item => item.value === rating)
    ?.avatar
}

export function formatRelease(release) {
  return store.state.games.releaseItems.find(item => item.value === release)
    ?.text
}
