import router from '@router'
import { getGraphqlProvider } from './graphql-provider'

import useAuthPlugin from './auth'
import useBEM from './bem'
import useCompositionAPI from './composition-api'
import useConfetti from './confetti'
import useFormValidatorPlugin from './form-validator'
import useGlobalFilters from './global-filters'
import useMediaPreview from './media-preview'
import useMessageModal from './modal'
import usePortalVue from './portal'
import useToasterPlugin from './toaster'
import useVueTheMaskPlugin from './vue-the-mask'
import useVuetify from './vuetify'
import useDatadogRum from './rum'

export const usePlugins = () => {
  const graphQLProvider = getGraphqlProvider()

  useAuthPlugin()
  useAuthPlugin()
  useBEM()
  useCompositionAPI()
  useConfetti()
  useFormValidatorPlugin()
  useGlobalFilters()
  useMediaPreview()
  useMessageModal()
  usePortalVue()
  useToasterPlugin({ router })
  useVueTheMaskPlugin()
  useVuetify()
  if (
    process.env.VUE_APP_CI_CONTEXT === 'dev' ||
    process.env.VUE_APP_CI_CONTEXT === 'test'
  ) {
    useDatadogRum()
  }

  // return api for each plugin that has relevant return expressions
  return {
    graphQLProvider,
  }
}
