import store from '@src/state/store'
import { createComponent, computed } from '@vue/composition-api'
import { Organisation, OrgVerifyStatusEnum } from '@graphql-types'

import style from './chips.module.scss'
import router from '@src/router'

export default createComponent({
  name: 'FpOrgChip',

  props: {
    orgId: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const dataRef = computed<Organisation>(() => {
      return store.state.orgToVerify.map[props.orgId]
    })

    return () => {
      const isLoading = !dataRef.value

      if (isLoading) {
        return (
          <div class={[style.container, 'text-center']}>
            <v-progress-circular
              size="14"
              width="2"
              indeterminate
              color={style.globalColorInfo}
            />
          </div>
        )
      }

      const {
        UNDER_REVIEW,
        UNKNOWN,
        VERIFIED,
        REJECTED,
        UNVERIFIED,
      } = OrgVerifyStatusEnum

      const data = dataRef.value

      const status = data?.status! || UNKNOWN

      const isStudent = data?.isStudent

      let icon =
        {
          [UNVERIFIED]: 'user',
          [REJECTED]: 'times',
          [VERIFIED]: 'check',
          [UNDER_REVIEW]: 'hourglass-half',
        }[status] || 'question'

      const color =
        {
          [UNVERIFIED]: style.globalColorDisabled,
          [REJECTED]: style.globalColorError,
          [VERIFIED]: style.globalColorSuccess,
          [UNDER_REVIEW]: style.globalColorWarning,
        }[status] || style.globalColorInfo

      return (
        <v-tooltip
          top
          scopedSlots={{
            activator: ({ on }) => (
              <div
                class={[style.container, 'text-truncate']}
                onClick={clickHandler}
                on={on}
              >
                <fp-icon name={icon} style={{ color }} class="mr-2" />
                {isStudent && <fp-icon name="graduation-cap" class="mr-1" />}
                {/* {isStudent && <strong class={style.studentTag}>Student</strong>} */}
                <span>{data.title}</span>
              </div>
            ),
          }}
        >
          {data.title}
        </v-tooltip>
      )
    }

    function clickHandler() {
      router.push(`/admin/account/${props.orgId}`)
    }
  },
})
