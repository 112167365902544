import gql from 'graphql-tag'
import { output } from '../../shared/fragments/mutationOutput'

export const removeAPIKey = gql`
  mutation removeAPIKey($userID: ID!, $apiKey: String!) {
    removeAPIKey(userID: $userID, apiKey: $apiKey) {
      ...output
    }
  }
  ${output}
`

export const createAPIKey = gql`
  mutation createAPIKey($userID: ID!, $apiKeyType: APIKeyTypeEnum!) {
    createAPIKey(userID: $userID, apiKeyType: $apiKeyType) {
      ...output
    }
  }
  ${output}
`
