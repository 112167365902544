<script>
import FpTableCell from './table-cell.tsx'
import { convertToUnit, uuid } from '@utils'
import { handleSort, defaultSortData } from './api'

export default {
  name: 'FpSimpleTable',

  components: { FpTableCell },

  props: {
    light: Boolean,

    data: {
      type: Array,
      default: () => [],
    },

    /**
     * @type {Array.<{text: string, value: string | number,  grow: boolean, sortable: boolean, width: number | string}>}
     */
    columns: {
      type: Array,
      default: () => [],
    },

    perPage: {
      type: Number,
      default: 5,
    },
    customSort: {
      type: Function,
      default: () => {},
    },
    loading: Boolean,
  },

  data() {
    return {
      currentSortData: { ...defaultSortData },
      uuid,
      sortIcons: {
        desc: 'caret-down',
        asc: 'caret-up',
      },
      dataSorted: [],
    }
  },

  computed: {
    rowSkeletonsSet() {
      return Array(this.perPage)
        .fill(false)
        .map(_ => Math.random().toString(16))
    },

    columnsComputed() {
      const isAnyColGrow = this.columns.some(col => col.grow)

      if (isAnyColGrow) {
        return this.columns
      }

      return this.columns.map(col => {
        return { ...col, grow: true, leader: true }
      })
    },

    isEmptyState() {
      const { loading, data } = this
      return !loading && (!data || data.length < 1)
    },
  },

  watch: {
    // Data watcher only observes when the data is first loaded
    data: {
      handler: function (val) {
        this.dataSorted = [...val]
        this.currentSortData = { ...defaultSortData }

        // Sort descending by default on load
        this.columnsComputed.filter(col => {
          if (col.value === 'total') {
            this.onSortClick(col)
          }
        })
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    convertToUnit,

    onSortClick(col) {
      this.dataSorted = handleSort(
        col,
        this.currentSortData,
        this.data,
        this.customSort
      )
    },
    onCellMouseEnter(item) {
      this.$emit('row-enter', item)
    },
    onCellMouseLeave(item) {
      this.$emit('row-leave', item)
    },

    getSortIcon(col) {
      const { currentSortData, sortIcons } = this

      const name =
        currentSortData.column === col.value
          ? sortIcons[currentSortData.order] || 'sort'
          : 'sort'

      return { name }
    },
  },
}
</script>

<template>
  <div>
    <div v-bem="{ light, loading }">
      <div
        v-for="(col, colIndex) in columnsComputed"
        :key="col.value"
        v-bem:column="{ grow: col.grow }"
        :style="{ width: convertToUnit(col.width) }"
      >
        <div v-bem:thead />
        <h5
          v-bem:heading="{
            sortable: col.sortable,
            sorting: currentSortData.column === col.value,
          }"
          @click="onSortClick(col)"
        >
          {{ col.text }}
          <fp-icon v-if="col.sortable" :name="getSortIcon(col).name" />
        </h5>

        <section v-if="loading" key="skelton">
          <div
            v-for="nonstr in rowSkeletonsSet"
            :key="nonstr"
            v-bem:skeleton
            class="layout align-center pl-2"
          >
            <fp-skeleton v-if="colIndex === 1" top-margin="5" height="24" />
          </div>
        </section>

        <!-- if key relies on an object, such as the item's value, cell won't
              re-render if the item's value change, e.g. dashboard top table toggles -->
        <fp-table-cell
          v-for="(item, index) in dataSorted"
          v-else
          :key="uuid() + index"
          :class="$bem('::cell')"
          :without-td-wrapped="true"
          :column-config="col"
          :item="item"
          :slots="$scopedSlots"
          @mouseenter="onCellMouseEnter(item)"
          @mouseleave="onCellMouseLeave(item)"
        />
      </div>
    </div>
    <div v-if="isEmptyState" v-bem:message="{ light }" :class="`text-center`">
      <div v-for="(_, index) in [0, 0, 0, 0, 0]" :key="index" v-bem:emptyRowBox>
        <div v-if="!!$slots.emptyRow" v-bem:emptyRow>
          <slot name="emptyRow" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="@component-styles/table"></style>
