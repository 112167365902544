<template>
  <section slot="skeleton" style="height: 80.5px">
    <div>
      <fp-skeleton height="15" width="135" bottom-margin="15" no-animate />
    </div>
    <div class="layout align-center">
      <fp-skeleton
        height="40"
        width="85"
        bottom-margin="3"
        top-margin="3"
        no-animate
        round
      />
      <fp-skeleton height="20" width="45" left-margin="25" no-animate />
    </div>
  </section>
</template>

<script>
export default {
  name: 'FpSwitchSkeleton',
}
</script>
