var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{class:("v-page-" + (_vm.$route.name)),attrs:{"id":"app-wrapper"}},[(
      _vm.hydrated === _vm.HYDRATED_STATUS.TRUE ||
      _vm.hydrated === _vm.HYDRATED_STATUS.UNKNOWN
    )?_c('div',{staticStyle:{"height":"100%"},attrs:{"id":"app-router-view"}},[_c('router-view',{attrs:{"type":"layout"}}),(_vm.ssoLoading)?_c('div',{staticClass:"d-flex justify-center align-center w-100",staticStyle:{"height":"100vh"}},[_c('v-progress-circular',{attrs:{"size":"60","width":"5","indeterminate":"","color":"#0047ff"}})],1):_vm._e(),(
        _vm.guardOnDuty &&
        _vm.guardOnDuty.id &&
        _vm.guardOnDuty.body &&
        !_vm.guardOnDuty.body.onGuardInView
      )?_c('guard-dialog',{attrs:{"guard":_vm.guardOnDuty,"show-dialog":_vm.showGuardDialog},on:{"on-cancel":_vm.handleDialogCancel,"on-confirm":function (approved, form) { return _vm.handleDialogConfirm(approved, form); }}}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }