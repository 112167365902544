import gql from 'graphql-tag'

export const payment = gql`
  fragment payment on Payment {
    id
    organisation
    type
    description
    date
    amount
    magnite {
      seatID
      dealID
      adPlatform
    }
    ad
    game
    client
  }
`
