<template>
  <div v-bem>
    <fp-input
      ref="input"
      :value="formattedAddress"
      v-bind="propsPenetrating"
      icon="building"
      v-on="listenersPenetrating"
      @change="onChange"
      @focus="onFocus"
    />
  </div>
</template>

<script>
// Code from https://github.com/MadimetjaShika/vuetify-google-autocomplete/blob/dev/src/vga/VuetifyGoogleAutocomplete.js
import insertGoogleScript from './insertGoogleScript'
import { formatAddress } from '@shared/location/helpers'
import { isJSONObject, isObject, omit } from '@utils'

const apiKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY

export default {
  skipGloballyRegister: true,

  name: 'FpAddressInput',

  inheritAttrs: false,

  props: {
    /** This ID will pass down directly to the real <input /> element */
    id: {
      type: String,
      default: () => Math.random().toString(16).slice(2),
    },

    label: {
      type: String,
      default: null,
    },

    /**
     * Types supported in place autocomplete requests.
     * https://developers.google.com/places/supported_types#table3
     * https://developers.google.com/places/web-service/autocomplete
     */
    type: {
      type: String,
      default: 'address',
    },
    error: Boolean,
    message: {
      type: String,
      default: '',
    },

    // JSON-string
    // e.g. "{"country":"Sweden","placeId":"ChIJcfSG19QrV0YRsI_kQfP-AAQ","latitude":56,"locality":"Alvesta","longitude":14,"administrative_area_level_1":"Kronoberg County"}"
    value: {
      type: String,
      default: undefined,
      required: false,
    },

    /** Google map script options */
    apiKey: {
      type: String,
      default: apiKey,
    },
    version: {
      type: String,
      default: '',
    },
    language: {
      type: [String, Array],
      default: null,
    },
    /**
     * Select Address Types and Address Component Types
     * https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes
     */
    addressTypeKeysMap: {
      type: Object,
      default: () => ({
        unit_number: 'long_name',
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_2: 'short_name',
        administrative_area_level_1: 'short_name',
        country: 'long_name',
        postal_code: 'short_name',
      }),
    },
    /**
     * Restrict the autocomplete search to a particular country or set of countries.
     * https://developers.google.com/places/web-service/autocomplete
     */
    country: {
      type: [String, Array],
      default: null,
    },

    /**
     * Bias the search towards user's current location.
     * https://developers.google.com/places/web-service/autocomplete
     */
    autoLocate: { type: Boolean, default: false },
  },

  data: () => ({
    /**
     * The Google map places autocomplete instance
     * https://developers.google.com/maps/documentation/javascript/reference#Autocomplete
     */
    placesInstance: null,

    /**
     * Indicates if the Geolocate has already been set.
     */
    geoHasBeenSet: false,

    checkTimer: null,
  }),

  computed: {
    formattedAddress() {
      const x = formatAddress(this.value)
      return x
    },

    propsPenetrating() {
      const { id, type, label, message, error } = this
      return {
        ...this.$attrs,
        id,
        type,
        label,
        message,
        error,
      }
    },

    listenersPenetrating() {
      const { $listeners } = this
      return omit($listeners, ['input'])
    },
  },

  watch: {
    /**
     * Update the SDK country option whenever it changes from the parent.
     */
    country(newVal) {
      if (newVal) {
        this.placesInstance.componentRestrictions.country = newVal
      }
    },
  },

  mounted() {
    insertGoogleScript(this.apiKey, this.version, this.language)
      .then(() => {
        if (window.google) {
          const inputElement = document.getElementById(this.id)
          this.setup(inputElement)
        }
      })
      .catch(() => {
        console.warn('[FpAddressInput]: Google map script load error!')
      })
  },

  methods: {
    changeValue(result) {
      // ⚠️ We only accept AWSJSON for address value
      if (isObject(result)) {
        return this.$emit('input', JSON.stringify(result))
      }

      if (isJSONObject(result)) {
        this.$emit('input', result)
      }
    },

    onFocus(e) {
      this.locate()
    },

    onChange(input) {
      const vTextFieldComp = this.$refs.input?.$refs?.input
      const { lazyValue, value } = vTextFieldComp

      // do null check immediately, if submit was faster than timer, it would not change value
      if (!lazyValue) {
        this.$emit('input', JSON.stringify(lazyValue))
      }

      this.checkTimer = setTimeout(() => {
        if (lazyValue !== value) {
          // this.$toast.warning('Please select an Address !')

          if (vTextFieldComp) {
            vTextFieldComp.lazyValue = this.formattedAddress
          }
        }

        // Give 500 ms to Place service for fetching place data
        // if fetch dome less than 500ms (which normally should be that)
        // clear the timeout at place_changed handler
        // otherwise we deem user does not select a prediction, just type sth and blur
      }, 500)
    },

    /**
     * Bias the autocomplete object to the user's geographical location,
     * as supplied by the browser's 'navigator.geolocation' object.
     */
    locate() {
      if (this.autoLocate) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(({ coords }) => {
            const geolocation = {
              lat: coords.latitude,
              lng: coords.longitude,
            }

            const circle = new window.google.maps.Circle({
              center: geolocation,
              radius: coords.accuracy,
            })

            this.placesInstance.setBounds(circle.getBounds())

            this.geoHasBeenSet = true
          })
        }
      }
    },

    /**
     * @param {HTMLInputElement} inputElement
     */
    setup(inputElement) {
      const options = {}

      if (this.types) {
        options.types = [this.type]
      }

      if (this.country) {
        options.componentRestrictions = {
          country: this.country,
        }
      }

      // https://developers.google.com/maps/documentation/javascript/reference/places-widget#AutocompleteOptions
      this.placesInstance = new window.google.maps.places.Autocomplete(
        inputElement,
        options
      )

      this.placesInstance.addListener('place_changed', this.placeChangeHandler)
    },

    placeChangeHandler() {
      clearTimeout(this.checkTimer)

      const place = this.placesInstance.getPlace()

      this.dealWithRawPlace(place)
    },

    dealWithRawPlace(place) {
      const {
        geometry,
        formatted_address: formattedAddress,
        address_components: addressComponents,
        place_id: placeId,
      } = place

      if (!geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        this.$emit('no-results-found', place)
        return
      }

      const result = {}

      if (addressComponents && formattedAddress) {
        // Get each component of the address from the place details
        addressComponents.forEach(item => {
          const addressType = item.types[0]

          if (this.addressTypeKeysMap[addressType]) {
            const val = item[this.addressTypeKeysMap[addressType]]
            result[addressType] = val
          }
        })

        result.latitude = geometry.location.lat()
        result.longitude = geometry.location.lng()

        // additional fields available in google places results
        result.placeId = placeId

        this.$emit('place-change', {
          result,
          place,
          address: addressComponents,
        })

        this.changeValue(result)
      }
    },
  },
}
</script>

<style lang="scss" src="@component-styles/address-input"></style>
