<template>
  <transition name="fade-vertical">
    <bottom-nav v-if="visible">
      <div
        :class="$style.inner"
        :style="{ maxWidth: finalWidth, minWidth: finalWidth }"
      >
        <slot />
      </div>
    </bottom-nav>
  </transition>
</template>

<script>
import useSize from '@components/_base/mixins/useSize'
import BottomNav from './index'

export default {
  skipGloballyRegiter: true,

  name: 'FormBottomActionsBar',

  components: {
    BottomNav,
  },

  mixins: [useSize],

  props: {
    visible: Boolean,
  },
}
</script>

<style lang="scss" module>
@import '@scss';

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}
</style>
