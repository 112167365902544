import * as sharedApi from '../api'
import { EChartOption, EChartsOptionConfig, ECharts } from 'echarts/lib/echarts'
import * as echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/toolbox'

import { getTooltip, getTooltipContainerCss } from '../components/tooltip'

import {
  globalColorPrimary,
  globalColorAccent,
  globalColorLightGrey,
  globalColorSuccess,
  // @ts-ignore
} from '@scss-ts'

import { formatCurrency } from '@shared/finance/utils'
import { formatNumber } from '@shared/math/helpers'

import { hexToRgba } from '@styles/utils'

const MONEY_DATA_INDEX = 1

const init = (dom: HTMLElement) => sharedApi.init(echarts, dom)

const setOption = (
  chart: ECharts,
  option: EChartOption,
  opts: EChartsOptionConfig
) => {
  const spacer = {
    top: 20,
    right: 30,
  }

  const defaultOption: EChartOption = {
    axisPointer: {
      shadowStyle: {
        color: hexToRgba(globalColorLightGrey, '0.3'),
      },
    },
    toolbox: {
      padding: [13, spacer.right],
      left: 5,
      feature: {
        magicType: {
          show: true,
          title: {
            line: ' ',
            bar: ' ',
          },
          type: ['bar', 'line'],
        },
        // saveAsImage: { title: 'Save as picture', show: true },
      },
    },
    grid: {
      containLabel: true,
      left: spacer.right + 6,
      right: spacer.right,
      top: spacer.top * 2.8,
      bottom: 28,
    },
    legend: {
      icon: 'circle',
      itemGap: 10,
      itemWidth: 6,
      align: 'left',
      // @ts-ignore
      x: 'right',
      padding: [spacer.top, spacer.right, 0, 0],
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          formatter: (params: any) => {
            let rt = params.value

            if (typeof params.value === 'number') {
              rt = Math.ceil(params.value)
              if (
                params.axisDimension === 'y' &&
                params.axisIndex === MONEY_DATA_INDEX
              ) {
                return formatCurrency(rt, 2)
              }
              return formatNumber(rt)
            }

            return rt
          },
        },
      },
      formatter: (params: any) => {
        const first = params[0] || {}
        const second = params[1] || {}
        const third = params[2] || {}

        let opts = {
          [first.seriesName]: {
            value: first.value,
            keyCss: {
              color: globalColorPrimary,
            },
          },
          [second.seriesName]: {
            value: second.value,
            keyCss: {
              color: globalColorAccent,
            },
          },
        }

        if (third.seriesName) {
          opts = {
            ...opts,
            [third.seriesName]: {
              value: third.value,
              keyCss: {
                color: globalColorSuccess,
              },
            },
          }
        }

        return getTooltip(opts)
      },
      extraCssText: getTooltipContainerCss(`
        padding: 14px 16px 14px 16px;
        border-bottom-right-radius:0;
        `),
    },
    xAxis: [
      {
        type: 'category',
        data: [],
        axisPointer: {
          type: 'shadow',
        },
        splitLine: {
          show: false,
          interval: 0,
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        min: 0,

        splitLine: {
          show: true,
        },
      },
      {
        type: 'value',
        min: 0,

        splitLine: {
          show: false,
        },
      },
    ],
    series: [
      {
        type: 'bar',
        barGap: '0',
        data: [],
      },
      {
        type: 'bar',
        barGap: '0',
        data: [],
      },
      {
        type: 'bar',
        barGap: '0',
        data: [],
      },
    ],
  }

  const emptyStateOption: EChartOption = {
    legend: {
      show: true,
    },
  }

  sharedApi.setOption(chart, option, defaultOption, emptyStateOption, opts)
}

const isEmpty = sharedApi.isEmpty

export { init, setOption, isEmpty }
