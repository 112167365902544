import { actions, getters } from './auth'
import store from '../store'

type Actions = keyof typeof actions
type Computations = keyof typeof getters

export function AuthDispatch(action: Actions, payload: any) {
  return store.dispatch(`auth/${action}`, payload)
}

export function AuthGetters(key: Computations) {
  return store.getters[`auth/${key}`]
}
