import style from './style.module.scss'
import { createComponent, reactive, computed } from '@vue/composition-api'
import FpMenuCard from '@components/menus/menu-card'
import { useTransaction } from '@components/transaction/useTransaction'
import FpFeedCard from '@components/feed-card'
// @ts-ignore
import { OrgGetters, OrgDispatch } from '@state/modules/org.store.ts'
import { UserGetters } from '@src/state/modules/user.store'
import { Payment } from 'typedefs'
import {
  formatTimeAgo,
  formatCurrency,
  formatCentsToDollars,
  formatAndAbbrCurrency,
} from '@utils'
import useBreakpoint from '@src/components/hooks/useBreakpoint'
import router from '@src/router'
import { RoleTypeEnum } from '@src/shared/constants'

export default createComponent({
  name: 'HeaderTransaction',
  components: { FpMenuCard },

  setup(_, ctx) {
    const transaction = useTransaction()

    OrgDispatch('getRecentPayments', { limit: 3 })

    const org = reactive({
      isGamePublisher: computed(() => OrgGetters('isGamePublisher')),
      balance: computed(() => OrgGetters('balance')),
      // todo: type this in OrgGetters not here
      fetching: computed(() => OrgGetters('fetching') as boolean),
      // todo: type this in OrgGetters not here
      recentPayments: computed(() => OrgGetters('recentPayments') as Payment[]),
    })
    const currentUser = UserGetters('userInfo')
    const currentUserRole = currentUser.roles[0]?.roleID.split(':')[1]
    const isFinance =
      currentUserRole === RoleTypeEnum.OWNER ||
      currentUserRole === RoleTypeEnum.FINANCE

    const [breakPoint] = useBreakpoint(ctx)

    const state = reactive({
      fBalance: computed(() => {
        const balance = (org?.balance as any) ?? 0

        let rv = breakPoint.xsOnly
          ? formatAndAbbrCurrency(balance, 2)
          : formatCurrency(balance, 2)

        rv = rv.replace(/\$/g, '') // Remove $ symbol since we want stylized $ but keep minus sign if negative (e.g. -$12.00)

        return rv
      }),
    })

    const handleAddFunds = () => {
      ctx.emit('on-add-funds')
    }

    const handleFooterLeftClick = () => {
      ctx.root.$router.push({
        path: '/account?tab=transactions',
      })
    }

    return () => {
      const isSm = breakPoint.smAndDown

      const menu = (
        <FpMenuCard
          loading={org.fetching}
          title="Recent Transactions"
          items={org?.recentPayments && [...org.recentPayments]}
          footerRight={{
            label: 'View all',
            onClick: handleFooterLeftClick,
            closeMenuOnClick: true,
          }}
          // @ts-ignore
          scopedSlots={{
            activator: args => {
              return (
                <div>
                  <fp-skeletonize
                    right-margin="5"
                    left-margin="5"
                    loading={org.fetching}
                    width={isSm ? '30vw' : 100}
                    height={isSm ? 40 : 42}
                    round
                  >
                    <div class="d-flex">
                      <button
                        v-ripple
                        tabindex="2"
                        class={[
                          style.button,
                          style.left,
                          { [style.round]: org.isGamePublisher || !isFinance },
                        ]}
                        onClick={_ => handleActivate(args?.toggle)}
                      >
                        <fp-icon name="dollar-sign" />

                        <span class={style.balance} data-cy="account-balance">
                          {state.fBalance}
                        </span>

                        {!isSm && (
                          <fp-icon
                            name={args?.visible ? 'angle-up' : 'angle-down'}
                          />
                        )}
                      </button>

                      {!org.isGamePublisher && isFinance && (
                        <button
                          v-ripple
                          tabindex="2"
                          class={[style.button, style.right]}
                          onClick={handleAddFunds}
                          data-cy="add-funds-button"
                        >
                          <strong data-cy="add-funds-text">ADD FUNDS</strong>
                          <span class={style.iconCircle}>
                            <fp-icon name="plus" />
                          </span>
                        </button>
                      )}
                    </div>
                  </fp-skeletonize>
                </div>
              )
            },
            item: (item: Payment) => {
              const icon = transaction.getIcon(item?.type)

              return (
                <FpFeedCard
                  // key needed for reactivity
                  key={item.id}
                  title={item.description}
                  message={formatCurrency(formatCentsToDollars(item.amount), 2)}
                  icon={icon}
                  // @ts-ignore
                  date={new Date(+item.date * 1000)}
                  dateFunction={formatTimeAgo}
                />
              )
            },
            noItems: () => <div>No transactions to display yet 👌</div>,
          }}
        ></FpMenuCard>
      )

      return <div>{menu}</div>
    }

    function handleActivate(toggler) {
      if (breakPoint.smAndDown) {
        router.push({ path: '/account?tab=transactions' })
        return
      }

      toggler && toggler()
    }
  },
})
