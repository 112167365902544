import gql from 'graphql-tag'
import { contact } from '../../user/fragments/contact'
import { frequencyCap } from './FrequencyCap'
import { s3Object } from './S3Object'

export const ad = gql`
  fragment ad on Ad {
    # Ad Meta
    views
    status

    # Describe
    id

    purpose
    campaignType
    client {
      id
      contact {
        ...contact
      }
      businessNumber
      title #company
      dateUpdated
      archived
      dateCreated
    }
    # Audience
    locations
    DMAs
    shareOfVoice
    languages
    genders
    # Expect both to be set, or both null, maxAge >= minAge
    minAge
    maxAge
    magnite {
      dealID
      seatID
      adPlatform
    }
    platforms
    os
    gameIDs
    genres
    genresOptOut
    pacing
    ratings
    coppaCompliant
    timeOfDays {
      start
      end
      day
    }
    impressionURLs
    adRestrictions
    org
    # Budget and Time
    budgetTotal
    budgetDaily
    expensesTotal
    kochava
    externallyHosted
    bidType
    frequencyCaps {
      ...frequencyCap
    }
    tags {
      tag
      internal
    }
    displayStartDate
    displayEndDate
    timezone
    CPMCents

    # Upload Content
    # adContentType: AdContentTypeEnum!
    adCreatives {
      ...s3Object
    }
    # People see active
    # Approved, not paused, not archived, not expired, (within budget??)
    active
    # paused is user toggleable
    paused
    pending
    approved
    reviewed

    # PostMeta
    id
    title
    archived
    dateUpdated
    dateCreated
    nickname
    gam {
      lineItemID
      lineItemName
      lineItemURL
      orderItemID
      orderItemName
      orderItemURL
    }

    valueAddType

    # User details
    updatedByUserAt
    updatedByUser
  }
  ${contact}
  ${s3Object}
  ${frequencyCap}
`
