/* Important!
Always store in cents! Display in dollars.
33.12*100 = 3311.9999999999995 */

/* Key Takeaways
Always store dollars in whole cents and display in dollars
Use money javascript libraries to help with other currencies.
Avoid .toFixed(2); because of rounding issues */

/*
https://twitter.com/wesbos/status/1029021381126709248
https://stackoverflow.com/questions/2876536/precise-financial-calculation-in-javascript-what-are-the-gotchas
https://stackoverflow.com/questions/588004/is-floating-point-math-broken */

import { abbrNumber, substring } from '@utils'

export const formatDollarsToCents = function (iVal) {
  let value = iVal
  value = (value + '').replace(/[^\d.-]/g, '')
  if (value && value.includes('.')) {
    value = substring(value, 0, value.indexOf('.') + 3)
  }

  return value ? Math.round(parseFloat(value) * 100) : 0
}

export const formatCentsToDollars = function (iVal) {
  let value = iVal
  value = (value + '').replace(/[^\d.-]/g, '')
  value = parseFloat(value)
  return value ? value / 100 : 0
}

/** Parse any string like or number type money amount to standard Number  */
export const parseCurrency = function (iVal, defaultValue = 0): number {
  let value = iVal
  value = (value + '').replace(/[^\d.-]/g, '')
  value = parseFloat(value)
  const out = !value || isNaN(value) ? defaultValue : value
  return out
}

/**
 * Format to human-readable USD format
 * Add Dollar symbbol with separated commas style
 */
export const formatCurrency = function (val, decimals = 0) {
  const _val = val || 0

  const formatted = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimals,
  }).format(_val)

  return formatted
}

/**
 * Format and abbreviate currency xx.xx when it is over 7 chars long, counting
 * separators like commas. So, if xx,xx.xx over 7 chars, it will abbr it.
 *
 * e.g.
 * '925123.01231' -> $925.12K
 * 9399102 -> $9.3991M
 */
export const formatAndAbbrCurrency = (input: number | string, decimals = 0) => {
  const amount = parseCurrency(input)

  const nStr = formatCurrency(amount, decimals)

  if (Math.abs(amount) < 1000) return nStr

  if (nStr.length <= 7) return nStr

  const abbred = abbrNumber(amount)

  if (abbred.startsWith('-')) {
    const letters = abbred.split('')
    letters.splice(1, 0, '$')
    return letters.join('')
  }

  return '$' + abbred
}
