import { uniqueRandomArray, vueToNormalObject, replaceAll } from '@utils'
import * as CRUDTypes from '@components/crud/types'
import * as types from './types'

import gql from 'graphql-tag'
import { tryMutate, tryQuery } from '@graphql/query-helpers'
import { getOrgChildrenTitle } from '@src/graphql/org/queries'

import {
  globalColorPrimary,
  globalColorAccent,
  globalColorLightPrimary,
  globalColorError,
  globalColorWarning,
  globalColorSuccess,
  // @ts-ignore
} from '@scss-ts'

import { ALLOWED_CHARS_REGEX } from '@shared/regexes'

const colors = [
  globalColorAccent,
  globalColorPrimary,
  globalColorError,
  globalColorWarning,
  globalColorSuccess,
  globalColorLightPrimary,
]

const randomColor = uniqueRandomArray(colors)

const getOrgMembersQuery = gql`
  query getOrgMembers(
    $ids: [ID]
    $limit: Int
    $skip: Int
    $filter: OrgMembersFilterInput
  ) {
    getOrgMembers(ids: $ids, limit: $limit, skip: $skip, filter: $filter) {
      items {
        accountType
        allowAccessToAllEntities
        email
        firstName
        jobTitle
        lastName
        orgID
        phone
        timezone
        userID
        userRole {
          roleID
          roleName
          rolePermissions
        }
      }
      skip
      limit
      total
    }
  }
`

const updateOrgMemberMutation = gql`
  mutation updateOrgMember($input: UpdateOrgMemberInput!) {
    updateOrgMember(input: $input) {
      id
      success
      message
      statusCode
    }
  }
`

const createOrgMemberMutation = gql`
  mutation createOrgMember($input: CreateOrgMemberInput!) {
    createOrgMember(input: $input) {
      id
      success
      message
      statusCode
    }
  }
`

export const removeOrgMemberMutation = gql`
  mutation removeOrgMember($id: ID!) {
    removeOrgMember(id: $id) {
      id
      success
      message
      statusCode
    }
  }
`

export const replaceOrgMemberOwnershipMutation = gql`
  mutation replaceOrgMemberOwnership($input: ReplaceOrgMemberOwnershipInput!) {
    replaceOrgMemberOwnership(input: $input) {
      id
      success
      message
      statusCode
    }
  }
`

// constants
export const GET_ORG_MEMBERS_LIMIT = 20

export const ORG_ENTITY_NAME = {
  ADVAGC: 'advertisements',
  GP: 'games',
}

// keys

export const USER_ROLE_KEY = 'userRole'
export const ROLE_TYPE_KEY = 'roleID'
export const EMAIL_KEY = 'email'
export const FIRST_NAME_KEY = 'firstName'
export const LAST_NAME_KEY = 'lastName'
export const USER_ID_KEY = 'userID'
export const ACCOUNT_TYPE_KEY = 'accountType'
export const JOB_TITLE_KEY = 'jobTitle'
export const TIMEZONE_KEY = 'timezone'
export const ALLOW_ACCESS_KEY = 'allowAccessToAllEntities'
export const ORGTITLE_KEY = 'orgTitle'
// cols

export const COL_AVATAR = 'avatar'
export const COL_NAME = 'name'
export const COL_COMPANY = 'company'
export const COL_ROLE = USER_ROLE_KEY
export const COL_ACCESS = 'access'
export const COL_OPERATIONS = 'operations'

// funcs

export const validateInput = input => {
  return new RegExp(ALLOWED_CHARS_REGEX).test(input)
}
export const getOrgMemberSubs = async (x: any = {}) => {
  const [err, data] = await tryQuery({
    query: getOrgChildrenTitle,
    variables: {
      id: '',
    },
  })
  if (!data) return [err, { items: [] }]

  return [err, data]
}
export const getOrgMembers = async (x: any = {}) => {
  // remove the magic stuff vuex tacks onto the object, causing limit/skip
  // to be undefined even after local variable assignment to GET_ORG_MEMBERS_LIMIT
  const args = vueToNormalObject(x)

  const limit = args.limit ? args.limit : GET_ORG_MEMBERS_LIMIT

  const variables = {
    limit: limit,
    ...args,
  }

  const [err, data] = await tryQuery({
    query: getOrgMembersQuery,
    variables,
  })

  if (!data) return [err, []]

  return [err, data]
}

export const removeOrgMember = async id => {
  const [err, data] = await tryMutate({
    mutation: removeOrgMemberMutation,
    variables: { id },
  })

  if (!data) return [err, []]

  return [err, data]
}

export const updateOrgMember = async (input = {}) => {
  const [err, data] = await tryMutate({
    mutation: updateOrgMemberMutation,
    variables: { input },
  })

  if (!data) return [err, []]

  return [err, data]
}

export const createOrgMember = async (input = {}) => {
  const [err, data] = await tryMutate({
    mutation: createOrgMemberMutation,
    variables: { input },
  })

  if (!data) return [err, []]

  return [err, data]
}

export const replaceOrgMemberOwnership = async ({
  input = {},
  email,
  isSA = {},
}) => {
  const [err, data] = await tryMutate({
    mutation: replaceOrgMemberOwnershipMutation,
    variables: { input, email, isSA },
  })

  if (!data) return [err, []]

  return [err, data]
}

const buildFormItems = (
  formData,
  mode: CRUDTypes.Mode,
  dataMap,
  options: types.MapOptions
): [any[], any] => {
  const crudItems: any[] = []
  let formStateItem = {}

  Object.keys(dataMap).forEach(formKey => {
    const mapItem = dataMap[formKey] || null
    let value = (formData && formData[formKey]) ?? mapItem?.initialValue

    if (formKey === ROLE_TYPE_KEY) {
      value =
        (formData &&
          formData[USER_ROLE_KEY] &&
          formData[USER_ROLE_KEY][ROLE_TYPE_KEY] &&
          formData[USER_ROLE_KEY][ROLE_TYPE_KEY]) ??
        mapItem?.initialValue
    }

    const label = mapItem?.label ?? formKey ?? 'UNKNOWN'
    let display = (mapItem?.displayMap && mapItem?.displayMap[value]) ?? value

    if (formKey === TIMEZONE_KEY) {
      display = replaceAll(display, '_', ' ')
    }

    if (
      (mode === CRUDTypes.Mode.CREATE || mode === CRUDTypes.Mode.EDIT) &&
      formKey === ACCOUNT_TYPE_KEY
    ) {
      value = options.createAccountType
    }

    formStateItem = {
      ...formStateItem,
      [formKey]: {
        label: label,
        value: value,
      },
    }

    // build crud items

    const hiddenCrudItems = [
      USER_ID_KEY,
      FIRST_NAME_KEY,
      LAST_NAME_KEY,
      ROLE_TYPE_KEY,
      ACCOUNT_TYPE_KEY,
      ALLOW_ACCESS_KEY,
    ]

    const readOnlyCrudItems = [EMAIL_KEY]

    const crudItem = {
      key: formKey,
      label: label,
      displayValue: display,
      hidden: hiddenCrudItems.includes(formKey),
      readOnly: readOnlyCrudItems.includes(formKey),
    }

    crudItems.push(crudItem)
  })

  return [crudItems, formStateItem]
}

const mapToComponentData = (editData, dataMap, options: types.MapOptions) => {
  const editMemberItems: any[] = []
  const editFormStateItems: any[] = []

  // edit data
  editData &&
    editData.items &&
    editData.items.forEach((o, i) => {
      const [editCRUDItems, editFormStateItem] = buildFormItems(
        o,
        CRUDTypes.Mode.EDIT,
        dataMap,
        options
      )
      editFormStateItems.push(editFormStateItem)

      // build memberItem
      const extraProps = {
        color: randomColor(),
      }
      const memberItem = {
        ...o,
        ...extraProps,
        crudItems: editCRUDItems,
      }
      editMemberItems.push(memberItem)
    })

  // create data
  const [createCRUDItems, createFormStateItem] = buildFormItems(
    null,
    CRUDTypes.Mode.CREATE,
    dataMap,
    options
  )

  return {
    createCRUDItems,
    createFormStateItem,

    editMemberItems,
    editFormStateItems,
  }
}

export { mapToComponentData }
