import store from '@state/store'
import gql from 'graphql-tag'
import * as m from '../mutations'
import * as confirmItemUpdate from '@shared/ui/templates/confirmItemUpdate'
import { getOperationName, deepEqualByDoc } from '../../utils'
import {
  handleSensitiveInfoChange,
  handleVerificationStatus,
} from '../../shared/guards'
import { BUSINESS_TYPES, BUSINESS_ACTIONS, ORG_VERIFY_STATUS } from '@constants'
import { GameStatusEnum } from '@graphql-types'

// privates
const getUpdateCondition = async variables => {
  const docOfTruth = gql`
    fragment updateGameGuardedFields on Game {
      adRestrictions
      coppaCompliant
      rating
      title
    }
  `

  const currentInput = await store.getters[`games/currentGame`]
  const status = currentInput?.status ?? ''

  if (
    [
      GameStatusEnum.DRAFT,
      GameStatusEnum.PENDING,
      GameStatusEnum.REJECTED,
    ].includes(status)
  ) {
    return false
  }

  const out = deepEqualByDoc(variables.input, currentInput, docOfTruth)
  return out
}

// handlers
const handleUpdateGame = async operation => {
  const condition = await getUpdateCondition(operation?.variables)
  if (condition) {
    return {
      title: confirmItemUpdate.title({ type: BUSINESS_TYPES.GAME }),
      content: confirmItemUpdate.content({
        type: BUSINESS_TYPES.GAME,
        labels: ['Title', 'Game Rating', 'Content Restrictions', 'COPPA'],
      }),
    }
  }
}

const handlePauseGames = async operation => {
  const unverified = await handleVerificationStatus(
    ORG_VERIFY_STATUS.UNVERIFIED
  )
  if (unverified) return unverified

  return handleSensitiveInfoChange({
    operation,
    type: BUSINESS_TYPES.GAME,
    action: BUSINESS_ACTIONS.PAUSE,
  })
}

const unpauseGames = async operation => {
  const unverified = await handleVerificationStatus(
    ORG_VERIFY_STATUS.UNVERIFIED
  )
  if (unverified) return unverified
}

const handleRemoveGameAccess = async operation => {
  return {
    title: 'Confirm Remove',
    content: `
      <div>
        <p>Once removed, <strong>${operation?.dialog?.userName}</strong> will no longer have access to <strong>${operation?.dialog?.gameName}</strong>.</p>
      </div>
    `,
  }
}

const handleAddGameAccess = async operation => {
  return {
    title: 'Confirm Add',
    content: `
      <div>
        <p>Add <strong>${operation?.dialog?.userName}</strong> to <strong>${
      operation?.dialog?.gameName
    }</strong>?</p>
      </div>

      <div>Access Level: <b>${
        operation?.dialog.readOnly ? 'Read' : 'Write'
      }</b></div>
    `,
  }
}

const handleUpdateGameAdApprovals = async operation => {
  const type = operation?.variables?.type
  const customTitle = operation?.variables?.customTitle
  return {
    title: `Confirm ${
      type === BUSINESS_ACTIONS.APPROVE ? 'Approve' : 'Reject'
    }`,
    content: `<p>
    Are you sure you want to ${
      type === BUSINESS_ACTIONS.APPROVE ? 'approve' : 'reject'
    } ${customTitle ? `<b>${customTitle}</b>` : 'this ad'}?
    </p>`,
  }
}

const getGuards = () => {
  const out = {
    [getOperationName(m.updateGame)]: handleUpdateGame,
    [getOperationName(m.pauseGames)]: handlePauseGames,
    [getOperationName(m.unpauseGames)]: unpauseGames,

    [getOperationName(m.archiveGames)]: operation =>
      handleSensitiveInfoChange({
        operation,
        type: BUSINESS_TYPES.GAME,
        action: BUSINESS_ACTIONS.ARCHIVE,
      }),

    [getOperationName(m.removeGameAccess)]: handleRemoveGameAccess,
    [getOperationName(m.addGameAccess)]: handleAddGameAccess,

    [getOperationName(m.updateGameAdApprovals)]: handleUpdateGameAdApprovals,
  }

  return out
}

export const guards = {
  ...getGuards(),
}
