<template>
  <label
    v-bem="{
      disabled,
      disabledisStr,
      checked,
      focused: isFocused,
    }"
    role="radio"
    v-bind="$attrs"
    @focus="onFocus"
    @blur="onBlur"
    @keyup.enter="checkThisRadio"
    @keyup.space="checkThisRadio"
  >
    <div v-if="(label || value) && !hasCustomLabel" v-bem:label>
      {{ label || value }}
    </div>

    <div v-if="!label && hasCustomLabel" v-bem:label>
      <slot />
    </div>

    <div v-if="!disabled" v-bem:indicator />

    <input
      ref="input"
      v-bem:inner
      hidden="hidden"
      :name="radioName"
      v-bind="$attrs"
      :checked="checked"
      type="radio"
      @change="checkedChangeHandler"
    />

    <div v-if="disabledisStr" v-bem:disabled-text>
      <fp-chip label disabled> Coming Soon </fp-chip>
    </div>
  </label>
</template>

<script>
import useSize from '@component-mixins/useSize'
import useReadonly from '@component-mixins/useReadonly'

export default {
  name: 'FpRadio',

  mixins: [useSize, useReadonly],

  inheritAttrs: false,

  // ===
  // Injections
  // ===

  inject: {
    isInGroup: {
      from: 'injectFromGroup',
      default: false,
    },
    radioName: { from: 'name', default: null },
    groupInstance: { from: 'groupInstance', default: null },
  },

  // ===
  // Props
  // ===

  props: {
    value: {
      type: [Boolean, Number, String],
      required: true,
    },

    label: {
      type: [String, Number],
      default: '',
    },

    disabled: {
      type: [Boolean, String],
      default: false,
    },
  },

  data() {
    return { isFocused: false }
  },

  computed: {
    disabledisStr() {
      return typeof this.disabled === 'string'
    },
    checked() {
      return this.value === this.groupInstance.value
    },

    hasCustomLabel() {
      return this.$slots.default && this.$slots.default.length
    },

    isButtonStyle() {
      return this.groupInstance?.button
    },
  },

  created() {
    if (!this.isInGroup) {
      console.warn('[Radio]: Radio should be warpped with RadioGroup')
    }
  },

  methods: {
    onFocus(e) {
      this.isFocused = true
      this.$emit('focus', e)
    },

    onBlur(e) {
      this.isFocused = false
      this.$emit('blur', e)
    },

    checkedChangeHandler(ev) {
      if (this.disabled) return

      const { checked } = ev.target

      if (checked && this.isInGroup) {
        this.checkThisRadio()
      }
    },

    checkThisRadio(ev) {
      this.groupInstance.$emit('radioValueChange', this.value)
    },
  },
}
</script>

<style lang="scss" src="@component-styles/radio"></style>
