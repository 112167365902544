import Worker from './countries.worker.js'
const util = require('util')

const _promisify = fn => util.promisify(fn)

// callback from util.promisify must be last and adhere to node style
const _getAll = ({ fields, search, excludedCodes }, callback) => {
  if (!fields || !Array.isArray(fields) || fields.length < 1) {
    throw Error('fields are required and must be array')
  }
  const worker = new Worker()
  worker.postMessage({ cmd: 'getAll', args: { fields, search, excludedCodes } })
  worker.addEventListener('message', event => {
    // node style error first callback
    callback(null, event.data)
    // terminate worker or thread will linger in chrome process
    worker.terminate()
  })

  worker.onerror = event => {
    callback(event.message, null)
    worker.terminate()
  }
}

const _getDivisions = (code, callback) => {
  if (!code || typeof code !== 'string') {
    throw Error('code is required and typeof string')
  }

  const worker = new Worker()
  worker.postMessage({ cmd: 'getDivisions', args: { code } })

  worker.addEventListener('message', event => {
    callback(null, event.data)
    worker.terminate()
  })

  worker.onerror = event => {
    callback(event.message, null)
    worker.terminate()
  }
}

const _genBlocksByCodes = (codes, callback) => {
  if (!codes || !Array.isArray(codes)) {
    throw Error('codes are required and must be array')
  }

  const worker = new Worker()

  worker.postMessage({ cmd: 'genBlocksByCodes', args: { codes } })

  worker.addEventListener('message', event => {
    callback(null, event.data)
    worker.terminate()
  })

  worker.onerror = event => {
    callback(event.message, null)
    worker.terminate()
  }
}

export const getAll = _promisify(_getAll)
export const getDivisions = _promisify(_getDivisions)
export const genBlocksByCodes = _promisify(_genBlocksByCodes)
