<script>
import FpOneStep from './step'

export default {
  name: 'FpStepper',

  components: {
    FpOneStep,
  },

  props: {
    steps: {
      type: Array,
      default: () => [],
    },

    current: {
      type: Number,
      default: 1,
    },

    record: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      // When user want to jump back to a completed step
      // lastPeakStepIndex will remember the biggest number of step user has achived
      lastPeakStepIndex: 0,
    }
  },

  computed: {
    activeStep() {
      return this.current - 1
    },
  },

  watch: {
    current: {
      handler(newVal) {
        if (newVal > this.lastPeakStepIndex + 1) {
          this.lastPeakStepIndex = newVal - 1
        }
      },

      immediate: true,
    },

    record: {
      handler(newVal) {
        if (this.current > newVal) {
          return console.warn('[FpStepper]: record cannot smaller than record!')
        }
        this.lastPeakStepIndex = this.record - 1
      },

      immediate: true,
    },
  },

  created() {
    if (this.current > this.record) {
      return console.warn('[FpStepper]: current cannot greater than record!')
    }

    this.lastPeakStepIndex = this.record - 1
  },

  methods: {
    jump(stepIndex) {
      this.$emit('before-jump', this.current, stepIndex + 1)
      this.$emit('update:current', stepIndex + 1)
    },
  },
}
</script>

<template>
  <ol v-bem>
    <fp-one-step
      v-for="(step, stepIndex) in steps"
      :key="step.label"
      :error="step.error"
      :active="stepIndex === activeStep"
      :completed="stepIndex < lastPeakStepIndex"
      :stand-by-active="
        stepIndex === lastPeakStepIndex && stepIndex !== activeStep
      "
      :label="step.label"
      :step-index="stepIndex"
      @active="jump"
    />
  </ol>
</template>

<style lang="scss" src="@component-styles/stepper"></style>
