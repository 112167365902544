<template>
  <label
    v-bem="{
      dragHover,
      uploading,
      hasState,
      success: uploadSuccess,
      failed: uploadFailed || error,
      filled: hasFileFilledOrState,
    }"
    :style="{
      '--width': finalWidth,
      '--padding-bottom': paddingBottomPercentage,
    }"
  >
    <input
      :id="id"
      :name="name"
      hidden="hidden"
      type="file"
      :accept="accept"
      :value="value"
      @change="fileChangeHandler"
    />

    <!-- Bearing wall, used for extend the component size to settled ratio -->
    <div v-bem:bearing-wall />

    <!-- According to type change upload inner -->
    <component :is="uploadInnerComp" ref="uploadInner" />

    <div v-bem:mask="{ lighter: isMediaType }" />
    <!-- Stuffs show on SINGLE file uploading -->

    <div
      v-if="uploading"
      v-bem:progress="{ lighter: isMediaType }"
      :style="progressBarStyle"
    />

    <transition-group name="fade-slow" mode="out-in">
      <p v-if="uploading && !scanning" key="percent" v-bem:state-msg>
        {{ uploadPercent + '%' }}
      </p>

      <p v-else-if="scanning" key="scan" v-bem:state-msg> Checking... </p>
    </transition-group>

    <transition name="fade-slow">
      <v-progress-circular
        v-if="uploading"
        class="fp-upload__spinner"
        size="20"
        width="2"
        indeterminate
        color="white"
      />
    </transition>

    <transition name="fade-slow">
      <div v-if="hasFile" v-bem:clear @click.stop.prevent="clear">
        <fp-icon class="clear" name="times" />
      </div>
    </transition>

    <!-- error messsage, if you dont want it, -->
    <fp-hint-message
      v-if="error && errorMessage.length && !noErrorMessage"
      fill
      :message="errorMessageDisplay"
    />
  </label>
</template>

<script>
import { s3UploadFile } from '@shared/s3'
import { contentTypes } from '@shared/contentTypes'
import useDisabled from '@component-mixins/useDisabled'
import useReadonly from '@component-mixins/useReadonly'

import { convertToUnit } from '@utils'
import { ASPECT_RATIO_VALUES } from '@constants'

const TYPES_ENUM = ['media', 'file']

const AspectRatioConfig = {
  REG: /^(\d+):(\d+)$/,
}

export const getFileExtension = fileName => {
  return fileName.indexOf('.') > -1 ? `.${fileName.split('.').pop()}` : ''
}

export default {
  name: 'FpS3Upload',

  blockName: 'fp-upload',

  mixins: [useDisabled, useReadonly],

  provide() {
    return {
      hub: this,
    }
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    type: {
      type: String,
      default: 'media',
      validator(v) {
        return TYPES_ENUM.includes(v)
      },
    },

    label: {
      type: String,
      default: '',
    },

    value: {
      type: [Object],
      default: null,
    },
    // The unit is kb
    maxSize: {
      type: Number,
      default: 2048,
    },

    accept: {
      default: '.jpg,.png,.jpeg',
      type: String,
    },

    tip: {
      type: String,
      default: 'Drag file here or click to upload',
    },

    name: {
      type: String,
      default: '',
    },

    id: {
      type: String,
      default: Math.random().toString(16).slice(2),
    },

    noErrorMessage: Boolean,

    // Not support multiple yet
    multiple: Boolean,

    /**
     * XHR config props
     * @url url
     * @withCredentials withCredentials
     * @headers headers
     * @data extra data append to Formdata
     */
    url: {
      type: String,
      default: '',
    },

    withCredentials: Boolean,

    headers: {
      type: Object,
      default: () => ({}),
    },

    tagging: {
      type: Object,
      default: () => ({}),
    },

    /**
     * State message props
     * @sizeExceedMessage
     * @invalidFileMessage
     * @noMultipleMessage
     * @typeInvalidMessage
     */
    sizeExceedMessage: {
      type: String,
      default: 'File size is too large',
    },

    invalidFileMessage: {
      type: String,
      default: 'Invalid file type!',
    },

    noMultipleMessage: {
      type: String,
      default: 'Not support multiple files now',
    },

    typeInvalidMessage: {
      type: String,
      default: 'Please only upload image files',
    },

    /**
     * These props list below works only for media type upload
     * @prefill prefilled s3 object when editing
     * @ratio media file aspect ratio
     */
    prefill: {
      type: Object,
      default: null,
    },

    ratio: {
      type: String,
      default: 'SIXTEEN_BY_NINE',
      validator(v) {
        return AspectRatioConfig.REG.test(ASPECT_RATIO_VALUES[v])
      },
    },

    resolution: {
      type: String,
      default: null,
    },

    width: {
      type: [String, Number],
      default: '',
    },

    height: {
      type: [String, Number],
      default: '',
    },
  },

  data() {
    return {
      AspectRatioConfig,
      widthCalculated: null,

      fileList: null,

      dragHover: false,

      uploadPercent: 0,
      scanning: false,
      uploading: false,
      uploadSuccess: false,
      uploadFailed: false,

      // isError exactly
      error: false,
      errorMessage: [],

      checkPointQueue: [],

      lastestExpectedFieldName: '',
    }
  },

  computed: {
    isMediaType() {
      return this.type === 'media'
    },

    finalWidth() {
      return this.widthCalculated || convertToUnit(this.width || '100%')
    },

    numberRatio() {
      return ASPECT_RATIO_VALUES[this.ratio]
    },

    labelToDisplay() {
      const labelTxtMap = {
        media: this.numberRatio,
        file: 'Upload',
      }

      return this.label || labelTxtMap[this.type]
    },

    paddingBottomPercentage() {
      let [, ratioW, ratioH] = AspectRatioConfig.REG.exec(this.numberRatio)
      ratioW = Number(ratioW)
      ratioH = Number(ratioH)

      return convertToUnit((ratioH / ratioW) * 100, '%')
    },

    uploadInnerComp() {
      return `fp-${this.type}-upload`
    },

    progressBarStyle() {
      const { uploadPercent } = this

      return {
        transform: `translate3d(0, 0, 0) scaleX(${uploadPercent / 100})`,
      }
    },

    hasFile() {
      return !!(this.prefill || this.fileList?.length)
    },

    hasState() {
      return this.uploadSuccess || this.uploadFailed || this.error
    },

    hasFileFilledOrState() {
      return this.hasState || this.hasFile
    },

    errorMessageDisplay() {
      return this.errorMessage.join(' / ')
    },

    labelText() {
      const { label, ratio } = this
      return label || ratio
    },
  },

  created() {
    if (this.type === 'media') {
      // Q: WHY NOT MOVE THIS TO MEDIA UPLOD COMPPONENT?
      // A: We need calculate the size asap, otherwise wil cause reflow
      this.calcSizeBaseOnRatio()
    }

    this.addCheckPoint(this.sizeCheck)

    this.$on('_reset', this.reset)
    this.$on('_runCustomCheckPoints', this.runCustomCheckPoints)
    this.$on('_allChecksPassed', this.uploadFiles)
  },

  mounted() {
    // Prevent default drag drop events
    this.addListener(
      document,
      'dragleave drop dragenter dragover',
      e => {
        e.preventDefault()
      },
      false
    )

    // enable drag and drop
    this.enableDND()
  },

  methods: {
    calcSizeBaseOnRatio() {
      const { width, height } = this

      let [, ratioW, ratioH] = AspectRatioConfig.REG.exec(this.numberRatio)

      if (!width && !height) return

      if (width && height) {
        return console.warn(
          '[FpUpload]: Cannot set `width` and `height` at the same time, ignore `height` in this time'
        )
      }

      if (width && !height) {
        // Custom width
        return
      }

      // height is not number like value, eg. 50%, 60em
      if (isNaN(Number(height))) {
        return console.warn(
          `[FpUpload]: invalid \`height\` value: ${height}, use Number or Number-string instead`
        )
      }

      this.widthCalculated = (Number(this.height) / ratioH) * ratioW
      this.widthCalculated = convertToUnit(this.widthCalculated)
    },

    addListener(...args) {
      if (typeof args[0] === 'string') {
        var target = this.$el
        var [events, listener, options] = args
      } else {
        // eslint-disable-next-line
        var [target, events, listener, options] = args
      }

      events.split(' ').forEach(eventName => {
        target.addEventListener(eventName, listener, options)
      })
    },

    expect(fieldName) {
      this.lastestExpectedFieldName = fieldName

      return this
    },

    when(events) {
      return {
        be: value => {
          this.addListener(events, () => {
            this[this.lastestExpectedFieldName] = value
          })

          return this
        },
      }
    },

    enableDND() {
      this.expect('dragHover')
        .when('dragover')
        .be(true)
        .when('dragleave drop')
        .be(false)

      this.addListener('drop', e => {
        if (this.readonly || this.disabled) return false

        // Filter out invalid type of files
        let files = [...e.dataTransfer.files].filter(file => {
          const { type, name } = file
          const extension = getFileExtension(name).toLowerCase()

          const baseType = type.replace(/\/.*$/, '')

          const isTypeValid = this.accept
            .split(',')
            .map(type => type.trim())
            .filter(type => type)
            .some(acceptedType => {
              if (/\..+$/.test(acceptedType)) {
                return extension === acceptedType
              }
              if (/\/\*$/.test(acceptedType)) {
                return baseType === acceptedType.replace(/\/\*$/, '')
              }
              if (/^[^\/]+\/[^\/]+$/.test(acceptedType)) {
                return type === acceptedType
              }

              return false
            })

          return isTypeValid
        })

        if (files.length === 0) {
          this.$emit('_reset')
          this.addError(this.invalidFileMessage)
          this.showError()
          return this.$emit('error', new Error(this.errorMessageDisplay))
        }

        if (files.length > 1) {
          this.$emit('_reset')
          this.addError(this.noMultipleMessage)
          this.showError()
          return this.$emit('error', new Error(this.errorMessageDisplay))
        }

        this.fileChangeHandler({ target: { files } })
      })
    },

    fileChangeHandler(e) {
      if (typeof e.target === 'undefined') {
        this.addError(this.invalidFileMessage)
        return false
      }

      this.$emit('_reset')

      const { files } = e.target
      // Q: should we assign fileList before validation
      this.fileList = [...files]
      this.$emit('change', this.fileList)

      // Validate workflow:
      // use Image to give an example:
      //  1. check type (use DND handler & accept attribute to control)
      //  2. check size
      //  3. check ratio (asynchronous)
      //  4. emit _allValdationPassed event
      this.$emit('_runCustomCheckPoints', files)
    },

    addCheckPoint(checkMethod) {
      // checkMethod dont has to emit error
      // errors will be emit toghter inside runCustomCheckPoints method
      this.checkPointQueue.push(checkMethod)
    },

    async runCustomCheckPoints(files) {
      if (this.error) return false

      const result = await Promise.all(
        this.checkPointQueue.map(checkMethod =>
          // Every checkMethod accept two params
          // whick second is the method to push error message to error stack
          checkMethod(files, this.addError)
        )
      )

      if (result.some(valid => !valid)) {
        this.$emit('error', new Error(this.errorMessageDisplay))
        return this.showError()
      }

      this.$emit('_allChecksPassed')
    },

    async sizeCheck(files, addError) {
      return [...files].every(file => {
        let size = Math.floor(file.size / 1024)

        if (size > this.maxSize) {
          addError(this.sizeExceedMessage)

          this.$emit('exceed', file)

          return false
        }

        return true
      })
    },

    async upload(file) {
      this.$emit('start', file)

      const { tagging } = this

      this.uploading = true

      const fileType = contentTypes.find(
        e => getFileExtension(file.name) === e.ext
      ) || { mime: this.fileList[0].type }

      const Tagging = Object.entries({
        ...tagging,
      }).map(([key, value]) => ({ key, value }))

      const done = () => {
        this.uploadPercent = 0
        this.uploading = false
        this.uploadSuccess = true
        this.uploadFailed = false
        this.scanning = false
      }

      const failed = () => {
        this.uploadPercent = 0
        this.uploading = false
        this.uploadSuccess = false
        this.uploadFailed = true
        this.scanning = false
      }

      const scanning = () => {
        this.scanning = true
      }

      const onprogress = ev => {
        const { loaded, total } = ev
        this.uploadPercent = ~~((loaded * 100) / total)
        this.$emit('progress', ev, file)
      }

      try {
        const key = await s3UploadFile(file, fileType.mime, Tagging, onprogress)
        const { payloadToEmit } = this.$refs.uploadInner
        this.$emit(
          'success',
          { ...payloadToEmit, fileName: key },
          { done, failed, scanning },
          file
        )
      } catch (error) {
        failed()
        this.$emit('error', error, file)
      }
    },

    uploadFiles() {
      const { fileList } = this

      fileList.forEach(this.upload)
    },

    addError(message) {
      this.errorMessage.push(message)
    },

    showError(message) {
      this.error = true
    },

    clear() {
      this.$emit('clear', {
        ...this.prefill,
        ...this.$refs.uploadInner.payloadToEmit,
      })
      this.$emit('_reset')
    },

    reset() {
      this.fileList = []

      this.uploading = false
      this.uploadPercent = 0
      this.uploadSuccess = false
      this.uploadFailed = false

      this.error = false
      this.errorMessage = []
    },
  },
}
</script>

<style lang="scss" src="@component-styles/s3-upload"></style>
