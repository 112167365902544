import gql from 'graphql-tag'
import { output } from '../../shared/fragments/mutationOutput'

export const uploadFile = gql`
  mutation uploadFile($input: S3ObjectInput!) {
    uploadFile(input: $input) {
      ...output
    }
  }
  ${output}
`

export const createUploadURL = gql`
  mutation createUploadURL($input: CreateUploadURLInput!) {
    createUploadURL(input: $input) {
      id
      success
      message
      statusCode

      url
      fields {
        key
        value
      }
    }
  }
`

export const createMultipartUploadURLs = gql`
  mutation createMultipartUploadURLs($input: CreateMultipartUploadURLsInput!) {
    createMultipartUploadURLs(input: $input) {
      id
      success
      message
      statusCode

      key
      partURLs {
        partNumber
        url
      }
      url
      thumbnailURL
    }
  }
`

export const setMultipartUpload = gql`
  mutation setMultipartUpload($input: SetMultipartUploadInput!) {
    setMultipartUpload(input: $input) {
      ...output
    }
  }
  ${output}
`
