<script>
import useSize from '@component-mixins/useSize'

export default {
  name: 'PropRow',

  mixins: [useSize],

  props: {
    loading: Boolean,
  },
}
</script>

<template>
  <div
    :class="$style.row"
    :style="{
      height: finalHeight,
    }"
  >
    <v-flex xs5 pa-0 :class="$style.nameRow">
      <slot name="name" />
    </v-flex>
    <v-flex xs7 pa-0 :class="$style.valueRow">
      <fp-skeletonize
        width="120"
        round
        :loading="loading"
        :height="height ? height - 10 : 14"
      >
        <span>
          <slot />
        </span>
      </fp-skeletonize>
    </v-flex>
  </div>
</template>

<style lang="scss" module>
@import '@scss';

.row {
  display: flex;
  padding-top: 14px;
  padding-bottom: 14px;
  line-height: 21px;
  border-bottom: 1px solid #f7f8fb;

  .nameRow {
    color: $color-mid-grey;
  }

  .valueRow {
    display: flex;
    justify-content: flex-end;
    font-weight: 400;
    color: $color-dark;
  }
}
</style>
