// @ts-ignore

import { cs } from './console'
import { formatMessage } from './formatMessage'

export const csg = (type, name, lines, failed = false) => {
  if (process.env.NODE_ENV === 'production') return
  const parts = formatMessage(type, name, failed)
  console.groupCollapsed(...parts)
  if (lines) {
    for (const line of lines) {
      cs.l(line?.body, line?.label)
    }
  }

  console.groupEnd()
}
