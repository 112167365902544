import gql from 'graphql-tag'

export const getReports = gql`
  query getReports(
    $limit: Int
    $skip: Int
    $filter: ReportQueryFilterInput
    $ids: [ID!]
    $orderBy: ReportsOrderByEnum
  ) {
    getReports(
      ids: $ids
      limit: $limit
      skip: $skip
      orderBy: $orderBy
      filter: $filter
    ) {
      skip
      limit
      total
      items {
        dimensions
        downloadURL
        filter {
          adIDs
          clientIDs
          countryCodes

          gameIDs
          orgIDs
          platforms
          ratings
        }
        id
        lastRunDate
        lastStatus
        metrics
        name
        orgID
        period {
          endDate
          startDate
          type
        }
        recurrence {
          day
          type
        }
        timezone
        toEmails
        dateCreated
        dateUpdated
        archived
      }
    }
  }
`

export const getCountries = gql`
  query getCountries {
    getCountries {
      name
      code
    }
  }
`

export const getReportFields = gql`
  query getReportFields($orgIDs: [ID!]) {
    getReportFields(orgIDs: $orgIDs) {
      dimensions
      metrics
    }
  }
`
