<script>
export default {
  name: 'AccountCard',

  props: {
    title: {
      type: String,
      default: '',
    },
    rowData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default
    },
    hasButtonsSlot() {
      return !!this.$slots.buttons
    },
  },
}
</script>

<template>
  <v-flex grow>
    <div :class="[$style.card, !!padding ? $style[padding] : '']">
      <div v-if="!!title" :class="$style.title">
        <div :class="$style.titleDot"></div>
        <span>{{ title }}</span>
      </div>

      <v-layout row wrap ma-1>
        <v-flex v-if="rowData && rowData.length" class="mb-12">
          <prop-row
            v-for="(row, index) in rowData"
            :key="`setting${index}`"
            :loading="loading"
          >
            <template #name>
              {{ row.title }}
            </template>
            <div v-if="row.value" class="text-xs-right" v-html="row.value" />
            <div v-else :class="$style.unset"> Not set </div>
          </prop-row>
        </v-flex>

        <slot />
      </v-layout>
    </div>
    <div v-if="hasButtonsSlot" :class="$style.cta">
      <slot name="buttons" />
    </div>
  </v-flex>
</template>

<style lang="scss" module>
@import '@scss';

.unset {
  font-style: italic;
  font-weight: normal;
  color: $color-mid-grey;
  opacity: 0.3;
}

.card {
  @include card-border();

  position: relative;
  margin-bottom: 30px;

  &.small {
    padding: 10px 25px 15px 30px;
  }

  .title {
    display: flex;
    align-items: center;
    height: 25px;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 600;
    color: $color-dark-primary;
    text-transform: uppercase;

    .titleDot {
      width: 13px;
      min-width: 13px;
      height: 13px;
      margin-right: 8px;
      background: $color-primary;
      border-radius: 50%;
    }
  }

  .content {
    margin-bottom: 30px;
  }
}

.cta {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  padding: 0 25px 15px 30px;
  margin-top: -57px; // Use 57 since we want it to keep the same positioning as the previous css

  > button {
    max-width: 100%;
    margin: 0 !important;
    > span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
