import gql from 'graphql-tag'
import { output } from '../../shared/fragments/mutationOutput'

export const createAd = gql`
  mutation createAd($input: createAdInput!) {
    createAd(input: $input) {
      ...output
    }
  }
  ${output}
`

export const updateAd = gql`
  mutation updateAd($input: updateAdInput!) {
    updateAd(input: $input) {
      ...output
    }
  }
  ${output}
`

export const pauseAds = gql`
  mutation pauseAds($ids: [ID]!) {
    pauseAds(ids: $ids) {
      ...output
    }
  }
  ${output}
`

export const unpauseAds = gql`
  mutation unpauseAds($ids: [ID]!) {
    unpauseAds(ids: $ids) {
      ...output
    }
  }
  ${output}
`

export const archiveAds = gql`
  mutation archiveAds($ids: [ID]!) {
    archiveAds(ids: $ids) {
      ...output
    }
  }
  ${output}
`

export const unarchiveAds = gql`
  mutation unarchiveAds($ids: [ID]!) {
    unarchiveAds(ids: $ids) {
      ...output
    }
  }
  ${output}
`

export const requestAdReviews = gql`
  mutation requestAdReviews($input: RequestAdReviewsInput!) {
    requestAdReviews(input: $input) {
      ...output
    }
  }
  ${output}
`
