import gql from 'graphql-tag'

export const getTitles = gql`
  query getTitles($type: TitleTypeEnum!, $filter: TitleFiltersInput) {
    getTitles(type: $type, filter: $filter) {
      id
      title
    }
  }
`
