import * as m from '../mutations'
import { getOperationName } from '../../utils'

const handleRemoveReportingKey = async operation => {
  return {
    title: 'Confirm Delete',
    content: `
      <div>
        <p>Are you sure you want to delete the existing reporting api key?</p>
      </div>
    `,
  }
}

const getGuards = () => {
  const out = {
    [getOperationName(m.removeAPIKey)]: handleRemoveReportingKey,
  }

  return out
}

export const guards = {
  ...getGuards(),
}
