import {
  Query,
  Mutation,
  MutationAddNotificationArgs,
  MutationDeleteNotificationsArgs,
  MutationDismissNotificationsArgs,
  QueryGetNotificationsArgs,
  Maybe,
  Notification,
} from 'typedefs'
import * as mutas from '@graphql/notification/mutations'
import to from 'await-to-js'
import * as qry from '@graphql/notification/queries'
import { tryMutate, tryQuery } from '@graphql/query-helpers'
import graphql from '@graphql'
import { reactive, computed, SetupContext, watch } from '@vue/composition-api'
import { useToaster } from '@components/_base/toaster/useToaster'
import Vue from 'vue'
import { isAbsoluteUrl, vueToNormalObject } from '@utils'
import { debounce } from '@shared/function'
import { iconColorMap } from './settings'
import { ValuesType } from 'utility-types'
import { Action } from 'types'
import uniqBy from 'lodash/uniqBy'
import * as NotificationStore from '@state/modules/notifications'

type State = {
  items: NotificationItem[]
  pageCount: number
  perPage: number
  loading: boolean
}
// type ItemFilterFn = (item: Maybe<Notification> | NotificationItem) => void
// type Options = {
//   itemsPerPage?: number
//   itemFilter?: ItemFilterFn
// }
type ActionKeys = 'dismiss' | 'delete' | 'action'
type ActionMap = Record<ActionKeys, () => Action | undefined>
type NotificationItem = NotificationStore.NotificationItem

export const useNotification = (ctx: SetupContext, actions?: ActionKeys[]) => {
  const toaster = useToaster(ctx)

  const notificationItems = NotificationStore.get(
    'notifications'
  ) as NotificationItem[]

  const state: State = reactive({
    items: [...notificationItems],
    loading: false,
    pageCount: 0,
    perPage: 6,
  })

  const cState = reactive({
    noItems: computed(() => {
      return !state.items || state.items.length <= 0
    }),
    allDismissed: computed(() => {
      const rv = state.items.every(item => item.dismissed === true)
      return rv
    }),

    itemIds: computed(() => {
      return state.items.map(item => item?.id)
    }),
  })

  const handleNotificationAdded = async (notification: Maybe<Notification>) => {
    if (!notification) return
    const original = NotificationStore.get(
      'notifications'
    ) as NotificationStore.NotificationItem[]

    const rv: NotificationItem[] = processNotifications([
      notification && { ...notification },
      ...original,
    ])

    NotificationStore.dispatch('setNotifications', [...rv])
    Vue.set(state, 'items', [...rv])
  }

  const setLoading = async (val: boolean) => {
    state.loading = val
  }

  const add = async (args?: MutationAddNotificationArgs) => {
    const rv: [Error | null, Mutation['addNotification']] = await to(
      graphql.mutate({
        mutation: mutas.addNotification,
        variables: {
          ...args,
        },
      })
    )

    return rv
  }

  const remove = async (args?: MutationDeleteNotificationsArgs) => {
    const rv: [Error | null, Mutation['deleteNotifications']] = await tryMutate(
      {
        mutation: mutas.deleteNotifications,
        variables: {
          ...args,
        },
      }
    )
    return rv
  }

  const dismiss = async (args?: MutationDismissNotificationsArgs) => {
    const rv: [Error | null, Mutation['dismissNotifications']] =
      await tryMutate({
        mutation: mutas.dismissNotifications,
        variables: {
          ...args,
        },
      })

    return rv
  }

  const get = async (args?: QueryGetNotificationsArgs) => {
    const rv: [Error | null, Query['getNotifications']] = await tryQuery({
      query: qry.getNotifications,
      variables: { limit: 10, ...args },
    })
    return rv
  }

  const setItemsProp = (
    ids: string[],
    key: keyof NotificationItem,
    value: ValuesType<NotificationItem>
  ) => {
    const indices = ids.map(id => state.items.findIndex(item => item.id === id))
    indices.forEach(index => {
      if (index >= 0) {
        const settie = vueToNormalObject(state.items[index])
        Vue.set(settie, key, value)
      }
    })
  }

  const getActions = (item: Maybe<Notification> | NotificationItem) => {
    const handleDismiss = async () => {
      await dismissItems([item?.id ?? ''])
      await getAndSetItems()
    }

    const handleDelete = async () => {
      await removeItems([item?.id ?? ''])
      await getAndSetItems()
    }

    const actionMap: ActionMap = {
      dismiss: () => ({
        label: 'Dismiss',
        onClick: debounce(handleDismiss, 1000, {
          leading: true,
          trailing: false,
        }),
      }),
      delete: () => ({
        label: 'Delete',
        onClick: debounce(handleDelete, 1000, {
          leading: true,
          trailing: false,
        }),
      }),
      action: () => {
        if (item?.url) {
          return {
            label: 'Action',
            onClick: () => {
              const url = item?.url || ''
              if (isAbsoluteUrl(url)) {
                window.location.href = url
              } else {
                ctx.root.$router.push(url)
              }
            },
          }
        }
      },
    }

    const actionsToUse: Action[] = []

    actions &&
      actions.forEach(action => {
        const tmp = (actionMap[action] && actionMap[action]()) ?? null
        if (tmp) {
          actionsToUse.push(tmp)
        }
      })

    return actionsToUse
  }

  const removeItems = async (ids: string[]) => {
    if (!ids || !Array.isArray(ids) || ids.length <= 0) return
    setItemsProp(ids, 'loading', true)
    const [err] = await remove({
      id: [...ids],
    })

    if (err) {
      toaster.error(err)
    } else {
      const newItems = state.items.filter(o => !ids.includes(o.id))
      NotificationStore.dispatch('setNotifications', newItems)
      Vue.set(state, 'items', newItems)
    }

    setItemsProp(ids, 'loading', false)
  }
  const dismissItems = async (ids: string[]) => {
    if (!ids || !Array.isArray(ids)) return

    setItemsProp(ids, 'loading', true)
    const [err] = await dismiss({
      id: [...ids],
    })

    toaster.error(err)
    setItemsProp(ids, 'loading', false)
  }

  /**
   *  dismiss all items in the current hook state, not all items in the backend
   */
  const dismissAllItems = async () => {
    state.loading = true
    const ids = state.items.map(o => o?.id ?? '')
    await dismissItems(ids)
    state.loading = false
  }

  const getAndSetItems = async (page = 1) => {
    state.loading = true
    const [err, res] = await get({
      limit: state.perPage,
      skip: (page - 1) * state.perPage,
    })

    toaster.error(err)

    if (!err) {
      const tmp = processNotifications(res?.items ?? [])

      NotificationStore.dispatch('setNotifications', tmp)
      Vue.set(state, 'items', tmp)

      Vue.set(state, 'pageCount', Math.ceil(res!.total! / state.perPage))
    }

    state.loading = false
  }

  const processNotifications = (
    items: Maybe<Notification>[] | NotificationItem[]
  ): NotificationItem[] => {
    if (!items || !Array.isArray(items)) return []

    const mapped = (items as Maybe<Notification>[]).map((item, i) => {
      const actionsToUse = getActions(item)

      let rv = { ...(item as NotificationItem) }
      rv.loading = false
      rv.actions = actionsToUse

      return { ...rv }
    })
    const sorted = mapped.sort((a, b) => {
      return b.date - a.date
    })

    const deduped = uniqBy(sorted, o => o.id)

    return deduped
  }

  const getIcon = (item: NotificationItem | Notification) => {
    const type = item?.type ?? 'INFO'
    const icon = {
      color: iconColorMap[type]?.color,
      background: iconColorMap[type]?.background,
      name: iconColorMap[type]?.name,
    }

    return icon
  }

  // watchers

  watch(
    computed(() => NotificationStore.get('notificationAdded')),
    item => {
      handleNotificationAdded(item as Notification)
    },
    { deep: true }
  )

  // todo decouple generic item management to new useItem hook
  return {
    add,
    get,
    dismiss,
    remove,
    dismissItems,
    dismissAllItems,
    removeItems,
    getActions,
    getAndSetItems,
    setItemsProp,
    handleNotificationAdded,
    getIcon,
    setLoading,
    state,
    cState,
  }
}
