import Vue from 'vue'
// @ts-ignore
import PreviewDialog from '@src/components/advanced-dialog/media-preview-dialog.vue'
import { AdRatiosEnum } from '@graphql-types'
import app from '@src/main'

type PreviewOptions = {
  fileName: string
  url: string
  ratio: AdRatiosEnum
}

declare module 'vue/types/vue' {
  interface Vue {
    $preview: (options: PreviewOptions) => void
  }
}

let currentPreviewUrl = ''

export default function useMediaPreview() {
  Vue.use({
    install(Vue) {
      Vue.prototype.$preview = function (options: PreviewOptions) {
        if (currentPreviewUrl === options.url && !options.url) {
          currentPreviewUrl = ''
          return
        }

        const dialog = new (Vue.extend(PreviewDialog))({
          propsData: {
            ...options,
          },
          parent: this || app,
        })

        dialog.$mount()
        ;(dialog as any).show()

        currentPreviewUrl = options.url
      }
    },
  })
}
