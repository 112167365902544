<template>
  <form
    action="javascript:void(0);"
    :class="[$style.container, { [$style.spaceBetween]: spaceBetweenContent }]"
    :style="{ maxWidth: finalWidth }"
  >
    <slot />
    <div v-if="$slots.footer" :class="$style.footer">
      <slot name="footer" />
    </div>
  </form>
</template>

<script>
import useSize from '@components/_base/mixins/useSize'

export default {
  name: 'FormLayout',

  mixins: [useSize],

  props: {
    spaceBetweenContent: Boolean,
  },
}
</script>

<style lang="scss" module>
@import '@scss';

.container {
  width: 100%;
  max-width: 550px;
  margin: 0 auto;

  &.spaceBetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 75px;
}
</style>
