<template>
  <div v-bem:media>
    <div v-bem:label="{ failed: hub.uploadFailed }">
      <div v-bem:label-text>
        {{ hub.labelToDisplay }}
      </div>

      <div v-bem:label-tip>
        <slot name="tip" />
        <span>
          Drag file here or click to upload
          <fp-icon name="upload" />
        </span>
        <small>
          {{ hub.resolution }}, &lt;{{ (hub.maxSize / 1024).toFixed(1) }} MB,
          {{ hub.accept }}
        </small>
      </div>
    </div>

    <div v-show="hub.hasFileFilledOrState || hub.prefill" v-bem:preview>
      <img ref="img" v-bem:preview-inner :src="previewComputedSrc" />
      <!-- Not support video in R1 -->
      <!-- <video v-bem:preview-inner src=""></video> -->
    </div>

    <!-- Success or Fail icon -->
    <div v-if="showStateIcon" v-bem:state-icon>
      <fp-icon class="state" :name="stateIcon" />
    </div>
  </div>
</template>

<script>
export default {
  // Tell BEM plugin this component use fp-upload as blockName
  blockName: 'FpUpload',

  name: 'FpMediaUpload',

  inject: ['hub'],

  data() {
    return {
      file: null,
      previewDataUrl: '',
      previewVisible: false,
    }
  },

  computed: {
    fileName() {
      return this.file?.name || this.hub.prefill?.originalName || ''
    },

    previewComputedSrc() {
      return this.previewDataUrl || this.hub.prefill?.thumbnailUrl
    },

    stateIcon() {
      if (this.hub.uploadSuccess) return 'check'
      if (this.hub.uploadFailed) return 'exclamation'
      if (this.hub.prefill) return 'check'

      return 'exclamation'
    },

    showStateIcon() {
      return this.hub.hasFileFilledOrState && !this.hub.uploading
    },

    payloadToEmit() {
      const { hub, fileName, previewComputedSrc } = this
      return {
        originalName: fileName,
        ratio: hub.ratio,
        previewUrl: previewComputedSrc,
      }
    },
  },

  created() {
    this.hubHooksRegister()
  },

  methods: {
    hubHooksRegister() {
      this.hub.$on('change', fileList => {
        this.file = fileList[0]
      })

      this.hub.addCheckPoint(this.ratioCheck)

      this.hub.$on('_reset', this.reset)
    },

    previewImgOrVideo() {
      this.previewDataUrl = ''
      this.previewVisible = false

      return new Promise((resolve, reject) => {
        if (!this.file || !window.FileReader) return

        const fileType = this.file.type

        if (/^image/.test(fileType)) {
          // Resolve this promise when new image loaded
          this.hub.addListener(this.$refs.img, 'load', resolve, {
            once: true,
          })

          this.hub.addListener(this.$refs.img, 'error', reject, {
            once: true,
          })

          let reader = new FileReader()
          reader.readAsDataURL(this.file)
          // Async action warning
          reader.onloadend = ev => {
            this.previewDataUrl = ev.target.result
            this.previewVisible = true
          }
        } else if (/^video/.test(fileType)) {
          // TODO: R2 video preview
          // ref: https://stackoverflow.com/questions/36035721/how-can-i-set-preview-of-video-file-selecting-from-input-type-file/40580663
        }
      })
    },

    /**
     * Check ratio need img to be mounted completely,
     * so the check will be asynchronous
     */
    async ratioCheck(files, addError) {
      try {
        await this.previewImgOrVideo()
      } catch (err) {
        addError(
          'Image or Video render failed ! Try to check if file is corrupted.'
        )
        return false
      }

      const { width, height } = this.$refs.img
      // eslint-disable-next-line
      let [_, ratioW, ratioH] = this.hub.AspectRatioConfig.REG.exec(
        this.hub.numberRatio
      )
      ratioW = Number(ratioW)
      ratioH = Number(ratioH)

      const realRatio = width / height
      const expectedRaio = ratioW / ratioH

      const isImgRatioValid = Math.abs(realRatio - expectedRaio) <= 0.05

      if (!isImgRatioValid) {
        addError('Unexpected ratio')
      }

      return isImgRatioValid
    },

    reset() {
      this.previewDataUrl = false
    },
  },
}
</script>
