import Vue from 'vue'
import { ENV_MODE } from '@constants'
import router from '@router'
import store from '@state/store'
import { vuetify } from '@plugins/vuetify'
import { usePlugins } from '@plugins'
import dispatchActionForAllModules from '@state/utils/dispatch-action-for-all-modules'
// We need to disable the next line or else it'll throw 'url.parse' was deprecated since v11.0.0. Use 'url.URL' constructor instead
// For some reason it's seeing the built in url browser type instead and not the imported url lib
// eslint-disable-next-line
import { parse } from 'url'

import App from './app'
import '@components/_globals'

// Install all Vue  & other plugins
const plugins = usePlugins()

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === ENV_MODE.PROD

// If running inside Cypress...
if (window.Cypress) {
  // Ensure tests fail when Vue emits an error.
  Vue.config.errorHandler = window.Cypress.cy.onUncaughtException
}

let app = new Vue({
  router,
  store,
  vuetify,
  apolloProvider: plugins.graphQLProvider,
  created() {
    const parsedUrl = parse(window.location.href, true)
    // Dispatch all modules if LoggedIn or Mocked
    // Run the `init` action for every store module, if one exists.
    // Do not dispatch if the SSO auth code is provided.
    if (
      (store.getters['auth/loggedIn'] && !parsedUrl.query.code) ||
      process.env.VUE_APP_USE_MOCKED_DATA === 'true'
    ) {
      dispatchActionForAllModules('init')
    }
  },
  render: h => h(App),
}).$mount('#app')

// If running inside Cypress...
if (window.Cypress) {
  // Attach the app to the window, which can be useful
  // for manually setting state in Cypress commands
  // such as `cy.logIn()`.
  window.__app__ = app
}

export default app
