// import store from '@state/store'
// import gql from 'graphql-tag'
import {
  removeOrgMemberMutation,
  replaceOrgMemberOwnershipMutation,
} from '@components/members/membersAPI'
import { getOperationName } from '../../utils'

const handleRemoveOrgMember = async operation => {
  if (operation.variables.confirmInput) {
    return {
      title: 'Are you absolutely sure?',
      content: `
        <div>
          <p>Are you sure you want to delete <strong>${operation.variables.confirmInput.userName}</strong> from <strong>${operation.variables.confirmInput.orgName}</strong></p>
          <p><strong>This action cannot be undone.</strong></p>
        </div>
      `,
      confirmInput: operation.variables.confirmInput.userEmail,
    }
  }
}

const handleReplaceOrgMemberOwnership = async operation => {
  let text = `
    <div>
      <p>You will be transferring <strong>ownership</strong> of your account to <strong>${operation.variables.email}</strong>.</p>
      <p>Your account will <strong>lose</strong> the <strong>owner</strong> role and be replaced with the <strong>admin</strong> role.</p>
      <p>You will <strong>not</strong> be able to regain ownership unless <strong>${operation.variables.email}</strong> transfers the ownership back to you.</p>
    </div>
  `

  if (Object.keys(operation.variables.isSA).length > 0) {
    const data = operation.variables.isSA

    text = `
      <div>
        <p>You will be transferring the <strong>ownership</strong> of <strong>${data.orgName}</strong> to <strong>${data.toEmail}</strong>.</p>
        <p>The current owner of <strong>${data.orgName}</strong>, ${data.fromEmail}, will <strong>lose</strong> the <strong>owner</strong> role and be replaced with the <strong>admin</strong> role.</p>
      </div>
    `
  }

  return {
    title: 'Are you absolutely sure?',
    content: text,
    confirmInput: operation.variables.email,
  }
}

const getGuards = () => {
  const out = {
    [getOperationName(removeOrgMemberMutation)]: handleRemoveOrgMember,
    [getOperationName(replaceOrgMemberOwnershipMutation)]:
      handleReplaceOrgMemberOwnership,
  }

  return out
}

export const guards = {
  ...getGuards(),
}
