<script>
import { convertToUnit } from '@utils'
import FpIcon from '@components/_base/icon'
import useSize from '@component-mixins/useSize'
import useIcon from '@component-mixins/useIcon'
import useDisabled from '@component-mixins/useDisabled'

export default {
  name: 'FpIconButton',

  components: {
    FpIcon,
  },

  mixins: [useSize, useIcon, useDisabled],

  inheritAttrs: false,

  props: {
    // Modifiers
    type: {
      default: 'inherit',
      type: String,
    },

    iconOffsetX: {
      type: Number,
      default: 0,
    },

    iconOffsetY: {
      type: Number,
      default: 0,
    },

    // States
    loading: Boolean,

    tooltip: {
      type: String,
      default: '',
    },
    // link for the vue router
    link: {
      type: [String, Object],
      default: '',
    },
    // href for the external link
    href: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '1x',
    },
  },

  computed: {
    finalIcon() {
      return this.icon
    },

    iconOffset() {
      const { iconOffsetX, iconOffsetY } = this
      return {
        transform: `translate(${convertToUnit(iconOffsetX)}, ${convertToUnit(
          iconOffsetY
        )})`,
        pointerEvents: 'none',
      }
    },
  },

  methods: {
    clickHandler(ev) {
      if (this.disabled || this.loading) return

      if (this.link) {
        return this.$router.push(this.link)
      }

      if (this.href) {
        const a = document.createElement('a')

        a.href = this.href
        a.target = '_blank'

        a.click()

        a.remove()

        return
      }

      this.$emit('click', ev)
    },
  },
}
</script>

<template>
  <i v-bem:wrapper>
    <v-tooltip top v-bind="$attrs">
      <template v-slot:activator="{ on }">
        <div
          v-ripple
          v-bem="{ disabled, loading, $size, $type: type }"
          v-bind="$attrs"
          @keyup.enter.stop="clickHandler"
          v-on="tooltip ? on : null"
          @click="clickHandler"
        >
          <fp-icon :style="iconOffset" :name="finalIcon" />

          <svg
            v-if="loading"
            class="fp-icon-button__spinner"
            viewBox="22 22 44 44"
          >
            <circle
              class="fp-icon-button__spinner-path"
              cx="44"
              cy="44"
              r="20"
              fill="none"
              stroke-width="2"
              stroke="#0047ff"
              stroke-linecap="round"
            />
          </svg>
        </div>
      </template>

      <div v-if="tooltip" v-bem:tooltip>
        {{ tooltip }}
      </div>
    </v-tooltip>
  </i>
</template>
