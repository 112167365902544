import gql from 'graphql-tag'
import { contact } from '../../user/fragments/contact'

export const getClients = gql`
  query getClients(
    $ids: [ID]
    $limit: Int
    $skip: Int
    $orderBy: DefaultOrderByEnum
    $filter: ClientFiltersInput
  ) {
    # Logged In User Account
    getClients(
      ids: $ids
      limit: $limit
      skip: $skip
      orderBy: $orderBy
      filter: $filter
    ) {
      skip
      limit
      total
      items {
        contact {
          ...contact
        }
        businessNumber
        industry

        id
        title #company
        dateUpdated
        dateCreated
        org
        archived
      }
    }
  }
  ${contact}
`

export const getClientList = gql`
  query getClients(
    $ids: [ID]
    $limit: Int
    $skip: Int
    $orderBy: DefaultOrderByEnum
    $filter: ClientFiltersInput
  ) {
    # Logged In User Account
    getClients(
      ids: $ids
      limit: $limit
      skip: $skip
      orderBy: $orderBy
      filter: $filter
    ) {
      items {
        id
        title #company
      }
      limit
      skip
      total
    }
  }
`
