import { convertToUnit } from '@utils'
const SIZES_ENUM = ['small', 'medium', 'normal', 'large']

export default {
  props: {
    size: {
      default: 'normal',
      type: String,
      validator(v) {
        return SIZES_ENUM.includes(v)
      },
    },

    width: {
      type: [String, Number],
      default: '',
    },

    height: {
      type: [String, Number],
      default: '',
    },

    fill: { type: Boolean },
  },

  computed: {
    finalWidth() {
      if (this.fill) return '100%'

      return convertToUnit(this.width)
    },

    finalHeight() {
      return convertToUnit(this.height)
    },

    sizeStyle() {
      return {
        width: this.finalWidth,
        height: this.finalHeight,
      }
    },

    sizeAsCSSVars() {
      return {
        '--width': this.finalWidth,
        '--height': this.finalHeight,
      }
    },

    $size() {
      return this.size
    },
  },
}
