<script>
const MATCH = 'match'

const passRules = [
  {
    text: 'contain a lower case letter',
    test(pass) {
      return pass && /[a-z]/.test(pass)
    },
  },
  {
    text: 'contain a upper case letter',
    test(pass) {
      return pass && /[A-Z]/.test(pass)
    },
  },
  {
    text: 'contain at least one number',
    test(pass) {
      return pass && /\d/.test(pass)
    },
  },
  {
    text: 'contain at least 8 characters',
    test(pass) {
      return pass && pass.length >= 8
    },
  },
  {
    id: MATCH,
    text: 'passwords match exactly',
    test(pass, passConfirm) {
      return pass === passConfirm && pass && passConfirm
    },
  },
]

export default {
  name: 'FpPassInput',

  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: 'Password',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: null,
    },
    validationRequired: {
      type: Boolean,
      default: true,
    },
    confirm: {
      type: Boolean,
      default: true,
    },
    alwaysShowRules: Boolean,
  },

  data() {
    return {
      passVisible: false,
      pass: null,
      passConfirm: null,
      isFocused: false,
    }
  },

  computed: {
    rules() {
      let _passRules = passRules
      if (!this.confirm) {
        _passRules = passRules.filter(r => r.id !== MATCH)
      }
      return _passRules.map(rule => [
        rule.text,
        rule.test(this.pass, this.passConfirm),
      ])
    },
    type() {
      return this.passVisible ? 'text' : 'password'
    },
    isValidPass() {
      return !this.rules.some(([, done]) => !done)
    },
  },

  watch: {
    pass(value) {
      this.handleInputEmitter(value)
    },
    passConfirm(value) {
      this.handleInputEmitter(value)
    },
  },

  methods: {
    handleInputEmitter(value) {
      if (this.validationRequired) {
        this.$emit('input', this.isValidPass ? value : null)
      } else {
        this.$emit('input', value)
      }
    },
    handleIconClick() {
      this.passVisible = !this.passVisible
    },
  },
}
</script>

<template>
  <div v-bem="{ showRules: pass || alwaysShowRules }">
    <fp-input
      v-model="pass"
      :label="label"
      :placeholder="placeholder"
      :type="type"
      :append-icon="passVisible ? 'eye-slash' : 'eye'"
      data-cy="password-input-reset"
      icon="lock"
      v-bind="$attrs"
      v-on="$listeners"
      @focus="ev => (isFocused = true)"
      @blur="ev => (isFocused = false)"
      @click:append="handleIconClick"
    />

    <fp-input
      v-if="confirm"
      v-model="passConfirm"
      :label="label + ' confirm'"
      :placeholder="placeholder"
      :type="type"
      :append-icon="passVisible ? 'eye-slash' : 'eye'"
      data-cy="password-input-confirm"
      icon="lock"
      v-bind="$attrs"
      v-on="$listeners"
      @focus="ev => (isFocused = true)"
      @blur="ev => (isFocused = false)"
      @click:append="handleIconClick"
    />

    <ul v-bem:rules>
      <li
        v-for="([rule, done], index) in rules"
        :key="index"
        v-bem:rule="{ done }"
      >
        <span v-bem:icon>
          <fp-icon name="check" />
        </span>
        {{ rule }}
      </li>
    </ul>
  </div>
</template>

<style lang="scss" src="@component-styles/pass-input"></style>
