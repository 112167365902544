import Vue from 'vue'
// @ts-ignore
import MessageDialog from '@components/advanced-dialog/message-dialog'
import { SelectOption } from '@components/_base/select/types'

import router from '@src/router'
import { html } from '@src/shared/string/intellisense'
import app from '@src/main'
import { TextAlign } from 'types'

declare module 'vue/types/vue' {
  interface Vue {
    $confirm: (options: ConfirmOptions) => void
    $prompt: (options: PromptOptions) => void
    $message: (options: MessageOptions) => void
  }
}

type BasicOptions = {
  title: string | JSX.Element
  content: string | JSX.Element
  confirmText: string
  cancelText: string
  textAlign: TextAlign
  onCancel: () => any
  confirmInput: Boolean

  // * v-dialog options
  // Clicking outside of the element will not deactivate it if true,
  // ⚠️ which means this dialog is interact required
  persistent: boolean
}

type ConfirmOptions = Partial<BasicOptions> & {
  onConfirm?: ({ hide: boolean }) => any
}

type PromptOptions = Partial<BasicOptions> & {
  placeholder?: string
  label?: string
  initialValue?: string
  required?: boolean
  promptOptions?: Array<SelectOption>
  promptOptionCustomInputValue?: string
  onConfirm?: ({
    userInput,
    toggleLoading,
  }: {
    userInput: string
    toggleLoading: (val: boolean) => void
  }) => any
}

type MessageOptions = {
  title?: string
  content: string
  textAlign?: TextAlign
}

// Prompt usage
// this.$prompt({
//   title: `You'll need to verify your account first`,
//   content: html`
//     <p>
//       You cannot perform this action until your account has been verified which
//       could take up to <strong>24 hours.</strong>
//     </p>
//   `,
//   placeholder: 'eg. http://xxxxxx.gg',
//   confirmText: 'Verify account',
//   onConfirm: async val => {
//     const valid = await validate(val)
//     await sleep(3000)

//     return valid
//   },
//   cancelText: "I'll do this later",
// })

function Prompt(options: PromptOptions) {
  const msgDialog = new (Vue.extend(MessageDialog))({
    propsData: {
      isPrompt: true,
      title: options.title,
      content: options.content,
      required: options.required,
      initialValue: options.initialValue,
      placeholder: options.placeholder,
      label: options.label,
      yesText: options.confirmText,
      nopeText: options.cancelText,
      textAlign: options.textAlign,
      promptOptions: options.promptOptions,
      promptOptionCustomInputValue: options.promptOptionCustomInputValue,
      persistent: true,

      async onYes({ hide, userInput, toggleLoading }) {
        const result = await options.onConfirm?.({
          userInput,
          toggleLoading,
        })

        if (result === false) return

        hide()
      },

      async onNope({ hide }) {
        await options.onCancel?.()
        hide()
      },
    },
    parent: app,
  })

  msgDialog.$mount()
  ;(msgDialog as any).show()
}

function Confirm(options: ConfirmOptions) {
  const msgDialog = new (Vue.extend(MessageDialog))({
    propsData: {
      title: options.title,
      content: options.content,
      yesText: options.confirmText,
      nopeText: options.cancelText,
      textAlign: options.textAlign,
      persistent: true,
      confirmInput: options.confirmInput,

      async onYes({ hide }) {
        await options.onConfirm?.({ hide })
        hide()
      },

      async onNope({ hide }) {
        await options.onCancel?.()
        hide()
      },
    },
    parent: app,
  })

  msgDialog.$mount()
  ;(msgDialog as any).show()
}

function Message(options: MessageOptions) {
  const msgDialog = new (Vue.extend(MessageDialog))({
    propsData: {
      title: options.title,
      content: options.content,
      textAlign: options.textAlign,
      persistent: false,
    },
    parent: app,
  })

  msgDialog.$mount()
  ;(msgDialog as any).show()
}

export default function useMessageModal() {
  Vue.use({
    install(Vue) {
      Vue.prototype.$confirm = Confirm

      Vue.prototype.$message = Message

      Vue.prototype.$prompt = Prompt

      // * ------- Some convient presets ---------
      Vue.prototype.$confirm.verifyYourAccount = (options: any = {}) => {
        Confirm.call(Vue, {
          title: `You'll need to verify your account first`,
          content: html`
            <p>
              You cannot perform this action until your account has been
              verified which could take up to <strong>24 hours.</strong>
            </p>
          `,
          confirmText: 'Verify account',
          onConfirm: () => {
            router.push('/verification')
          },
          onCancel: options?.onCancel,
          cancelText: "I'll do this later",
        })
      }
    },
  })
}
