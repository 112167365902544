<script>
import FpIcon from '@components/_base/icon'

const TYPES = ['sm', 'md', 'lg', '']

export default {
  name: 'FpSwitch',

  components: {
    FpIcon,
  },

  inheritAttrs: false,

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    disabled: Boolean,

    loading: Boolean,

    title: {
      type: String,
      required: false,
      default: '',
    },

    label: {
      type: String,
      required: false,
      default: '',
    },

    hint: {
      type: String,
      default: '',
    },

    trueText: {
      type: String,
      default: 'YES',
    },

    falseText: {
      type: String,
      default: 'NO',
    },

    size: {
      type: String,
      default: '',
      validator(val) {
        return TYPES.includes(val)
      },
    },
  },

  computed: {
    iconName() {
      if (this.disabled) return 'ban'
      return this.value ? 'check' : 'times'
    },
    text() {
      return this.value ? this.trueText : this.falseText
    },
  },

  methods: {
    handleClick() {
      if (this.disabled) return
      const nextValue = !this.value
      this.$emit('input', nextValue)
      this.$emit('change', nextValue)
    },
  },
}
</script>

<template>
  <div>
    <h6 v-if="title" v-bem:title>
      {{ title }}
    </h6>
    <label v-bem:label>
      {{ label }}
    </label>

    <small v-if="hint" v-bem:hint>
      {{ hint }}
    </small>

    <div
      v-bem="{ active: value, disabled, $size: size }"
      v-bind="$attrs"
      @click="handleClick"
      @keyup.enter="handleClick"
    >
      <div v-bem:tracker>
        <div v-bem:slider>
          <transition name="fade" mode="out-in">
            <fp-icon v-if="loading" name="spinner" spin />
            <fp-icon v-else-if="value && !loading" name="check" />
            <fp-icon v-else name="times" />
          </transition>
        </div>
      </div>
      <div v-bem:text>
        {{ text }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="@component-styles/switch"></style>
