// @ts-ignore

import _capitalize from 'lodash/capitalize'
import _toLower from 'lodash/toLower'
import _upperFirst from 'lodash/upperFirst'
import _startCase from 'lodash/startCase'
import _lowerCase from 'lodash/lowerCase'
import _isString from 'lodash/isString'
import truncate from 'lodash/truncate'
import trim from 'lodash/trim'

const _isAbsoluteUrl = require('is-absolute-url')

// initials of first and last name
// const getInitials = value => {
//   if (!value) return ''
//   const toks = value.split(' ')

//   if (toks.length === 1) return toks[0][0]

//   const fl = toks[0] + ' ' + toks[toks.length - 1]

//   const initials = fl
//     .split(' ')
//     .map(n => n[0])
//     .join('')
//   return initials.toUpperCase()
// }

const getInitials = (firstName, lastName) => {
  const first = firstName && firstName.length ? firstName : ['']
  const last = lastName && lastName.length ? lastName : ['']

  return capitalize(first[0]) + capitalize(last[0])
}

function hasAlphabet(str) {
  if (!str) return false
  var regExp = /[a-zA-Z]/g
  return regExp.test(str)
}

export function firstUpper(value) {
  if (!value) return ''
  return _upperFirst(value)
}

export function isAbsoluteUrl(value) {
  return _isAbsoluteUrl(value)
}

export function splitCamel(value) {
  if (!value) return ''
  let str = value
  str = str.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, '$1 $2')
  return str
}
export function replaceAll(value, searchvalue, newvalue) {
  if (!value || typeof value !== 'string') return value

  const re = new RegExp(searchvalue, 'g')
  return value.replace(re, newvalue)
}

// Converts the first character of string to upper case and the remaining to lower case.
// => 'Fred'
export function capitalize(value) {
  return _capitalize(value)
}

export function lowerCase(value) {
  return _lowerCase(value)
}

export function startCase(value) {
  return _startCase(value)
}

export function toLower(value) {
  return _toLower(value)
}

export function isString(value) {
  return _isString(value)
}

export function startCaseAllUppercase(upperCase) {
  return _startCase(_capitalize(upperCase))
}

/**
 * sorts array of string or array of object 1 level deep where prop is string.
 * key is required if items are array of objects
 */
export function sortString<T>(
  items: string[] | T[],
  order: 'asc' | 'desc' = 'desc',
  key: string = ''
) {
  const localeSort = items.sort((a, b) => {
    const locales = ['en']
    const options = { sensitivity: 'base' }

    const _a = key ? a[key] : a
    const _b = key ? b[key] : b

    if (order === 'asc') {
      return _a.localeCompare(_b, locales, options)
    }
    return _b.localeCompare(_a, locales, options)
  })

  return localeSort
}

/* WARNING: this past tense function is not perfect. Please add
each word used to the corresponding unit test to check. Add it to
the exceptions object inside the function if required.
Todo: find a more reliable solution that is not too heavy with the
bundle size.
 */
export function toPastUnreliable(input, defaultValue = '') {
  if (!input) return defaultValue
  // language exceptions
  const exceptions = {
    are: 'were',
    eat: 'ate',
    go: 'went',
    have: 'had',
    inherit: 'inherited',
    is: 'was',
    run: 'ran',
    sit: 'sat',
    visit: 'visited',
    edit: 'edited',
    copy: 'copied',
    review: 'requested review of',
  }

  // grammatically predictable rules
  function getPastTense(verb) {
    if (exceptions[verb]) {
      return exceptions[verb]
    }
    if (/e$/i.test(verb)) {
      return verb + 'd'
    }
    if (/[aeiou]c$/i.test(verb)) {
      return verb + 'ked'
    }
    // for american english only
    if (/el$/i.test(verb)) {
      return verb + 'ed'
    }
    if (/[aeio][aeiou][dlmnprst]$/.test(verb)) {
      return verb + 'ed'
    }
    if (/[aeiou][bdglmnprst]$/i.test(verb)) {
      return verb.replace(/(.+[aeiou])([bdglmnprst])/, '$1$2$2ed')
    }
    return verb + 'ed'
  }

  return getPastTense(input)

  // https://gist.github.com/letsgetrandy/1e05a68ea74ba6736eb5
}

// since we only have a few words that needs the gerund english form,
// we use a fake conversion util. If the use case gets big, we could use
// a proper nlp library like https://github.com/FinNLP/en-inflectors
// or https://github.com/spencermountain/compromise but these
// are usualle big in bundle size
export function toGerundUnreliable(verb) {
  if (!verb) return ''

  const ing = 'ing'

  let out = verb
  const lastChar = verb.slice(-1).toLowerCase()

  // roughly following this guide http://www.english-for-students.com/How-to-convert-a-VERB-into-a-GERUND.html

  // If the verb ends in E, remove the E and add ING.
  if (lastChar === 'e') {
    out = out.slice(0, -1) + ing
  }

  // other rules left unimplemented. add on a per need basis, hence the name toGerundUnreliable

  return out
}

// a or an before a word. E.g. a game, an organisation
// http://www.butte.edu/departments/cas/tipsheets/grammar/articles.html
// this does not support plurals, e.g. the games
// only works with a set of known words, add more words on a per need basis.
// this method is to save bundle size. using a bigger library to transform
// 2 words in the whole app is overkill
export const articlizeUnreliable = value => {
  if (!value) return ''

  const anList = ['a', 'e', 'i', 'o', 'u']

  let out = value
  const firstChar = out.trim().charAt(0).toLowerCase()
  if (anList.includes(firstChar)) {
    out = 'an ' + out
  } else {
    out = 'a ' + out
  }

  return out
}

function isUrl(s) {
  var regexp =
    /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
  return regexp.test(s)
}

function substring(s, start, end?: number) {
  return s.substring(start, end)
}
export { truncate, isUrl, hasAlphabet, getInitials, trim, substring }
