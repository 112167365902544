import { actions, getters, mutations } from './org'
import store from '../store'

type Actions = keyof typeof actions
type Computations = keyof typeof getters
type Mutations = keyof typeof mutations

export function OrgDispatch(action: Actions, payload: any) {
  return store.dispatch(`org/${action}`, payload)
}

export function OrgCommit(mutation: Mutations, payload: any) {
  return store.commit(`org/${mutation}`, payload)
}

export function OrgGetters(key: Computations) {
  return store.getters[`org/${key}`]
}
