<template>
  <div v-bem:file>
    <div v-bem:label="{ failed: hub.uploadFailed }">
      <div v-if="!hub.hasFile" v-bem:label-text>
        {{ hub.labelToDisplay }}
      </div>

      <div v-if="hub.hasFile" v-bem:filename>
        <fp-icon size="lg" :name="fileTypeIcon" />
        {{ fileName }}
      </div>

      <div v-bem:label-tip>
        <slot name="tip" />
        <span>
          Drag file here or click to {{ hub.hasFile ? 'change' : 'upload' }}
          <fp-icon v-if="!hub.hasFile" name="upload" />
        </span>
        <small>
          (max: {{ (hub.maxSize / 1024).toFixed(1) }}mb)
          {{ hub.accept }}
        </small>
      </div>
    </div>

    <!-- Success or Fail icon -->
    <transition name="fade-slow">
      <div v-if="showStateIcon" v-bem:state-icon>
        <fp-icon class="state" :name="stateIcon" />
      </div>
    </transition>
  </div>
</template>

<script>
import { getFileExtension } from './index.vue'
export default {
  // Tell BEM plugin this component use fp-upload as blockName
  blockName: 'FpUpload',

  name: 'FpFileUpload',

  inject: ['hub'],

  data() {
    return {
      file: null,
    }
  },

  computed: {
    fileName() {
      return this.file?.name || this.hub.prefill?.originalName || ''
    },

    fileExtension() {
      return getFileExtension(this.fileName)
    },

    fileTypeIcon() {
      return (
        {
          '.docx': 'file-word',
          '.doc': 'file-word',
          '.pdf': 'file-pdf',
        }[this.fileExtension] || 'file-alt'
      )
    },

    stateIcon() {
      if (this.hub.uploadSuccess) return 'check-circle'
      if (this.hub.uploadFailed) return 'exclamation-circle'
      if (this.hub.prefill) return 'check-circle'

      return null
    },

    showStateIcon() {
      return (
        this.hub.hasFileFilledOrState && !this.hub.uploading && this.stateIcon
      )
    },

    payloadToEmit() {
      const { hub, fileName } = this
      return {
        originalName: fileName,
        fileKey: hub.name,
      }
    },
  },

  created() {
    this.hubHooksRegister()
  },

  methods: {
    hubHooksRegister() {
      this.hub.$on('change', fileList => {
        this.file = fileList[0]
      })
    },
  },
}
</script>
