import { CreateAccountInput, OrgTypeEnum, ContactInput } from 'typedefs'
import store from '../store'
import { Overwrite, ValuesType } from 'utility-types'
import Vue from 'vue'

export type AccountType = 'advertise' | 'build' | null
export type OrgType = OrgTypeEnum.AGENCY | OrgTypeEnum.ADVERTISER | null
export type Student = 'y' | 'n' | null

export type State = Overwrite<
  CreateAccountInput,
  {
    orgType: OrgType
    contact: ContactInput
  }
> & {
  accountType: AccountType
  isStudent: Student
}

export const state: State = {
  accountType: null,
  orgType: null,
  isStudent: null,
  student: { institution: '', studentID: '' },
  title: '',
  contact: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    jobTitle: '',
    timezone: '',
  },
}

export const getters = {
  state(): State {
    return state
  },
}

export const mutations = {
  SET_STATE(state, { key, value }) {
    Vue.set(state, key, value)
  },
}

export const actions = {
  setState({ commit }, { key, value }) {
    commit('SET_STATE', { key, value })
  },
}

type ActionKeys = keyof typeof actions
type DispatchPayload = {
  key: keyof State
  value: ValuesType<State>
}

const MODULE_KEY = 'onboard'

export function dispatch(action: ActionKeys, payload: DispatchPayload) {
  return store.dispatch(`${MODULE_KEY}/${action}`, payload)
}

export function getState(): State {
  const rv = store.getters[`${MODULE_KEY}/state`]
  return rv
}
