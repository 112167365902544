import gql from 'graphql-tag'
import {
  reportingStatsCharts,
  reportingStatsCard,
  dashRangeMetrics,
  dashTables,
} from '../fragments'

export const getReportingStats = gql`
  query getReportingStats($input: ReportingStatsQueryInput) {
    getReportingStats(input: $input) {
      charts {
        ...reportingStatsCharts
      }
      cards {
        ...reportingStatsCard
      }
    }
  }
  ${reportingStatsCharts}
  ${reportingStatsCard}
`
// TODO - to be removed after enabling reporting dashboard for other user types
export const getDashboard = gql`
  query getDashboard($filter: DashboardFiltersInput) {
    getDashboard(filter: $filter) {
      rangeMetrics {
        ...dashRangeMetrics
      }
      tables {
        ...dashTables
      }
    }
  }
  ${dashRangeMetrics}
  ${dashTables}
`
