<script>
export default {
  skipGloballyRegister: true,

  name: 'BottomNav',

  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      height: 100,
    }
  },

  created() {
    if (window.innerHeight < 830) {
      this.height = 74
    }
  },
}
</script>

<template>
  <v-bottom-navigation
    :class="$style.container"
    class="text-center"
    :value="true"
    :height="height"
    fixed
  >
    <div :class="$style.slotBox">
      <slot />
    </div>
  </v-bottom-navigation>
</template>

<style lang="scss" module>
@import '@scss';

.container.container {
  z-index: 6;
  border-top: 1px solid $color-disabled;
  box-shadow: none;
}

.slotBox {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 24px;
}
</style>
