// ACCOUNT_MANAGER
// ADMIN
// OWNER
// FINANCE
// REPORTING
// DEVELOPER
// FULL_ACCESS

// check in RDS role_permissions

const DEFAULT_VALUE = {
  text: 'UTC',
  value: 'UTC',
}

export const gameDevRoles = {
  default: DEFAULT_VALUE,
  data: [
    {
      text: 'Account Manager',
      value: 'GAME_PUBLISHER:ACCOUNT_MANAGER',
    },
    {
      text: 'Admin',
      value: 'GAME_PUBLISHER:ADMIN',
    },
    // {
    //   text: 'Owner',
    //   value: 'GAME_PUBLISHER:OWNER',
    // },
    // {
    //   text: 'Full Access',
    //   value: 'FULL_ACCESS',
    // },
    {
      text: 'Developer',
      value: 'GAME_PUBLISHER:DEVELOPER',
    },
    {
      text: 'Finance',
      value: 'GAME_PUBLISHER:FINANCE',
    },

    {
      text: 'Reporting',
      value: 'GAME_PUBLISHER:REPORTING',
    },
  ],
}

export const ROLE_TEXT = {
  DEVELOPER: 'Developer',
  FINANCE: 'Finance',
  REPORTING: 'Reporting',
  ADMIN: 'Admin',
  ACCOUNT_MANAGER: 'Account Manager',
  OWNER: 'Owner',
  FULL_ACCESS: 'Full Access',
}

export const roleDescription = {
  'ADVERTISER:ACCOUNT_MANAGER':
    'The Account Manager role allows managing the organisation’s ad campaigns, as well as being able to view statistics on their performance & reach.',

  'GAME_PUBLISHER:ACCOUNT_MANAGER':
    'The Account Manager role allows managing the organisation’s games, as well as being able to view statistics on their performance. It does not allow access to a game’s API key.',
  'GAME_PUBLISHER:ADMIN':
    'The Administrator role provides control over the organisation’s settings, including user management of the organisation’s team, as well as full access to the organisation’s games.',
  'GAME_PUBLISHER:OWNER':
    'The Owner role allows full access to the information of an organisation.',
  'GAME_PUBLISHER:DEVELOPER':
    'The Developer role allows full access to the organisation’s games, including a game’s API key.',
  'GAME_PUBLISHER:FINANCE':
    'The Finance role provides full access to the organisation’s financial settings.',
  'GAME_PUBLISHER:REPORTING':
    'The Reporting role allows access to statistics on the performance of the organisation’s games.',

  'SUPER_ADMIN:FULL_ACCESS':
    'The Full Access role allows the Super Admin to manage all of the affairs of an organisation, with the exception of updating organsation.',
}
