// import store from '@state/store'
// import gql from 'graphql-tag'
import * as m from '../mutations'
// import * as confirmItemUpdate from '@shared/ui/templates/confirmItemUpdate'
import { getOperationName } from '../../utils'
import { BUSINESS_TYPES, BUSINESS_ACTIONS, ORG_VERIFY_STATUS } from '@constants'
import {
  handleSensitiveInfoChange,
  handleVerificationStatus,
} from '../../shared/guards'

// const getUpdateCondition = async variables => {
//   const docOfTruth = gql`
//     fragment updateAdGuardedFields on Ad {
//       adRestrictions
//       ratings
//       adImages {
//         url
//       }
//     }
//   `
//   const currentInput = await store.getters[`ads/adForm`]
//   const out = deepEqualByDoc(variables.input, currentInput, docOfTruth)
//   return out
// }

// const handleUpdateAd = async operation => {
//   const { variables } = operation
//   const condition = await getUpdateCondition(variables)

//   if (condition)
//     return {
//       title: confirmItemUpdate.title({ type: BUSINESS_TYPES.AD }),
//       content: confirmItemUpdate.content({
//         type: BUSINESS_TYPES.AD,
//         labels: ['ESRB Ratings', 'Restricted Content', 'Creative'],
//       }),
//     }
// }

const handlePauseAd = async operation => {
  const unverified = await handleVerificationStatus(
    ORG_VERIFY_STATUS.UNVERIFIED
  )
  if (unverified) return unverified

  return handleSensitiveInfoChange({
    operation,
    type: BUSINESS_TYPES.AD,
    action: BUSINESS_ACTIONS.PAUSE,
  })
}

const getGuards = () => {
  const out = {
    // [getOperationName(m.updateAd)]: handleUpdateAd,
    [getOperationName(m.requestAdReviews)]: () =>
      handleVerificationStatus(ORG_VERIFY_STATUS.UNVERIFIED),
    [getOperationName(m.unpauseAds)]: () =>
      handleVerificationStatus(ORG_VERIFY_STATUS.UNVERIFIED),
    [getOperationName(m.pauseAds)]: handlePauseAd,
    [getOperationName(m.archiveAds)]: operation =>
      handleSensitiveInfoChange({
        operation,
        type: BUSINESS_TYPES.AD,
        action: BUSINESS_ACTIONS.ARCHIVE,
      }),
  }

  return out
}

export const guards = {
  ...getGuards(),
}
