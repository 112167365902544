import {
  createComponent,
  reactive,
  getCurrentInstance,
  computed,
} from '@vue/composition-api'
import { chunkArray } from '@utils'

import style from './page-head.module.scss'

export default createComponent({
  name: 'PageHead',

  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },

    mobileFilterLimit: {
      type: Number,
      default: 2,
    },
  },

  setup(props, { root }) {
    const state = reactive({
      showAllFilters: false,
    })

    const _this = getCurrentInstance()!

    const isMobile = computed(() => root.$vuetify.breakpoint.xs)

    return () => {
      const filtersSlot = _this.$slots.filters

      const archiverSlot = _this.$slots.archiver
      const actionsSlot = _this.$slots.actions

      const { mobileFilterLimit } = props

      let FilterItems, santisedFiltersSlot, ChunkedFilterItems

      if (filtersSlot) {
        // 1️⃣ If not mobile, show all defaultly
        // 2️⃣ If mobile, show limited filter items defaultly
        // First filter removes slotted v-node elements with falsy v-if statements
        santisedFiltersSlot = filtersSlot.filter(_ => _.tag !== undefined)

        FilterItems =
          isMobile.value && !state.showAllFilters
            ? santisedFiltersSlot.filter(
                (_, index) => index <= mobileFilterLimit - 1
              )
            : filtersSlot

        ChunkedFilterItems = chunkArray(FilterItems, 4)
      }

      return (
        <portal to="pageHeadPortal" class={style.container}>
          <v-container fluid class="px-0">
            {' '}
            <v-row align="center" class="pb-sm-0">
              {/* TITLE */}
              {props.title && (
                <v-col
                  style={{ flexGrow: 0 }}
                  cols={isMobile.value ? 12 : null}
                >
                  <h1 class={style.title}>{props.title}</h1>
                </v-col>
              )}

              {/* ARCHIVER & ACTIONS */}
              <v-col role="archiver-actions" class={'py-0'}>
                <v-container fluid class="px-0">
                  {' '}
                  <v-row align="center" justify="space-between">
                    {archiverSlot && (
                      <v-col cols={isMobile.value ? 12 : null}>
                        {[...archiverSlot]}
                      </v-col>
                    )}

                    <v-spacer />

                    {!isMobile.value && (
                      <div>{actionsSlot && [...actionsSlot]}</div>
                    )}
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>

          {/* FILTERS */}
          <div role="filters" align="center" class={style.filters}>
            {ChunkedFilterItems &&
              ChunkedFilterItems.map(o => {
                return (
                  <v-container fluid class="px-0">
                    {' '}
                    <v-row class="ma-0">{[...o]}</v-row>
                  </v-container>
                )
              })}
            {isMobile.value && santisedFiltersSlot?.length > mobileFilterLimit && (
              <div onClick={toggleFiltersVisible} class={style.moreFilters}>
                <fp-icon
                  class="mr-2"
                  name={`arrow-circle-${state.showAllFilters ? 'up' : 'down'}`}
                />
                {state.showAllFilters ? 'Hide filters' : 'Show more filters'}
              </div>
            )}
          </div>
        </portal>
      )
    }

    function toggleFiltersVisible() {
      state.showAllFilters = !state.showAllFilters
    }
  },
})
