import {
  globalColorPrimary,
  globalColorError,
  globalColorMidAccent,
  globalColorSuccess,
  globalColorWhite,
  // @ts-ignore
} from '@scss-ts'

import { NotificationTypeEnum } from 'typedefs'
import { IconColorMap } from '@components/_base/icon'

const iconColorMap: IconColorMap<NotificationTypeEnum> = {
  SUCCESS: {
    background: globalColorSuccess,
    color: globalColorWhite,
    name: 'check',
  },
  ERROR: {
    background: globalColorError,
    color: globalColorWhite,
    name: 'times',
  },
  WARNING: {
    background: globalColorMidAccent,
    color: globalColorWhite,
    name: 'exclamation',
  },
  INFO: {
    background: globalColorPrimary,
    color: globalColorWhite,
    name: 'info',
  },
}

export { iconColorMap }
