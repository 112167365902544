import useLocalStorage from './useLocalStorage'
import { useToaster } from '@components/_base/toaster/useToaster'
import { Auth } from 'aws-amplify'
import { LOCAL_STORE_KEYS } from '@state/modules/auth'

export const useAuth = ctx => {
  const resendSignUp = () => {
    const toaster = useToaster(ctx)
    const signupConfirmId = useLocalStorage(LOCAL_STORE_KEYS.SIGNUP_CONFIRM_ID)

    return Auth.resendSignUp(signupConfirmId.getValue())
      .then(data => toaster.success('A new email has been sent'))
      .catch(err => toaster.error(err))
  }

  const currentUserInfo = async () => {
    const cached = useLocalStorage(LOCAL_STORE_KEYS.USER_ID)
    const cachedValue = cached?.getValue() ?? null
    if (cachedValue) {
      return cachedValue
    }
    const currentValue = await Auth.currentUserInfo()
    return currentValue
  }

  return { resendSignUp, currentUserInfo }
}

export default useAuth
