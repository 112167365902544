import * as CRUDTypes from '@components/crud/types'
import * as RoleTypes from '@views/accounts/roles/types'
import { vueToNormalObject } from '@utils'
import { Role } from '@graphql-types'
import { gameDevRoles } from '@src/shared/ui/list/roles'
import { getRoles } from '@src/router/views/accounts/roles/rolesAPI'

type State = {
  mode: CRUDTypes.Mode
  createForm?: RoleTypes.RoleItem
  editForm?: RoleTypes.RoleItem
  loading: boolean
  roles: Array<Role>
}

export const state: State = {
  mode: CRUDTypes.Mode.LIST,
  createForm: undefined,
  editForm: undefined,
  loading: false,
  roles: [],
}

export const getters = {
  loading({ loading }) {
    return loading
  },
  mode({ mode }) {
    return mode
  },
  createForm({ createForm }) {
    return createForm
  },
  editForm({ editForm }) {
    return editForm
  },
  roles({ roles }) {
    return roles
  },
}

export const mutations = {
  SET_LOADING(state, value) {
    state.loading = value
  },
  SET_MODE(state, value) {
    state.mode = value
  },
  SET_CREATE_FORM(state, value) {
    state.createForm = value
  },
  SET_EDIT_FORM(state, value) {
    state.editForm = value
  },
  SET_ROLES(state, value) {
    state.roles = value
  },
}

export const actions = {
  async setLoading({ dispatch, commit, state }, data) {
    commit('SET_LOADING', data)
  },
  async setMode({ dispatch, commit, state }, data) {
    commit('SET_MODE', data)
  },
  async setCreateForm({ dispatch, commit, state }, data) {
    commit('SET_CREATE_FORM', vueToNormalObject(data))
  },
  async setEditForm({ dispatch, commit, state }, data) {
    commit('SET_EDIT_FORM', vueToNormalObject(data))
  },
  async getRoles({ dispatch, commit, state }, variables) {
    dispatch('setLoading', true)

    const [err, res] = await getRoles({
      ...variables,
    })
    if (err) {
      commit('SET_ROLES', gameDevRoles.data)
    }
    if (!err && res) {
      if (res.items && res.items.length > 0) {
        commit('SET_ROLES', res.items)
      } else {
        commit('SET_ROLES', gameDevRoles.data)
      }
    }

    dispatch('setLoading', false)
  },
}
