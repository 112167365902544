<script>
export default {
  name: 'StripeElements',
  components: {},
  props: {
    cardRef: {
      type: String,
      default: '',
      required: true,
    },
    cardError: {
      type: String,
      default: '',
    },
    card: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  mounted() {
    this.mountCard()
  },
  methods: {
    mountCard() {
      if (this.card && this.cardRef) {
        this.card.mount(this.$refs[this.cardRef])
      }
    },
  },
}
</script>

<template>
  <div v-if="!!card" :class="$style.root">
    <div :ref="cardRef" :class="$style.stripeCard" />

    <fp-hint-message
      v-if="!!cardError"
      :class="$style.errorMsg"
      :message="cardError"
    />
  </div>
</template>

<style lang="scss" module>
@import '@scss';
@import '~watson-scss';

.root {
  position: relative;
}

.stripeCard {
  width: 100%;
  height: 56px;
  padding: 17px 20px;
  border: 2px solid $color-dark-ice;
  border-width: 2px;
  border-radius: 8px;

  @include perfect-transition;

  &:global(.StripeElement--focus) {
    background-color: rgba($color-primary, 0.03);
    border: 2px solid $color-mid-primary;
    box-shadow: 0 0 0 3px rgba($color-primary, 0.04);
  }
}

.errorMsg {
  color: $color-error;
}
</style>
