<template>
  <portal to="pageHeadPortal">
    <form-layout :width="width">
      <slot name="stepper" />

      <div
        :class="[
          $style.headingBox,
          `text-${textAlign}`,
          textAlign === 'left' ? $style.headerWhenLeft : '',
        ]"
      >
        <h3 :class="$style.heading">
          {{ title }}
        </h3>

        <small :class="$style.tip">
          {{ subtitle }}
        </small>
      </div>
    </form-layout>
  </portal>
</template>

<script>
import FormLayout from '@layouts/form-layout'

export default {
  name: 'FpPageHeadForForm',

  components: {
    FormLayout,
  },

  props: {
    width: {
      type: [Number, String],
      default: 800,
    },

    title: {
      type: String,
      required: true,
    },

    textAlign: {
      type: String,
      default: 'center',
    },

    subtitle: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" module>
@import '@scss';

.headerWhenLeft {
  padding-left: $size-page-for-form-heading-padding;
}

.headingBox {
  margin-top: 20px;
  margin-bottom: 30px;
}

:global(.fp-stepper) {
  + .headingBox {
    margin-top: 50px;

    @include mq($until: sm) {
      margin-top: 30px;
    }
  }
}

h3.heading {
  font-size: 28px;
  line-height: 42px;
  color: $color-white;
}

small.tip {
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: $color-white;
}
</style>
