import { tryQuery, tryMutate } from '@graphql/query-helpers'
import * as titleQueries from '@graphql/titles/queries'
import * as sharedQueries from '@graphql/shared/queries'
import * as orgMutations from '@graphql/org/mutations'
import * as orgQueries from '@graphql/org/queries'

import {
  Query,
  QueryGetTitlesArgs,
  Mutation,
  MutationSuspendOrgsArgs,
  QueryGetOrgChildrenArgs,
} from '@graphql-types'

const getOrgChildren = async (variables: QueryGetOrgChildrenArgs) => {
  const res: [Error | null, Query['getOrgChildren']] = await tryQuery({
    query: orgQueries.getOrgChildren,
    variables: {
      ...variables,
    },
  })

  return res
}

const getTitles = async (variables: QueryGetTitlesArgs) => {
  const res: [Error | null, Query['getTitles']] = await tryQuery({
    query: titleQueries.getTitles,
    variables: {
      filter: {
        archived: false,
      },
      ...variables,
    },
  })

  return res
}

const getTags = async variables => {
  const res: [Error | null, Query['getTags']] = await tryQuery({
    query: sharedQueries.getTags,
    variables: {
      ...variables,
    },
  })

  return res
}

const validatePhone = async variables => {
  const res: [Error | null, Query['validatePhone']] = await tryQuery({
    query: sharedQueries.validatePhone,
    variables: {
      ...variables,
    },
  })

  return res
}

const suspendOrgs = async (variables: MutationSuspendOrgsArgs) => {
  const res: [Error | null, Mutation['suspendOrgs']] = await tryMutate({
    mutation: orgMutations.suspendOrgs,
    variables: {
      ...variables,
    },
  })

  return res
}

export { getTitles, getTags, validatePhone, suspendOrgs, getOrgChildren }
