import gql from 'graphql-tag'

export const insertionOrderDetails = gql`
  fragment insertionOrderDetails on InsertionOrderDetails {
    orgID
    insertionOrderSource
    opportunityID
    purchaseOrderNumber

    adTraffickerContactEmail

    currency
    clientBudget
    billingSource
    invoicingSource

    brandLiftStudies {
      provider
      measurementID
    }
    audienceSegments {
      provider
      CPM
      name
      code
    }
    thirdPartySettings {
      provider
      impressionsCount
    }
  }
`
