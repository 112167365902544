import { trimStart } from 'lodash'
import { cs } from '../log/console'
import { replaceAll } from '../utils'

const d3 = require('d3-format')

// where n is number of unique numbers to generate
// export const generateUniqueNumbers = n => {
//   const arr: number[] = []
//   while (arr.length < n) {
//     var r = Math.floor(Math.random() * 100) + 1
//     if (arr.indexOf(r) === -1) arr.push(r)
//   }

//   return arr
// }

export const isFloat = n => {
  return Number(n) === n && n % 1 !== 0
}

/**
 * Format number or string-number to commas style (max 3 precision!)
 * 1. Add commas
 * eg. '12345.67' to '12,345.67'
 */
export const formatNumber = (iVal: string | number): string => {
  let value = iVal
  if (!iVal) return '0'

  value = value.toString()

  if (/[^\d.\s]/g.test(value)) {
    cs.i('the value invalid: ' + value, 'formatNumber')
    return value
  }

  const data = numberWithCommas(value)

  return data
}

/**
 * Format numbers with separated commas
 * eg. 1000000 => 1,000,000
 * eg. '100000' => 100,000
 */
export const numberWithCommas = (
  val: number | string,
  separator = ','
): string => {
  let parts = val.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator)
  return parts.join('.')
}

/**
 * sorts array of numbers or array of object 1 level deep where prop is number.
 * key is required if items are array of objects
 */
export function sortNumber<T>(
  items: number[] | T[],
  order: 'asc' | 'desc' = 'desc',
  key = ''
) {
  const computeAB = (a, b) => {
    const _a = key ? a[key] : a
    const _b = key ? b[key] : b
    return [_a, _b]
  }

  if (order === 'asc') {
    return items.sort((a, b) => {
      const [_a, _b] = computeAB(a, b)
      return _a - _b
    })
  }
  return items.sort((a, b) => {
    const [_a, _b] = computeAB(a, b)
    return _b - _a
  })
}

export function parseNum(val, defaultVal = 0) {
  // https://i.stack.imgur.com/dzin3.png
  // https://stackoverflow.com/questions/17106681/parseint-vs-unary-plus-when-to-use-which

  // unary operator turns empty string to 0 not NaN. Expect NaN
  if (val === '') return defaultVal
  if (typeof defaultVal !== 'number') {
    throw Error('parseNum defaultVal must be a number')
  }

  const temp = +val
  if (isNaN(temp)) {
    return defaultVal
  }

  return temp
}

/**
 * Parse numbers with separated commas
 * eg. 1,000,000 => 1000000
 * eg. 100,000 => 100000
 */
export function parseNumWithCommas(val: string, defaultVal = 0): number {
  if (!val) return defaultVal
  if (typeof defaultVal !== 'number') {
    throw Error('parseNum defaultVal must be a number')
  }

  const temp = +replaceAll(val, ',', '')
  if (isNaN(temp)) {
    return defaultVal
  }

  return temp
}

// https://stackoverflow.com/questions/1726630/formatting-a-number-with-exactly-two-decimals-in-javascript
export function round(iValue, iExp) {
  let value = iValue
  let exp = iExp

  if (typeof exp === 'undefined' || +exp === 0) return Math.round(value)

  value = +value
  exp = +exp

  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) return NaN

  // Shift
  value = value.toString().split('e')
  value = Math.round(+(value[0] + 'e' + (value[1] ? +value[1] + exp : exp)))

  // Shift back
  value = value.toString().split('e')
  return +(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp))
}

export function trimStartingZerosForDecimals(decimalString: string): string {
  let rv = decimalString
  rv = trimStart(decimalString, '0')
  if (!rv || rv[0] === '.') {
    rv = '0' + rv
  }
  return rv
}

export function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min)) + min
}

/**
 * Format and abbreviate numebr xx.xx when it is over 7 chars long, counting
 * separators like commas. So, if xx,xx.xx over 7 chars, it will abbr it.
 */
export function formatAndAbbrNumber(val: number): string {
  const tmp = formatNumber(val)

  if (tmp.length <= 7) return tmp

  return abbrNumber(val)
}

/**
 * Abbreviate number
 * e.g.
 * 925123.01231 -> 925.12K
 * 9399102.00 -> 9.3991M
 */
export function abbrNumber(val: number): string {
  if (Math.abs(val) < 1000) return val.toString()
  // eslint-disable-next-line
  // [​[fill]align][sign][symbol][0][width][,][.precision][~][type]
  // https://github.com/d3/d3-format#locale_formatPrefix
  const formatted = d3.format('.5~s')(val).toUpperCase()

  const rv = replaceAll(formatted, 'G', 'B')
  return rv
}
