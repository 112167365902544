<script>
export default {
  name: 'Primary',
}
</script>

<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 500 834"
    xml:space="preserve"
  >
    <path
      fill="#0c4ff5"
      d="M242.5 0 0 242.2v344.9l63.3 63.4V407.9l179.2-179.2V139L63.3 318.2v-49.8L242.5 89.2z"
    />
    <path
      fill="#0c4ff5"
      d="M270.9 252.7 99.6 424v346.6L163 834V450.2l134.2-134.1H347L195.4 467.6v89.7L500 252.7z"
    />
  </svg>
</template>
