// @ts-check
let skeletonCount = 0

/**
 * Register a new skeleton element, return it's index
 * @returns {number}
 */
export function registerNewSkeleton() {
  skeletonCount++
  return skeletonCount
}
