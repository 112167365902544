import Vue from 'vue'
import {
  distanceInWords,
  formatDate,
  formatDateUTC,
  formatTimeAgo,
  formatNumber,
  formatCurrency,
  firstUpper,
  splitCamel,
  capitalize,
  startCaseAllUppercase,
} from '@utils'

import {
  formatCentsToDollars,
  formatDollarsToCents,
} from '@shared/finance/utils'

export default function useGlobalFilters() {
  Vue.filter('timeAgo', time => {
    return distanceInWords(time)
  })

  Vue.filter('formatDate', (date, format) => {
    if (date && date.toString().length === 10) {
      // eslint-disable-next-line
      date = date * 1000
    }

    return formatDate(date, format)
  })

  Vue.filter('formatDateUTC', (date, format) => {
    if (date && date.toString().length === 10) {
      // eslint-disable-next-line
      date = date * 1000
    }

    return formatDateUTC(date, format)
  })

  Vue.filter('formatTimeAgo', (date, format, toDate) => {
    return formatTimeAgo(date, format, toDate)
  })

  Vue.filter('noEmpty', (str, placeholder = '-') => {
    return str.trim() ? str : placeholder
  })

  Vue.filter('formatNumber', formatNumber)
  Vue.filter('formatCurrency', formatCurrency)

  Vue.filter('formatCentsToDollars', formatCentsToDollars)

  Vue.filter('formatDollarsToCents', formatDollarsToCents)
  Vue.filter('firstUpper', firstUpper)
  Vue.filter('startCaseAllUppercase', startCaseAllUppercase)
  Vue.filter('splitCamel', splitCamel)
  Vue.filter('capitalize', capitalize)
}
