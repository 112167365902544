// @ts-ignore
import { mutate, query } from '@graphql/query-helpers'
import to from 'await-to-js'

export const actions = {
  async tryMutate({ dispatch, commit, state }, args) {
    const errAndRes = await to(mutate(args))
    return errAndRes
  },
  async tryQuery({ dispatch, commit, state }, args) {
    const errAndRes = await to(query(args))
    return errAndRes
  },
}

export default actions
