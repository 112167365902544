export enum FileStatusEnum {
  UNKNOWN,

  FRONTEND_CHECKING,
  FRONTEND_CHECK_ERROR,
  FRONTEND_CHECK_FINISHED,

  WAITING_FOR_UPLOAD,
  UPLOADING,
  UPLOAD_NETWORK_ERROR,
  UPLOAD_FINISHED,

  BACKEND_CHECKING,
  BACKEND_CHECK_NETWORK_ERROR,
  BACKEND_CHECK_ERROR,
  BACKEND_CHECK_WARNING,
  BACKEND_CHECK_FINISHED,

  ALL_GOOD,
}

export enum FeErrorsEnum {
  SIZE_EXCEED = 'File is too large',
  SIZE_DECEED = 'File is too small',

  RESOLUTION_EXCEED = 'Resolution is too large',
  RESOLUTION_DECEED = 'Resolution is too small',

  REPEATED_RATIO = 'Repeated ratio',
  UNVALID_RATIO = 'Unsupported ratio',

  DURATION_INVALID = 'Video duration is invalid',
}

export interface FpS3File {
  status: FileStatusEnum

  id: string
  fileName: string
  mimeType: string
  extension: string
  size: number | string
  ratio: {
    real: string
    // if estimate has value, it means the real ratio doesn't match any valid ratio existed
    // but the real is very close to the estimate one(it's an valid ratio)
    estimate?: string
  }
  width: number
  height: number
  uploadPercent: number

  error: Error | null
  warning: Error | null

  raw: File
  url: string
  thumbnailUrl: string

  // for video file only
  duration?: number
  durationSecond?: number
  key?: string
  partTags?: {
    partNumber: number
    eTagID: string
  }[]

  switchStatus(FileStatusEnum): void
  clearError(): void
}
