import { actions, getters, mutations } from './roles'
import store from '../store'

type Actions = keyof typeof actions
type Computations = keyof typeof getters
type Mutations = keyof typeof mutations

export function RolesDispatch(action: Actions, payload: any) {
  return store.dispatch(`roles/${action}`, payload)
}

export function RolesCommit(mutation: Mutations, payload: any) {
  return store.commit(`roles/${mutation}`, payload)
}

export function RolesGetters(key: Computations) {
  return store.getters[`roles/${key}`]
}
