export default {
  data() {
    return {
      isFocused: false,
    }
  },

  computed: {
    nonInteractive() {
      return !!(this.disabled || this.readonly)
    },
  },

  mounted() {
    const anchor = this.$refs.focusAnchor
    if (anchor) {
      anchor.addEventListener('focus', this.focusHandler$)
      anchor.addEventListener('blur', this.blurHandler$)
    }
  },

  beforeDestory() {
    const anchor = this.$refs.focusAnchor
    if (anchor) {
      anchor.removeEventListener('focus', this.focusHandler$)
      anchor.removeEventListener('blur', this.blurHandler$)
    }
  },

  methods: {
    focusHandler$() {
      if (this.nonInteractive) return
      this.isFocused = true
    },

    blurHandler$() {
      if (this.nonInteractive) return
      this.isFocused = false
    },
  },
}
