var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"bem",rawName:"v-bem",value:({}),expression:"{}"}]},[_c('v-combobox',_vm._g(_vm._b({attrs:{"filter":_vm.filter,"hide-no-data":!_vm.search,"search-input":_vm.search,"items":_vm.itemsInternal,"data-cy":_vm.cypressName},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":_vm.handleChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var parent = ref.parent;
return [(_vm.propsPenetrating.multiple === true)?_c('v-chip',{attrs:{"close":"","ripple":""},on:{"click:close":function($event){return parent.selectItem(item)}}},[_c('span',{directives:[{name:"bem",rawName:"v-bem:selectionChipLabel",arg:"selectionChipLabel"}]},[_vm._v(_vm._s(item))])]):_c('v-chip',{attrs:{"ripple":""}},[_c('span',{directives:[{name:"bem",rawName:"v-bem:selectionChipLabel",arg:"selectionChipLabel"}]},[_vm._v(_vm._s(item))])])]}},{key:"item",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
var index = ref.index;
var item = ref.item;
return [_c('v-list-item',_vm._g(_vm._b({key:index,attrs:{"ripple":{ class: 'primary--text' }},on:{"click":_vm.handleListItemClick}},'v-list-item',attrs,false),on),[(_vm.deletableItems)?_c('v-list-item-action',{on:{"click":function($event){$event.stopPropagation();}}},[_c('fp-icon-button',{staticClass:"close-button",attrs:{"icon":"times","size":"small"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteItem(index, item)}}})],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item)+" ")])],1)],1)]}}])},'v-combobox',_vm.propsPenetrating,false),_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }