import { EChartOption, EChartsOptionConfig, ECharts } from 'echarts/lib/echarts'
// @ts-ignore
import { globalColorDarkIce } from '@scss-ts'
import { isArrayEmpty, merge } from '@utils'

import theme from '@styles/chartThemes/default.json'

const CHART_THEME_NAME = 'frameplay'

const initIsEmptyOpts = {
  allZeroIsEmpty: true,
}
export type IsEmptyOpts = typeof initIsEmptyOpts

const init = (echarts: any, dom: HTMLElement) => {
  echarts.registerTheme(CHART_THEME_NAME, theme)

  const chart = echarts.init(dom, CHART_THEME_NAME, { renderer: 'canvas' })
  return chart
}

const setOption = (
  chart: ECharts,
  option: EChartOption,
  defaultOption: EChartOption,
  emptyStateOption: EChartOption | null,
  opts: EChartsOptionConfig,
  isEmptyOpts?: IsEmptyOpts
) => {
  if (!chart) return

  const useEmptyState = isEmpty(option, isEmptyOpts)

  let mergedOptions = merge(defaultOption, option)

  if (useEmptyState) {
    const defaultEmptyStateOption = setDefaultEmptyStateOption(mergedOptions)
    const mergedEmptyStateOption = merge(
      defaultEmptyStateOption,
      emptyStateOption
    )

    mergedOptions = merge(mergedOptions, mergedEmptyStateOption)
  }

  // notMerge to fix tooltip render issue on area chart becoming white
  chart.setOption(mergedOptions, { notMerge: true, ...opts })
}

const setDefaultEmptyStateOption = (option: EChartOption) => {
  const _option: EChartOption = { ...option }

  if (_option.legend) _option.legend.show = false
  if (_option.tooltip) _option.tooltip.show = false

  // @ts-ignore
  _option.hoverAnimation = false
  // @ts-ignore
  _option.silent = true

  if (Array.isArray(_option.yAxis)) {
    _option.yAxis.forEach(o => {
      o.axisPointer = {
        show: false,
      }
    })
  }

  if (_option.yAxis) {
    ;(_option.yAxis as EChartOption.YAxis).axisPointer = {
      show: false,
    }
  }

  _option.series &&
    _option.series.forEach((seriesItem: any) => {
      seriesItem.data = [
        {
          cursor: 'default',
          hoverAnimation: false,
          value: 0,
          name: 'No data available',
          itemStyle: { color: globalColorDarkIce },
          emphasis: {
            itemStyle: { color: globalColorDarkIce },
          },
          axisPointer: {
            show: false,
          },
        },
      ]
    })

  return _option
}

const isEmpty = (option: EChartOption, opts: IsEmptyOpts = initIsEmptyOpts) => {
  const noSeries =
    !option ||
    !option.series ||
    !Array.isArray(option.series) ||
    isArrayEmpty(option.series)

  if (noSeries) return true

  const firstSeries: any = (option.series && option.series[0]) || {}
  const firstSeriesData: any[] = firstSeries.data

  const noSeriesData =
    !firstSeriesData ||
    !Array.isArray(firstSeriesData) ||
    isArrayEmpty(firstSeriesData)

  const hasAllZeros =
    (firstSeriesData.every(e => e === 0) || false) &&
    (opts?.allZeroIsEmpty ?? true)

  if (noSeriesData || hasAllZeros) return true

  return false
}

export { init, setOption, isEmpty }
