<script>
import Vue from 'vue'

import HeaderActions from './actions.vue'
import HeaderMenus from './menus.vue'
import Logo from '@components/logo'

import { mapGetters } from 'vuex'
import { headroom } from 'vue-headroom'
import { intersection } from '@shared/array'

export const HeaderEventBus = new Vue()

export const HEADER_EVENTS = {
  headerUnpin: 'headerUnpin',
}

export default {
  skipGloballyRegister: true,

  name: 'GlobalHeader',

  components: {
    HeaderActions,
    HeaderMenus,
    Headroom: headroom,
    Logo,
  },

  data() {
    return {
      logoTheme: 'white',
      observer: null,
      speed: 250,
    }
  },

  computed: {
    ...mapGetters('org', ['fetching', 'currentOrganisation']),
    adminEditMode() {
      return this.$route.meta.adminEditMode
    },
  },
  mounted() {
    this.observer = this.createMutationObserver()

    const observerConfig = {
      attributes: true,
      attributeFilter: ['style'],
    }
    this.observer.observe(this.$refs['headroom'].$el.firstChild, observerConfig)
  },

  methods: {
    createMutationObserver() {
      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.attributeName === 'style') {
            const classList = Array.from(mutation.target.classList)

            const pinnedTop =
              intersection(classList, ['headroom--top', 'headroom--pinned'])
                .length === 2
            const pinnedNotTop =
              intersection(classList, ['headroom--not-top', 'headroom--pinned'])
                .length === 2
            // this fixes logo becomes white agaisnt white bg, occurs only when
            // setLogoTheme has a delay and you spam scroll fast just before
            // header is unpinned. Bug does not occur when scroll is slow or
            // when header is pinned.
            const unpinnedNotTop =
              intersection(classList, [
                'headroom--not-top',
                'headroom--unpinned',
              ]).length === 2

            // delay is to fix bug where logo disppears before header animation transition
            // finished.
            // bug replication steps:
            // 1. Scroll down half way down the page
            // 2. Scroll up to activate the white header
            // 3. Scroll back down - at this point, the logo will disappear before the header transition has occurred
            const setLogoTheme = (theme, delay) => {
              if (!delay) {
                this.logoTheme = theme
              } else {
                setTimeout(() => {
                  this.logoTheme = theme
                }, this.speed)
              }
            }

            if (pinnedTop) {
              setLogoTheme('white', false)
            } else if (pinnedNotTop) {
              setLogoTheme('primary', false)
            } else if (unpinnedNotTop) {
              setLogoTheme('primary', false)
            } else {
              setLogoTheme('white', true)
            }
          }
        })
      })

      return observer
    },

    unpinHandler() {
      HeaderEventBus.$emit(HEADER_EVENTS.headerUnpin)
    },
  },
}
</script>

<template>
  <headroom
    ref="headroom"
    :z-index="4"
    :speed="speed"
    easing="ease-out"
    @unpin="unpinHandler"
  >
    <header
      :class="[$style.container, { [$style.isAdminMode]: adminEditMode }]"
    >
      <div :class="$style.inner">
        <div v-if="!adminEditMode" :class="$style.logoWrapper">
          <logo :class="$style.logo" :theme="logoTheme" />
        </div>

        <transition name="skeleton" mode="out-in">
          <header-menus v-if="!adminEditMode" />

          <div v-else :class="$style.editModeHeader">
            <fp-icon-button
              size="large"
              icon="arrow-left"
              @click="$router.back()"
            />
          </div>
        </transition>

        <header-actions />
      </div>
    </header>
  </headroom>
</template>

<style lang="scss">
@import '@scss';

.headroom {
  --header-font-size: 16px;
  --header-background: transparent;
  --header-shadow: none;
  --header-text-color: #{rgba($color-white, 0.8)};
  --header-text-active-color: #{$color-white};
  --header-button-left-background: rgba(12, 79, 245, 0.29);
  --header-button-right-background: rgba(0, 112, 243, 0.8);
  --header-button-active-background: #{rgba($color-white, 0.2)};

  &[style*='fixed'] {
    --header-font-size: 16px;
    --header-background: #{$color-white};
    --header-shadow: 0 10px 30px -2px #{rgba($color-black, 0.12)};
    --header-text-color: #{rgba($color-black, 0.8)};
    --header-text-active-color: #{$color-black};
    --header-button-left-background: #{rgba(#0070f3, 0.8)};
    --header-button-right-background: #0070f3;
    --header-button-active-background: #{$color-dark-primary};
  }
}
</style>

<style lang="scss" module>
@import '~watson-scss';
@import '@scss';

.container {
  @include perfect-transition;

  z-index: $layer-modal-z-index;
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: var(--header-font-size);
  background: var(--header-background);
  box-shadow: var(--header-shadow);
  transition-timing-function: ease-out;

  &.isAdminMode {
    // background-color: $color-white;
    // box-shadow: 0 10px 30px -2px rgba($color-black, 0.07);
  }
}

.editModeHeader {
  display: flex;
  align-items: center;
  width: 100%;
  color: var(--header-text-color);

  .heading {
    margin-left: 20px;
    font-weight: bold;
    color: inherit;
  }
}

.inner {
  @extend %center-content;

  display: flex;
  flex-grow: 1;
  align-items: center;
  height: $size-header-height;
}

.logoWrapper {
  @include circle(60px);

  position: relative;
  z-index: 3;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  width: 40px; // Overrides circle mixin
  cursor: pointer;
  background-image: var(--logo-url);
  background-repeat: no-repeat;
  background-position: center;

  // hide the logo when we hide the menu word, to avoid confusing menu and logo icons
  @include mq($until: md) {
    display: none;
  }
}

.logo {
  width: 100%;
  margin: auto;
  user-select: none;

  &:focus {
    outline: none;
  }
}
</style>
