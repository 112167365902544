import { embedFuncs } from '@shared/string/tagged'

const getStatus = args => {
  const rt = args.status
  return rt
}

export const UNKNOWN_ERROR_MSG = 'An error has occured.'

export const actionDenied = embedFuncs`Sorry, you cannot do this because your account is ${getStatus}`
