export const INIT_BILLING = {
  company: '',
  businessNumber: '',
  contact: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    phoneCountry: '',
    address: '',
  },
}

export const INIT_CONTACT = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  phoneCountry: '',
  address: '',
}

export const INIT_ORG = {
  id: '',
  title: '',
  isStudent: false,
  student: { institution: '', studentID: '' },
  archived: false,
  dateUpdated: '',
  dateCreated: '',
  orgType: '',
  contact: {
    ...INIT_CONTACT,
  },
  billing: {
    ...INIT_BILLING,
  },
  website: '',
  businessNumber: '',
  numberFormat: '',
  timezone: '',
  status: '',
  verifyHistory: { files: [], history: [] },
  balance: '',
}
