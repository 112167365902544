import '@component-styles/feed-card.scss'
import style from './style.module.scss'
import { createComponent, watch } from '@vue/composition-api'
import truncate from 'lodash/truncate'
import { BEMProvider } from '@plugins/bem'
import useToggle from '@components/hooks/useToggle'
import { formatDate } from '@utils'
import { VNode } from 'vue'

const bem = BEMProvider('fp-feed-card')

export type ItemAction = {
  label: string
  onClick: () => void
}

type ActionAlign = 'left' | 'right'
type CardType = 'compact' | 'big' | 'radio'
type Icon = {
  name: string
  background: string
  color: string
}

type Props = {
  loading: boolean
  title?: string
  message?: string
  actions?: ItemAction[]
  actionAlign?: ActionAlign
  shadow: 'no' | 'big' | 'small'
  type?: CardType
  icon: Icon
  activeIcon: Icon
  date: Date
  selectable: boolean
  selected: boolean
  showTime: boolean
  truncate: boolean
  dateFunction: (date: Date) => string
}

export default createComponent({
  name: 'FpFeedCard',
  components: {},
  props: {
    loading: Boolean,
    onClick: Function,
    title: {
      type: String,
      default: '',
    },
    date: {
      type: Date,
    },
    hideTime: Boolean,
    truncate: {
      type: Boolean,
      default: true,
    },
    actionAlign: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    actions: {
      type: Array,
      default: () => [],
    },
    shadow: {
      type: String,
      default: 'big',
    },
    icon: {
      type: Object,
      default: () => ({}),
    },
    activeIcon: {
      type: Object,
      default: () => ({}),
    },
    selectable: Boolean,
    selected: Boolean,
    showTime: Boolean,
    dateFunction: {
      type: Function,
    },
    type: {
      type: String,
      default: 'compact',
    },
  },
  setup(props: Props, ctx) {
    const toggler = useToggle(false)

    const { loading, icon, date, selectable, activeIcon } = props

    watch(
      () => props.selected,
      val => {
        toggler.toggle(val)
      }
    )

    const actionAlign: ActionAlign = props.actionAlign || 'right'
    const type: CardType = props.type || 'compact'
    const title =
      type === 'compact' && props.truncate
        ? truncate(props.title ?? '', { length: 55 })
        : props.title

    const message =
      type === 'compact' && props.truncate
        ? truncate(props.message ?? '', { length: 59 })
        : props.message

    if (!title && !message) return <div></div>

    const renderIcon = () => {
      let rv: JSX.Element | VNode[] = (
        <fp-icon
          shape="circle"
          background={icon?.background ?? style.globalColorWhite}
          // icon name required even when no icon given in props to avoid
          // console errors. Make it white/transparent to look like empty
          name={icon?.name ?? 'check'}
          style={`color: ${icon?.color ?? style.globalColorWhite}`}
        />
      )
      if (toggler.state.on) {
        rv = ctx.slots.activeIcon ? (
          ctx.slots.activeIcon()
        ) : (
          <fp-icon
            shape="circle"
            background={activeIcon?.background ?? style.globalGradientPrimary}
            name={activeIcon?.name ?? 'check'}
            style={`color: ${activeIcon?.color ?? style.globalColorWhite}`}
          />
        )
      }

      return rv
    }

    const handleClick = () => {
      if (props.selectable) {
        toggler.toggle()
      }
      ctx.emit('click', toggler.state.on)
    }

    const handleActionClick = (e: Event, action: ItemAction) => {
      // disable toggling the check box of parent when action clicked
      e.stopPropagation()
      action && action.onClick()
    }

    const DateTime = () => {
      if (!date) return null

      const fDate = props.dateFunction
        ? props.dateFunction(date)
        : formatDate(date)

      const fTime = formatDate(date, 'h:mm a')

      return (
        <div {...bem('::date-time', { $type: type })}>
          <v-container fluid class="pa-0">
            <v-row no-gutters>
              <v-col cols="12">
                {type === 'big' && (
                  <span>
                    <fp-icon prefix="far" name="clock" class="mr-1" />
                  </span>
                )}
                <span>{fDate}</span>
              </v-col>
              {props.showTime && (
                <v-col cols="12">
                  <div>{fTime}</div>
                </v-col>
              )}
            </v-row>
          </v-container>
        </div>
      )
    }

    return () => {
      const rv = (
        <fp-card
          shadow={props.shadow}
          noBodyPad
          v-ripple={{ class: style.ripple }}
        >
          <div {...bem('::cardContent')}>
            <div class="d-flex">
              <div class="mr-4">
                <div {...bem('::checkbox')}>
                  <div {...bem('::empty-circle')} />
                  {renderIcon()}
                </div>
              </div>

              <div {...bem('::textWrapper')} class="w-100">
                <div class="d-flex pt-1">
                  <div class="w-100">
                    <div
                      {...bem('::title', { $type: type })}
                      class={['pb-1']}
                      data-cy="feed-card-title"
                    >
                      {title}
                    </div>
                    <div data-cy="feed-card-message">
                      {message && (
                        <div {...bem('::message', { $type: type })}>
                          {message}
                        </div>
                      )}
                    </div>
                  </div>

                  <DateTime />
                </div>

                {props.actions && props.actions.length > 0 && (
                  <v-container fluid class="pa-0">
                    <v-row
                      no-gutters
                      justify="end"
                      align="center"
                      class={props.loading && style.disabled}
                    >
                      <v-col
                        class={[
                          { 'pt-4': type === 'big' },
                          `text-${actionAlign}`,
                        ]}
                      >
                        {props.actions.map(action => (
                          <span
                            {...bem('::action', {
                              $type: type,
                              $type2: action.label.toLowerCase(),
                              loading,
                            })}
                            onClick={e => handleActionClick(e, action)}
                          >
                            {action.label}
                          </span>
                        ))}
                      </v-col>
                    </v-row>
                  </v-container>
                )}
              </div>
            </div>
          </div>
        </fp-card>
      )

      return (
        <div
          {...bem({
            selectable,
            selected: toggler.state.on,
            notSelected: !toggler.state.on,
            noIcon: !icon || (!icon.name && !icon.background),
            $type: type,
          })}
          onClick={handleClick}
        >
          {rv}
        </div>
      )
    }
  },
})
