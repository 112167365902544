var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{directives:[{name:"bem",rawName:"v-bem",value:({
    disabled: _vm.disabled,
    settled: _vm.settled,
    noLabel: _vm.noLabel,
    light: _vm.light,
    indeterminate: _vm.indeterminate,
    error: _vm.isInGroup && _vm.groupInstance.error,
  }),expression:"{\n    disabled,\n    settled,\n    noLabel,\n    light,\n    indeterminate,\n    error: isInGroup && groupInstance.error,\n  }"}],attrs:{"role":"checkbox"},on:{"click":function($event){$event.stopPropagation();}}},[(_vm.label && !_vm.hasCustomLabel)?_c('div',{directives:[{name:"bem",rawName:"v-bem:label",arg:"label"}]},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(!_vm.label && _vm.hasCustomLabel)?_c('div',{directives:[{name:"bem",rawName:"v-bem:label",arg:"label"}]},[_vm._t("default")],2):_vm._e(),(!_vm.disabled)?_c('span',{directives:[{name:"bem",rawName:"v-bem:indicator",arg:"indicator"}]},[(_vm.indeterminate)?_c('fp-icon',{attrs:{"name":"minus"}}):_c('fp-icon',{attrs:{"name":"check"}})],1):_vm._e(),_c('input',_vm._b({directives:[{name:"bem",rawName:"v-bem:inner",arg:"inner"}],attrs:{"hidden":"hidden","type":"checkbox"},domProps:{"checked":_vm.checked},on:{"change":_vm.checkedChangeHandler}},'input',_vm.$attrs,false)),(_vm.disabledisStr)?_c('div',{directives:[{name:"bem",rawName:"v-bem:disabled-text",arg:"disabled-text"}]},[_c('fp-chip',{attrs:{"label":"","disabled":""}},[_vm._v(" Coming Soon ")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }