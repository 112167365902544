import { reactive } from '@vue/composition-api'

export interface Methods<T> {
  set: (items: T[]) => void
  add: (item: T) => void
  // addAt: (index: number, item: T) => void
  // addRange: (items: T[]) => void
  // addRangeAt: (index: number, items: T[]) => void
  removeAt: (index: number) => void
  // updateAt: (index: number, item: T) => void
  filter: (fn: (item: T) => boolean) => void
  // sort: (fn?: (a: T, b: T) => number) => void
}

type State<T> = {
  items: T[]
}

export const useList = <T>(initial: T[] = []) => {
  const state: State<T> = reactive({
    items: [],
  })

  state.items = [...initial]

  const set = items => (state.items = [...items])
  const add = item => set([...state.items, item])

  // const addAt = (index, item) =>
  //   set([...state.items.slice(0, index), item, ...state.items.slice(index)])
  // const addRangeAt = (index, items) =>
  //   set([...state.items.slice(0, index), ...items, ...state.items.slice(index)])
  // const updateAt = (index, item) =>
  //   set([...state.items.slice(0, index), item, ...state.items.slice(index + 1)])
  const removeAt = index =>
    set([...state.items.slice(0, index), ...state.items.slice(index + 1)])
  const filter = fn => set(state.items.filter(fn))
  // const sort = (fn?) => set([...state.items].sort(fn))

  const methods: Methods<T> = {
    set,
    add,
    // addRange,
    // addAt,
    // addRangeAt,
    // updateAt,
    removeAt,
    filter,
    // sort,
  }

  return {
    state,
    ...methods,
  }
}

export default useList
