import { ROLE_TYPES } from '@src/shared/constants'
import {
  getOrgTypeFromCognitoUser,
  getRoleTypeFromCognitoUser,
  getPermissionsFromCognitoUser,
} from '@state/modules/org'
import store from '@state/store'
import { OrgTypeEnum } from '@graphql-types'

export function checkRoutePermission(allowRoles, allowPermissions) {
  let org = ''
  let roles = []

  const isFetchingUser = store.getters['user/loading']
  const currUser = store.getters['auth/currentUser']

  if (isFetchingUser) {
    org = getOrgTypeFromCognitoUser(currUser)
    roles = [getRoleTypeFromCognitoUser(currUser)]
  } else {
    const user = store.getters['user/userInfo']
    org = user.org.type
    user.roles.forEach(r => {
      roles.push(r.roleID.split(':')[1])
    })
  }

  let roleAndOrgTypeAllowed = false
  const role = roles[0]

  // allow: Object {
  //          org1: [role1, role2],
  //          ...
  //        }

  if (Object.keys(ROLE_TYPES).includes(role)) {
    // check pre-defined roles
    Object.entries(allowRoles).forEach(([orgAllowed, rolesAllowed]) => {
      // check if org is allowed
      if (orgAllowed === org) {
        // check if roles are allowed
        roles.forEach(role => {
          if (rolesAllowed.includes(role)) roleAndOrgTypeAllowed = true
        })
      }
    })
  } else {
    // check custom role permissions
    const myPermissions = getPermissionsFromCognitoUser(currUser)
    if (allowPermissions) {
      for (let i = 0; i < allowPermissions.length; i++) {
        if (myPermissions.includes(allowPermissions[i])) {
          roleAndOrgTypeAllowed = true
          break
        }
      }
    }
  }

  return roleAndOrgTypeAllowed
}

export function checkRoutePermissionHier(allowHier) {
  // const isFetchingUser = store.getters['user/loading']
  if (!allowHier) return true
  let HierarchyAllowed = false
  const user = store.getters['user/userInfo']
  const hier = user.org.hierarchy

  HierarchyAllowed = allowHier.includes(hier)
  return HierarchyAllowed
}

export function checkRoutePermissionOrg(allowOrg) {
  if (!allowOrg) return true
  let orgAllowed = false
  const user = store.getters['user/userInfo']
  if (user.org.type === OrgTypeEnum.SUPER_ADMIN) return true

  const orgId = user.org.id

  orgAllowed = allowOrg.includes(orgId)
  return orgAllowed
}
