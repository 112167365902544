<template>
  <label
    v-bem="{
      disabled,
      settled,
      noLabel,
      light,
      indeterminate,
      error: isInGroup && groupInstance.error,
    }"
    role="checkbox"
    @click.stop
  >
    <div v-if="label && !hasCustomLabel" v-bem:label>
      {{ label }}
    </div>

    <div v-if="!label && hasCustomLabel" v-bem:label>
      <slot />
    </div>

    <span v-if="!disabled" v-bem:indicator>
      <fp-icon v-if="indeterminate" name="minus" />
      <fp-icon v-else name="check" />
    </span>

    <input
      v-bem:inner
      hidden="hidden"
      v-bind="$attrs"
      :checked="checked"
      type="checkbox"
      @change="checkedChangeHandler"
    />

    <div v-if="disabledisStr" v-bem:disabled-text>
      <fp-chip label disabled> Coming Soon </fp-chip>
    </div>
  </label>
</template>

<script>
import useSize from '@component-mixins/useSize'
import useReadonly from '@component-mixins/useReadonly'

export default {
  name: 'FpCheckbox',

  mixins: [useSize, useReadonly],

  inheritAttrs: false,

  model: {
    prop: 'value',
    event: 'change',
  },

  // ===
  // Injections
  // ===

  inject: {
    isInGroup: {
      from: 'injectFromGroup',
      default: false,
    },
    groupInstance: { from: 'groupInstance', default: null },
  },

  // ===
  // Props
  // ===

  props: {
    indeterminate: {
      type: Boolean,
      default: false,
    },

    value: {
      type: [Boolean, Number, String, Object],
      default: false,
    },

    label: {
      type: [String, Number],
      default: '',
    },

    light: Boolean,

    disabled: {
      type: [Boolean, String],
      default: false,
    },

    useLabel: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      id: Math.random().toString(16),
    }
  },

  computed: {
    disabledisStr() {
      return typeof this.disabled === 'string'
    },
    checkedValueWhenInGroup() {
      return this.groupInstance.value.includes(this.value)
    },

    hasCustomLabel() {
      return this.$slots.default?.length
    },

    noLabel() {
      return !this.hasCustomLabel && !this.label
    },

    checked() {
      if (this.isInGroup) return this.checkedValueWhenInGroup
      return this.value
    },

    settled() {
      return this.checked || this.indeterminate
    },
  },

  created() {
    if (this.isInGroup) {
      this.signUpInGroup()
    }
  },

  methods: {
    signUpInGroup() {
      if (!this.isInGroup) return
      this.groupInstance.$emit('checkboxSignUp', this.id, this.value)
    },

    checkedChangeHandler(ev) {
      const { id, value, disabled } = this

      if (disabled) return

      const { checked } = ev.target

      // for standalone usage
      this.$emit('change', checked, value)

      if (this.isInGroup) {
        this.groupInstance.$emit('checkboxValueChange', id, checked)
      }
    },
  },
}
</script>

<style lang="scss" src="@component-styles/checkbox"></style>
