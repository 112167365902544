import { getters, actions } from './companies'
import store from '../store'

type CompaniesAction = keyof typeof actions
type CompaniesComputation = keyof typeof getters

export function CompaniesDispatch(action: CompaniesAction, payload?: any) {
  return store.dispatch(`companies/${action}`, payload)
}

export function CompaniesGetters(key: CompaniesComputation) {
  return store.getters[`companies/${key}`]
}
