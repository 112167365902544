import { createComponent } from '@vue/composition-api'
import style from './style.module.scss'

type Route = {
  path: string
  label: string
  // children?: Omit<Route, 'children'>[]
}

type Props = {
  routes: Route[]
}

export default createComponent({
  name: 'BreadCrumb',
  props: {
    routes: Array,
  },
  setup(props: Props) {
    return () => {
      return (
        <h5 class={style.breadCrumb}>
          {props.routes.map((item: Route, key) => {
            return (
              <span class={style.breadCrumbItem}>
                {!item.path && (
                  <span
                    class={{ 'primary--text': key === props.routes.length - 1 }}
                  >
                    {item.label}
                  </span>
                )}
                {item.path && <a href={item.path}>{item.label}</a>}
                {key < props.routes.length - 1 && <span>{' > '}</span>}
              </span>
            )
          })}
        </h5>
      )
    }
  },
})
