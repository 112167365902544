<template>
  <animate-item>
    <a :class="$style.link" v-bind="$attrs" @click="clickHandler">
      <fp-icon :name="icon" />
      <slot />
    </a>
  </animate-item>
</template>

<script>
import router from '@router'
import posed from 'vue-pose'

export default {
  skipGloballyRegister: true,

  name: 'HeaderActionDropdownCommonLink',

  components: {
    AnimateItem: posed.div({
      visible: {
        opacity: 1,
        y: 0,
      },
      hidden: { opacity: 0, y: 60 },
    }),
  },

  inheritAttrs: false,

  props: {
    icon: { type: String, default: '' },
    to: {
      type: [Object, String], // { name: 'hello' } || 'hello'
      default: () => {},
    },
  },

  methods: {
    clickHandler() {
      let pushRoute =
        typeof this.to === 'object'
          ? JSON.parse(JSON.stringify(this.to))
          : this.to

      if (this.to) {
        router.push(pushRoute)
      }

      this.$emit('click')
    },
  },
}
</script>

<style lang="scss" module>
@import '@scss';

.link {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 10px 18px;
  margin: 0 -20px;
  font-size: 17px;
  color: $color-dark-grey !important;
  text-decoration: none;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: $color-primary !important;
    background-color: $color-mid-ice;
  }

  > :global(.fp-icon) {
    min-width: 17px;
    margin-right: 14px;
  }

  + .action {
    margin-top: 5px;
  }
}
</style>
