import store from '../store'
import Vue from 'vue'
import { ValuesType } from 'utility-types'

const MODULE_KEY = 'funds'

const initalState = {
  currentAddFundsStep: 1,
}

export type State = typeof initalState

export const state: State = {
  ...initalState,
}

export const getters = {
  currentAddFundsStep() {
    return state.currentAddFundsStep
  },
}

export const mutations = {
  SET_STATE(state, { key, value }) {
    Vue.set(state, key, value)
  },
}

export const actions = {
  setState({ commit }, { key, value }) {
    commit('SET_STATE', { key, value })
  },
}

type ActionKeys = keyof typeof actions

type DispatchPayload = {
  key: keyof State
  value: ValuesType<State>
}

export function dispatch(action: ActionKeys, payload: DispatchPayload) {
  return store.dispatch(`${MODULE_KEY}/${action}`, payload)
}
