// import { cs } from '@shared/security/actionGuards'
import { getMainDefinition } from '../../utils'

import * as schema from './allGuardedOperations'
import store from '@state/store'

const checkDefinition = async (kind, operation) => {
  const validOps = operation === 'mutation' || operation === 'query'
  return validOps && kind === 'OperationDefinition'
}

// we use the state store to confirm if a guard should be used or not
// the store allows us to sync state between UI and the guard logic
const canThisGuardBeUsed = async key => {
  const exists = await store.getters[`actionGuards/guardExists`](key)

  return exists
}

const callGuard = async (key, variables, dialog) => {
  const guardFunction = schema.allGuardedOperations[key]
  if (!guardFunction) return null
  // return null in your guard handler if you don't want to continue
  // triggering this guard based on whatever conditions
  const guard = await guardFunction({ key, variables, dialog })
  if (guard) {
    await store.dispatch(`actionGuards/setGuard`, { key, guard, variables })
  }

  return guard
}

const startGuard = async (id, guard, variables) => {
  await store.dispatch(`actionGuards/setGuardOnDuty`, {
    id,
    body: guard,
    variables,
  })
}

export const maybeCallGuards = async (ast, variables, dialog) => {
  const { kind, name, operation } = getMainDefinition(ast)
  const key = name?.value

  const definitionPassed = await checkDefinition(kind, operation)
  if (!definitionPassed) return

  const canUseThisGuard = await canThisGuardBeUsed(key)
  if (!canUseThisGuard) return

  const guard = await callGuard(key, variables, dialog)
  if (!guard) return

  await startGuard(key, guard, variables)
}
