<script>
import { mapGetters } from 'vuex'
import appConfig from '@src/app.config'

export default {
  name: 'GlobalFooter',

  data() {
    return {
      appConfig,
    }
  },
  computed: {
    ...mapGetters('ui', ['links']),
  },
}
</script>

<template>
  <footer :class="$style.container">
    <div :class="$style.inner">
      <img
        :class="$style.logo"
        src="@assets/images/fp-logo-horizonal-white.svg"
        alt=""
      />

      <nav :class="$style.links">
        <base-link
          :class="$style.link"
          :href="links.brochure.terms"
          target="_blank"
        >
          Terms of Use
        </base-link>
        <base-link
          :class="$style.link"
          :href="links.brochure.privacy"
          target="_blank"
        >
          Privacy Policy
        </base-link>
        <base-link
          :class="$style.link"
          :href="links.brochure.url"
          target="_blank"
        >
          {{ appConfig.title }} &copy; {{ new Date().getFullYear() }}
        </base-link>
      </nav>
    </div>
  </footer>
</template>

<style lang="scss" module>
@import '@scss';
@import '~watson-scss';

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
  background: linear-gradient(47.39deg, #0070f3 0%, #57a3fc 99.6%);
}

.inner {
  @extend %center-content;

  padding-top: 24px;
  padding-bottom: 24px;

  @include mq($from: md) {
    display: flex;
    justify-content: space-between;
  }

  @include mq($until: md) {
    padding: 30px 20px 30px 20px;
  }
}

.logo {
  height: 24px;
}

.links {
  @include mq($from: md) {
    display: flex;
    align-items: center;
  }
}

.link {
  color: $color-white !important;
  text-decoration: none;

  @extend %font-footer;

  @include perfect-transition;

  svg {
    margin-right: 4px;
    color: rgba($color-mid-grey, 0.5) !important;
  }

  @include mq($from: md) {
    & + & {
      margin-left: 25px;
    }
  }

  @include mq($until: md) {
    &:first-child {
      padding-top: 20px;
    }

    display: block;
  }
}
</style>
