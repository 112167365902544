<template>
  <!-- todo: handle different input types. example here https://codesandbox.io/s/2wyrp5z000?from-embed -->
  <!-- https://medium.com/@logaretm/authoring-validatable-custom-vue-input-components-1583fcc68314 -->
  <div>
    <label v-if="label" v-bem:label :for="label">
      {{ label }}
    </label>

    <div
      v-bem="{
        disabled,
        readonly,
        $size,
        focus: isFocused,
        error,
        success,
        hasState,
      }"
      :class="$bem('::inner-box')"
      :style="{ width: finalWidth }"
    >
      <!-- real input element -->
      <!-- todo: fix blur not working with vee validate
      https://baianat.github.io/vee-validate/guide/events.html#changing-default-events -->
      <input
        :id="label"
        :ref="inputRef"
        v-bem:inner
        :type="type"
        :name="name"
        v-bind="$attrs"
        :readonly="disabled || readonly"
        :value="value"
        @input="inputHandler"
        @change="inputHandler"
      />

      <!-- placeholder -->
      <p v-if="!value" v-bem:placeholder>
        {{ $attrs.placeholder }}
      </p>

      <!-- TODO: clearable button -->

      <!-- icon or indicator, when there's a state like 'error', icon become indicator -->
      <span
        v-if="finalIcon"
        v-bem:icon="{ pointer: $listeners['icon-click'] }"
        @click="handleIconClick"
      >
        <fp-icon v-if="finalIcon" :name="finalIcon" />
      </span>

      <fp-hint-message v-if="hasState && !!message" :message="message" />

      <div v-if="value && maxLetterCount" v-bem:counter>
        {{ value && value.length }}/{{ maxLetterCount }}
      </div>
    </div>
  </div>
</template>

<script>
import useSize from '@component-mixins/useSize'
import useIcon from '@component-mixins/useIcon'
import useDisabled from '@component-mixins/useDisabled'
import useReadonly from '@component-mixins/useReadonly'
import useFocus from '@component-mixins/useFocus'
import useValidateState from '@component-mixins/useValidateState'

const STATE_TO_ICON_MAP = {
  error: 'exclamation-circle',
  success: 'check-circle',
}

export default {
  skipGloballyRegister: true,

  blockName: 'fp-input-arc',

  name: 'FpInputArchived',

  inject: ['$validator'],

  $_veeValidate: {
    // This is not documented well. When using raw html input returning the value
    // is fine like this: this.$el.value. But, if you use a custom component need to
    // get the value of the ref that is bound to the custom component's input. Otherwise,
    // the vee-validate confirm rule won't work. For "vue": "2.6.10". See:
    // https://github.com/baianat/vee-validate/issues/1896
    // https://codesandbox.io/s/9l7r2mlxwy
    value() {
      return this.$refs[this.inputRef].value
    },
    // name getter
    name() {
      return this.name
    },
  },
  mixins: [
    useSize,
    useIcon,
    useFocus,
    useDisabled,
    useReadonly,
    useValidateState,
  ],
  inheritAttrs: false,

  model: {
    prop: 'value',
    event: 'input',
  },

  props: {
    name: { type: String, default: '' },
    value: { type: String, default: '' },
    type: {
      type: String,
      default: 'text',
      validator: val => {
        return ['text', 'password', 'email'].indexOf(val) !== -1
      },
    },
    // validation will be outside the component
    // and just pass through the result to here
    message: { type: String, default: '' },
    label: { type: String, default: '' },
    error: { type: Boolean },
    success: { type: Boolean },

    clearable: { type: Boolean },

    maxLetterCount: { type: Number, default: 0 },
  },

  data() {
    return {
      inputRef: 'focusAnchor',
    }
  },

  computed: {
    finalIcon() {
      if (this.disabled) return 'ban'
      if (this.hasState) return this.stateIcon
      return this.icon
    },

    stateIcon() {
      return STATE_TO_ICON_MAP[this.state]
    },
  },

  methods: {
    inputHandler(ev) {
      this.$emit('input', ev.target.value)
    },

    clearAll() {
      this.$emit('input', '')
    },

    handleIconClick() {
      this.$emit('icon-click')
    },
  },
}
</script>

<style lang="scss" src="@component-styles/input.archived"></style>
