import AWSAppSyncClient, { createAppSyncLink } from 'aws-appsync'
import { Auth } from '@aws-amplify/auth'
import { ApolloLink } from 'apollo-link'
import { getGuardLink, errorLink } from '@graphql/links'

const useApiKey = process.env.VUE_APP_AUTH_MODE === 'apikey'
const APPSYNC_AUTH_TYPE = {
  NONE: 'NONE',
  API_KEY: 'API_KEY',
  AWS_IAM: 'AWS_IAM',
  AMAZON_COGNITO_USER_POOLS: 'AMAZON_COGNITO_USER_POOLS',
  OPENID_CONNECT: 'OPENID_CONNECT',
}

const getMiddlewareLinks = () => {
  let links = [getGuardLink(), errorLink]
  return links.filter(o => !!o)
}

export default function getDefaultClient() {
  const appSyncConfig = {
    url: process.env.VUE_APP_APPSYNC_URL,
    region: process.env.VUE_APP_AWS_DEFAULT_REGION,
    // Amplify uses Amazon IAM to authorize calls to Amazon S3.
    // This provides the relevant IAM credentials.
    complexObjectsCredentials: () => Auth.currentCredentials(),
    disableOffline: true,
  }

  if (useApiKey) {
    appSyncConfig.auth = {
      type: APPSYNC_AUTH_TYPE.API_KEY,
      apiKey: process.env.VUE_APP_APPSYNC_API_KEY,
    }
  } else {
    appSyncConfig.auth = {
      type: APPSYNC_AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      // amplify in theory refreshes the token automatically, if passed like this below:
      // https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/54
      // this is to avoid token expiry issues, e.g. after 1 hour inactivity
      jwtToken: async () =>
        (await Auth.currentSession()).getIdToken().getJwtToken(),
    }
  }

  // under the hood seems like appsync js is using createAppsyncLink if you
  // use appSyncConfig object. So either you use createAppSyncLink here
  // or appsync js does it for you if you pass in appSyncConfig into  AWSAppSyncClient
  // https://github.com/awslabs/aws-mobile-appsync-sdk-js/blob/1885a8a43bcb415808d16f85d4dd28ca4c87697b/packages/aws-appsync/src/client.ts#L225
  const appSyncLink = createAppSyncLink({
    ...appSyncConfig,
  })

  // WARNING, the ordering of the array for ApolloLink.from is important
  // the last one is appSyncLink
  const link = ApolloLink.from([...getMiddlewareLinks(), appSyncLink])

  // constructor api:
  // https://github.com/awslabs/aws-mobile-appsync-sdk-js/blob/1885a8a43bcb415808d16f85d4dd28ca4c87697b/packages/aws-appsync/src/client.ts

  // 'In order to initialize AWSAppSyncClient, you must specify url, region and auth properties on the config object or a custom link.'
  // it seems like you can init appsync with either apollo-link or appSynconfig, not both?
  // https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/3
  return new AWSAppSyncClient(
    // do not enable unless offline is needed. appsync does not clear old localstorage
    // items thus breaking when quota exceeded.
    // https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/359
    {
      disableOffline: true,
      cacheOptions: {
        // dataIdFromObject: object => {
        //   switch (object.__typename) {
        //     // modify cache configuration, issue: https://lowlatencymedia.atlassian.net/browse/PR-865
        //     // reference: https://www.apollographql.com/docs/react/v2/caching/cache-configuration
        //     case 'OrgHierarchy':
        //       return `${object.hierarchy}:${object.id}`
        //     default:
        //       return defaultDataIdFromObject(object) // fall back to default handling
        //   }
        // },
      },
    },
    {
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
        },
        // Force queries to get fresh data
        query: {
          fetchPolicy: 'network-only',
        },
      },
      link,
    }
  )
}
