import { createComponent } from '@vue/composition-api'
import { BEMProvider } from '@plugins/bem'
import { TextAlign } from 'types'
import { convertToUnit } from '@src/shared/utils'

const bem = BEMProvider('fp-table')

const classes = {
  unimportant: 'unimportant',
  emphasize: 'emphasize',
  secondary: 'secondary',
  info: 'info',
  disabled: 'disabled',
  error: 'error',
  success: 'success',
  warning: 'warning',
}

export type ColConfig = {
  text: string
  value: string
  width?: string | number
  align?: TextAlign
  vAlign?: 'top' | 'center' | 'bottom'

  emphasize?: boolean
  leader?: boolean
  ellipsis?: boolean

  fixed?: boolean
  fixPosition?: 'lef' | 'reight'
  offset?: number

  clickStop?: boolean
}

export default createComponent({
  name: 'FpTableCell',

  props: {
    columnConfig: Object,
    item: Object,
    slots: Object,
    selected: Boolean,
    toggleRowLoading: Function,
    toggleRowExpand: Function,
    rowLoading: Boolean,
    rowExpanded: Boolean,
    itemIndex: Number,
  },

  setup(props, { parent }) {
    return () => {
      const {
        columnConfig,
        item,
        slots,
        selected,
        toggleRowLoading,
        toggleRowExpand,
        itemIndex,
      } = props as { columnConfig: ColConfig; [key: string]: any }

      const key = columnConfig!.value

      if (!key) return () => null

      let slotValue = item![key]

      const {
        emphasize,
        secondary,
        ellipsis,
        unimportant,
        leader,
        align,
        fixed,
        width,
        fixPosition,
        __edge,
        clickStop,
      } = columnConfig as any

      const cellScopedSlot = slots![`cell.${key}`]

      if (cellScopedSlot) {
        slotValue = cellScopedSlot({
          value: item![key],
          item,
          columnConfig,
          key,
          toggleRowLoading,
          toggleRowExpand,
          rowLoading: props.rowLoading,
          rowExpanded: props.rowExpanded,
          classes,
          itemIndex,
        })
      }

      const cellEvents: any = {}

      if (clickStop) {
        cellEvents.click = (event: MouseEvent) => {
          event.stopPropagation()
        }
      }

      const CellVNode = (
        <div
          {...bem('::cell', {
            ellipsis,
            selected,
            emphasize,
            secondary,
            unimportant,
            leader,
            $align: align,
          })}
          on={cellEvents}
          {...(ellipsis === true && {
            style: {
              width: convertToUnit(columnConfig.width),
            },
          })}
        >
          {ellipsis === true ? (
            <v-tooltip
              top
              open-delay={500}
              scopedSlots={{
                activator: ({ on }) => {
                  const attrs = {
                    on: on,
                  }
                  return (
                    <div {...attrs} {...bem('::cell-tooltip-activator')}>
                      {slotValue}
                    </div>
                  )
                },
              }}
            >
              <span> {slotValue}</span>
            </v-tooltip>
          ) : (
            slotValue
          )}
        </div>
      )

      const isLeader = columnConfig.leader

      const isOperations = columnConfig.value === 'operations'

      return (
        <td
          {...bem('::td', {
            operations: isOperations,
            fixed: fixed && width,
            edge: __edge,
            $fixPosition: fixed ? `fix-${fixPosition}` : null,
          })}
          style={{
            width: convertToUnit(columnConfig.width),
            '--offset': fixed ? columnConfig.offset : 0,
          }}
        >
          {!isLeader && (
            <span {...bem('::mobile-th')}>{columnConfig.text}</span>
          )}
          {CellVNode}
        </td>
      )
    }
  },
})
