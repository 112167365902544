<template>
  <section slot="skeleton">
    <div class="layout align-center">
      <fp-skeleton
        height="40"
        width="85"
        bottom-margin="3"
        top-margin="3"
        round
      />
      <fp-skeleton height="20" width="45" left-margin="25" />
    </div>
  </section>
</template>

<script>
export default {
  name: 'FpSwitchNoLabelSkeleton',
}
</script>
