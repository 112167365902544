// NOTE: this file is a workaround for scss module issue with webpack v5 and vue 2

// globalColorPrimary: $color-primary;
// globalColorAccent: $color-accent;
// globalColorMidAccent: $color-mid-accent;
// globalColorDarkerAccent: $color-darker-accent;
// globalColorBlack: $color-black;
// globalColorDarkGrey: $color-dark-grey;
// globalColorDarkPrimary: $color-dark-primary;
// globalColorLighterPrimary: $color-lighter-primary;
// globalColorLightPrimary: $color-light-primary;
// globalColorMidPrimary: $color-mid-primary;
// globalColorLightGrey: $color-light-grey;
// globalColorMidGrey: $color-mid-grey;
// globalColorMidIce: $color-mid-ice;
// globalColorLightIce: $color-light-ice;
// globalColorDarkIce: $color-dark-ice;
// globalColorWhite: $color-white;

// // states
// globalColorSuccess: $color-success;
// globalColorWarning: $color-warning;
// globalColorError: $color-error;
// globalColorInfo: $color-info;
// globalColorDisabled: $color-disabled;

// // gradients
// globalGradientPrimary: $gradient-primary;

// // fonts
// headingFontFamily: $heading-font-family;

const globalColorPrimary = '#0c4ff5' // $color-primary
const globalColorAccent = '#ec5a29' // $color-accent
const globalColorMidAccent = '#ff784a' // $color-mid-accent
const globalColorDarkerAccent = '#ba2f00' // $color-darker-accent
const globalColorBlack = '#2e2d38' // $color-black
const globalColorDarkGrey = '#666' // $color-dark-grey
const globalColorDarkPrimary = '#0238be' // $color-dark-primary
const globalColorLighterPrimary = '#8bbffc' // $color-lighter-primary
const globalColorLightPrimary = '#57a3fc' // $color-light-primary
const globalColorMidPrimary = '#0070f3' // $color-mid-primary
const globalColorLightGrey = '#bdbdbd' // $color-light-grey
const globalColorMidGrey = '#8e8e8c' // $color-mid-grey
const globalColorMidIce = '#eef2fd' // $color-mid-ice
const globalColorLightIce = '#f6f7fc' // $color-light-ice
const globalColorDarkIce = '#e3e8f4' // $color-dark-ice
const globalColorWhite = '#fff' // $color-white

// states
const globalColorSuccess = '#6bd47c' // $color-success
const globalColorWarning = '#fbe44d' // $color-warning
const globalColorError = '#ec4f4d' // $color-error
const globalColorInfo = '#8e8e8c' // $color-info
const globalColorDisabled = '#e5e5e5' // $color-disabled

// gradients
const globalGradientPrimary =
  'linear-gradient(180deg, #57a3fc 0%, #0070f3 100%)'

// fonts
const headingFontFamily = "'Poppins', sans-serif"

export {
  globalColorPrimary,
  globalColorAccent,
  globalColorMidAccent,
  globalColorDarkerAccent,
  globalColorBlack,
  globalColorDarkGrey,
  globalColorDarkPrimary,
  globalColorLighterPrimary,
  globalColorLightPrimary,
  globalColorMidPrimary,
  globalColorLightGrey,
  globalColorMidGrey,
  globalColorMidIce,
  globalColorLightIce,
  globalColorDarkIce,
  globalColorWhite,
  globalColorSuccess,
  globalColorWarning,
  globalColorError,
  globalColorInfo,
  globalColorDisabled,
  globalGradientPrimary,
  headingFontFamily,
}
