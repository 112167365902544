<template>
  <li
    v-bem:step="{
      active: active || standByActive,
      completed,
      error,
      standByActive,
    }"
  >
    <strong v-bem:label>{{ label }}</strong>

    <div v-bem:wrapper>
      <div v-bem:line.left />

      <div
        v-bem:circle
        :data-cy="`step-${stepIndex + 1}`"
        @click="clickHandler"
      >
        <div v-bem:background />

        <div v-bem:indicator>
          <transition name="fade">
            <fp-icon
              v-if="completed && !active"
              :name="error ? 'exclamation' : 'check'"
            />
          </transition>
        </div>
      </div>

      <div v-bem:line.right />
    </div>
  </li>
</template>

<script>
export default {
  skipGloballyRegister: true,

  blockName: 'fp-stepper',

  name: 'FpOneStep',

  props: {
    label: {
      type: String,
      required: true,
    },

    stepIndex: {
      type: Number,
      required: true,
    },

    error: Boolean,
    completed: Boolean,
    active: Boolean,
    standByActive: Boolean,
  },

  data: () => ({}),

  methods: {
    clickHandler() {
      if (this.completed) {
        // Back to complteted step
        this.$emit('active', this.stepIndex)
      }

      if (this.standByActive) {
        // Re-active
        this.$emit('active', this.stepIndex)
      }
    },
  },
}
</script>
