<script>
import CcIconList from '@components/credit-card/cc-icon-list'

export default {
  name: 'CreditCard',
  components: {
    CcIconList,
  },
  props: {
    loading: Boolean,
    value: {
      type: String,
      default: ``,
    },
    savePaymentInfo: {
      type: Boolean,
      default: false,
    },
    creditCards: {
      type: Array,
      default: () => [{ text: '', value: '', icon: { name: 'credit-card' } }],
    },
  },

  methods: {
    handleInput(val) {
      this.$emit('input', val)
    },
    handleSavePaymentInfo(val) {
      this.$emit('on-payment-info-save', val)
    },
    handleCardRemove() {
      this.$emit('on-card-remove', this.value)
    },
  },
}
</script>

<template>
  <div>
    <cc-icon-list :loading="loading" />

    <fp-skeleton
      v-if="loading"
      round
      :loading="loading"
      :height="56"
      class="mt-2"
    />
    <template v-else>
      <div>
        <div :class="$style.selectBox">
          <fp-select
            class="mt-4"
            label="Select a credit card"
            with-icon
            :items="creditCards"
            :value="value"
            @input="handleInput"
          />

          <div
            v-if="value"
            data-cy="remove-card"
            :class="[$style.removeCard, $style.label, $style.clickable]"
            @click="handleCardRemove"
          >
            Remove Card
          </div>
        </div>

        <div v-if="value === ''">
          <label :class="$style.label"> New Credit Card Details: </label>

          <div :class="[$style.newCC, 'mb-8']">
            <slot></slot>
          </div>

          <div class="d-flex">
            <fp-checkbox
              class="mr-2"
              :value="savePaymentInfo"
              @change="handleSavePaymentInfo"
            />
            <label
              :class="[$style.label, $style.clickable]"
              @click="() => handleSavePaymentInfo(!savePaymentInfo)"
            >
              Securely save payment information for next time
            </label>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" module>
@import '@scss';

.selectBox {
  position: relative;
}

.removeCard {
  position: absolute;
  right: 0;
  bottom: 0;
}

.label {
  @extend %font-input-label;
}

.clickable {
  &:hover {
    color: $color-primary;
    cursor: pointer;
  }
}

.newCC {
  // sync margin with input label margins instead of mt helper
  margin-top: 10.6px;
}
</style>
