import { createComponent } from '@vue/composition-api'
import { BEMProvider } from '@plugins/bem'

const bem = BEMProvider('fp-table')

const columnTypesToBaseUrl = {
  'org': '/admin/account/',
  'game': '/games/',
  'client': '/clients/edit/',
}

// gets the ID from the data passed depending on the type.
// it builds the url string with the base url and id
// else it returns null
const getIdFromValueType = (data:any, baseUrl:string) => {
  let url:string|null = null

  if (data) {
    if (typeof data === 'string') {
      url = baseUrl + data
    } else if (typeof data === 'object' && 'id' in data) {
      url = baseUrl + data.id
    }
  }

  return url
}

// checks the columnType and returns the correct URL for the column.
// if none then it returns the main row url,
// else it will return null
const getColumnUrlFromData = (item:any, columnType:string|undefined, baseUrl:string|null|undefined = null) => {
  let link:{url: string, showUnderline: boolean}|null = null
  let potentialUrlCols = Object.keys(columnTypesToBaseUrl)

  // only allow this for certain pages that passes the baseUrl
  // this is because some pages and the item passed isn't structured the same way. e.g transacations-admin page
  // check if the column is a particular type and if the key is within item
  if (columnType && potentialUrlCols.includes(columnType) && (columnType in item)) {
    let check = getIdFromValueType(item[columnType], columnTypesToBaseUrl[columnType])

    if (check) {
      link = {
        url: check,
        showUnderline: true
      }
    }
  }

  // if link is null then set the baseUrl for the column
  if (baseUrl && link === null) {
    // rates doesn't use the id so we must check and use the correct one
    let url:string;

    if (baseUrl.includes('rates')) {
      url = baseUrl + item.game.id
    } else {
      url = baseUrl + item.id
    }

    link = {
      url: url,
      showUnderline: false
    }
  }


  return link;
}

export default createComponent({
  name: 'FpColumnLink',

  props: {
    baseUrl: String,
    columnType: String,
    item: Object
  },

  setup(props, context) {
    return () => {
      const {
        baseUrl,
        columnType,
        item
      } = props

      let hasLink = getColumnUrlFromData(item, columnType, baseUrl)

      let slot = context.slots.default()

      if (hasLink) {
        return (
          <router-link
              to={hasLink.url}
              {...bem('::link', {
                underline: hasLink.showUnderline
              })}
            >
              {slot}
          </router-link>
        )
      }

      return (
        {slot}
      )
    }
  },
})
