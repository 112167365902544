<script>
import vuetifyIcon from '@component-mixins/vuetifyIcon'
import {
  FPDate,
  formatDate,
  cs,
  FP_TIME_FORMAT,
  timeStringToUnixTimestamp,
  FP_PRETTY_DATETIME_FORMAT,
  substring,
} from '@utils'
import { isValid, parseISO } from 'date-fns'

const dateStrIndex = 0
const timeStrIndex = 1
const VALIDATOR_KEY_VALUE = 'value'

const genAWSTimestampValidateor = keyName => {
  return function (val) {
    if (val === undefined) {
      return true
    }

    // for datetime string values
    if (keyName === VALIDATOR_KEY_VALUE && typeof val === 'string') {
      return true
    }

    const valid = isValid(new Date(val * 1000))

    if (val.toString().length !== 10) {
      cs.e(
        `${keyName} not match AWSTimestamp format (10 digits number)`,
        'Date Picker:'
      )
      return false
    }

    if (!valid) {
      cs.w(`value invalid: ${val}`, 'Date Picker:')
      return false
    }

    return true
  }
}

export default {
  blockName: 'fp-date-time',

  name: 'FpDateTimePicker',

  mixins: [vuetifyIcon],

  inheritAttrs: false,

  props: {
    label: {
      type: String,
      default: 'Select date',
    },
    message: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    // Standard timestamp
    defaultTime: {
      type: [String, Number],
      default: null,
    },
    displayFormat: {
      type: String,
      default: FP_PRETTY_DATETIME_FORMAT,
    },
    // this prop determine which YYYY-MM showed at first sight after picker popuped
    // eg. '2020-03'
    displayedYearMonth: {
      type: String,
      default: undefined,
    },

    // ⚠️ AWSTimestamp is a number of 10 digits(no million-second), eg. 1547056404
    // multiple 1000 to convert js-handlable timestamp
    value: {
      type: [Number, String],
      default: null,
      validator: genAWSTimestampValidateor(VALIDATOR_KEY_VALUE),
    },
    valueAsDatetimeString: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: undefined,
      validator: genAWSTimestampValidateor('min'),
    },
    max: {
      type: Number,
      default: undefined,
      validator: genAWSTimestampValidateor('max'),
    },

    timePickerProps: {
      type: Object,
      default: () => ({}),
    },
    datePickerProps: {
      type: Object,
      default: () => ({}),
    },
    datePickerOnly: Boolean,

    // Reuse the CY attribute from the parent component
    // Avoids duplicate DONE or CANCEL button cy tags
    cy: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      dialogVisible: false,
      dialogRef: 'dialog',
    }
  },

  computed: {
    width() {
      return '800px'
    },

    dateComputed() {
      let { value } = this
      if (value === null) {
        return null
      }

      if (this.valueAsDatetimeString === true) {
        if (typeof value !== 'string') return ''
        const x = value.split(' ')[dateStrIndex]
        return x
      }

      const rv = this.AWSTimestampToISO8601(value)

      return rv
    },

    timeComputed() {
      let { value } = this

      if (value === null) {
        return null
      }

      if (this.valueAsDatetimeString === true) {
        if (typeof value !== 'string') {
          return this.defaultTime || formatDate(Date.now, FP_TIME_FORMAT)
        }
        const x = value.split(' ')[timeStrIndex]
        return x
      }

      const o = this.AWSTimestampToISO8601(value, FP_TIME_FORMAT)

      return o
    },

    minDateComputed() {
      let { min } = this

      if (!min) return undefined

      const x = this.AWSTimestampToISO8601(min)

      return x
    },

    maxDateComputed() {
      let { max } = this

      if (!max) return undefined

      return this.AWSTimestampToISO8601(max)
    },

    valueToDisplay() {
      let timeString = ''

      if (this.value === null) {
        return ''
      }

      if (this.valueAsDatetimeString === true) {
        if (typeof this.value !== 'string') {
          return ''
        }
        timeString = this.value
      } else {
        const { timeComputed, dateComputed } = this

        timeString = `${dateComputed} ${timeComputed}`
      }

      const date = parseISO(timeString)
      const rv = formatDate(date, this.displayFormat)

      return rv
    },

    dateProps() {
      const {
        dateComputed,
        minDateComputed,
        maxDateComputed,
        datePickerProps,
      } = this

      let value = dateComputed

      if (this.datePickerProps.type === 'month') {
        value = substring(value, 0, 7)
      } else if (this.datePickerProps.type === 'year') {
        value = substring(value, 0, 4)
      }

      return {
        value,
        min: minDateComputed,
        max: maxDateComputed,
        ...datePickerProps,
      }
    },

    timeProps() {
      const { timeComputed, timePickerProps } = this
      return {
        value: timeComputed,
        ...timePickerProps,
      }
    },
  },

  watch: {
    tabindex(val) {
      this.setTabIndex(val)
    },

    dialogVisible(val) {
      if (val) {
        if (!this.value) {
          const result = this.converToAWSTimestamp(
            this.defaultTime || Date.now()
          )

          this.$emit('input', result)
        }
      }
    },

    displayedYearMonth(val) {
      if (val && this.$refs.picker) {
        this.$refs.picker.tableDate = val
      }
    },
  },

  mounted() {
    this.setTabIndex(-1)
  },

  methods: {
    setTabIndex(index) {
      if (this.$el) {
        Array.from(this.$el.children).forEach(el => {
          el.setAttribute('tabindex', index.toString())
        })
      }
    },

    saveDialog() {
      this.$refs[this.dialogRef].save(this.value)
    },

    converToAWSTimestamp(timeString) {
      // ⚠️ ⚠️ ⚠️ ⚠️ ⚠️ ⚠️//
      // do not use built-in javascript date functions to parse date
      // it is unreliable across safari and ie, etc. Always use a good date library
      const b = timeStringToUnixTimestamp(timeString)
      const c = Math.floor(b)
      return c
    },

    AWSTimestampToISO8601(value, format = 'yyyy-MM-dd') {
      const valueInNumber = Number(value)

      return formatDate(valueInNumber * 1000, format)
    },

    changeValue(timeValue, dateValue) {
      if (timeValue === null) {
        // eslint-disable-next-line no-param-reassign
        timeValue = this.defaultTime || formatDate(Date.now(), FP_TIME_FORMAT)
      }

      if (dateValue === null) {
        // eslint-disable-next-line no-param-reassign
        dateValue = this.AWSTimestampToISO8601(
          FPDate(new Date()).getAWSTimestamp().result()
        )
      }

      let timeString = `${dateValue} ${timeValue}`
      let result = null

      if (this.valueAsDatetimeString === true) {
        // add seconds, ideally we should use a timepicker from the vuetify lib and use-seconds prop
        // we assume the timepicker in the datepicker at this stage does not support seconds, but default to 00 seconds
        // seconds is require for some backend requests, such as createAd
        const toks = timeValue.split(':')
        if (toks.length < 3) {
          // eslint-disable-next-line no-param-reassign
          timeString = timeString + ':00'
        }
        result = timeString
      } else {
        result = this.converToAWSTimestamp(timeString)
      }

      this.$emit('input', result)
    },

    handleDateChange(dateValue) {
      this.changeValue(this.timeComputed, dateValue)
    },

    handleTimeChange(event) {
      this.changeValue(event.target.value, this.dateComputed)
    },
  },
}
</script>

<template>
  <v-dialog
    :ref="dialogRef"
    v-model="dialogVisible"
    attach="#app-wrapper"
    :width="width"
    :return-value.sync="value"
  >
    <template v-slot:activator="{ on }">
      <div class="fp-date-time__input_box" v-on="on">
        <fp-input
          v-bind="$attrs"
          :value="valueToDisplay"
          readonly
          append-icon="calendar-day"
          :label="label"
          :message="message"
          :error="error"
        />
      </div>
    </template>

    <div v-bem>
      <v-date-picker
        ref="picker"
        v-bind="dateProps"
        scrollable
        landscape
        full-width
        :reactive="true"
        :prev-icon="vuetifyIcon('angle-left')"
        :next-icon="vuetifyIcon('angle-right')"
        @change="handleDateChange"
      />

      <section v-if="!datePickerOnly" v-bem:time>
        <input
          v-bem:time-input
          type="time"
          :value="timeComputed"
          @change="handleTimeChange"
        />
      </section>

      <v-row
        tag="footer"
        justify="end"
        align="center"
        class="fp-date-time__footer mb-1"
      >
        <fp-button
          size="small"
          type="text"
          :data-cy="'date-picker-cancel--' + cy"
          @click="dialogVisible = false"
        >
          Cancel
        </fp-button>

        <fp-button
          size="small"
          type="text"
          :data-cy="'date-picker-done--' + cy"
          @click="saveDialog()"
        >
          Done
        </fp-button>
      </v-row>
    </div>
  </v-dialog>
</template>

<style lang="scss" src="@component-styles/date-time-picker"></style>
