import gql from 'graphql-tag'

export const output = gql`
  fragment output on mutationOutput {
    id
    success
    message
    statusCode
  }
`
