<template>
  <div v-bem="{ ice, hasLabel: !!label, withChip }">
    <v-autocomplete
      ref="autocomplete"
      v-bind="propsPenetrating"
      v-on="$listeners"
      @change="inputHandler"
    >
      <template v-if="withAvatar || withIcon || withChip" #selection="data">
        <img
          v-if="data.item.avatar"
          v-bem:item-avatar
          :width="itemAvatarWidth"
          :src="data.item.avatar"
        />

        <v-avatar v-if="data.item.icon" left>
          <fp-icon
            :prefix="data.item.icon.prefix ? data.item.icon.prefix : 'fa'"
            :name="data.item.icon.name"
            size="lg"
          />
        </v-avatar>

        <div v-if="data.item.chip" v-bem:item-chip @click="data.select">
          <fp-chip
            v-if="data.item.chip.name"
            class="mr-2"
            type="primary"
            small
            label
          >
            <strong>{{ data.item.chip.name }}</strong>
          </fp-chip>
        </div>

        {{ data.item.text }}
      </template>

      <template v-else-if="ellipsis" #selection="{ item, index }">
        <v-chip
          v-if="index < 2 && !hideChips.includes(item.value)"
          close
          :disabled="propsPenetrating.disabled"
          @click:close="deleteItem(item, index)"
        >
          <span>{{ item.text }}</span>
        </v-chip>
        <span v-if="index === 2" class="grey--text text-caption">
          (+{{ valueToPenetrate.length - 2 }} others)
        </span>
      </template>

      <template v-if="withAvatar || withIcon || withChip" #item="data">
        <img
          v-if="data.item.avatar"
          v-bem:item-avatar
          :width="itemAvatarWidth"
          :src="data.item.avatar"
        />
        <v-avatar v-if="data.item.icon" left>
          <fp-icon
            :prefix="data.item.icon.prefix ? data.item.icon.prefix : 'fa'"
            :name="data.item.icon.name"
            size="lg"
          />
        </v-avatar>

        <div v-if="data.item.chip">
          <fp-chip
            v-if="data.item.chip.name"
            class="mr-2"
            type="primary"
            small
            label
          >
            {{ data.item.chip.name }}
          </fp-chip>
        </div>

        <v-list-item-content>
          <v-list-item-title> {{ data.item.text }} </v-list-item-title>
        </v-list-item-content>
      </template>
      <template v-else-if="hasCustomOption" #item="data">
        <v-list-item-content>
          <slot name="custom-option" :option="data" />
        </v-list-item-content>
      </template>

      <template #no-data>
        <div v-bem:no-data>
          <slot name="no-data" />
        </div>
      </template>
      <template v-if="appendOuterTooltip && !appendOuterIcon" #append-outer>
        <div class="v-input__icon v-input__icon--append-outer">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span
                aria-hidden="true"
                class="v-icon notranslate theme--light"
                v-on="on"
              >
                <slot name="append-outer" />
              </span>
            </template>
            {{ appendOuterTooltip }}
          </v-tooltip>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import vuetifyIcon from '@component-mixins/vuetifyIcon'

export default {
  name: 'FpSelect',

  inject: ['$validator'],

  mixins: [vuetifyIcon],

  $_veeValidate: {
    value() {
      return this.value
    },
    name() {
      return this.name
    },
  },

  inheritAttrs: false,

  model: {
    value: 'value',
    event: 'input',
  },

  props: {
    name: { type: String, default: '' },
    icon: { type: String, default: '' },
    appendOuterIcon: { type: String, default: '' },

    noDataText: { type: String, default: '' },
    hint: { type: String, default: '' },

    // label and placeholder all are considered as label
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },

    // either be single value or an array comprimise items' value
    // eslint-disable-next-line
    value: { required: true },
    items: { type: Array, default: () => [] },
    autoSelectFirst: { type: Boolean, default: true },

    itemAvatarWidth: { type: Number, default: 20 },
    withAvatar: Boolean,
    withIcon: Boolean,
    withChip: { type: Boolean, default: false },

    outlined: { type: Boolean, default: true },
    ice: Boolean,

    multiple: Boolean,

    persistentHint: { type: Boolean, default: true },
    required: Boolean,

    error: Boolean,
    message: { type: String, default: '' },

    ellipsis: { type: Boolean, default: false },
    hideChips: { type: Array, default: () => [] },
    appendOuterTooltip: { type: String, default: '' },
    hasCustomOption: { type: Boolean, default: false },
  },

  computed: {
    valueToPenetrate() {
      if (this.multiple) {
        return (
          this.value &&
          this.value.map(value => {
            return this.findItem(value)
          })
        )
      }
      return this.findItem(this.value)
    },

    propsPenetrating() {
      const {
        autoSelectFirst,
        label,
        placeholder,
        valueToPenetrate,
        error,
        items,
        multiple,
        outlined,
        ice,
        message: errorMessages,
        appendIcon,
        appendOuterIcon,
        prependIcon,
        icon,
        required,
        persistentHint,
        noDataText,
        hint,
        $attrs,
      } = this

      let formatAppend = this.vuetifyIcon(appendIcon || 'angle-down')
      let formatAppendOuter = this.vuetifyIcon(appendOuterIcon)
      let formatPrepend = this.vuetifyIcon(prependIcon || icon)
      let formatClear = this.vuetifyIcon('circle-cross')

      const props = {
        autoSelectFirst,
        label: label || placeholder,
        placeholder,
        outlined,
        items,
        dark: ice,
        value: valueToPenetrate,
        multiple,
        errorMessages,
        error,
        hint,
        persistentHint,
        appendIcon: formatAppend,
        appendOuterIcon: formatAppendOuter,
        prependInnerIcon: formatPrepend,
        clearIcon: formatClear,
        noDataText:
          noDataText || items?.length
            ? `Nothing selectable for ${this.$refs.autocomplete?.lazySearch}`
            : 'Nothing selectable',
        ...$attrs,
        disabled: $attrs.loading || $attrs.disabled,
      }

      if (multiple) {
        props.chips = true
      }

      if (required) {
        props.label += '*'
      }

      return props
    },
  },

  methods: {
    findItem(value) {
      return this.items?.find(
        item => item[this.$attrs['item-value'] || 'value'] === value
      )
    },

    inputHandler(valueFromVSelect) {
      // maintain the same order as the items when selected
      const mapOrder = () =>
        this.items
          .filter(el => {
            const match = valueFromVSelect.indexOf(el?.value) > -1
            return match
          })
          .map(o => {
            return o?.value
          })

      const value4Single = valueFromVSelect || ''

      const out = this.multiple ? mapOrder() : value4Single
      this.$emit('input', out)
    },

    deleteItem(item, index) {
      const rv = [...this.value]
      rv.splice(index, 1)
      this.$emit('input', rv)

      // when deleting an item, we need to emit for the on change event as well
      this.$emit('change', rv)
    },
  },
}
</script>

<style lang="scss" src="@component-styles/select"></style>
