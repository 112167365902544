var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.label)?_c('label',{directives:[{name:"bem",rawName:"v-bem:label",arg:"label"}],attrs:{"for":_vm.label}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{directives:[{name:"bem",rawName:"v-bem",value:({
      disabled: _vm.disabled,
      readonly: _vm.readonly,
      focus: _vm.isFocused,
      $size: _vm.$size,
      error: _vm.error,
      success: _vm.success,
      hasState: _vm.hasState,
    }),expression:"{\n      disabled,\n      readonly,\n      focus: isFocused,\n      $size,\n      error,\n      success,\n      hasState,\n    }"}],style:({ width: _vm.finalWidth })},[_c('p',{directives:[{name:"bem",rawName:"v-bem:prefix",arg:"prefix"}]},[_vm._v(" "+_vm._s(_vm.prefix)+" ")]),_c('input',_vm._b({directives:[{name:"bem",rawName:"v-bem:inner",arg:"inner"}],ref:"focusAnchor",attrs:{"type":"number","readonly":_vm.disabled || _vm.readonly},domProps:{"value":_vm.value},on:{"input":_vm.inputHandler}},'input',_vm.$attrs,false)),_c('p',{directives:[{name:"bem",rawName:"v-bem:display",arg:"display"}]},[_vm._v(" "+_vm._s(_vm.numberFormated)+" ")]),_c('div',{class:_vm.$bem('::btn :minus'),on:{"click":_vm.minus}},[_c('fp-icon',{attrs:{"name":_vm.disabled ? 'ban' : 'minus'}})],1),_c('div',{class:_vm.$bem('::btn :plus'),on:{"click":_vm.plus}},[_c('fp-icon',{attrs:{"name":_vm.disabled ? 'ban' : 'plus'}})],1),(_vm.hasState)?_c('fp-hint-message',{attrs:{"message":_vm.message}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }