import { createComponent } from '@vue/composition-api'

import style from './title.module.scss'

export default createComponent({
  name: 'FpTitle',

  props: {
    title: {
      type: String,
      required: true,
    },
    strong: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    return () => {
      return (
        <div class={[style.titleWrap]}>
          <v-tooltip
            top
            scopedSlots={{
              activator: ({ on }) => {
                const attrs = {
                  class: [style.title],
                  on: on,
                }

                if (props.strong)
                  return <strong {...attrs}>{props.title}</strong>

                return <div {...attrs}>{props.title}</div>
              },
            }}
          >
            <span> {props.title}</span>
          </v-tooltip>
        </div>
      )
    }
  },
})
