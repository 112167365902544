<template>
  <div
    v-bem="{ dark, $shadow: `${shadow}-shadow`, noBodyPad }"
    :style="{
      '--padding': padding,
      'min-height': height,
      height: '100%',
      background: background,
    }"
  >
    <div
      v-if="hasSlot('label')"
      v-bem:header="{ large: largeLabel, noBodyPad }"
    >
      <v-container fluid class="pa-0">
        <v-row no-gutters justify="space-between" align="center">
          <v-col :sm="hasSlot('header-right') ? '6' : '12'" cols="12">
            <div v-bem:label="{ large: largeLabel }">
              <slot name="label" />
            </div>
          </v-col>

          <v-col v-if="hasSlot('header-right')" class="text-lg-right">
            <slot name="header-right" />
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div v-if="hasSlot('default')" :class="defaultSlotPadding">
      <slot />
    </div>

    <div v-if="hasSlot('footer-left') || hasSlot('footer-right')" v-bem:footer>
      <div v-if="hasSlot('footer-left')">
        <slot name="footer-left" />
      </div>

      <div v-if="hasSlot('footer-right')">
        <slot name="footer-right" />
      </div>
    </div>

    <div v-if="hasSlot('dialog')" v-bem:dialog>
      <slot name="dialog" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FpCard',

  props: {
    dark: Boolean,

    shadow: {
      type: String,
      default: 'big',
    },

    height: {
      type: String,
      default: '',
    },

    background: {
      type: String,
      default: '',
    },

    padding: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: null,
    },

    largeLabel: Boolean,
    noBodyPad: Boolean,
  },

  computed: {
    defaultSlotPadding() {
      return this.hasSlot('footer-left') || this.hasSlot('footer-right')
        ? 'pb-6'
        : ''
    },
  },

  methods: {
    hasSlot(name) {
      return !!this.$slots[name]
    },
  },
}
</script>

<style lang="scss" src="@component-styles/card"></style>
