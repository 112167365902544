/** @ts-ignore */
// the V-IMG component  https://vuetifyjs.com/en/components/images
// we may need to setup ImageMagick to do this see: https://github.com/vuetifyjs/vuetify-loader
import Vue from 'vue'
import { iconList } from './vuetify-icons.js'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import camelCase from 'lodash/camelCase'
import kebabCase from 'lodash/kebabCase'

import Ripple from 'vuetify/lib/directives/ripple'
import Resize from 'vuetify/lib/directives/resize'

import Vuetify, {
  VApp,
  VAlert,
  VAutocomplete,
  VAvatar,
  VLayout,
  VTextField,
  VTextarea,
  VDataTable,
  VPagination,
  VLabel,
  VIcon,
  VImg,
  VInput,
  VCheckbox,
  VContainer,
  VCombobox,
  VCol,
  VMenu,
  VList,
  VListItem,
  VListItemTitle,
  VListItemContent,
  VListItemAction,
  VDialog,
  VCard,
  VCardTitle,
  VCardText,
  VCardActions,
  VTooltip,
  VTreeview,
  VBottomNavigation,
  VFlex,
  VDatePicker,
  VBtn,
  VSpacer,
  VTabs,
  VTab,
  VTabItem,
  VTimePicker,
  VNavigationDrawer,
  VChip,
  VRow,
  VProgressCircular,
  VSelect,
  VExpandTransition,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelHeader,
  VExpansionPanelContent,
} from 'vuetify/lib'

// Helper function
// Loop icon values from vuetify-icons.js
function setIconValues(array) {
  let items = {}
  for (let index = 0; index < array.length; index++) {
    const icon = array[index].split('fa')[1]

    items[camelCase(icon)] = {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', kebabCase(icon)],
      },
    }
  }
  return items
}

// Vuetify constructor options
const opts = {
  icons: {
    iconfont: 'faSvg',
    values: {
      ...setIconValues(iconList),

      // Override Vuetify component defaults
      // $vuetify.icons.fileCopy
      fileCopy: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'copy'],
        },
      },
      // $vuetify.icons.dropdown
      dropdown: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'angle-down'],
        },
      },
      // $vuetify.icons.next
      next: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'chevron-right'],
        },
      },
      // $vuetify.icons.prev
      prev: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'chevron-left'],
        },
      },
      // $vuetify.icons.sort
      sort: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'angle-down'],
        },
      },

      delete: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'times-circle'],
        },
      },
      checkboxOn: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'check-square'],
        },
      },
      checkboxOff: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'square'],
        },
      },
      checkboxIndeterminate: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'minus-square'],
        },
      },
      subgroup: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'angle-down'],
        },
      },
      loading: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'spinner'],
        },
      },
      clear: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'times'],
          size: 'sm',
        },
      },
      circleCross: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'times-circle'],
          size: 'sm',
        },
      },
      info: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'info-circle'],
        },
      },
      warning: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'exclamation-triangle'],
        },
      },
      error: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'times-circle'],
        },
      },
    },
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#0070f3',
        secondary: '#666',
        success: '#6bd47c',
        accent: '#ec5a29',
        error: '#ec4f4d',
        info: '#8e8e8c',
      },
    },
  },

  // https://vuetifyjs.com/en/customization/breakpoints

  // Conditionals
  // xsOnly: boolean
  // smOnly: boolean
  // smAndDown: boolean
  // smAndUp: boolean
  // mdOnly: boolean
  // mdAndDown: boolean
  // mdAndUp: boolean
  // lgOnly: boolean
  // lgAndDown: boolean
  // lgAndUp: boolean
  // xlOnly: boolean

  // breakpoint: {
  //   thresholds: {
  //     xs: 0,
  //     sm: 500,
  //     md: 1020,
  //     lg: 1280,
  //     xl: 1700,
  //   },
  //   scrollBarWidth: 0,
  // },
}

// Declare for default export
const useVuetify = () => {
  Vue.use(Vuetify, {
    components: {
      VApp,
      VAlert,
      VAutocomplete,
      VAvatar,
      VMenu,
      VLayout,
      VFlex,
      VCheckbox,
      VContainer,
      VCombobox,
      VCol,
      VTextField,
      VDataTable,
      VTextarea,
      VPagination,
      VLabel,
      VListItemContent,
      VListItemAction,
      VIcon,
      VTooltip,
      VImg,
      VInput,
      VDialog,
      VCard,
      VCardTitle,
      VCardText,
      VCardActions,
      VBottomNavigation,
      VChip,
      VDatePicker,
      VBtn,
      VSpacer,
      VTabs,
      VTab,
      VTabItem,
      VTimePicker,
      VTreeview,
      VRow,
      VList,
      VListItem,
      VListItemTitle,
      VNavigationDrawer,
      VProgressCircular,
      VSelect,
      VExpandTransition,
      VExpansionPanels,
      VExpansionPanel,
      VExpansionPanelHeader,
      VExpansionPanelContent,
    },

    directives: {
      Ripple,
      Resize,
    },
  })
}

// Default for @plugins usage in Vuetify & Project
export default useVuetify

export const vuetify = new Vuetify(opts)
