/*
 * central place to put tagged template literals
 * good reference https://www.leighhalliday.com/tagged-template-literals
 */

export function oneLine(strings, ...placeholders) {
  let withSpace = strings.reduce(
    (result, string, i) => result + placeholders[i - 1] + string
  )
  let withoutSpace = withSpace.replace(/$\n^\s*/gm, ' ')
  return withoutSpace
}

// example usage:

// const addressFormat = embedFuncs`
//   ${args => args.street_number}
//   ${args => args.route},
//   ${args => args.locality}`

// const output = oneLine`${addressFormat({
//   street_number: 'black',
//   route: 15,
//   locality: 'asd',
// })}`

export function embedFuncs(strings, ...args) {
  const interleaved = args.reduce(
    (acc, arg, index) => {
      return [...acc, arg, strings[index + 1]]
    },
    [strings[0]]
  )

  return args =>
    interleaved
      .map(part => (typeof part === 'function' ? part(args) : part))
      .join('')
}
