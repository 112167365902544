<template>
  <div
    v-bem="{ disabled, $size, $type: type, fill }"
    :style="{
      '--item-width': finalItemWidth,
    }"
  >
    <div v-bem:track>
      <div v-if="value !== ''" v-bem:slider :style="sliderOffset" />

      <span
        v-for="item in items"
        :key="item.value"
        v-bem:item="{ active: item.value === value }"
        :tabindex="tabindex"
        :data-cy="`toggle-${item.value}`"
        @keyup.enter="
          $event => {
            changeValue(item.value)
            $event.target.blur()
          }
        "
        @click="_ => changeValue(item.value)"
      >
        <span>{{ item.text }}</span>
      </span>
    </div>
  </div>
</template>

<script>
import { convertToUnit } from '@utils'

import useDisabled from '@component-mixins/useDisabled'
import useSize from '@component-mixins/useSize'

export default {
  name: 'FpToggle',

  mixins: [useDisabled, useSize],

  props: {
    value: {
      type: [String, Number, Boolean],
      required: true,
    },

    type: {
      default: 'primary',
      type: String,
    },

    items: {
      type: Array,
      required: true,
    },

    itemWidth: {
      type: [Number, String],
      default: 150,
    },

    tabindex: {
      type: [String, Number],
      default: 2,
    },
  },

  computed: {
    finalItemWidth() {
      // If this.fill is true, split 100% equally to each item
      return this.fill || this.$vuetify.breakpoint.xs
        ? convertToUnit(this.getPercentageWidth(), '%')
        : convertToUnit(this.itemWidth)
    },

    currentActiveIndex() {
      return this.items.findIndex(item => item.value === this.value)
    },

    sliderOffset() {
      if (this.currentActiveIndex !== -1) {
        return {
          left:
            this.fill || this.$vuetify.breakpoint.xs
              ? convertToUnit(
                  this.currentActiveIndex * this.getPercentageWidth(),
                  '%'
                )
              : convertToUnit(this.currentActiveIndex * this.itemWidth),
        }
      }

      return {
        left: 0,
        opacity: 0,
      }
    },
  },

  methods: {
    convertToUnit,

    getPercentageWidth() {
      const number = 100 / this.items.length
      return number
    },

    changeValue(value) {
      if (this.disabled) return
      this.$emit('input', value)
      this.$emit('change', value)
    },
  },
}
</script>

<style lang="scss" src="@component-styles/toggle"></style>
