// @ts-ignore-next-line
import Footer from '@components/bottom-nav'
// @ts-ignore-next-line
import FormLayout from '@layouts/form-layout'
// @ts-ignore-next-line
import PageHeadForForm from '@components/page-head/for-form'
import style from './metaTag.module.scss'

import {
  reactive,
  watch,
  createComponent,
  onMounted,
} from '@vue/composition-api'
import * as tagsLib from '@shared/tag'
import { useList } from '@components/hooks/useList'
import { fillArray } from '@utils'

export default createComponent({
  name: 'MetaTags',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    tags: {
      type: Array,
      default: () => [],
    },
    formText: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
    validateTags: {
      type: Boolean,
    },
    cypressPrefix: {
      type: String,
      default: 'cy-fp-meta-tag',
    },

    // required: Boolean,
    error: Boolean,
    message: { type: String, default: '' },
    maxTagLen: {
      type: [Number],
      default: 5,
    },
  },
  setup(props: any, ctx) {
    const itemsAPI = useList(fillArray(props.items.length))
    const state = reactive<any>({
      types: [tagsLib.INTERNAL_KEY],
      listOfTags: [],
      errors: [],
      messages: [],
    })

    const tagsState = reactive<any>({
      types: [
        {
          text: tagsLib.AD_RESTRICTING_NAME,
          value: tagsLib.AD_RESTRICTING_KEY,
          disabled: false,
        },
        {
          text: tagsLib.GAME_RESTRICTING_NAME,
          value: tagsLib.GAME_RESTRICTING_KEY,
          disabled: false,
        },
        {
          text: tagsLib.INTERNAL_NAME,
          value: tagsLib.INTERNAL_KEY,
          disabled: false,
        },
      ],
      items: [...props.tags],
    })

    const mapFormModelToEmittedValue = () => {
      return state.listOfTags.map((tags, i) => ({
        items: tags,
        type: state.types[i],
      }))
    }

    const refreshTypesDisabled = () => {
      // reset all to disabled false
      tagsState.types = tagsState.types.map(o => ({ ...o, disabled: false }))

      // disable types that are selected for further selection
      tagsState.types = tagsState.types.map(o => {
        const tmp = { ...o }
        if (state.types.includes(tmp.value)) {
          tmp.disabled = true
        }
        return tmp
      })
    }

    const handleTypesChange = (val, i) => {
      let output = mapFormModelToEmittedValue()
      output[i].type = val
      ctx.emit('input', output)

      refreshTypesDisabled()
    }

    const validateTags = (tags: string[]): [boolean, string] => {
      let errMsg = ''
      if (tags.length > props.maxTagLen) {
        return [true, `The max number of tags is ${props.maxTagLen}`]
      }
      if (tags.length <= 0) {
        return [true, 'At least one tag is required']
      }
      const valid = tags.every(s => {
        const [valid, message] = tagsLib.validateTag(s)
        if (!valid) {
          errMsg = message
        }
        return valid
      })

      return [valid, errMsg]
    }

    const handleListOfTagsChange = (val: string[], i) => {
      // validate
      const [valid, message] = validateTags(val)
      state.errors[i] = !valid
      state.messages[i] = message

      // emit
      let output: any = mapFormModelToEmittedValue()
      output[i].items = val
      ctx.emit('input', output)
    }

    const addItem = () => {
      const nextIndex = itemsAPI.state.items.length
      itemsAPI.add(nextIndex)
      state.listOfTags.push([])
      const nextType = tagsState.types.find(o => !o.disabled)
      state.types.push(nextType?.value ?? '')
      const output = mapFormModelToEmittedValue()
      ctx.emit('input', output)
    }

    const removeItem = i => {
      // restore type selectable
      refreshTypesDisabled()

      // only do the removal after type selectable restoration
      itemsAPI.removeAt(i)
      state.listOfTags.splice(i, 1)
      state.types.splice(i, 1)

      const output = mapFormModelToEmittedValue()
      ctx.emit('input', output)
    }

    onMounted(() => {
      // for use case -> back button and next again back to this step
      state.listOfTags.forEach((tags, i) => {
        const [valid, message] = validateTags(tags)
        state.errors[i] = !valid
        state.messages[i] = message
      })
    })

    // Update prop
    watch(
      () => props.items,
      value => {
        itemsAPI.set(fillArray(value.length))
        state.types = value.map(o => o.type)
        state.listOfTags = value.map(o => o.items)

        refreshTypesDisabled()
      },
      { deep: true }
    )

    watch(
      () => props.tags,
      value => {
        tagsState.items = [...(value || [])]
      },
      { deep: true }
    )

    // use cases: turn on swith off and on
    watch(
      () => props.validateTags,
      value => {
        if (value === true) {
          state.listOfTags.forEach((tags, i) => {
            const [valid, message] = validateTags(tags)
            state.errors[i] = !valid
            state.messages[i] = message
          })
        }
      }
    )

    return () => {
      return (
        <div>
          {itemsAPI.state.items.map((item, _) => {
            const deletable =
              itemsAPI.state.items.length > 1 ||
              state.types[item] ||
              state.listOfTags[item]

            return (
              <v-container fluid class="px-0">
                {' '}
                <v-row>
                  <v-col cols="4" class="pb-0">
                    <div class={style.xAndAmount}>
                      <div class={style.deleteBtn}>
                        {deletable && !props.loading && (
                          <fp-icon-button
                            icon="times"
                            size="small"
                            onClick={_ => removeItem(item)}
                          />
                        )}
                      </div>
                      <fp-skeletonize loading={props.loading} preset="input">
                        <fp-select
                          value={state.types[item]}
                          onChange={val => handleTypesChange(val, item)}
                          label="Type"
                          items={tagsState.types}
                          data-cy={`${props.cypressPrefix}-meta-tag-${item}--type`}
                        />
                      </fp-skeletonize>
                    </div>
                  </v-col>

                  <v-col cols="8" class="pb-0">
                    <fp-skeletonize loading={props.loading} preset="input">
                      <fp-combobox
                        value={state.listOfTags[item]}
                        onChange={val => handleListOfTagsChange(val, item)}
                        label="Search for a meta tag"
                        counter={props.maxTagLen}
                        items={tagsState.items}
                        error={state.errors[item]}
                        message={state.messages[item]}
                        cypressName={`${props.cypressPrefix}-meta-tag-${item}--items`}
                      ></fp-combobox>
                    </fp-skeletonize>
                  </v-col>
                </v-row>
              </v-container>
            )
          })}
          {itemsAPI.state.items.length < tagsState.types.length && (
            <div class="d-flex justify-end">
              <div></div>
              <fp-button
                size="medium"
                type="text"
                icon="plus"
                data-cy={`${props.cypressPrefix}-meta-tag--add`}
                onClick={addItem}
              >
                + Add another
              </fp-button>
            </div>
          )}
        </div>
      )
    }
  },
})
