import gql from 'graphql-tag'

export const getGAMGameTargeting = gql`
  query getGAMGameTargeting($ad: ID!, $limit: Int, $skip: Int) {
    getGAMGameTargeting(ad: $ad, limit: $limit, skip: $skip) {
      items {
        id
        title
      }
      limit
      skip
      total
    }
  }
`
