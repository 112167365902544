import gql from 'graphql-tag'

export const getGameRates = gql`
  query getGameRates(
    $games: [ID]
    $limit: Int
    $skip: Int
    $filter: GameRateFiltersInput
  ) {
    getGameRates(games: $games, limit: $limit, skip: $skip, filter: $filter) {
      items {
        game
        orgs {
          org
          rate
        }
        id
        archived
        dateUpdated
        dateCreated
        costDeductionsRate
        platformDeliveryFees {
          type
          displayFee
          displayFeePrevious
          displayLastUpdated {
            at
            userID
            userFirstName
            userLastName
            timezone
          }
          videoFee
          videoFeePrevious
          videoLastUpdated {
            at
            userID
            userFirstName
            userLastName
            timezone
          }
        }
      }
      skip
      limit
      total
    }
  }
`
