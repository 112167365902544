<template>
  <p class="fp-form__text">
    <slot />
  </p>
</template>

<script>
export default {
  blockName: 'fp-form',

  name: 'FpFormText',
}
</script>

<style lang="scss" src="@component-styles/form"></style>
