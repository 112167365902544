<template>
  <div>
    <label v-if="label" v-bem:label :for="label">
      {{ label }}
    </label>

    <div
      v-bem="{
        disabled,
        readonly,
        focus: isFocused,
        $size,
        error,
        success,
        hasState,
      }"
      :style="{ width: finalWidth }"
    >
      <p v-bem:prefix>
        {{ prefix }}
      </p>

      <input
        ref="focusAnchor"
        v-bem:inner
        v-bind="$attrs"
        :value="value"
        type="number"
        :readonly="disabled || readonly"
        @input="inputHandler"
      />

      <p v-bem:display>
        {{ numberFormated }}
      </p>

      <div :class="$bem('::btn :minus')" @click="minus">
        <fp-icon :name="disabled ? 'ban' : 'minus'" />
      </div>

      <div :class="$bem('::btn :plus')" @click="plus">
        <fp-icon :name="disabled ? 'ban' : 'plus'" />
      </div>

      <fp-hint-message v-if="hasState" :message="message" />
    </div>
  </div>
</template>

<script>
import useSize from '@component-mixins/useSize'
import useDisabled from '@component-mixins/useDisabled'
import useReadonly from '@component-mixins/useReadonly'
import useFocus from '@component-mixins/useFocus'
import useValidateState from '@component-mixins/useValidateState'

export default {
  name: 'FpNumber',

  mixins: [useSize, useDisabled, useReadonly, useFocus, useValidateState],

  inheritAttrs: false,

  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    value: { type: Number, required: true },

    precision: {
      type: Number,
      default: 1,
      validator(v) {
        return [10, 1, 0.1, 0.01].includes(v)
      },
    },

    step: { type: Number, default: 1 },

    min: { type: Number, default: 0 },

    max: { type: Number, default: 99999999 },

    prefix: { type: String, default: '' },
    label: { type: String, default: '' },
  },
  computed: {
    numberFormated() {
      return this.value.toLocaleString('en-US').split(',').join(', ')
    },
  },

  mounted() {
    this.value = this.min
  },

  methods: {
    inputHandler(ev) {
      if (this.disabled || this.readonly) return

      let result = Number(ev.target.value)
      if (result < this.min) result = this.min
      if (result > this.max) result = this.max

      this.$emit('change', result)
    },

    minus() {
      // fix the accuracy problem of JS decimal calculation
      let result = (this.value * 1000 - this.step * 1000) / 1000
      this.inputHandler({ target: { value: result } })
    },

    plus() {
      let result = (this.value * 1000 + this.step * 1000) / 1000
      this.inputHandler({ target: { value: result } })
    },
  },
}
</script>

<style lang="scss" src="@component-styles/number"></style>
