<script>
import appConfig from '@src/app.config'
import { mapGetters, mapActions } from 'vuex'
import GuardDialog from '@components/guards/dialog'
import * as NotificationStore from '@state/modules/notifications.ts'
import * as sub from '@graphql/notification/subscriptions'

export default {
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(iTitle) {
      let title = iTitle
      title = typeof title === 'function' ? title(this.$store) : title
      return title ? `${title} | ${appConfig.title}` : appConfig.title
    },
  },
  components: {
    GuardDialog,
  },

  data: () => ({
    HYDRATED_STATUS: {
      UNKNOWN: 'UNKNOWN',
      TRUE: 'TRUE',
      FALSE: 'FALSE',
    },
    blur: false,
  }),

  computed: {
    ...mapGetters('actionGuards', ['guardOnDuty']),
    ...mapGetters('auth', [
      'currentUser',
      'loggedIn',
      'authErrorMessages',
      'ssoLoading',
    ]),
    ...mapGetters('org', ['organisation']),

    showGuardDialog() {
      return !!this.guardOnDuty
    },
    hydrated: {
      get: function () {
        return this.HYDRATED_STATUS.UNKNOWN
      },
      set: function (newValue) {
        return newValue
      },
    },
  },

  watch: {
    organisation: {
      handler: function (v) {},
      deep: true,
      immediate: true,
    },
    authErrorMessages: {
      handler: function (v) {
        if (v && v[0] === 'Invalid Refresh Token') {
          this.logoutAction()
          this.$toast.error('Session expired, please login again')
        }
      },
      deep: true,
    },
  },
  // todo: convert to vue apollo hooks
  apollo: {
    $subscribe: {
      onNotificationAdded: {
        query: sub.onNotificationAdded,
        variables() {
          return {
            user: this.currentUser?.username ?? null,
          }
        },
        result(res) {
          const toAdd = { ...(res?.data?.notificationAdded ?? null) }
          NotificationStore.dispatch('setNotificationAdded', toAdd)
        },
        skip() {
          return !this.loggedIn
        },
      },
    },
  },

  async created() {},
  async mounted() {
    this.$store.subscribe(({ type, payload }, state) => {
      if (type === 'actionGuards/SET_GUARD_ON_DUTY' && payload) {
        if (payload?.body?.onGuardInView) {
          payload.body.onGuardInView(this, payload)
        }
      }
    })

    try {
      await this.$apollo.provider.defaultClient.hydrated()
      this.hydrated = this.HYDRATED_STATUS.TRUE
    } catch (e) {
      this.hydrated = this.HYDRATED_STATUS.FALSE
    }
  },

  methods: {
    ...mapActions('actionGuards', ['setApproved']),
    ...mapActions('auth', ['logOut']),

    logoutAction() {
      this.logOut().then(() => {
        this.$router.push('/login')
      })
    },

    async handleDialogCancel() {
      await this.setApproved({ key: this.guardOnDuty?.id, value: false })
    },
    async handleDialogConfirm(approved = true, variables) {
      const { guardOnDuty, setApproved } = this

      await setApproved({ key: guardOnDuty?.id, value: approved, variables })
    },
  },
}
</script>

<template>
  <v-app id="app-wrapper" :class="`v-page-${$route.name}`">
    <!-- unknown bug: div required above router-view otherwise going to
    http://localhost:8080/ leaves a blank screen with no errors when
    using with v-app wrapper -->

    <!-- hydrated used by apollo and appsync, probably to avoid loading UI if data
    not yet fetched from appsync -->
    <div
      v-if="
        hydrated === HYDRATED_STATUS.TRUE ||
        hydrated === HYDRATED_STATUS.UNKNOWN
      "
      id="app-router-view"
      style="height: 100%"
    >
      <!-- Decide which layout will be used: MainLayout, AuthLayout -->
      <router-view type="layout" />

      <div
        v-if="ssoLoading"
        class="d-flex justify-center align-center w-100"
        style="height: 100vh"
      >
        <v-progress-circular
          size="60"
          width="5"
          indeterminate
          color="#0047ff"
        />
      </div>

      <guard-dialog
        v-if="
          guardOnDuty &&
          guardOnDuty.id &&
          guardOnDuty.body &&
          !guardOnDuty.body.onGuardInView
        "
        :guard="guardOnDuty"
        :show-dialog="showGuardDialog"
        @on-cancel="handleDialogCancel"
        @on-confirm="(approved, form) => handleDialogConfirm(approved, form)"
      />
    </div>
  </v-app>
</template>

<style lang="scss" src="./styles/app.scss"></style>
