<template>
  <div v-bem="{ error }">
    <div v-bem:below-root>
      <label v-if="label" v-bem:label>
        {{ label }}
      </label>

      <small v-if="hint" v-bem:hint>
        {{ hint }}
      </small>

      <div v-bem role="radiogroup">
        <slot />
      </div>

      <div v-if="message" v-bem:message>
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
import useGroup from '@component-mixins/useGroup'

export default {
  name: 'FpRadioGroup',

  mixins: [useGroup],

  model: {
    prop: 'value',
    event: 'change',
  },

  $_veeValidate: {
    value() {
      return this.value
    },
    name() {
      return this.name
    },
  },

  props: {
    name: { type: String, default: '' },
    value: {
      type: [Boolean, Number, String, undefined],
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    error: Boolean,
  },

  created() {
    this.$on('radioValueChange', this.changeHandler)
  },

  methods: {
    changeHandler(v) {
      this.$emit('change', v)
    },
  },
}
</script>

<style lang="scss" src="@component-styles/radio"></style>
