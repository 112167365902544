// @ts-ignore

import * as dateFns from 'date-fns'
import { substring } from '@utils'
export const addDays = (date, n) => dateFns.addDays(date, n)
export const addMonths = (date, n) => dateFns.addMonths(date, n)
export const addYears = (date, n) => dateFns.addYears(date, n)
export const addHours = (date, n) => dateFns.addHours(date, n)
export const addMinutes = (date, n) => dateFns.addMinutes(date, n)
export const subDays = (date, n) => dateFns.subDays(date, n)
export const subHours = (date, n) => dateFns.subHours(date, n)
export const endOfDay = date => dateFns.endOfDay(date)

export const parseISO = dateFns.parseISO

export const toShortISOString = date => substring(date.toISOString(), 0, 10)

export const setDate = date => {
  validateDate(date)
  const parsed = dateFns.toDate(date)
  return parsed
}

export const getTime = date => dateFns.getTime(date)

/* Compare the two dates and return 1 if the first date is after the second, -1
if the first date is before the second or 0 if dates are equal. */
export const compareAsc = (date1, date2) => {
  const res = dateFns.compareAsc(date1, date2)
  return res
}

export const differenceInHours = (date1, date2) => {
  const res = dateFns.differenceInHours(date1, date2)
  return res
}

export const differenceInCalendarDays = (date1, date2) => {
  const res = dateFns.differenceInCalendarDays(date1, date2)
  return res
}

export const isValidAWSTimeStamp = val => {
  if (!val) return false

  if (typeof val === 'number' || typeof val === 'string') {
    if (val.toString().length === 10) {
      return true
    }
  }

  return false
}

// replace this with date-fns getUnixTime after migrate to 2.0
export const getUnixTime = date => parseInt(date.getTime() / 1000)

const validateDate = date => {
  const msg = `${date} is typeof ${typeof date}. valid date required.`

  try {
    if (!dateFns.isValid(date)) throw Error(msg)
  } catch (e) {
    throw Error(msg)
  }
}

export const timeStringToUnixTimestamp = input => {
  // ⚠️ ⚠️ ⚠️ ⚠️ ⚠️ ⚠️//
  // do not use built-in javascript date functions to parse date
  // it is unreliable across safari and ie, etc. Always use a good date library
  const a = dateFns.parseISO(input)
  const b = new Date(a).getTime() / 1000
  return b
}

// fix timezone out of sync issues between local tests and circleCI tests
// https://github.com/date-fns/date-fns/issues/489
export function fixedDate(date) {
  const [year, month, day] = substring(date, 0, 10).split('-')
  return new Date(year, month - 1, day)
}
