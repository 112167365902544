<script>
import primary from './themes/primary'
import white from './themes/white'

export default {
  name: 'Logo',
  components: {
    primary,
    white,
  },
  props: {
    theme: { type: String, default: 'primary' },
    link: { type: String, default: '/' },
    static: Boolean, // Unlinkable
  },
  data() {
    return {
      selectedTheme: this.theme,
    }
  },
  computed: {
    rootStyle() {
      if (!this.static) {
        return 'cursor:pointer;'
      }

      return ''
    },
  },
  watch: {
    theme(val) {
      this.selectedTheme = val
    },
  },

  methods: {
    handleClick() {
      if (this.static) return
      if (this.$router) this.$router.push(this.link)
    },
  },
}
</script>

<template>
  <div :class="$style.root" :style="rootStyle" @click="handleClick">
    <component :is="selectedTheme" :class="$style.logo"></component>
  </div>
</template>

<style lang="scss" module>
@import '@scss';

.root {
  width: 100%;
  max-width: 21px;
  height: 34.5px;
}
</style>
