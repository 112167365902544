import { computed, createComponent, reactive } from '@vue/composition-api'
import router from '@src/router'

// @ts-ignore-next-line
import Footer from '@components/bottom-nav'
// @ts-ignore-next-line
import FormLayout from '@layouts/form-layout'
// @ts-ignore-next-line
import PageHeadForForm from '@components/page-head/for-form'
import style from './users.module.scss'
import gStyle from '@views/games/game-summary/game-summary.module.scss'
import secStyle from '@views/accounts/style.module.scss'
import NavDrawer from '@components/nav-drawer'

// @ts-ignore-next-line
import PlaceholderPortrait from '@components/avatar/portrait'

import CRUDListView from '@components/crud/views/list'
import * as CRUDTypes from '@components/crud/types'
import * as MembersAPI from './membersAPI'

import * as types from './types'

import useMembers from './useMembers'
import { useToaster } from '@components/_base/toaster/useToaster'

import { RoleTypeEnum } from '@src/shared/constants'

import {
  getInitials,
  capitalize,
  humanize,
  startCaseAllUppercase,
} from '@utils'
import { ROLE_TEXT } from '@src/shared/ui/list/roles'

// @ts-ignore-next-line
import FpMoreActions from '@components/more-actions-menu'

// @ts-ignore-next-line
import FpDialog from '@components/_base/dialog'

import { UserGetters } from '@src/state/modules/user.store'
import { RolesGetters } from '@src/state/modules/roles.store'
import { OrgHierarchyEnum, OrgTypeEnum } from '@graphql-types'
import { PermissionEnum } from '@src/router/routesMap'
import { OrgGetters } from '@src/state/modules/org.store'

// type Data = typeof initialData

const columnOperation = {
  text: ' ',
  value: MembersAPI.COL_OPERATIONS,
  fixed: true,
  clickStop: true,
  width: 120,
  align: 'center',
}

const columnsHolding = [
  { text: '', value: MembersAPI.COL_AVATAR, align: 'center', width: 60 },
  {
    text: 'First name',
    value: 'firstName',
    sortable: true,
    emphasize: true,
    width: 120,
    ellipsis: true,
  },
  {
    text: 'Last name',
    value: 'lastName',
    sortable: true,
    emphasize: true,
    width: 120,
    ellipsis: true,
  },
  {
    text: 'Subsidiary',
    value: 'orgTitle',
    emphasize: true,
    width: 150,
    ellipsis: true,
  },
  {
    text: 'Role',
    value: MembersAPI.COL_ROLE,
    width: 150,
    ellipsis: true,
  },
  {
    text: 'Email',
    value: 'email',
    sortable: true,
    ellipsis: true,
  },

  columnOperation,
]

const baseColumns = [
  { text: '', value: MembersAPI.COL_AVATAR, align: 'center', width: 60 },
  {
    text: 'First name',
    value: 'firstName',
    sortable: true,
    emphasize: true,
    width: 120,
    ellipsis: true,
  },
  {
    text: 'Last name',
    value: 'lastName',
    sortable: true,
    emphasize: true,
    width: 120,
    ellipsis: true,
  },
  {
    text: 'Role',
    value: MembersAPI.COL_ROLE,
    width: 150,
    ellipsis: true,
  },
]

const columns = [
  ...baseColumns,
  {
    text: 'Email',
    value: 'email',
    sortable: true,
    ellipsis: true,
  },

  columnOperation,
]

const columnsFpsaGp = [
  ...baseColumns,
  {
    text: 'Access all games',
    value: MembersAPI.COL_ACCESS,
    width: 120,
    ellipsis: true,
  },
  {
    text: 'Email',
    value: 'email',
    sortable: true,
    ellipsis: true,
  },
  columnOperation,
]

export default createComponent({
  name: 'Users',
  components: {
    NavDrawer,
    CRUDListView,
    PlaceholderPortrait,
    FpMoreActions,
    FpDialog,
  },
  // @ts-ignore
  props: [...types.vueProps],
  setup(props: types.Props, ctx) {
    const toaster = useToaster(ctx)

    const {
      handleNavDrawerInput,
      handleViewDetailsClick,
      handleEditClick,
      handleCancelClick,
      handleDeleteClick,
      handleDeleteConfirm,
      handleSave,
      handlePageUpdate,
      updateFirstName,
      updateLastName,
      updateEmailFilter,
      updateOrgFilter,
      updatePhoneFilter,
      computedState,
      state,
    } = useMembers(props, ctx)

    const ownerTransferState = reactive({
      display: false,
    })

    const perm = reactive({
      canEditMember: computed(() => {
        const perms = UserGetters('userInfo')?.permissions ?? []
        return perms.includes(PermissionEnum.UPDATEORGMEMBER)
      }),
      canDeleteMember: computed(() => {
        const perms = UserGetters('userInfo')?.permissions ?? []
        return perms.includes(PermissionEnum.REMOVEORGMEMBER)
      }),
    })

    const adminEditMode = router.currentRoute.meta.adminEditMode
    const currentUser = UserGetters('userInfo')
    const currentUserRole = currentUser.roles[0]?.roleID.split(':')[1]
    const isOwner = currentUserRole && currentUserRole === RoleTypeEnum.OWNER
    const isAdmin = currentUserRole && currentUserRole === RoleTypeEnum.ADMIN
    const isHolding = currentUser?.org?.hierarchy === OrgHierarchyEnum.HOLDING
    // const currentOrgID = currentUser?.org?.id

    const isAdvertiser = OrgGetters('isAdvertiser')
    const isAgency = OrgGetters('isAgency')

    const cState = reactive({
      cols: computed(() => {
        if (isHolding) {
          return columnsHolding
        } else {
          if (
            ((isOwner || isAdmin) &&
              currentUser?.org?.type === OrgTypeEnum.GAME_PUBLISHER) ||
            adminEditMode
          ) {
            return columnsFpsaGp
          }
          return columns
        }
      }),
    })

    return function () {
      // render funcs

      const Name = () => {
        if (
          state.mode === CRUDTypes.Mode.VIEW ||
          state.mode === CRUDTypes.Mode.DELETE
        ) {
          return <h4 class="pt-4">{computedState.name}</h4>
        }
        if (
          state.mode === CRUDTypes.Mode.CREATE ||
          state.mode === CRUDTypes.Mode.EDIT
        ) {
          return (
            <v-container fluid class="mt-6">
              <v-row>
                <v-col cols="12" md="6" class="pa-0 pr-sm-4">
                  <h4>
                    <fp-skeletonize preset="input" loading={state.loading}>
                      {computedState.firstNameCrudItem?.renderFunc()}
                    </fp-skeletonize>
                  </h4>
                </v-col>
                <v-col cols="12" md="6" class="pa-0">
                  <h4>
                    <fp-skeletonize preset="input" loading={state.loading}>
                      {computedState.lastNameCrudItem?.renderFunc()}
                    </fp-skeletonize>
                  </h4>
                </v-col>
              </v-row>
            </v-container>
          )
        }

        return null
      }

      const Role = () => {
        if (
          state.mode === CRUDTypes.Mode.VIEW ||
          state.mode === CRUDTypes.Mode.DELETE
        ) {
          return (
            <h5 class={[style.subtitle, 'pb-1']}>
              {
                ROLE_TEXT[
                  state.currentViewedItem?.userRole?.roleID.split(':')[1]
                ]
              }
            </h5>
          )
        }
        if (
          state.mode === CRUDTypes.Mode.CREATE ||
          state.mode === CRUDTypes.Mode.EDIT
        ) {
          return (
            <h5>
              <fp-skeletonize
                height="56"
                bottom-margin="52"
                fill
                loading={state.loading || RolesGetters('loading')}
              >
                {computedState.roleCrudItem?.renderFunc()}
              </fp-skeletonize>
            </h5>
          )
        }

        return null
      }

      const Header = () => {
        return (
          <div class={style.head}>
            <PlaceholderPortrait />
            <Name />
            <Role />

            <div class={['pt-4']}>
              <div>
                {state.actions.map(o => (
                  <div>
                    <div>
                      {/* <div class={[style.actionLabel, 'pb-1']}>{o?.role}</div> */}
                      <div class={[style.actionValue]}>{o?.description}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      }

      const DefaultContent = () => {
        let out: JSX.Element

        if (state.mode === CRUDTypes.Mode.DELETE) {
          out = (
            <div class="pt-6 pb-10">
              <div>
                Are you sure you want to delete
                <strong> {computedState.name}</strong> from the organization?
              </div>

              <br />
              <strong>This action cannot be undone.</strong>
            </div>
          )
        } else {
          out = (
            <CRUDListView
              items={state.currentViewedItem?.crudItems}
              mode={state.mode}
              loading={state.loading}
            />
          )
        }

        return (
          <div class="pt-4">
            {out}

            {/* <div class={[style.actions, 'pt-6']}>
              <div>
                {state.actions.map(o => (
                  <div>
                    <div>
                      <div class={[style.actionLabel, 'pb-1']}>{o?.role}</div>
                      <div class={[style.actionValue]}>{o?.description}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div> */}
          </div>
        )
      }

      const ConfirmButton = () => {
        if (computedState.showSaveBtn) {
          return (
            <fp-button
              icon="check"
              width={150}
              size="small"
              disabled={state.loading}
              onClick={() => handleSave(state.mode)}
            >
              Save
            </fp-button>
          )
        }

        if (state.mode === CRUDTypes.Mode.DELETE) {
          return (
            <fp-button
              icon="trash"
              width={150}
              type="error"
              size="small"
              disabled={state.loading}
              onClick={handleDeleteConfirm}
            >
              Delete
            </fp-button>
          )
        }

        return null
      }

      const CancelButton = () => {
        return (
          <fp-button
            class="mr-4"
            type="line"
            icon="times"
            icon-position="left"
            size="small"
            width={150}
            disabled={state.loading}
            onClick={() => handleCancelClick(state.mode)}
          >
            Cancel
          </fp-button>
        )
      }

      const Append = () => {
        if (computedState.showSaveBtn || state.mode === CRUDTypes.Mode.DELETE) {
          return (
            <div>
              <CancelButton />
              <ConfirmButton />
            </div>
          )
        }

        return (
          <div>
            <v-tooltip
              disabled={perm.canDeleteMember}
              bottom
              scopedSlots={{
                activator: ({ on }) => {
                  const attrs = {
                    on: on,
                  }
                  return (
                    <div {...attrs}>
                      <fp-button
                        type="text"
                        icon="trash"
                        size="small"
                        disabled={state.loading || !perm.canDeleteMember}
                        loading={state.loading}
                        onClick={handleDeleteClick}
                      >
                        <fp-icon name="trash" size="1x" class="mr-2" />
                        Delete User
                      </fp-button>
                    </div>
                  )
                },
              }}
            >
              <span>Insufficient permission to delete user</span>
            </v-tooltip>
          </div>
        )
      }

      // handles the actions for the more menu.
      const handleMoreAction = (actionName, item) => {
        switch (actionName) {
          case 'transferOwnership':
            transferOwnership(item)
            break
          case 'relogin':
            handleAfterTransferSuccess()
            break
        }
      }

      const transferOwnership = async item => {
        state.loading = true

        const [err] = await MembersAPI.replaceOrgMemberOwnership({
          input: {
            existingUserID: currentUser.id,
            newUserID: item.userID,
            orgID: currentUser.org.id,
          },
          email: item.email,
        })

        // don't show cancel mutation error
        if (err) {
          //  if the guard was cancelled by the user then show a warning instead
          if (err.networkError?.canceledByUser) {
            state.loading = false
            toaster.warning(`Owner transfer to ${item.email} was cancelled.`)
            return
          }

          // else throw an error
          toaster.error(
            'An error has occurred while attempting to transfer the ownership.'
          )
        } else {
          // toaster.success('Ownership successfully transferred.')
          ownerTransferState.display = true
        }

        // state.loading = false
      }

      const handleAfterTransferSuccess = () => {
        ownerTransferState.display = false
        ctx.root.$router.go(0)
      }

      const AllowAccess = () => {
        // if the current user is part of the holding then remove the games section
        // state.currentViewedItem.orgID === currentOrgID &&
        if (isHolding) return null

        if (
          state.mode === CRUDTypes.Mode.EDIT ||
          state.mode === CRUDTypes.Mode.CREATE
        )
          return (
            <fp-skeletonize preset="input" loading={state.loading}>
              {computedState.allowAccessCrudItem?.renderFunc()}
            </fp-skeletonize>
          )
        else
          return (
            <section class={[style.fieldBox]}>
              <fp-skeletonize
                loading={state.loading}
                width="120"
                height="15"
                no-animate
                bottom-margin="6"
              >
                <label class={[style.fieldLabel]}>
                  Access to all{' '}
                  {isAdvertiser || isAgency
                    ? MembersAPI.ORG_ENTITY_NAME.ADVAGC
                    : MembersAPI.ORG_ENTITY_NAME.GP}
                </label>
              </fp-skeletonize>

              <fp-skeletonize
                loading={state.loading}
                width="250"
                height="24"
                round
                bottom-margin="16"
              >
                <div class={[style.fieldDisplayValue]}>
                  {state.currentViewedItem.allowAccessToAllEntities
                    ? 'Yes'
                    : 'No'}
                </div>
              </fp-skeletonize>
            </section>
          )
      }

      // sync jsx https://stackoverflow.com/questions/50041991/how-to-write-vue-sync-in-javascript-using-createelement-render-function
      return (
        <section class={['pt-6', secStyle.container]}>
          <fp-button
            class={[secStyle.filtBtn]}
            type="light"
            size="small"
            data-cy="button--filters"
            icon="filter"
            icon-position="left"
            onClick={() => (state.showFilter = !state.showFilter)}
          >
            Filters
          </fp-button>

          <nav-drawer v-model={state.showFilter} width="340px">
            <template slot="prepend">
              <h4>Filters</h4>
            </template>
            {state.showFilter && (
              <div class="pb-6 pt-4">
                <fp-input
                  placeholder="First Name"
                  value={state.filter.firstName}
                  label="First Name"
                  type="text"
                  hide-details
                  clearable
                  onInput={updateFirstName}
                  data-cy="mem-first-name-filter"
                />
              </div>
            )}
            {state.showFilter && (
              <div class="pb-6">
                <fp-input
                  placeholder="Last Name"
                  value={state.filter.lastName}
                  label="Last Name"
                  type="text"
                  hide-details
                  clearable
                  onInput={updateLastName}
                />
              </div>
            )}
            {state.showFilter && (
              <div class="pb-6">
                <fp-input
                  placeholder="Email"
                  value={state.filter.email}
                  label="Email"
                  type="text"
                  hide-details
                  clearable
                  onInput={updateEmailFilter}
                  data-cy="mem-email-filter"
                />
              </div>
            )}
            {state.showFilter && (
              <div class="pb-6">
                <fp-select
                  v-model={state.filter.orgID}
                  tabindex="2"
                  label="Organization"
                  item-text="title"
                  item-value="id"
                  clearable
                  items={state.orgList}
                  disabled={state.loading}
                  onInput={updateOrgFilter}
                  hide-details
                  data-cy="mem-filters-org-list"
                />
              </div>
            )}
            {state.showFilter && (
              <div class="pb-6">
                <fp-input
                  placeholder="Phone"
                  value={state.filter.phone}
                  label="Phone"
                  type="text"
                  hide-details
                  clearable
                  onInput={updatePhoneFilter}
                  data-cy="mem-phone-filter"
                />
              </div>
            )}
          </nav-drawer>

          <fp-table
            page={state.currentPage}
            selectable={false}
            loading={state.loading}
            perPage={state.limit}
            total={state.total}
            rowAlignTop={false}
            columns={cState.cols}
            data={state.data}
            row-clickable={false}
            on={{
              'update:page': handlePageUpdate,
            }}
            scopedSlots={{
              [`cell.${MembersAPI.COL_AVATAR}`]: ({ item }) => {
                const initials = getInitials(item?.firstName, item?.lastName)

                if (!initials) return null

                return (
                  <div>
                    <v-avatar color={item?.color} size={32}>
                      <span class="white--text">{initials}</span>
                    </v-avatar>
                  </div>
                )
              },

              [`cell.${MembersAPI.COL_COMPANY}`]: ({ value }) => {
                return capitalize(value)
              },

              [`cell.${MembersAPI.COL_ROLE}`]: ({ value }) => {
                return value?.roleID
                  ? startCaseAllUppercase(humanize(value?.roleID.split(':')[1]))
                  : ''
              },

              [`cell.${MembersAPI.COL_ACCESS}`]: ({ value, item }) => {
                return item?.allowAccessToAllEntities ? 'Yes' : 'No'
              },

              [`cell.${MembersAPI.COL_OPERATIONS}`]: ({
                rowLoading,
                item,
                key,
                toggleRowLoading,
                itemIndex,
                columnConfig,
              }) => {
                if (isOwner) {
                  columnConfig.width = 160
                }

                return (
                  <div
                    class={
                      isOwner ? `d-flex align-center align-self-stretch` : ''
                    }
                  >
                    <fp-button
                      key={`view-details-${itemIndex}`}
                      type="text"
                      size="small"
                      class=""
                      disabled={false}
                      loading={false}
                      onClick={() => handleViewDetailsClick(item, itemIndex)}
                    >
                      VIEW DETAILS {'>'}
                    </fp-button>

                    {isOwner && !isHolding && (
                      <div data-test-id="ot-more-actions">
                        <FpMoreActions
                          class="ml-2"
                          actions={{
                            transferOwnership: {
                              icon: 'exclamation',
                              color: 'priamry',
                              text: 'Transfer Ownership',
                              disabled:
                                item.userRole.roleID.split(':')[1] ===
                                RoleTypeEnum.OWNER,
                              tooltip:
                                item.userRole.roleID.split(':')[1] ===
                                RoleTypeEnum.OWNER
                                  ? "This option is disabled since you're the current owner"
                                  : null,
                            },
                          }}
                          on={{
                            'take-action': actionName =>
                              handleMoreAction(actionName, item),
                          }}
                        />
                      </div>
                    )}
                  </div>
                )
              },
            }}
          />

          <NavDrawer
            value={computedState.navDrawerOpen}
            stateless={computedState.navStateless}
            temporary={true}
            onInput={handleNavDrawerInput}
            scopedSlots={{
              default: () => {
                return (
                  <div>
                    <Header />
                    <div class={style.divider}></div>
                    <DefaultContent />
                    <div class={[gStyle.divider, 'mx-0 mt-0 mb-5']}></div>
                    {(state.mode === CRUDTypes.Mode.EDIT ||
                      state.mode === CRUDTypes.Mode.VIEW ||
                      state.mode === CRUDTypes.Mode.CREATE) && <AllowAccess />}
                  </div>
                )
              },
              prepend: () => {
                if (state.mode === CRUDTypes.Mode.VIEW) {
                  return (
                    <v-tooltip
                      disabled={perm.canEditMember}
                      bottom
                      scopedSlots={{
                        activator: ({ on }) => {
                          const attrs = {
                            on: on,
                          }
                          return (
                            <div {...attrs}>
                              <fp-button
                                icon="pen"
                                size="small"
                                onClick={handleEditClick}
                                disabled={!perm.canEditMember}
                              >
                                Edit
                              </fp-button>
                            </div>
                          )
                        },
                      }}
                    >
                      <span>Insufficient permission to edit user</span>
                    </v-tooltip>
                  )
                }
                if (state.mode === CRUDTypes.Mode.CREATE) {
                  return <h4>Add User</h4>
                }
                if (state.mode === CRUDTypes.Mode.EDIT) {
                  return <h4>Edit User</h4>
                }
              },
              append: () => {
                return <Append />
              },
            }}
          />

          {/* only show the dialog if the current user is the owner */}
          {isOwner && (
            <FpDialog
              title={'Owner transferred successfully'}
              value={ownerTransferState.display}
              loading={false}
              closeable={false}
              centerActions={true}
              scrollable={true}
              scopedSlots={{
                content: () => {
                  return (
                    <div class={`text-center`}>
                      <p>
                        The ownership has been successfully transferred. You are
                        now required to re-login to continue.
                      </p>
                    </div>
                  )
                },
                actions: () => {
                  return (
                    <div class={`text-center w-100`}>
                      <div class="pb-8">
                        <fp-button
                          size="normal"
                          type="primary"
                          onClick={() => handleMoreAction('relogin', null)}
                          icon={'chevron-right'}
                        >
                          Re-login
                        </fp-button>
                      </div>
                    </div>
                  )
                },
              }}
            />
          )}
        </section>
      )
    }
  },
})

// -------------------------------------------------------------
