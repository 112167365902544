import store from '@src/state/store'
import { createComponent, computed } from '@vue/composition-api'
import { Game } from '@graphql-types'

import style from './chips.module.scss'
import router from '@src/router'
import { getStatusChipType } from '@src/shared/game/filters'

export default createComponent({
  name: 'FpGameChip',

  props: {
    gameId: {
      type: String,
      required: true,
    },

    disabled: Boolean,
  },

  setup(props) {
    const dataRef = computed<Game>(() => {
      return store.state.gameToStatus.map[props.gameId]
    })

    return () => {
      const isLoading = !dataRef.value

      if (isLoading) {
        return (
          <div class={[style.container, 'text-center']}>
            <v-progress-circular
              size="14"
              width="2"
              indeterminate
              color={style.globalColorInfo}
            />
          </div>
        )
      }

      const data = dataRef.value

      const status = data?.status

      return (
        <v-tooltip
          top
          scopedSlots={{
            activator: ({ on }) => (
              <div
                class={[style.container, 'text-truncate']}
                onClick={clickHandler}
                on={on}
              >
                <fp-chip
                  small
                  circle
                  class="mr-2"
                  type={props.disabled ? 'disabled' : getStatusChipType(status)}
                />
                <span>{`${data.title} [${status}]`}</span>
              </div>
            ),
          }}
        >
          {data.title}
        </v-tooltip>
      )
    }

    function clickHandler() {
      router.push(`/games/${props.gameId}`)
    }
  },
})
