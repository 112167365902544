<script>
import * as api from './api'
// TODO - bar-dashboard should be removed after the reporting dashboard is enabled for other users
export default {
  name: 'BarChartDashboard',

  props: {
    id: {
      type: String,
      required: true,
    },
    height: {
      type: [Number, String],
      required: true,
    },
    option: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      chart: null,
      model: {
        noDataDialog: false,
      },
    }
  },
  computed: {
    noData() {
      return api.isEmpty(this.option)
    },
  },

  watch: {
    option() {
      this.setOption()
    },
  },

  mounted() {
    this.chart = api.init(document.getElementById(this.id))
    this.setOption()
    // this.handleResize()
  },
  methods: {
    handleResize() {
      if (this.chart) {
        this.setOption()
        this.chart.resize()
      }
    },
    setOption() {
      api.setOption(this.chart, { ...this.option })
    },
  },
}
</script>

<template>
  <div v-resize="handleResize" :class="$style.root" :style="'height:' + height">
    <div :id="id" style="width: 100%; height: inherit"></div>
  </div>
</template>

<style lang="scss" module>
@import '@scss';

.root {
  width: 100%;
}
</style>
