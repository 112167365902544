import gql from '@graphql'
import * as queries from '@graphql/adSpace/queries'
import {
  pauseAdSpaces,
  unpauseAdSpaces,
  archiveAdSpaces,
  unarchiveAdSpaces,
} from '@graphql/adSpace/mutations'
import random from 'lodash/random'

export const state = {
  adSpaces: {
    items: [],
  },
  throttle: '',
  currentAdSpace: null,
}

export const mutations = {
  SET_ASYNC_THROTTLE(state, data) {
    state.throttle = data
  },
  SET_ADSPACES(state, data) {
    state.adSpaces = data
  },
  SET_CURRENT_ADSPACE(state, data) {
    state.currentAdSpace = data
  },
}

export const getters = {
  adSpaces(state) {
    return state.adSpaces.items
  },
  total(state) {
    return state.adSpaces.total
  },
  currentAdSpace(state) {
    return state.currentAdSpace
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ dispatch }) {},

  // ---------------------- //
  // GRAPHQL QUERIES
  // -----------------------//
  async getAdSpaces({ dispatch, commit, state }, variables) {
    const throttle = await dispatch('vuexStateThrottle')
    const { data } = await gql.query({
      query: queries.getAdSpaces,
      variables,
    })
    if (data && throttle === state.throttle) {
      commit('SET_ADSPACES', data.getAdSpaces)
    }
  },

  async getAdSpaceById({ commit }, variables) {
    const {
      data: { getAdSpaces: res },
    } = await gql.query({
      query: queries.getAdSpaces,
      variables,
    })

    if (res.items) {
      // REAL data returns array.length === 1 &&
      // MOCKED returns different ID in static list
      // return first item in the array
      commit('SET_CURRENT_ADSPACE', res.items[0])
      return res.items[0]
    } else {
      return null
    }
  },

  // ---------------------- //
  // GRAPHQL MUTATIONS
  // -----------------------//
  // updateAdSpace({ commit }, form) {
  //   return gql.mutate({
  //     mutation: updateAdSpace,
  //     variables: form,
  //   })
  // },
  async pauseAdSpaces({ commit }, ids) {
    // id must be array
    const res = await gql.mutate({
      mutation: pauseAdSpaces,
      variables: { ids },
    })
    return res.data.pauseAdSpaces
  },
  async unpauseAdSpaces({ commit }, ids) {
    // id must be array
    const res = await gql.mutate({
      mutation: unpauseAdSpaces,
      variables: { ids },
    })
    return res.data.unpauseAdSpaces
  },
  async archiveAdSpaces({ commit }, ids) {
    const res = await gql.mutate({
      mutation: archiveAdSpaces,
      variables: { ids },
    })
    return res.data.archiveAdSpaces
  },
  async unarchiveAdSpaces({ commit }, ids) {
    const res = await gql.mutate({
      mutation: unarchiveAdSpaces,
      variables: { ids },
    })
    return res.data.unarchiveAdSpaces
  },
  // ---------------------- //
  // HELPER ACTIONS
  // -----------------------//
  vuexStateThrottle({ commit }) {
    // Stop the Vuex store from updating the state with old data requests
    // Give us a random number to compare against
    let throttle = random(0, 1000)
    commit('SET_ASYNC_THROTTLE', throttle)
    return throttle
  },
}
