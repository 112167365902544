<script>
import GlobalHeader from '@components/header'
import GlobalFooter from '@components/footer'
import { mapGetters } from 'vuex'
import { convertToUnit, has } from '@utils'

export default {
  name: 'MainLayout',
  components: {
    GlobalHeader,
    GlobalFooter,
  },

  data() {
    return {
      transitionName: 'view-fade-vertical',
      curtainExceedingHeight: '0px',
      noTransition: false,
    }
  },

  computed: {
    ...mapGetters('org', ['isUnverified', 'isRejected']),

    contentClasses() {
      const classes = this.$route.meta.contentClasses
      if (classes && typeof classes === 'string') return classes
      return ''
    },

    curtainClasses() {
      const classes = this.$route.meta.curtainClasses
      if (classes && typeof classes === 'string') return classes
      return ''
    },

    noCurtain() {
      return this.$route.meta.noCurtain
    },

    noHeader() {
      return this.$route.meta.noHeader
    },

    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown
    },

    allMenus() {
      return this.$router.options.routes
        .find(r => r.name === 'main')
        .children.filter(childRoute => childRoute.menuConfig)
    },

    isVerifyPage() {
      return this.$route.name.includes('verification')
    },

    hideVerifyAlert() {
      const whitelist = ['ad-edit', 'ad-new']

      return (
        this.isVerifyPage ||
        (whitelist.includes(this.$route.name) && window.innerHeight <= 820)
      )
    },

    globalAlertVisible() {
      return this.isUnverified && !this.hideVerifyAlert
    },
  },

  watch: {
    $route: {
      handler: function (route) {
        this.curtainExceedingHeight = this.processCurtainExceedingHeight()
        this.setNoTransition(route)
      },
      immediate: true,
    },
    smAndDown: {
      handler: function (val) {
        this.curtainExceedingHeight = this.processCurtainExceedingHeight()
      },
      deep: true,
    },
  },

  beforeRouteUpdate(to, from, next) {
    let transitionName = 'view-fade-vertical'

    const fromOrderInMenus = this.allMenus.findIndex(
      menu => menu.name === from.name
    )

    const toOrderInMenus = this.allMenus.findIndex(
      menu => menu.name === to.name
    )

    // If views change among the Menus' page( Dashboard, Ads, Games, Ad Spaces... ),
    // use the left or right transition which depend on the orders of them,
    // otherwise use vertical transition
    if (fromOrderInMenus !== -1 && toOrderInMenus !== -1) {
      transitionName =
        fromOrderInMenus > toOrderInMenus ? 'view-fade-left' : 'view-fade-right'
    }

    this.transitionName = transitionName

    next()
  },
  created() {
    this.setNoTransition(this.$route)
  },
  methods: {
    setNoTransition(Route) {
      // each matched route is checked for cssClass from top to bottom, so you can override
      Route.matched.forEach(route => {
        if (
          route.props.default &&
          route.props.default instanceof Object &&
          'noTransition' in route.props.default
        ) {
          this.noTransition = route.props.default.noTransition
        }
      })
    },
    processCurtainExceedingHeight() {
      if (
        this.smAndDown === true &&
        has(this.$route.meta, 'curtainExceedingHeightSmAndDown')
      ) {
        return convertToUnit(this.$route.meta.curtainExceedingHeightSmAndDown)
      } else {
        return convertToUnit(this.$route.meta.curtainExceedingHeight)
      }
    },
    afterViewEnter() {
      const {
        $refs: { view },
      } = this
      /** ⚠️ Fire after the router-view component enter & end-transition
       * Usage (Add a lifecycle in component's $options) :
       *  afterViewEnter() {
       *    // do someting here
       *  }
       */
      if (view && view.$options.afterViewEnter) {
        view.$options.afterViewEnter.call(view)
      }
    },

    beforeViewLeave(el, done) {
      if (this.$refs.view && this.$refs.view.$options.beforeViewLeave) {
        this.$refs.view.$options.beforeViewLeave.call(this.$refs.view, done)
      } else {
        done()
      }
    },
  },
}
</script>

<template>
  <v-container tag="main" fluid :class="`${$style.container} pa-0`">
    <portal-target itemprop="" class="print-only" name="printPortal">
      <!--
        This component can be located anywhere in your App.
        The slot content of the above portal component will be rendered here.
      -->
    </portal-target>

    <portal-target :class="$style.sideMenuPortal" name="sideMenuPortal" />

    <global-header v-if="!noHeader" />

    <div
      v-if="!noCurtain"
      :class="[$style.pageHeadCurtain, curtainClasses]"
      :style="{
        '--exceeding-height': curtainExceedingHeight,
        '--min-height': $route.meta.curtainMinHeight,
      }"
    >
      <portal-target :class="$style.pageHeadPortal" name="pageHeadPortal" />
    </div>

    <v-layout tag="section" column :class="[$style.content, contentClasses]">
      <article :class="$style.main">
        <router-view v-if="noTransition" ref="view" :key="$route.fullPath" />

        <transition
          v-else
          :name="transitionName"
          mode="out-in"
          @after-enter="afterViewEnter"
          @leave="beforeViewLeave"
        >
          <router-view ref="view" :key="$route.fullPath" />
        </transition>
      </article>
    </v-layout>

    <global-footer v-if="!$route.meta.hideFooter" />
  </v-container>
</template>

<style lang="scss" src="@styles/print"></style>

<style lang="scss" module>
@import '@scss';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: $color-light-ice;
}

.pageHeadCurtain {
  --exceeding-height: 0%;
  --min-height: 0;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: var(--min-height);
  padding-top: #{$size-gap-between-header-content};

  &::before {
    @extend %full-fill;

    top: #{-1 * $size-header-height};
    height: calc(100% + var(--exceeding-height) + #{$size-header-height});
    content: '';
    background: linear-gradient(81.16deg, #0070f3 0%, #57a3fc 99.6%);
  }

  > .pageHeadPortal {
    @extend %center-content;

    z-index: 1;
    padding-bottom: 10px;
  }

  @include mq($until: mobile) {
    padding-top: 0;
  }
}

.sideMenuPortal {
  position: relative;
  z-index: 10;
}

.content {
  @extend %center-content;

  position: relative;
  margin: 0 auto;
}

.verificationTip {
  padding-right: 180px;

  &:global(+ .fp-button) {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-self: center;
  width: 100%;
  max-width: 1600px;
}
</style>
