import { actions, getters, mutations } from './dashboard'
import store from '../store'

type Actions = keyof typeof actions
type Computations = keyof typeof getters
type Mutations = keyof typeof mutations

export function DashboardDispatch(action: Actions, payload: any) {
  return store.dispatch(`dashboard/${action}`, payload)
}

export function DashboardCommit(mutation: Mutations, payload: any) {
  return store.commit(`dashboard/${mutation}`, payload)
}

export function DashboardGetters(key: Computations) {
  return store.getters[`dashboard/${key}`]
}
