import * as m from '../mutations'
import { handleVerificationStatus } from '../../shared/guards'
import { getOperationName } from '../../utils'
import { ORG_VERIFY_STATUS } from '@constants'

const getGuards = () => {
  const out = {
    [getOperationName(m.createStripePaymentIntent)]: () =>
      handleVerificationStatus(ORG_VERIFY_STATUS.UNVERIFIED),
  }

  return out
}

export const guards = {
  ...getGuards(),
}
