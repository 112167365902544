export const contentTypes = [
  {
    ext: '.aac',
    mime: 'audio/aac',
  },
  {
    ext: '.css',
    mime: 'text/css',
  },
  {
    ext: '.csv',
    mime: 'text/csv',
  },
  {
    ext: '.doc',
    mime: 'application/msword',
  },
  {
    ext: '.docx',
    mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  },
  {
    ext: '.gif',
    mime: 'image/gif',
  },
  {
    ext: '.jpg',
    mime: 'image/jpeg',
  },
  {
    ext: '.mp3',
    mime: 'audio/mpeg',
  },
  {
    ext: '.mp4',
    mime: 'video/mp4',
  },
  {
    ext: '.mpeg',
    mime: 'video/mpeg',
  },
  {
    ext: '.mpkg',
    mime: 'installer+xml',
  },
  {
    ext: '.oga',
    mime: 'audio/ogg',
  },
  {
    ext: '.ogv',
    mime: 'video/ogg',
  },
  {
    ext: '.ogx',
    mime: 'application/ogg',
  },
  {
    ext: '.png',
    mime: 'image/png',
  },
  {
    ext: '.pdf',
    mime: 'application/pdf',
  },
  {
    ext: '.ppt',
    mime: 'ms-powerpoint',
  },
  {
    ext: '.pptx',
    mime: 'presentationml.presentation',
  },
  {
    ext: '.rtf',
    mime: 'application/rtf',
  },
  {
    ext: '.svg',
    mime: 'svg+xml',
  },
  {
    ext: '.tiff',
    mime: 'image/tiff',
  },
  {
    ext: '.ts',
    mime: 'video/mp2t',
  },
  {
    ext: '.ttf',
    mime: 'font/ttf',
  },
  {
    ext: '.txt',
    mime: 'text/plain',
  },
  {
    ext: '.vsd',
    mime: 'vnd.visio',
  },
  {
    ext: '.wav',
    mime: 'audio/wav',
  },
  {
    ext: '.weba',
    mime: 'audio/webm',
  },
  {
    ext: '.webm',
    mime: 'video/webm',
  },
  {
    ext: '.webp',
    mime: 'image/webp',
  },
  {
    ext: '.xls',
    mime: 'mapplication/vnd.ms-excel',
  },
  {
    ext: '.xlsx',
    mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  {
    ext: '.zip',
    mime: 'application/zip',
  },
  {
    ext: '.3gp',
    mime: 'video/3gpp',
  },
  {
    ext: '.3g2',
    mime: 'video/3gpp2',
  },
]
