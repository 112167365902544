<template>
  <transition
    :name="$parent.transition"
    @after-leave="afterLeave"
    @after-enter="afterEnter"
  >
    <div
      v-if="$parent.visible"
      v-on-clickaway="clickOutsideHandler"
      :style="$parent.popupsPosition"
      :class="[VUETIFY_APP_CLASS, $bem(), $parent.popupsClass]"
    >
      <slot name="content" v-bind="$parent.slotProps" />
    </div>
  </transition>
</template>

<script>
import { mixin as useClickaway } from 'vue-clickaway'
import { VUETIFY_APP_CLASS } from '@constants'

// For Pop-ups component's usage,
// inspect the @component/_base/dropdown
export default {
  skipGloballyRegister: true,

  name: 'FpPopups',

  mixins: [useClickaway],

  data() {
    return {
      entered: false,
      VUETIFY_APP_CLASS,
    }
  },

  methods: {
    afterEnter(el) {
      this.$emit('after-enter', el)
      this.entered = true
    },

    afterLeave(el) {
      this.$emit('after-leave', el)
      this.entered = false
    },

    clickOutsideHandler() {
      this.$emit('click-outside')
    },
  },
}
</script>

<style lang="scss" src="@component-styles/pop-ups"></style>
