import * as queries from '@graphql/gam/queries'

export const state = {
  gamGames: {},
}

export const getters = {
  gamGamesTargetedTotal({ gamGames }) {
    return gamGames?.total
  },
  gamGamesTargetedLimit({ gamGames }) {
    return gamGames?.limit
  },
  gamGamesTargetedItems({ gamGames }) {
    return gamGames?.items
  },
}

export const mutations = {
  SET_GAMES(state, data) {
    state.gamGames = data
  },
}

export const actions = {
  // ---------------------- //
  // GRAPHQL ACTIONS
  // -----------------------//
  async getGAMGamesTargeted({ dispatch, commit }, variables = { limit: 10 }) {
    const [err, res] = await this.dispatch('tryQuery', {
      query: queries.getGAMGameTargeting,
      variables,
    })
    if (res) commit('SET_GAMES', res)
    return [err, res]
  },

  // ---------------------- //
  // GRAPHQL MUTATIONS
  // -----------------------//
}
