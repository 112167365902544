import * as queries from '@graphql/reports/queries'
import {
  createReport,
  updateReport,
  removeReport,
  archiveReports,
  unarchiveReports,
} from '@graphql/reports/mutations'

export const state = {
  reports: {
    items: Array(6)
      .fill(false)
      .map(_ => Object.create(null)),
  },
  reportList: { items: [] },
  currentReport: null,
  countries: [],
  reportFields: null,
  fetchingReportFields: false,
}

export const getters = {
  reports({ reports }) {
    return reports?.items
  },
  total({ reports }) {
    return reports?.total
  },
  currentReport({ currentReport }) {
    return currentReport
  },
  reportList({ reportList }) {
    return reportList?.items
  },
  countries({ countries }) {
    return countries
  },
  reportFields({ reportFields }) {
    return reportFields
  },
  fetchingReportFields({ fetchingReportFields }) {
    return fetchingReportFields
  },
}

export const mutations = {
  SET_REPORTS(state, data) {
    state.reports = data
  },

  SET_REPORT_LIST(state, data) {
    state.reportList = { items: data }
  },

  SET_CURRENT_REPORT(state, data) {
    state.currentReport = data
  },

  SET_COUNTRIES(state, data) {
    state.countries = data
  },

  SET_REPORT_FIELDS(state, data) {
    state.reportFields = data
  },

  SET_FETCHING_REPORT_FIELDS(state, data) {
    state.fetchingReportFields = data
  },
}

export const actions = {
  // ---------------------- //
  // GRAPHQL QUERIES
  // -----------------------//
  async getReports({ commit }, variables) {
    const [err, res] = await this.dispatch('tryQuery', {
      query: queries.getReports,
      variables: variables,
    })
    if (!err) commit('SET_REPORT_LIST', res)
    else commit('SET_REPORT_LIST', [])
    return [err, res]
  },

  async getReportById({ commit }, variables) {
    const [err, res] = await this.dispatch('tryQuery', {
      query: queries.getReports,
      variables: variables,
    })
    if (!err && res) {
      if (res.items.length === 0) {
        throw new Error(`The Report ID "${variables.ids}" does not exist`)
      }
      return [err, res.items[0]]
    } else {
      return [err, null]
    }
  },

  async getCountries({ commit }) {
    const [err, res] = await this.dispatch('tryQuery', {
      query: queries.getCountries,
    })
    if (!err) commit('SET_COUNTRIES', res)
    else commit('SET_COUNTRIES', [])

    return [err, res]
  },

  async getReportFields({ commit }, variables) {
    commit('SET_FETCHING_REPORT_FIELDS', true)
    const [err, res] = await this.dispatch('tryQuery', {
      query: queries.getReportFields,
      variables,
    })
    commit('SET_FETCHING_REPORT_FIELDS', false)
    if (!err) commit('SET_REPORT_FIELDS', res)
    else commit('SET_REPORT_FIELDS', [])

    return [err, res]
  },

  // ---------------------- //
  // GRAPHQL MUTATIONS
  // -----------------------//
  async createReport({ commit }, input) {
    const [err, res] = await this.dispatch('tryMutate', {
      mutation: createReport,
      variables: input,
    })

    return [err, res]
  },

  async updateReport({ commit }, input) {
    const [err, res] = await this.dispatch('tryMutate', {
      mutation: updateReport,
      variables: input,
    })
    return [err, res]
  },

  async removeReport({ commit }, id) {
    const [err, res] = await this.dispatch('tryMutate', {
      mutation: removeReport,
      variables: id,
    })

    return [err, res]
  },

  async archiveReports({ commit }, ids) {
    const [err, res] = await this.dispatch('tryMutate', {
      mutation: archiveReports,
      variables: ids,
    })

    return [err, res]
  },

  async unarchiveReports({ commit }, ids) {
    const [err, res] = await this.dispatch('tryMutate', {
      mutation: unarchiveReports,
      variables: ids,
    })

    return [err, res]
  },
}
