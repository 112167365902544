var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.label)?_c('label',{directives:[{name:"bem",rawName:"v-bem:label",arg:"label"}],attrs:{"for":_vm.label}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{directives:[{name:"bem",rawName:"v-bem",value:({
      disabled: _vm.disabled,
      readonly: _vm.readonly,
      $size: _vm.$size,
      focus: _vm.isFocused,
      error: _vm.error,
      success: _vm.success,
      hasState: _vm.hasState,
    }),expression:"{\n      disabled,\n      readonly,\n      $size,\n      focus: isFocused,\n      error,\n      success,\n      hasState,\n    }"}],class:_vm.$bem('::inner-box'),style:({ width: _vm.finalWidth })},[_c('input',_vm._b({directives:[{name:"bem",rawName:"v-bem:inner",arg:"inner"}],ref:_vm.inputRef,attrs:{"id":_vm.label,"type":_vm.type,"name":_vm.name,"readonly":_vm.disabled || _vm.readonly},domProps:{"value":_vm.value},on:{"input":_vm.inputHandler,"change":_vm.inputHandler}},'input',_vm.$attrs,false)),(!_vm.value)?_c('p',{directives:[{name:"bem",rawName:"v-bem:placeholder",arg:"placeholder"}]},[_vm._v(" "+_vm._s(_vm.$attrs.placeholder)+" ")]):_vm._e(),(_vm.finalIcon)?_c('span',{directives:[{name:"bem",rawName:"v-bem:icon",value:({ pointer: _vm.$listeners['icon-click'] }),expression:"{ pointer: $listeners['icon-click'] }",arg:"icon"}],on:{"click":_vm.handleIconClick}},[(_vm.finalIcon)?_c('fp-icon',{attrs:{"name":_vm.finalIcon}}):_vm._e()],1):_vm._e(),(_vm.hasState && !!_vm.message)?_c('fp-hint-message',{attrs:{"message":_vm.message}}):_vm._e(),(_vm.value && _vm.maxLetterCount)?_c('div',{directives:[{name:"bem",rawName:"v-bem:counter",arg:"counter"}]},[_vm._v(" "+_vm._s(_vm.value && _vm.value.length)+"/"+_vm._s(_vm.maxLetterCount)+" ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }