import gql from '@graphql'
import { createUploadURL } from '@src/graphql/file/mutations'
import { CreateUploadUrlOutput } from '@graphql-types'

type KeyValue = {
  key: string
  value: string
}

// Returns the new id of the file
export async function s3UploadFile(
  file: File,
  contentType: string,
  tags: KeyValue[],
  onProgress?: (ev: ProgressEvent) => any
) {
  const res = await gql.mutate({
    mutation: createUploadURL,
    variables: {
      input: {
        originalName: file.name,
        contentType: contentType,
        tags: tags,
      },
    },
  })

  const data: CreateUploadUrlOutput = res.data?.createUploadURL
  if (!data?.success) {
    throw new Error('server error')
  }

  const formData = new FormData()
  data.fields.forEach(field => {
    formData.append(field.key, field.value)
  })

  formData.append('file', file)

  const xhr = new XMLHttpRequest()

  // Progress listener needs to be attached BEFORE xhr is sent
  if (onProgress) {
    xhr.upload.onprogress = onProgress
  }

  xhr.open('POST', data.url, true)

  return new Promise<string>((resolve, reject) => {
    xhr.send(formData)

    xhr.onerror = () => {
      reject(Error('xhr error: ' + xhr.responseText))
    }

    xhr.onload = () => {
      if (xhr.status !== 204) {
        reject(Error('unexpected status code: ' + xhr.status))
        return
      }

      resolve(data.id ?? '')
    }
  })
}
