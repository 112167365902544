import * as queries from '@graphql/ad/queries'
import * as tQueries from '@graphql/titles/queries'
import { TITLE_TYPES } from '@shared/constants'
import {
  createAd,
  updateAd,
  pauseAds,
  unpauseAds,
  archiveAds,
  unarchiveAds,
  requestAdReviews,
} from '@graphql/ad/mutations'
import random from 'lodash/random'
import cloneDeep from 'lodash/cloneDeep'
import { DMAIDs, initialAdForm } from '@shared/ads/adForm'
import { UserGetters } from './user.store'

export const state = {
  ads: {
    items: [],
  },
  throttle: '',
  adList: {},
  adListAll: {},
  magniteDealIDList: {},
  magniteSeatIDList: {},
  adForm: cloneDeep(initialAdForm),
  DMAs: [],
  loadingDMAs: false,
}

export const getters = {
  ads({ ads }) {
    return ads?.items
  },
  total({ ads }) {
    return ads?.total
  },
  adForm({ adForm }) {
    return adForm
  },
  adList({ adList }) {
    return adList?.items
  },
  adListAll({ adListAll }) {
    return adListAll?.items
  },
  magniteDealIDList({ magniteDealIDList }) {
    return magniteDealIDList?.items
  },
  magniteSeatIDList({ magniteSeatIDList }) {
    return magniteSeatIDList?.items
  },
  DMAs({ DMAs }) {
    return DMAs
  },
  loadingDMAs({ loadingDMAs }) {
    return loadingDMAs
  },
}

export const mutations = {
  SET_ASYNC_THROTTLE(state, data) {
    state.throttle = data
  },
  SET_ADS(state, data) {
    state.ads = data
  },
  SET_ADS_LIST(state, data) {
    const firstItem = [{ title: 'All Ads', id: null }]
    let filterList = cloneDeep(data)
    state.adList = { items: data }
    state.adListAll = { items: firstItem.concat(filterList) }
  },
  SET_MAGNITE_SEAT_ID_LIST(state, data) {
    let firstItem = [{ title: 'All Seat IDs', id: null }]
    let filterList = cloneDeep(data)
    state.magniteSeatIDList = { items: firstItem.concat(filterList) }
  },
  SET_MAGNITE_DEAL_ID_LIST(state, data) {
    let firstItem = [{ title: 'All Deal IDs', id: null }]
    let filterList = cloneDeep(data)
    state.magniteDealIDList = { items: firstItem.concat(filterList) }
  },
  SET_AD_FORM(state, data) {
    const newData = { ...state.adForm, ...data }
    state.adForm = newData
  },
  RESET_AD_FORM(state) {
    state.adForm = cloneDeep(initialAdForm)
  },
  SET_LOADING_DMAS(state, data) {
    state.loadingDMAs = data
  },
  SET_DMAS(state, data) {
    state.DMAs = data
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ dispatch }) {
    dispatch('getDMAs')
  },

  resetForm({ commit }) {
    commit('RESET_AD_FORM')
  },

  // ---------------------- //
  // GRAPHQL QUERIES
  // -----------------------//
  async getAds({ dispatch, commit, state }, variables) {
    const throttle = await dispatch('vuexStateThrottle')

    const [err, res] = await this.dispatch('tryQuery', {
      query: queries.getAds,
      variables: {
        ...variables,
        // TODO: enable IO to FPSA only
        canAccessInsertionOrder: UserGetters('canAccessInsertionOrder'),
      },
    })

    if (res && throttle === state.throttle) {
      commit('SET_ADS', res)
    }
    return [err, res]
  },

  async getAd(_, id) {
    const [err, res] = await this.dispatch('tryQuery', {
      query: queries.getAds,
      variables: {
        ids: [id],
        // TODO: enable IO to FPSA only
        canAccessInsertionOrder: UserGetters('canAccessInsertionOrder'),
      },
    })

    if (res?.items?.length === 1 && !err) {
      return [null, res.items[0]]
    } else {
      return [err, null]
    }
  },

  async getAdList({ commit }, variables = { type: TITLE_TYPES.AD }) {
    const [err, res] = await this.dispatch('tryQuery', {
      query: tQueries.getTitles,
      variables,
    })

    if (!err) commit('SET_ADS_LIST', res)
  },

  async getMagniteSeatIDList(
    { commit },
    variables = { type: TITLE_TYPES.MAGNITE_SEAT_ID }
  ) {
    const [err, res] = await this.dispatch('tryQuery', {
      query: tQueries.getTitles,
      variables,
    })
    if (!err) commit('SET_MAGNITE_SEAT_ID_LIST', res)
  },

  async getMagniteDealIDList(
    { commit },
    variables = { type: TITLE_TYPES.MAGNITE_DEAL_ID }
  ) {
    const [err, res] = await this.dispatch('tryQuery', {
      query: tQueries.getTitles,
      variables,
    })
    if (!err) commit('SET_MAGNITE_DEAL_ID_LIST', res)
  },

  async getAdSummary(_, { ad }) {
    const [err, res] = await this.dispatch('tryQuery', {
      query: queries.getAdSummary,
      variables: { ads: [ad] },
    })

    if (res?.items?.length === 1 && !err) {
      return [null, res.items[0]]
    } else {
      return [err, null]
    }
  },

  async getDMAs({ commit }) {
    commit('SET_LOADING_DMAS', true)
    const [err, res] = await this.dispatch('tryQuery', {
      query: queries.getDMAs,
      variables: {
        limit: 1000,
        skip: 0,
      },
    })
    commit('SET_LOADING_DMAS', false)

    if (err) {
      return
    }

    const rv = res.items.map(i => {
      if (i.id === DMAIDs.ALL) {
        return {
          text: i.title,
          value: i.id,
        }
      }
      return {
        text: `${i.title} (${i.id})`,
        value: i.id,
      }
    })

    rv.sort((a, b) => {
      if (a.value === DMAIDs.ALL) {
        return -1
      }
      return 0
    })

    commit('SET_DMAS', rv)
  },

  // ---------------------- //
  // GRAPHQL MUTATIONS
  // -----------------------//
  async createAd({ commit }, input) {
    const [err, res] = await this.dispatch('tryMutate', {
      mutation: createAd,
      variables: input,
    })

    return [err, res]
  },

  async updateAd({ commit }, input) {
    const [err, res] = await this.dispatch('tryMutate', {
      mutation: updateAd,
      variables: input,
    })

    return [err, res]
  },

  async pauseAds({ commit }, ids) {
    const [err, res] = await this.dispatch('tryMutate', {
      mutation: pauseAds,
      variables: { ids },
    })

    return [err, res]
  },

  async unpauseAds({ commit }, ids) {
    const [err, res] = await this.dispatch('tryMutate', {
      mutation: unpauseAds,
      variables: { ids },
    })

    return [err, res]
  },

  async archiveAds({ commit }, ids) {
    const [err, res] = await this.dispatch('tryMutate', {
      mutation: archiveAds,
      variables: { ids },
    })

    return [err, res]
  },

  async unarchiveAds({ commit }, ids) {
    const [err, res] = await this.dispatch('tryMutate', {
      mutation: unarchiveAds,
      variables: { ids },
    })

    return [err, res]
  },

  async requestAdReviews({ commit }, ids) {
    const [err, res] = await this.dispatch('tryMutate', {
      mutation: requestAdReviews,
      variables: { input: { ids: [...ids] } },
    })

    return [err, res]
  },

  async saveAdForm({ commit }, data) {
    await commit('SET_AD_FORM', cloneDeep(data))
  },

  // ---------------------- //
  // HELPER ACTIONS
  // -----------------------//
  vuexStateThrottle({ commit }) {
    // Stop the Vuex store from updating the state with old data requests
    // Give us a random number to compare against
    let throttle = random(0, 1000)
    commit('SET_ASYNC_THROTTLE', throttle)
    return throttle
  },
}
