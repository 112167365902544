import { useList } from './useList'

// syntax for generic is to require the generic to be set
/**
 * Example usage
 *
 * const items = { m: 'male', f: 'female' }
 *
 * const radio = useRadio<keyof typeof items>(items)
 *
 * radio.set('m')
 *
 *
 */
export const useRadio = <K = void, U extends K = K>() => {
  const list = useList<K>([])

  const set = (key: K) => {
    if (is(key)) {
      list.filter(k => k !== key)
    } else {
      list.set([key])
    }
  }

  const getValue = () => list?.state?.items[0] ?? null
  const isEmpty = () => !getValue()

  const is = (key: K) => list.state.items.includes(key)
  return { is, set, getValue, isEmpty }
}

export default useRadio
