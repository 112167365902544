import * as sharedApi from '../api'
import { EChartOption, EChartsOptionConfig, ECharts } from 'echarts/lib/echarts'
import * as echarts from 'echarts/lib/echarts'
import 'echarts/lib/component/grid'
import 'echarts/lib/chart/line'
import { formatAndAbbrCurrency, formatAndAbbrNumber } from '@utils'

const init = (dom: HTMLElement) => sharedApi.init(echarts, dom)

const setOption = (
  chart: ECharts,
  option: EChartOption,
  opts: EChartsOptionConfig,
  isEmptyOpts?: sharedApi.IsEmptyOpts
) => {
  const defaultOption: EChartOption = {
    xAxis: {
      type: 'category',
      boundaryGap: false,

      splitLine: {
        show: false,
      },
      show: false,
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: false,
      },
      show: false,
      axisPointer: {
        show: true,
      },
    },
    grid: {
      containLabel: false,
      left: 0,
      top: 0,
      right: 0,
      bottom: 17,
    },
    series: [
      {
        // enable symbol for easier debugging
        // symbol: 'circle',
        data: [],
        type: 'line',
        smooth: true,
        smoothMonotone: 'x',
        areaStyle: {},
      },
    ],
  }

  sharedApi.setOption(chart, option, defaultOption, null, opts, isEmptyOpts)
}

const formatTooltipLabel = (
  value: string | number,
  valueIsDollars: boolean
) => {
  const rt = +value
  if (valueIsDollars) {
    return formatAndAbbrCurrency(rt, 2)
  }

  if (rt > 1000) {
    return formatAndAbbrNumber(+rt.toFixed(3))
  }

  // @ts-ignore
  return formatAndAbbrNumber(parseInt(rt))
}

export { init, setOption, formatTooltipLabel }
