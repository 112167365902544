// @ts-ignore

import * as lib from 'libphonenumber-js/max'

export const toNationalFormat = (countryCode, input) => {
  const phoneNumber = parsePhoneNumberFromString(input, countryCode)
  const out = phoneNumber?.formatNational() || ''
  return out
}

export const toInternationalFormat = (countryCode, input) => {
  const nat = toNationalFormat(countryCode, input)
  const phoneNumber = parsePhoneNumberFromString(nat, countryCode)
  const out = phoneNumber?.number || ''
  return out
}

// country can only be inferred if the input phone is in international
// format (+ country dialling code. e.g. if AU then +61 049791123)
// otherwise it will return empty string
export const inferCountry = input => {
  const phoneNumber = parsePhoneNumberFromString(input)
  const out = phoneNumber?.country || ''

  return out
}

export const parsePhoneNumberFromString = (input, countryCode = '') => {
  const phoneNumber = lib.parsePhoneNumberFromString(
    input || '',
    countryCode?.toUpperCase()
  )
  return phoneNumber
}
