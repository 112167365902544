import gql from 'graphql-tag'
import { output } from '../../shared/fragments/mutationOutput'

export const createReport = gql`
  mutation createReport($input: CreateReportInput!) {
    createReport(input: $input) {
      ...output
    }
  }
  ${output}
`

export const updateReport = gql`
  mutation updateReport($input: UpdateReportInput!) {
    updateReport(input: $input) {
      ...output
    }
  }
  ${output}
`

export const removeReport = gql`
  mutation removeReport($id: ID!) {
    removeReport(id: $id) {
      ...output
    }
  }
  ${output}
`

export const archiveReports = gql`
  mutation archiveReports($ids: [ID!]!) {
    archiveReports(ids: $ids) {
      ...output
    }
  }
  ${output}
`

export const unarchiveReports = gql`
  mutation unarchiveReports($ids: [ID!]!) {
    unarchiveReports(ids: $ids) {
      ...output
    }
  }
  ${output}
`

export const sendSlackMessage = gql`
  mutation sendSlackMessage($items: [SendSlackMessageInput]!) {
    sendSlackMessage(items: $items) {
      ...output
    }
  }
  ${output}
`
