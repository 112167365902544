<template>
  <v-container fluid class="px-0 pt-0">
    <v-row class="mx-0" no-gutters>
      <v-col :class="[$vuetify.breakpoint.smAndUp && 'mr-3']">
        <fp-select
          :value="countryCode"
          :items="items"
          name="code"
          :error="error"
          label="Country code"
          :disabled="$attrs.disabled"
          @change="handleCountrySelect"
        />
      </v-col>

      <v-col cols="12" sm="8">
        <fp-input
          type="tel"
          :error="error"
          :message="message"
          v-bind="propsPenetrating"
          @input="changeValue"
          @change="handleChange"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AsYouType } from 'libphonenumber-js/max'
import { toInternationalFormat } from '@shared/phone/helpers'
import { getAll } from '@shared/countries/api'

const DEFAULT_COUNTRY = 'US'

export default {
  name: 'FpTelInput',

  inheritAttrs: false,

  props: {
    value: {
      type: String,
      default: '',
    },
    country: {
      type: String,
      default: DEFAULT_COUNTRY,
    },
    error: Boolean,
    message: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      countryCode: DEFAULT_COUNTRY,
      phoneList: [],
    }
  },

  computed: {
    propsPenetrating() {
      const { value, countryCode } = this
      // format the code to show the user
      const formatted = this.format(value, countryCode)
      this.$emit('inputFormatted', formatted)

      return {
        value: formatted,
        ...this.$attrs,
      }
    },

    items() {
      if (!this.phoneList || this.phoneList.length < 5) return this.phoneList
      let phones = [...this.phoneList]
      phones.splice(4, 0, { divider: true })
      return phones
    },
  },

  watch: {
    code() {
      const { value } = this
      this.changeValue(value)
    },

    country() {
      this.setCountryCode()
    },
  },

  async created() {
    const countries = await getAll({
      fields: ['name', 'dialCode', 'code'],
    })
    let phoneList = [] // Country Code + (+Country dialCode)
    countries.forEach(country => {
      if (!country) return

      phoneList.push({
        dialCode: country.dialCode,
        text: `${country.name} (+${country.dialCode})`,
        value: country.code,
      })
    })

    this.phoneList = phoneList
  },

  mounted() {
    this.setCountryCode()
  },

  methods: {
    setCountryCode() {
      this.countryCode = this.country || DEFAULT_COUNTRY
    },

    handleCountrySelect(code) {
      this.countryCode = code
      this.$emit('countryChange', this.countryCode)
    },

    handleChange(value) {
      const { countryCode } = this
      // unformat the phone back to the value used by the server
      // before emitting back up to parent component

      const unformatted = this.unformat(value, countryCode)

      this.$emit('change', unformatted)
    },

    format(value, code) {
      // if the value is null or undefined, we must return
      // an empty string or else the field won't be rendered
      // https://lowlatencymedia.atlassian.net/browse/PR-4507
      if (value === null || !value) return ''

      const asYouType = new AsYouType(code?.toUpperCase())
      const out = asYouType?.input(value)
      return out || value
    },

    unformat(value, code) {
      const intlFormat = toInternationalFormat(code, value)
      return intlFormat || value
    },

    changeValue(value) {
      const { countryCode } = this
      // unformat the phone back to the value used by the server
      // before emitting back up to parent component
      this.$emit('inputFormatted', value)
      const unformatted = this.unformat(value, countryCode)
      this.$emit('input', unformatted)
    },
  },
}
</script>
