import gql from 'graphql-tag'
import { output } from '../../shared/fragments/mutationOutput'

export const createGame = gql`
  mutation createGame($input: createGameInput!) {
    createGame(input: $input) {
      ...output
    }
  }
  ${output}
`

export const updateGame = gql`
  mutation updateGame($input: updateGameInput!) {
    updateGame(input: $input) {
      ...output
    }
  }
  ${output}
`

export const pauseGames = gql`
  mutation pauseGames($ids: [ID]!) {
    pauseGames(ids: $ids) {
      ...output
    }
  }
  ${output}
`

export const unpauseGames = gql`
  mutation unpauseGames($ids: [ID]!) {
    unpauseGames(ids: $ids) {
      ...output
    }
  }
  ${output}
`

export const archiveGames = gql`
  mutation archiveGames($ids: [ID]!) {
    archiveGames(ids: $ids) {
      ...output
    }
  }
  ${output}
`

export const unarchiveGames = gql`
  mutation unarchiveGames($ids: [ID]!) {
    unarchiveGames(ids: $ids) {
      ...output
    }
  }
  ${output}
`

export const requestGameReviews = gql`
  mutation requestGameReviews($input: RequestGameReviewsInput!) {
    requestGameReviews(input: $input) {
      ...output
    }
  }
  ${output}
`

export const updateGameAdApprovals = gql`
  mutation updateGameAdApprovals($input: UpdateGameAdApprovalsInput!) {
    updateGameAdApprovals(input: $input) {
      ...output
    }
  }
  ${output}
`

export const removeGameAccess = gql`
  mutation removeGameAccess($input: RemoveGameAccessInput!) {
    removeGameAccess(input: $input) {
      ...output
    }
  }
  ${output}
`

export const addGameAccess = gql`
  mutation addGameAccess($input: AddGameAccessInput!) {
    addGameAccess(input: $input) {
      ...output
    }
  }
  ${output}
`
