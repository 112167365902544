import gql from 'graphql-tag'
import store from '@state/store'

const getGameStatusByIds = <T = any>(
  ids: string[]
): Promise<[any, { items: T[] } | null]> => {
  const query = gql`
    query getGames($ids: [ID]) {
      getGames(ids: $ids) {
        items {
          id
          title
          status
        }
      }
    }
  `
  return store.dispatch('tryQuery', {
    query,
    variables: { ids },
  })
}

export { getGameStatusByIds }
