import { createComponent } from '@vue/composition-api'

export default createComponent({
  name: 'SVGPenAndPaper',
  setup() {
    return () => {
      return (
        <svg
          width="80"
          height="80"
          viewBox="0 0 80 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M71 13V6C71 5.20435 71.3161 4.44129 71.8787 3.87868C72.4413 3.31607 73.2044 3 74 3C74.7956 3 75.5587 3.31607 76.1213 3.87868C76.6839 4.44129 77 5.20435 77 6V13H71Z"
            fill="#90CAF9"
          />
          <path d="M47 13V4.41602L55.584 13H47Z" fill="#90CAF9" />
          <path
            d="M71 69.767V15H77V69.767L74 75.767L71 69.767Z"
            fill="#42A5F5"
          />
          <path
            d="M3 77V3H45V14C44.9987 14.1317 45.0236 14.2623 45.0734 14.3843C45.1232 14.5062 45.1968 14.617 45.2899 14.7101C45.383 14.8032 45.4938 14.8768 45.6157 14.9266C45.7377 14.9764 45.8683 15.0013 46 15H57V77H3ZM38 65C37.7348 65 37.4804 65.1054 37.2929 65.2929C37.1054 65.4804 37 65.7348 37 66C37 66.2652 37.1054 66.5196 37.2929 66.7071C37.4804 66.8946 37.7348 67 38 67H46C46.2652 67 46.5196 66.8946 46.7071 66.7071C46.8946 66.5196 47 66.2652 47 66C47 65.7348 46.8946 65.4804 46.7071 65.2929C46.5196 65.1054 46.2652 65 46 65H38ZM14 49C13.7348 49 13.4804 49.1054 13.2929 49.2929C13.1054 49.4804 13 49.7348 13 50C13 50.2652 13.1054 50.5196 13.2929 50.7071C13.4804 50.8946 13.7348 51 14 51H26C26.2652 51 26.5196 50.8946 26.7071 50.7071C26.8946 50.5196 27 50.2652 27 50C27 49.7348 26.8946 49.4804 26.7071 49.2929C26.5196 49.1054 26.2652 49 26 49H14ZM14 37C13.7348 37 13.4804 37.1054 13.2929 37.2929C13.1054 37.4804 13 37.7348 13 38C13 38.2652 13.1054 38.5196 13.2929 38.7071C13.4804 38.8946 13.7348 39 14 39H46C46.2652 39 46.5196 38.8946 46.7071 38.7071C46.8946 38.5196 47 38.2652 47 38C47 37.7348 46.8946 37.4804 46.7071 37.2929C46.5196 37.1054 46.2652 37 46 37H14ZM14 25C13.7348 25 13.4804 25.1054 13.2929 25.2929C13.1054 25.4804 13 25.7348 13 26C13 26.2652 13.1054 26.5196 13.2929 26.7071C13.4804 26.8946 13.7348 27 14 27H46C46.2652 27 46.5196 26.8946 46.7071 26.7071C46.8946 26.5196 47 26.2652 47 26C47 25.7348 46.8946 25.4804 46.7071 25.2929C46.5196 25.1054 46.2652 25 46 25H14ZM14 13C13.7348 13 13.4804 13.1054 13.2929 13.2929C13.1054 13.4804 13 13.7348 13 14C13 14.2652 13.1054 14.5196 13.2929 14.7071C13.4804 14.8946 13.7348 15 14 15H34C34.2652 15 34.5196 14.8946 34.7071 14.7071C34.8946 14.5196 35 14.2652 35 14C35 13.7348 34.8946 13.4804 34.7071 13.2929C34.5196 13.1054 34.2652 13 34 13H14Z"
            fill="#42A5F5"
          />
          <path
            d="M74 0C72.4091 0.0012712 70.8837 0.633821 69.7588 1.75876C68.6339 2.88371 68.0013 4.40909 68 6V70C67.9983 70.3091 68.0702 70.6143 68.21 70.89L72.21 78.89C72.3755 79.2233 72.6307 79.5039 72.9469 79.7C73.2632 79.8961 73.6279 80 74 80C74.3722 80 74.7369 79.8961 75.0531 79.7C75.3694 79.5039 75.6246 79.2233 75.79 78.89L79.79 70.89C79.9298 70.6143 80.0018 70.3091 80 70V6C79.9988 4.40909 79.3662 2.88371 78.2413 1.75876C77.1163 0.633821 75.5909 0.0012712 74 0ZM76 69.53L74 73.53L72 69.53V16H76V69.53ZM76 12H72V6C72 5.46957 72.2107 4.96086 72.5858 4.58579C72.9609 4.21071 73.4696 4 74 4C74.5305 4 75.0392 4.21071 75.4142 4.58579C75.7893 4.96086 76 5.46957 76 6V12Z"
            fill="#1976D2"
          />
          <path
            d="M47.41 0.590012C47.2263 0.402514 47.0069 0.253709 46.7647 0.152378C46.5225 0.0510458 46.2625 -0.000761235 46 1.19863e-05H2.00001C1.73711 -0.000899742 1.47663 0.0502094 1.23357 0.150396C0.990505 0.250582 0.769665 0.397867 0.583766 0.583766C0.397867 0.769665 0.250582 0.990505 0.150396 1.23357C0.0502094 1.47663 -0.000899742 1.73711 1.19863e-05 2.00001V78C-0.000899742 78.2629 0.0502094 78.5234 0.150396 78.7665C0.250582 79.0095 0.397867 79.2304 0.583766 79.4163C0.769665 79.6022 0.990505 79.7494 1.23357 79.8496C1.47663 79.9498 1.73711 80.0009 2.00001 80H58C58.2629 80.0009 58.5234 79.9498 58.7665 79.8496C59.0095 79.7494 59.2304 79.6022 59.4163 79.4163C59.6022 79.2304 59.7494 79.0095 59.8496 78.7665C59.9498 78.5234 60.0009 78.2629 60 78V14C60.0008 13.7375 59.949 13.4775 59.8476 13.2353C59.7463 12.9932 59.5975 12.7737 59.41 12.59L47.41 0.590012ZM48 6.83001L53.17 12H48V6.83001ZM56 76H4.00001V4.00001H44V14C43.9991 14.2629 44.0502 14.5234 44.1504 14.7665C44.2506 15.0095 44.3979 15.2304 44.5838 15.4163C44.7697 15.6022 44.9905 15.7494 45.2336 15.8496C45.4766 15.9498 45.7371 16.0009 46 16H56V76Z"
            fill="#1976D2"
          />
          <path
            d="M14 16H34C34.5304 16 35.0391 15.7893 35.4142 15.4142C35.7893 15.0391 36 14.5304 36 14C36 13.4696 35.7893 12.9609 35.4142 12.5858C35.0391 12.2107 34.5304 12 34 12H14C13.4696 12 12.9609 12.2107 12.5858 12.5858C12.2107 12.9609 12 13.4696 12 14C12 14.5304 12.2107 15.0391 12.5858 15.4142C12.9609 15.7893 13.4696 16 14 16Z"
            fill="#1976D2"
          />
          <path
            d="M14 24C13.4696 24 12.9609 24.2107 12.5858 24.5858C12.2107 24.9609 12 25.4696 12 26C12 26.5304 12.2107 27.0391 12.5858 27.4142C12.9609 27.7893 13.4696 28 14 28H46C46.5304 28 47.0391 27.7893 47.4142 27.4142C47.7893 27.0391 48 26.5304 48 26C48 25.4696 47.7893 24.9609 47.4142 24.5858C47.0391 24.2107 46.5304 24 46 24H14Z"
            fill="#1976D2"
          />
          <path
            d="M46 36H14C13.4696 36 12.9609 36.2107 12.5858 36.5858C12.2107 36.9609 12 37.4696 12 38C12 38.5304 12.2107 39.0391 12.5858 39.4142C12.9609 39.7893 13.4696 40 14 40H46C46.5304 40 47.0391 39.7893 47.4142 39.4142C47.7893 39.0391 48 38.5304 48 38C48 37.4696 47.7893 36.9609 47.4142 36.5858C47.0391 36.2107 46.5304 36 46 36Z"
            fill="#1976D2"
          />
          <path
            d="M26 48H14C13.4696 48 12.9609 48.2107 12.5858 48.5858C12.2107 48.9609 12 49.4696 12 50C12 50.5304 12.2107 51.0391 12.5858 51.4142C12.9609 51.7893 13.4696 52 14 52H26C26.5304 52 27.0391 51.7893 27.4142 51.4142C27.7893 51.0391 28 50.5304 28 50C28 49.4696 27.7893 48.9609 27.4142 48.5858C27.0391 48.2107 26.5304 48 26 48Z"
            fill="#1976D2"
          />
          <path
            d="M46 64H38C37.4696 64 36.9609 64.2107 36.5858 64.5858C36.2107 64.9609 36 65.4696 36 66C36 66.5304 36.2107 67.0391 36.5858 67.4142C36.9609 67.7893 37.4696 68 38 68H46C46.5304 68 47.0391 67.7893 47.4142 67.4142C47.7893 67.0391 48 66.5304 48 66C48 65.4696 47.7893 64.9609 47.4142 64.5858C47.0391 64.2107 46.5304 64 46 64Z"
            fill="#1976D2"
          />
        </svg>
      )
    }
  },
})
