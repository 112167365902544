import { splitCamel, articlizeUnreliable, startCaseAllUppercase } from '@utils'

export const formatType = (args, opts) => {
  const splittedCamel = splitCamel(args.type)

  let word = ''

  if (opts?.allUppercase) {
    word = startCaseAllUppercase(splittedCamel)
  } else {
    word = splittedCamel.toLowerCase()
  }

  if (opts?.articlize) {
    word = articlizeUnreliable(word)
  }

  return word
}
