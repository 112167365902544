import { createComponent } from '@vue/composition-api'

export default createComponent({
  name: 'SVGPens',
  setup() {
    return () => {
      return (
        <svg
          width="76"
          height="80"
          viewBox="0 0 76 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M27 75V65H32C32.2652 65 32.5196 64.8946 32.7071 64.7071C32.8946 64.5196 33 64.2652 33 64C33 63.7348 32.8946 63.4804 32.7071 63.2929C32.5196 63.1054 32.2652 63 32 63H27V53H32C32.2652 53 32.5196 52.8946 32.7071 52.7071C32.8946 52.5196 33 52.2652 33 52C33 51.7348 32.8946 51.4804 32.7071 51.2929C32.5196 51.1054 32.2652 51 32 51H27V41H32C32.2652 41 32.5196 40.8946 32.7071 40.7071C32.8946 40.5196 33 40.2652 33 40C33 39.7348 32.8946 39.4804 32.7071 39.2929C32.5196 39.1054 32.2652 39 32 39H27V29H32C32.2652 29 32.5196 28.8946 32.7071 28.7071C32.8946 28.5196 33 28.2652 33 28C33 27.7348 32.8946 27.4804 32.7071 27.2929C32.5196 27.1054 32.2652 27 32 27H27V17H32C32.2652 17 32.5196 16.8946 32.7071 16.7071C32.8946 16.5196 33 16.2652 33 16C33 15.7348 32.8946 15.4804 32.7071 15.2929C32.5196 15.1054 32.2652 15 32 15H27V5H41V75H27Z"
            fill="#90CAF9"
          />
          <path
            d="M3.62402 67H12.376L8.00002 75.77L3.62402 67Z"
            fill="#90CAF9"
          />
          <path
            d="M3 13V8C3 6.67392 3.52678 5.40215 4.46447 4.46447C5.40215 3.52678 6.67392 3 8 3C9.32608 3 10.5979 3.52678 11.5355 4.46447C12.4732 5.40215 13 6.67392 13 8V13H3Z"
            fill="#90CAF9"
          />
          <path
            d="M55 13V8C55 6.67392 55.5268 5.40215 56.4645 4.46447C57.4021 3.52678 58.6739 3 60 3C61.3261 3 62.5979 3.52678 63.5355 4.46447C64.4732 5.40215 65 6.67392 65 8V13H55Z"
            fill="#90CAF9"
          />
          <path d="M13 15H3V65H13V15Z" fill="#42A5F5" />
          <path
            d="M58.612 71L55 63.766V15H65V63.766L61.388 71H58.612Z"
            fill="#42A5F5"
          />
          <path
            d="M8.00003 0C5.87916 0.00280458 3.84596 0.84656 2.34628 2.34624C0.846593 3.84593 0.00283675 5.87913 3.21646e-05 8V66C-0.00174624 66.3091 0.0702462 66.6143 0.210032 66.89L6.21003 78.89C6.37545 79.2233 6.63067 79.5039 6.94692 79.7C7.26318 79.8961 7.62791 80 8.00003 80C8.37216 80 8.73689 79.8961 9.05314 79.7C9.3694 79.5039 9.62461 79.2233 9.79003 78.89L15.79 66.89C15.9298 66.6143 16.0018 66.3091 16 66V8C15.9972 5.87913 15.1535 3.84593 13.6538 2.34624C12.1541 0.84656 10.1209 0.00280458 8.00003 0ZM8.00003 73.53L5.24003 68H10.76L8.00003 73.53ZM12 64H4.00003V16H12V64ZM12 12H4.00003V8C4.00003 6.93913 4.42146 5.92172 5.17161 5.17157C5.92175 4.42143 6.93917 4 8.00003 4C9.0609 4 10.0783 4.42143 10.8285 5.17157C11.5786 5.92172 12 6.93913 12 8V12Z"
            fill="#1976D2"
          />
          <path
            d="M42 2.00001H26C25.7371 1.9991 25.4766 2.05021 25.2336 2.1504C24.9905 2.25058 24.7697 2.39787 24.5838 2.58377C24.3979 2.76966 24.2506 2.99051 24.1504 3.23357C24.0502 3.47663 23.9991 3.73711 24 4.00001V76C23.9991 76.2629 24.0502 76.5234 24.1504 76.7665C24.2506 77.0095 24.3979 77.2304 24.5838 77.4163C24.7697 77.6022 24.9905 77.7494 25.2336 77.8496C25.4766 77.9498 25.7371 78.0009 26 78H42C42.2629 78.0009 42.5234 77.9498 42.7665 77.8496C43.0095 77.7494 43.2304 77.6022 43.4163 77.4163C43.6022 77.2304 43.7494 77.0095 43.8496 76.7665C43.9498 76.5234 44.0009 76.2629 44 76V4.00001C44.0009 3.73711 43.9498 3.47663 43.8496 3.23357C43.7494 2.99051 43.6022 2.76966 43.4163 2.58377C43.2304 2.39787 43.0095 2.25058 42.7665 2.1504C42.5234 2.05021 42.2629 1.9991 42 2.00001ZM40 74H28V66H32C32.5304 66 33.0392 65.7893 33.4142 65.4142C33.7893 65.0392 34 64.5304 34 64C34 63.4696 33.7893 62.9609 33.4142 62.5858C33.0392 62.2107 32.5304 62 32 62H28V54H32C32.5304 54 33.0392 53.7893 33.4142 53.4142C33.7893 53.0392 34 52.5304 34 52C34 51.4696 33.7893 50.9609 33.4142 50.5858C33.0392 50.2107 32.5304 50 32 50H28V42H32C32.5304 42 33.0392 41.7893 33.4142 41.4142C33.7893 41.0392 34 40.5304 34 40C34 39.4696 33.7893 38.9609 33.4142 38.5858C33.0392 38.2107 32.5304 38 32 38H28V30H32C32.5304 30 33.0392 29.7893 33.4142 29.4142C33.7893 29.0392 34 28.5304 34 28C34 27.4696 33.7893 26.9609 33.4142 26.5858C33.0392 26.2107 32.5304 26 32 26H28V18H32C32.5304 18 33.0392 17.7893 33.4142 17.4142C33.7893 17.0392 34 16.5304 34 16C34 15.4696 33.7893 14.9609 33.4142 14.5858C33.0392 14.2107 32.5304 14 32 14H28V6.00001H40V74Z"
            fill="#1976D2"
          />
          <path
            d="M74 12H68V8C68 5.87827 67.1572 3.84344 65.6569 2.34315C64.1566 0.842854 62.1218 0 60 0C57.8783 0 55.8435 0.842854 54.3432 2.34315C52.8429 3.84344 52 5.87827 52 8V64C51.9983 64.3091 52.0702 64.6143 52.21 64.89L56.21 72.89C56.3756 73.2232 56.6309 73.5036 56.9471 73.6997C57.2633 73.8958 57.6279 73.9998 58 74V78C58 78.5304 58.2107 79.0391 58.5858 79.4142C58.9609 79.7893 59.4696 80 60 80C60.5305 80 61.0392 79.7893 61.4142 79.4142C61.7893 79.0391 62 78.5304 62 78V74C62.3721 73.9998 62.7368 73.8958 63.053 73.6997C63.3692 73.5036 63.6245 73.2232 63.79 72.89L67.79 64.89C67.9298 64.6143 68.0018 64.3091 68 64V16H72V26C72 26.5304 72.2107 27.0391 72.5858 27.4142C72.9609 27.7893 73.4696 28 74 28C74.5305 28 75.0392 27.7893 75.4142 27.4142C75.7893 27.0391 76 26.5304 76 26V14C76.0009 13.7371 75.9498 13.4766 75.8496 13.2336C75.7495 12.9905 75.6022 12.7697 75.4163 12.5838C75.2304 12.3979 75.0095 12.2506 74.7665 12.1504C74.5234 12.0502 74.2629 11.9991 74 12ZM64 63.53L60.77 70H59.23L56 63.53V16H64V63.53ZM64 12H56V8C56 6.93913 56.4215 5.92172 57.1716 5.17157C57.9217 4.42143 58.9392 4 60 4C61.0609 4 62.0783 4.42143 62.8285 5.17157C63.5786 5.92172 64 6.93913 64 8V12Z"
            fill="#1976D2"
          />
        </svg>
      )
    }
  },
})
