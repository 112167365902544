// @ts-check
import * as helpers from './helpers'

export function FPObject(input) {
  let val = input
  return {
    omit: function (keys) {
      val = helpers.omit(val, keys)
      return this
    },

    // todo: figure out how to detect end of method chaining, if even possible in js
    // so we don't need to call result() to get the value
    result: function () {
      return val
    },
  }
}

export default FPObject
