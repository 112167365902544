import cloneDeep from 'lodash/cloneDeep'
import isNumber from 'lodash/isNumber'
import { isString, sortString, sortNumber } from '@utils'

type Column = {
  text: string
  value: string
  grow: boolean
  sortable: boolean
  width: number | string
}

type SortOrder = 'asc' | 'desc' | ''
type SortColumnKey = string | number
type SortData = {
  order: SortOrder
  column: SortColumnKey
}

export type SortArgs<T> = {
  col: Column
  sortData: SortData
  items: T[]
}

const SORT_ORDERS: SortOrder[] = ['desc', 'asc', '']
const defaultSortData = { column: '', order: '' }

function defaultSort<T>(sortArgs: SortArgs<T>): string[] | number[] | T[] {
  const { col, sortData, items } = sortArgs

  if (sortData.order === '') return items

  const key = col.value || ''
  const isAllString = items.every(item => isString(key ? item[key] : item))
  const isAllNumber = items.every(item => isNumber(key ? item[key] : item))

  if (isAllString) {
    return sortString(items, sortData.order, key)
  }

  if (isAllNumber) {
    return sortNumber(items, sortData.order, key)
  }

  return items
}

function handleSort<T>(
  col: Column,
  sortData: SortData,
  items: T[],
  customSort: (sortArgs: SortArgs<T>) => T[]
): T[] | string[] | number[] {
  const _items = cloneDeep(items)
  const noSort = () => _items

  if (!col.sortable) {
    return noSort()
  }

  const colNameToSort = col.value
  let currentOrder = sortData.order
  if (sortData.column !== colNameToSort) {
    sortData.column = colNameToSort
    currentOrder = ''
  }
  const index = SORT_ORDERS.indexOf(currentOrder)
  const newOrder: SortOrder =
    SORT_ORDERS[index >= SORT_ORDERS.length - 1 ? 0 : index + 1]
  sortData.order = newOrder
  if (newOrder === '') {
    sortData.column = ''
  }

  // construct sort args for custom sort callback
  const sortArgs: SortArgs<T> = { col, sortData, items: _items }

  if (!sortArgs.sortData.column && !sortArgs.sortData.order) {
    return noSort()
  }

  if (customSort) {
    const customReturnValue = customSort(sortArgs)
    if (!customReturnValue) return defaultSort(sortArgs)
    return customSort(sortArgs)
  }

  return defaultSort(sortArgs)
}

export { handleSort, SORT_ORDERS, defaultSortData }
