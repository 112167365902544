import gql from 'graphql-tag'

export const getSdk = gql`
  query getSdk {
    getSdk {
      version
      url
      platform
      current
      engineName
      engineVersion
    }
  }
`
