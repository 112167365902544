import {
  createComponent,
  SetupContext,
  reactive,
  watch,
} from '@vue/composition-api'
import * as type from './type'
import style from './index.module.scss'

export default createComponent({
  name: 'NavDrawer',
  // @ts-ignore
  props: [...type.vueProps],

  setup(props: type.Props, ctx: SetupContext) {
    // hooks

    // states
    const state = reactive<any>({
      value: false,
    })

    // // events
    const handleInput = val => {
      ctx.emit('input', val)
    }

    watch(
      () => props.value,
      val => {
        state.value = val
      }
    )

    // render funcs

    // main render
    return () => {
      return (
        <v-navigation-drawer
          value={state.value}
          stateless={props.stateless}
          right
          onInput={handleInput}
          fixed
          temporary={props.temporary}
          // width={props.width ?? cState.width}
          width={props.width ?? '600px'}
          scopedSlots={{
            prepend: () => (
              <div class="py-6 px-10">
                <div class={['d-flex', 'align-center', 'justify-end']}>
                  <div class={['mr-4']}>
                    {ctx.slots.prepend && ctx.slots.prepend()}
                  </div>
                  <fp-icon-button
                    class={[style.closeBtn]}
                    icon="times"
                    size="2x"
                    onClick={() => handleInput(!props.value)}
                  />
                </div>
              </div>
            ),
            append: () =>
              ctx.slots.append && (
                <div class="py-6 px-10">
                  <div class={['d-flex', 'align-center', 'justify-center']}>
                    {ctx.slots.append()}
                  </div>
                </div>
              ),
          }}
        >
          <div class={['px-10']}>
            {ctx.slots.default && ctx.slots.default()}
          </div>
        </v-navigation-drawer>
      )
    }
  },
})
