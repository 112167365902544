import gql from 'graphql-tag'
import { output } from '@graphql/shared/fragments/mutationOutput'
import { verifyItemInput } from '../inputs'

export const verifyItemsArgs = {
  items: [{ ...verifyItemInput }],
}
export const verifyItems = gql`
  mutation verifyItems($items: [VerifyItemInput]!) {
    verifyItems(items: $items) {
      ...output
    }
  }
  ${output}
`
export const changeOwnership = gql`
  mutation changeOwnership($input: changeOwnershipInput!) {
    changeOwnership(input: $input) {
      ...output
    }
  }
  ${output}
`

export const selfApprove = gql`
  mutation selfApprove($items: [SelfApproveInput]!) {
    selfApprove(items: $items) {
      ...output
    }
  }
  ${output}
`
