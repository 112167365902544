<template>
  <i
    v-bem="{ round, circle, noAnimate, useTextColor }"
    :style="{
      ...sizeAsCSSVars,
      '--delay': delay + 's',
      '--right-margin': convertToUnit(rightMargin),
      '--left-margin': convertToUnit(leftMargin),
      '--top-margin': convertToUnit(topMargin),
      '--bottom-margin': convertToUnit(bottomMargin),
      '--width': convertToUnit(width),
    }"
  />
</template>

<script>
import useSize from '@component-mixins/useSize'
import { convertToUnit } from '@utils'

export default {
  name: 'FpSkeleton',

  mixins: [useSize],

  props: {
    round: Boolean,
    circle: Boolean,
    noAnimate: Boolean,
    useTextColor: Boolean,

    delay: {
      type: [Number, String],
      default: 0,
    },

    rightMargin: {
      type: [Number, String],
      default: 0,
    },

    leftMargin: {
      type: [Number, String],
      default: 0,
    },

    topMargin: {
      type: [Number, String],
      default: 0,
    },

    bottomMargin: {
      type: [Number, String],
      default: 0,
    },

    width: {
      type: [Number, String],
      default: '100%',
    },
  },

  methods: { convertToUnit },
}
</script>

<style lang="scss" src="@component-styles/skeleton"></style>
