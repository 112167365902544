import {
  ApiKeyTypeEnum,
  Mutation,
  MutationCreateAccountArgs,
} from '@graphql-types'
import * as queries from '@graphql/org/queries'
import * as mutas from '@graphql/org/mutations'
import * as paymentQueries from '@graphql/payment/queries'
import { getAPIKeys } from '@graphql/api-key/queries'
import {
  ORG_TYPES,
  ORG_VERIFY_STATUS,
  TITLE_TYPES,
  ROLE_TYPES,
} from '@shared/constants'
import { inferCountry } from '@shared/phone/helpers'
import { INIT_BILLING, INIT_CONTACT, INIT_ORG } from '@shared/org/constants'
import { Store } from 'vuex'
import * as tQueries from '@graphql/titles/queries'
import { state as authState } from '@state/modules/auth'
import { UserGetters } from '@src/state/modules/user.store'

export const state = {
  organisations: [{ ...INIT_ORG }],

  // only for admin inspect user's account
  currentOrganisation: null,
  payments: {
    items: null,
    total: 0,
  },
  recentPayments: {
    items: null,
  },
  apiAuth: null,
  fetching: true,

  orgList: null,
  orgListLoading: false,

  orgTableList: null,
}

export const getters = {
  fetching() {
    return state.fetching
  },
  orgListLoading() {
    return state.orgListLoading
  },
  organisation({ organisations }) {
    return organisations[0]
  },
  title(_, { organisation }) {
    return organisation.title
  },
  balance(_, { organisation }) {
    return organisation.balance || '0.00'
  },
  email(_, { organisation }) {
    return organisation.contact.email
  },
  billing(_, { organisation }) {
    return organisation.billing
  },
  payments({ payments }) {
    return payments.items
  },
  // the amount of filtered payment items including all pages
  paymentItemsTotalAmount({ payments }) {
    return +payments.amountSumAllPages
  },
  recentPayments({ recentPayments }) {
    return recentPayments.items
  },
  // Common Chunks
  orgType(_, { organisation }) {
    return organisation.orgType
  },
  roleType() {
    return getRoleTypeFromCognitoUser(authState.currentUser)
  },
  // Cognito will store user type inside localStorage
  cachedUserOrgType(_, getters, rootState) {
    return getCachedUserOrgType(rootState)
  },
  cachedUserRoleType(_, getters, rootState) {
    return getCachedUserRoleType(rootState)
  },

  isGamePublisher(_, { orgType, cachedUserOrgType, fetching }, rootState) {
    if (fetching && cachedUserOrgType) {
      return ORG_TYPES[cachedUserOrgType] === ORG_TYPES.GAME_PUBLISHER
    }
    return ORG_TYPES[orgType] === ORG_TYPES.GAME_PUBLISHER
  },
  isAgency(_, { orgType, cachedUserOrgType, fetching }) {
    if (fetching && cachedUserOrgType) {
      return ORG_TYPES[cachedUserOrgType] === ORG_TYPES.AGENCY
    }
    return ORG_TYPES[orgType] === ORG_TYPES.AGENCY
  },
  isAdvertiser(_, { orgType, cachedUserOrgType, fetching }) {
    if (fetching && cachedUserOrgType) {
      return ORG_TYPES[cachedUserOrgType] === ORG_TYPES.ADVERTISER
    }
    return ORG_TYPES[orgType] === ORG_TYPES.ADVERTISER
  },
  isThirdParty(_, { orgType, cachedUserOrgType, fetching }) {
    if (fetching && cachedUserOrgType) {
      return ORG_TYPES[cachedUserOrgType] === ORG_TYPES.THIRD_PARTY
    }
    return ORG_TYPES[orgType] === ORG_TYPES.THIRD_PARTY
  },
  isSuperAdmin(_, { orgType, cachedUserOrgType, fetching }) {
    if (fetching && cachedUserOrgType) {
      return ORG_TYPES[cachedUserOrgType] === ORG_TYPES.SUPER_ADMIN
    }
    return ORG_TYPES[orgType] === ORG_TYPES.SUPER_ADMIN
  },
  // role types
  isAccountManager(_, { roleType, cachedUserRoleType, fetching }) {
    if (fetching && cachedUserRoleType) {
      return ROLE_TYPES[cachedUserRoleType] === ROLE_TYPES.ACCOUNT_MANAGER
    }
    return ROLE_TYPES[roleType] === ROLE_TYPES.ACCOUNT_MANAGER
  },
  isAdmin(_, { roleType, cachedUserRoleType, fetching }) {
    if (fetching && cachedUserRoleType) {
      return ROLE_TYPES[cachedUserRoleType] === ROLE_TYPES.ADMIN
    }
    return ROLE_TYPES[roleType] === ROLE_TYPES.ADMIN
  },
  isOwner(_, { roleType, cachedUserRoleType, fetching }) {
    if (fetching && cachedUserRoleType) {
      return ROLE_TYPES[cachedUserRoleType] === ROLE_TYPES.OWNER
    }
    return ROLE_TYPES[roleType] === ROLE_TYPES.OWNER
  },
  isDeveloper(_, { roleType, cachedUserRoleType, fetching }) {
    if (fetching && cachedUserRoleType) {
      return ROLE_TYPES[cachedUserRoleType] === ROLE_TYPES.DEVELOPER
    }
    return ROLE_TYPES[roleType] === ROLE_TYPES.DEVELOPER
  },
  isFinance(_, { roleType, cachedUserRoleType, fetching }) {
    if (fetching && cachedUserRoleType) {
      return ROLE_TYPES[cachedUserRoleType] === ROLE_TYPES.FINANCE
    }
    return ROLE_TYPES[roleType] === ROLE_TYPES.FINANCE
  },
  isFullAccess(_, { roleType, cachedUserRoleType, fetching }) {
    if (fetching && cachedUserRoleType) {
      return ROLE_TYPES[cachedUserRoleType] === ROLE_TYPES.FULL_ACCESS
    }
    return ROLE_TYPES[roleType] === ROLE_TYPES.FULL_ACCESS
  },
  isReporting(_, { roleType, cachedUserRoleType, fetching }) {
    if (fetching && cachedUserRoleType) {
      return ROLE_TYPES[cachedUserRoleType] === ROLE_TYPES.REPORTING
    }
    return ROLE_TYPES[roleType] === ROLE_TYPES.REPORTING
  },

  isStudent(_, { organisation }) {
    return organisation.isStudent
  },
  studentInfo(_, { organisation }) {
    return organisation.student
  },

  verifyStatus(_, { verification }) {
    return ORG_VERIFY_STATUS[verification?.status]
  },
  verification(_, { organisation }) {
    return {
      status: organisation.status,
      documents: organisation.verifyHistory?.files || [],
    }
  },
  verifyHistory(_, { organisation }) {
    return organisation?.verifyHistory || {}
  },

  isUnverified(_, { verifyStatus }) {
    return verifyStatus === ORG_VERIFY_STATUS.UNVERIFIED
  },
  isRejected(_, { verifyStatus }) {
    return verifyStatus === ORG_VERIFY_STATUS.REJECTED
  },
  isUnderReview(_, { verifyStatus }) {
    return verifyStatus === ORG_VERIFY_STATUS.UNDER_REVIEW
  },

  // self approval
  isSelfApprove(_, { organisation }) {
    const { orgType, trusted } = organisation
    const isAdvertiser = ORG_TYPES[orgType] === ORG_TYPES.ADVERTISER
    const isAgency = ORG_TYPES[orgType] === ORG_TYPES.AGENCY

    // must be ADV or AGC and has the self serve to be true
    return (isAdvertiser || isAgency) && trusted
  },

  // ! if an org not verified yet, it cannot take most of actions like send review
  orgNotVerifiedYet(_, { isUnverified, isRejected, isUnderReview }) {
    return isUnverified || isRejected || isUnderReview
  },

  isVerified(_, { verifyStatus }) {
    return verifyStatus === ORG_VERIFY_STATUS.VERIFIED
  },

  // Game publisher only
  apiKey({ apiAuth }) {
    return apiAuth && apiAuth.apiKey
  },

  orgList(state) {
    return state?.orgList || []
  },
  orgTableList({ orgTableList }) {
    return orgTableList?.items || []
  },
  orgTableTotal({ orgTableList }) {
    return orgTableList?.total || 1
  },

  currentOrganisation({ currentOrganisation }) {
    if (!currentOrganisation) return {}

    return {
      ...currentOrganisation,
      email: currentOrganisation.contact.email,
      organisation: currentOrganisation,
      verification: currentOrganisation?.verification,
    }
  },
  currentOrganisationVerifyStatus({ currentOrganisation }, { verification }) {
    return currentOrganisation
      ? ORG_VERIFY_STATUS[currentOrganisation?.status]
      : ORG_VERIFY_STATUS[verification?.status]
  },
}

export const mutations = {
  SET_ORGANISATION(state, value) {
    const out = mapOrgItem(value, state.organisations[0])
    const newVal = { ...state.organisations[0], ...out }

    state.organisations.splice(0, 1, { ...newVal })
  },
  SET_ORGANISATIONS(state, items) {
    const out = items.map((org, i) => {
      const obj = mapOrgItem(org, state.organisations[i])
      return obj
    })

    state.organisations = [...out]
  },
  SET_CURRENT_ORGANISATION(state, data) {
    const out = mapOrgItem(data, state.organisations[0])
    state.currentOrganisation = out
  },
  SET_FETCHING(state, value) {
    state.fetching = value
  },
  SET_ORG_LIST_LOADING(state, value) {
    state.orgListLoading = value
  },
  SET_PAYMENTS(state, value) {
    state.payments = value
  },

  SET_RECENT_PAYMENTS(state, value) {
    state.recentPayments = value
  },
  SET_API_AUTH(state, value) {
    state.apiAuth = value
  },
  SET_BILLING(state, value) {
    const original = state.organisations?.['0'].billing
    const newBilling = { ...value } || INIT_BILLING
    newBilling.contact.phoneCountry = inferCountry(newBilling?.contact?.phone)
    const out = { ...original, ...newBilling }

    state.organisations[0].billing = { ...out }
  },
  SET_CONTACT(state, contact) {
    const original = state.organisations?.['0'].contact

    const newContact = { ...contact } || INIT_CONTACT

    newContact.phoneCountry = inferCountry(newContact.phone)

    const out = { ...original, ...newContact }

    state.organisations[0].contact = { ...out }
  },
  SET_ORG_TABLE_LIST(state, value) {
    state.orgTableList = value
  },
  SET_ORG_LIST(state, value) {
    state.orgList = value
  },

  SET_ORG_VERIFICATION_STATE(state, value) {
    state.organisations[0].status = value
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  async init({ dispatch, getters, commit }) {
    const token = JSON.parse(localStorage.getItem('user_id')!)
    commit('SET_FETCHING', true)
    await dispatch('getOrganisations', {
      limit: 1,
      ids: [token?.signInUserSession?.idToken?.payload?.organization],
    })

    if (getters.isSuperAdmin) {
      await dispatch('getOrgList')
    }

    if (getters.isAgency) {
      await dispatch('clients/getClientList', { limit: 100000 }, { root: true })
    }

    // if (!getters.isSuperAdmin) {
    //   dispatch('getRecentPayments')
    // }

    if (getters.isGamePublisher) {
      await dispatch('getGamePublishersApiKey')
    }
    commit('SET_FETCHING', false)
  },
  // ---------------------- //
  // GRAPHQL ACTIONS
  // -----------------------//

  async setBilling({ dispatch, commit, state }, data) {
    commit('SET_BILLING', data)
  },

  async setOrgListLoading({ dispatch, commit, state }, data) {
    commit('SET_ORG_LIST_LOADING', data)
  },

  async setContact({ dispatch, commit, state }, data) {
    commit('SET_CONTACT', data)
  },

  async setOrganisation({ dispatch, commit, state }, data) {
    commit('SET_ORGANISATION', data)
  },

  // Get current loggedIn organisation
  async getOrganisations(this: Store<any>, { commit }, variables) {
    // commit('SET_FETCHING', true)

    const [err, data] = await this.dispatch('tryQuery', {
      query: queries.getOrganisations,
      variables,
    })

    if (err) {
      throw err
    }

    // // infer the country of all the phones retrieved from the server
    // const orgItems = mapOrgItems(data?.items)
    const out = data?.items

    commit('SET_ORGANISATIONS', out)
    // commit('SET_FETCHING', false)
  },

  // SUPER ADMIN
  async getOrgList(
    this: Store<any>,
    { commit },
    variables = { type: TITLE_TYPES.ORG }
  ) {
    commit('SET_ORG_LIST_LOADING', true)

    const [err, data] = await this.dispatch('tryQuery', {
      query: tQueries.getTitles,
      variables,
    })

    if (!err && data) {
      commit('SET_ORG_LIST', data) // Use for admin dropdown lists
    }

    commit('SET_ORG_LIST_LOADING', false)
  },

  // SUPER ADMIN
  async createAccount(
    this: Store<any>,
    { dispatch },
    input: MutationCreateAccountArgs
  ) {
    const [err, res]: [Error | null, Mutation['createAccount']] =
      await dispatch('tryMutate', {
        mutation: mutas.createAccount,
        variables: input,
      })

    return [err, res]
  },

  async getOrgTableList(this: Store<any>, { commit }, variables) {
    const [err, data] = await this.dispatch('tryQuery', {
      query: queries.getOrganisations,
      variables,
    })

    if (!err && data) {
      commit('SET_ORG_TABLE_LIST', data) // Use for admin table
    }

    return [err, data]
  },

  async getCurrentOrganisation(this: Store<any>, { commit }, { orgId }) {
    commit('SET_FETCHING', true)
    const [err, res] = await this.dispatch('tryQuery', {
      query: queries.getOrganisations,
      variables: { ids: [orgId] },
    })
    commit('SET_FETCHING', false)

    if (err) {
      throw err
    }

    commit('SET_CURRENT_ORGANISATION', res?.items[0])

    return true
  },

  async getPayments(this: Store<any>, { commit }, variables) {
    const [err, data] = await this.dispatch('tryQuery', {
      query: paymentQueries.getPayments,
      variables,
    })

    if (!err && data) commit('SET_PAYMENTS', data)
  },

  async getRecentPayments(this: Store<any>, { commit }, variables) {
    // commit('SET_FETCHING', true)
    const [err, data] = await this.dispatch('tryQuery', {
      query: paymentQueries.getPayments,
      variables: { limit: 20, ...variables },
    })

    if (!err && data) commit('SET_RECENT_PAYMENTS', data)
    // commit('SET_FETCHING', false)
  },

  async getGamePublishersApiKey(this: Store<any>, { commit }) {
    const [err, data] = await this.dispatch('tryQuery', {
      query: getAPIKeys,
      variables: {
        userID: UserGetters('userInfo').id,
        apiKeyType: ApiKeyTypeEnum.SDK,
      },
    })

    if (!err && data) commit('SET_API_AUTH', data[0])
  },
}

// ===
// Helpers
// ===

// ⚠️ Due to super admin may has multiple account_type,
// directly read cognito:groups ARRAY to determine if super admin
// else return the first (and only) value of the array
export function getOrgTypeFromCognitoUser(user) {
  const SUPER_ADMIN = 'SUPER_ADMIN'

  const payload = user?.signInUserSession?.idToken?.payload || {}

  if (payload?.['cognito:groups']?.includes(SUPER_ADMIN)) {
    return SUPER_ADMIN
  } else {
    const tmp = payload?.['cognito:groups']
    // fix when onboard, this will break when no org
    const rv = Array.isArray(tmp) ? tmp[0] : ''

    return rv
  }
}

export function getRoleTypeFromCognitoUser(user) {
  const role =
    user?.signInUserSession?.idToken?.payload?.role.split(':')[1] || ''

  return role
}

export function getPermissionsFromCognitoUser(user) {
  const permissions =
    user?.signInUserSession?.idToken?.payload?.permissions.split('|') || ''

  return permissions
}

// ===
// Private helpers
// ===

function getCachedUserOrgType(rootState) {
  const cachedUser =
    typeof rootState.auth.currentUser === 'object'
      ? rootState.auth.currentUser
      : null

  if (cachedUser) {
    return getOrgTypeFromCognitoUser(cachedUser)
  }

  return null
}

function getCachedUserRoleType(rootState) {
  const cachedUser =
    typeof rootState.auth.currentUser === 'object'
      ? rootState.auth.currentUser
      : null

  if (cachedUser) {
    return getRoleTypeFromCognitoUser(cachedUser)
  }

  return null
}

const mapOrgItem = (newValue, original) => {
  const billContact = {
    ...original?.billing?.contact,
    ...newValue?.billing?.contact,
  }
  const contact = { ...original?.contact, ...newValue?.contact }

  billContact.phoneCountry = inferCountry(billContact?.phone)
  contact.phoneCountry = inferCountry(contact?.phone)

  const obj = {
    ...original,
    ...newValue,
    contact: { ...contact },
    billing: {
      ...original?.billing,
      ...newValue?.billing,
      contact: billContact,
    },
  }

  return obj
}
