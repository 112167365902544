import { createComponent } from '@vue/composition-api'

export default createComponent({
  name: 'SVGMegaphone',
  setup() {
    return () => {
      return (
        <svg
          width="80"
          height="72"
          viewBox="0 0 80 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M55.4976 51.2619C44.6254 45.0995 32.4512 41.5928 19.9668 41.0273L19 40.9949V14.9988L19.9785 14.9773C32.5227 14.8931 44.8031 11.3668 55.481 4.78291L57 3.86011V52.1345L55.4976 51.2619Z"
            fill="#42A5F5"
          />
          <path
            d="M12.862 68.9973L9.15701 42.9973H17.138L20.843 68.9973H12.862Z"
            fill="#42A5F5"
          />
          <path
            d="M8 40.9973C6.6744 40.9958 5.40353 40.4685 4.46619 39.5311C3.52885 38.5938 3.00156 37.3229 3 35.9973V19.9973C3.00156 18.6717 3.52885 17.4008 4.46619 16.4635C5.40353 15.5262 6.6744 14.9989 8 14.9973H17V40.9973H8Z"
            fill="#90CAF9"
          />
          <path
            d="M58.94 0.237422C58.6215 0.0660116 58.2623 -0.0152602 57.9011 0.00236113C57.5399 0.0199825 57.1903 0.13583 56.89 0.337422C44.63 8.50742 33 11.9974 18 11.9974H8C5.87913 12.0002 3.84593 12.844 2.34624 14.3437C0.84656 15.8433 0.00280458 17.8766 0 19.9974V35.9974C0.00392114 37.8092 0.622188 39.5661 1.75378 40.981C2.88538 42.396 4.46339 43.3853 6.23 43.7874L10.02 70.2774C10.0863 70.755 10.3235 71.1924 10.6875 71.5087C11.0515 71.8249 11.5178 71.9985 12 71.9974H22C22.2862 71.9971 22.569 71.9355 22.8294 71.8166C23.0897 71.6977 23.3215 71.5244 23.5092 71.3083C23.6969 71.0922 23.836 70.8384 23.9172 70.5639C23.9984 70.2895 24.0198 70.0008 23.98 69.7174L20.32 44.0974C32.78 44.5974 46.88 48.9874 56.89 55.6574C57.2165 55.8816 57.6039 56.0003 58 55.9974C58.3285 55.9974 58.6517 55.9149 58.94 55.7574C59.2603 55.5885 59.5283 55.3353 59.7152 55.0251C59.902 54.7149 60.0005 54.3595 60 53.9974V1.99742C60.0005 1.63531 59.902 1.27996 59.7152 0.969769C59.5283 0.659576 59.2603 0.406337 58.94 0.237422ZM8 39.9974C7.47467 39.9976 6.95446 39.8942 6.4691 39.6932C5.98373 39.4923 5.54271 39.1976 5.17125 38.8262C4.79979 38.4547 4.50516 38.0137 4.30419 37.5283C4.10322 37.043 3.99986 36.5228 4 35.9974V19.9974C4.0014 18.937 4.42328 17.9204 5.17312 17.1705C5.92296 16.4207 6.93956 15.9988 8 15.9974H16V39.9974H8ZM13.73 67.9974L10.31 43.9974H16.27L19.69 67.9974H13.73ZM56 50.3974C44.9783 44.1678 32.6468 40.6156 20 40.0274V15.9774C32.7229 15.9093 45.1802 12.3313 56 5.63742V50.3974Z"
            fill="#1976D2"
          />
          <path
            d="M70.0014 17.9975C70.3112 17.9972 70.6168 17.925 70.894 17.7866L78.894 13.7866C79.1289 13.6691 79.3384 13.5065 79.5106 13.3081C79.6827 13.1096 79.814 12.8793 79.8971 12.6301C79.9801 12.3809 80.0133 12.1178 79.9947 11.8558C79.9761 11.5937 79.906 11.338 79.7886 11.103C79.6711 10.8681 79.5085 10.6586 79.3101 10.4865C79.1116 10.3144 78.8812 10.183 78.632 10.1C78.3829 10.0169 78.1197 9.98373 77.8577 10.0023C77.5957 10.021 77.3399 10.091 77.105 10.2085L69.105 14.2085C68.7018 14.41 68.3785 14.7418 68.1875 15.1501C67.9965 15.5584 67.9491 16.0193 68.0529 16.4579C68.1566 16.8966 68.4056 17.2873 68.7592 17.5668C69.1129 17.8462 69.5506 17.998 70.0014 17.9975Z"
            fill="#1976D2"
          />
          <path
            d="M70 29.9973H78C78.5304 29.9973 79.0391 29.7866 79.4142 29.4115C79.7893 29.0365 80 28.5277 80 27.9973C80 27.4669 79.7893 26.9582 79.4142 26.5831C79.0391 26.208 78.5304 25.9973 78 25.9973H70C69.4696 25.9973 68.9609 26.208 68.5858 26.5831C68.2107 26.9582 68 27.4669 68 27.9973C68 28.5277 68.2107 29.0365 68.5858 29.4115C68.9609 29.7866 69.4696 29.9973 70 29.9973Z"
            fill="#1976D2"
          />
          <path
            d="M78.8939 42.2085L70.8939 38.2085C70.659 38.091 70.4032 38.021 70.1412 38.0023C69.8792 37.9837 69.6161 38.0169 69.3669 38.0999C69.1177 38.183 68.8873 38.3143 68.6889 38.4864C68.4904 38.6585 68.3278 38.868 68.2104 39.103C68.0929 39.3379 68.0228 39.5937 68.0042 39.8557C67.9856 40.1177 68.0187 40.3808 68.1018 40.63C68.1849 40.8792 68.3162 41.1096 68.4883 41.308C68.6604 41.5064 68.8699 41.669 69.1048 41.7865L77.1048 45.7865C77.5793 46.0237 78.1286 46.0628 78.6319 45.895C79.1351 45.7272 79.5511 45.3664 79.7884 44.892C80.0256 44.4175 80.0646 43.8682 79.8969 43.3649C79.7291 42.8616 79.3683 42.4456 78.8938 42.2084L78.8939 42.2085Z"
            fill="#1976D2"
          />
        </svg>
      )
    }
  },
})
