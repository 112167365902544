import * as confirmActionUpdate from '@shared/ui/templates/confirmActionUpdate'
import { getOperationName, isBulkUpdate } from '../../utils'
import { BUSINESS_ACTIONS, BUSINESS_TYPES } from '@constants'
import * as sharedMutations from '../mutations'
import * as msg from '@shared/messages'
import { isVerificationStatus } from '@shared/org/api'

// todo: refactor to be based on graphql schema directly
// e.g. import directly from graphql types and compute with js
const inferAndGetContext = (key, variables, type, action) => {
  const isVerifyItemsMutation =
    key === getOperationName(sharedMutations.verifyItems)

  let _type = type
  let _action = action

  if (isVerifyItemsMutation) {
    const item = variables.items[0]

    // todo: check agaisnt the graphql schema for verifyItems input
    _type = BUSINESS_TYPES[item?.type]
    _action = item?.approved
      ? BUSINESS_ACTIONS.APPROVE
      : BUSINESS_ACTIONS.REJECT
  }

  return {
    type: _type,
    action: _action,
    customTitle: variables.customTitle,
    prependContent: variables.prependContent,
  }
}

const buildTitleBasedOnGraphql = (key, variables, type, action) => {
  const isBulk = isBulkUpdate(variables)
  const out = confirmActionUpdate.title({
    ...inferAndGetContext(key, variables, type, action),
    isBulk,
  })
  return out
}

const buildContentBasedOnGraphql = (key, variables, type, action) => {
  const out = confirmActionUpdate.content({
    ...inferAndGetContext(key, variables, type, action),
  })
  return out
}

const getVerificationStatus = async status => {
  /**
   * @type {boolean} is status equal to vuex stored status
   */
  const isStatus = isVerificationStatus(status)

  const errorMessage = msg.actionDenied({
    status: status,
  })

  return [isStatus, errorMessage]
}

// public funcs
export const handleSensitiveInfoChange = ({
  operation,
  type = '',
  action = '',
} = {}) => {
  const { key, variables } = operation
  const title = buildTitleBasedOnGraphql(key, variables, type, action)
  const content = buildContentBasedOnGraphql(key, variables, type, action)
  const out = {
    title,
    content,
  }
  return out
}

export const handleVerificationStatus = async status => {
  const [result, errorMessage] = await getVerificationStatus(status)

  if (result)
    return {
      approved: false,
      onGuardInView: async viewModel => {
        viewModel.$confirm.verifyYourAccount()
      },
      onGuardEnd: () => {
        return [false, errorMessage]
      },
    }
}
