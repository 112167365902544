import { reactive, SetupContext, computed } from '@vue/composition-api'

export const useBreakpoint = (ctx: SetupContext) => {
  const bp = ctx?.root?.$vuetify?.breakpoint ?? null

  const state = reactive({
    mdAndDown: computed(() => bp?.mdAndDown ?? false),
    smAndDown: computed(() => bp?.smAndDown ?? false),
    xsOnly: computed(() => bp?.xsOnly ?? false),
  })

  return [state]
}

export default useBreakpoint
