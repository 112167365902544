import { FPString } from './fluent'

/**
 * @returns {string}
 */
export function humanize(str: string) {
  if (!str) return ''
  if (str.length <= 3) return str
  const newStr = FPString(str)
    .replaceAll(/^[!@#$%^&*()_+\-=\[\]{};':"\\|<>\/?]*$/g, ' ')
    .replaceAll(/[\W_]+/g, ' ')
    .trim()
    .splitCamel()
    .toLower()
    .firstUpper()
    .result()
  return newStr
}
