import { actions, getters, mutations } from './members'
import store from '../store'

type Actions = keyof typeof actions
type Computations = keyof typeof getters
type Mutations = keyof typeof mutations

export function MembersDispatch(action: Actions, payload: any) {
  return store.dispatch(`members/${action}`, payload)
}

export function MembersCommit(mutation: Mutations, payload: any) {
  return store.commit(`members/${mutation}`, payload)
}

export function MembersGetters(key: Computations) {
  return store.getters[`members/${key}`]
}
