import { datadogRum } from '@datadog/browser-rum'

export default function useDatadogRum() {
  datadogRum.init({
    applicationId: '1f6eae3e-6cd7-45f2-9d7f-f043c45d48d8',
    clientToken: 'pubbd730f06e21a37e710a10b25cefa81e2',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'v1-app-on-dev',
    env: 'dev',
    version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  })
}
