import gql from 'graphql-tag'
import { contact } from '../../user/fragments/contact'
import { studentDetails } from '../fragments/orgStudentDetails'

export const getOrganisations = gql`
  query getOrganisations(
    $ids: [ID]
    $limit: Int
    $skip: Int
    $orderBy: DefaultOrderByEnum
    $filter: OrgFiltersInput
  ) {
    # Logged In User Account
    getOrganisations(
      ids: $ids
      limit: $limit
      skip: $skip
      orderBy: $orderBy
      filter: $filter
    ) {
      skip
      limit
      total
      items {
        id
        title
        hierarchy {
          holdingOrgID
          holdingOrgTitle
          hierarchy
        }
        archived
        dateUpdated
        dateCreated
        orgType
        industry
        contact {
          ...contact
        }
        website
        businessNumber
        numberFormat
        timezone
        billing {
          company
          businessNumber
          contact {
            ...contact
          }
        }
        isStudent
        student {
          ...studentDetails
        }
        status
        verifyHistory {
          action
          actionedBy
          date
          message
          type
          files {
            url
            originalName
          }
          history {
            action
            actionedBy
            date
            files {
              url
              originalName
            }
            message
            type
          }
        }
        balance
        trusted
      }
    }
  }
  ${contact}
  ${studentDetails}
`

export const getOrganisationList = gql`
  query getOrganisations(
    $ids: [ID]
    $limit: Int
    $skip: Int
    $orderBy: DefaultOrderByEnum
    $filter: OrgFiltersInput
  ) {
    getOrganisations(
      ids: $ids
      limit: $limit
      skip: $skip
      orderBy: $orderBy
      filter: $filter
    ) {
      items {
        id
        title
        orgType
      }
    }
  }
`

export const getOrgChildren = gql`
  query getOrgChildren($id: ID, $limit: Int, $skip: Int) {
    getOrgChildren(id: $id, limit: $limit, skip: $skip) {
      items {
        adSpaces
        balance
        earnings
        email
        games {
          adSpaces
          earnings
          id
          title
          viewableImpressions
        }
        id
        orgStatus
        title
        viewableImpressions
      }
      skip
      limit
      total
    }
  }
`
export const getOrgChildrenTitle = gql`
  query getOrgChildren($id: ID, $limit: Int, $skip: Int) {
    getOrgChildren(id: $id, limit: $limit, skip: $skip) {
      items {
        id
        title
      }
      skip
      limit
      total
    }
  }
`
// export const getOrganizationTitle = gql`
//   query getOrganisations(
//     $ids: [ID]
//     $limit: Int
//     $skip: Int
//     $filter: OrgFiltersInput
//     $orderBy: DefaultOrderByEnum
//   ) {
//     getOrganisations(
//       ids: $ids
//       limit: $limit
//       skip: $skip
//       filter: $filter
//       orderBy: $orderBy
//     ) {
//       items {
//         title
//       }
//     }
//   }
// `
