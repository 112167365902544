import * as m from '../mutations'
import { getOperationName } from '../../utils'
import { BUSINESS_ACTIONS, BUSINESS_TYPES } from '@constants'
import { handleSensitiveInfoChange } from '../../shared/guards/guardHandlers'

const getGuards = () => {
  const out = {
    [getOperationName(m.pauseAdSpaces)]: operation =>
      handleSensitiveInfoChange({
        operation,
        type: BUSINESS_TYPES.AD_SPACE,
        action: BUSINESS_ACTIONS.PAUSE,
      }),
    [getOperationName(m.archiveAdSpaces)]: operation =>
      handleSensitiveInfoChange({
        operation,
        type: BUSINESS_TYPES.AD_SPACE,
        action: BUSINESS_ACTIONS.ARCHIVE,
      }),
  }
  return out
}

export const guards = {
  ...getGuards(),
}
