// @ts-ignore
import * as RouteName from './routeNames'
import * as RouteData from './routeData'

import store from '@state/store'
import MainLayout from '@layouts/main-layout'
import AuthLayout from '@layouts/auth-layout'
// import { OrgTypeEnum } from '@graphql-types'
import { Page2Hier, Page2Role, PagesEnum, Page2Permissions } from './routesMap'

const CURTAIN_EXCEEDING_HEIGHT_BASE = 100

// common route style options
const blankWithTopNav = {
  curtainExceedingHeight: `0px`,
  curtainMinHeight: '0px',
  contentClasses: 'px-0',
  curtainClasses: 'pa-0',
  hideFooter: true,
}

const routes = [
  {
    path: '/',
    name: 'main',
    component: MainLayout,
    redirect: to => 'dashboard',
    children: [
      //
      // dashboard pages
      //
      {
        name: 'dashboard-holding',
        path: 'dashboard-holding',
        component: () => lazyLoadView(import('@views/company-dashboard')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.companyDashboard.home],
          allowHier: Page2Hier[PagesEnum.companyDashboard.home],
          permissions: Page2Permissions[PagesEnum.companyDashboard.home],
          curtainExceedingHeight: `-17px`,
        },
        menuConfig: {
          label: 'Dashboard',
        },
      },
      {
        name: RouteData.holdingOrgs.name,
        path: RouteData.holdingOrgs.path,
        component: () => lazyLoadView(import('@views/companies')),
        props: {
          noTransition: true,
        },
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.companies.home],
          allowHier: Page2Hier[PagesEnum.companies.home],
          permissions: Page2Permissions[PagesEnum.companies.home],
          ...blankWithTopNav,
        },
        menuConfig: {
          label: 'Organizations',
        },
      },

      //
      // new reporting dashboard
      //
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => lazyLoadView(import('@views/reporting-dashboard')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.reportingDashboard.home],
          allowHier: Page2Hier[PagesEnum.reportingDashboard.home],
          permissions: Page2Permissions[PagesEnum.reportingDashboard.home],
          curtainExceedingHeight: `-17px`,
        },
        menuConfig: {
          label: 'Dashboard',
        },
      },

      //
      // old dashboard
      // TODO - Hide old dashboard for now. To be removed in a future date
      // See the PR below when removing the old dashboard files
      // https://bitbucket.org/frameplay/%7Bae31564e-cbde-4d10-9bef-6cec9cc6bf96%7D/pull-requests/1813
      //
      // {
      //   name: 'dashboard',
      //   path: 'dashboard',
      //   component: () => lazyLoadView(import('@views/dashboard')),
      //   meta: {
      //     authRequired: true,
      //     allow: Page2Role[PagesEnum.dashboard.home],
      //     allowHier: Page2Hier[PagesEnum.dashboard.home],
      //     permissions: Page2Permissions[PagesEnum.dashboard.home],
      //     curtainExceedingHeight: `${CURTAIN_EXCEEDING_HEIGHT_BASE + 60}px`,
      //   },
      //   menuConfig: {
      //     label: 'Dashboard',
      //   },
      // },

      //
      // org pages
      //
      {
        name: 'orgs',
        path: 'organizations',
        component: () => lazyLoadView(import('@views/orgs')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.orgs.home],
          permissions: Page2Permissions[PagesEnum.orgs.home],
        },
        menuConfig: {
          label: 'Organizations',
        },
      },
      {
        name: 'orgs-create',
        path: 'organizations/new',
        component: () => lazyLoadView(import('@views/orgs/form')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.orgs.new],
          permissions: Page2Permissions[PagesEnum.orgs.new],
          curtainExceedingHeight: `${CURTAIN_EXCEEDING_HEIGHT_BASE}px`,
        },
      },

      /**
       * SA UM HO users
       */
      {
        name: 'users',
        path: 'users',
        component: () => lazyLoadView(import('@views/users-admin')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.users.home],
        },
        menuConfig: {
          label: 'Users',
        },
      },
      {
        name: 'users-create',
        path: 'users/new',
        component: () => lazyLoadView(import('@views/users-admin/form')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.users.new],
          permissions: Page2Permissions[PagesEnum.users.new],
          curtainExceedingHeight: `${CURTAIN_EXCEEDING_HEIGHT_BASE}px`,
        },
      },
      {
        name: 'users-edit',
        path: 'users/edit/:id',
        component: () => lazyLoadView(import('@views/users-admin/form')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.users.edit],
          permissions: Page2Permissions[PagesEnum.users.edit],
          curtainExceedingHeight: `${CURTAIN_EXCEEDING_HEIGHT_BASE}px`,
        },
      },

      //
      // rates pages
      //
      {
        name: 'rates',
        path: 'rates',
        component: () => lazyLoadView(import('@views/rates/index.vue')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.rates.home],
          permissions: Page2Permissions[PagesEnum.rates.home],
        },
      },
      // {
      //   name: 'rates-new',
      //   path: 'rates/new',
      //   component: () => lazyLoadView(import('@views/rates/form/index')),
      //   meta: {
      //     authRequired: true,
      //     allow: adminOnly,
      //     curtainExceedingHeight: `${CURTAIN_EXCEEDING_HEIGHT_BASE}px`,
      //   },
      // },
      {
        name: 'rates-edit',
        path: 'rates/:id',
        component: () => lazyLoadView(import('@views/rates/form/index')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.rates.id],
          permissions: Page2Permissions[PagesEnum.rates.id],
          curtainExceedingHeight: `${CURTAIN_EXCEEDING_HEIGHT_BASE}px`,
        },
      },

      {
        name: 'ads',
        path: 'ads',
        component: () => lazyLoadView(import('@views/ads')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.ads.home],
          permissions: Page2Permissions[PagesEnum.ads.home],
        },
        menuConfig: {
          label: 'Advertisements',
        },
      },
      {
        name: 'ad-new',
        path: 'ads/new',
        component: () =>
          lazyLoadView(import('@src/router/views/ads/form/index')),
        meta: {
          authRequired: true,
          hideFooter: true,
          allow: Page2Role[PagesEnum.ads.new],
          permissions: Page2Permissions[PagesEnum.ads.new],
          curtainExceedingHeight: `${CURTAIN_EXCEEDING_HEIGHT_BASE}px`,
        },
      },
      {
        name: 'ad-summary',
        path: 'ads/:id',
        component: () =>
          lazyLoadView(import('@src/router/views/ads/ad-summary')),
        meta: {
          authRequired: true,
          hideFooter: true,
          allow: Page2Role[PagesEnum.ads.id],
          permissions: Page2Permissions[PagesEnum.ads.id],
          curtainExceedingHeight: `${CURTAIN_EXCEEDING_HEIGHT_BASE}px`,
        },
      },
      {
        name: 'ad-edit',
        path: 'ads/edit/:id',
        component: () =>
          lazyLoadView(import('@src/router/views/ads/form/index')),
        meta: {
          authRequired: true,
          hideFooter: true,
          allow: Page2Role[PagesEnum.ads.edit],
          permissions: Page2Permissions[PagesEnum.ads.edit],
          curtainExceedingHeight: `${CURTAIN_EXCEEDING_HEIGHT_BASE}px`,
        },
      },
      // Be mindful that we are reusing the path /games - which do not conflict because they can only be access by separate org types
      // add redirect upon role type to fix the refresh page bug

      /* games page */
      {
        name: 'games',
        path: 'games',
        component: () =>
          lazyLoadView(import('@views/games/index-table-listing')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.games.home],
          permissions: Page2Permissions[PagesEnum.games.home],
        },
        menuConfig: {
          label: 'Games',
        },
        beforeEnter: (to, from, next) => {
          const cogType = JSON.parse(localStorage.getItem('user_id'))
            .signInUserSession.idToken.payload['cognito:groups']
          if (cogType.includes('SUPER_ADMIN')) {
            next('/games/admin')
          } else if (cogType.includes('GAME_PUBLISHER')) {
            next('/games/publisher')
          } else if (cogType.includes('THIRD_PARTY')) {
            next('/games/third')
          }
        },
      },
      {
        name: 'games-publisher',
        path: 'games/publisher',
        component: () => lazyLoadView(import('@views/games')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.games.home],
          permissions: Page2Permissions[PagesEnum.games.home],
          curtainExceedingHeight: `${CURTAIN_EXCEEDING_HEIGHT_BASE}px`,
        },
      },
      {
        name: 'games-admin',
        path: 'games/admin',
        component: () =>
          lazyLoadView(import('@views/games/index-table-listing')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.games.home],
          permissions: Page2Permissions[PagesEnum.games.home],
        },
      },
      {
        name: 'games-third',
        path: 'games/third',
        component: () =>
          lazyLoadView(import('@views/games/index-table-listing')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.games.home],
          permissions: Page2Permissions[PagesEnum.games.home],
        },
      },

      {
        name: 'games-create',
        path: 'games/new',
        component: () => lazyLoadView(import('@views/games/form/index.tsx')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.games.new],
          permissions: Page2Permissions[PagesEnum.games.new],
          curtainExceedingHeight: '100px',
        },
      },
      {
        name: 'games-edit',
        path: 'games/edit/:id',
        component: () => lazyLoadView(import('@views/games/form/index.tsx')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.games.edit],
          permissions: Page2Permissions[PagesEnum.games.edit],
          curtainExceedingHeight: '100px',
        },
      },
      {
        name: 'game-summary',
        path: 'games/:id',
        component: () => lazyLoadView(import('@views/games/game-summary')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.games.id],
          permissions: Page2Permissions[PagesEnum.games.id],
          curtainExceedingHeight: '40%',
        },
      },

      //
      // Client
      //
      {
        name: 'clients',
        path: 'clients',
        component: () => lazyLoadView(import('@views/clients')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.clients.home],
          permissions: Page2Permissions[PagesEnum.clients.home],
        },
        menuConfig: {
          label: 'Clients',
        },
      },
      {
        name: 'client-create',
        path: 'clients/new',
        component: () => lazyLoadView(import('@views/clients/client-form')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.clients.new],
          permissions: Page2Permissions[PagesEnum.clients.new],
          curtainExceedingHeight: `${CURTAIN_EXCEEDING_HEIGHT_BASE}px`,
        },
      },
      {
        name: 'client-edit',
        path: 'clients/edit/:id',
        component: () => lazyLoadView(import('@views/clients/client-form')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.clients.edit],
          permissions: Page2Permissions[PagesEnum.clients.edit],
          curtainExceedingHeight: `${CURTAIN_EXCEEDING_HEIGHT_BASE}px`,
        },
      },
      //
      // download page
      //
      {
        name: 'download',
        path: '/download',
        component: () => lazyLoadView(import('@views/download')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.download.home],
          permissions: Page2Permissions[PagesEnum.download.home],
          curtainExceedingHeight: `${CURTAIN_EXCEEDING_HEIGHT_BASE}px`,
        },
        menuConfig: {
          label: 'Download SDK',
        },
      },
      //
      // reporting
      //
      {
        name: RouteName.REPORTING,
        path: 'reports',
        component: () =>
          lazyLoadView(import('@src/router/views/reports/index.tsx')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.reporting.home],
          permissions: Page2Permissions[PagesEnum.reporting.home],
          // curtainExceedingHeight: `${CURTAIN_EXCEEDING_HEIGHT_BASE}px`,
        },
        menuConfig: {
          label: 'Reports',
        },
      },
      {
        name: 'reports-create',
        path: 'reports/new',
        component: () =>
          lazyLoadView(import('@src/router/views/reports/form/index')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.reporting.home],
          permissions: Page2Permissions[PagesEnum.reporting.home],
          curtainExceedingHeight: `${CURTAIN_EXCEEDING_HEIGHT_BASE}px`,
        },
      },
      {
        name: 'reports-edit',
        path: 'reports/edit/:id',
        component: () =>
          lazyLoadView(import('@src/router/views/reports/form/index')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.reporting.home],
          permissions: Page2Permissions[PagesEnum.reporting.home],
          curtainExceedingHeight: `${CURTAIN_EXCEEDING_HEIGHT_BASE}px`,
        },
      },
      //
      // Old reporting route
      // Hide old reporting https://lowlatencymedia.atlassian.net/browse/PR-4307
      //
      // {
      //   name: RouteName.REPORTING_OLD,
      //   path: 'reporting',
      //   component: () =>
      //     lazyLoadView(import('@src/router/views/report/index.tsx')),
      //   meta: {
      //     authRequired: true,
      //     allow: Page2Role[PagesEnum.reportingOld.home],
      //     permissions: Page2Permissions[PagesEnum.reportingOld.home],
      //     curtainExceedingHeight: `${CURTAIN_EXCEEDING_HEIGHT_BASE}px`,
      //   },
      //   menuConfig: {
      //     label: 'Reports',
      //   },
      // },
      {
        name: 'add-funds',
        path: 'add-funds',
        component: () => lazyLoadView(import('@views/funds/funds-add')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.addFunds.home],
          permissions: Page2Permissions[PagesEnum.addFunds.home],
          curtainExceedingHeight: `${CURTAIN_EXCEEDING_HEIGHT_BASE}px`,
        },
      },

      {
        name: 'account',
        path: 'account',
        component: () => lazyLoadView(import('@views/accounts/index.tsx')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.account.home],
          permissions: Page2Permissions[PagesEnum.account.home],
          ...blankWithTopNav,
        },
      },
      {
        name: 'transactions',
        path: 'account/transactions',
        component: () =>
          lazyLoadView(import('@views/accounts/transactions-admin')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.account.transactions],
          permissions: Page2Permissions[PagesEnum.account.transactions],
        },
      },
      {
        name: 'transactions-new',
        path: 'account/transactions/new',
        component: () =>
          lazyLoadView(
            import('@src/router/views/accounts/transactions-admin/new')
          ),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.account.transactionsNew],
          permissions: Page2Permissions[PagesEnum.account.transactionsNew],
          curtainExceedingHeight: `${CURTAIN_EXCEEDING_HEIGHT_BASE}px`,
        },
      },
      {
        name: 'verification-status',
        path: 'account/verification',
        component: () => lazyLoadView(import('@views/accounts/verification')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.account.verification],
          permissions: Page2Permissions[PagesEnum.account.verification],
          curtainExceedingHeight: `${CURTAIN_EXCEEDING_HEIGHT_BASE * 0.8}px`,
        },
      },

      {
        name: 'verification-flow',
        path: 'verification',
        component: () =>
          lazyLoadView(import('@views/accounts/verification/form/index.tsx')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.verification.home],
          permissions: Page2Permissions[PagesEnum.verification.home],
          curtainExceedingHeight: `${CURTAIN_EXCEEDING_HEIGHT_BASE}px`,
        },
      },

      // Developer
      {
        name: 'advanced-settings',
        path: 'advanced-settings',
        component: () =>
          lazyLoadView(import('@views/advanced-settings/index.tsx')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.advancedSettings.home],
          ...blankWithTopNav,
        },
      },

      {
        name: 'notifications',
        path: 'notifications',
        component: () => lazyLoadView(import('@views/notifications')),
        meta: {
          authRequired: true,
          allow: Page2Role[PagesEnum.notifications.home],
          permissions: Page2Permissions[PagesEnum.notifications.home],
          curtainExceedingHeight: `${CURTAIN_EXCEEDING_HEIGHT_BASE + 60}px`,
          curtainExceedingHeightSmAndDown: 0,
          hideNotification: true,
        },
      },
      {
        name: '404',
        path: '404',
        component: () => lazyLoadView(import('@views/_404')),
        meta: {
          // 404 content height/2
          curtainExceedingHeight: '127.5px',
        },
      },
      // {
      //   name: RouteName.SIGNUP_VERIFY_WAIT,
      //   path: 'sign-up-verify-wait',
      //   component: () => lazyLoadView(import('@views/sign-up/verify-wait')),
      //   meta: {
      //     noCurtain: true,
      //     noHeader: true,
      //   },
      // },

      // {
      //   name: RouteName.VERIFY_PHONE,
      //   path: 'verify-phone',
      //   component: () => lazyLoadView(import('@views/sign-up/verify-phone')),
      //   meta: {
      //     authRequired: true,
      //     noCurtain: true,
      //     noHeader: true,
      //   },
      // },
      // {
      //   name: RouteName.ONBOARD_ACCOUNT_TYPE,
      //   path: 'onboarding/account-type',
      //   component: () =>
      //     lazyLoadView(import('@views/sign-up/onboard-account-type')),
      //   meta: {
      //     authRequired: true,
      //     noCurtain: true,
      //     noHeader: true,
      //   },
      // },
      // {
      //   name: RouteName.ONBOARD_ORG_TYPE,
      //   path: 'onboarding/org-type',
      //   component: () =>
      //     lazyLoadView(import('@views/sign-up/onboard-org-type')),
      //   meta: {
      //     authRequired: true,
      //     noCurtain: true,
      //     noHeader: true,
      //   },
      // },
      // {
      //   name: RouteName.ONBOARD_CONTACT,
      //   path: 'onboarding/contact',
      //   component: () => lazyLoadView(import('@views/sign-up/onboard-contact')),
      //   meta: {
      //     authRequired: true,
      //     noCurtain: true,
      //     noHeader: true,
      //   },
      // },
      // {
      //   name: RouteName.ONBOARD_USER,
      //   path: 'onboarding/user',
      //   component: () => lazyLoadView(import('@views/sign-up/onboard-user')),
      //   meta: {
      //     authRequired: true,
      //     noCurtain: true,
      //     noHeader: true,
      //   },
      // },
      // {
      //   name: RouteName.ONBOARD_STUDENT,
      //   path: 'onboarding/student',
      //   component: () => lazyLoadView(import('@views/sign-up/onboard-student')),
      //   meta: {
      //     authRequired: true,
      //     noCurtain: true,
      //     noHeader: true,
      //   },
      // },
      // {
      //   name: RouteName.ONBOARD_BUSINESS,
      //   path: 'onboarding/business',
      //   component: () =>
      //     lazyLoadView(import('@views/sign-up/onboard-business')),
      //   meta: {
      //     authRequired: true,
      //     noCurtain: true,
      //     noHeader: true,
      //   },
      // },
      // {
      //   name: RouteName.ONBOARD_SCHOOL,
      //   path: 'onboarding/school',
      //   component: () => lazyLoadView(import('@views/sign-up/onboard-school')),
      //   meta: {
      //     authRequired: true,
      //     noCurtain: true,
      //     noHeader: true,
      //   },
      // },
      // Admin Only pages
      //
      {
        name: 'dashboard-id',
        path: 'admin/dashboard/:id',
        component: () => lazyLoadView(import('@views/dashboard')),
        meta: {
          // Super Admin Editting someone-organisation mode
          adminEditMode: true,
          authRequired: true,
          allow: Page2Role[PagesEnum.admin.dashboardId],
          permissions: Page2Permissions[PagesEnum.admin.dashboardId],
        },
      },
      {
        name: 'org-dashboard-id',
        path: 'admin/org-dashboard/:id',
        component: () => lazyLoadView(import('@views/company-dashboard')),
        meta: {
          // Super Admin Editting someone-organisation mode
          adminEditMode: true,
          authRequired: true,
          allow: Page2Role[PagesEnum.admin.orgDashboardId],
          allowHier: Page2Hier[PagesEnum.admin.orgDashboardId],
          permissions: Page2Permissions[PagesEnum.admin.orgDashboardId],
          curtainExceedingHeight: `-17px`,
        },
      },
      {
        name: RouteData.accountID.name,
        path: 'admin/account/:id',
        component: () => lazyLoadView(import('@views/accounts')),
        props: {
          noTransition: true,
        },
        meta: {
          adminEditMode: true,
          authRequired: true,
          allow: Page2Role[PagesEnum.admin.accountId],
          permissions: Page2Permissions[PagesEnum.admin.accountId],
          ...blankWithTopNav,
        },
      },

      {
        name: 'transactions-id',
        path: 'admin/account/transactions/:id',
        component: () => lazyLoadView(import('@views/accounts/transactions')),
        meta: {
          adminEditMode: true,
          authRequired: true,
          allow: Page2Role[PagesEnum.admin.accountTransactionsId],
          permissions: Page2Permissions[PagesEnum.admin.accountTransactionsId],
        },
      },
    ],
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthLayout,
    props: true,
    children: [
      {
        name: 'login',
        path: 'login',
        alias: '/login',
        component: () => lazyLoadView(import('@views/login')),
        meta: {
          title: 'Login',
        },
      },

      // {
      //   name: 'sign-up',
      //   path: 'sign-up',
      //   alias: '/sign-up',
      //   component: () => lazyLoadView(import('@views/sign-up')),
      //   meta: {
      //     title: 'Create your account',
      //   },
      // },

      {
        name: RouteName.FORGET_PASSWORD,
        path: 'forgot-password',
        alias: '/forgot-password',
        component: () =>
          lazyLoadView(import('@views/password/forgot-password')),
        meta: {
          title: 'Reset password',
        },
      },

      {
        name: RouteName.NEW_PASSWORD,
        path: 'new-password',
        alias: '/new-password',
        component: () => lazyLoadView(import('@views/password/new-password')),
        meta: {
          title: 'Create a new password',
        },
      },

      {
        name: RouteName.SET_PASSWORD,
        path: 'set-password',
        alias: '/set-password',
        component: () => lazyLoadView(import('@views/password/set-password')),
        meta: {
          title: 'Set password',
        },
      },

      // {
      //   name: RouteName.SIGNUP_VERIFY_CODE,
      //   path: 'sign-up-verify-code',
      //   alias: '/sign-up-verify-code',
      //   component: () => lazyLoadView(import('@views/sign-up/verify-code')),
      //   meta: {
      //     noCurtain: true,
      //     noHeader: true,
      //   },
      // },
      // {
      //   name: RouteName.SIGNUP_VERIFY_CODE_FB,
      //   path: 'sign-up-verify-code-fb',
      //   alias: '/sign-up-verify-code-fb',
      //   component: () =>
      //     lazyLoadView(import('@views/sign-up/verify-code-fallback')),
      //   meta: {
      //     title: 'Email verification ',
      //   },
      // },

      // {
      //   name: RouteName.SIGNUP_VERIFIED,
      //   path: 'sign-up-verified',
      //   alias: '/sign-up-verified',
      //   component: () => lazyLoadView(import('@views/sign-up/verified')),
      //   meta: {
      //     noCurtain: true,
      //     noHeader: true,
      //   },
      // },

      // {
      //   name: 'sign-up-confirm',
      //   path: 'sign-up-confirm',
      //   alias: '/sign-up-confirm',
      //   component: () => lazyLoadView(import('@views/sign-up/verify-code')),
      //   meta: {
      //     title: 'Email confirmed',
      //   },
      // },
    ],
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => lazyLoadView(import('@views/logout')),
    meta: {
      beforeResolve() {
        store.dispatch('auth/logOut')
      },
    },
  },
  {
    path: '*',
    redirect: '404',
  },
]

export default routes

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require('@views/_loading').default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require('@views/_timeout').default,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 30000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    },
  })
}
