// @ts-ignore

import _omit from 'lodash/omit'
import _isObject from 'lodash/isObject'
import _mapValues from 'lodash/mapValues'
import _pick from 'lodash/pick'
import _pickBy from 'lodash/pickBy'
import { default as _deepEqual } from 'deep-equal'
import _merge from 'lodash/merge'
import has from 'lodash/has'
import mapKeys from 'lodash/mapKeys'
import isEmpty from 'lodash/isEmpty'
import difference from 'lodash/difference'
import _transform from 'lodash/transform'

export const transform = _transform

export const merge = (object, other) => _merge(object, other)

export const omit = <T extends object, K extends keyof T>(
  object: T,
  keys: K[]
) => {
  const rv = _omit(object, keys)
  return rv
}

export const deepEqual = (a, b, opts) => {
  const rv = _deepEqual(a, b, { ...opts })
  return rv
}

/**
 * ⚠️ Warning do not pass in very large nested objects.
 * Copy the values from b into a by props that only exist in a.
 * Only gets the diff from level 1 depth
 */
export const copyIntersection = (
  a: { [key: string]: any },
  b: { [key: string]: any }
) => {
  let _a = Object.assign({}, a)
  const _b = Object.assign({}, b)
  // remove all props from b that is not in a
  const diff = difference(Object.keys(b), Object.keys(a))
  removeProps(_b, diff)
  Object.keys(_a).forEach(k => (_a[k] = _b[k]))
  return _a
}

/**
 * ⚠️ Warning do not pass in very large nested objects
 */
export function removeProps(obj: { [key: string]: any }, keys: string[]) {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      switch (typeof obj[prop]) {
        case 'object':
          if (keys.indexOf(prop) > -1) {
            delete obj[prop]
          } else {
            removeProps(obj[prop], keys)
          }
          break
        default:
          if (keys.indexOf(prop) > -1) {
            delete obj[prop]
          }
          break
      }
    }
  }
}

export const objectFilter = (obj, predicate) =>
  Object.keys(obj)
    .filter(key => predicate(obj[key], key))
    // eslint-disable-next-line
    .reduce((res, key) => ((res[key] = obj[key]), res), {})

// uncomment this when you need it
// https://exploringjs.com/impatient-js/ch_sets.html#difference-a-b
// export const diffByKeys = (a, b, defaultValue = null) => {
//   if (!a || !b) return defaultValue
//   const all = { ...a, ...b }
//   const out = pick(all, difference(Object.keys(a), Object.keys(b)))
//   return isEmpty(out) ? defaultValue : out
// }

export const pick = (object, keys) => {
  const rv = _pick(object, keys)
  return rv
}

export const pickBy = (object, funcPerProp) => {
  const rv = _pickBy(object, funcPerProp)
  return rv
}

// export const isEmpty = obj => {
//   for (const key in obj) {
//     // ⚠️ cannot use .hasOwnProperty. Some libs like graphql-js use Object.create(null)
//     // https://stackoverflow.com/questions/47773538/typeerror-obj-hasownproperty-is-not-a-function-when-calling-graphql-mutation
//     if (Object.keys(obj).indexOf(key) !== -1) return false
//   }
//   return true
// }

export const isObject = object => {
  const rv = _isObject(object)
  return rv
}

export const mapValues = (object, iterateeFunc) => {
  const rv = _mapValues(object, iterateeFunc)
  return rv
}

export const isJSONObject = string => {
  if (typeof string !== 'string') return false

  try {
    const result = JSON.parse(string)
    return (
      Object.prototype.toString.call(result) === '[object Object]' ||
      Array.isArray(result)
    )
  } catch (err) {
    return false
  }
}

export { isEmpty, mapKeys, has }
