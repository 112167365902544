import { embedFuncs } from '@shared/string/tagged'

export const defaultAddressFormat = embedFuncs`
  ${args => args.street_number}
  ${args => (args.route ? args.route + ',' : '')}
  ${args => args.locality}
  ${args => args.administrative_area_level_1}
  ${args => args.postal_code},
  ${args => args.country}
`
