import store from '@state/store'
import gql from 'graphql-tag'
import * as m from '../mutations'
import { getOperationName, deepEqualByDoc } from '../../utils'
import { handleVerificationStatus } from '../../shared/guards'
import { ORG_VERIFY_STATUS } from '@constants'

const getUpdateCondition = async variables => {
  const docOfTruth = gql`
    fragment updateOrgGuardedFields on Organisation {
      title
      businessNumber
      verifyHistory {
        files {
          url
        }
      }
    }
  `
  const currentInput = await store.getters[`org/organisation`]
  const out = deepEqualByDoc(variables.input, currentInput, docOfTruth)

  return out
}

const handleUpdateOrgDetails = async operation => {
  const inputKeys = Object.keys(operation?.variables?.input)

  if (inputKeys.includes('holdingOrgID')) {
    return {
      title: 'Are you absolutely sure?',
      content: `
        <div>
          <p>You will be changing this organization's <strong>holding organization</strong>.</p>
          The holding organization may <strong>gain</strong> or <strong>lose</strong> access to sensitive information of this organization such as transactions and game data.
        </div>
      `,
      confirmInput: operation?.variables?.input.title,
    }
  }

  const condition = await getUpdateCondition(operation?.variables)

  if (condition) {
    if (operation?.variables.input?.verification?.documents) {
      return {
        title: 'Confirm Verification Submission',
        content: `
          <div>
            <p>Are you sure the information provided is true and correct?</p>
            <p>You will be unable to make changes to the information submitted until Frameplay has reviewed all information submitted. This will normally take between 1-3
            business days.</p>
            <p>By clicking confirm, you acknowledge that you are an administrator of the business account and are authorized or have authorization to submit this information on behalf of the business.</p>
          </div>
        `,
      }
    }

    return {
      title: 'Confirm organization Details Update',
      content: `
        <div>
          Frameplay admins may be in contact with you for new verification
          details should you choose to proceed with this change.
          <br />
          <br />
          Do you want to continue?
        </div>
      `,
    }
  }
}

const getGuards = () => {
  const out = {
    [getOperationName(m.updateOrgDetails)]: handleUpdateOrgDetails,

    [getOperationName(m.updateContactDetails)]: () =>
      handleVerificationStatus(ORG_VERIFY_STATUS.UNDER_REVIEW),

    [getOperationName(m.updateBillingDetails)]: () =>
      handleVerificationStatus(ORG_VERIFY_STATUS.UNDER_REVIEW),
  }

  return out
}

export const guards = {
  ...getGuards(),
}
