import { FPDate } from '@utils'

export const today = () => ~~(Date.now() / 1000)

export const todayAt5YearLater = () =>
  FPDate(new Date())
    .addMonths(12 * 5)
    .getAWSTimestamp()
    .result()

export const todayAt20YearLater = () =>
  FPDate(new Date())
    .addMonths(12 * 20)
    .getAWSTimestamp()
    .result()

export const todayAt13MonthBefore = () =>
  FPDate(new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000))
    .addMonths(-13)
    .getAWSTimestamp()
    .result()

export const todayAt12MonthBefore = () =>
  FPDate(new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000))
    .addMonths(-12)
    .getAWSTimestamp()
    .result()

export const monthItemList = [
  {
    text: 'January',
    value: '01',
  },
  {
    text: 'February',
    value: '02',
  },
  {
    text: 'March',
    value: '03',
  },
  {
    text: 'April',
    value: '04',
  },
  {
    text: 'May',
    value: '05',
  },
  {
    text: 'June',
    value: '06',
  },
  {
    text: 'July',
    value: '07',
  },
  {
    text: 'August',
    value: '08',
  },
  {
    text: 'September',
    value: '09',
  },
  {
    text: 'October',
    value: '10',
  },
  {
    text: 'November',
    value: '11',
  },
  {
    text: 'December',
    value: '12',
  },
]
