// @ts-check

import { allGuardedOperations } from '@graphql/shared/guards/allGuardedOperations'
import Vue from 'vue'
import { csg } from '@utils'

// add sources of guarded actions here, can come from anywhere
// like graphql requests or vuex actions or anything custom
export const state = {
  guardOnDuty: null,
  guards: { ...allGuardedOperations },
}

export const getters = {
  guardOnDuty(state) {
    return state.guardOnDuty
  },

  isApproved(state) {
    return key => {
      return state.guards[key]?.approved
    }
  },

  guardExists(state) {
    return key => {
      return !!state.guards[key]
    }
  },
}

export const mutations = {
  SET_APPROVED(state, { key, value = false, variables = null }) {
    const cd = state.guardOnDuty?.body

    if (cd) {
      state.guardOnDuty.body.approved = value
      state.guardOnDuty.variables = {
        ...state.guardOnDuty.variables,
        variables,
      }
    }
  },

  SET_GUARD(state, { key, guard, variables }) {
    Vue.set(state.guards, key, guard, variables)
  },

  SET_GUARD_ON_DUTY(state, { id, body, variables }) {
    const canStart = id && body
    Vue.set(state, 'guardOnDuty', canStart ? { body, id, variables } : null)

    if (canStart)
      csg('guard', `my watch begins`, [
        { label: 'body', body },
        { label: 'id', body: id },
        { label: 'variables', body: variables },
      ])
  },
}

export const actions = {
  async setApproved(
    { dispatch, commit, state },
    { key, value = false, variables = null }
  ) {
    await commit('SET_APPROVED', { key, value, variables })
  },

  async setGuard({ dispatch, commit, state }, { key, guard, variables }) {
    await commit('SET_GUARD', { key, guard, variables })
  },

  async setGuardOnDuty({ dispatch, commit, state }, data) {
    const { id, body, variables } = data || {}
    await commit('SET_GUARD_ON_DUTY', { id, body, variables })
  },
}
