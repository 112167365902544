import { onError } from '@apollo/client/link/error'
import { AuthDispatch } from '@src/state/modules/auth.store'

// Log any GraphQL errors or network error that occurred
export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path, errorType }) => {
      if (errorType === 'AUTHENTICATION:3') {
        // AuthDispatch('refreshSession', async () => {
        //   window.location.href = '/'
        // })

        AuthDispatch('logOut', async () => {})
      }
    })
  // if (networkError) console.log(`[Network error]: ${networkError}`)
})
