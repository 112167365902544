<template>
  <div class="d-flex">
    <label class="fp-form__label">
      <slot />
    </label>

    <div
      v-if="tooltip"
      class="fp-form__tooltip ml-1"
      @click="handleTooltipClick"
    >
      <fp-icon name="question-circle" />
    </div>
  </div>
</template>

<script>
export default {
  blockName: 'fp-form',

  name: 'FpFormLabel',

  props: {
    loading: Boolean,
    tooltip: {
      type: String, // this is HTML
      default: ``,
    },
  },

  methods: {
    handleTooltipClick() {
      this.$message({ content: this.tooltip })
    },
  },
}
</script>

<style lang="scss" src="@component-styles/form"></style>
