<template>
  <v-col v-bind="$attrs" :class="[$style.container]">
    <slot />
  </v-col>
</template>
<script>
export default {
  name: 'FpFilterItem',

  inheritAttrs: false,
}
</script>

<style lang="scss" module>
@import '@scss';

.container {
  min-width: 250px;
  padding: 0 12px 0 12px;

  @include mq($until: mobile) {
    min-width: 100%;
    max-width: 100%;
    margin-bottom: 16px;
  }
}
</style>
