import { getters, actions } from './user'
import store from '../store'

type UserAction = keyof typeof actions
type UserComputation = keyof typeof getters

export function UserDispatch(action: UserAction, payload?: any) {
  return store.dispatch(`user/${action}`, payload)
}

export function UserGetters(key: UserComputation) {
  return store.getters[`user/${key}`]
}
