import { actions, getters, mutations } from './games'
import store from '../store'

type Actions = keyof typeof actions
type Computations = keyof typeof getters
type Mutations = keyof typeof mutations

export function GamesDispatch(action: Actions, payload: any) {
  return store.dispatch(`games/${action}`, payload)
}

export function GamesCommit(mutation: Mutations, payload: any) {
  return store.commit(`games/${mutation}`, payload)
}

export function GamesGetters(key: Computations) {
  return store.getters[`games/${key}`]
}
