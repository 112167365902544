import { MutableKeys } from 'utility-types'
import * as CRUDTypes from '@components/crud/types'
import { UserRole } from '@graphql-types'

export type MemberItem = {
  selected: boolean
  loading: boolean
  expanded: boolean
  accountType: string

  orgID: string
  userID: string
  userRole: UserRole

  crudItems: CRUDTypes.Item[]
}

export type MapOptions = {
  createAccountType: string
}

export type State = {
  loading: boolean
  currentViewedItem: MemberItem
  navDrawerOpen: boolean
  mode: CRUDTypes.Mode
  actions: any[]
  // urlQuery: { id:q string }
}

export type CRUD = {
  items: CRUDTypes.Item[]
}

export type CreateData = {
  crudItems: CRUDTypes.Item[]
}

export type Validator = {
  validateAll: () => boolean
}

export type Props = {
  data: MemberItem[]
  dataMap: any
  tableColumns: any[]
  createData: CreateData
  columns: any[]
  loading: boolean
  total: number
  createFormValidator: Validator
  editFormValidators: Validator
}

// Vue-metadata types

type VuePropsKeys = MutableKeys<Props>[]
export const vueProps: VuePropsKeys = [
  'tableColumns',
  'data',
  'dataMap',
  'createData',
  'columns',
  'loading',
  'total',
  'createFormValidator',
  'editFormValidators',
]
