<template>
  <transition :name="transitionName" mode="out-in" v-on="$listeners">
    <slot v-if="loading" name="skeleton">
      <fp-skeleton v-if="!preset" :key="skeletonKey.skeleton" v-bind="$attrs" />

      <component
        :is="`fp-${preset}-skeleton`"
        v-else
        :key="skeletonKey.skeleton"
        v-bind="$attrs"
      />
    </slot>

    <div v-else :key="skeletonKey.real" name="skeleton-wrapper">
      <slot />
    </div>
  </transition>
</template>

<script>
import { registerNewSkeleton } from './skeletons-manage'
import cs from '@shared/log/console'

export default {
  name: 'FpSkeletonize',

  inheritAttrs: false,

  props: {
    loading: Boolean,

    // Look up the ./presets dir to check all presets
    preset: {
      type: String,
      default: null,
      validator(val) {
        if (!['input', 'bar-chart', 'switch', null].includes(val)) {
          cs.e(`Preset ${val} not existed, please check`, 'FpSkeletonize:')
          return false
        }

        return true
      },
    },

    transitionName: {
      type: String,
      default: 'fade-skeleton',
    },
  },

  data: () => ({
    index: registerNewSkeleton(),
  }),

  computed: {
    skeletonKey() {
      return { skeleton: `skeleton-${this.index}`, real: `real-${this.index}` }
    },
  },
}
</script>
