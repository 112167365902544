import Vue from 'vue'
import Toasted from 'vue-toasted'
import '@component-styles/toaster.scss'
import { icon } from '@fortawesome/fontawesome-svg-core'
import { html } from '@shared/string/intellisense'

/**
 * 👋👋 See toast usages here: @components/_base/toaster/toaster.stories.js
 * it's a big problem our curtomer toaster from vue-toasted
 * cannot set duration or onClick handler yet, so maybe in the future,
 * add this feature to vue-toasted!
 */

export const customToasters = {
  primary: {
    icon: 'info',
  },
  warning: {
    icon: 'exclamation',
  },
  success: {
    icon: 'check',
  },
  info: {
    icon: 'info',
  },
  error: {
    icon: 'times',
  },
}

function genMessageHtml(payload) {
  let text, subtext, type
  if (typeof payload === 'string') {
    text = payload
  } else {
    text = payload.text
    subtext = payload.subtext
    type = payload.type
  }

  if (text) {
    text = text.replace('GraphQL error:', '')
  }

  if (type === 'AUTHENTICATION:4')
    text = "User's role has insufficient permission to view this page"

  return html`
    <div class="toast__content">
      <div class="toast__message">${text}</div>
      <small class="${subtext ? 'toast__subtext' : ''}">
        ${subtext || ''}
      </small>
    </div>
  `
}

function genIconHtml(iconName) {
  return function (el) {
    const iconEle = icon({ prefix: 'fas', iconName })
    el.innerHTML = iconEle.html
    el.className = 'toast__icon'
    return el
  }
}

function genCustomOption(name, iconName) {
  return {
    position: 'top-center',
    duration: 10000,
    theme: 'bubble',
    keepOnHover: true,
    className: ['toast', `toast--${name}`],
    iconPack: 'callback',
    icon: genIconHtml(iconName),
  }
}

const useToasterPlugin = options => {
  // you can also pass options, check options reference below
  // https://github.com/shakee93/vue-toasted#options
  Vue.use(Toasted, {
    ...options,
    singleton: true,
  })

  Object.entries(customToasters).forEach(([type, o]) => {
    Vue.toasted.register(type, genMessageHtml, genCustomOption(type, o.icon))
  })

  Vue.use({
    install(Vue) {
      const ToastProxy = Vue.prototype.$toasted.global

      const ToastShortcut = (...args) => {
        ToastProxy.primary(...args)
      }

      ToastShortcut.success = ToastProxy.success
      ToastShortcut.warning = ToastProxy.warning
      ToastShortcut.error = ToastProxy.error
      ToastShortcut.info = ToastProxy.info
      ToastShortcut.primary = ToastProxy.primary

      Vue.prototype.$toast = ToastShortcut
    },
  })
}

export default useToasterPlugin
