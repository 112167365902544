import gql from 'graphql-tag'
import { output } from '../../shared/fragments/mutationOutput'

export const createStripePaymentIntent = gql`
  mutation createStripePaymentIntent($amount: Int!) {
    createStripePaymentIntent(amount: $amount) {
      clientSecret
      paymentMethods {
        id
        card {
          brand
          country
          exp_month
          exp_year
          funding
          last4
        }
        created
        type
        livemode
      }
    }
  }
`

export const removeStripePaymentMethod = gql`
  mutation removeStripePaymentMethod($id: ID!) {
    removeStripePaymentMethod(id: $id) {
      ...output
    }
  }
  ${output}
`

export const createPayment = gql`
  mutation createPayment($input: CreatePaymentInput!) {
    createPayment(input: $input) {
      ...output
    }
  }
  ${output}
`
