import style from './style.module.scss'
import {
  createComponent,
  watch,
  computed,
  reactive,
} from '@vue/composition-api'
import FpMenuCard from '@components/menus/menu-card'
import FpFeedCard from '@components/feed-card'
import * as NotificationStore from '@state/modules/notifications'
import { formatTimeAgo } from '@utils'
import { useNotification } from '@components/notification/useNotification'

export default createComponent({
  name: 'HeaderNotifier',
  components: { FpMenuCard },

  setup(_, ctx) {
    const notification = useNotification(ctx, ['action', 'dismiss'])

    const handleActivator = (toggle: () => void) => {
      if (ctx.root.$vuetify.breakpoint.smAndDown) {
        ctx.root.$router.push({ name: 'notifications' })
      } else {
        toggle && toggle()
      }
    }

    notification.getAndSetItems()

    const state = reactive({
      items: computed(() => {
        const filtered = notification.state.items.filter(o => !o.dismissed)
        const sliced = filtered.slice(0, 3)

        return sliced
      }),
    })

    watch(
      computed(() => NotificationStore.get('notifications')),
      items => {
        const newItems = (items as NotificationStore.NotificationItem[]).map(
          o => {
            const actions = notification.getActions(o)
            const rv = {
              ...o,
              actions: actions,
            }
            return rv
          }
        )

        notification.state.items = newItems
      },
      { deep: true }
    )

    return () => {
      const menu = (
        <FpMenuCard
          loading={notification.state.loading}
          title="Notifications"
          items={[...state.items]}
          footerLeft={{
            label: 'CLEAR ALL',
            onClick: async () => {
              await notification.dismissAllItems()
              await notification.getAndSetItems()
            },
            disabled: notification.cState.noItems,
          }}
          footerRight={{
            label: 'VIEW ALL',
            onClick: () => ctx.root.$router.push({ name: 'notifications' }),
            closeMenuOnClick: true,
          }}
          // @ts-ignore
          scopedSlots={{
            activator: args => {
              return (
                <div class={style.activator}>
                  {!notification.cState.allDismissed && (
                    <div class={style.dot} />
                  )}

                  <div data-cy="header-notification-activator">
                    <fp-icon-button
                      onClick={() => handleActivator(args?.toggle)}
                      icon="bell"
                    />
                  </div>
                </div>
              )
            },
            item: (item: NotificationStore.NotificationItem) => {
              const icon = notification.getIcon(item)

              return (
                <FpFeedCard
                  // key needed for reactivity
                  key={item.id}
                  title={item.title}
                  message={item.message ?? ''}
                  loading={item.loading}
                  icon={icon}
                  actions={item.actions}
                  // @ts-ignore
                  date={new Date(+item.date * 1000)}
                  dateFunction={formatTimeAgo}
                  data-cy="header-notification-card"
                />
              )
            },
            noItems: () => <div>All caught up 👌</div>,
          }}
        ></FpMenuCard>
      )

      return <div>{menu}</div>
    }
  },
})
