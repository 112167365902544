<script>
export default {
  name: 'CirclePlaceholder',
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
    <path d="M16 0C7.164 0 0 7.164 0 16s7.164 16 16 16V0z" fill="#E3E3E3" />
    <path d="M16 0v32c8.836 0 16-7.164 16-16S24.836 0 16 0z" fill="#BBB" />
    <path
      d="M18.426 24.35c0 1.278-1.088 2.317-2.43 2.317-1.34 0-2.43-1.04-2.43-2.317 0-1.278 1.09-2.317 2.43-2.317 1.342 0 2.43 1.039 2.43 2.317zM16.16 5.333h.286c1.604 0 2.944.427 4.013 1.274 1.08.847 1.621 1.971 1.621 3.373 0 .96-.198 1.773-.593 2.44-.386.66-1.077 1.438-2.067 2.334-.982.894-1.617 1.632-1.914 2.225a4.292 4.292 0 00-.446 1.948c0 .15.069.546.207 1.186h-2.841l-.28-.719c-.158-.416-.239-.776-.239-1.088 0-.593.086-1.16.252-1.707a5.59 5.59 0 01.774-1.525c.337-.48 1.003-1.244 1.993-2.287 1-1.045 1.5-1.903 1.5-2.581 0-1.316-.907-1.98-2.72-1.98-.881 0-1.799.423-2.759 1.26l-1.534-2.725c1.218-.907 2.801-1.385 4.747-1.428z"
      fill="#FFF"
    />
  </svg>
</template>
