import * as queries from '@graphql/sdk/queries'
import {
  gameEngineNameToChangelogUrlMap,
  gameEngineNameToUpgradeGuideUrlMap,
} from '@constants'

export const state = {
  sdkList: [],
}

export const getters = {
  list({ sdkList }) {
    return sdkList
  },
}

export const mutations = {
  SET_SDK(state, value) {
    let sdkArray = value

    // push cocos coming soon if no cocos is found from backend
    if (!sdkArray?.find(x => x?.engineName?.toLowerCase() === 'cocos')) {
      sdkArray.push({
        version: '-',
        platform: '-',
        current: '-',
        engineName: 'Cocos',
        engineVersion: '',
      })
    }

    state.sdkList = sdkArray.map(gameEngine => ({
      ...gameEngine,
      changelogUrl:
        gameEngineNameToChangelogUrlMap[gameEngine.engineName] ?? undefined,
      upgradeGuideUrl:
        gameEngineNameToUpgradeGuideUrlMap[gameEngine.engineName] ?? undefined,
    }))
  },
}

export const actions = {
  async getSDKList({ dispatch, commit }, variables = { limit: 10 }) {
    const [err, res] = await this.dispatch('tryQuery', {
      query: queries.getSdk,
      variables,
    })

    if (res) commit('SET_SDK', res)
    return [err, res]
  },
}
