import to from 'await-to-js'
import { cs } from '@shared/utils'

export function sleep(millisecond) {
  return new Promise(resolve => setTimeout(resolve, millisecond))
}

/**
 * @returns {[Error | null, any]}
 */
export async function asyncRetry({
  fn = () => {},
  fnName,
  inputs,
  times = 5,
  interval = 100,
  attemptedTime = 1,
  shouldRetry = () => true,
  errorMessage = '',
}: {
  fn?: any
  fnName?: string
  inputs?: any
  times?: number
  interval?: number
  attemptedTime?: number
  shouldRetry?: any
  errorMessage?: string
}) {
  const name = fnName || fn.name

  if (times === 0) {
    return [
      new Error(errorMessage || `${name} failed after ${attemptedTime} times`),
      null,
    ]
  }

  const [err, res] = await to(fn(...inputs))

  if (err) {
    const shouldContinue = shouldRetry([err, res])

    if (!shouldContinue) {
      return [err, null]
    }

    cs.e(
      `${name} failed ${attemptedTime} time, more info: ${err}`,
      'Async Retry'
    )

    await sleep(interval)

    return asyncRetry({
      fn,
      inputs,
      times: times - 1,
      interval,
      attemptedTime: attemptedTime + 1,
      // ⚠️ shouldRetry must be passed back in for some things to work like
      // bulk file uploader.
      shouldRetry,
      errorMessage,
    })
  } else {
    return [null, res]
  }
}
