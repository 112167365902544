import gql from 'graphql-tag'
import { tryMutate, tryQuery } from '@graphql/query-helpers'
import { vueToNormalObject } from '@utils'

/**
 * Gql queries and mutations
 */
const createCustomRoleMutation = gql`
  mutation createCustomRole($input: CustomRoleInput!) {
    createCustomRole(input: $input) {
      id
      success
      message
      statusCode
    }
  }
`
const updateCustomRoleMutation = gql`
  mutation updateCustomRole($input: CustomRoleInput!) {
    updateCustomRole(input: $input) {
      id
      success
      message
      statusCode
    }
  }
`
const removeCustomRoleMutation = gql`
  mutation removeCustomRole($id: ID!) {
    removeCustomRole(id: $id) {
      id
      success
      message
      statusCode
    }
  }
`
const modifyRolePermissionsMutation = gql`
  mutation modifyRolePermissions(
    $id: ID!
    $permissions: [RolePermissionEnum!]!
  ) {
    modifyRolePermissions(id: $id, permissions: $permissions) {
      id
      success
      message
      statusCode
    }
  }
`
const getRolesQuery = gql`
  query getRoles($id: ID, $limit: Int, $org: ID, $skip: Int) {
    getRoles(id: $id, limit: $limit, org: $org, skip: $skip) {
      items {
        allowEarnings
        allowLiveData
        description
        id
        isCustom
        name
        org
      }
      skip
      limit
      total
    }
  }
`
const getRolePermissionsQuery = gql`
  query getRolePermissions($limit: Int, $roleID: ID, $skip: Int) {
    getRolePermissions(limit: $limit, roleID: $roleID, skip: $skip) {
      items {
        description
        name
        org
        role {
          description
          id
          isCustom
          name
          org
        }
      }
      limit
      skip
      total
    }
  }
`

/**
 * CRUD functions
 */
export const createCustomRole = async (input = {}) => {
  const [err, data] = await tryMutate({
    mutation: createCustomRoleMutation,
    variables: { input },
  })

  if (!data) return [err, []]

  return [err, data]
}
export const updateCustomRole = async (input = {}) => {
  const [err, data] = await tryMutate({
    mutation: updateCustomRoleMutation,
    variables: { input },
  })

  if (!data) return [err, []]

  return [err, data]
}
export const removeCustomRole = async id => {
  const [err, data] = await tryMutate({
    mutation: removeCustomRoleMutation,
    variables: { id },
  })

  if (!data) return [err, []]

  return [err, data]
}
export const modifyRolePermissions = async (id, permissions) => {
  const [err, data] = await tryMutate({
    mutation: modifyRolePermissionsMutation,
    variables: { id, permissions },
  })

  if (!data) return [err, []]

  return [err, data]
}

export const getRoles = async (x: any = {}) => {
  const args = vueToNormalObject(x)

  const limit = args.limit ? args.limit : 20

  const variables = {
    limit: limit,
    ...args,
  }

  const [err, data] = await tryQuery({
    query: getRolesQuery,
    variables,
  })

  if (!data) return [err, []]

  return [err, data]
}

export const getRolePermissions = async (x: any = {}) => {
  const args = vueToNormalObject(x)

  const limit = args.limit ? args.limit : 20

  const variables = {
    limit: limit,
    ...args,
  }

  const [err, data] = await tryQuery({
    query: getRolePermissionsQuery,
    variables,
  })

  if (!data) return [err, []]

  return [err, data]
}
