<script>
import { mapGetters } from 'vuex'
import Logo from '@components/logo'
import { checkRoutePermission } from '@router/utils'
import { OrgHierarchyEnum } from '@graphql-types'
import { RoleTypeEnum } from '@src/shared/constants'

export default {
  skipGloballyRegister: true,

  name: 'HeaderMenus',

  components: {
    Logo,
  },

  data() {
    return {
      sideMenuVisible: false,
    }
  },

  computed: {
    ...mapGetters('org', [
      'orgType',
      'organisation',
      'fetching',
      'isGamePublisher',
    ]),
    ...mapGetters('ui', ['links']),
    ...mapGetters('user', ['userInfo', 'loading']),

    allMenus() {
      if (!this.userInfo.org) return []
      let menus = this.$router.options.routes
        .find(r => r.name === 'main')
        .children.filter(childRoute => {
          if (childRoute.menuConfig) {
            // for HOLDING users, only allow for dashboard and companies
            if (this.userInfo.org.hierarchy === 'HOLDING') {
              return (
                ['Dashboard', 'Organizations', 'Reports'].includes(
                  childRoute.menuConfig.label
                ) && childRoute.name !== 'dashboard'
              )
            }
            // if only allows for sepecific hierarchy
            if (childRoute.meta.allowHier) {
              if (
                childRoute.meta.allowHier.includes(this.userInfo.org.hierarchy)
              ) {
                return true
              } else {
                return false
              }
            }
            return true
          }
        })
      if (this.orgType === 'SUPER_ADMIN') {
        menus = menus.filter(route => route.menuConfig.label !== 'Download SDK')
      }
      // } else if () {
      //   menus = menus.filter(route => route.menuConfig.label !== 'Dashboard')
      // }
      return menus
    },

    menusCurrentUserHavePermission() {
      return this.allMenus.filter(item => {
        if (checkRoutePermission(item.meta.allow, item.meta.permissions))
          return true
      })
    },

    externalLinksList() {
      return {
        Documentation: { href: this.links.docs.url, text: 'Documentation' },
      }
    },

    externalLinks() {
      const { Documentation } = this.externalLinksList

      let map = {
        GAME_PUBLISHER: [Documentation],
      }
      if (
        !this.userInfo ||
        !this.userInfo.roles ||
        !this.userInfo.roles[0] ||
        !this.userInfo.org
      ) {
        return []
      }
      const role = this.userInfo.roles[0].roleID.split(':')[1]
      if (
        this.userInfo.org.hierarchy === OrgHierarchyEnum.HOLDING ||
        role === RoleTypeEnum.REPORTING
      ) {
        map = {}
      }

      return map[this.orgType] || []
    },

    isMobile() {
      return this.$vuetify.breakpoint.xs
    },
  },

  methods: {
    toggleSideMenu() {
      this.sideMenuVisible = !this.sideMenuVisible
    },
  },
}
</script>

<template>
  <transition name="fade-skeleton" mode="out-in">
    <fp-skeletonize
      :loading="fetching || loading"
      left-margin="90"
      :width="isMobile ? '0' : 300"
      height="25"
    >
      <div :class="$style.container">
        <ul
          :class="$style.menus"
          data-cy="header-menu"
          class="d-none d-lg-flex px-0"
        >
          <li
            v-for="(item, index) in menusCurrentUserHavePermission"
            :key="index"
          >
            <router-link
              active-class="is-active"
              tabindex="1"
              :to="{ name: item.name }"
              :data-cy="`header-menu-${item.name}`"
              @keyup.native.enter="_ => $router.push({ name: item.name })"
            >
              <span>
                {{ item.menuConfig.label }}
              </span>
            </router-link>
          </li>

          <li v-if="externalLinks.length" :class="$style.separator" />

          <li
            v-for="(config, i) in externalLinks"
            :key="'ex-link' + i"
            :class="$style.isExternal"
          >
            <a :class="$style.externalLink" :href="config.href" target="_blank">
              <span>
                {{ config.text }}
                <fp-icon name="external-link-alt" />
              </span>
            </a>
          </li>
        </ul>

        <div
          :class="[$style.sideMenuContainer, ['d-flex d-lg-none align-center']]"
        >
          <button
            tabindex="1"
            :class="$style.sideMenuButton"
            data-cy="mobile-menu-trigger"
            @click="toggleSideMenu"
          >
            <fp-icon name="bars" />
            Menu
          </button>

          <portal to="sideMenuPortal">
            <transition name="fade-quick">
              <span
                v-if="sideMenuVisible"
                :class="$style.sideMenuOverlay"
                @click.self="toggleSideMenu"
              />
            </transition>

            <transition name="side-menu-fade">
              <div
                v-if="sideMenuVisible"
                data-cy="mobile-side-menu"
                :class="$style.sideMenu"
              >
                <span :class="$style.closeSideMenu">
                  <fp-icon-button
                    size="large"
                    icon="times"
                    @click="toggleSideMenu"
                  />
                </span>
                <logo :class="$style.logo" theme="white" link="" />

                <ul
                  :class="[$style.menus, $style.isVertical]"
                  data-cy="mobile-menu"
                >
                  <li
                    v-for="(item, index) in menusCurrentUserHavePermission"
                    :key="index"
                  >
                    <router-link
                      active-class="is-active"
                      tabindex="1"
                      :data-cy="`mobile-menu-${item.name}`"
                      :to="{ name: item.name }"
                      @keyup.native.enter="
                        _ => $router.push({ name: item.name })
                      "
                      @click.native="toggleSideMenu"
                    >
                      <span>
                        {{ item.menuConfig.label }}
                      </span>
                    </router-link>
                  </li>
                  <li
                    v-for="(config, i) in externalLinks"
                    :key="'ex-link' + i"
                    :class="$style.isExternal"
                  >
                    <a
                      :class="$style.externalLink"
                      :href="config.href"
                      target="_blank"
                    >
                      {{ config.text }}
                      <fp-icon name="external-link-alt" />
                    </a>
                  </li>
                </ul>
              </div>
            </transition>
          </portal>
        </div>
      </div>
    </fp-skeletonize>
  </transition>
</template>

<style lang="scss" module>
@import '~watson-scss';
@import '@scss';

.container {
  position: relative;
  z-index: 1;
  height: 100%;
}

.menus {
  align-items: center;
  margin: 0;
  margin-left: 15px;
  list-style: none;

  @include mq($from: lg) {
    height: 100%;
  }

  &:not(.isVertical) > li:not(.separator) {
    @include mq($from: lg) {
      height: 100%;
    }
  }

  > li:not(.separator) a {
    @extend %font-menus;

    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 15px;
    color: var(--header-text-color);
    white-space: nowrap;

    > span {
      @include perfect-transition;

      border-bottom: 3px solid transparent;
    }

    &:hover,
    &:focus {
      color: var(--header-text-active-color);

      > span {
        @include perfect-transition(300ms);

        border-bottom-color: var(--header-text-active-color);
      }
    }

    &:focus {
      outline: none;

      > span {
        @include perfect-transition(250ms);

        border-bottom-color: var(--header-text-active-color);
      }
    }

    &:global(.is-active) {
      color: var(--header-text-active-color);

      > span {
        border-bottom-color: $color-accent;
      }
    }

    &.externalLink {
      color: var(--header-text-color);

      > svg {
        margin-left: 6px;
        font-size: 13px;
      }
    }
  }

  .separator {
    width: 2px;
    height: 30%;
    background-color: rgba($color-white, 0.3);
  }

  &.isVertical {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 80px;
    margin-left: 25px;

    > li:not(.separator) {
      display: inline-block;
      width: 100%;
      color: $color-white;

      a {
        display: block;
        width: 100%;
        padding: 20px 15px;
      }
    }
  }
}

.sideMenuContainer {
  height: 100%;
}

.sideMenuButton {
  padding: 10px;
  margin-left: 15px;
  font-size: 13px;
  font-weight: 800;
  color: var(--header-text-active-color);
  white-space: nowrap;
  border: 1px solid var(--header-text-active-color);
  border-radius: 6px;

  &:focus {
    outline: none;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  // hide the menu word when we hide the logo, to avoid confusing menu and logo icons
  @include mq($until: md) {
    padding: 10px 14px;
    margin-left: 0;
    font-size: 0;

    svg {
      font-size: initial;
    }
  }

  @include mq($until: mobile) {
    margin-left: 0;
  }
}

.sideMenuOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);

  @include mq($from: lg) {
    display: none;
  }
}

.closeSideMenu {
  position: absolute;
  top: 20px;
  right: 30px;
  color: $color-white;
}

.sideMenu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 50vw;
  min-width: 300px;
  background: linear-gradient(88.21deg, #0070f3 0%, #57a3fc 99.6%);

  @include mq($until: md) {
    width: 70vw;
  }

  > .logo {
    position: absolute;
    top: 20px;
    left: 45px;
    width: 60px;

    @include mq($until: lg) {
      top: 25px;
    }
  }

  @include mq($from: lg) {
    display: none;
  }
}
</style>
