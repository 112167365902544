import * as sharedApi from '../api'
import { EChartOption, EChartsOptionConfig, ECharts } from 'echarts/lib/echarts'
import * as echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/toolbox'

import { getTooltipContainerCss } from '../components/tooltip'

import {
  globalColorLightGrey,
  // @ts-ignore
} from '@scss-ts'

import { hexToRgba } from '@styles/utils'

const init = (dom: HTMLElement) => sharedApi.init(echarts, dom)

const setOption = (
  chart: ECharts,
  option: EChartOption,
  opts: EChartsOptionConfig
) => {
  const spacer = {
    top: 20,
    right: 30,
  }

  const defaultOption: EChartOption = {
    axisPointer: {
      shadowStyle: {
        color: hexToRgba(globalColorLightGrey, '0.3'),
      },
    },
    toolbox: {
      padding: [13, spacer.right],
      left: 5,
      feature: {
        magicType: {
          show: true,
          title: {
            line: ' ',
            bar: ' ',
          },
          type: ['bar', 'line'],
        },
      },
    },
    grid: {
      containLabel: true,
      left: spacer.right + 6,
      right: spacer.right,
      top: spacer.top * 2.8,
      bottom: 28,
    },
    legend: {
      icon: 'circle',
      itemGap: 30,
      itemWidth: 6,
      align: 'left',
      // @ts-ignore
      x: 'right',
      padding: [spacer.top, spacer.right, 0, 0],
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
      extraCssText: getTooltipContainerCss(`
        padding: 14px 16px 14px 16px;
        border-bottom-right-radius:0;
        `),
    },
    series: [
      {
        type: 'bar',
        barGap: '0',
        data: [],
      },
      // {
      //   type: 'bar',
      //   barGap: '0',
      //   data: [],
      // },
      // {
      //   type: 'bar',
      //   barGap: '0',
      //   data: [],
      // },
    ],
  }

  const emptyStateOption: EChartOption = {
    legend: {
      show: true,
    },
  }

  sharedApi.setOption(chart, option, defaultOption, emptyStateOption, opts)
}

const isEmpty = sharedApi.isEmpty

export { init, setOption, isEmpty }
