import * as sharedApi from '../api'
import { EChartOption, EChartsOptionConfig, ECharts } from 'echarts/lib/echarts'
import * as echarts from 'echarts/lib/echarts'
import { getTooltipContainerCss } from '../components/tooltip'
// @ts-ignore
import { globalColorBlack, globalColorDarkGrey } from '@scss-ts'

import 'echarts/lib/chart/pie'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/tooltip'

const init = (dom: HTMLElement) => sharedApi.init(echarts, dom)

const setOption = (
  chart: ECharts,
  option: EChartOption,
  opts: EChartsOptionConfig
) => {
  const defaultOption: EChartOption = {
    tooltip: {
      trigger: 'item',
      formatter: (params: any) => {
        const body = `
          <div style="color:${globalColorDarkGrey};">
            <strong style="color:${globalColorBlack}">
              ${params.percent}%
            </strong>
            ${params.name}
          </div>
        `
        return body
      },
      extraCssText: getTooltipContainerCss(
        `padding: 11px 14px 11px 14px;
         border-bottom-left-radius:0;
        `
      ),
    },
    legend: {
      orient: 'vertical',
      icon: 'roundRect',
      itemWidth: 16,
      itemHeight: 5,
      itemGap: 30,
      align: 'left',
      left: '60%',
      top: 'center',
      padding: [12, 27, 12, 27],
    },
    series: [
      {
        type: 'pie',
        data: [],
      },
    ],
  }

  const emptyStateOption: EChartOption = {
    series: [
      {
        center: ['50%'],
      },
    ],
  }

  sharedApi.setOption(chart, option, defaultOption, emptyStateOption, opts)
}

export { init, setOption }
