import {
  createComponent,
  reactive,
  SetupContext,
  watch,
} from '@vue/composition-api'

import style from './list.module.scss'
import * as types from '../types'

export default createComponent({
  name: 'CRUDListView',
  // @ts-ignore
  props: [...types.vueProps],

  setup(props: types.Props, ctx: SetupContext) {
    const state = reactive<any>({
      items: [],
      mode: types.Mode.VIEW,
    })

    watch(
      () => props.items,
      val => {
        state.items = val
      }
    )

    watch(
      () => props.mode,
      val => {
        state.mode = val
      }
    )

    // utils

    // rener funcs
    const Items = () => {
      return (
        <div>
          {state.items?.map(item => {
            if (item?.hidden) return null

            const renderMapEdit = item[types.Mode.EDIT]?.renderFunc()
              ? {
                  [types.Mode.EDIT]: loading => (
                    <section class={[style.fieldBox]}>
                      <fp-skeletonize loading={loading} preset="input">
                        {item[types.Mode.EDIT]?.renderFunc()}
                      </fp-skeletonize>
                    </section>
                  ),
                }
              : {}

            const renderMapCreate = item[types.Mode.CREATE]?.renderFunc()
              ? {
                  [types.Mode.CREATE]: loading => (
                    <section class={[style.fieldBox]}>
                      <fp-skeletonize loading={loading} preset="input">
                        {item[types.Mode.CREATE]?.renderFunc()}
                      </fp-skeletonize>
                    </section>
                  ),
                }
              : {}

            const renderMap = {
              [types.Mode.VIEW]: loading => (
                <section class={[style.fieldBox]}>
                  <fp-skeletonize
                    loading={loading}
                    width="120"
                    height="15"
                    no-animate
                    bottom-margin="6"
                  >
                    <label class={[style.fieldLabel]}>{item.label}</label>
                  </fp-skeletonize>
                  <fp-skeletonize
                    loading={loading}
                    width="250"
                    height="24"
                    round
                    bottom-margin="16"
                  >
                    <div class={[style.fieldDisplayValue]}>
                      {item.displayValue ? item.displayValue : 'N/A'}
                    </div>
                  </fp-skeletonize>
                </section>
              ),
              ...renderMapEdit,
              ...renderMapCreate,
            }

            let out = renderMap[state.mode] ?? renderMap[types.Mode.VIEW]

            if (item?.readOnly) {
              if (state.mode === types.Mode.CREATE) {
                out = renderMap[types.Mode.CREATE]
              } else if (state.mode === types.Mode.EDIT) {
                out = renderMap[types.Mode.VIEW]
              } else {
                out = renderMap[state.mode]
              }
            }

            return out && out(props.loading)
          })}
        </div>
      )
    }
    // data

    return () => {
      return (
        <div>
          <Items />
        </div>
      )
    }
  },
})

// -------------------------------------------------------------
