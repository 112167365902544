/*eslint-disable */

import { setContext } from '@apollo/client/link/context'
import store from '@state/store'
import { getMainDefinition } from 'apollo-utilities'
import { Strategy } from '@patterns'
import { mapValues, csg, pickBy } from '@utils'

// intro: apollo-links are chainable functions that controls flow of graphql requests
// https://github.com/apollographql/apollo-link. Can create custom links hereby

// https://github.com/apollographql/apollo-client/issues/2441

export const getGuardLink = () => {
  return setContext(request => {
    return new Promise(async (resolve, reject) => {
      const mainDef = getMainDefinition(request.query)
      const { kind, name } = mainDef
      const key = name?.value

      // privates
      const always = async opts => {
        const { noGuard = false } = opts || {}
        // we do this to avoid committing for unguarded operations
        if (!noGuard) {
          await store.dispatch(`actionGuards/setGuardOnDuty`, null)
        }

        return
      }

      const fail = async (err, opts) => {
        // props based on graphql client object props. see errorHandler for
        // the graphql client library used

        await reject({
          message: err ? err : `Permission denied for operation: ${key}`,
          canceledByUser: true,
        })
        await always(opts)
      }

      const success = async opts => {
        await resolve()
        await always(opts)
      }
      const guard = await store.getters[`actionGuards/guardOnDuty`]

      // checks
      if (kind !== 'OperationDefinition') {
        success({ noGuard: true })
        return
      }

      if (!guard) {
        success({ noGuard: true })
        return
      }

      if (guard.id !== key) {
        success({ noGuard: true })
        return
      }

      const approved = await store.getters[`actionGuards/isApproved`](key)
      if (approved) {
        success()
        return
      }

      if (guard.body?.onGuardEnd) {
        const [res, err] = await guard.body?.onGuardEnd()
        if (res === true) {
          success()
        } else {
          fail(err)
        }
      }

      store.subscribeAction({
        after: async (action, state) => {
          if (action.type === 'actionGuards/setApproved') {
            const approved = action.payload?.value
            const newVariables = action.payload?.variables

            if (newVariables) {
              // ⚠️⚠️⚠️ mutating original request. use object assign. cannot
              // use spread operator.  object assign triggers the variable change
              // inside the apollo client of appsync client js. Otherwise, apollo
              // client will not detect the variable change and still pass in the
              // old variables after re-assining the variables!  ⚠️⚠️⚠️
              request.variables = Object.assign(request.variables, newVariables)
            }

            const isRightOps = guard?.id === key
            if (isRightOps && approved === false) {
              fail()
            }
            if (isRightOps && approved === true) {
              success()
              return
            }
          }
        },
      })
    })
  })
}
