<script>
const TABS = 'tabs'
const SELECT = 'select'
const DEFAULT_VAL = -1

export default {
  name: 'FpTab',

  inheritAttrs: false,

  props: {
    tabs: {
      type: Array,
      required: true,
    },

    current: {
      type: Number,
      default: DEFAULT_VAL,
    },

    headerTabindex: {
      type: Number,
      default: DEFAULT_VAL,
    },

    type: {
      type: String,
      default: TABS,
      validator(v) {
        return [TABS, SELECT].includes(v)
      },
    },
  },

  data() {
    return {
      currentSelect: DEFAULT_VAL,
      TABS,
      SELECT,
    }
  },

  computed: {
    selectItems() {
      const tmp =
        this.tabs && this.tabs.map((o, i) => ({ text: o?.id, value: i }))
      return tmp
    },
  },
}
</script>

<template>
  <div :class="$style.container">
    <div :class="$style.selectBox">
      <fp-select
        v-if="type === SELECT"
        label="Navigation"
        :value="current"
        :items="selectItems"
        :class="$style.select"
        @change="v => $emit('update:current', v)"
      />
    </div>

    <v-tabs
      v-bind="$attrs"
      :value="current"
      slider-color="primary"
      fixed-tabs
      @change="v => $emit('update:current', v)"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.id"
        :tabindex="headerTabindex"
        :class="[$style.tab, { [$style.isError]: tab.error }]"
        :style="{
          display: type === TABS ? '' : 'none',
        }"
      >
        <div :class="[$style.tabInner, { [$style.isError]: tab.error }]">
          <fp-icon
            v-if="tab.error"
            :class="$style.errorIcon"
            name="exclamation-circle"
          />
          {{ tab.title }}
        </div>
      </v-tab>

      <v-tab-item v-for="tab in tabs" :key="tab.id + '-item'">
        <divider v-if="type === SELECT" class="pt-6" />
        <slot :name="tab.id"></slot>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<style lang="scss" module>
@import '@scss';

.container {
  position: relative;
  background-color: $color-white;
}

.selectBox {
  position: absolute;
  top: 0;
  z-index: $layer-dropdown-z-index;
  width: 100%;
}

:global(.v-tabs-bar),
.selectBox {
  @include content-padding(70, 0);
}

.tabInner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.tab:global(.v-tab--active) {
  &.isError {
    background-image: $gradient-accent;
  }
}

.tab:global(:not(.v-tab--active)) {
  &.isError {
    color: $color-accent !important;
  }
}

.errorIcon {
  margin-right: 5px;
  font-size: 20px;
}

:global(.v-tab) {
  height: 40px;
  font-weight: 600 !important;
}

:global(.v-tab--active) {
  color: $color-white !important;
  background: $gradient-primary;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.13);
}

:global(.v-tabs-slider) {
  display: none;
}

:global(.v-slide-group__wrapper) {
  contain: unset;
  overflow: visible;
}

:global(.theme--light.v-tabs-items) {
  background-color: transparent;
}

:global(
    .v-application--is-ltr
      .v-tabs-bar.v-tabs-bar--is-mobile:not(.v-tabs-bar--show-arrows)
      > .v-slide-group__wrapper
      > .v-tabs-bar__content
      > .v-tabs-slider-wrapper
      + .v-tab) {
  margin-left: 0;
}
</style>
