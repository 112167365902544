import gql from 'graphql-tag'

export const getOrgChildren = gql`
  query getOrgChildren($id: ID, $limit: Int, $skip: Int) {
    getOrgChildren(id: $id, limit: $limit, skip: $skip) {
      items {
        adSpaces
        balance
        earnings
        email
        games {
          adSpaces
          earnings
          id
          title
          viewableImpressions
        }
        id
        orgStatus
        title
        viewableImpressions
      }
      skip
      limit
      total
    }
  }
`
