// @ts-check
import * as formatters from './formatters'
import * as helpers from './helpers'

export function FPDate(input) {
  let value = input
  return {
    setDate: function (date) {
      value = helpers.setDate(date)
      return this
    },
    addDays: function (n) {
      value = helpers.addDays(value, n)
      return this
    },
    addMonths: function (n) {
      value = helpers.addMonths(value, n)

      // returning this will return the same object reference so defensive copy may be needed
      // alternatively the immutable way is to return the same FPDate function but
      // FPDate() may cause performance issues
      // to implement the immutable version go here https://stackoverflow.com/questions/46338399/how-to-chain-js-functions
      return this
    },
    addYears: function (n) {
      value = helpers.addYears(value, n)
      return this
    },
    formatDate: function (format) {
      value = formatters.formatDate(value, format)
      return this
    },

    distanceInWords: function (toDate) {
      value = formatters.distanceInWords(value, toDate)
      return this
    },

    toShortISOString: function () {
      value = helpers.toShortISOString(value)
      return this
    },

    getAWSTimestamp() {
      value = Math.floor(new Date(value).getTime() / 1000)
      return this
    },

    getTime() {
      value = new Date(value).getTime()

      return this
    },
    endOfDay: function () {
      value = helpers.endOfDay(value)
      return this
    },

    // To access the underlying value, we need an accessor
    result: function () {
      return value
    },

    // todo: return strings automatically without calling result() but not break vuetify
    // date picker component

    // valueOf: function() {
    //   if (typeof value === 'string') return value
    // },
  }
}

export default FPDate

// ref https://www.geekstrick.com/method-chaining-javascript/
