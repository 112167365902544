import * as queries from '@graphql/dashboard/queries'

import random from 'lodash/random'

export const state = {
  // TODO - old rangeMetrics and tables to be removed after enabling reporting dashboard for other users.
  rangeMetrics: null,
  tables: null,
  charts: null,
  cards: null,
  throttle: '',
}

export const getters = {
  charts({ charts }) {
    return charts
  },

  cards({ cards }) {
    return cards
  },

  rangeMetrics({ rangeMetrics }) {
    return rangeMetrics
  },

  tables({ tables }) {
    return tables
  },
}

export const mutations = {
  SET_ASYNC_THROTTLE(state, data) {
    state.throttle = data
  },
  SET_DASHBOARD(state, { rangeMetrics, tables }) {
    state.rangeMetrics = rangeMetrics
    state.tables = tables
  },
  SET_REPORTING_DASHBOARD(state, { charts, cards }) {
    state.charts = charts
    state.cards = cards
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ dispatch }) {},

  // ---------------------- //
  // GRAPHQL QUERIES
  // -----------------------//
  async getReportingStats({ dispatch, commit, state }, variables) {
    const throttle = await dispatch('vuexStateThrottle')

    const [err, res] = await this.dispatch('tryQuery', {
      query: queries.getReportingStats,
      variables,
    })

    if (!err && throttle === state.throttle) {
      commit('SET_REPORTING_DASHBOARD', res)
    }

    return [err, res]
  },

  // TODO - to be removed after enabling reporting dashboard for other users.
  async getDashboard({ dispatch, commit, state }, variables) {
    const throttle = await dispatch('vuexStateThrottle')

    const [err, res] = await this.dispatch('tryQuery', {
      query: queries.getDashboard,
      variables,
    })

    if (!err && throttle === state.throttle) {
      commit('SET_DASHBOARD', res)
    }

    return [err, res]
  },

  // ---------------------- //
  // HELPER ACTIONS
  // -----------------------//
  vuexStateThrottle({ commit }) {
    // Stop the Vuex store from updating the state with old data requests
    // Give us a random number to compare against
    let throttle = random(0, 1000)
    commit('SET_ASYNC_THROTTLE', throttle)
    return throttle
  },

  clearDashboard({ commit }) {
    commit('SET_REPORTING_DASHBOARD', {
      charts: null,
      cards: null,
    })

    // TODO - to be removed after enabling reporting dashboard for other users.
    commit('SET_DASHBOARD', {
      rangeMetrics: null,
      tables: null,
    })
  },
}
