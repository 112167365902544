import { OrgTypeEnum, OrgHierarchyEnum } from '@graphql-types'
import { RoleTypeEnum } from '@src/shared/constants'

const PagesEnum = {
  dashboard: { home: 'dashboard' },
  reportingDashboard: { home: 'reporting-dashboard' },
  companies: { home: 'companies' },
  companyDashboard: { home: 'dashboard-holding' },

  // companyDashboard: { home: 'company-dashboard/:id' },
  orgs: { home: 'organizations', new: 'organizations/new' },
  users: { home: 'users', new: 'users/new', edit: 'users/edit/:id' },
  rates: {
    home: 'rates',
    id: 'rates/:id',
  },
  ads: {
    home: 'ads',
    new: 'ads/new',
    id: 'ads/:id',
    edit: 'ads/edit/:id',
  },
  games: {
    home: 'games',
    new: 'games/new',
    edit: 'games/edit/:id',
    id: 'games/:id',
  },
  clients: {
    home: 'clients',
    new: 'clients/new',
    edit: 'clients/edit/:id',
  },
  download: {
    home: 'download',
  },
  reporting: {
    home: 'reports',
    new: 'reports/new',
    edit: 'reports/edit/:id',
  },
  reportingOld: {
    home: 'reporting',
  },
  addFunds: {
    home: 'add-funds',
  },
  account: {
    home: 'account',
    transactions: 'account/transactions',
    transactionsNew: 'account/transactions/new',
    verification: 'account/verification',
  },
  advancedSettings: {
    home: 'advanced-settings',
  },
  verification: {
    home: 'verification',
  },
  notifications: {
    home: 'notifications',
  },
  admin: {
    dashboardId: 'admin/dashboard/:id',
    orgDashboardId: 'admin/org-dashboard/:id',
    accountId: 'admin/account/:id',
    accountTransactionsId: 'admin/account/transactions/:id',
  },
  signupVerify: {
    verifyWait: 'sign-up-verify-wait',
    verifyPhone: 'verify-phone',
  },
  onboarding: {
    accountType: 'onboarding/account-type',
    orgType: 'onboarding/org-type',
    contact: 'onboarding/contact',
    student: 'onboarding/student',
    business: 'onboarding/business',
    school: 'onboarding/school',
  },
  auth: {
    home: 'auth',
    login: 'auth/login',
    signup: 'auth/sign-up',
    forgot: 'auth/forgot-password',
    new: 'auth/new-password',
    set: 'auth/set-password',
    signupVerify: 'auth/sign-up-verify-code',
    signupVerifyFb: 'auth/sign-up-verify-code-fb',
    signupVerified: 'auth/sign-up-verified',
    signupConfirm: 'auth/sign-up-confirm',
  },
  logout: { home: 'logout' },
  error: { 404: '404' },
} as const

export enum PermissionEnum {
  ADDNOTIFICATION = 'addNotification',
  ARCHIVEADS = 'archiveAds',
  ARCHIVEADSPACES = 'archiveAdSpaces',
  ARCHIVECLIENTS = 'archiveClients',
  ARCHIVEGAMES = 'archiveGames',
  CHANGEOWNERSHIP = 'changeOwnership',
  CREATEACCOUNT = 'createAccount',
  CREATEAD = 'createAd',
  CREATECLIENT = 'createClient',
  CREATEGAME = 'createGame',
  CREATEGAMERATE = 'createGameRate',
  CREATEORGMEMBER = 'createOrgMember',
  CREATEPAYMENT = 'createPayment',
  CREATESTRIPEPAYMENTINTENT = 'createStripePaymentIntent',
  CREATEUPLOADURL = 'createUploadURL',
  DELETENOTIFICATIONS = 'deleteNotifications',
  DISMISSNOTIFICATIONS = 'dismissNotifications',
  GETADREPORT = 'getAdReport',
  GETADS = 'getAds',
  GETADSPACES = 'getAdSpaces',
  GETCLIENTS = 'getClients',
  GETCURRENTUSER = 'getCurrentUser',
  GETDASHBOARD = 'getDashboard',
  GETREPORTINGSTATS = 'getReportingStats',
  GETGAMERATES = 'getGameRates',
  GETGAMES = 'getGames',
  GETNOTIFICATIONS = 'getNotifications',
  GETORGANISATIONS = 'getOrganisations',
  GETORGCHILDREN = 'getOrgChildren',
  GETORGMEMBERS = 'getOrgMembers',
  GETPAYMENTS = 'getPayments',
  GETSDK = 'getSdk',
  GETTAGS = 'getTags',
  GETTHIRDPARTIES = 'getThirdParties',
  GETTITLES = 'getTitles',
  PAUSEADS = 'pauseAds',
  PAUSEADSPACES = 'pauseAdSpaces',
  PAUSEGAMES = 'pauseGames',
  REMOVEORGMEMBER = 'removeOrgMember',
  REMOVESTRIPEPAYMENTMETHOD = 'removeStripePaymentMethod',
  REQUESTADREVIEWS = 'requestAdReviews',
  REQUESTGAMEREVIEWS = 'requestGameReviews',
  SEENNOTIFICATIONS = 'seenNotifications',
  SUSPENDORGS = 'suspendOrgs',
  UNARCHIVEADS = 'unarchiveAds',
  UNARCHIVEADSPACES = 'unarchiveAdSpaces',
  UNARCHIVECLIENTS = 'unarchiveClients',
  UNARCHIVEGAMES = 'unarchiveGames',
  UNPAUSEADS = 'unpauseAds',
  UNPAUSEADSPACES = 'unpauseAdSpaces',
  UNPAUSEGAMES = 'unpauseGames',
  UPDATEAD = 'updateAd',
  UPDATEBILLINGDETAILS = 'updateBillingDetails',
  UPDATECLIENT = 'updateClient',
  UPDATECONTACTDETAILS = 'updateContactDetails',
  UPDATEGAME = 'updateGame',
  UPDATEORGDETAILS = 'updateOrgDetails',
  UPDATEORGMEMBER = 'updateOrgMember',
  UPLOADFILE = 'uploadFile',
  VALDIATEPHONE = 'validatePhone',
  VERIFYITEMS = 'verifyItems',
}

//
// Page mapped to roles
//
const Admin = {
  [OrgTypeEnum.SUPER_ADMIN]: [RoleTypeEnum.FULL_ACCESS],
}

const ThirdParty = {
  [OrgTypeEnum.THIRD_PARTY]: [RoleTypeEnum.OWNER],
}

const AdvertiserAll = {
  [OrgTypeEnum.ADVERTISER]: [
    RoleTypeEnum.ACCOUNT_MANAGER,
    RoleTypeEnum.ADMIN,
    RoleTypeEnum.OWNER,
    RoleTypeEnum.FINANCE,
    RoleTypeEnum.REPORTING,
  ],
}

const AgencyAll = {
  [OrgTypeEnum.AGENCY]: [
    RoleTypeEnum.ACCOUNT_MANAGER,
    RoleTypeEnum.ADMIN,
    RoleTypeEnum.OWNER,
    RoleTypeEnum.FINANCE,
    RoleTypeEnum.REPORTING,
  ],
}

const AdvertiserManager = {
  [OrgTypeEnum.ADVERTISER]: [
    RoleTypeEnum.ACCOUNT_MANAGER,
    RoleTypeEnum.ADMIN,
    RoleTypeEnum.OWNER,
  ],
}

const AgencyManager = {
  [OrgTypeEnum.AGENCY]: [
    RoleTypeEnum.ACCOUNT_MANAGER,
    RoleTypeEnum.ADMIN,
    RoleTypeEnum.OWNER,
  ],
}

const GamePublisherAll = {
  [OrgTypeEnum.GAME_PUBLISHER]: [
    RoleTypeEnum.ACCOUNT_MANAGER,
    RoleTypeEnum.ADMIN,
    RoleTypeEnum.OWNER,
    RoleTypeEnum.DEVELOPER,
    RoleTypeEnum.FINANCE,
    RoleTypeEnum.REPORTING,
  ],
}

const GamePublisherManager = {
  [OrgTypeEnum.GAME_PUBLISHER]: [
    RoleTypeEnum.ACCOUNT_MANAGER,
    RoleTypeEnum.ADMIN,
    RoleTypeEnum.OWNER,
    RoleTypeEnum.DEVELOPER,
  ],
}

// const GamePublisherAdmin = {
//   [OrgTypeEnum.GAME_PUBLISHER]: [RoleTypeEnum.OWNER, RoleTypeEnum.ADMIN],
// }

// const GamePublisherFinance = {
//   [OrgTypeEnum.GAME_PUBLISHER]: [
//     RoleTypeEnum.OWNER,
//     RoleTypeEnum.FINANCE,
//     RoleTypeEnum.ADMIN,
//   ],
// }

const AdvertiserFinance = {
  [OrgTypeEnum.ADVERTISER]: [RoleTypeEnum.OWNER, RoleTypeEnum.FINANCE],
}

const AgencyFinance = {
  [OrgTypeEnum.AGENCY]: [RoleTypeEnum.OWNER, RoleTypeEnum.FINANCE],
}

const Page2Role = {
  // TODO - Hide old dashboard for now. To be removed in a future date
  // See the PR below when removing the old dashboard files
  // https://bitbucket.org/frameplay/%7Bae31564e-cbde-4d10-9bef-6cec9cc6bf96%7D/pull-requests/1813
  // [PagesEnum.dashboard.home]: {
  //   ...Admin,
  //   ...GamePublisherAll,
  //   ...AdvertiserAll,
  //   ...AgencyAll,
  // },
  // only enable reporting dashboard for SA for now
  [PagesEnum.reportingDashboard.home]: {
    ...Admin,
    ...GamePublisherAll,
    ...AdvertiserAll,
    ...AgencyAll,
  },
  [PagesEnum.companies.home]: {
    ...GamePublisherAll,
  },
  [PagesEnum.companyDashboard.home]: {
    ...GamePublisherAll,
  },
  [PagesEnum.orgs.home]: {
    ...Admin,
  },
  [PagesEnum.orgs.new]: {
    ...Admin,
  },
  [PagesEnum.users.home]: {
    ...Admin,
  },
  [PagesEnum.users.new]: {
    ...Admin,
  },
  [PagesEnum.users.edit]: {
    ...Admin,
  },
  [PagesEnum.rates.home]: {
    ...Admin,
  },
  [PagesEnum.rates.id]: {
    ...Admin,
  },
  [PagesEnum.ads.home]: {
    ...Admin,
    ...AdvertiserManager,
    ...AgencyManager,
  },
  [PagesEnum.ads.new]: {
    ...Admin,
    ...AdvertiserManager,
    ...AgencyManager,
  },
  [PagesEnum.ads.id]: {
    ...Admin,
    ...AdvertiserManager,
    ...AgencyManager,
  },
  [PagesEnum.ads.edit]: {
    ...Admin,
    ...AdvertiserManager,
    ...AgencyManager,
  },
  [PagesEnum.games.home]: {
    ...Admin,
    ...ThirdParty,
    ...GamePublisherManager,
  },
  [PagesEnum.games.new]: {
    ...Admin,
    ...GamePublisherManager,
  },
  [PagesEnum.games.edit]: {
    ...Admin,
    ...GamePublisherManager,
  },
  [PagesEnum.games.id]: {
    ...Admin,
    ...GamePublisherManager,
  },
  [PagesEnum.clients.home]: {
    ...Admin,
    ...AgencyManager,
  },
  [PagesEnum.clients.new]: {
    ...Admin,
    ...AgencyManager,
  },
  [PagesEnum.clients.edit]: {
    ...Admin,
    ...AgencyManager,
  },
  [PagesEnum.download.home]: {
    ...Admin,
    ...GamePublisherManager,
  },
  [PagesEnum.reporting.home]: {
    ...Admin,
    ...AdvertiserAll,
    ...AgencyAll,
    ...GamePublisherAll,
  },
  // Hide old reporting https://lowlatencymedia.atlassian.net/browse/PR-4307
  // [PagesEnum.reportingOld.home]: {
  //   ...Admin,
  //   ...AdvertiserAll,
  //   ...AgencyAll,
  // },
  [PagesEnum.addFunds.home]: {
    ...AdvertiserFinance,
    ...AgencyFinance,
  },
  [PagesEnum.account.home]: {
    ...ThirdParty,
    ...GamePublisherAll,
    ...AdvertiserAll,
    ...AgencyAll,
  },
  [PagesEnum.account.transactions]: {
    ...Admin,
    // ...ThirdParty,
    // ...GamePublisherFinance,
    // ...AdvertiserFinance,
    // ...AgencyFinance,
  },
  [PagesEnum.account.transactionsNew]: {
    ...Admin,
  },
  [PagesEnum.account.verification]: {
    ...ThirdParty,
    ...GamePublisherAll,
    ...AdvertiserAll,
    ...AgencyAll,
  },
  [PagesEnum.verification.home]: {
    ...ThirdParty,
    ...GamePublisherAll,
    ...AdvertiserAll,
    ...AgencyAll,
  },
  [PagesEnum.notifications.home]: {
    ...Admin,
    ...ThirdParty,
    ...GamePublisherAll,
    ...AdvertiserAll,
    ...AgencyAll,
  },
  [PagesEnum.advancedSettings.home]: {
    ...Admin,
    ...ThirdParty,
    ...GamePublisherAll,
    ...AdvertiserAll,
    ...AgencyAll,
  },
  [PagesEnum.admin.dashboardId]: {
    ...Admin,
  },
  [PagesEnum.admin.orgDashboardId]: {
    ...Admin,
  },
  [PagesEnum.admin.accountId]: {
    ...Admin,
  },
  [PagesEnum.admin.accountTransactionsId]: {
    ...Admin,
  },
}

const Page2Hier = {
  [PagesEnum.companies.home]: [OrgHierarchyEnum.HOLDING],
  [PagesEnum.companyDashboard.home]: [OrgHierarchyEnum.HOLDING],
  // TODO - renove the old dashboard after enabling for other users
  [PagesEnum.dashboard.home]: [
    OrgHierarchyEnum.STANDALONE,
    OrgHierarchyEnum.SUBSIDIARY,
  ],
  [PagesEnum.reportingDashboard.home]: [
    OrgHierarchyEnum.STANDALONE,
    OrgHierarchyEnum.SUBSIDIARY,
  ],
}

const Page2Permissions = {
  // TODO - update PagesEnum.dashboard.home to use PermissionEnum.GETREPORTINGSTATS after enabling for other users
  [PagesEnum.dashboard.home]: [PermissionEnum.GETDASHBOARD],
  [PagesEnum.reportingDashboard.home]: [PermissionEnum.GETREPORTINGSTATS],
  [PagesEnum.companies.home]: [],
  [PagesEnum.companyDashboard.home]: [PermissionEnum.GETDASHBOARD],
  [PagesEnum.orgs.home]: [],
  [PagesEnum.orgs.new]: [],
  [PagesEnum.users.home]: [
    PermissionEnum.GETORGMEMBERS,
    PermissionEnum.REMOVEORGMEMBER,
    PermissionEnum.UPDATEORGMEMBER,
    PermissionEnum.CREATEORGMEMBER,
  ],
  [PagesEnum.users.new]: [PermissionEnum.CREATEORGMEMBER],
  [PagesEnum.users.edit]: [PermissionEnum.UPDATEORGMEMBER],
  [PagesEnum.rates.home]: [
    PermissionEnum.GETGAMERATES,
    PermissionEnum.CREATEGAMERATE,
  ],
  [PagesEnum.rates.id]: [PermissionEnum.GETGAMERATES],
  [PagesEnum.ads.home]: [
    PermissionEnum.GETADS,
    PermissionEnum.PAUSEADS,
    PermissionEnum.UNPAUSEADS,
  ],
  [PagesEnum.ads.new]: [PermissionEnum.CREATEAD],
  [PagesEnum.ads.id]: [PermissionEnum.GETADS],
  [PagesEnum.ads.edit]: [PermissionEnum.UPDATEAD],
  [PagesEnum.games.home]: [
    PermissionEnum.GETGAMES,
    PermissionEnum.CREATEGAME,
    PermissionEnum.UPDATEGAME,
    PermissionEnum.PAUSEGAMES,
    PermissionEnum.UNARCHIVEGAMES,
    PermissionEnum.UNPAUSEGAMES,
    PermissionEnum.ARCHIVEGAMES,
  ],
  [PagesEnum.games.new]: [PermissionEnum.CREATEGAME],
  [PagesEnum.games.edit]: [PermissionEnum.UPDATEGAME],
  [PagesEnum.games.id]: [PermissionEnum.GETGAMES],
  [PagesEnum.clients.home]: [
    PermissionEnum.GETCLIENTS,
    PermissionEnum.CREATECLIENT,
    PermissionEnum.UPDATECLIENT,
    PermissionEnum.ARCHIVECLIENTS,
    PermissionEnum.UNARCHIVECLIENTS,
  ],
  [PagesEnum.clients.new]: [PermissionEnum.CREATECLIENT],
  [PagesEnum.clients.edit]: [PermissionEnum.UPDATECLIENT],
  [PagesEnum.download.home]: [PermissionEnum.GETSDK],
  [PagesEnum.reporting.home]: [PermissionEnum.GETADREPORT],
  [PagesEnum.addFunds.home]: [PermissionEnum.CREATESTRIPEPAYMENTINTENT],
  [PagesEnum.account.home]: [PermissionEnum.GETORGANISATIONS],
  [PagesEnum.account.transactions]: [
    PermissionEnum.GETPAYMENTS,
    PermissionEnum.REMOVESTRIPEPAYMENTMETHOD,
  ],
  [PagesEnum.account.transactionsNew]: [
    PermissionEnum.GETTITLES,
    PermissionEnum.CREATEPAYMENT,
  ],
  [PagesEnum.account.verification]: [PermissionEnum.GETORGANISATIONS],
  [PagesEnum.verification.home]: [''],
  [PagesEnum.notifications.home]: [
    PermissionEnum.GETNOTIFICATIONS,
    PermissionEnum.DELETENOTIFICATIONS,
    PermissionEnum.DISMISSNOTIFICATIONS,
    PermissionEnum.SEENNOTIFICATIONS,
  ],
  [PagesEnum.admin.dashboardId]: [PermissionEnum.GETDASHBOARD],
  [PagesEnum.admin.orgDashboardId]: [''],
  [PagesEnum.admin.accountId]: [PermissionEnum.GETORGANISATIONS],
  [PagesEnum.admin.accountTransactionsId]: [PermissionEnum.GETPAYMENTS],
}

export {
  // Role2Page,
  Page2Role,
  PagesEnum,
  Page2Hier,
  Page2Permissions,
}
