import gql from 'graphql-tag'

export const getGames = gql`
  query getGames(
    $ids: [ID]
    $limit: Int
    $skip: Int
    $orderBy: DefaultOrderByEnum
    $filter: GameFiltersInput
  ) {
    getGames(
      ids: $ids
      limit: $limit
      skip: $skip
      orderBy: $orderBy
      filter: $filter
    ) {
      skip
      limit
      total
      items {
        genres
        #
        release
        #
        rating
        adRestrictions
        coppaCompliant
        comments
        #
        paused
        reviewed
        approved
        #
        id
        status
        title
        archived
        org
        dateUpdated
        dateCreated
        # only SA
        dataCollection
        #
        platforms
        adApprovalType
        isTest
      }
    }
  }
`

export const getGamesSuperAdmin = gql`
  query getGames(
    $ids: [ID]
    $limit: Int
    $skip: Int
    $orderBy: DefaultOrderByEnum
    $filter: GameFiltersInput
  ) {
    getGames(
      ids: $ids
      limit: $limit
      skip: $skip
      orderBy: $orderBy
      filter: $filter
    ) {
      skip
      limit
      total
      items {
        genres
        #
        release
        #
        rating
        adRestrictions
        coppaCompliant
        comments
        #
        paused
        reviewed
        approved
        #
        id
        status
        title
        archived
        org
        dateUpdated
        dateCreated
        # only SA
        dataCollection
        sdkDownloadOrigin
        tags {
          tag
          name
          value
          valueType
          internal
          type
        }
        earningsCap
        earningsGoal
        #
        platforms
        adApprovalType
        isTest
      }
    }
  }
`

export const getGameList = gql`
  query getGames(
    $ids: [ID]
    $limit: Int
    $skip: Int
    $orderBy: DefaultOrderByEnum
    $filter: GameFiltersInput
  ) {
    # Logged In User Account
    getGames(
      ids: $ids
      limit: $limit
      skip: $skip
      orderBy: $orderBy
      filter: $filter
    ) {
      items {
        id
        title
        status
        reviewed
        paused
        archived
        approved
      }
    }
  }
`

export const getGameAdApprovals = gql`
  query getGameAdApprovals(
    $games: [ID]!
    $limit: Int
    $skip: Int
    $orderBy: GameAdApprovalsOrderByEnum
  ) {
    getGameAdApprovals(
      games: $games
      limit: $limit
      skip: $skip
      orderBy: $orderBy
    ) {
      skip
      limit
      total
      items {
        game
        ad
        adNickname
        fpApprover {
          id
          email
          firstName
          lastName
        }
        fpStatus
        fpComment
        gpApprover {
          id
          email
          firstName
          lastName
        }
        gpStatus
        gpComment
        dateUpdated
      }
    }
  }
`

export const getGameAdApprovalHistory = gql`
  query getGameAdApprovalHistory(
    $game: ID
    $ad: ID
    $limit: Int
    $skip: Int
    $orderBy: GameAdApprovalsOrderByEnum
  ) {
    getGameAdApprovalHistory(
      game: $game
      ad: $ad
      limit: $limit
      skip: $skip
      orderBy: $orderBy
    ) {
      skip
      limit
      total
      items {
        game
        ad
        adNickname
        fpApprover {
          id
          email
          firstName
          lastName
        }
        fpStatus
        fpComment
        gpApprover {
          id
          email
          firstName
          lastName
        }
        gpStatus
        gpComment
        dateUpdated
      }
    }
  }
`

export const getGameAccess = gql`
  query getGameAccess(
    $game: ID
    $user: ID
    $limit: Int
    $skip: Int
    $filter: GameAccessFilterInput
  ) {
    getGameAccess(
      game: $game
      user: $user
      limit: $limit
      skip: $skip
      filter: $filter
    ) {
      skip
      limit
      total
      items {
        game
        user {
          accountType
          allowAccessToAllEntities
          email
          firstName
          jobTitle
          lastName
          orgID
          phone
          timezone
          userID
          userRole {
            roleID
            roleName
            rolePermissions
          }
        }
        write
      }
    }
  }
`

export const getGameAccessForCurrentUser = gql`
  query getGameAccessForCurrentUser($game: ID) {
    getGameAccessForCurrentUser(game: $game) {
      game
      user {
        accountType
        allowAccessToAllEntities
        email
        firstName
        jobTitle
        lastName
        orgID
        phone
        timezone
        userID
        userRole {
          roleID
          roleName
          rolePermissions
        }
      }
      write
    }
  }
`
