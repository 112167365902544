import { ASPECT_RATIO_VALUES } from '@shared/constants'
import { startCaseAllUppercase } from '@utils'
import { noop } from '@src/shared/function'

export function convertToUnit(str, unit = 'px') {
  if (str == null || str === '') {
    return undefined
  } else if (isNaN(+str)) {
    return String(str)
  } else {
    return `${Number(str)}${unit}`
  }
}

/**
 * Turn enums to [{ text: string, value: any }] struct,
 * so it can be passed to <fp-select /> `items` prop,
 * the text will be capitalize by default
 * @param {string[]} enums enums from shared/enums
 * @param {map} textMap Priority use this map to relflect result text
 * @param {boolean} valueAsText use enums' value as text
 *
 */
export function enumsToSelectItems({
  enums = [''],
  avatars = {},
  textMap = {},
  valueAsText = false,
}): {
  avatar: any
  text: string
  value: string
}[] {
  return enums.map(key => ({
    avatar: avatars[key],
    text: valueAsText ? key : textMap[key] || startCaseAllUppercase(key),
    value: key,
  }))
}

/**
 * Helper function to reverse an array of our select items
 * @param {string[]} selectItems
 * @param {map} searchVal
 * @param {boolean} textTransformer
 */
export const reverseMapSelectItems = (
  selectItems,
  searchVal,
  textTransformer = noop
) => {
  // check if array of arrays (array of selectItems of {text, value}).
  if (Array.isArray(selectItems)) {
    if (Array.isArray(selectItems[0])) {
    } else {
      throw Error('selectItems must be an array of selectItem objects')
    }
  }

  const oneArrToRuleThemAll = [].concat.apply([], selectItems)

  // handle selectitems with multiple values
  if (Array.isArray(searchVal)) {
    const list = searchVal.map(words => {
      const outp: any = oneArrToRuleThemAll.find((item: any) => {
        const val = item.value
        return val === words
      })
      if (!outp) return ''

      // @ts-ignore
      const text = textTransformer(outp) || outp.text
      return text
    })
    return list.join(', ')
  }

  // handle selectitems with one value
  const outp = oneArrToRuleThemAll.find((item: any) => {
    const val = item.value
    return val === searchVal
  })

  if (!outp) return ''
  // @ts-ignore
  const text = textTransformer(outp) || outp.text
  return text
}

export function formatAspectRatio(value) {
  let returnValue
  Object.keys(ASPECT_RATIO_VALUES).forEach(e => {
    if (value === e) returnValue = ASPECT_RATIO_VALUES[e]
  })
  return returnValue
}

/**
 * Copy string to clipboard
 * @param {string} str
 */
export function copyToClipboard(str) {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'

  document.body.appendChild(el)

  const docSelection = document.getSelection()
  if (!docSelection) return

  const selected =
    docSelection.rangeCount > 0 // Check if there is any content selected previously
      ? docSelection.getRangeAt(0) // Store selection if found
      : false // Mark as false to know no selection existed before

  el.select() // Select the <textarea> content
  document.execCommand('copy') // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el)

  if (selected) {
    // If a selection existed before copying
    docSelection.removeAllRanges() // Unselect everything on the HTML document
    docSelection.addRange(selected) // Restore the original selection
  }
}
