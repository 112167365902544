<template>
  <v-dialog
    v-model="state.visible"
    hide-overlay
    :width="computation.width"
    attach="body"
    @click:outside="hide"
    @keydown.esc="hide"
  >
    <fp-card no-body-pad>
      <div :class="$style.body">
        <fp-icon-button
          class="close-button"
          icon="times"
          type="light"
          size="small"
          @click.capture="hide"
        />

        <video
          v-if="computation.isVideo"
          :class="$style.video"
          :src="url"
          :width="computation.width"
          controls
          autoplay
        ></video>

        <v-img
          v-else
          :src="url"
          :lazy-src="url"
          contain
          eager
          :transition="false"
          :aspect-ratio="computation.aspectRatio"
          :width="computation.width"
          max-width="100vw"
          max-height="80vh"
        >
          <template #placeholder>
            <div :class="$style.progressBox">
              <v-progress-circular indeterminate color="primary" />
            </div>
          </template>
        </v-img>
      </div>

      <div :class="$style.footer">
        <div :class="$style.fileName" class="text-truncate">
          {{ fileName }}
        </div>

        <!-- <div :class="$style.actions">
          <fp-button size="large" color="primary" type="text" @click="hide">
            Cancel
          </fp-button>
        </div> -->
      </div>
    </fp-card>
  </v-dialog>
</template>

<script>
import {
  createComponent,
  getCurrentInstance,
  reactive,
  computed,
} from '@vue/composition-api'
import { ASPECT_RATIO_VALUES, MIME_TYPE } from '@shared/constants'

const ratioTo480pResolutionWidth = {
  ONE_BY_ONE: 480,
  TWO_BY_THREE: 480,
  THREE_BY_TWO: 720,
  FOUR_BY_THREE: 640,
  THREE_BY_FOUR: 480,
  SIXTEEN_BY_NINE: 853.33,
  NINE_BY_SIXTEEN: 480,
  TWENTY_ONE_BY_NINE: 1120,
  NINE_BY_TWENTY_ONE: 480,
  FORTY_BY_NINE: 2133.33 / 2,
  NINE_BY_FORTY: 480 / 2,
}

export default createComponent({
  name: 'FpMediaPreviewDialog',

  props: {
    fileName: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    ratio: {
      type: String, // !! ⚠️ '4:3', '16:9' like, not 'FOUR_By_THREE' like string
      required: true,
    },
    mimeType: {
      type: String,
      required: false,
    },
  },

  setup(props) {
    const _this = getCurrentInstance()

    const state = reactive({
      visible: false,
    })

    const isRatioValid = !!ASPECT_RATIO_VALUES[props.ratio]

    const computation = reactive({
      aspectRatio: computed(() => {
        const [w, h] = props.ratio.split(':').map(Number)

        return w / h
      }),

      width: computed(() => {
        return isRatioValid
          ? ratioTo480pResolutionWidth[ASPECT_RATIO_VALUES[props.ratio]]
          : 600
      }),

      isVideo: computed(() => props.mimeType === MIME_TYPE.MP4),
    })

    return { state, computation, show, hide }

    function show() {
      state.visible = true
    }

    function hide() {
      state.visible = false

      setTimeout(() => {
        _this.$destroy()
      }, 300)
    }
  },
})
</script>

<style lang="scss" module="$style" src="./media-preview.module.scss"></style>
