// @ts-ignore

import to from 'await-to-js'
import { csg, isEmpty, invariant } from '@utils'
import graphql from '@graphql'

// if multiple queries, return all results from data
// if single query, return first result
export const mapQueryOrMutateData = data => {
  if (isEmpty(data)) return null
  if (Object.keys(data).length > 1) return data
  return Object.values(data)[0]
}

const _mutate = async ({ mutation, variables, dialog }) => {
  invariant(!!mutation, 'mutate helper requires a mutation')

  const { data } = await graphql.mutate({
    mutation: mutation,
    variables: { ...variables },
    dialog,
  })

  const out = mapQueryOrMutateData(data)

  return out
}

export const mutate = args => {
  return new Promise(async (resolve, reject) => {
    const [err, res] = await to(_mutate(args))

    const executeErrFn = async err => {
      csg('graphql', `mutate`, [{ label: 'Error', body: err }], true)
      await reject(err || 'Error caught but no error payload was passed back')
    }

    const executeSuccessFn = async res => {
      // await here because our reject function could be async/promise
      await resolve(res)
    }

    if (err) {
      executeErrFn(err)
      return
    }

    if (!res) {
      executeErrFn('Result null or undefined')
      return
    }

    if (!res.success) {
      const err = new Error(res.message)

      Object.entries(res).forEach(([k, v]) => {
        err[k] = v
      })

      executeErrFn(err)
      return
    }

    if (res.success) {
      executeSuccessFn(res)
      return
    }

    // we assume that if we don't detect any errors and result is not
    // success, maybe backend has a bug that did not return success
    // so let's say there is an error
    executeErrFn(`If you see this error, then we don't know why`)
  })
}

export const query = async ({ query, variables }) => {
  invariant(!!query, 'query helper requires a query')

  const res = await graphql.query({
    query: query,
    variables: { ...variables },
  })

  const out = mapQueryOrMutateData(res?.data)
  return out
}

export const tryMutate = async args => {
  const errAndRes = await to(mutate(args))
  return errAndRes
}

export const tryQuery = async args => {
  const errAndRes = await to(query(args))
  return errAndRes
}
