// @ts-nocheck

import Vue from 'vue'
import Popups from '@components/_base/pop-ups'
import router from '@router'
import store from '@state/store'
import { convertToUnit } from '@utils'

export type ActivatorSlotProps = {
  visible: boolean
  isPopupsEntered: boolean
  toggle: () => void
}

export default {
  skipGloballyRegister: true,

  name: 'FpDropdown',

  props: {
    hAlign: {
      type: String,
      default: 'right',
      validator(val) {
        return ['left', 'right'].includes(val)
      },
    },

    transition: {
      type: String,
      default: 'extend',
    },

    popupsClass: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      visible: false,

      popupsInstance: null,
      isPopupsEntered: false,
      popupsPosition: {
        top: 0,
        left: 0,
        right: 'auto',
      },

      documentWidth: document.body.clientWidth,
    }
  },

  computed: {
    slotProps() {
      const { visible, isPopupsEntered, toggle } = this
      return { visible, isPopupsEntered, toggle }
    },

    isMobile() {
      return this.$vuetify.breakpoint.xs
    },
  },

  watch: {
    hAlign() {
      this.popupsPosition = this.calcPosition()
    },

    visible(val) {
      if (val) {
        this.popupsPosition = this.calcPosition()
      }
    },
  },

  mounted() {
    this.renderPopups()

    window.addEventListener('scroll', this.updatePostionWhenScroll)
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.updatePostionWhenScroll)
  },

  updated() {
    if (this.documentWidth !== document.body.clientWidth) {
      this.documentWidth = document.body.clientWidth
      this.popupsPosition = this.calcPosition()
    }
  },

  methods: {
    calcPosition() {
      if (!this.$el) return this.popupsPosition

      // Ignore Invalid Node, e.g. COMMENT_NODE <!-- -->
      // https://developer.mozilla.org/en-US/docs/Web/API/Node/nodeType
      if (![1, 3, 9].includes(this.$el.nodeType)) return this.popupsPosition

      let top: number = 0
      let left: number = 'auto'
      let right: number = 'auto'

      const { hAlign } = this

      const alignToRight = hAlign === 'right'

      let {
        y,
        x,
        right: rightOffset,
        height,
      } = this.$el.getBoundingClientRect()

      top = convertToUnit(y + height + 10)

      if (this.isMobile) {
        left = '3vw'
        right = '3vw'
      } else {
        left = alignToRight ? 'auto' : convertToUnit(x)
        right = alignToRight
          ? convertToUnit(this.documentWidth - rightOffset)
          : 'auto'
      }

      return {
        top,
        left,
        right,
      }
    },

    updatePostionWhenScroll() {
      this.popupsPosition = this.calcPosition()
    },

    toggle(visibility) {
      this.visible =
        typeof visibility === 'boolean' ? visibility : !this.visible
      this.$emit(this.visible ? 'show' : 'hide')
    },

    renderPopups() {
      // 1️⃣ INITIALIZE POPUPS, BIND popups.$parent WITH DROPDWOMN ITSELF
      const popupsInstance = new (Vue.extend(Popups))({
        router,
        store,
        parent: this,
      })

      // 2️⃣ PASS DOWN CONTENT SLOT TO POPUPS
      popupsInstance.$scopedSlots = this.$scopedSlots

      // 3️⃣ SET UP MESSAGING APPROACHES
      popupsInstance.$on('click-outside', this.toggle.bind(this, false))
      popupsInstance.$on('after-enter', this.popupsEnterHandler)
      popupsInstance.$on('after-leave', this.popupsLeaveHandler)

      // 4️⃣ MOUNT IT
      popupsInstance.$mount()
      document.body.appendChild(popupsInstance.$el)

      // 5️⃣ KEEP THE REFERENCE OF POPUPS
      this.popupsInstance = popupsInstance
    },

    popupsEnterHandler(el) {
      this.isPopupsEntered = true
      this.$emit('after-popups-enter')
    },

    popupsLeaveHandler(el) {
      this.isPopupsEntered = false
      this.$emit('after-popups-leave')
    },
  },

  render(h) {
    // Render activator slot as dropdown's $el
    return this.$scopedSlots.activator(this.slotProps)
  },
}
