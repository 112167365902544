// ⚠️ one single source of truth for ALL guards in the app
// can be guarded graphql mutations, queries or vuex actions or
// custom UI logic or everything that is guarded

import * as gameGuards from '@graphql/game/guards'
import * as adSpaceGuards from '@graphql/adSpace/guards'
import * as adGuards from '@graphql/ad/guards'
import * as orgGuards from '@graphql/org/guards'
import * as paymentGuards from '@graphql/payment/guards'
import * as verifyItemsGuards from './verifyItemsGuard'
import * as memberGuards from '@graphql/member/guards'
import * as reportingKeysGuards from '@graphql/api-key/guards'

const allGuards = {
  ...gameGuards.guards,
  ...adGuards.guards,
  ...adSpaceGuards.guards,
  ...orgGuards.guards,
  ...verifyItemsGuards.guards,
  ...paymentGuards.guards,
  ...memberGuards.guards,
  ...reportingKeysGuards.guards,
}

export const allGuardedOperations = allGuards
