import { actions, getters } from './ads'
import store from '../store'

type AdActions = keyof typeof actions
type AdComputations = keyof typeof getters

export function AdDispatch(action: AdActions, payload?: any) {
  return store.dispatch(`ads/${action}`, payload)
}

export function AdGetters(key: AdComputations) {
  return store.getters[`ads/${key}`]
}
