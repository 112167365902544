// https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ForgotPassword.html

export const AUTH_EXCEPTIONS = {
  codeMismatchException: 'CodeMismatchException',
  expiredCodeException: 'ExpiredCodeException',
  invalidEmail: 'invalidEmail',
  invalidPasswordException: 'InvalidPasswordException',
  limitExceededException: 'LimitExceededException',
  UserLambdaValidationException: 'UserLambdaValidationException',
  userNotConfirmedException: 'UserNotConfirmedException',
  userNotFoundException: 'UserNotFoundException',
}

export const SIGN_UP_EXPIRY_KEY = 'fp-signup-expiry'
