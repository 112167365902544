import gql from 'graphql-tag'

export const getAPIKeys = gql`
  query getAPIKeys($userID: ID!, $apiKeyType: APIKeyTypeEnum) {
    getAPIKeys(userID: $userID, apiKeyType: $apiKeyType) {
      apiKey
      dateCreated
      dateUpdated
    }
  }
`
