import { embedFuncs } from '@shared/string/tagged'
import { toGerundUnreliable, firstUpper } from '@utils'
import { BUSINESS_ACTIONS } from '@constants'
import { formatType } from './utils'

// templates
const defaultTitle = embedFuncs`
Confirm ${args => (args.isBulk ? 'Bulk' : '')} ${args =>
  formatType(args?.type, { allUppercase: true })} ${args =>
  firstUpper(args.action)}`

const defaultContent = embedFuncs`
<p>
${args => firstUpper(toGerundUnreliable(args.action))}
your ${formatType} will stop all ads from being shown in your game's ad placements.
</p>
<p>
<strong>Do you want to continue?</strong>
    </p>`

const rejectContent = embedFuncs`
${args => {
  return args.customTitle
    ? `<p>Are you sure you want to reject <b>${args.customTitle}</b>?</p>`
    : ``
}}
<div>
If you are rejecting ${args =>
  formatType(args, {
    articlize: true,
  })} you must provide a rejection reason below.
</div>`

const approveContent = embedFuncs`${args => {
  return args.prependContent ? args.prependContent : ''
}}
<p>
Are you sure you want to approve ${args => {
  return args.customTitle
    ? `<b>${args.customTitle}</b>`
    : 'this ' + formatType(args)
}}?
</p>`

const map = {
  [BUSINESS_ACTIONS.PAUSE]: {
    title: defaultTitle,
    content: defaultContent,
  },
  [BUSINESS_ACTIONS.ARCHIVE]: {
    title: defaultTitle,
    content: defaultContent,
  },
  [BUSINESS_ACTIONS.APPROVE]: {
    title: defaultTitle,
    content: approveContent,
  },
  [BUSINESS_ACTIONS.REJECT]: {
    title: defaultTitle,
    content: rejectContent,
  },
}

const getContent = ({ action, type, customTitle, prependContent }) => {
  const template = map[action].content
  return template({ action, type, customTitle, prependContent })
}

const getTitle = ({ action, type, isBulk }) => {
  const template = map[action].title
  return template({ action, type, isBulk })
}

// IMPORTANT: if you are adding a new action apart from pausing or archiving,
// please add or check that it works with the unit tests in toGerundUnreliable function
// toGerundUnreliable is a cheap and small function convert verb like pause to pausing
// without using heavyweight bundle size NLP libraries
export const content = args => getContent({ ...args })
export const title = args => getTitle({ ...args })
