import { createComponent } from '@vue/composition-api'

export default createComponent({
  name: 'SVGCheck',
  setup() {
    return () => {
      return (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="20" cy="20" r="20" fill="#6BD57C" />
          <path
            d="M17.774 26.7447L12.2489 21.079C11.917 20.7386 11.917 20.1867 12.2489 19.8462L13.451 18.6135C13.7829 18.2731 14.3212 18.2731 14.6531 18.6135L18.375 22.4302L26.3469 14.2553C26.6788 13.9149 27.2171 13.9149 27.549 14.2553L28.7511 15.488C29.083 15.8284 29.083 16.3803 28.7511 16.7207L18.976 26.7447C18.6441 27.0851 18.1059 27.0851 17.774 26.7447Z"
            fill="white"
          />
        </svg>
      )
    }
  },
})
