import gql from 'graphql-tag'

export const getAdSpaces = gql`
  query getAdSpaces(
    $ids: [ID]
    $limit: Int
    $skip: Int
    $orderBy: DefaultOrderByEnum
    $filter: AdSpaceFiltersInput
  ) {
    # Logged In User Account
    getAdSpaces(
      ids: $ids
      limit: $limit
      skip: $skip
      orderBy: $orderBy
      filter: $filter
    ) {
      skip
      limit
      total
      items {
        id
        title
        game
        type
        status
        dynamics
        procedural
        aspectRatio
        paused
        archived
        dateUpdated
        dateCreated
      }
    }
  }
`
