import { reactive } from '@vue/composition-api'

type State = {
  on: boolean
}

/**
 * switches between true/false by default
 *
 * pass in boolean to disable switch and set a value
 *
 * usage:
 *
 * onClick={toggle}
 *
 * or
 *
 * onClick={() => toggle(true)}
 *
 */
export const useToggle = (initialValue = false) => {
  const state: State = reactive({
    on: false,
  })

  state.on = initialValue ?? false

  const toggle = (val?) => {
    if (typeof val === 'boolean') {
      state.on = val
    } else {
      state.on = !state.on
    }
  }

  return {
    state,
    toggle,
  }
}

export default useToggle
