import gql from 'graphql-tag'

export const notification = gql`
  fragment notification on Notification {
    id
    date
    dismissed
    seen
    title
    user
    message
    url
    type
  }
`
