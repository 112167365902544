import gql from 'graphql-tag'
import { dashRangeMetrics, dashTables } from '../fragments'

export const getOrgDashboard = gql`
  query getOrgDashboard($id: ID!) {
    getOrgDashboard(id: $id) {
      dashboard {
        rangeMetrics {
          ...dashRangeMetrics
        }
        tables {
          ...dashTables
        }
      }
      orgs {
        earnings
        title
        viewableImpressions
      }
    }
  }
  ${dashRangeMetrics}
  ${dashTables}
`
