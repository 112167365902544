// @ts-ignore
import Vue from 'vue'
import VueRouter from 'vue-router'
// https://github.com/declandewet/vue-meta
import VueMeta from 'vue-meta'
import store from '@state/store'
import routes from './routes'
import { getOrgTypeFromCognitoUser } from '@state/modules/org'
import { cs } from '@utils'
import * as RouteName from '@router/routeNames'
import * as FundsStore from '@state/modules/funds'
import { Auth } from 'aws-amplify'
import {
  checkRoutePermission,
  checkRoutePermissionHier,
  checkRoutePermissionOrg,
} from '@router/utils'
import { OrgHierarchyEnum } from '@graphql-types'

import {
  BLANK_STATE_ERROR,
  SSO_REFRESH_TOKEN_EXPIRED_ERROR,
} from '@src/state/modules/auth'
import { AuthDispatch } from '@src/state/modules/auth.store'
import { UserDispatch } from '@src/state/modules/user.store'

// We need to disable the next line or else it'll throw 'url.parse' was deprecated since v11.0.0. Use 'url.URL' constructor instead
// For some reason it's seeing the built in url browser type instead and not the imported url lib
// eslint-disable-next-line
import { parse } from 'url'

// Suppress router.push console NavigationDuplicate errors
// @todo - review when Vue 3 officially released
// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)
Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: 'page',
})

const router = new VueRouter({
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0,
      }
    }
  },
})

// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
  // Check if auth is required on this route (including nested routes).
  const authRequired = routeTo.matched.some(route => route.meta.authRequired)
  // If you're loggedin, re-route from these pages
  const redirectLoggedinRoutes = [
    'login',
    'sign-up',
    'set-password',
    RouteName.SIGNUP_VERIFY_WAIT,
    RouteName.SIGNUP_VERIFIED,
    RouteName.SIGNUP_VERIFY_CODE,
    RouteName.SIGNUP_VERIFY_CODE_FB,
  ]

  // If auth is required and the user is NOT currently logged in,
  // redirect to login unless using mock data
  if (process.env.VUE_APP_USE_MOCKED_DATA === 'true') {
    return next()
  }

  // reset to step 1 of add funds when in step 3 but goto another route and then come
  // back to add-funds. We don't want to stay stuck in step 3
  if (routeFrom?.name === 'add-funds') {
    FundsStore.dispatch('setState', {
      key: 'currentAddFundsStep',
      value: 1,
    })
  }

  const parsedUrl = parse(routeTo.fullPath, true)
  const excludedRoutesForSSO = [RouteName.NEW_PASSWORD, RouteName.SET_PASSWORD]

  // federated login here
  // check if the code is passed as a param
  // excluded some routes that also uses the code param
  if (parsedUrl.query.code && !excludedRoutesForSSO.includes(routeTo.name)) {
    await AuthDispatch('setSsoLoading', true)

    try {
      await AuthDispatch('ssoLogin', {
        code: parsedUrl.query.code,
        redirectRoute: routeTo.path,
      })
    } catch (e) {
      // return immediately to show blank page if anything goes wrong with fed login
      // TODO - update so it redirects back to the SSO login instead of a blank page
      await AuthDispatch('setSsoLoading', false)
      alert('An error has occurred while signing in.')
      return
    }

    await AuthDispatch('setSsoLoading', false)
  }

  return store.dispatch('auth/validate').then(async loggedInUser => {
    if (typeof loggedInUser === 'string') {
      const lowerCaseLoggedInUser = loggedInUser.toLowerCase()

      if (lowerCaseLoggedInUser === BLANK_STATE_ERROR.toLowerCase()) {
        // as mentioned in auth.js we check if it's a string and has the value of "unauthorized"
        // we need a clean slate to be able to continue which is why we should reload the page.
        // this is pretty much a stopgap measure for the blank page issue
        // every other call shouldn't be affected by this
        router.go()
      }

      // if the refresh token is expired for SSO users,
      // return immediately so it doesn't redirect to the login page
      if (
        lowerCaseLoggedInUser === SSO_REFRESH_TOKEN_EXPIRED_ERROR.toLowerCase()
      ) {
        return
      }
    }

    if (store.getters['user/loading']) {
      if (loggedInUser) await store.dispatch('auth/refreshSession')
      await store.dispatch('user/getUserInfo')
    }

    // work out the dashboard name depending on user details.
    // 🐛🐛 ex bug -> work out the name first before running next() because it may cause infinite loop
    let dashName = 'dashboard'
    const userInfo = store.getters['user/userInfo']
    if (userInfo.org?.hierarchy === OrgHierarchyEnum.HOLDING) {
      // for HOLDING users, redirect to company dashboard instead of dashboard
      dashName = 'dashboard-holding'
    }

    const goToDashboard = { name: dashName }

    if (loggedInUser) {
      /// 2. Redirect logged-in users away from routes they shouldn't see
      redirectLoggedinRoutes.map(value => {
        if (routeTo.name === value) {
          return next(goToDashboard)
        }
      })

      /// 3. Business rules for loggedin users
      const isFetchingOrganisation = store.getters['org/fetching']
      // Get currentOrgType either from localStorage or store.org
      // depend on whether getOrganisation is fetching

      const orgType = store.getters['org/orgType']
      const currUser = store.getters['auth/currentUser']

      const orgTypeFromAuthUser = getOrgTypeFromCognitoUser(currUser)
      const currentOrgType = isFetchingOrganisation
        ? orgTypeFromAuthUser
        : orgType

      // for new signed up users without org details, app will break when
      // going to dashboard. must onboard first
      const allowedOnboard = [
        RouteName.VERIFY_PHONE,
        RouteName.ONBOARD_ACCOUNT_TYPE,
        RouteName.ONBOARD_ORG_TYPE,
        RouteName.ONBOARD_STUDENT,
        RouteName.ONBOARD_CONTACT,
        RouteName.ONBOARD_USER,
        RouteName.ONBOARD_SCHOOL,
        RouteName.ONBOARD_BUSINESS,
      ]
      // only check start and end of onboard pages, to avoid spamming
      // Auth.currentUserPoolUser bypass cache, and also it slows down each page transition
      const routesToCheckForVerifyPhone = [
        RouteName.ONBOARD_ACCOUNT_TYPE,
        RouteName.ONBOARD_BUSINESS,
        RouteName.ONBOARD_SCHOOL,
      ]
      const notAllowedOnboardTo = !allowedOnboard.includes(routeTo?.name)
      const allowedOnboardFrom = allowedOnboard.includes(routeFrom?.from)

      // intercept any onboard routes when the user's phone is not verified
      if (routesToCheckForVerifyPhone.includes(routeTo?.name)) {
        const cognitoUser = await Auth.currentUserPoolUser({
          bypassCache: true,
        })
        const phoneVerified = cognitoUser?.attributes?.['phone_number_verified']
        if (!phoneVerified && routeTo?.name !== RouteName.VERIFY_PHONE) {
          return next({
            name: RouteName.VERIFY_PHONE,
            params: {
              routeNameOnSuccess: routeTo?.name,
            },
          })
        }
      }

      // if no org and the next route is not allowed when there is no org,
      // and if the prev route is an onboard page, go to that page,
      // else goto the start of the onboard page
      // most pages will break without an org, so we can't route to those

      // !! do not use the vuex store from orgs to get org type.
      // get the latest org from the cognito user to check whether user has an
      // org or not. https://lowlatencymedia.atlassian.net/browse/RS-1124
      if (!orgTypeFromAuthUser && notAllowedOnboardTo) {
        if (allowedOnboardFrom) {
          next({ name: routeFrom?.from })
        } else {
          next({ name: RouteName.ONBOARD_ACCOUNT_TYPE })
        }
        return
      }

      // ROUTE PERMISSION DETECTION
      // If currentUser has no permission to visit target route
      // Add rules to the array, check org type, then specify Vue structured path
      const redirectRules = [
        {
          res:
            currentOrgType !== 'SUPER_ADMIN' &&
            currentOrgType !== 'THIRD_PARTY',
          path: goToDashboard,
        },
        {
          res: currentOrgType === 'SUPER_ADMIN',
          path: goToDashboard,
        },
        {
          res: currentOrgType === 'THIRD_PARTY',
          path: { name: 'games-third' },
        },
      ]

      // Filter rules and redirect relevant user
      // eslint-disable-next-line no-unused-vars
      let [{ res, path }] = redirectRules.filter(rule => {
        if (rule.res) return rule
      })

      const notAllowed =
        routeTo.meta.allow &&
        !checkRoutePermission(routeTo.meta.allow, routeTo.meta.permissions)

      // if (store.getters['user/loading']) {
      //   await store.dispatch('user/getUserInfo')
      // }

      // // for HOLDING users, redirect to company dashboard instead of dashboard
      // const userInfo = store.getters['user/userInfo']
      // if (userInfo.org?.hierarchy === 'HOLDING') {
      //
      //   // return next({ name: 'company-dashboard' })

      //   path = { name: 'company-dashboard' }
      // }

      // let notAllowedHier = false

      // if (routeTo.meta.allowHier) {
      //   notAllowedHier = !checkRoutePermissionHier(routeTo.meta.allowHier)
      // }

      const notAllowedHier =
        routeTo.meta.allowHier &&
        !checkRoutePermissionHier(routeTo.meta.allowHier)

      const notAllowedOrg =
        routeTo.meta.allowOrg && !checkRoutePermissionOrg(routeTo.meta.allowOrg)

      if (notAllowed || notAllowedHier || notAllowedOrg) {
        // checks to prevent infinite loop
        if (routeTo.name !== path.name) {
          if (routeFrom.name === path.name) {
            return
          }
          next(path)
        } else {
          next()
        }
      }

      /// 4. If normal valid user going to route they can access - proceed
      return next()
    }

    /// 1. If Not loggedInUser and Authorisation isn't required - Continue
    //     ELSE if on route that does require Auth - Redirect to login page
    if (!authRequired) {
      // cs.i('Router: Auth not required')
      return next()
    } else {
      redirectToLogin()
    }
  })

  function redirectToLogin() {
    cs.i('Router: Redirect To Login')
    // Pass the original route to the login component
    store.dispatch('auth/logOut')

    return next({
      path: '/login',
      query: {
        redirectFrom: routeTo.fullPath,
      },
    })
  }
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  AuthDispatch('refreshSession', async (session, err) => {
    UserDispatch('updatePermissions')
  })

  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }

  // If we reach this point, continue resolving the route.
  next()
})

// When each route is finished evaluating...
router.afterEach((routeTo, routeFrom) => {
  // Complete the animation of the route progress bar.
})

export default router
