// https://date-fns.org/docs/parse
// import parseDate from 'date-fns/parse'
// https://date-fns.org/docs/distanceInWords
// import DFdistanceInWords from 'date-fns/distanceInWords'
// https://date-fns.org/docs/isToday
// import isToday from 'date-fns/is_today'
// https://date-fns.org/docs/format
// import format from 'date-fns/format'
// // https://date-fns.org/docs/differenceInHours
// import differenceInHours from 'date-fns/differenceInHours'

import {
  formatDistance,
  isToday,
  differenceInHours,
  format,
  toDate,
  isValid,
} from 'date-fns'

// example input 23:46
export function twentyFourStringToAMPM(input) {
  if (!input) return ''

  // extract hour and mins
  const toks = input.split(':')
  const h24 = toks[0]
  const mins = toks[1]

  // convert the hour
  var h = h24 % 12
  if (h === 0) h = 12
  return h + ':' + mins + (h24 < 12 ? ' am' : ' pm')
}

export function distanceInWords(ifromDate, itoDate = new Date()) {
  let fromDate = ifromDate
  let toDate = itoDate
  fromDate = parseTimestamp(fromDate)
  toDate = parseTimestamp(toDate)
  return formatDistance(fromDate, toDate) + (isToday(toDate) ? ' ago' : '')
}

export function formatDate(date, dateFormat = 'dd MMM, yyyy') {
  // no side effects for date format. handle parsing outside of formatting.
  const x = toDate(date)
  if (!isValid(x)) return ''
  const o = format(x, dateFormat)

  return o
}

export function formatDateUTC(date, dateFormat = 'dd MMM, yyyy') {
  // return immediately if falsey
  if (!date) {
    return ''
  }

  // no side effects for date format. handle parsing outside of formatting.
  const x = toDate(date)
  if (!isValid(x)) return ''

  const utcDate = new Date(
    x.getUTCFullYear(),
    x.getUTCMonth(),
    x.getUTCDate(),
    x.getUTCHours(),
    x.getUTCMinutes(),
    x.getUTCSeconds()
  )

  const o = format(utcDate, dateFormat)

  return o
}

// export function formatDateUTC(unix) {
//   const o = new Intl.DateTimeFormat('en-US', {
//     hour: '2-digit',
//     minute: '2-digit',
//     // second: '2-digit',
//     // year: '2-digit',
//     month: 'short',
//     day: '2-digit',

//     // timeZoneName: 'short',

//     timeZone: 'UTC',
//   }).format(unix)

//   return o
// }

export function formatTimeAgo(
  ifromDate,
  idateFormat = 'dd MMM, yyyy',
  itoDate = new Date()
) {
  let fromDate = ifromDate
  let dateFormat = idateFormat
  let toDate = itoDate

  // Check if date is unix timestamp from backend (in seconds)
  fromDate = parseTimestamp(fromDate)
  toDate = parseTimestamp(toDate)

  // Fallback Display state:
  // 23 hours ago
  // or
  // Jul 01, 2019
  return differenceInHours(toDate, fromDate) < 23
    ? formatDistance(fromDate, toDate) + ' ago'
    : format(fromDate, dateFormat)
}

// Parse Timestamps / Dates
// Expects timestamps in SECONDS
// Accepts Dates in any format
// May not work for edge cases with extremely old dates
export function parseTimestamp(input) {
  let date = input
  if (!isNaN(parseInt(date))) {
    date = date * 1000 // convert to milliseconds
  }
  return date
}

export const FP_TIME_FORMAT = 'HH:mm'
export const FP_PRETTY_DATETIME_FORMAT = 'dd MMM, yyyy - hh:mm a'
export const FP_DATETIME_PICKER_FORMAT = 'yyyy-MM-dd HH:mm:ss'
