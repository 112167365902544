import {
  createComponent,
  computed,
  reactive,
  ref,
  watch,
  onMounted,
} from '@vue/composition-api'
import FpDropdown from '@components/_base/dropdown'
import style from './style.module.scss'
// @ts-ignore
import { HeaderEventBus, HEADER_EVENTS } from '@components/header'
import { Action } from 'types'

// type Activator = ActivatorSlotProps

export type State<T> = {
  items: T[]
  loading: boolean
}

export type FooterAction = Action & {
  closeMenuOnClick?: boolean
}

type Props<T> = {
  icon: { name: string }
  title: string
  items: T[]
  loading: boolean
  footerLeft: FooterAction
  footerRight: FooterAction
}

export default createComponent({
  name: 'FpMenuCard',

  props: {
    icon: { type: Object, default: () => ({}) },
    items: { type: Array, default: () => [] },
    footerLeft: { type: Object, default: () => ({}) },
    footerRight: { type: Object, default: () => ({}) },
    title: { type: String, default: '' },
    loading: Boolean,
  },

  setup<T>(props: Props<T>, { slots }) {
    // ref name must match variable name.
    // todo: is there a way to not manually match string name and variable name of ref?
    const root = ref(null)
    const { icon, title } = props

    const state: State<T> = reactive({
      items: [],
      loading: false,
    })

    const computedState = reactive({
      noItems: computed(() => {
        return !state.items || state.items.length <= 0
      }),
      hasIcon: computed(() => {
        return icon && icon.name
      }),
    })

    watch(
      () => props.items,
      items => {
        state.items = items
      }
    )

    watch(
      () => props.loading,
      val => {
        state.loading = val
      }
    )

    // todo: find a better solution to do this
    onMounted(() => {
      HeaderEventBus.$on(HEADER_EVENTS.headerUnpin, () => {
        // @ts-ignore
        // eslint-disable-next-line no-unused-expressions
        root?.value?.toggle(false)
      })
    })

    return () => {
      const Header = () => {
        return (
          <v-container fluid class="pa-0">
            <v-row align="center" justify="center" no-gutters class="pb-5">
              {(computedState.hasIcon && (
                <v-col class={[style.iconBox, 'ml-3']} cols="1">
                  <fp-icon name={icon.name} class={style.icon} />
                </v-col>
              )) ??
                null}

              <v-col class="ml-3">
                <h4 class={style.header}>{title}</h4>
              </v-col>
            </v-row>
          </v-container>
        )
      }

      const Items = () => {
        let rv: JSX.Element | JSX.Element[] = slots.noItems()

        if (state.loading) {
          rv = (
            <v-col cols="12" class={[style.itemSkele, 'mb-4']}>
              <fp-skeleton width="100%" height="105" />
            </v-col>
          )
        } else if (computedState.noItems) {
          rv = (
            <v-col>
              <div class={style.noItems}>{slots.noItems()}</div>
            </v-col>
          )
        } else {
          rv = state.items.map((itemIteratee, i) => {
            return <v-col cols="12">{slots.item(itemIteratee, i)}</v-col>
          })
        }

        return (
          <v-container fluid class="pa-0">
            {' '}
            <v-row no-gutters align="center" justify="center">
              {rv}
            </v-row>
          </v-container>
        )
      }

      const Footer = () => {
        // resolve to false, because vue wants to mutate an undefined object
        // to an object with observer, which cause condition to not work
        const hasActionProps =
          (props.footerRight?.label || props.footerLeft?.label) ?? false
        const hasSlots = (slots.footerLeft || slots.footerRight) ?? false

        if (!hasActionProps && !hasSlots) return <div class="pt-5"></div>

        const defaultFooterButton = (props: FooterAction) => {
          const { disabled, onClick, label, closeMenuOnClick } = props

          const handleClick = () => {
            if (disabled || !onClick) return
            onClick()
            if (closeMenuOnClick) {
              // @ts-ignore
              // eslint-disable-next-line no-unused-expressions
              root?.value?.toggle(false)
            }
          }

          return (
            <div class={[style.footerButton, disabled && style.disabled]}>
              <span onClick={handleClick} class={style.label}>
                {label}
              </span>
            </div>
          )
        }

        const footerButton = (key: 'footerRight' | 'footerLeft') => {
          const footerActionProps = props[key]

          if (slots[key]) {
            return slots[key](root?.value)
          } else if (footerActionProps?.label) {
            return defaultFooterButton(footerActionProps)
          }
          return null
        }

        const FooterButtonLeft = () => {
          if (!footerButton('footerLeft')) return null
          return (
            <v-col>
              <div class="mx-3">{footerButton('footerLeft')}</div>
            </v-col>
          )
        }

        const FooterButtonRight = () => {
          if (!footerButton('footerRight')) return null
          return (
            <v-col>
              <div class="text-right mx-3">{footerButton('footerRight')}</div>
            </v-col>
          )
        }

        return (
          <v-container fluid class="px-0">
            {' '}
            <v-row align="center" justify="space-between">
              <FooterButtonLeft />
              <FooterButtonRight />
            </v-row>
          </v-container>
        )
      }

      return (
        // @ts-ignore
        <FpDropdown
          // root must match the ref variable name.
          ref="root"
          scopedSlots={{
            activator: slots.activator,
            content: () => (
              <div class={style.content}>
                <Header />
                <Items />
                <Footer />
              </div>
            ),
          }}
        ></FpDropdown>
      )
    }
  },
})
